import { z } from 'zod';

// Zod custom type
// null 허용하고 싶으면 뒤에 각 스키마에 nullish() 붙여서 사용
// nullable 을 따로 만든건 nullable을 그 뒤에 적용해도 안되는 케이스

// #region numeric

// for double negative/positive/0
/* export const zodNumber = z
    .string()
    .transform((val) => Number(`${val}`.replaceAll(',', '')))
    .pipe(z.number())
    .or(z.coerce.number());

// for double positive/0 
export const zodPosiNumber = z
    .string()
    .transform((val) => Number(`${val}`.replaceAll(',', '')))
    .pipe(z.number().nonnegative())
    .or(z.coerce.number().nonnegative())

    
// for int positive/0  (ord 와 같이 0 또는 양수에 사용)
export const zodPosiInt = z
    .string()
    .transform((val) => Number(`${val}`.replaceAll(',', '')))
    .pipe(z.number().int().nonnegative())
    .or(z.coerce.number().int().nonnegative())

// for int nullable positive except 0  
export const zodPosiNumberExp0Nullable = z
    .number().min(0).nullish()
    .or(z.string()
        .transform((val) => Number(`${val}`.replaceAll(',', '')))
        .pipe(z.number().min(0))
    )
*/


export const zodNumber = (optional: boolean) =>
    z.custom<string>().refine((value) => {
        if (value == null || value === '') return optional;
        if (typeof value === 'number') return true;
        const v = Number(String(value).replaceAll(',', ''));
        return Number.isNaN(v) === false;
    });

// for double positive/0
export const zodPosiNumber = (optional: boolean) =>
    z.custom<string>().refine((value) => {
        if (value == null || value === '') return optional;
        if (typeof value === 'number' && value >= 0) return true;
        const v = Number(String(value).replaceAll(',', ''));
        return Number.isNaN(v) === false && v >= 0;
    });

// for int positive/0  (ord 와 같이 0 또는 양수에 사용)
export const zodPosiInt = (optional: boolean) =>
    z.custom<string>().refine((value) => {
        if (value == null || value === '') return optional;
        if (typeof value === 'number' && value >= 0 && Number.isInteger(value)) return true;
        // if (value.includes('.')) return false; // 여기선 int() 해도 안됨 
        const v = Number(String(value).replaceAll(',', ''));
        return Number.isNaN(v) === false && v >= 0 && Number.isInteger(v);
    });


export const zodRange = (min: number, max: number) =>
    z.coerce
        .number()
        .refine((value) => value >= min && value <= max, `Must be ebetween ${min} and ${max}`)

export const zodRangeNullable = (min: number, max: number) =>
    zodRange(min, max)
        .or(z.literal(''))
        .nullish()


/*
export const zodRange = (min: number, max: number) =>
    z.custom<string>().refine((value) => {
        if (value == null || value === '') return false;
        if (typeof value === 'number' && value >= 0) return true;
        const num = Number(String(value));
        if (num < min || num > max) { return false }
        return true;
    });

export const zodRangeNullable = (min: number, max: number) =>
    z.custom<string>().refine((value) => {
        if (value == null || value === '') return true;
        if (typeof value === 'number' && value >= 0) return true;
        const num = Number(String(value));
        if (num < min || num > max) { return false }
        return true;
    });
*/

// #endregion

// #region Date 
export const zodDate = z
    .string()
    .regex(/^\d{4}-\d{2}-\d{2}$/, 'Must be in YYYY-MM-DD format.')

export const zodDateNullable = z.literal('').nullish().or(zodDate)

export const zodTime = z
    .string()
    .regex(/^\d{2}:\d{2}:\d{2}$/, 'Must be in hh:mm:ss format.')

export const zodTimeNullable = zodTime.or(z.literal('')).nullish()

export const zodDateTime = z
    .string()
    .regex(
        /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/,
        'Must be in YYYY-MM-DD HH:mm:ss format.',
    )

export const zodDateTimeNullable = zodDateTime.or(z.literal('')).nullish()

// #endregion
