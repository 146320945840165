import React, { useCallback, useEffect, useState } from 'react';
import './autocomplete.scss';
import OutsideHandler from './OutsideHandler';

export interface AutoCompleteProps<T> {
  inputId?: string;
  field?: keyof T;
  suggestions: T[];
  itemTemplate?: (v: T) => React.ReactNode;
  completeMethod: (query: string) => void;
  onChange: (v: T | null) => void;
  size?: number;
  inputClassName?: string;
  title?: string;
  inputStyle?: React.CSSProperties;
  placeholder?: string;
  disabled?: boolean;
  optional?: boolean;
  initItem?: T | null;
}

// iphone에서 datalist 제대로 작동안해서 별개로

export default function AutoComplete<T>({
  inputId,
  field,
  suggestions,
  itemTemplate,
  completeMethod,
  onChange,
  size,
  inputClassName,
  title,
  inputStyle,
  placeholder,
  disabled,
  optional,
  initItem,
}: AutoCompleteProps<T>) {
  const [currQry, setCurrQry] = useState<string>('');
  const [showList, setShowList] = useState(false);

  const vfn = (v: T) => String(field ? v[field] : v);

  useEffect(() => {
    if (initItem) onItemSelect(vfn(initItem));
  }, [initItem]);

  const onInputChange = (query: string) => {
    setCurrQry(query);
    onChange(null); // 기존 선택 결과 리셋
    if (query.length > 1) completeMethod(query);
  };

  useEffect(() => {
    setShowList(!!suggestions.length);
  }, [suggestions]);

  const onItemSelect = (val: string) => {
    setCurrQry(val);
    const picked = initItem && val === vfn(initItem)
      ? initItem
      : suggestions.find((v) => val === vfn(v));
    onChange(picked != null ? picked : optional ? (currQry as T) : null);
    setShowList(false);
  };

  useEffect(() => {
    if (!currQry && !optional) onChange(null);
    else if (optional) {
      if (optional) {
        onChange(optional ? (currQry as T) : null);
      }
    }
  }, [currQry]);

  // https://velog.io/@hyejeong/Keyboard-Navigation-%EA%B5%AC%ED%98%84%ED%95%98%EA%B8%B0
  const [cursor, setCursor] = useState(-1);

  const keyboardNavigation = useCallback(
    (e: KeyboardEvent) => {
      if (!showList) return;
      const dftAction = () => {
        e.preventDefault();
        // e.stopPropagation()
      };
      if (e.key === 'ArrowDown') {
        dftAction();
        setCursor((prev) => (prev < suggestions.length - 1 ? prev + 1 : prev));
      } else if (e.key === 'ArrowUp') {
        dftAction();
        setCursor((prev) => (prev > 0 ? prev - 1 : 0));
      } else if (e.key === 'Escape') {
        dftAction();
        setCursor(-1);
        setShowList(false);
      } else if (e.key === 'Enter' && cursor >= 0 && suggestions[cursor]) {
        dftAction();
        onItemSelect(vfn(suggestions[cursor]));
      } else {
        setCursor(-1);
      }
    },
    [suggestions, showList, cursor],
  );

  useEffect(() => {
    window.addEventListener('keydown', keyboardNavigation);
    return () => {
      window.removeEventListener('keydown', keyboardNavigation);
    };
  }, [keyboardNavigation]);

  useEffect(() => {
    const ul = document.getElementsByClassName('autocomplete-items-wrapper');
    if (ul.length !== 1) return;
    const item = ul[0].getElementsByClassName('current');
    if (item.length !== 1) return;
    item[0].scrollIntoView(false);
  }, [cursor]);

  return (
    <OutsideHandler callback={() => onItemSelect(currQry)}>
      <span className="autocomplete-wrapper">
        <input
          id={inputId}
          value={currQry}
          placeholder={placeholder}
          onChange={(e) => onInputChange(e.target.value)}
          size={size}
          className={inputClassName}
          title={title}
          style={inputStyle}
          disabled={disabled}
        />
        {showList && !!suggestions.length && (
          <ul className="autocomplete-items-wrapper">
            {suggestions.map((v, index) => (
              <li
                key={vfn(v)}
                className={index === cursor ? 'current' : ''}
                onClick={() => onItemSelect(vfn(v))}
              >
                {itemTemplate?.(v) ?? vfn(v)}
              </li>
            ))}
          </ul>
        )}
      </span>
    </OutsideHandler>
  );
}
