import _ from 'lodash';
import React from 'react';

/* eslint-disable no-extend-native */
export {};
declare global {
  export interface Number {
    getSignColor(): string;
    toFixedWithComma(digits: number, minDigits?: number): string;
    toFixedSigFig(digitsToAdd: number, comma?: boolean): string;
    toIntDigits(digits: number): string;
    getSignColorMinusRed(): string;
    getNeg(): number;
  }

  export interface String {
    isIn(...args: string[]): boolean;
    fixProdId(): string; // 주식코드 A 제거

    // API에서 NaN, Infinity 등은 문자열로 serialization 되어서 (json에 NaN은 없어서)
    toFixed(digits: number): string; // 'NaN' 케이스
    toFixedWithComma(digits: number, minDigits?: number): string; // 'NaN' 케이스
    toFixedSigFig(digits: number): string;
    getSignColor(): string;
    toNumber(): number | null;
    getSignColorMinusRed(): string;
    toDate(): Date | undefined;
  }

  export interface Date {
    toFormatString(fmt: string): string;
    // getTimezoneOffset이 작동안하는 브라우저 있어서 서버서 주는 시간 사용
    // asKST(): Date; // 시각 값만 해당 타임존으로.  타임존 표시는 브라우저 타임존
    addMonths(months: number): Date;
    addSeconds(seconds: number): Date;
    addDays(days: number): Date;
    consenEoQ(): Date;
  }

  export interface Array<T> {
    contains(v: T): boolean;
  }

  // simplegrid 등에서 dftFormatter 에러 안나게 하기 위해 (ReactNode 타입 케이스 때문)
  export interface Object {
    getSignColor(): string;
    toFixed(digits: number): string; // 'NaN' 케이스
    toFixedWithComma(digits: number, minDigits?: number): string;
  }
}

Number.prototype.getSignColor = function getSignColor(this: number): string {
  if (this > 0) return 'red';
  if (this < 0) return 'blue';
  return 'black';
};

Number.prototype.getSignColorMinusRed = function getSignColorMinusRed(
  this: number,
): string {
  if (this < 0) return 'red';
  return 'black';
};

Number.prototype.toFixedWithComma = function toFixedWithComma(
  this: number,
  digits: number,
  minDigits: number | undefined = undefined,
): string {
  return this.toLocaleString('en-US', {
    minimumFractionDigits: minDigits ?? digits,
    maximumFractionDigits: digits,
  });
};

function getSigFig(v: number) {
  // significant figure (지수로 바꿔야..)
  if (Math.abs(v) >= 1000) return 0;
  if (Math.abs(v) >= 100) return 1;
  if (Math.abs(v) >= 10) return 2;
  if (Math.abs(v) >= 1) return 3;
  if (Math.abs(v) >= 0.1) return 4;
  return 5;
}

Number.prototype.toFixedSigFig = function toFixedSigFig(
  this: number,
  digitsToAdd = 0,
) {
  return this.toFixedWithComma(getSigFig(this) + digitsToAdd);
};

Number.prototype.toIntDigits = function toIntDigits(
  this: number,
  digits: number,
): string {
  return this.toLocaleString('en-US', { minimumIntegerDigits: digits });
};

// number | undefined 숫자에 대해 쉽게 음수 받을려고
Number.prototype.getNeg = function getNeg(this: number): number {
  return -this;
};

String.prototype.isIn = function isIn(
  this: string,
  ...args: string[]
): boolean {
  return args.indexOf(this) >= 0;
};

String.prototype.fixProdId = function fixProdId(this: string): string {
  return this[0] === 'A' ? this.slice(1) : this;
};

String.prototype.toFixed = function toFixed(
  this: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  digits: number,
): string {
  return this;
};

String.prototype.toFixedWithComma = function toFixedWithComma(
  this: string,
  digits: number,
  // eslint-disable-next-line
  minDigits: number | undefined = undefined,
): string {
  return this;
};

String.prototype.toFixedSigFig = function toFixedSigFig(
  this: string,
  // eslint-disable-next-line
  digitsToAdd = 0,
): string {
  return this;
};

String.prototype.getSignColor = function getSignColor(this: string): string {
  return 'black';
};

String.prototype.toNumber = function toNumber(this: string): number | null {
  return this === '' ? null : Number(this.replace(/,/g, ''));
};

String.prototype.toDate = function toDate(this: string): Date | undefined {
  const d = new Date(this);
  if (d instanceof Date && !isNaN(d.valueOf())) return d;
};

function padDigits(n: number, totalDigits: number) {
  const nStr = n.toString();
  let pd = '';
  if (totalDigits > nStr.length) {
    for (let i = 0; i < totalDigits - nStr.length; i += 1) {
      pd += '0';
    }
  }
  return pd + nStr;
}

Date.prototype.toFormatString = function toFormatString(
  this: Date,
  fmt: string,
): string {
  if (!this) return 'undefined date';
  if (fmt === 'yyyyMMdd') {
    return (
      this.getFullYear() +
      padDigits(this.getMonth() + 1, 2) +
      padDigits(this.getDate(), 2)
    );
  }
  if (fmt === 'yyyy-MM-dd') {
    return `${this.getFullYear()}-${padDigits(
      this.getMonth() + 1,
      2,
    )}-${padDigits(this.getDate(), 2)}`;
  }
  if (fmt === 'yy-MM-dd') {
    return `${padDigits(this.getFullYear() - 2000, 2)}-${padDigits(
      this.getMonth() + 1,
      2,
    )}-${padDigits(this.getDate(), 2)}`;
  }
  if (fmt === 'yy/MM/dd') {
    return `${padDigits(this.getFullYear() - 2000, 2)}/${padDigits(
      this.getMonth() + 1,
      2,
    )}/${padDigits(this.getDate(), 2)}`;
  }
  if (fmt === 'yyyy/MM/dd') {
    return `${this.getFullYear()}/${padDigits(
      this.getMonth() + 1,
      2,
    )}/${padDigits(this.getDate(), 2)}`;
  }
  if (fmt === 'MM/dd/yyyy') {
    return `${padDigits(this.getMonth() + 1, 2)}/${padDigits(
      this.getDate(),
      2,
    )}/${this.getFullYear()}`;
  }
  if (fmt === 'yyyy/MM')
    return `${this.getFullYear()}/${padDigits(this.getMonth() + 1, 2)}`;
  if (fmt === 'MM/dd')
    return `${padDigits(this.getMonth() + 1, 2)}/${padDigits(
      this.getDate(),
      2,
    )}`;
  if (fmt === 'MM-dd')
    return `${padDigits(this.getMonth() + 1, 2)}-${padDigits(
      this.getDate(),
      2,
    )}`;
  if (fmt === 'HHmm')
    return padDigits(this.getHours(), 2) + padDigits(this.getMinutes(), 2);
  if (fmt === 'HH:mm')
    return `${padDigits(this.getHours(), 2)}:${padDigits(
      this.getMinutes(),
      2,
    )}`;
  if (fmt === 'HH:mm:ss') {
    return `${padDigits(this.getHours(), 2)}:${padDigits(
      this.getMinutes(),
      2,
    )}:${padDigits(this.getSeconds(), 2)}`;
  }
  if (fmt === 'HH:mm:ss.fff') {
    return `${this.toFormatString('HH:mm:ss')}.${padDigits(
      this.getMilliseconds(),
      3,
    )}`;
  }
  if (fmt === 'yyyyMMdd HHmm')
    return `${this.toFormatString('yyyyMMdd')} ${this.toFormatString('HHmm')}`;
  if (fmt === 'yyyy-MM-dd HH:mm')
    return `${this.toFormatString('yyyy-MM-dd')} ${this.toFormatString(
      'HH:mm',
    )}`;
  if (fmt === 'MM/dd/yy') {
    return `${padDigits(this.getMonth() + 1, 2)}/${padDigits(
      this.getDate(),
      2,
    )}/${padDigits(this.getFullYear() - 2000, 2)}`;
  }
  if (fmt === 'M/d/yy') {
    return `${this.getMonth() + 1}/${this.getDate()}/${padDigits(
      this.getFullYear() - 2000,
      2,
    )}`;
  }
  if (fmt === 'MM/dd HH:mm') {
    return `${padDigits(this.getMonth() + 1, 2)}/${padDigits(
      this.getDate(),
      2,
    )} ${this.toFormatString('HH:mm')}`;
  }
  if (fmt === 'MM-dd HH:mm') {
    return `${padDigits(this.getMonth() + 1, 2)}-${padDigits(
      this.getDate(),
      2,
    )} ${this.toFormatString('HH:mm')}`;
  }
  return `wrong date format : ${fmt}`;
};

Date.prototype.addMonths = function addMonths(
  this: Date,
  months: number,
): Date {
  const d = new Date(this.getTime());
  d.setMonth(d.getMonth() + months);
  return d;
};

Date.prototype.addSeconds = function addSeconds(
  this: Date,
  seconds: number,
): Date {
  return new Date(this.getTime() + seconds * 1000);
};

Date.prototype.addDays = function addDays(this: Date, days: number): Date {
  const d = new Date(this.getTime());
  d.setDate(d.getDate() + days);
  return d;
};

Date.prototype.consenEoQ = function consenEoQ(this: Date): Date {
  const d = new Date();
  const month = d.getMonth() + 1; // 0 ~ 11
  const year = d.getFullYear();
  const date = d.getDate(); // getDay 요일
  if (month <= 3) return new Date(year - 1, 12 - 1, 31);
  if (month < 5 || (month == 5 && date <= 15)) return new Date(year, 3 - 1, 31);
  if (month < 8 || (month == 8 && date <= 15)) return new Date(year, 6 - 1, 30);
  if (month < 11 || (month == 11 && date <= 15))
    return new Date(year, 9 - 1, 30);
  return new Date(year, 12 - 1, 31);
};

Array.prototype.contains = function contains<T>(this: Array<T>, v: T): boolean {
  return this.indexOf(v) >= 0;
};

// https://stackoverflow.com/questions/33908299/javascript-parse-a-string-to-date-as-local-time-zone
export function parseISOLocal(s: string | null | undefined) {
  if (s === null) return null;
  if (s === undefined) return undefined;
  const b = s.split(/\D/).map((v) => parseInt(v, 10));
  if (b.length === 3) return new Date(b[0], b[1] - 1, b[2]);
  return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
}

export function round(v: number | null | undefined, digits = 0) {
  if (v == null) return v;
  if (digits === 0) return Math.round(v);
  return Math.round(v * 10 ** digits) * 10 ** -digits;
}

export function roundToSigFig(v: number | null, digitsToAdd = 0) {
  if (v == null) return v;
  return round(v, getSigFig(v) + digitsToAdd);
}

// arr 은 소팅된 것 전제
function percentile(arr: number[], p: number) {
  // p is %
  if (arr.length === 0) throw new Error('array is empty');
  if (typeof p !== 'number') throw new TypeError('p must be a number');
  if (p <= 0) return arr[0];
  if (p >= 100) return arr[arr.length - 1];
  const index = (arr.length - 1) * p * 0.01;
  const lower = Math.floor(index);
  const upper = lower + 1;
  const weight = index % 1;
  if (upper >= arr.length) return arr[lower];
  return arr[lower] * (1 - weight) + arr[upper] * weight;
}

function fScore(
  minsc: number,
  maxsc: number,
  minx: number,
  maxx: number,
  x: number,
) {
  return minsc + ((x - minx) / (maxx - minx)) * (maxsc - minsc);
}

export function percentScore(univ: number[], tgts: number[], cut: number) {
  if (univ.length === 0) {
    return [];
  } // throw new Error('univ is empty');
  const u = [...univ].sort((a, b) => a - b);
  const v0 = u[0];
  const v05 = percentile(u, cut);
  const v95 = percentile(u, 100 - cut);
  const v100 = u[u.length - 1];
  return tgts.map((v) => {
    if (v < v05) return round(fScore(0, cut, v0, v05, v), 2);
    if (v > v95) return round(fScore(100 - cut, 100, v95, v100, v), 2);
    return round(fScore(cut, 100 - cut, v05, v95, v), 2);
  });
}

export const utc2local = (t: string | undefined, fmt: string) =>
  t ? new Date(t).toFormatString(fmt) : '';

export const utc2hm = (t: string | undefined) =>
  t ? new Date(t).toFormatString('HH:mm') : '';

export const utc2hms = (t: string | undefined) =>
  t ? new Date(t).toFormatString('HH:mm:ss') : '';

export const utc2hmsf = (t: string | undefined) =>
  t ? new Date(t).toFormatString('HH:mm:ss.fff') : '';

export const numComma = (n: number | undefined) => n?.toFixedWithComma(0);

export const nonZeroComma = (n: number | undefined) =>
  n === 0 ? '' : n?.toFixedWithComma(0);

// eslint-disable-next-line
export const cutName = (nm: string | undefined, len = 10) => {
  // 한글 기준, 알파벳은 두배 허용
  if (!nm) return '';
  let cumlen = 0;
  for (let i = 0; i < nm.length; i += 1) {
    cumlen += nm.charCodeAt(i) < 256 ? 1 : 2;
    if (cumlen >= len * 2 && i < nm.length - 1) {
      return `${nm.slice(0, i + 1)}...`;
    }
  }
  return nm;
};

export const cutMessage = (msg: string | undefined, len = 100) =>
  cutName(msg, len);

export const sumprod = (
  ar1: number[],
  ar2: number[],
  where?: (v1: number, v2: number) => boolean,
) => {
  if (ar1.length !== ar2.length) throw new RangeError();
  let sum = 0;
  for (let i = 0; i < ar1.length; i += 1) {
    if (!where || where(ar1[i], ar2[i])) sum += ar1[i] * ar2[i];
  }
  return sum;
};

export const weiAvg = (arr: number[] | undefined, wei: number[]) => {
  if (arr === undefined) return undefined;
  return sumprod(arr, wei) / wei.reduce((a, c) => a + c, 0);
};

export const parseBool = (val: string | null) => {
  const up = val?.toUpperCase().trim(); // excel 에서 붙여넣으면 \r 붙어서 trim
  if (up === 'TRUE') {
    return true;
  }
  if (up === 'FALSE') {
    return false;
  }
  return null;
};

export const unknownToString = (val: unknown) => {
  if (val == null) return '';
  return String(val);
};

export const clipRange = (val: number, min: number, max: number) =>
  Math.min(max, Math.max(min, val));

export const rescale = (
  val: number | null | undefined,
  threshold: number,
  emp: number,
  center = 0,
): number | null => {
  if (val == null) return null;
  const recentered = val - center;
  if (recentered > threshold) return (recentered - threshold) * emp;
  if (recentered < -threshold) return (recentered + threshold) * emp;
  return null;
};

export const gradationStyle = (
  norm: number | null,
  alpha = 0.7,
): React.CSSProperties => {
  if (norm == null) return {};
  const n = Math.round(norm);
  const backgroundColor =
    n > 0
      ? `rgba(255, ${255 - n}, ${255 - n}, ${alpha})`
      : `rgba(${255 + n}, ${255 + n}, 255, ${alpha})`;
  const color = n > 120 || n < -120 ? 'white' : undefined;
  return { backgroundColor, color };
};

export const switchExpr = <T extends string | number, TOut>(
  val: T,
  ...args: [T | ((v: T) => boolean), TOut][]
): TOut | undefined =>
  args.find((a) =>
    typeof a[0] === 'function' ? a[0](val) : a[0] === val,
  )?.[1];

export const ifesleExpr = <T>(
  ...args: [boolean | undefined, T][]
): T | undefined => args.find((a) => a[0])?.[1];

export type DiffRes<T extends { Id: number | string }> = {
  added: T[];
  changed: [number | string, keyof T, unknown, unknown][];
  removed: (number | string)[];
};

export function findDiff<T extends { Id: number | string }>(
  initData: T[],
  currData: T[],
  columns: (keyof T)[],
): DiffRes<T> {
  // 생성이 너무 느림
  // const initDic: { [key: number]: T } =
  //  initData.reduce((c, v) => ({ ...c, [v.Id]: v }), {});
  const initDic = new Map(initData.map((v) => [v.Id, v]));
  const changed: [number | string, keyof T, unknown, unknown][] = [];
  const added: T[] = [];
  currData.forEach((curr) => {
    const init = initDic.get(curr.Id);
    if (init) {
      columns.forEach((c) => {
        const [oldVal, newVal] = [init[c], curr[c]];
        if (oldVal !== newVal) {
          changed.push([curr.Id, c, oldVal, newVal]);
        }
      });
      initDic.delete(curr.Id);
    } else {
      added.push(curr);
    }
  });
  const stringId = initData.length && typeof initData[0].Id === 'string';
  const removed = [...initDic.keys()].map((v) => (stringId ? v : Number(v)));
  return { added, changed, removed };
}

export const mapValueToString = (o: { [key: string]: unknown }) =>
  _.mapValues(o, (v) => v?.toString() ?? '');

export function isArrayOfStrings(value: unknown): value is string[] {
  return (
    Array.isArray(value) && value.every((item) => typeof item === 'string')
  );
}

function isObject(o: unknown) {
  return o instanceof Object && !(o instanceof Array);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapObjEmptyStrToNull(o: any, dep = 0) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res: any = {};
  Object.keys(o).forEach((key) => {
    const v = o[key];
    if (typeof v === 'string' && v.trim() === '') res[key] = null;
    else if (isObject(v) && dep === 0)
      res[key] = mapObjEmptyStrToNull(v, dep + 1);
    else res[key] = v;
  });
  return res;
}

export function accumArr(arr: number[]) {
  const cumulativeSum: number[] = [];

  function execute(sum: number, element: number) {
    const nsum = sum + element;
    cumulativeSum.push(nsum);
    return nsum;
  }
  arr.reduce(execute, 0);
  return cumulativeSum;
}
