import React, { useEffect, useState } from 'react';
import { Calendar, CalendarType } from '../../Tms/Tools';
import './CalendarCore.scss';

// 사용되는 화면: 세미나, IPO, 기업이벤트(middle), Mezzanine(AI)
// Calendar 테이블에 저장되는 케이스(세미나,IPO,CmplAi) OR 아닌 케이스(CalendarType = Default)에 사용 가능
// 화면에 Calendar 만 그려주거나 Calender 내용을 입력/수정/삭제 까지만 한다면 CalendarBody 사용
// Calendar 에 저장되는데 위 타입이 아닌데 캘린더를 사용한다면 Calendar 형식으로 맞춰서 요청해서 item 넘기면 됨
// Default 인 경우 subTitle 과 subTitle Color 를 넘겨서 랜더링할때 css 적용가능
// Opts 값을 바꿔서 전체적인 크기 조정 가능 

export interface Opts {
  cellWidth: number;
  cellHeight: number;
  weekEndWidthRto: number;
}

// #region seminarUtil 
const getSeminarColor = (wk: string) => {
  switch (wk) {
    case "Sun":
      return "red";
    case "Sat":
      return "blue";
    default:
      return "black";
  }
}

export enum SeminarTy {
  VS = 'VS',
  IR = 'IR',
  SMR = 'SMR',
  NDR = 'NDR',
  AI = 'AI',
  Special = 'Special',
  Request = 'Request',
}

const seminarDic = {
  VS: { text: 'V', color: 'red' },
  IR: { text: 'I', color: 'blue' },
  SMR: { text: 'S', color: 'green' },
  NDR: { text: 'N', color: 'orange' },
  AI: { text: 'A', color: 'gray' },
  Special: { text: 'Sp', color: 'purple' },
  Request: { text: 'R', color: 'magenta' }
};

const seminarTyTemplate = (p: Calendar) => {
  const smr = seminarDic[p.smrTy]
  return (
    <span style={{ color: smr?.color ?? 'black', fontWeight: 'bold', margin: '2px' }}>
      {smr.text}
    </span>
  );
};

const dftTemplate = (p: Calendar) => {
  const color = p.subTitleColor;
  return (
    <span style={{ color: 'black', margin: '2px' }}>
      <span style={{ color: color ?? 'black', fontWeight: 'bold', marginRight: '3px' }}>[{p.subTitle}]</span>
      {p.memo}
    </span >
  );
}


// #endregion

const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

interface Props {
  ty: CalendarType;
  items: Calendar[];
  currItem: Calendar | null,
  setCurrItem?: React.Dispatch<React.SetStateAction<Calendar | null>>,
  setUCYear: React.Dispatch<React.SetStateAction<number | null>>, // setUppercomponentYear
  setUCMonth: React.Dispatch<React.SetStateAction<number | null>> // setUppercomponentMonth
}

export default function CalendarCore({ ty, items, currItem, setCurrItem: setCurrSmr, setUCYear, setUCMonth }: Props) {
  const linkClass = 'btn btn-link';
  const [year, setYear] = useState(new Date().getFullYear()); // year는 숫자그대로
  const [month, setMonth] = useState(new Date().getMonth()); // month 는 zero base 
  const opts: Opts = { cellWidth: 200, cellHeight: 100, weekEndWidthRto: 1 }
  let d = new Date(year, month, 1);

  const getym = (prev: boolean) => {
    if (month === 11 && !prev) {
      setYear(year + 1)
      setMonth(0)
    }
    else if (month === 0 && prev) {
      setYear(year - 1)
      setMonth(11)
    } else {
      setMonth(month + (prev ? -1 : 1))
    }
  }
  useEffect(() => {
    setUCYear(year)
    setUCMonth(month + 1)
  }, [year, month])

  return (

    <div >

      <div style={{ width: opts.cellWidth * (5 + opts.weekEndWidthRto * 2), textAlign: 'center', fontWeight: 'bold', fontSize: 'large' }}>
        <button className={linkClass} onClick={() => getym(true)} type='button'> prev </button>
        <span style={{ margin: 10 }}>{year}</span>
        <span>/</span>
        <span style={{ margin: 10 }}>{month + 1}</span>
        <button className={linkClass} onClick={() => getym(false)} type='button'> next </button>
      </div>

      <table>
        <thead key='calthead' >
          <tr>
            {weekdays.map((wd) => (
              <th key={`${wd}head`} style={{ color: getSeminarColor(wd), textAlign: 'center' }}>
                <span key={`${wd}headspan`} style={{ float: 'right', margin: '5px', fontSize: '12' }}>{wd}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[0, 1, 2, 3, 4, 5].map((r) => {
            if (d.getMonth() !== month) return (<> </>);
            return (
              <tr key={`Tr${r}`}>
                {weekdays.map((wd, i) => {
                  const blank = d.getDay() !== i && r === 0 || (r !== 0 && d.getMonth() !== month); // 첫 번째 줄과 마지막 줄 공백 
                  if (!blank) { d = d.addDays(1); }
                  return (
                    <td key={`${wd}${r}td`} className="caltd" style={{ color: getSeminarColor(wd) }}>
                      <div key={`${wd}${r}cell`}>{blank ? '' : d.addDays(-1).getDate()}</div>

                      {!blank && items != null && items.length > 0 && items.filter((item) => item.d === d.addDays(-1).toFormatString("yyyy-MM-dd")).map((item) =>
                        <div key={`calitem.${item.Id}`} className='calitem'
                          onClick={() => {
                            if (setCurrSmr != null) {
                              setCurrSmr(item)
                            }
                          }}
                          role="presentation"
                          style={{ cursor: 'pointer', backgroundColor: currItem?.Id === item.Id ? "yellow" : "" }}>

                          {[CalendarType.Seminar, CalendarType.SeminarSinga,].contains(ty) && (
                            <>
                              {seminarTyTemplate(item)}
                              <span> {item.hm !== undefined && item.hm}, {item.memo}</span>
                              <br />
                              <span>&nbsp;&nbsp;&nbsp;&nbsp;({item.callerNm}, {item.brkNm})</span>
                            </>
                          )}
                          {[CalendarType.CmplAi].contains(ty) && <span>[{item.prodNm}] {item.memo}</span>}
                          {ty === CalendarType.Default && dftTemplate(item)}
                        </div>
                      )
                      }
                    </td>
                  )
                })
                }
              </tr>
            )
          }
          )}
        </tbody>
      </table >
    </div >
  )
}



