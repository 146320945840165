import React, { useEffect, useState, useRef } from 'react';
import Editor from 'tmslib/src/ui/Editor';
import MultiCheckBox from 'tmslib/src/ui/MultiCheckBox';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { DftBtnStyleMx } from '../../AppTypes';
import {
  callAxios,
  callAxiosGet,
  downloadFile,
  getSplitFileNm,
  UrlGrid,
  UrlGridArgs,
  emptyGridArgs,
} from '../../tmsutil';
import {
  CmplCheckItem,
  CmplCheckCate0,
  CmplCheckCate,
  TargetType,
  CmplCheckFile,
  cmplCheckCate0Schema,
  cmplCheckCateSchema,
} from '../../Tms/MO';
import { permittedExtensions } from '../../Tms/Tms';
import FileUploadDialog from '../../shared/FileUploadDialog';

const page = '/Middle/CheckList';
const btnClass = DftBtnStyleMx;
type T = { Id: number }; // 임의 IId 타입
type PageFunc =
  | 'CmplCheckItem'
  | 'DelFile'
  | 'DelItem'
  | 'SaveItemTgts'
  | 'SaveItemDetail'
  | 'SaveItemInuse'
  | 'SaveItemCode';

// prettier-ignore
const cmplCheckItemDft: UrlGridArgs<CmplCheckItem> = {
  url: `${page}/CmplCheckItem`,
  title: '점검 항목',
  // prettier-ignore
  columns: ['code_', 'cate0', 'cate0Note', 'cate1', 'cateNote', 'cno', 'note', 'period', 'deadline', 'reportTo', 'regName', 'tgtsRepr', 'inuse', 'genT'],
  // prettier-ignore
  headers: ['전체코드', '대ID', '대분류', '중ID', '중분류', '순번', '점검항목', '주기', '기한', '보고처', '관련 법규', '적용대상', '사용', '생성일'],
  // prettier-ignore
  widths: { cate0: 40, cate1: 40, cateNote: 150, cno: 40, note: 1000, period: 50, deadline: 100, regName: 150 },
  editable: false,
  meta: {
    noVirtualize: true,
    dftColWidth: 80,
  },
  dftStyler: () => ({ textAlign: 'left', whiteSpace: 'pre' }),
  height: 500,
};

// prettier-ignore
const cmplCheckCate0: UrlGridArgs<CmplCheckCate0> = {
  url: `${page}/CmplCheckCate0`,
  title: '대분류',
  columns: ['Id', 'note'],
  headers: ['코드', '분류명'],
  widths: { note: 100 },
  editable: true,
  height: 700,
  schema: cmplCheckCate0Schema,
};

// prettier-ignore
const cmplCheckCate: UrlGridArgs<CmplCheckCate> = {
  url: `${page}/CmplCheckCate`,
  title: '중분류',
  columns: ['Id', 'note'],
  headers: ['코드', '분류명'],
  widths: { note: 200 },
  editable: true,
  height: 700,
  infoMsg: 'AY02 와 같이 대분류 코드도 포함',
  schema: cmplCheckCateSchema,
};

type Flds =
  | 'inuse'
  | 'tgts'
  | 'note'
  | 'deadline'
  | 'regName'
  | 'regDetail'
  | 'period';
type ChangedFlds = {
  func: PageFunc;
  fld: Flds;
};

let changedFlds: ChangedFlds[] = [];

export default function CheckList() {
  const { msgBox: m, logger } = useMessageState();
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);
  const [itemId, setItemId] = useState<number | null>(null);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const dataCnt = useRef(0);

  const [fileUploadVisible, setFileUploadVisible] = useState<boolean>(false);
  const [fileUploadTitle, setFileUploadTitle] = useState<string>('');
  const [fileUploadUrl, setFileUploadUrl] = useState<string>('');
  const [inuse, setInuse] = useState<boolean>(false);
  const [selected, setSelected] = useState<TargetType[]>([]);
  const [files, setFiles] = useState<CmplCheckFile[]>([]);
  const [note, setNote] = useState('');
  const [deadline, setDeadline] = useState('');
  const [regName, setRegName] = useState('');
  const [regDetail, setRegDetail] = useState('');
  const [showCode, setShowCode] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [period, setPeriod] = useState<string>('');

  const setDetail = (r: CmplCheckItem) => {
    setShowDetail(true);
    setShowCode(false);
    setItemId(r.Id);
    setInuse(r.inuse); // 사용여부
    setSelected(r.tgts.map((v) => v.tgt)); // 적용대상
    setFiles(r.files);
    setNote(r.note ?? ''); // 점검항목
    setDeadline(r.deadline ?? ''); // 기한
    setRegName(r.regName ?? ''); // 관련법규
    setRegDetail(r.regDetail ?? ''); // 법규상세
    setPeriod(r.period ?? ''); // 주기
  };

  const query = (args: unknown) => {
    setShowDetail(false);
    setShowCode(false);
    setGridArgs(emptyGridArgs);
    setRefreshNeeded((p) => p + 1);
    setGridArgs(args as UrlGridArgs<T>);
  };

  const getButton = (a: unknown) => {
    const { title } = a as UrlGridArgs<T>;

    return (
      <button
        type="button"
        className={btnClass}
        onClick={() => {
          query(a);
        }}
      >
        {title}
      </button>
    );
  };

  const call = (
    funcNm: PageFunc,
    params: unknown,
    args?: {
      callbackGridArgs?: unknown;
      confirmMsg?: string;
    },
  ) => {
    if (funcNm === 'SaveItemCode') {
      if (code.length < 7) {
        m.alert(`${code} 전체코드는 7자리 이상 입니다.`);
        return;
      }
    }
    callAxios({
      m,
      logger,
      url: `${page}/${funcNm}`,
      params,
      confirmMsg: args?.confirmMsg,
      onSuccess: () => {
        if (args?.callbackGridArgs) {
          query(args?.callbackGridArgs);
        }
        if (funcNm === 'SaveItemCode') {
          setShowCode(false);
        }
        if (funcNm === 'DelItem') {
          setItemId(null);
        }
      },
    });
  };

  const cmplCheckItem: UrlGridArgs<CmplCheckItem> = {
    ...cmplCheckItemDft,
    meta: {
      ...cmplCheckItemDft.meta,
      onRowClick: (r) => {
        setDetail(r.original);
      },
      contextMenus: [
        {
          label: '전체코드 삭제',
          callback: (items) =>
            call(
              'DelItem',
              { id: items[0].Id },
              {
                confirmMsg: '전체코드 삭제',
                callbackGridArgs: cmplCheckItem,
              },
            ),
        },
      ],
    },
    onDataChange: (data) => {
      // 그리드 데이터 삭제시 세부항목 reset
      if (dataCnt.current !== 0 && data.length < dataCnt.current) {
        setShowDetail(false);
      }
      dataCnt.current = data.length; // 현재값 업데이트
    },
  };

  const getFile = (funcNm: string, fnm: string | null) => {
    downloadFile({
      m,
      logger,
      url: `${page}/Download${funcNm}`,
      params: { fnm },
    });
  };

  const codeTemplate = () => (
    <span key="code">
      <label htmlFor="code">
        전체코드:
        <input
          type="text"
          id="code"
          value={code}
          size={10}
          onChange={(e) => setCode(e.target.value)}
        />
      </label>
      <button
        type="button"
        className="btn btn-secondary btn-sm position-relative"
        onClick={() =>
          call(
            'SaveItemCode',
            { code },
            {
              confirmMsg: '전체코드 입력',
              callbackGridArgs: cmplCheckItem,
            },
          )
        }
      >
        save
      </button>
    </span>
  );

  const tgtsTemplate = () => {
    const tgtsList = Object.values(TargetType).filter(
      (v) => v !== TargetType.PE,
    );
    return (
      <div className="children-me-2">
        <MultiCheckBox
          name="tgts"
          selected={selected}
          options={tgtsList}
          onChangeSelected={(sltd) => setSelected(sltd as TargetType[])}
        />
        <button
          type="button"
          className="btn btn-secondary btn-sm position-relative"
          onClick={() => {
            call(
              'SaveItemTgts',
              { id: itemId, tgts: selected },
              { callbackGridArgs: cmplCheckItem },
            );
          }}
        >
          Save
        </button>
      </div>
    );
  };

  const filesTemplate = files.map((v) => (
    <span key={v.fnm}>
      {getSplitFileNm(v.fnm)}
      <button
        type="button"
        className={btnClass}
        onClick={() => getFile('FileDownload', v.fnm)}
      >
        파일
      </button>
      <button
        type="button"
        className={btnClass}
        onClick={() =>
          call(
            'DelFile',
            { id: v.Id },
            {
              confirmMsg: '파일삭제',
              callbackGridArgs: cmplCheckItem,
            },
          )
        }
      >
        삭제
      </button>
    </span>
  ));

  const setChangedFldsCheck = (func: PageFunc, fld: Flds) => {
    const flds0 = changedFlds.map((v) => v.fld);
    if (!flds0.contains(fld)) {
      changedFlds.push({ func, fld });
    }
  };

  const saveItemDetailButton = () => (
    <button
      type="button"
      className="btn btn-secondary btn-sm position-relative"
      onClick={() => {
        changedFlds.forEach((v) => {
          let val;
          if (v.func === 'SaveItemDetail') {
            if (v.fld === 'note') val = note;
            if (v.fld === 'deadline') val = deadline;
            if (v.fld === 'regName') val = regName;
            if (v.fld === 'regDetail') val = regDetail;
            if (v.fld === 'period') val = period;
            call(v.func, { id: itemId, fld: v.fld, val });
          } else if (v.func === 'SaveItemInuse') {
            call(v.func, { id: itemId, inuse });
          }
        });
        if (changedFlds.length > 0) {
          query(cmplCheckItem); // callbackGridArgs 한번만 호출
          changedFlds = [];
        }
      }}
    >
      전체 저장
    </button>
  );

  const detailTemplate = () => (
    <>
      <hr className="narrow light" />
      <div id="detailContainer" className="row" style={{ width: 1200 }}>
        <div className="col">
          <hr className="narrow light" />
          <div>{filesTemplate}</div>
          <hr className="narrow light" />
          <button
            type="button"
            onClick={() => {
              setFileUploadVisible(true);
              setFileUploadTitle('파일추가');
              setFileUploadUrl(`${page}/UploadFileUpload`);
            }}
          >
            파일추가
          </button>
          <hr className="narrow light" />
          <div>
            <b>[적용 대상]</b>&nbsp;&nbsp;
            {tgtsTemplate()}
          </div>

          <hr className="narrow light" />
          <div>
            <b>[사용]</b>&nbsp;&nbsp;
            <label htmlFor="inuse">
              <input
                id="inuse"
                type="checkbox"
                checked={inuse}
                onChange={(e) => {
                  setChangedFldsCheck('SaveItemInuse', 'inuse');
                  setInuse(e.target.checked);
                }}
              />
              사용
            </label>
          </div>
          <hr className="narrow light" />
          <b>[점검 항목]</b>
          <div>
            <textarea
              value={note}
              onChange={(e) => {
                setChangedFldsCheck('SaveItemDetail', 'note');
                setNote(e.target.value);
              }}
              style={{ height: 200, width: 800 }}
            />
          </div>

          <hr className="narrow light" />
          <b>[주기]</b>
          <div>
            <textarea
              value={period}
              onChange={(e) => {
                setChangedFldsCheck('SaveItemDetail', 'period');
                setPeriod(e.target.value);
              }}
              style={{ height: 40, width: 200 }}
            />
          </div>

          <hr className="narrow light" />
          <b>[기한]</b>
          <div>
            <textarea
              value={deadline}
              onChange={(e) => {
                setChangedFldsCheck('SaveItemDetail', 'deadline');
                setDeadline(e.target.value);
              }}
              style={{ height: 80, width: 200 }}
            />
          </div>

          <hr className="narrow light" />
          <b>[관련 법규]</b>
          <div>
            <textarea
              value={regName}
              onChange={(e) => {
                setChangedFldsCheck('SaveItemDetail', 'regName');
                setRegName(e.target.value);
              }}
              style={{ height: 80, width: 200 }}
            />
          </div>

          <hr className="narrow light" />
          <b>[법규 상세]</b>
          <div>
            <Editor
              value={regDetail}
              onBlur={(html) => {
                setChangedFldsCheck('SaveItemDetail', 'regDetail');
                setRegDetail(html);
              }}
              style={{ height: 500 }}
            />
          </div>
          {saveItemDetailButton()}
        </div>
      </div>
    </>
  );

  useEffect(() => {
    query(cmplCheckItem);
  }, []);

  // 전체조회 id: null 개별 : id
  // 파일추가시 필요
  useEffect(() => {
    // 대분류, 중분류 선택시 조회 안되게 함
    if (gridArgs.title !== '점검 항목') return;
    if (itemId == null) return;
    callAxiosGet({
      m,
      logger,
      url: `${page}/CheckItem`,
      params: { id: itemId },
      onSuccess: (data) => {
        if (data) {
          if (itemId === data.Id) {
            setDetail(data);
          }
        }
      },
    });
  }, [refreshNeeded]);

  return (
    <div style={{ minWidth: '3000px' }} className="children-me-2">
      {[cmplCheckItem, cmplCheckCate0, cmplCheckCate].map((v) => getButton(v))}
      <button
        type="button"
        className={btnClass}
        onClick={() => setShowCode(true)}
      >
        전체코드입력
      </button>
      {showCode && codeTemplate()}
      <hr className="narrow light" />
      <div className="col-3">
        <UrlGrid args={gridArgs} params={{}} refreshNeeded={refreshNeeded} />
      </div>
      {showDetail && detailTemplate()}
      <FileUploadDialog
        headerTitle={fileUploadTitle}
        fileExtensionLimit={permittedExtensions}
        fileSizeLimit={5}
        params={{ id: itemId }}
        url={fileUploadUrl}
        visible={fileUploadVisible}
        setVisible={setFileUploadVisible}
        setRefreshNeeded={setRefreshNeeded}
      />
    </div>
  );
}
