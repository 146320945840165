import { z } from 'zod';
import {
  zodDate,
  zodNumber,
  zodPosiInt,
  zodRange,
  zodRangeNullable,
} from './ZodType';

export enum EtfPfTy {
  OrgRefPf = 'OrgRefPf', // 0, //[Description("TTM")]
  RefPf = 'RefPf', // 1, //[Description("TTML")]
  TFL = 'TFL', // 2, //[Description("TFL")]
  ETFL = 'ETFL', // 3, //[Description("ETFL")]
  ActivePf = 'ActivePf', // 4, //[Description("액티브")]
  BasketPf = 'BasketPf', // 5, //[Description("바스켓")]
  PassivePf = 'PassivePf', // 6, //[Description("지수추종")]
  FinalPf = 'FinalPf', // 7, //[Description("최종포트")]
  PrePdf = 'PrePdf', // 8, // [Description("1차PDF")] pdf 도 비중
  Pdf = 'Pdf', // 9, // [Description("PDF")] pdf 도 비중
  TFS = 'TFS', // 51, // [Description("TFS")] TFL 동일 로직의 숏
  CustETFUniv = 'CustETFUniv',
}

export type EtfPfWei = Record<EtfPfTy, number>;
export enum EtfPdfTy {
  None = 'None',
  Manual = 'Manual',
  EOD = 'EOD',
  Final = 'Final',
}
export const etfPdfTySchema = z.nativeEnum(EtfPdfTy);

export interface EtfPdf {
  Id: number;
  d: string;
  vhId: string;
  prodId: string;
  ty: EtfPdfTy;
  pos: number;
  prodNm: string | null;
}

export const etfPdfSchema = z.object({
  Id: z.string(),
  d: zodDate,
  vhId: z.string(),
  prodId: z.string(),
  ty: etfPdfTySchema,
  pos: zodPosiInt(false),
});

export interface MafsCashDiv {
  Id: string;
  d: string;
  fundCd: string;
  fundNm: string;
  krCd: string;
  prodNm: string;
  pos: number;
  div: number;
  divamt: number;
  errMsg: string | null;
  vhId: string | null;
  prodId: string | null;
}

export const mafsCashDivSchema = z.object({
  Id: z.string(),
  d: zodDate,
  fundCd: z.string(),
  fundNm: z.string(),
  krCd: z.string(),
  prodNm: z.string(),
  pos: zodPosiInt(false),
  div: zodNumber(false),
  divamt: zodNumber(false),
  errMsg: z.string().nullish(),
  vhId: z.string(),
  prodId: z.string(),
});

export interface KsdEtfSetupRdmpt {
  Id: number;
  d: string;
  vhId: string;
  brkId: string;
  acptNo: number;
  proc: number;
  job: number;
  prop: number;
  etfTy: number;
  etfCd: string;
  apCd: string;
  reqCu: number;
  stlD: string;
  stlNo: number;
  expdCash: number;
  needq: number;
  paidq: number;
  shortq: number;
  sgn: number;
}

export interface KsdEtfSrBsk {
  Id: number;
  d: string;
  srId: number;
  prodId: string;
  needq: number;
  paidq: number;
  exceptq: number;
  shortq: number;
  prodNm: string | null;
  sr: KsdEtfSetupRdmpt | null;
}

export interface LpDaily {
  Id: number;
  d: string;
  vhId: string;
  brkId: string;
  ch2: number;
  ch1: number;
  ch: number;
  cu: number;
  cuAdj: number;
  brkNm: string | null;
}

export interface BrkFeeStat {
  Id: number;
  brkId: string;
  brkNm: string;
  fee: number;
}

export enum ETFAstTy {
  Dom = 'Dom',
  US = 'US',
  Global = 'Global',
}
export const ETFAstTySchema = z.nativeEnum(ETFAstTy);

export interface PdfPar {
  Id: number;
  name: string;
  sltd: boolean;
  note: string;
  mstId: number;
  vhId: string | null;
  d0: string;
  RefVhId: string;
  MinMC: number;
  MinTA: number;
  TflWei: number;
  TfsWei: number;
  EtfCds: string | null;
  EtfWeis: string | null;
  FutCds: string | null;
  FutWeis: string | null;
  BasketId: string | null;
  EtfAstTy: ETFAstTy;
  isDom: boolean;
  IdxMembCnt: number | null;
  ActiveWei: number;
  CashWei: number;
  DelevThrd: number;
  DelevCash: number;
  ShortAdj: number;
  MaxEachWei: number;
  MinEachWei: number;
  MinSseWei: number | null;
  TiltEach: number;
  TiltSum: number;
  smallCapMinMC: number;
  maxWeiSmallCap: number;
  isEqwETFL: boolean;
  isEqwTF: boolean;
}

export const pdfParSchema = z.object({
  Id: z.number(),
  d0: z.string(),
  RefVhId: z.string(),
  EtfAstTy: ETFAstTySchema, // 자산구분
  MinMC: zodPosiInt(false), // 시총 (억)
  MinTA: zodPosiInt(false), // 거래대금(억)
  TflWei: zodRange(0, 1),
  TfsWei: zodRange(0, 1),
  EtfCds: z.string().nullish(),
  EtfWeis: z.string().nullish(),
  FutCds: z.string().nullish(),
  FutWeis: z.string().nullish(),
  BasketId: z.string().nullish(),
  IdxMembCnt: zodPosiInt(true),
  isEqwETFL: z.boolean(),
  isEqwTF: z.boolean(),
  ActiveWei: zodRange(0, 1),
  CashWei: zodRange(0, 1),
  DelevThrd: zodRangeNullable(0, 1), // 디레버리지 기준 (bbig서 sr < 0.1)
  DelevCash: zodRangeNullable(0, 1), // 디레버리지시 현금비중
  ShortAdj: zodRange(0, 1), // 숏종목조정
  MaxEachWei: zodRange(0, 100), // 종목한도(%)
  MinEachWei: zodRange(0, 100), // 최소한도(%)
  MinSseWei: zodRange(0, 100), // 종목별 최대 비중 (%)
  TiltEach: zodRange(0, 100), // %
  TiltSum: zodRange(0, 100), // %
  smallCapMinMC: zodNumber(false), // 캡 씌우는 최소시총 (억)
  maxWeiSmallCap: zodNumber(false), // 캡 씌우는 최소시총의 캡 (%)
});

export interface EtfUniv {
  Id: number;
  vhId: string;
  prodId: string;
  prodNm: string | null;
  d0: string;
  d1: string | null;
  isRepr: boolean;
  gics: string | null;
  sec: string | null;
  secNm: string | null;
}

export const etfUnivSchema = z.object({
  Id: z.string(),
  vhId: z.string(),
  prodId: z.string(),
  prodNm: z.string().nullish(),
  d0: z.string().nullish(),
  d1: z.string().nullish(),
  isRepr: z.boolean(),
  sec: z.string().nullish(),
  secNm: z.string().nullish(),
});

export interface EtfWeiAdj {
  Id: number;
  vhId: string;
  prodId: string;
  wei: number;
  d0: string | null;
  d1: string | null;
  prodNm: string | null;
}

export const etfWeiAdjSchema = z.object({
  Id: z.string(),
  vhId: z.string(),
  prodId: z.string(),
  prodNm: z.string().nullish(),
  d0: z.string().nullish(),
  d1: z.string().nullish(),
  wei: zodRange(0, 100),
});

export enum SectorType {
  None = 'None',
  TICS = 'TICS',
  TICS_JP = 'TICS_JP',
  MK = 'MK',
  KStock = 'KStock',
  GICS = 'GICS',
  BBIG = 'BBIG',
  CarbZero = 'CarbZero',
  KCulture = 'KCulture',
  SnP500 = 'SnP500',
  NASDAQ100 = 'NASDAQ100',
  HighDiv = 'HighDiv',
  KBio = 'KBio',
  GAI = 'GAI',
  GSD = 'GSD',
  GBIO = 'GBIO',
  GCT = 'GCT',
  KV = 'KV',
}

export const GetSectorName = (sec: SectorType) => {
  switch (sec) {
    case SectorType.BBIG:
      return 'BBIG';
    case SectorType.CarbZero:
      return '탄소중립';
    case SectorType.KCulture:
      return 'K컬쳐';
    case SectorType.KV:
      return '밸류업';
    case SectorType.HighDiv:
      return '고배당';
    case SectorType.KBio:
      return 'k바이오';
    case SectorType.GAI:
      return 'AI';
    case SectorType.GSD:
      return 'SD';
    case SectorType.GBIO:
      return 'GB';
    case SectorType.GCT:
      return 'GC';
    default:
      return SectorType.toString();
  }
};

export interface SecUniv {
  Id: number;
  ty: SectorType;
  sec: string;
  secNm: string | null;
  prodId: string;
  prodNm: string | null;
  d0: string | null;
  d1: string | null;
}

// export const sectorTypeSchema = z.nativeEnum(SectorType);

export const secUnivSchema = z.object({
  Id: z.string(),
  sec: z.string(),
  secNm: z.string().nullish(),
  prodId: z.string(),
  prodNm: z.string().nullish(),
  d0: z.string().nullish(),
  d1: z.string().nullish(),
});

export interface PfWeiItem {
  Id: number;
  prodId: string;
  prodNm: string | null;
  krCd: string | null;
  wei: number;
  weiPct: number;
  orgwei: number | null;
  orgweiPct: number | null;
  yw: number;
  weiPctCh: number;
  mc: number | null;
  ta: number | null;
  noStat: boolean;
  sec: string | null;
  secNm: string | null;
  secExtd: boolean;
  gics: string | null;
  trdWei: number | null;
  trdWeiPct: number | null;
  isPassive: boolean;
  prc: number | null;
  shortWei: number | null;
  reprId: number | null;
  isRepr: boolean;
  manualPos: number | null;
}

export interface PfWei {
  ty: EtfPfTy | null;
  Name: string | null;
  Desc: string | null;
  Info: string | null;
  WeiSum: number;
  SecTy: SectorType;
  Errors: string[];
  Items: PfWeiItem[];
  columns: string[] | null;
  headers: string[] | null;
}
