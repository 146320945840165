import React, { CSSProperties, useEffect } from 'react';

export interface Tab {
  header: string | JSX.Element;
  content: JSX.Element;
}
const dftHeadStyle: CSSProperties = {
  padding: '0.3rem 0.6rem',
  fontSize: '13px',
};
export default function TabView({
  tabs,
  activeIndex,
  onTabChange,
  headStyle,
}: {
  tabs: Tab[];
  activeIndex?: number;
  onTabChange?: (newIdx: number) => void;
  headStyle?: CSSProperties;
}) {
  const [currIdx, setCurrIdx] = React.useState(activeIndex ?? 0);
  const currTab = tabs[currIdx];
  return (
    <>
      <nav>
        <div className="nav nav-tabs">
          {tabs.map((t, i) => (
            <button
              key={`tab_${i}`}
              className={`nav-link ${i === currIdx ? 'active' : ''}`}
              style={{ ...dftHeadStyle, ...headStyle }}
              onClick={() => {
                setCurrIdx(i);
                if (i != currIdx) onTabChange?.(i);
              }}
            >
              {t.header}
            </button>
          ))}
        </div>
      </nav>
      <div className="p-2">{!!currTab && currTab.content}</div>
    </>
  );
}
