import { z } from 'zod';
import { VhGrpTy, StrgCate } from './Tms';
import { LS, EX } from './Common';
import { zodTime, zodDate } from './ZodType';

export type RiskLog = {
  Id: number;
  d: string;
  vhId: string;
  stId: string;
  parId: number;
  subjId?: string | null;
  subjNm?: string | null;
  stat: number | null;
  expdStat: number | null;
  itemStat: string;
  title: string;
  stateMsg?: string | null;
  violDays: number;
  ystdViolDays?: number | null;
  grace?: boolean;
  permitted?: boolean;
  permitRejected?: boolean;
  permitRqtd?: boolean;
  alertPars: string;
  violPars: string;
  level: number | null;
  expdLevel: number | null;
  ystdViolId?: number | null;
  reqPermit?: null;
  cate: StrgCate;
  note: string;
  alert: string;
  danger: string;
  pars?: string | null;
};

export enum OrderPermitType {
  None = 'None',
  주문금액과다 = '주문금액과다',
  종목한도초과 = '종목한도초과',
  넷한도초과 = '넷한도초과',
  공시한도초과 = '공시한도초과',
  FHF승인요 = 'FHF승인요',
  삼전한도초과 = '삼전한도초과',
  ETF한도초과 = 'ETF한도초과',
  해외주식지정가초과 = '해외주식지정가초과',
  초과반대방향취소 = '초과반대방향취소',
  롱한도초과 = '롱한도초과',
}
const orderPermitTypeSchema = z.nativeEnum(OrderPermitType);

export interface OrderPermit {
  Id: number;
  d: string;
  ostId: number;
  ty: OrderPermitType;
  reqT: string;
  reqNote: string | null;
  permit: boolean | null;
  cnfrmT: string | null;
  cnfrmNote: string | null;
  desc: string;
  stId: string | null;
  tgt: VhGrpTy | null;
  prodId: string | null;
  prodNm: string | null;
  ls: LS | null;
  ex: EX | null;
  sgn: number | null;
  wei: number | null;
  hm0: string | null;
  hm1: string | null;
  reqNm: string | null;
  cnfrmNm: string | null;
}

export interface OrderPermitBatch {
  Id: number;
  d: string;
  ty: OrderPermitType;
  stId: string;
  prodId: string;
  permit: boolean;
  t0: string;
  t1: string;
  cnfrmT: string | null;
  cnfrmNote: string | null;
  prodNm: string | null;
  cnfrmNm: string | null;
  tyNm: string;
}

export const orderPermitBatchSchema = z.object({
  d: zodDate,
  ty: orderPermitTypeSchema,
  stId: z.string(),
  prodId: z.string(),
  prodNm: z.string().nullish().readonly(),
  permit: z.coerce.boolean(),
  t0: zodTime,
  t1: zodTime,
  cnfrmNm: z.string().nullish().readonly(),
  note: z.string().nullish(),
  cnfrmT: z.string().nullish().readonly(),
});

export interface ViolPermit {
  Id: number;
  d: string;
  violId: number;
  msg: string | null;
  stId: string | null;
  reqT: string;
  reqNote: string | null;
  permit: boolean | null;
  cnfrmT: string | null;
  cnfrmNote: string | null;
  reqNm: string | null;
  cnfrmNm: string | null;
}

export enum TargetType {
  해당없음 = '해당없음',
  회계 = '회계',
  인사 = '인사', // 인사총무에서 인사 총무 분리
  총무 = '총무',
  운용지원 = '운용지원',
  마케팅 = '마케팅',
  준법지원 = '준법지원',
  대체지원 = '대체지원',
  고유운용 = '고유운용',
  PE = 'PE',
  ETF = 'ETF',
  전체부서 = '전체부서',
}
// const targetTypeSchema = z.nativeEnum(TargetType);

export interface CmplCheckTgt {
  Id: number;
  itemId: number;
  tgt: TargetType;
}

export interface CmplCheckFile {
  Id: number;
  itemId: number;
  fnm: string;
}

export interface CmplCheckItem {
  Id: number;
  cateId: string;
  cno: number;
  code: string;
  code_: string;
  cate0?: string | null;
  cate1?: string | null;
  cate0Note?: string | null;
  cateNote?: string | null;
  note?: string | null;
  inuse: boolean;
  tgtsRepr: string;
  period?: string | null;
  deadline?: string | null;
  reportTo?: string | null;
  regName?: string | null; // regulations name
  regDetail?: string | null; // regulations detail
  genT?: string | null;
  tgts: CmplCheckTgt[];
  files: CmplCheckFile[];
}

// 대분류
export interface CmplCheckCate0 {
  Id: string;
  note?: string | null;
}

// 중분류
export interface CmplCheckCate {
  Id: string;
  cate0Id: string;
  note?: string | null;
}

export const cmplCheckCate0Schema = z.object({
  Id: z.string(),
  note: z.string().nullish(),
});

export const cmplCheckCateSchema = z.object({
  Id: z.string(),
  cate0Id: z.string(),
  note: z.string().nullish(),
});

export interface CmplReportItemFile {
  Id: number;
  itemId: number;
  fnm: string;
}

export enum WorkType {
  해당사항없음 = '해당사항없음',
  당월해당사항없음 = '당월해당사항없음',
  준수 = '준수',
  처리 = '처리',
  처리예정 = '처리예정',
}

export interface CmplReportItem {
  Id: number;
  reportId: number;
  itemId: number;
  work?: WorkType | null; // 업무처리
  note?: string | null; // 비고
  t?: string | null; // 저장일시
  code_?: string | null;
  tgt?: TargetType | null;
  cate0Note?: string | null;
  cateNote?: string | null;
  itemNote?: string | null;
  period?: string | null;
  deadline?: string | null;
  reportTo?: string | null;
  regName?: string | null;
  regDetail?: string | null;
  files: CmplReportItemFile[];
  attached: boolean;
}

export interface CmplReport {
  Id: number;
  m: string;
  tgt: TargetType;
  writerId?: string | null; // 작성자
  writerNm?: string | null;
  writerT?: string | null; // 저장일시
  writerNote?: string | null;
  checkerId?: string | null; // 점검자 (부서장)
  checkerNm?: string | null;
  checkerT?: string | null; // 저장일시
  checkerNote?: string | null;
  cnfrmId?: string | null; // 승인자 (컴플)
  cnfrmNm?: string | null;
  cnfrmT?: string | null; // 저장일시
  cnfrmNote?: string | null;
  items: CmplReportItem[];
}

export interface LongShortTogether {
  Id: number;
  vhId: string;
  prodId: string;
  prodNm: string;
  l: string;
  s: string;
}

export interface NetExposure {
  Id: number;
  vhId: string;
  stId: string;
  exp: number;
}

export interface UStkFillPrc {
  Id: number;
  vhId: string;
  stId: string;
  prodId: string;
  prodNm: string;
  isBuy: string;
  b?: number | null;
  prc: number;
  vwap: number;
  rt: number;
}

export interface IpoLockup {
  Id: number;
  prodId: string;
  prodNm: string;
  vhId: string;
  d0: string;
  d1: string;
  qty: number;
  pos: number;
  diff: number;
}

export interface EtfDevCorr {
  Id: number;
  vhId: string;
  dev: number;
  devClose: number;
  corr: number;
}

export interface Ownership5 {
  Id: number;
  prodId: string;
  prodNm: string;
  stk: number;
  etf: number;
  asc: number;
  evt: number;
  mzn: number;
  wrnt: number;
  loan: number;
  holds: number;
  shares: number;
  holdRto: number;
  t1Rto: number;
  t2Rto: number;
  notiBd?: string | null;
  notiWei?: number | null;
  notiMemo?: string | null;
}

export interface Ownership10 {
  Id: number;
  prodId: string;
  prodNm: string;
  hgStk: number;
  aiStk: number;
  peStk: number;
  piStk: number;
  hgMzn: number;
  aiMzn: number;
  peMzn: number;
  piMzn: number;
  wrnt: number;
  etf: number;
  asc: number;
  tot: number;
  totRto: number;
  shares: number;
  vote: number;
  voteRto: number;
  voteAvail: number;
  isListed: string;
}

export interface ConflictGrid {
  Id: number;
  d: string;
  prodId: string;
  prodNm: string;
  hbq: number;
  hbp: number;
  hsq: number;
  hsp: number;
  abq: number;
  abp: number;
  asq: number;
  asp: number;
  c: number;
  vol: number;
  hpos: number;
  apos: number;
}

export interface MezRel {
  Id: number;
  prodId: string;
  prodNm: string;
  exch: string;
  stId: string;
  le: number;
  lx: number;
  se: number;
  sx: number;
  pos: number;
}

export interface UnfairRes {
  Id: number;
  d: string;
  prodId: string;
  prodNm: string;
  qty: number;
  vol: number;
  vol20: number;
  vw: number;
  buy: number;
  bw: number;
  sh: number;
  tvw: number;
  tbw: number;
}

export interface PreAllocRes {
  Id: number;
  vhId: string;
  prodId: string;
  prodNm: string;
  ls: LS;
  ex: EX;
  q2o: number;
  fq: number;
  alloc: number;
  q2oValid: string;
  fillAlloc: string;
}

export interface PreAllocStatRes {
  Id: number;
  ty: string;
  cnt: number;
  invalid: number;
}

export interface ShortCheckRes {
  Id: number;
  d: string;
  vhId: string;
  prodId: string;
  prodNm?: string | null;
  posMorn: number;
  loan: number;
  buy: number;
  sell: number;
  expd: number;
  mrgn: number;
  expd2: number;
}

export interface SsRightAvail {
  Id: number;
  d: string;
  vhId: string;
  prodId: string;
  prodNm?: string | null;
  d0: string;
  d1: string;
  le: number;
  lx: number;
  se: number;
  sx: number;
  // se_d1: string; 변수 형식 맞이 않음 화면에서 미사용
  validBuy: number;
  avail: boolean;
}

export interface KsdShortSell {
  Id: number;
  d: string;
  vhId: string;
  prodId: string;
  prodNm?: string | null;
  bt0: string;
  st0: string;
  preSell: boolean;
  bqty: number;
  sqty: number;
  overSell: boolean;
  checkHold: boolean;
  ypos: number;
  yloan: number;
  yhold: number;
  checkLoan: boolean;
  lt0: string;
  loan: number;
  checkTrade: boolean;
  checkRes: string;
}

export interface KsdTrnc {
  Id: number;
  t: string;
  trnc: string;
  sgdQty: number;
  pos: number;
  l2s: boolean;
}

export enum KrxTrncTy {
  장전잔고 = '장전잔고',
  매수주문 = '매수주문',
  매수취소 = '매수취소',
  매수체결 = '매수체결',
  매수리젝 = '매수리젝',
  일반매도주문 = '일반매도주문',
  일반매도취소 = '일반매도취소',
  일반매도리젝 = '일반매도리젝',
  차입공매도주문 = '차입공매도주문',
  차입공매도취소 = '차입공매도취소',
  차입공매도리젝 = '차입공매도리젝',
  차입 = '차입',
  상환 = '상환',
  장후잔고 = '장후잔고',
}

export interface KrxShortSell {
  Id: number;
  trnc: KrxTrncTy;
  ot: string;
  bft: string;
  oid: string;
  ooid: string;
  acctNo: string;
  oq: number;
  bfq: number;
  loant: string;
  loanq: number;
  shortAvail: number;
  d: string;
  vhId: string;
  prodId: string;
}

export interface VarRes {
  Id: number;
  vhId: string;
  na: number;
  drv: number;
  drvr: number;
  trs: number;
  trsr: number;
}
