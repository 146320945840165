import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import { DftBtnStyleMx } from '../../AppTypes';
import DateSelector from '../../shared/DateSelector';
import {
  callAxiosGet,
  UrlGrid,
  UrlGridArgs,
  emptyGridArgs,
  saveMemo,
} from '../../tmsutil';
import VhclSelector from '../../shared/VhclSelector';
import ProdSelector from '../../shared/ProdSelector';
import {
  ShortCheckRes,
  SsRightAvail,
  KsdShortSell,
  KsdTrnc,
  KrxShortSell,
} from '../../Tms/MO';
import { MemoTy } from '../../Tms/Tms';

const page = '/Middle/ShortCheck';
const btnClass = DftBtnStyleMx;
type T = { Id: number }; // 임의 IId 타입

const shortCheck: UrlGridArgs<ShortCheckRes> = {
  url: `${page}/ShortCheck`,
  title: '공매도 점검',
  // prettier-ignore
  columns: ['Id', 'd', 'vhId', 'prodId', 'prodNm', 'posMorn', 'loan', 'buy', 'sell', 'expd', 'mrgn', 'expd2'],
  // prettier-ignore
  headers: ['#', '날짜', '펀드', '종목코드', '종목명', '기보유', '차입', '매수', '매도', '당일예상1', '대용', '당일예상2'],
  widths: { Id: 40 },
  dftStyler: (v, c, r) => {
    if (
      (c === 'expd' && r.original.expd < 0) ||
      (c === 'expd2' && r.original.expd2 < 0)
    ) {
      return { backgroundColor: '#f99' };
    }
    return { backgroundColor: 'white' };
  },
  meta: {
    dftColWidth: 80,
  },
  height: 1000,
};

const ssRightAvail: UrlGridArgs<SsRightAvail> = {
  url: `${page}/SsRightAvail`,
  title: '유증 참여 가능',
  // prettier-ignore
  columns: ['d', 'vhId', 'prodId', 'prodNm', 'd0', 'd1', 'le', 'lx', 'se', 'sx', 'validBuy', 'avail'],
  // prettier-ignore
  headers: ['날짜', '펀드', '종목코드', '종목명', '시작일', '종료일', 'LE', 'LX', 'SE', 'SX', '참여매수', '참여가능'],
  widths: {},
  meta: {
    dftColWidth: 80,
  },
  height: 1000,
  checkboxes: ['avail'],
};

// warnning check
const ksdShortSellDft: UrlGridArgs<KsdShortSell> = {
  url: `${page}/KsdShortSell`,
  title: '예탁원 체결 기반 점검',
  // prettier-ignore
  columns: ['Id', 'd', 'vhId', 'prodId', 'prodNm', 'ypos', 'yloan', 'st0', 'bt0', 'preSell', 'sqty', 'bqty', 'overSell', 'checkLoan', 'lt0', 'loan', 'checkTrade', 'checkRes'],
  // prettier-ignore
  headers: ['#', '날짜', '펀드', '종목', '종목명', '전일보유', '전일대차', '첫매도T', '첫매수T', '선매도', '총매도', '총매수', '과매도', '대차확인요', '첫대차T', '당일대차', '매매확인요', '점검 결과'],
  // prettier-ignore
  widths: {Id: 40,	prodId: 60,	prodNm: 120,	st0: 120, bt0: 120, preSell: 40,	overSell: 40,	ypos: 60,	checkLoan: 60,	checkTrade: 60, checkRes: 120},
  meta: {
    dftColWidth: 60,
  },
  height: 800,
  checkboxes: ['preSell', 'overSell', 'checkLoan', 'checkTrade'],
};

const krxShortSell: UrlGridArgs<KrxShortSell> = {
  url: `${page}/KrxShortSell`,
  title: 'KRX 잔고 초과 매도',
  // prettier-ignore
  columns: ['d', 'vhId', 'ot', 'bft', 'oid', 'ooid', 'acctNo', 'trnc', 'oq', 'bfq', 'loant', 'loanq', 'shortAvail'],
  // prettier-ignore
  headers: ['날짜', '펀드', '주문시각', '매수체결시각', '주문번호', '원번호', '계좌', '구분', '주문량', '매수체결량', '대차거래시각', '차입/상환', '매도가능잔고'],
  // prettier-ignore
  widths: {oid: 100,	ooid: 100,	acctNo: 100,	trnc: 100},
  meta: {
    dftColWidth: 70,
  },
  height: 1000,
};

export default function ShortCheck() {
  const { msgBox: m, logger } = useMessageState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [d0, setD0] = useState(d);
  const vh = searchParams.get('vh') || 'ALL_FUNDS';

  const [negExpd, setNegExpd] = useState<boolean>(false);
  const [negExpd2, setNegExpd2] = useState<boolean>(false);
  const [prod, setProd] = useState<string | null>('A011200');
  const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [ksdTrnc, setKsdTrnc] = useState<KsdTrnc[]>([]);

  const putMemo = (o: KsdShortSell, oldMemo: string | null) =>
    saveMemo({
      m,
      logger,
      isDelete: false,
      memoParams: {
        d: o.d,
        ty: MemoTy.CmplKsdShort,
        vh: o.vhId,
        prod: o.prodId,
      },
      callback: () => setRefreshNeeded((p) => p + 1),
      oldval: oldMemo,
    });

  const clearArgs = () => {
    setKsdTrnc([]);
    setGridArgs(emptyGridArgs);
  };

  const query = (args: unknown) => {
    clearArgs();
    setRefreshNeeded((p) => p + 1);
    setGridArgs(args as UrlGridArgs<T>);
  };

  const ksdShortSell: UrlGridArgs<KsdShortSell> = {
    ...ksdShortSellDft,
    meta: {
      ...ksdShortSellDft.meta,
      onRowClick: (r) => {
        const row = r.original;
        callAxiosGet({
          m,
          logger,
          url: `${page}/KsdTrncs`,
          params: {
            d: row.d,
            vh: row.vhId,
            prod: row.prodId,
            ypos: row.ypos,
            yloan: row.yloan,
          },
          onSuccess: (data) => {
            setKsdTrnc(data);
          },
        });
      },
      contextMenus: [
        {
          label: '이상 없음',
          callback: (items) => putMemo(items[0], '이상 없음'),
          disabled: (items) => !items.some((v) => v.checkTrade),
        },
        {
          label: '직접 입력',
          callback: (items) => putMemo(items[0], null), // oldVal null 직접입력
          disabled: (items) => !items.some((v) => v.checkTrade), // checkTrade 당일대차
        },
      ],
    },
  };

  const getParams = (url: string) => {
    const func = url.split('/').pop();
    if (!func) return {};
    const dftParams = { d, d0, vhOrGrp: vh };
    if (func.isIn('ShortCheck')) {
      return { ...dftParams, negExpd, negExpd2 };
    }
    if (func.isIn('KrxShortSell')) {
      return { ...dftParams, prod };
    }
    return dftParams;
  };

  useEffect(() => {
    query(shortCheck);
  }, [d0, d, vh, negExpd, negExpd2]);

  return (
    <div className="children-me-2" style={{ minWidth: '1500px' }}>
      <DateSelector
        value={d}
        initDate0={d0}
        onChange={(date) => date !== d && setSearchParams({ d: date, vh })}
        onChangeD0={(date0) => setD0(date0)}
      />
      <VhclSelector
        d={d}
        meta
        all
        onChange={(vhcl) =>
          vhcl && vhcl.Id !== vh && setSearchParams({ d, vh: vhcl.Id })
        }
        value={vh}
      />
      &nbsp;&nbsp;
      <label htmlFor="negExpd">
        <input
          type="checkbox"
          checked={negExpd}
          id="negExpd"
          onChange={(e) => setNegExpd(e.target.checked)}
        />
        예상1 음수만
      </label>
      &nbsp;&nbsp;
      <label htmlFor="negExpd2">
        <input
          type="checkbox"
          checked={negExpd2}
          id="negExpd2"
          onChange={(e) => setNegExpd2(e.target.checked)}
        />
        예상2 음수만
      </label>
      <button
        type="button"
        className={btnClass}
        onClick={() => query(shortCheck)}
      >
        공매도 체크
      </button>
      &nbsp;&nbsp;
      <button
        type="button"
        className={btnClass}
        onClick={() => query(ssRightAvail)}
      >
        유증 참여 가능
      </button>
      <hr className="narrow light" />
      <button
        type="button"
        className={btnClass}
        onClick={() => query(ksdShortSell)}
      >
        예탁원 체결 기반 점검
      </button>
      &nbsp;&nbsp;|&nbsp;&nbsp; 종목:
      <ProdSelector
        onChange={(e) => {
          setProd(e?.Id ?? 'A011200');
        }}
      />
      <button
        type="button"
        className={btnClass}
        onClick={() => query(krxShortSell)}
      >
        KRX 잔고초과매도자료
      </button>
      <hr className="narrow light" />
      <div>
        <UrlGrid
          args={gridArgs}
          params={getParams(gridArgs.url)}
          refreshNeeded={refreshNeeded}
        />
      </div>
      {ksdTrnc.length > 0 && (
        <div>
          <SimpleGrid
            data={ksdTrnc as KsdTrnc[]}
            columns={['t', 'trnc', 'sgdQty', 'pos']}
            headers={['T', '구분', '수량', '잔고']}
            args={{
              widths: { t: 120 },
              meta: { dftColWidth: 100 },
              dftStyler: (v, c, r) => {
                if (r.original.trnc === '대차')
                  return { backgroundColor: 'yellow' };
                if (r.original.l2s) return { backgroundColor: 'orange' };
                return null;
              },
            }}
          />
        </div>
      )}
    </div>
  );
}
