import { z } from 'zod';
import { ApprovalState } from 'tmslib/src/domain/Approval';
import { VhGrpTy } from './Tms';
import { Sz, VentCate, ProdActionTy, RstrTy, rstrTySchema } from './Prod';
import { EtfPfWei } from './Etf';
import {
  Curncy,
  curncySchema,
  LS,
  lsSchema,
  PosBookTy,
  posBookTySchema,
  Period,
  periodSchema,
} from './Common';

import {
  zodNumber,
  zodPosiNumber,
  zodDate,
  zodDateNullable,
  zodPosiInt,
} from './ZodType';

export interface AllocDaily {
  Id: number;
  d: string;
  stId: string;
  allocDate: boolean;
  navReal: number;
  navRealYd: number;
  navAvg: number | null;
  allocPrc: number | null;
  tgtLev: number | null;
  tgtAlloc: number | null;
  noAlloc: boolean;
  manualLev: number | null;
  manualPfStd: number | null;
  finalLev: number | null;
  tgtPfStd: number | null;
  mngrCnfrm: boolean;
  cmplCnfrm: boolean;
  operCnfrm: boolean;
  errmsg: string | null;
  initPrin: number | null; 
}

export enum AllocDailyCfgTy {
  Nav = 'Nav',
  PfStd = 'PfStd',
}
export const allocDailyCfgTySchema = z.nativeEnum(AllocDailyCfgTy);

export interface AllocDailyCfg {
  Id: string;
  d0: string;
  d1: string;
  ty: AllocDailyCfgTy;
  lbPrin: number;
  ubPrin: number;
  lbTgt: number;
  ubTgt: number;
  allocVal: number;
}
export const allocDailyCfgchema = z.object({
  Id: z.string(),
  d0: zodDate,
  d1: zodDate,
  lbPrin: zodPosiInt(false),
  ubPrin: zodPosiInt(false),
  lbTgt: zodPosiInt(false),
  ubTgt: zodPosiInt(false),
  allocVal: zodNumber(false),
});

export interface BoAcctBS {
  Id: number;
  d: string;
  acctId: string;
  curncy: Curncy;
  balance: number;
}

export const boAcctBSSchema = z.object({
  Id: z.number(),
  d: zodDate,
  acctId: z.string(),
  curncy: curncySchema,
  balance: zodNumber(false),
});

export interface BoAcctFF {
  Id: number;
  d: string;
  acctId: string;
  flow: number;
}

export const boAcctFFSchema = z.object({
  Id: z.number(),
  d: zodDate,
  acctId: z.string(),
  flow: zodNumber(false),
});

export interface BoBS {
  Id: number;
  d: string;
  vhId: string;
  stId: string;
  prin: number;
  units: number;
  prft: number;
  cost: number;
  na: number;
  nav: number;
  rt: number;
  lad: number;
  sad: number;
  lprd: number;
  sprd: number;
  loan: number;
  fee: number;
  osFee: number | null;
  tax: number;
  ta: number | null;
  prftReal: number;
  navReal: number;
  rtReal: number;
  prftBookF: number;
  laf: number;
  saf: number;
  lprf: number;
  sprf: number;
  navAsia: number | null;
  chReal: number;
  chBook: number;
  _tgt: VhGrpTy | null;
  tgt: VhGrpTy | null;
  chDom: number | null;
  chRealF: number | null;
  chBookF: number | null;
  la: number;
  sa: number;
  lrtd: number;
  srtd: number;
  lrtf: number;
  srtf: number;
  yna: number;
  flow: number;
}

export interface BoCost {
  Id: number;
  d: string;
  vhId: string;
  book: PosBookTy;
  cost: number;
}

export const boCostSchema = z.object({
  Id: z.number(),
  d: zodDate,
  vhId: z.string(),
  book: posBookTySchema,
  cost: zodNumber(false),
});

export enum BoFFSource {
  Manual = 'Manual',
  MAFS = 'MAFS',
  EtfCu = 'EtfCu',
  Interim = 'Interim',
  TMS = 'TMS',
}

export const boFFSourceSchema = z.nativeEnum(BoFFSource);

export interface BoFF {
  Id: number;
  d: string;
  vhId: string;
  stId: string;
  src: BoFFSource;
  flow: number;
  isFirst: boolean;
}

export const boFFSchema = z.object({
  Id: z.number(),
  d: zodDate,
  vhId: z.string(),
  stId: z.string(),
  src: boFFSourceSchema,
  flow: zodNumber(false),
  isFirst: z.boolean(),
});

export interface BoLoanFee {
  Id: number;
  d: string;
  vhId: string;
  stId: string;
  fee: number;
}

export const boLoanFeeSchema = z.object({
  Id: z.number(),
  d: zodDate,
  vhId: z.string(),
  stId: z.string(),
  fee: zodNumber(false),
});

export enum Source {
  Manual = 0,
  MAFS = 11,
  EtfCu = 61,
  Interim = 71,
  TMS = 100,
}

export enum BoSource {
  Manual = 'Manual',
  KSD = 'KSD',
  SC = 'SC',
  SHSW = 'SHSW',
  EfXp = 'EfXp',
  Fix = 'Fix',
  ArsAlloc = 'ArsAlloc',
  EMSX = 'EMSX',
  EfXpRt = 'EfXpRt',
  Simul = 'Simul',
  EZE = 'EZE',
  RstrSwitch = 'RstrSwitch',
  ConvRight = 'ConvRight',
  AiSent = 'AiSent',
  PbsUpload = 'PbsUpload',
  AutoCorpAct = 'AutoCorpAct',
  FromProdDiv = 'FromProdDiv',
  EtfPdf = 'EtfPdf',
  TMS = 'TMS',
}
export const boSourceSchema = z.nativeEnum(BoSource);

export enum BoFillTy {
  Fill = 'Fill',
  CorpAct = 'CorpAct',
  RtT1 = 'RtT1',
  RollOver = 'RollOver',
  BlockDeal = 'BlockDeal',
  Reinv = 'Reinv',
  EtfPdf = 'EtfPdf',
  SODPos = 'SODPos',
}
export const boFillTySchema = z.nativeEnum(BoFillTy);

export enum PosRtTy {
  Regular = 'Regular',
  Book = 'Book',
  Real = 'Real',
}
export const posRtTySchema = z.nativeEnum(PosRtTy);

export enum BoStrgAlloc {
  Manual = 'Manual',
  OrdNo = 'OrdNo',
  OrdInst = 'OrdInst',
  AcctDft = 'AcctDft',
  SetOff = 'SetOff',
  AcctSpec = 'AcctSpec',
}
export const boStrgAllocSchema = z.nativeEnum(BoStrgAlloc);

export enum PosRstrTy {
  None = 'None',
  R = 'R',
  B = 'B',
  L = 'L',
  M = 'M',
  D = 'D',
  S = 'S',
  T = 'T',
  P = 'P',
  V = 'V',
  W = 'W',
  RV = 'RV',
  BV = 'BV',
  LV = 'LV',
  MV = 'MV',
  MW = 'MW',
  DV = 'DV',
  SV = 'SV',
  TV = 'TV',
  PV = 'PV',
}
export const posRstrTySchema = z.nativeEnum(PosRstrTy);

export interface BoCashDiv {
  Id: number;
  d: string;
  vhId: string;
  stId: string;
  prodId: string;
  book: PosBookTy;
  rstr: PosRstrTy;
  div: number;
  rt: PosRtTy;
  src: BoSource;
  userId: string;
  rcvd: string | null;
  prodNm: string | null;
  userNm: string | null;
}

export const boCashDivSchema = z.object({
  Id: z.number(),
  d: zodDate,
  vhId: z.string(),
  stId: z.string(),
  prodId: z.string(),
  book: posBookTySchema,
  rstr: posRstrTySchema,
  div: zodNumber(false),
  rt: posRtTySchema,
  src: boSourceSchema,
  userId: z.string().nullish().readonly(),
  rcvd: z.string().nullish(),
  prodNm: z.string().nullish().readonly(),
  userNm: z.string().nullish().readonly(),
});

export interface BoDirBS {
  Id: number;
  d: string;
  vhId: string;
  stId: string;
  prft: number;
  cost: number;
  units: number | null;
  na: number | null;
  taxna: number | null;
  prin: number | null;
  prin0: number | null;
  nav: number | null;
  taxnav: number | null;
  ta: number | null;
  liab: number | null;
  flow: number;
}

export interface BoKsdStkFill {
  Id: number;
  d: string;
  seq: number;
  resCd: string | null;
  dataTy: number;
  icCd: string | null;
  brkCd: string | null;
  dataNo: number;
  brnchCd: string | null;
  acctCd: string | null;
  secTy: number;
  mktTy: number;
  bsTy: number;
  ordTy: number;
  cd: string | null;
  ordNo: string | null;
  fillNo: number;
  qty: number;
  prc: number;
  ta: number;
  trdd: string;
  stld: string;
  trdt: string;
  deleted: boolean;
}

export const boKsdStkFillSchema = z.object({
  Id: z.coerce.number(),
  d: zodDate,
  deleted: z.boolean(),
});

export interface BoKsdFutFill {
  Id: number;
  d: string;
  seq: number;
  resCd: string | null;
  dataTy: number;
  icCd: string | null;
  fundCd: string | null;
  brkCd: string | null;
  brnchCd: string | null;
  acctCd: string | null;
  mktTy: number;
  bsTy: number;
  ordTy: number;
  ordNo: string | null;
  fillNo: number;
  cd: string | null;
  nm: string | null;
  qty: number;
  prc: number;
  ta: number;
  trdt: string;
  trdd: string;
  deleted: boolean;
}

export const boKsdFutFillSchema = z.object({
  Id: z.coerce.number(),
  d: zodDate,
  deleted: z.boolean(),
});

export interface BoFill {
  Id: number;
  d: string;
  vhId: string;
  acctId: string;
  prodId: string;
  isBuy: boolean;
  qty: number;
  prc: number;
  fee: number;
  tax: number;
  trdt: string;
  src: BoSource;
  ty: BoFillTy;
  ordNo: string | null;
  stId: string;
  stAlloc: BoStrgAlloc;
  ord: number | null;
  fillNo: string | null;
  prodNm: string | null;
}

export interface AlgoAcctFillOvr {
  Id: string; // uuid
  d: string;
  aacId: number;
  CumQty: number | null;
  AvgPx: number | null;
}

export interface BoFillAggr {
  Id: number;
  d: string;
  vhId: string;
  acctId: string;
  prodId: string;
  isBuy: boolean;
  /** @minimum 0 */
  qty: number;
  /** @minimum 0 */
  prc: number;
  /** @minimum 0 */
  fee: number;
  /** @minimum 0 */
  tax: number;
  src: BoSource;
  ty: BoFillTy;
  stId: string;
  stAlloc: BoStrgAlloc;
  ord: number | null;
  ls: LS;
  fillt: string | null;
  rt: PosRtTy;
  tmpId: string | null;
  rstr: PosRstrTy;
  userId: string;
  book: PosBookTy | undefined;
  prodNm: string | null;
  userNm: string | null;
  fromCrncy: string | null;
  avgPrc: number | null;
  avgFx: number | null;
  aprvState: ApprovalState | null;
}

export const boFillAggrSchema = z.object({
  d: zodDate,
  vhId: z.string(),
  acctId: z.string(),
  prodId: z.string(),
  isBuy: z.coerce.boolean(),
  qty: zodPosiNumber(false),
  prc: zodPosiNumber(false),
  fee: zodNumber(false), // 음수 가능
  tax: zodPosiNumber(false),
  src: boSourceSchema,
  ty: boFillTySchema,
  stId: z.string(),
  stAlloc: boStrgAllocSchema,
  ord: zodNumber(true),
  ls: lsSchema,
  fillt: z.string().nullish().readonly(),
  rt: posRtTySchema,
  rstr: posRstrTySchema,
  book: posBookTySchema.nullish().readonly(),
  prodNm: z.string().nullish().readonly(),
  userNm: z.string().nullish().readonly(),
  avgPrc: z.coerce.number().nonnegative().nullish().readonly(),
  avgFx: z.coerce.number().nonnegative().nullish().readonly(),
});

export interface BoPos {
  Id: number;
  d: string;
  vhId: string;
  stId: string;
  prodId: string;
  posYd: number;
  prcYd: number;
  bQty?: number;
  bPrc?: number;
  sQty?: number;
  sPrc?: number;
  close: number;
  div?: number;
  fee?: number;
  osFee?: number;
  tax?: number;
  pos: number;
  prft: number;
  prftVhCcy?: number | null;
  fxplVhCcy?: number | null;
  rt: PosRtTy;
  rstr: PosRstrTy;
  book: PosBookTy;
  closeFx?: number | null;
  avgPrc?: number | null;
  avgFx?: number | null;
  prodIdRstr: string;
  prodNmRstr: string;
  prodNm: string | null;
  underId: string | null;
  underNm: string | null;
  na: number | null;
  ta: number | null;
  units: number | null;
  wei: number | null;
  fx: number | null;
  bpv: number | null;
  amtVhCcy: number | null;
  mc: number | null;
  sh: number | null;
  mkta: number | null;
  ventCate: string | null;
  ls: string | null;
  noMTM: boolean;
  ctry: string | null;
}

export interface BoSettle {
  Id: number;
  d: string;
  vhId: string;
  stId: string;
  reinv: number;
  nav: number | null;
}

export const boSettleSchema = z.object({
  Id: z.number(),
  d: zodDate,
  vhId: z.string(),
  stId: z.string(),
  reinv: zodNumber(false),
  nav: zodNumber(true),
});

enum SingaStrg {
  Whole = 'Whole',
  SG_TMT = 'SG_TMT',
  SG_GCHINA = 'SG_GCHINA',
  SG_AOPS = 'SG_AOPS',
  SG_BH = 'SG_BH',
  SG_ETC = 'SG_ETC',
  SG_JPA = 'SG_JPA',
}
const SingaStrgSchema = z.nativeEnum(SingaStrg);

export const boSettleSchemaForSinga = z.object({
  Id: z.number(),
  d: zodDate,
  stId: SingaStrgSchema,
  nav: zodNumber(false),
});

export interface BoTrd {
  Id: number;
  ed: string;
  vhId: string;
  book: PosBookTy;
  prodId: string;
  eord: number;
  ep: number;
  pos: number;
  xd: string | null;
  xord: number | null;
  xp: number | null;
  plheld: boolean;
  resetD: string | null;
  resetP: number | null;
  resetP0: number | null;
  procD: string | null;
  prodNm: string | null;
  userId: string;
  userNm: string | null;
}

export const boTrdSchema = z.object({
  Id: z.number(),
  ed: zodDate,
  vhId: z.string(),
  book: posBookTySchema,
  prodId: z.string(),
  eord: zodNumber(false),
  ep: zodNumber(false),
  pos: zodNumber(false),
  xd: zodNumber(true),
  xord: zodNumber(true),
  xp: zodNumber(true),
  plheld: z.coerce.boolean().nullish(),
  resetD: z.string().nullish(),
  resetP: zodNumber(true),
  resetP0: zodNumber(true),
  procD: z.string().nullish(),
  prodNm: z.string().nullish(),
  userId: z.string().readonly(),
  userNm: z.string().nullish().readonly(),
});

export interface PosAmt extends BoPos {
  underOrProdId: string;
  sec: string | null;
}

export interface PosAmtVhCcy extends PosAmt {
  amtVhCcy: number;
}

export interface PosWei extends PosAmtVhCcy {
  wei: number;
}

export interface PosWeiOrder extends PosWei {
  currWeiOfMornPos: number;
  tgt: VhGrpTy;
  ls: LS;
  ordsSum?: number | null;
  tgtWei: number;
  ystdWei?: number | null;
  exitAll?: boolean;
  bPrgs?: string | null;
  sPrgs?: string | null;
}

export interface PosWeiTarget extends PosWeiOrder {
  beta?: number | null;
  sz: Sz;
  ret: number | null;
  grp: string | null;
  refWei?: number | null;
  refDiff?: number | null;
  etfSecNm?: string;
  offsetAtClose?: boolean;
  isDiffReady?: boolean;
  suspended?: boolean;
  // etf 추가
  etfPfWei?: EtfPfWei | null;
}

export interface SwapPlCmprRes {
  Id: number;
  vhId: string;
  book: PosBookTy;
  tmsPrft: number | null;
  tmsPos: number | null;
  memo: string | null;
  prftCh1: number | null;
  intrCh1: number | null;
  lfeeCh1: number | null;
  etcCh1: number | null;
  posSum1: number | null;
  csMrgn1: number | null;
  pthCh1: number | null;
  posDiff1: number;
  prftDiff1: number;
  prftCh2: number | null;
  expCh2: number | null;
  fxCh2: number | null;
  csMrgn2: number | null;
  posSum2: number | null;
  posDiff2: number;
  prftDiff2: number | null;
}

export interface RptPos {
  Id: number;
  prodId: string;
  prodNm: string;
  vent: VentCate;
  pos: number;
  close: number;
  amtVhCcy: number;
  mc: number | null;
  ta: number | null;
  wei: number;
}

export interface UFutPosLtd {
  Id: number;
  ty: string;
  days: number | undefined;
  prodId: string;
  prodNm: string;
  ltd: string;
  sts: string;
}

export interface HousePos {
  Id: number;
  d: string;
  prodId: string;
  shares: number | null;
  holds: number;
  shorts: number;
  loan: number;
  mzns: number;
  holdRto: number | null;
  shortRto: number | null;
  bq2o: number;
  stks: number;
  evts: number;
  swap: number;
  wrnts: number;
  mznConv: number;
  netShorts: number;
  netShortRto: number | null;
  voteCurr: number;
  voteCurrRto: number | null;
  totCurr: number;
  totCurrRto: number | null;
  votePoten: number;
  votePotenRto: number | null;
  totPoten: number;
  totPotenRto: number | null;
  prodNm: string | null;
  t1_rto: number | null;
  t2_rto: number | null;
}

export enum Types {
  결산 = '결산',
  재투자 = '재투자',
  매수 = '매수',
  매도 = '매도',
}

export interface FundTrnc {
  Id: number;
  ord: number;
  d: string;
  brkId: string;
  prodId: string;
  ty: Types;
  units: number;
  nav: number;
  cumUnits: number;
  prin: number;
  shwm: number;
  hwm: number;
  prft: number;
  perfFee: number;
  deferred: number;
  rdmptUnits?: number | null;
  rdmptFee?: number | null;
  dropNav: number;
  xRto: number;
  allocFeeRto: number;
  unitsForNav: number;
  initNav: number;
}

export const fundTrncTySchema = z.nativeEnum(Types);

// FundMgt
export interface AccruedAlloc {
  Id: number;
  prodId: string;
  nav: number;
  SS?: string | null;
  NH?: string | null;
  MA?: string | null;
  sum: number;
  drop: number;
}

export enum MrgnType {
  장외 = '장외',
  그외 = '그외',
  K200 = 'K200',
  Cash = 'Cash',
  채권 = '채권',
  차입증거금 = '차입증거금',
}

export const mrgnTypeSchema = z.nativeEnum(MrgnType);

export interface MrgnPos {
  Id: number;
  d: string;
  vhId: string;
  book: PosBookTy;
  prodId: string;
  pos: number;
  prodNm?: string | null;
  ty: MrgnType;
  rto: number;
  prc?: number | null;
  mrgn: number;
  sgdQty: number;
  h2: number;
  b1: number;
  s1: number;
  need_cncl: number;
  toCnclAmt: number;
  unavailTy?: ProdActionTy | null;
  avail: number;
  availAmt: number;
}

export interface PbsCreditAvail {
  Id: number;
  vhId: string;
  mrgns: MrgnPos[];
  mrgn: number;
  debt: number;
  expdShort0: number;
  toCnclAmt: number;
  expdShort: number;
  availAmt: number;
  expdNet: number;
  pbsAvail: number;
  finalAvail: number;
  pbs: string;
  alloc: boolean;
}

export interface PbsSvc {
  Id: number;
  d: string;
  vhId: string;
  brkId: string;
  creditAvail?: number | null;
  currDebt?: number | null;
  maxCredit?: number | null;
  allocCredit: boolean;
}

// PbsSvc
export const currDebtSvcSchema = z.object({
  vhId: z.string(),
  brkId: z.string(),
  currDebt: zodPosiNumber(false), // numericStringSchema(false),
  allocCredit: z.coerce.boolean(),
});

export const creditAvailSvcSchema = z.object({
  brkId: z.string(),
  creditAvail: zodPosiNumber(false).readonly(), // numericStringSchema(false).readonly(), readonly인데..
  maxCredit: zodPosiNumber(false), // numericStringSchema(false),
});

export const mrgnRtoSchema = z.object({
  d0: zodDate,
  d1: zodDate,
  book: posBookTySchema,
  ty: mrgnTypeSchema,
  rto: zodNumber(false),
});

export interface MrgnRto {
  Id: number;
  d0: string;
  d1: string;
  book: PosBookTy;
  ty: MrgnType;
  rto: number;
}

export interface MrgnCh {
  Id: number;
  d: string;
  vhId: string;
  book: PosBookTy;
  prodId: string;
  sgdQty: number;
  prodNm?: string | null;
  ty: MrgnType;
  rto: number;
  prc?: number | null;
  mrgn: number;
}

export const mrgnChSchema = z.object({
  vhId: z.string(),
  book: posBookTySchema,
  prodId: z.string(),
  prodNm: z.string().nullish().readonly(),
  sgdQty: zodNumber(false), // numericStringSchema(false),
  ty: mrgnTypeSchema,
  mrgn: zodNumber(false).readonly(), // numericStringSchema(false).nullish().readonly(),
});

export interface MrgnAvail {
  Id: number;
  vhId: string;
  prodId: string;
  prodNm?: string | null;
  h: number;
  b2: number;
  b1: number;
  b: number;
  buySum: number;
  pdfBuy: number;
  lo: number;
  mg: number;
  ksd: number;
  rdmpt: number;
  sellNotYet: number;
  recallNotYell: number;
  k200: boolean;
  isBond: boolean;
  ty: MrgnType;
  unavailTy?: ProdActionTy | null;
  info?: string | null;
  avail0: number;
  avail: number;
  cncl: number;
  offerAvail: number;
  recallNeeded: number;
  recallNeeded2: number;
  h2: number;
  s1: number;
}

export interface KsdCollateral {
  Id: number;
  vhId: string;
  rqrd: number;
  surplus: number;
  avail: number;
  cncl: number;
  rdmpt: number;
  rmndSurplus20: number;
  rmndSurplus13: number;
}

export interface KsdMrgnPos {
  Id: number;
  d: string;
  vhId: string;
  prodId: string;
  pos0: number;
  upq: number;
  dnq: number;
  pos: number;
  prodNm?: string | null;
}

export interface MrgnPosCmpRes {
  Id: number;
  vhId: string;
  prodId: string;
  prodNm?: string | null;
  tms: number;
  ksd: number;
}

export enum ChartTy {
  None = 'None',
  TaxNav = 'TaxNav',
  DailyNav = 'DailyNav',
  PieChart = 'PieChart',
  StyleSize = 'StyleSize',
  StyleGrowth = 'StyleGrowth',
}
export const chartTySchema = z.nativeEnum(ChartTy);

export interface DailyCfg {
  Id: number;
  ty: ChartTy;
  prd?: Period | null;
  line?: string | null;
  ord?: string | null;
  vhIn?: string | null;
  stIn?: string | null;
  memo?: string | null;
  vhId?: string | null;
  taxnavcls?: string | null;
  title?: string | null;
}

export const dailyCfgSchema = z.object({
  ty: chartTySchema,
  prd: periodSchema.nullish(),
  line: z.string().nullish(),
  ord: z.string().nullish(),
  vhIn: z.string().nullish(),
  stIn: z.string().nullish(),
  title: z.string().nullish(),
  memo: z.string().nullish(),
  vhId: z.string().nullish(),
  taxnavcls: z.string().nullish(),
});

export const dailyCfgTaxNavSchema = z.object({
  vhId: z.string(),
  taxnavcls: z.string(),
  memo: z.string().nullish(),
});

export interface ProdRstr {
  Id: number;
  d: string;
  prodId: string;
  prodNm?: string | null;
  rstr: RstrTy;
  rto?: number | null;
  prc?: number | null;
  listD: string;
  wrntCd?: string | null;
  wrntD0?: string | null;
  wrntD1?: string | null;
}

export const prodRstrSchema = z.object({
  d: zodDate,
  prodId: z.string(),
  prodNm: z.string().nullish().readonly(),
  rstr: rstrTySchema,
  rto: zodNumber(true),
  listD: zodDate,
  prc: zodNumber(true),
  wrntCd: zodDateNullable,
  wrntD0: zodDateNullable,
  wrntD1: zodDateNullable,
});

export interface ProdRstrCodeMap {
  Id: number;
  prodId: string;
  prodNm?: string | null;
  rstr: RstrTy;
  mafs?: string | null;
  mafs2?: string | null;
  mafs3?: string | null;
}

export const prodRstrCodeMapSchema = z.object({
  prodId: z.string(),
  prodNm: z.string().nullish().readonly(),
  rstr: rstrTySchema,
  mafs: z.string(),
  mafs2: z.string().nullish(),
  mafs3: z.string().nullish(),
});

export interface ConvRight {
  Id: number;
  d: string;
  vhId: string;
  stId: string;
  sProdId: string;
  rstr: PosRstrTy;
  oddLotAmt: number;
  convQty: number;
  bProdId: string;
  afQty: number;
  rstrId?: number | null;
  listD?: string | null;
  updt: string;
  userId: string;
  underPrc?: number | null;
  userNm?: string | null;
  bProdNm?: string | null;
  sProdNm?: string | null;
  isDone: boolean;
}

export const convRightSchema = z.object({
  d: zodDate,
  vhId: z.string(),
  stId: z.string(),
  sProdId: z.string(),
  sProdNm: z.string().readonly(),
  rstr: posRstrTySchema,
  convQty: zodNumber(false),
  oddLotAmt: zodNumber(false),
  bProdId: z.string(),
  bProdNm: z.string().nullish().readonly(),
  underPrc: zodNumber(true),
  afQty: zodNumber(false),
  listD: zodDateNullable,
  isDone: z.boolean().nullable().readonly(),
});

export interface RstrPos {
  Id: number;
  d0: string;
  rlsd: string;
  vhId: string;
  stId: string;
  prodId: string;
  book: PosBookTy;
  rstr: PosRstrTy;
  ls: LS;
  qty: number;
  userId: string;
  memo?: string | null;
  prodNm?: string | null;
  userNm?: string | null;
  f0eId?: number | null;
  f0xId?: number | null;
  f1eId?: number | null;
  f1xId?: number | null;
  prc0?: number | null;
  prc1?: number | null;
  f0xCd?: string | null;
  f0xRstr?: PosRstrTy | null;
  exitOnD0: boolean;
  f1eCd?: string | null;
  f1eRstr: PosRstrTy | null;
  errMsg?: string | null;
}

export const rstrPosSchema = z.object({
  d0: zodDate,
  rlsd: zodDate,
  vhId: z.string(),
  stId: z.string(),
  prodId: z.string(),
  prodNm: z.string().nullish().readonly(),
  book: posBookTySchema,
  rstr: posRstrTySchema,
  ls: lsSchema,
  qty: zodNumber(false),
  userNm: z.string().nullish().readonly(),
  memo: z.string().nullish(),
  errMsg: z.string().nullish(),
  exitOnD0: z.boolean(),
  f0xCd: z.string().nullish(),
  f0xRstr: posRstrTySchema.nullish(),
  f0xId: z.coerce.number().nullish().readonly(),
  f0eId: z.coerce.number().nullish().readonly(),
  prc0: zodNumber(true),
  f1xId: z.coerce.number().nullish().readonly(),
  prc1: zodNumber(true),
  f1eCd: z.string().nullish(),
  f1eRstr: posRstrTySchema.nullish(),
  f1eId: z.coerce.number().nullish().readonly(),
});

export enum RstrPosFillType {
  None = 'None',
  F0E = 'F0E',
  F0X = 'F0X',
  F1E = 'F1E',
  F1X = 'F1X',
}

export interface BoSwapPl2 {
  Id: number;
  d: string;
  vhId: string;
  book: PosBookTy;
  unrzPl: number;
  cumRzPl: number;
  totPl: number;
  cumExp: number;
  cumFx: number;
  resetExp: number;
  csMrgn: number;
  cnclAvail: number;
  posSum: number;
}

export const boSwapPl2Schema = z.object({
  vhId: z.string(),
  unrzPl: zodNumber(false),
  cumRzPl: zodNumber(false),
  totPl: zodNumber(false),
  cumExp: zodNumber(false),
  cumFx: zodNumber(false),
  resetExp: zodNumber(false),
  csMrgn: zodNumber(false),
  cnclAvail: zodNumber(false),
  posSum: zodNumber(false),
});

export interface InterimFill {
  Id: number;
  vhId: string;
  sgn: string;
  tkr: string;
  qty: number;
  prc: number;
  tmpId: string;
}

export const interimFillSchema = z.object({
  sgn: z.string(),
  tkr: z.string(),
  qty: zodNumber(false),
  prc: zodNumber(false),
  tmpId: z.string().nullish(),
});

export interface FillAlloc {
  Id: number;
  vhId: string;
  ls: string;
  ex: string;
  tkr: string;
  oQty: number;
  fQty: number;
  prc: number | null;
  prodId: string;
}

export enum SwapPbsCdTy {
  Unknown,
  New,
  LateNew,
  Hold,
}
export interface SwapPbsCd {
  Id: number;
  d: string;
  t: string;
  brkId: string;
  ty: SwapPbsCdTy;
  cd: string;
  brkNm: string | null;
}

export enum Statistics {
  PfStdOld = 'PfStdOld',
  RtStd = 'RtStd', // 수익률 표준편차
  PfBetaP = 'PfBetaP',
  PfBetaQ = 'PfBetaQ',
  TurnOverA = 'TurnOverA',
  NetAdj = 'NetAdj',
  MktCap = 'MktCap',
  MktCapAdj = 'MktCapAdj',
  PBR = 'PBR',
  PER = 'PER',
  ExitDays = 'ExitDays',
  ExitDays1K = 'ExitDays1K',
  PfStd2 = 'PfStd2', // 포트폴리오 표준편차
  Rt = 'Rt',
  TopNetSec = 'TopNetSec',
  TopStock = 'TopStock',
  TopStockSmall = 'TopStockSmall',
  RtReal = 'RtReal',
  DrvVaR = 'DrvVaR', // 공시용 파생상품 (장내 + trs) 위험평가액  // 10일, 99%
  DrvVaRrt = 'DrvVaRrt', // 공시용 파생상품 위험평가액 / 순자산 (%)
  TrsVaR = 'TrsVaR', // TRS 파생상품 위험평가액                 // 10일, 99%
  TrsVaRrt = 'TrsVaRrt', // TRS 파생상품 위험평가액 / 순자산 (%)
  RtStdReal = 'RtStdReal',
}

export enum BoStatPeriod {
  None = 'None',
  Y1 = 'Y1',
  M6 = 'M6',
  M3 = 'M3',
  M1 = 'M1',
}

export const boStatPeriodSchema = z.nativeEnum(BoStatPeriod);

export interface BoStat {
  Id: number;
  d: string;
  vhId: string;
  stId: string;
  ls: LS;
  stat: Statistics;
  prdDays: Period;
  val?: number | null;
}

export interface FundLiq {
  Id: number;
  d: string;
  vhId: string;
  na: number;
  depositYd: number;
  repoBuy: number;
  stkTrdY2: number;
  stkTrdY1: number;
  bond2stl: number;
  ipoPay: number;
  feePay: number;
  liq0: number;
  cashBuy: number;
  cashSell: number;
  swapLE: number;
  swapSE: number;
  fundBuy: number;
  trd: number;
  inFlow: number;
  outFlow: number;
  flow: number;
  credit: number;
  liqAdj: number;
  liq: number;
  liqRt: number;
}

export interface KsdSetup {
  Id: number;
  d: string;
  vhId: string;
  proc: number;
  regul: number;
  krCd: string;
  crncy: string;
  dstrCd: string;
  selfAmt: number;
  invAmt: number;
  totAmt: number;
  regNo: number;
  setupD: string;
  approved: boolean;
}

export const ksdSetupSchema = z.object({
  d: zodDate,
  vhId: z.string(),
  proc: zodNumber(false),
  regul: zodNumber(false),
  krCd: z.string(),
  crncy: z.string(),
  dstrCd: z.string(),
  selfAmt: zodNumber(false),
  invAmt: zodNumber(false),
  totAmt: zodNumber(false),
  regNo: zodNumber(false),
  setupD: zodDate,
});

export interface KsdRdmpt {
  Id: number;
  d: string;
  vhId: string;
  proc: number;
  ty: number;
  regul: number;
  krCd: string;
  crncy: string;
  timing: number;
  dstrCd: string;
  amtTy: number;
  selfQty: number;
  invQty: number;
  totQty: number;
  selfAmt: number;
  invAmt: number;
  totAmt: number;
  fixD: string;
  payD: string;
  regNo: number;
  approved: boolean;
}

export const ksdRdmptSchema = z.object({
  d: zodDate,
  vhId: z.string(),
  proc: zodNumber(false),
  ty: zodNumber(false),
  regul: zodNumber(false),
  krCd: z.string(),
  crncy: z.string(),
  timing: zodNumber(false),
  dstrCd: z.string(),
  amtTy: zodNumber(false),
  selfQty: zodNumber(false),
  invQty: zodNumber(false),
  totQty: zodNumber(false),
  selfAmt: zodNumber(false),
  invAmt: zodNumber(false),
  totAmt: zodNumber(false),
  fixD: zodDate,
  payD: zodDate,
  regNo: zodNumber(false),
});

export interface BoLiqAdj {
  Id: number;
  d: string;
  vhId: string;
  flow: number;
  note: string | null;
}

export const boLiqAdjSchema = z.object({
  d: zodDate,
  vhId: z.string(),
  flow: zodNumber(false),
  note: z.string().nullish(),
});

export interface IPMSPos {
  Id: number;
  Date: string;
  hmStr: string;
  vhId: string;
  prodId: string;
  NetPosition: string; // YstdNet + NonFixFillSum + OrderCumSum;
  Borrowed: number; // YstdBorrowed + TodayBorrowed;
  MaxAdditoinalSaleQty: string; // 최대 매도 수량
  Saleable: number; // 매도가능수량

  YstdNet: number; // 전일잔고
  NonFixFillSum: number; //
  OrderCumSum: number;
  YstdBorrowed: number;
  TodayBorrowed: number;
}
/*
export enum EventType {
  OrderEvent = 'OrderEvent', // 200번대
  OrderRequested = 'OrderRequested', // 210번대
  OrderResponse = 'OrderResponse', // 230번대
  EndOfYstdPosition = 'EndOfYstdPosition', // 전일 포지션
  NonFixFill = 'NonFixFill', // 픽스 안타는 포지션 이벤트
  NewOrderRequested = 'NewOrderRequested', // 주문 전송
  OrderReplaceRequested = 'OrderReplaceRequested', // 주문 전송
  OrderCancelRequested = 'OrderCancelRequested', // 주문 전송
  ExecutionReport = 'ExecutionReport', // 주문 응답
  CancelRejected = 'CancelRejected', // 주문 응답
  LoanTransaction = 'LoanTransaction', // 대차
}
*/
export interface PositionEvent {
  Id: string;
  Date: string;
  hmStr: string;
  vhId: string;
  prodId: string;
  EventTypeDesc: string;
  DataDesc: string;
}
