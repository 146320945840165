import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useSearchParams } from 'react-router-dom';
import { useAuthState } from '../Auth/AuthContext';
import { DftBtnStyleMx } from '../../AppTypes';
import DateSelector from '../../shared/DateSelector';
import { UrlGrid, UrlGridArgs, emptyGridArgs } from '../../tmsutil';
import { ProdUserDaily, prodUserDailySchema } from '../../Tms/Prod';
import { FundRt } from '../../Tms/MkFunds';

const page = '/Fund/RtPrd';
const btnClass = DftBtnStyleMx;

const userNav: UrlGridArgs<ProdUserDaily> = {
  url: `${page}/UserNav`,
  title: '가정산기준가',
  // prettier-ignore
  columns: ['d', 'prodId', 'prodNm', 'val', 'keep', 'userNm'],
  // prettier-ignore
  headers: ['날짜', '종목코드', '종목명', '마감 가격','익일유지', '입력자'],
  widths: { prodNm: 200, keep: 80 },
  editable: true,
  meta: { dftColWidth: 100 },
  height: 500,
  checkboxes: ['keep'],
  schema: prodUserDailySchema,
};

// prettier-ignore
const columnsDft: (keyof FundRt)[] = ["Id", "nm", "nav", "d1rt", "d5rt", "m1rt", "m3rt", "m6rt", "y1rt", "ytdrt", "na", "taxnav"]
// prettier-ignore
const headersDft = ["TMS", "펀드명/전략", "기준가", "1D", "1W", "1M", "3M", "6M", "1Y", "YTD", "AUM(억)" ,"과표기준가"]
// prettier-ignore
const columnsAiDft: (keyof FundRt)[] = ["aigrpty", "Id",  "nm", "nav", "d1rt", "d5rt", "m1rt", "m3rt", "m6rt", "y1rt", "ytdrt", "na", "taxnav" ]
// prettier-ignore
const headersAiDft = ["AI그룹",  "TMS","펀드명/전략", "기준가", "1D", "1W", "1M", "3M", "6M", "1Y", "YTD", "AUM(억)" ,"과표기준가"]
const auxColumnsDft = [
  [...columnsDft],
  [...columnsDft],
  [...columnsDft],
  [...columnsDft],
  [...columnsAiDft], // 대체투자펀드
  [...columnsDft],
];
const auxHeadersDft = [
  [...headersDft],
  [...headersDft],
  [...headersDft],
  [...headersDft],
  [...headersAiDft], // 대체투자펀드
  [...headersDft],
];

// prettier-ignore
const fundRtDft: UrlGridArgs<FundRt> = {
  url: `${page}/FundRtsSummary`,
  title: '',
  columns: [],
  headers: [],
  widths: {},
  meta: {
    dftColWidth: 55,
    useGlobalFilter: false,
    theadStyle: {},
  },
  height: undefined,
  rowSpanFns: {
    aigrpty: (r0, r1) => r0.original.aigrpty === r1.original.aigrpty,
    Id: (r0, r1) => r0.original.Id === r1.original.Id,
  },
  dftStyler: (v, c, r) => {
    const fontSize = '12px'
    const height = 23
    // TODO rowSpanFns 칼렁은 작동 되지 않음
    let borderBottom : string | undefined;
    // const borderBottom = r.original.islast === true ? '1px solid gray' : undefined
    const textAlign = c.isIn('aigrpty', 'Id','nm') ? 'left' : 'right';
    const fontWeight = c.isIn('nav', 'd1rt')
      ? '550'
      : undefined;
    let backgroundColor: string | undefined;
    if(c.isIn('na', 'nav')) backgroundColor = 'rgba(255, 255, 170, 0.75)'
    if(r.original.Id === 'Sum') backgroundColor = 'rgba(221, 255, 221,0.75 )'
    // 대체투자 c === 'aigrpty' None -> Sum
    if(r.original.aigrpty === 'None' && r.original.d1rt === null) backgroundColor = 'rgba(221, 255, 221,0.75 )'

    let color: string | undefined;
    if (['d1rt', 'd5rt', 'm1rt','m3rt','m6rt','y1rt','ytdrt'].indexOf(c) >= 0) {
      color = v?.getSignColor()
    }
    return { fontSize, height, borderBottom, textAlign, fontWeight, backgroundColor, color };
  },
  auxColumns: [],
  auxHeaders: [],
  auxGridArgs: [],
};

const fundRtIsMngr: UrlGridArgs<FundRt> = {
  ...fundRtDft,
  title: '1. 주식운용본부',
  // prettier-ignore
  columns: ["Id", "nav", "d1rt", "d5rt", "m1rt", "m3rt", "m6rt", "y1rt", "ytdrt", "na"],
  // prettier-ignore
  headers: ["전략", "기준가", "1D", "1W", "1M", "3M", "6M", "1Y", "YTD", "AUM(억)"],
  // prettier-ignore
  widths: { Id: 120, nm: 150, nav: 90, na: 90 },
  auxColumns: [],
  auxHeaders: [],
  auxGridArgs: [
    { title: '2. 주식 주니어' },
    {
      title: '3. 싱가폴 펀드',
      footNote:
        '*SINGA(KRW)는 펀드 내 기여성과, SINGA(USD) 수익률은 싱가폴 자체 펀드 성과임',
    },
    { title: '4. 자문전략' },
    { title: '5. 대체투자전략' },
    { title: '6. 헤지펀드 대체투자본부 기준가' },
  ],
  dftFormatter: (v, c, r) => {
    if (v === -100) return '-';
    if (c === 'nm') {
      // prettier-ignore
      return r.original.nm.replace("타임폴리오","").replace("전문투자형사모투자신탁","").replace("사모투자신탁","").replace("(전문투자자)","").replace("전문","").replace("대체","")
              .replace("투자","").replace("Active","").replace("일반","").replace("코스닥벤처","")
    }
    // prettier-ignore
    if (['d1rt', 'd5rt', 'm1rt','m3rt','m6rt','y1rt','ytdrt', 'nav', 'na'].indexOf(c) >= 0) {
      if( c !== 'na' && v === 0 ) return '0.00';
      if( c === 'na') return v?.toFixedWithComma(0);
      return v?.toFixedWithComma(2);
    }
    return v;
  },
};

const auxGridArgsDftTwt = [
  { title: '2. 트로이카' },
  {
    title: '3. 공모펀드',
    footNote: '*위드타임모 가정산기준가는 D-1일 기준.', // 주석
  },
  { title: '마켓' },
  { title: '4. ETF' },
  { title: '5. 대체투자펀드' },
  {
    title: '6. 해외펀드',
    footNote: '*위 수익률은 당사펀드 내 싱가폴펀드의 기여 수익률임',
  },
];

const auxGridArgsDft = [
  { title: '2. 트로이카' },
  {
    title: '3. 공모펀드',
  },
  { title: '마켓' },
  { title: '4. ETF' },
  { title: '5. 대체투자펀드' },
  {
    title: '6. 해외펀드',
    footNote: '*위 수익률은 당사펀드 내 싱가폴펀드의 기여 수익률임',
  },
];

export default function RtPrd() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [isMngr, setIsMngr] = useState<boolean>(false);
  const [gridFundRt, setGridFundRt] =
    useState<UrlGridArgs<FundRt>>(emptyGridArgs);
  const [showFundRt, setShowFundRt] = useState<boolean>(true);
  const [gridUserNav, setGridUserNav] =
    useState<UrlGridArgs<ProdUserDaily>>(emptyGridArgs);
  const [showUserNav, setShowUserNav] = useState<boolean>(false);
  const [refreshNeeded, setRefreshNeeded] = useState(0);

  // 펀드수익률조회
  const fundRt: UrlGridArgs<FundRt> = {
    ...fundRtDft,
    title: '1. 헤지펀드',
    columns: columnsDft,
    headers: headersDft,
    widths: { aigrpty: 95, Id: 95, nm: 150, nav: 70, na: 70, taxnav: 100 },
    dftFormatter: (v, c, r) => {
      if (v === -100) return '-';
      if (c === 'aigrpty' && v === 'None') {
        // 대체투자 칼럼값 변경
        if (r.original.d1rt === null) return 'Sum';
        return '-';
      }
      if (c === 'nm') {
        // prettier-ignore
        return r.original.nm.replace("타임폴리오","").replace("전문투자형사모투자신탁","").replace("사모투자신탁","").replace("(전문투자자)","").replace("전문","").replace("대체","")
              .replace("투자","").replace("Active","").replace("일반","").replace("코스닥벤처","")
      }
      // prettier-ignore
      if (['d1rt', 'd5rt', 'm1rt','m3rt','m6rt','y1rt','ytdrt', 'nav', 'taxnav', 'na'].indexOf(c) >= 0) {
        if(c === 'na') { // AUM 해외
          if(r.original.Id === 'G000211' ||r.original.Id === 'SINGA(USD)') return v?.toFixedWithComma(4);
          return v?.toFixedWithComma(0);
        }
        if (v === 0) return '0.00';
        return v?.toFixedWithComma(2);
      }
      // 공모펀드 명칭 변경
      if (c === 'Id' && v === 'TWT**') return 'TWT(가정산)';
      return v;
    },
    auxColumns: auxColumnsDft,
    auxHeaders: auxHeadersDft,
    onDataChange: (data: FundRt[], res: AxiosResponse) => {
      const twtData: FundRt[] = res.data.auxData[1];
      const flag = twtData.some((v) => v.Id === 'TWT**');
      if (flag) {
        // 공모펀드 주석
        fundRt.auxGridArgs = auxGridArgsDftTwt;
      } else {
        fundRt.auxGridArgs = auxGridArgsDft;
      }
    },
  };

  const getFundRtsSummary = (isMngr0: boolean) => {
    setShowFundRt(true);
    setShowUserNav(false);
    setIsMngr(isMngr0);
    setGridFundRt(emptyGridArgs);
    setRefreshNeeded((p) => p + 1);
    setGridFundRt(isMngr0 ? fundRtIsMngr : fundRt);
  };

  const getUserNav = () => {
    setShowUserNav(true);
    setShowFundRt(false);
    setGridUserNav(emptyGridArgs);
    setRefreshNeeded((p) => p + 1);
    setGridUserNav(userNav);
  };

  useEffect(() => {
    setShowFundRt(true);
    setShowUserNav(false);
    setIsMngr(false);
    getFundRtsSummary(false);
  }, [d]);

  return (
    <div className="row" style={{ minWidth: '1500px' }}>
      <div className="col">
        <DateSelector
          value={d}
          onChange={(date) => date !== d && setSearchParams({ d: date })}
        />
        <button
          type="button"
          className={btnClass}
          onClick={() => {
            getFundRtsSummary(false);
          }}
        >
          펀드수익률조회
        </button>
        <button
          type="button"
          className={btnClass}
          onClick={() => {
            getFundRtsSummary(true);
          }}
        >
          전략수익률조회
        </button>
        <button type="button" className={btnClass} onClick={() => getUserNav()}>
          가정산기준가
        </button>
      </div>
      <hr className="narrow light" />
      <div className="d-flex children-me-5">
        {showFundRt && (
          <UrlGrid
            args={gridFundRt}
            params={{ d, isMngr }}
            refreshNeeded={refreshNeeded}
          />
        )}
      </div>
      {showUserNav && (
        <UrlGrid
          args={gridUserNav}
          params={{ d }}
          refreshNeeded={refreshNeeded}
        />
      )}
    </div>
  );
}
