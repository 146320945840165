import React, { useMemo, useState } from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
  Row,
} from '@tanstack/react-table';
import Tooltip from 'tmslib/src/ui/Tooltip';
import DataGrid from 'tmslib/src/table/DataGrid';
import { gradationStyle, rescale } from 'tmslib/src/util/utils';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { PosWeiTarget } from '../../../Tms/BO';
import { VhGrpTy } from '../../../Tms/Tms';
import { LS, ls2num } from '../../../Tms/Common';
import OstsGantt from './OstsGantt';
import { OrdPrcTy2, OrdSt } from '../../../Tms/Ord';
import SubOrdTab from './SubOrdTab';
import DiffOrderDlg from './DiffOrderDlg';
import { useAuthState } from '../../Auth/AuthContext';
import { DftBtnStyleMx } from '../../../AppTypes';
import { FuncCall, checkItems, saveProdGrp } from '../../../tmsutil';

export type StdItem = {
  Id: string;
  cntr: number;
  mrgnl: number;
};
export type EarnRev = {
  Id: string;
  u1: number;
  d1: number;
  u5: number;
  d5: number;
  rptsStr: string;
};
export type IdxWei = {
  Id: string;
  ksp: number | null;
  ksq: number | null;
  sp5: number | null;
};
export type ErSc = {
  Id: string;
  scY: number | null;
  scQ: number | null;
};
export type DropSc = {
  Id: string;
  sc5: number | null;
  sc20: number | null;
};

export type WeiTabConfig = {
  showCurrPrc?: boolean;
  showStdDev?: boolean;
  showEarnRev?: boolean;
  showIdxWei?: boolean;
  showErSc?: boolean;
  showDropSc?: boolean;
  rstrD1?: { [key: string]: string };
  showYstdWei?: boolean;
  refVhId?: string | null;
  showRefWei?: boolean | null;
  showCtry?: boolean | null;
};

interface Props {
  setUpdateNeeded: () => void;
  call: FuncCall;
  stId: string | null;
  vhId: string | null;
  cfg: WeiTabConfig;
  ls: LS;
  pos: PosWeiTarget[];
  osts: OrdSt[];
  stds: StdItem[];
  earnRevs: EarnRev[];
  idxWeis: IdxWei[];
  erScs: ErSc[];
  dropScs: DropSc[];
}

const someNote = (cfg: WeiTabConfig, ls: LS, o: PosWeiTarget) =>
  cfg.rstrD1?.[o.prodIdRstr] || (ls === LS.S && o.offsetAtClose);

const tooltipStyle: React.CSSProperties = {
  fontSize: '12px',
  fontWeight: 'normal',
  maxWidth: '300px',
  textAlign: 'left'
};

const earnRevCell = (er: EarnRev | null | undefined, d1: boolean, ls: LS) => {
  if (!er) return null;
  const up = d1 ? er.u1 : er.u5;
  const dn = d1 ? er.d1 : er.d5;
  if (!up && !dn) return null;
  const url =
    'http://www.wisereport.co.kr/wiseReport/cp/LoadReportQT.aspx?key=';
  const tooltipData = er.rptsStr
    .split('\n')
    .filter((r) => r)
    .map((r) => {
      const tok = r.split('|');
      return (
        <div key={tok[0] + tok[2]}>
          <a
            href={`${url}${tok[3]}`}
            className="rpt"
            target="_blank"
            rel="noreferrer"
          >
            {tok[0]}:{' '}
            <span style={{ color: Number(tok[1]).getSignColor() }}>
              {tok[1]}%
            </span>{' '}
            ({tok[2]})
          </a>
        </div>
      );
    });
  return (
    <Tooltip tooltip={tooltipData} style={tooltipStyle}>
      <span>
        <span className={ls === LS.S && up > 0 ? 'rptUpWarn' : 'rptUp'}>
          {up > 0 ? up : <>&nbsp;</>}
        </span>
        <span style={{ color: 'lightgray' }}>&nbsp;|&nbsp;</span>
        <span className={ls === LS.L && dn > 0 ? 'rptDnWarn' : 'rptDn'}>
          {dn > 0 ? dn : <>&nbsp;</>}
        </span>
      </span>
    </Tooltip>
  );
};

const columnHelper = createColumnHelper<PosWeiTarget>();

const getColumns = (
  isJunior: boolean,
  vhId: string | null,
  cfg: WeiTabConfig,
  ls: LS,
  osts: OrdSt[],
  stds: StdItem[],
  earnRevs: EarnRev[],
  idxWeis: IdxWei[],
  erScs: ErSc[],
  dropScs: DropSc[],
  setDiffTgt: React.Dispatch<React.SetStateAction<PosWeiTarget | null>>,
) => {
  const weiCols = [];
  if (cfg.showYstdWei) {
    weiCols.push(
      columnHelper.accessor('ystdWei', {
        header: '전일',
        meta: { formatter: (v) => v?.toFixed(2) },
        footer: (h) =>
          h.table
            .getRowModel()
            .flatRows.map((r) => r.original.ystdWei ?? 0)
            .reduce((a, v) => a + v, 0)
            .toFixed(2),
      }),
    );
  }
  if (cfg.showRefWei) {
    weiCols.push(
      columnHelper.accessor('refWei', {
        header: cfg.refVhId || '',
        meta: { formatter: (v) => v?.toFixed(2) },
        footer: (h) =>
          h.table
            .getRowModel()
            .flatRows.map((r) => r.original.refWei ?? 0)
            .reduce((a, v) => a + v, 0)
            .toFixed(2),
      }),
    );
  }
  weiCols.push(
    ...[
      columnHelper.accessor('currWeiOfMornPos', {
        header: '장전',
        meta: { formatter: (v, r) => (ls2num(r.original.ls) * v)?.toFixed(2) },
        footer: (h) =>
          h.table
            .getRowModel()
            .flatRows.map((r) => r.original.currWeiOfMornPos ?? 0)
            .reduce((a, v) => a + Number(v), 0)
            .toFixed(2),
      }),
      columnHelper.accessor('ordsSum', {
        header: '주문합',
        size: 50,
        meta: { formatter: (v) => v?.toFixed(2) },
        footer: (h) =>
          h.table
            .getRowModel()
            .flatRows.map((r) => r.original.ordsSum ?? 0)
            .reduce((a, v) => a + Number(v), 0)
            .toFixed(2),
      }),
      columnHelper.accessor('tgtWei', {
        header: '타겟',
        size: 50,
        meta: {
          formatter: (v, r) =>
            r.original.exitAll ? '전량청산' : v?.toFixed(2),
          styler: () => ({ fontWeight: 'bold' }),
        },
        footer: (h) =>
          h.table
            .getRowModel()
            .flatRows.map((r) => r.original.tgtWei ?? 0)
            .reduce((a, v) => a + Number(v), 0)
            .toFixed(2),
      }),
    ],
  );
  if (cfg.showRefWei) {
    weiCols.push(
      columnHelper.accessor('refDiff', {
        header: '비중차',
        meta: { formatter: (v) => v?.toFixed(2) },
      }),
    );
  }

  const stdDic = new Map(stds.map((v) => [v.Id, v]));
  const earnRevDic = new Map(earnRevs.map((v) => [v.Id, v]));
  const idxWeiDic = new Map(idxWeis.map((v) => [v.Id, v]));
  const erScDic = new Map(erScs.map((v) => [v.Id, v]));
  const dropScDic = new Map(dropScs.map((v) => [v.Id, v]));
  const kspName = vhId === 'TML' || isJunior ? 'KSP' : 'K200';
  const c1 =
    columnHelper.group({
      id: '_prod',
      header: () => <span>종목</span>,
      columns: [
        columnHelper.accessor('prodIdRstr', {
          header: '코드',
          footer: 'Sum',
          size: 80,
          meta: { textAlign: 'center' },
        }),
        columnHelper.accessor('prodNmRstr', {
          header: '명',
          size: 200,
          cell: (c) => {
            if (c.row.original.isDiffReady) {
              return (
                <>
                  {c.getValue()}
                  &nbsp;
                  <button // 다시 확인
                    type="button"
                    className="badge bg-warning text-dark"
                    style={{ cursor: 'pointer', border: 'none' }}
                    onClick={() => setDiffTgt(c.row.original)}
                  >
                    diff
                  </button>
                </>
              );
            }
            return c.getValue();
          },
          meta: {
            textAlign: 'left',
            styler: (v, r) => ({
              fontWeight: 'bold',
              backgroundColor: someNote(cfg, ls, r.original)
                ? '#ccc'
                : undefined,
            }),
            cellClassifier: (v, r) =>
              someNote(cfg, ls, r.original) ? 'note' : null,
            title: (v, r) => {
              const o = r.original;
              const msgs: string[] = [];
              const rd1 = cfg.rstrD1?.[o.prodIdRstr];
              if (rd1) msgs.push(`제한해제일: ${rd1}`);
              if (ls === LS.S && o.offsetAtClose) msgs.push('마감 상계');
              return msgs.join(' | ');
            },
          },
        }),
      ],
    })
  if (cfg.showCtry) {
    c1.columns?.push(columnHelper.accessor('ctry', {
      header: '국가',
      size: 80,
      meta: { textAlign: 'center' },
    }))

  }

  const res = [
    c1,
    cfg.showCurrPrc &&
    columnHelper.group({
      id: '_last',
      header: () => <span>현재가</span>,
      columns: [
        columnHelper.accessor('close', {
          header: 'Last',
          size: 60,
          meta: { formatter: (v) => v?.toFixedSigFig() },
        }),
        columnHelper.accessor('ret', {
          header: 'Ch%',
          meta: {
            formatter: (v) => v?.toFixed(1),
            styler: (v) => ({ color: v?.getSignColor() }),
          },
        }),
      ],
    }),

    columnHelper.group({
      id: '_wei',
      header: () => <span>비중(%)</span>,
      columns: weiCols,
    }),

    columnHelper.group({
      id: '_order',
      header: () => <span>주문 현황</span>,
      columns: [
        columnHelper.accessor(
          (r) => (
            <OstsGantt osts={osts.filter((v) => v.prodId === r.prodIdRstr)} />
          ),
          {
            header: '(체결률 | 체결가)',
            size: 300,
          },
        ),
      ],
    }),

    columnHelper.group({
      id: '_ty',
      header: () => <span>구분</span>,
      columns: [
        columnHelper.accessor('sz', {
          header: 'Sz',
          size: 20,
          meta: { textAlign: 'center' },
        }),
        columnHelper.accessor('sec', {
          header: 'Sec',
          size: 30,
          meta: { textAlign: 'center' },
        }),
        columnHelper.accessor('grp', {
          header: '그룹',
          size: 100,
          meta: { textAlign: 'center' },
        }),
      ],
    }),

    cfg.showStdDev &&
    columnHelper.group({
      id: '_std',
      header: () => <span>표준편차</span>,
      columns: [
        columnHelper.accessor(
          (r) => stdDic.get(r.underOrProdId)?.cntr.toFixed(2),
          { header: '기여' },
        ),
        columnHelper.accessor(
          (r) =>
            ((stdDic.get(r.underOrProdId)?.mrgnl ?? 0) * ls2num(ls)).toFixed(
              2,
            ),
          { header: '델타' },
        ),
      ],
    }),

    cfg.showEarnRev &&
    columnHelper.group({
      id: '_earnRev',
      header: () => <span>추정치 변경</span>,
      columns: [
        columnHelper.accessor(
          (r) => earnRevCell(earnRevDic.get(r.underOrProdId), true, ls),
          {
            header: '전일',
            size: 50,
            meta: { textAlign: 'center' },
          },
        ),
        columnHelper.accessor(
          (r) => earnRevCell(earnRevDic.get(r.underOrProdId), false, ls),
          {
            header: '5일',
            size: 50,
            meta: { textAlign: 'center' },
          },
        ),
      ],
    }),

    cfg.showIdxWei &&
    columnHelper.group({
      id: '_idxWei',
      header: () => <span>지수 구성%</span>,
      columns: [
        columnHelper.accessor((r) => idxWeiDic.get(r.underOrProdId)?.ksp, {
          header: kspName,
        }),
        columnHelper.accessor((r) => idxWeiDic.get(r.underOrProdId)?.ksq, {
          header: 'Q150',
        }),
        columnHelper.accessor((r) => idxWeiDic.get(r.underOrProdId)?.sp5, {
          header: 'SP5',
        }),
      ],
    }),

    cfg.showErSc &&
    columnHelper.group({
      id: '_erSc',
      header: () => <span>추정치 점수</span>,
      columns: [
        columnHelper.accessor(
          (r) => erScDic.get(r.underOrProdId)?.scY?.toFixed(1),
          {
            id: 'erSc12F2',
            header: '12Fw',
            size: 50,
            meta: {
              styler: (v, r) =>
                gradationStyle(
                  rescale(
                    erScDic.get(r.original.underOrProdId)?.scY,
                    25,
                    6,
                    50,
                  ),
                ),
            },
          },
        ),
        columnHelper.accessor(
          (r) => erScDic.get(r.underOrProdId)?.scQ?.toFixed(1),
          {
            id: 'erScQ',
            header: 'Q',
            size: 50,
            meta: {
              styler: (v, r) =>
                gradationStyle(
                  rescale(
                    erScDic.get(r.original.underOrProdId)?.scQ,
                    25,
                    6,
                    50,
                  ),
                ),
            },
          },
        ),
      ],
    }),

    cfg.showDropSc &&
    columnHelper.group({
      id: '_dropSc',
      header: () => <span>가격 점수</span>,
      columns: [
        columnHelper.accessor(
          (r) => dropScDic.get(r.underOrProdId)?.sc5?.toFixed(1),
          {
            id: 'dropSc5',
            header: '5일',
            meta: {
              styler: (v, r) =>
                gradationStyle(
                  rescale(
                    dropScDic.get(r.original.underOrProdId)?.sc5,
                    15,
                    6,
                    50,
                  ),
                ),
            },
          },
        ),
        columnHelper.accessor(
          (r) => dropScDic.get(r.underOrProdId)?.sc20?.toFixed(1),
          {
            id: 'dropSc20',
            header: '20일',
            meta: {
              styler: (v, r) =>
                gradationStyle(
                  rescale(
                    dropScDic.get(r.original.underOrProdId)?.sc20,
                    15,
                    6,
                    50,
                  ),
                ),
            },
          },
        ),
      ],
    }),
  ]
    .filter((v) => v)
    .map((v) => v as ColumnDef<PosWeiTarget, unknown>);

  return res;
};

const detailRenderer = (
  r: Row<PosWeiTarget>,
  call: FuncCall,
  osts: OrdSt[],
) => (
  <SubOrdTab
    call={call}
    osts={osts.filter((v) => v.prodId === r.original.prodIdRstr)}
    allOsts={false}
  />
);

export default function WeiTab({
  setUpdateNeeded,
  call,
  stId,
  vhId,
  cfg,
  ls,
  pos,
  osts,
  stds,
  earnRevs,
  idxWeis,
  erScs,
  dropScs,
}: Props) {
  const [expandAllDetails, setExpandAllDetails] = useState<boolean>();
  const [hideTBody, setHideTBody] = useState<boolean>();

  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();

  const addDefaultOrder = async (items: PosWeiTarget[], exitAll = false) => {
    if (!checkItems(items, m, true)) return;
    const p = items[0];
    let wei = 0;
    if (!exitAll) {
      const weiStr = await m.prompt('주문 비중 (%)');
      if (!weiStr) return;
      wei = Number(weiStr);
      if (Number.isNaN(wei)) {
        m.alert(`주문 비중 오류 : ${weiStr}`);
        return;
      }
      if (!wei) return;
    } else {
      wei = -p.tgtWei;
    }

    const vhIn = p.tgt === VhGrpTy.ETF ? vhId : null;
    const exAll = exitAll ? true : wei < 0 && Math.abs(wei + p.tgtWei) < 0.001;
    const par = {
      d: p.d,
      tgt: p.tgt,
      stId: p.stId,
      ls: p.ls,
      t0: null,
      t1: null,
      ord: null,
      vhIn,
      vhEx: null,
      pty: OrdPrcTy2.Opp,
      note: null,
      prodId: p.prodId,
      prc: null,
      exAll,
      chWei: wei,
      isOrdProc: false,
    };
    call('AddOrdStWei', par, {
      title: '신규 주문',
    });
  };

  const updateProdGrp = async (items: PosWeiTarget[]) => {
    if (!checkItems(items, m, true)) return;
    const p = items[0];
    saveProdGrp(m, logger, p.stId, p.prodId, p.grp, () => setUpdateNeeded())
  };

  const meta: TableMeta<PosWeiTarget> = {
    dftColWidth: 40,
    showRowNum: true,
    detailRender: (r) => detailRenderer(r, call, osts),
    hasDetail: (r) => osts.some((v) => v.prodId === r.original.prodIdRstr),
    expandAllDetails,
    onRowClick: () => setExpandAllDetails(undefined),
    hideTBody,
    containerClass: 'target-wei',
    contextMenus: [
      {
        label: '신규 주문',
        callback: addDefaultOrder,
      },
      {
        label: '전량 청산',
        callback: (items: PosWeiTarget[]) => addDefaultOrder(items, true),
      },
      { divider: true },
      {
        label: '종목 그룹 변경',
        callback: updateProdGrp,
      },
    ],
  };

  const [diffTgt, setDiffTgt] = useState<PosWeiTarget | null>(null);

  const isJunior = stId == null ? false : info?.isJunior(stId) ?? false;

  const cols = useMemo(
    () =>
      getColumns(
        // console.log('recalc col') TODO: 자동업뎃마다 osts땜에 리렌더링 되는중
        isJunior,
        vhId,
        cfg,
        ls,
        osts,
        stds,
        earnRevs,
        idxWeis,
        erScs,
        dropScs,
        setDiffTgt,
      ),
    [isJunior, vhId, cfg, ls, osts, stds, earnRevs, idxWeis, erScs, dropScs],
  );

  return (
    <div className="children-me-2">
      <b>{ls === LS.L ? 'Long' : 'Short'} Target</b>
      <button
        type="button"
        className={DftBtnStyleMx}
        onClick={() => setExpandAllDetails(true)}
      >
        전체 주문 보기
      </button>
      <button
        type="button"
        className={DftBtnStyleMx}
        onClick={() => setExpandAllDetails(false)}
      >
        전체 주문 닫기
      </button>
      {ls === LS.L && (
        <label htmlFor="foldLongWei">
          <input
            id="foldLongWei"
            name="foldLongWei"
            type="checkbox"
            checked={hideTBody ?? false}
            onChange={(e) => setHideTBody(e.target.checked)}
          />
          롱 비중 접기
        </label>
      )}
      <DataGrid data={pos} columns={cols} meta={meta} />
      <DiffOrderDlg call={call} diffTgt={diffTgt} setDiffTgt={setDiffTgt} />
    </div>
  );
}
