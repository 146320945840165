import React from 'react';
import { createColumnHelper, ColumnDef } from '@tanstack/react-table';
import DataGrid from 'tmslib/src/table/DataGrid';
import { GrpWei } from '../../../Tms/Pf';

interface Props {
  ventSum: GrpWei[];
}

const columnHelper = createColumnHelper<GrpWei>();
const columns = [
  columnHelper.accessor('grp', {
    header: '구분',
    size: 100,
    meta: {
      styler: (v, r) => ({
        background: r.original.grp === '벤처합' ? '#ffa' : '',
      }),
    },
  }),
  columnHelper.accessor('wei', {
    header: '총자산 대비(%)',
    size: 100,
    meta: {
      formatter: (v) => v?.toFixedWithComma(2),
      styler: (v, r) => ({
        background: r.original.grp === '벤처합' ? '#ffa' : '',
      }),
    },
  }),
].map((v) => v as ColumnDef<GrpWei, unknown>);

export default function GrpWeiTab({ ventSum }: Props) {
  return <DataGrid data={ventSum} columns={columns} />;
}
