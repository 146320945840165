// https://quilljs.com/playground/react

import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import QuillResizeImage from 'quill-resize-image';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  ['link', 'image', 'video'],
  [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
  [{ header: 1 }, { header: 2 }], // custom button values
  [{ size: ['small', false, 'large', 'huge'] }], // { font: [] }],
  // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  // ['blockquote', 'code-block'],
  ['clean'], // remove formatting button
];


Quill.register('modules/resize', QuillResizeImage);

const BlockEmbed: any = Quill.import('blots/block/embed');

// https://github.com/slab/quill/issues/1896#issuecomment-557792404
// https://quilljs.com/docs/guides/cloning-medium-with-parchment#images

// quill은 원래 inline style을 반영안함.
// QuillResizeImage로 이미지 사이즈 변경시, 사이즈가 inline style로 잡히므로,
// 이미지를 수정없이 그대로 반환해야 inline style이 먹혀서 변경된 사이즈가 작동함 
class ImageBlot extends BlockEmbed {
  static blotName = 'image';
  static tagName = 'img';

  static create(value: any) {
    const node = super.create();
    node.setAttribute('src', value.src ?? value);
    if (value.url) {
      node.setAttribute('url', value.url);
    }
    if (value.style) {
      node.setAttribute('style', value.style);
    }
    return node;
  }

  static value(node: any) {
    return {
      src: node.getAttribute('src'),
      url: node.getAttribute('url'),
      style: node.getAttribute('style')
    }
  }
}

Quill.register(ImageBlot);

// EditorCore is an uncontrolled React component
const EditorCore = forwardRef<Quill, any>(
  (
    {
      readOnly,
      defaultValue,
      onTextChange,
      onSelectionChange,
      style,
      className,
    },
    ref: any,
  ) => {
    const containerRef = useRef<any>(null);
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    });

    useEffect(() => {
      ref.current?.enable(!readOnly);
    }, [ref, readOnly]);

    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div'),
      );
      const quill = new Quill(editorContainer, {
        theme: 'snow',
        modules: {
          toolbar: toolbarOptions,
          resize: {
            locale: {},
          },
        },
      });


      ref.current = quill;

      quill.enable(!readOnly);

      if (defaultValueRef.current) {
        // defaultValue를 html로 넘기려면. => 어차피 Editor.tsx에서 set 해줘서 여기선 안함
        // https://quilljs.com/docs/modules/clipboard/#dangerouslypastehtml
        //quill.clipboard.dangerouslyPasteHTML(defaultValueRef.current);

        //https://github.com/zenoamaro/react-quill/pull/321/files
        //quill.setContents(quill.clipboard.convert({ html: defaultValueRef.current }));

        // https://quilljs.com/docs/api
        // quill.setContents(defaultValueRef.current);

      }
      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        onTextChangeRef.current?.(...args);
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      return () => {
        ref.current = null;
        container.innerHTML = '';
      };
    }, [ref]);

    return <div ref={containerRef} style={style} className={className}></div>;
  },
);

EditorCore.displayName = 'Editor';

export default EditorCore;
