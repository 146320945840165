import React, { useEffect, useState } from 'react';

export default function MultiCheckBox({
  name,
  selected: initialSelected,
  options,
  onChangeSelected,
  onChangeEach,
  labels,
}: {
  name: string;
  selected: string[];
  options: string[];
  onChangeSelected?: (selected: string[]) => void;
  onChangeEach?: (item: string, selected: boolean) => void;
  labels?: string[];
}) {
  const [selected, setSelected] = useState<string[]>(initialSelected);

  const onChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const index = selected.indexOf(value);
    if (index > -1) {
      setSelected([...selected.slice(0, index), ...selected.slice(index + 1)]);
    } else {
      setSelected([...selected, ...[value]]);
    }

    onChangeEach?.(value, index === -1);
  };

  const isSelected = (value: string) => selected.includes(value);

  useEffect(() => {
    setSelected(initialSelected);
  }, [initialSelected]);

  useEffect(() => {
    onChangeSelected?.(selected);
  }, [selected]);

  return (
    <>
      {options.map((o, i) => (
        <label key={o} htmlFor={`${name}_${o}`}>
          <input
            type="checkbox"
            name={name}
            id={`${name}_${o}`}
            value={o}
            checked={isSelected(o)}
            onChange={onChecked}
          />
          {labels ? labels[i] : o}
        </label>
      ))}
    </>
  );
}
