import React, { useEffect, useState } from 'react';
import { UrlGrid, UrlGridArgs, emptyGridArgs } from '../../tmsutil';
import { AppMst, appMstSchema } from '../../Tms/Tms';

const appMst: UrlGridArgs<AppMst> = {
  url: '/Admin/HealthCheck/AppMst',
  title: 'HealthCheck AppMst',
  // prettier-ignore
  columns: ['Id', 'ty', 'hc_t0', 'hc_t1', 'intv', 'skipDays', 'skipOnKorHoli', 'eoyHoli', 'skip', 'devToo', ],
  // prettier-ignore
  headers: ['Id', '구분', 'hc_t0', 'hc_t1', 'intv(초)', '휴일', '한휴건', '연말휴일', 'skip', 'devToo'],
  widths: { intv: 60, eoyHoli: 60 },
  meta: {
    dftColWidth: 80,
  },
  editable: true,
  checkboxes: ['skipOnKorHoli', 'eoyHoli', 'skip', 'devToo'],
  height: undefined,
  schema: appMstSchema,
};

export default function HealthCheck() {
  const [appMstData, setAppMstData] =
    useState<UrlGridArgs<AppMst>>(emptyGridArgs);
  const [refreshNeeded, setRefreshNeeded] = useState(0);

  useEffect(() => {
    setRefreshNeeded((p) => p + 1);
    setAppMstData(appMst);
  }, []);

  return (
    <div className="row" style={{ minWidth: '1000px' }}>
      <div>
        <UrlGrid args={appMstData} params={{}} refreshNeeded={refreshNeeded} />
      </div>
    </div>
  );
}
