import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import { UrlGrid, UrlGridArgs, emptyGridArgs, callAxios } from '../../tmsutil';
import {
  BoSwapPl2,
  FillAlloc,
  InterimFill,
  SwapPbsCd,
  boSwapPl2Schema,
  interimFillSchema,
} from '../../Tms/BO';
import DateSelector from '../../shared/DateSelector';
import { DftBtnStyleMx } from '../../AppTypes';
import { ProdUserDaily, prodUserDailySchema } from '../../Tms/Prod';
import { Company, SwapBrokers, getCompName } from '../../Tms/Identity';

const currMenu = '/PBS/Swap';
const dftHeight = 400;

// prettier-ignore
const getSwapPl2 = (title: string, brk: string): UrlGridArgs<BoSwapPl2> => {
  const cumFx = brk.isIn('KI', 'KB')
  return {
    url: `${currMenu}/BoSwapPl2`,
    title,
    columns: cumFx
      ? ['vhId', 'unrzPl', 'cumRzPl', 'totPl', 'cumExp', 'cumFx', 'resetExp', 'csMrgn', 'cnclAvail', 'posSum']
      : ['vhId', 'unrzPl', 'cumRzPl', 'totPl', 'cumExp', 'resetExp', 'csMrgn', 'cnclAvail', 'posSum'],
    headers: cumFx
      ? ['펀드', '평가손익(A)', '누적실현손익(B)', '총스왑손익(C)', '이자/비용총계(D)', '환손익누적(E)', 'Reset비용(F)', '현금 증거금(G)', '해지가능금액(H)', '포지션합']
      : ['펀드', '평가손익(A)', '누적실현손익(B)', '총스왑손익(C)', '이자/비용총계(D)', 'Reset비용(E)', '현금 증거금(F)', '해지가능금액(G)', '포지션합'],
    editable: true,
    meta: { dftColWidth: 100 },
    height: dftHeight,
    schema: boSwapPl2Schema,
    infoMsg: cumFx
      ? `(A) 총스왑손익 - 누적실현손익 (KRW)
(B) RESET 누적금액 (현금담보이자 제외한 실제 RESET 정산금액 누적분)
(C) 레포트 손익합계(누적) Total P/L
(D) 레포트 손입합계(누적) Interest
(E) Other Cash, 환손익 누적분 (청산한 포지션에 대한 환노출분, 2020.05부터 누적) (KRW)
(F) 리셋일에 리셋된 비용 합계(현금담보이자 제외) *리셋일에만 업데이트, 평상시에는 공란
(G) 현금 증거금 잔고액 (KRW)
(H) 현금증거금 해지 가능액 (KRW)`
      : `(A) 총스왑손익 - 누적실현손익 (KRW)
(B) RESET 누적금액 (현금담보이자 제외한 실제 RESET 정산금액 누적분)
(C) 레포트 손익합계(누적) Total P/L
(D) 레포트 손입합계(누적) Interest
(E) 리셋일에 리셋된 비용 합계(현금담보이자 제외) *리셋일에만 업데이트, 평상시에는 공란
(F) 현금 증거금 잔고액 (KRW)
(G) 현금증거금 해지 가능액 (KRW)`
  };
};

// prettier-ignore
const swapFx: UrlGridArgs<ProdUserDaily> = {
  url: `${currMenu}/SwapFx`,
  title: '해외스왑 적용 환율',
  columns: ['prodId', 'val'],
  headers: ['FX', '환율'],
  editable: true,
  height: dftHeight,
  meta: { dftColWidth: 100 },
  schema: prodUserDailySchema,
};

// prettier-ignore
const interimFill: UrlGridArgs<InterimFill> = {
  url: `${currMenu}/InterimFill`,
  title: '해외스왑 가체결(통합)',
  columns: ['sgn', 'tkr', 'qty', 'prc', 'tmpId'],
  headers: ['방향', 'Ticker', '체결수량', '평단가', 'EMSX_SEQ'],
  editable: true,
  height: dftHeight,
  meta: { dftColWidth: 100 },
  schema: interimFillSchema,
};

// prettier-ignore
const fillAlloc: UrlGridArgs<FillAlloc> = {
  url: `${currMenu}/FillAlloc`,
  title: '해외스왑 체결 분배',
  columns: ['vhId', 'ls', 'ex', 'tkr', 'oQty', 'fQty', 'prc'],
  headers: ['펀드코드', '롱/숏', '진입/청산', 'Ticker', '주문수량', '체결수량', '평단가'],
  height: 800,
  meta: { dftColWidth: 100 },
};

// prettier-ignore
const getSwapPbsCd = (newOnly: boolean): UrlGridArgs<SwapPbsCd> => ({
  url: `${currMenu}/SwapPbsCd`,
  title: `국내/해외 스왑코드 (${newOnly ? '신규' : '잔고'})`,
  columns: ['cd'],
  headers: ['스왑코드'],
  editable: true,
  height: dftHeight,
  meta: { dftColWidth: 100 },
});

type T = { Id: number }; // 임의 IId 타입

export default function Swap() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();

  const isHouse = user?.Comp === 'House';
  const brks = isHouse ? SwapBrokers : [user?.Comp ?? ''];
  const d = searchParams.get('d') || info?.currBizDay || '';
  const brk = searchParams.get('brk') || brks[0];
  if (!isHouse && user?.Comp !== brk) {
    setSearchParams({ d });
  }

  const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);
  const [warnResMsg, setWarnResMsg] = useState<string | null>(null);
  const [refreshNeeded, setRefreshNeeded] = useState(0);

  const btnClass = DftBtnStyleMx;

  useEffect(() => {
    setGridArgs(emptyGridArgs);
  }, [d, brk]);

  const getParams = (title: string) => {
    const dft = { d, brk };
    if (title === '국내스왑 손익') return { ...dft, frgn: false };
    if (title === '해외스왑 손익') return { ...dft, frgn: true };
    if (title === '국내/해외 스왑코드 (신규)') return { ...dft, newOnly: true };
    if (title === '국내/해외 스왑코드 (잔고)')
      return { ...dft, newOnly: false };
    return dft;
  };

  const query = (args: unknown) => {
    setRefreshNeeded((p) => p + 1);
    setGridArgs(args as UrlGridArgs<T>);
  };

  const getButton = (a: unknown) => {
    const { title } = a as UrlGridArgs<T>;

    return (
      <button
        key={title}
        type="button"
        className={btnClass}
        onClick={() => {
          setWarnResMsg('');
          query(a);
        }}
      >
        {title}
      </button>
    );
  };

  const call = (
    func: string,
    params: unknown,
    confirmMsg: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (data: any) => void,
  ) =>
    callAxios({
      m,
      logger,
      url: `${currMenu}/${func}`,
      params,
      confirmMsg,
      title: confirmMsg,
      onSuccess,
    });

  const domSwapPl = getSwapPl2('국내스왑 손익', brk);
  const frgnSwapPl = getSwapPl2('해외스왑 손익', brk);
  const swapCdNew = getSwapPbsCd(true);
  const swapCdHold = getSwapPbsCd(false);
  return (
    <div style={{ minWidth: '3000px' }} className="children-me-2">
      <DateSelector
        value={d}
        onChange={(date) => date !== d && setSearchParams({ d: date, brk })}
      />
      <select
        value={brk}
        onChange={(e) => setSearchParams({ d, brk: e.target.value })}
      >
        {brks.map((b) => (
          <option key={b} value={b}>
            {getCompName(b as Company)}
          </option>
        ))}
      </select>
      <hr className="narrow light" />
      {[domSwapPl, frgnSwapPl, swapFx, interimFill, fillAlloc].map((v) =>
        getButton(v),
      )}
      {isHouse && (
        <button
          type="button"
          className="btn btn-link"
          onClick={() =>
            call('LoadMissedFill', { d, brk }, '수기체결 로딩', (data) =>
              m.alert(
                `${data}개 체결 로딩. ${data > 0 ? '북포지션을 다시 생성하세요' : ''
                }`,
              ),
            )
          }
        >
          빠진체결로딩(타임전용)
        </button>
      )}
      <hr className="narrow light" />
      {[swapCdNew, swapCdHold].map((v) => getButton(v))}
      <hr className="narrow light" />
      {warnResMsg && (
        <div className="alert alert-slim alert-warning like-pre">
          {warnResMsg}
        </div>
      )}
      <UrlGrid
        args={gridArgs}
        params={getParams(gridArgs.title)}
        refreshNeeded={refreshNeeded}
      />
    </div>
  );
}
