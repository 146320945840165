import React from 'react';
import { ArrowClockwise, Bookmark, CheckLg, Pencil, PlusLg, Unlock, X, XLg } from 'react-bootstrap-icons';

export type ButtonRole = 'Default' | 'Ok' | 'Cancel' | 'Icon' | 'Link';

export type Icon = 'Check' | 'Plus' | 'X' | 'x' | 'Unlock' | 'Pencil' | 'Bookmark' | 'Refresh';

// https://icons.getbootstrap.com/
const mapIcon = (icon: Icon | null, iconcls?: string) => {
  if (!icon) return null;

  switch (icon) {
    case 'Check':
      return <CheckLg />;
    case 'Plus':
      return <PlusLg className={iconcls} />;
    case 'X':
      return <XLg />;
    case 'x':
      return <X />;
    case 'Unlock':
      return <Unlock />;
    case 'Pencil':
      return <Pencil />;
    case 'Bookmark':
      return <Bookmark />;
    case 'Refresh':
      return <ArrowClockwise />
  }
};

// return [label, icon, className]
const getDefault = (role: ButtonRole): [string, Icon | null, string] => {
  const dftCls = 'btn btn-sm';
  switch (role) {
    case 'Default':
      return ['', null, `${dftCls} btn-outline-secondary`];
    case 'Ok':
      return [role, 'Check', `${dftCls} btn-primary`];
    case 'Cancel':
      return [role, 'X', `${dftCls} btn-link`];
    case 'Icon':
      return ['', null, `${dftCls} btn-outline-primary rounded-circle`];
    case 'Link':
      return ['', null, `${dftCls} btn-link`];
  }
};

export interface ButtonProps {
  type?: 'button' | 'submit' | 'reset';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  btnRole?: ButtonRole;
  icon?: Icon;
  iconcls?: string;
  label?: string;
  className?: string; // 디폴트 클래스 대체
  appendedClass?: string; // 디폴트 클래스에 추가
  title?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  text?: boolean;
  noDisplayWhenDisabled?: boolean;
  innerRef?: React.MutableRefObject<HTMLButtonElement | null>;
  children?: React.ReactNode;
}

export default function Button({
  type,
  onClick,
  btnRole,
  icon,
  label,
  className,
  iconcls,
  appendedClass,
  title,
  style,
  disabled,
  text,
  noDisplayWhenDisabled,
  innerRef,
  children,
}: ButtonProps) {
  if (disabled && noDisplayWhenDisabled) return <></>;

  const [labelDft, iconDft, classNameDft] = getDefault(btnRole ?? 'Default');
  const class_ =
    className ??
    `${classNameDft} ${appendedClass ?? ''} ${text ? 'btn-link text-decoration-none' : ''}`;
  const content = label ?? children ?? labelDft;
  // console.log(class_)
  return (
    <button
      type={type ?? "button"}
      className={class_}
      title={title}
      style={style}
      ref={innerRef}
      onClick={onClick}
      disabled={disabled}
    >
      {mapIcon(icon ?? iconDft, iconcls)}
      {content ? <>&nbsp;</> : <></>}
      {content}
    </button>
  );
}
