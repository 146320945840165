import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthDispatch } from './AuthContext';
import { callAxios } from '../../tmsutil';

export default function Logout() {
  const { msgBox: m, logger } = useMessageState();
  const dispatch = useAuthDispatch();
  useEffect(() => {
    callAxios({
      m,
      logger,
      url: '/Auth/Logout',
      params: {},
      onSuccess: () => {},
    }).finally(() => dispatch({ type: 'LOGOUT' }));
  }, []);
  return <Navigate replace to="/" />;
}
