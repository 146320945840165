import { Table, RowData } from '@tanstack/react-table';
import _ from 'lodash';
import { uuidv7 } from 'uuidv7';
import { parseBool, unknownToString, clipRange } from '../util/utils';
import { IId, ColumnType, SemanticIdType } from './DataGrid';

const getCurrColIdx = <TData extends RowData>(table: Table<TData>) => {
  const cols = table.getVisibleLeafColumns();
  const currColId = table.getState().currentColumnId;
  return cols.findIndex((c) => c.id === currColId);
};

const getCurrRowIdx = <TData extends RowData>(table: Table<TData>) => {
  const rows = table.getRowModel().flatRows;
  const currRowId = table.getState().currentRowId;
  return rows.findIndex((r) => r.id === currRowId);
};

export function PasteClipboard<TData extends IId>(table: Table<TData>) {
  const tableMeta = table.options.meta;
  if (
    !tableMeta?.editable ||
    !tableMeta?.areaPastable ||
    !tableMeta?.updateData
  )
    return;

  const frozenColumns = new Set(
    table
      .getAllColumns()
      .filter((v) => v.columnDef.meta?.frozen)
      .map((v) => v.id as keyof TData),
  );
  const rowAppendable = tableMeta?.rowAppendable;
  navigator.clipboard.readText().then((txt) => {
    const objData = txt
      .split('\n')
      .filter((l) => l.length)
      .map((l) => l.split('\t'));
    if (!objData.length) return;
    const currColIdx = getCurrColIdx(table);
    if (currColIdx < 0) return;
    const currRowIdx = getCurrRowIdx(table);
    if (currRowIdx < 0) return;
    const cols = table.getVisibleLeafColumns();
    const { rows } = table.getRowModel();
    const added: TData[] = [];
    const updated: {
      [key: number | string]: { [key in keyof TData]: unknown };
    } = {};
    const { rows: allRows } = table.getCoreRowModel();
    const currMinId = Math.min(
      0,
      _.min(allRows.map((v) => Number(v.original.Id))) ?? 0, // stringId는 NaN으로
    );

    const { rows: sltdRows } = table.getSelectedRowModel();
    // 선택영역만큼 들어가도록 , 만약 붙여넣기할 데이터가 1개이면 전체 선택영역에 같은 데이터 복사
    // 붙여넣기할 데이터가 1개 이상이면 순서대로 반복
    for (let slti = 0; slti < sltdRows.length; slti += objData.length) {
      for (let i = 0; i < objData.length; i += 1) {
        const fldVals = objData[i];
        const isAdded = currRowIdx + i >= rows.length;

        if (isAdded) {
          if (rowAppendable) {
            const obj = {
              Id:
                tableMeta.idType === 'uuid'
                  ? uuidv7()
                  : currMinId - 1 - added.length,
            } as Partial<TData>;
            for (let j = 0; j < fldVals.length; j += 1) {
              const col = cols[currColIdx + j]
              if (!col) continue;
              const colId = col.id as keyof TData;
              if (!frozenColumns.has(colId)) {
                const colTy = col.columnDef.meta?.type;
                const v =
                  colTy === ColumnType.Checkbox
                    ? parseBool(fldVals[j])
                    : fldVals[j].trim();
                obj[colId] = v === '' ? undefined : (v as TData[typeof colId]);
              }
            }
            added.push(obj as TData);
          }
        } else if (rows.length > currRowIdx + slti + i) {
          // inputgrid에서 extraRow를 생성한 경우
          const obj = rows[currRowIdx + slti + i].original;
          const diff = {} as { [key in keyof TData]: unknown };

          fldVals.forEach((fldVal, j) => {
            if (!cols[currColIdx + j]) return;
            const colId = cols[currColIdx + j].id as keyof TData;
            if (!frozenColumns.has(colId)) {
              const colTy = cols[currColIdx + j].columnDef.meta?.type;
              const v =
                colTy === ColumnType.Checkbox
                  ? parseBool(fldVal)
                  : fldVal.trim();
              if (unknownToString(obj[colId]) !== unknownToString(v)) {
                diff[colId] = v === '' ? undefined : (v as TData[typeof colId]);
              }
            }
          });
          if (Object.keys(diff).length) {
            updated[obj.Id] = diff;
          }
        }
      }
    }
    if (added.length || Object.keys(updated).length) {
      tableMeta?.updateData?.(added, updated, new Set());
    }
  });
}

export const copyToClipboard = <TData extends RowData>(
  table: Table<TData>,
  byCell: boolean,
) => {
  const { rows } = table.getSortedRowModel();
  const { rows: sltdRows } = table.getSelectedRowModel();
  const sltdIds = new Set(sltdRows.map((r) => r.id));
  const cols = byCell
    ? table.options.getColumnSelectionRange()
    : table.getVisibleLeafColumns();
  const txt = rows
    .filter((r) => sltdIds.has(r.id))
    .map((r) => cols.map((c) => unknownToString(r.getValue(c.id))).join('\t'))
    .join('\n');
  navigator.clipboard.writeText(txt);
};

export const copyHeaderToClipboard = <TData extends RowData>(
  table: Table<TData>,
) => {
  const grp = table.getHeaderGroups();
  // 그룹 헤더인 경우 아래쪽만 복사
  if (grp.length > 1) {
    const grpHeaders = grp[0].headers
      .map((hh) => hh.column)
      .flatMap((hh) => hh.columns.map((hhh) => hhh.columnDef.header))
      .join('\t');
    navigator.clipboard.writeText(grpHeaders);
  } else {
    const txt = table.options.columns.map((r) => r.header).join('\t');
    navigator.clipboard.writeText(txt);
  }
};

export const clearSelectedArea = <TData extends IId>(
  table: Table<TData>,
  byCell: boolean,
) => {
  const tableMeta = table.options.meta;
  if (
    !tableMeta?.editable ||
    !tableMeta?.areaPastable ||
    !tableMeta?.updateData
  )
    return;

  const frozenColumns = new Set(
    table
      .getAllColumns()
      .filter((v) => v.columnDef.meta?.frozen)
      .map((v) => v.id as keyof TData),
  );

  const { rows } = table.getSelectedRowModel();
  const cols = byCell
    ? table.options.getColumnSelectionRange()
    : table.getVisibleLeafColumns();
  const updated: { [key: number | string]: { [key in keyof TData]: unknown } } =
    {};
  rows.forEach((r) => {
    const toDel = {} as { [key in keyof TData]: unknown };
    cols.forEach((c) => {
      if (!frozenColumns.has(c.id as keyof TData)) {
        toDel[c.id as keyof TData] = null;
      }
    });
    if (Object.keys(toDel).length) {
      updated[r.original.Id] = toDel;
    }
  });
  table.options.meta?.updateData?.([], updated, new Set());
};

export const deleteSelectedRows = <TData extends IId>(table: Table<TData>) => {
  const tableMeta = table.options.meta;
  if (
    !tableMeta?.editable ||
    !tableMeta?.areaPastable ||
    !tableMeta?.updateData
  )
    return;

  const { rows } = table.getSelectedRowModel();
  table.options.meta?.updateData?.(
    [],
    {},
    new Set(rows.map((r) => r.original.Id)),
  );
};

export const getNewRowId = <TData extends IId>(
  data: TData[],
  idType?: SemanticIdType,
) => {
  // string type도 일단 같이
  if (!idType || idType === 'number' || idType === 'string') {
    const currMinId = Math.min(
      0,
      // stringId 는 NaN으로 되고, NaN은 _.min 에서 빠짐.
      // 즉 string id값은 제외되고 신규는 -1,-2,...로
      _.min(data.map((v) => Number(v.Id))) ?? 0,
    );
    return currMinId - 1;
  }
  if (idType === 'uuid') return uuidv7();

  throw new Error('wrong id type');
};

export const appendRow = <TData extends IId>(table: Table<TData>) => {
  const tableMeta = table.options.meta;
  if (
    !tableMeta?.editable ||
    !tableMeta?.rowAppendable ||
    !tableMeta?.updateData
  )
    return null;
  const { rows: allRows } = table.getCoreRowModel();
  const data = allRows.map((v) => v.original);
  const obj = {
    Id: getNewRowId(data, tableMeta.idType),
    isExtraRow: true,
  } as unknown as TData;
  table.options.meta?.updateData?.([obj], {}, new Set());
  return obj;
};

export const selectAll = <TData extends RowData>(
  table: Table<TData>,
  byCell: boolean,
) => {
  table.toggleAllRowsSelected(true);
  if (byCell) {
    table.options.setColumnSelectionLB(0);
    table.options.setColumnSelectionUB(
      table.getVisibleLeafColumns().length - 1,
    );
  }
};

// step: 1 for rigt, -1 for left
export const moveCurrentCellLeftRight = <TData extends RowData>(
  table: Table<TData>,
  step: number,
  toTheEnd: boolean,
) => {
  const currColIdx = getCurrColIdx(table);
  if (currColIdx < 0) return;
  const cols = table.getVisibleLeafColumns();
  const endIdx = step > 0 ? cols.length - 1 : 0;
  const newColIdx = toTheEnd
    ? endIdx
    : clipRange(currColIdx + step, 0, cols.length - 1);
  table.options.setCurrentColumnId(cols[newColIdx].id);
  // 셀범위 선택 상태서 이동하면 선택 없앰
  table.options.setColumnSelectionLB(newColIdx);
  table.options.setColumnSelectionUB(newColIdx);
  const currRowId = table.getState().currentRowId;
  if (currRowId) {
    table.setRowSelection(() => ({ [currRowId]: true }));
  }
};

// step: 1 for down, -1 for up
export const moveCurrentCellUpDown = <TData extends IId>(
  table: Table<TData>,
  step: number,
  toTheEnd: boolean,
  appendAtEnd?: boolean,
) => {
  const currRowIdx = getCurrRowIdx(table);
  if (currRowIdx < 0) return;
  const rows = table.getRowModel().flatRows;
  const endIdx = step > 0 ? rows.length - 1 : 0;

  if (currRowIdx === endIdx && appendAtEnd) {
    const newObj = appendRow(table);
    if (newObj?.Id) table.options.setCurrentRowId(newObj.Id?.toString());
    return;
  }
  const newRowIdx = toTheEnd
    ? endIdx
    : clipRange(currRowIdx + step, 0, rows.length - 1);
  const newRow = rows[newRowIdx];
  table.options.setCurrentRowId(newRow.id);
  table.resetRowSelection();
  newRow.toggleSelected();
  const currColIdx = getCurrColIdx(table);
  table.options.setColumnSelectionLB(currColIdx);
  table.options.setColumnSelectionUB(currColIdx);
};

// step: 1 for rigt, -1 for left
export const selectRangeLeftRight = <TData extends RowData>(
  table: Table<TData>,
  step: number,
  toTheEnd: boolean,
) => {
  const currColIdx = getCurrColIdx(table);
  if (currColIdx < 0) return;
  const tabState = table.getState();
  const colLB = tabState.columnSelectionLB ?? currColIdx;
  const colUB = tabState.columnSelectionUB ?? currColIdx;
  const cols = table.getVisibleLeafColumns();
  // 현재 셀에서 오른쪽 선택영역이거나, 현재 셀 상에서 오른쪽 화살표면
  if (
    (colUB !== colLB && currColIdx === colLB) ||
    (colUB === colLB && step > 0)
  ) {
    if (toTheEnd && step < 0) {
      // 현재 셀에서 오른쪽에 있으면서 왼쪽 화살표면
      table.options.setColumnSelectionLB(0);
      table.options.setColumnSelectionUB(currColIdx);
    } else {
      const newColIdx = toTheEnd
        ? cols.length - 1
        : clipRange(colUB + step, 0, cols.length - 1);
      table.options.setColumnSelectionUB(newColIdx);
    }
  } else if (
    (colUB !== colLB && currColIdx === colUB) ||
    (colUB === colLB && step < 0)
  ) {
    if (toTheEnd && step > 0) {
      // 현재 셀에서 왼쪽에 있으면서 오른쪽 화살표면
      table.options.setColumnSelectionLB(currColIdx);
      table.options.setColumnSelectionUB(cols.length - 1);
    } else {
      const newColIdx = toTheEnd
        ? 0
        : clipRange(colLB + step, 0, cols.length - 1);
      table.options.setColumnSelectionLB(newColIdx);
    }
  } else {
    // 현재 셀은 항상 영역의 처음이나 끝
  }
};

// step: 1 for down, -1 for up
export const selectRangeUpDown = <TData extends RowData>(
  table: Table<TData>,
  step: number,
  toTheEnd: boolean,
) => {
  const currRowIdx = getCurrRowIdx(table);
  if (currRowIdx < 0) return;
  const rows = table.getRowModel().flatRows;
  // currentRow를 포함하는(연속하는) 선택범위에 대해 범위 확장 적용
  // 아래쪽부터 먼저 탐색
  let rowUB = currRowIdx + 1;
  while (rowUB < rows.length) {
    if (!rows[rowUB].getIsSelected()) break;
    rowUB += 1;
  }
  rowUB -= 1;
  let rowLB = currRowIdx - 1;
  while (rowLB >= 0) {
    if (!rows[rowLB].getIsSelected()) break;
    rowLB -= 1;
  }
  rowLB += 1;

  if (
    (rowUB !== rowLB && currRowIdx === rowLB) ||
    (rowUB === rowLB && step > 0)
  ) {
    if (step < 0) {
      // 현재 셀에서 아래쪽 있으면서 위 화살표면
      if (toTheEnd) {
        // shift key 상태면
        for (let i = currRowIdx + 1; i <= rowUB; i += 1) {
          // 현재셀 아래쪽 선택 취소
          rows[i].toggleSelected();
        }
        for (let i = rowLB - 1; i >= 0; i -= 1) {
          // 맨 윗줄까지 선택
          rows[i].toggleSelected();
        }
      } else {
        // 맨 아래쪽행 선택 취소
        rows[rowUB].toggleSelected();
      }
    } else {
      // 현재 셀에서 아래쪽 있으면서 아래 화살표면 다음 아래행 선택
      const newRowIdx = toTheEnd
        ? rows.length - 1
        : Math.min(rowUB + step, rows.length - 1);
      for (let i = rowUB + 1; i <= newRowIdx; i += 1) {
        rows[i].toggleSelected();
      }
    }
  } else if (
    (rowUB !== rowLB && currRowIdx === rowUB) ||
    (rowUB === rowLB && step < 0)
  ) {
    if (step > 0) {
      if (toTheEnd) {
        for (let i = currRowIdx - 1; i >= rowLB; i -= 1) {
          // 현재셀 위쪽 선택 취소
          rows[i].toggleSelected();
        }
        for (let i = rowUB + 1; i < rows.length; i += 1) {
          // 맨 아랫줄까지 선택
          rows[i].toggleSelected();
        }
      } else {
        rows[rowLB].toggleSelected();
      }
    } else {
      const newRowIdx = toTheEnd ? 0 : Math.max(rowLB + step, 0);
      for (let i = rowLB - 1; i >= newRowIdx; i -= 1) {
        rows[i].toggleSelected();
      }
    }
  } else {
    // 현재 셀은 항상 영역의 처음이나 끝
  }
};
