import React, { useEffect, useState } from 'react';
import {
  createColumnHelper,
  TableMeta,
  Row,
  ColumnDef,
  CellContext,
} from '@tanstack/react-table';
import { ZodFirstPartyTypeKind, ZodObject } from 'zod';
import _ from 'lodash';
import DataGrid, { IId, ColumnType, DataGridState } from './DataGrid';
import { ifesleExpr } from '../util/utils';
import Button from '../ui/Button';
import { ExtractKeys } from '../util/types';

const dftColWidthDic: { [key: string]: number } = {
  d: 70,
  vhId: 60,
  book: 80,
  stId: 60,
  prodId: 80,
  prodNm: 150,
  acctId: 90,
  brkNm: 80,
  userNm: 60,
  ls: 30,
  ex: 30,
};

const getDftColWidth = (fld: string) => {
  const w = dftColWidthDic[fld];
  if (w) return w;
  const fldLower = fld.toLowerCase();
  const flds0 = ['nav', 'prc', 'price', 'fx', 'msg'];
  if (flds0.some((f) => fldLower.startsWith(f) || fldLower.endsWith(f)))
    return 80;
  const flds1 = ['prft', 'pos'];
  if (flds1.some((f) => fldLower.startsWith(f) || fldLower.endsWith(f)))
    return 90;
  if (fldLower === 'memo') {
    return 200;
  }
  return undefined;
};

export const dftFallbackFormatter = (v: number | unknown) => {
  if (typeof v === 'number') {
    if (v >= 100000 || v <= -100000) return v.toFixedWithComma(4, 0);
    if (v >= 1000 || v <= -1000) return v.toFixedWithComma(6, 0);
  }
  return v;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SchemaType = ZodObject<any>;

export type SimpleGridDataType =
  | number
  | string
  | null
  | undefined
  | React.ReactNode;

export interface ColumnArgs<T extends IId> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  accessFns?: ((r: T) => any)[];
  headers?: string[];
  headerGroups?: [string, number][]; // head, colspan
  dftFormatter?: (
    v: SimpleGridDataType,
    c: keyof T,
    r: Row<T>,
    ctx: CellContext<T, unknown>,
  ) => SimpleGridDataType;
  formatters?: {
    [key in keyof Partial<T>]: (
      v: SimpleGridDataType,
      r: Row<T>,
      ctx: CellContext<T, unknown>,
    ) => SimpleGridDataType;
  };
  dftStyler?: (
    v: SimpleGridDataType,
    c: keyof T,
    r: Row<T>,
  ) => React.CSSProperties | null;
  stylers?: {
    [key in keyof Partial<T>]: (
      v: SimpleGridDataType,
      r: Row<T>,
    ) => React.CSSProperties | null;
  };
  titles?: {
    [key in keyof Partial<T>]: (
      v: SimpleGridDataType,
      r: Row<T>,
    ) => string | null;
  };
  rowSpanFns?: {
    [key in keyof Partial<T>]: (r0: Row<T>, r1: Row<T>) => boolean;
  };
  dftRowSpanFn?: (c: keyof T, r0: Row<T>, r1: Row<T>) => boolean;
  checkboxes?: (keyof T)[];
  comboboxes?: { [key in keyof Partial<T>]: { val: string; txt: string }[] };
  editables?: (keyof T)[];
  widths?: { [key in keyof Partial<T>]: number };
  fixedWidth?: number;
  schema?: SchemaType;
  footers?: {
    [key in keyof Partial<T>]: string | ((data: T[]) => SimpleGridDataType);
  };
  footStylers?: {
    [key in keyof Partial<T>]: (data: T[]) => React.CSSProperties | null;
  };
}

//export const isColDef = <T extends IId>(
//  columns: (keyof T)[] | ColumnDef<T, unknown>[],
//) => columns.some((v) => typeof v !== 'number' && typeof v !== 'string');

export function getColumnDefs<T extends IId>(
  //columns: (keyof T)[] | ColumnDef<T, unknown>[],
  columns: ExtractKeys<T>[],
  args?: ColumnArgs<T>,
  frozenId?: boolean,
): ColumnDef<T, unknown>[] {
  const {
    accessFns,
    headers,
    headerGroups,
    dftFormatter,
    formatters,
    dftStyler,
    stylers,
    titles,
    rowSpanFns,
    dftRowSpanFn,
    checkboxes,
    comboboxes,
    editables,
    widths,
    fixedWidth,
    schema,
    footers,
    footStylers,
  } = args ?? {};

  const columnHelper = createColumnHelper<T>();
  //const colDef = isColDef(columns);
  const cols = columns.map((c, i) => {
    //const f = (colDef ? (c as ColumnDef<T, unknown>).id : c) as keyof T;
    const f = c

    const formatter =
      formatters?.[f] ??
      ((v, r, ctx) => dftFormatter?.(v, f, r, ctx) ?? dftFallbackFormatter(v));
    const styler = stylers?.[f] ?? ((v, r) => dftStyler?.(v, f, r));
    let type =
      ifesleExpr(
        [checkboxes?.includes(f), ColumnType.Checkbox],
        [
          comboboxes && Object.prototype.hasOwnProperty.call(comboboxes, f),
          ColumnType.Combobox,
        ],
      ) ?? ColumnType.Text;
    let valTxts = type === ColumnType.Combobox ? comboboxes?.[f] : undefined;
    let frozen: boolean | undefined;
    if (!!editables?.length && !editables.contains(f)) frozen = true;
    if (frozenId && f === 'Id') frozen = true;

    if (schema && schema.shape[f]) {
      const def: {
        typeName: ZodFirstPartyTypeKind;
        innerType?: {
          typeName: ZodFirstPartyTypeKind;
          _def?: {
            innerType?: { _def?: { typeName: ZodFirstPartyTypeKind } };
          };
        };
        // eslint-disable-next-line no-underscore-dangle
      } = schema.shape[f]._def;

      if (def.typeName === ZodFirstPartyTypeKind.ZodReadonly) {
        frozen = true;
      }

      if (type === ColumnType.Text) {
        const isTy = (ty: ZodFirstPartyTypeKind) => {
          if (def.typeName === ty || def.innerType?.typeName === ty) {
            return true;
          }
          // nullable boolean 일 경우 _def 로 체크해야.
          // eslint-disable-next-line
          if ((def.innerType?._def as any)?.typeName === ty) {
            if (
              ty.isIn(
                ZodFirstPartyTypeKind.ZodNativeEnum,
                ZodFirstPartyTypeKind.ZodEnum,
              )
            ) {
              schema.shape[f].enum =
                // eslint-disable-next-line no-underscore-dangle
                schema.shape[f]._def.innerType.enum;
            }
            return true;
          }

          // eslint-disable-next-line
          if (def.innerType?._def?.innerType?._def?.typeName === ty) {
            if (
              ty.isIn(
                ZodFirstPartyTypeKind.ZodNativeEnum,
                ZodFirstPartyTypeKind.ZodEnum,
              )
            ) {
              schema.shape[f].enum =
                // eslint-disable-next-line no-underscore-dangle
                schema.shape[f]._def.innerType._def.innerType.enum;
            }
            return true;
          }
          return false;
        };

        if (
          !frozen &&
          (isTy(ZodFirstPartyTypeKind.ZodNativeEnum) ||
            isTy(ZodFirstPartyTypeKind.ZodEnum))
        ) {
          type = ColumnType.Combobox;
          valTxts = Object.entries(schema.shape[f].enum).map(([k, v]) => ({
            val: v as string,
            txt: k as string,
          }));
          valTxts = [{ val: '', txt: '' }, ...valTxts]; // null 값 선택용
        }

        if (isTy(ZodFirstPartyTypeKind.ZodBoolean)) {
          type = ColumnType.Checkbox;
        }
      }
    }

    const footer = footers?.[f];
    const footStyler = footStylers?.[f];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const col: any = {
      header: (headers ?? columns)[i],
      footer:
        typeof footer === 'function'
          ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (h: any) =>
            (footer as any)(
              h.table.getRowModel().flatRows.map((v: Row<T>) => v.original),
            )
          : footer,
      meta: {
        formatter,
        styler,
        title: titles?.[f],
        rowSpanFn:
          rowSpanFns?.[f] ??
          (dftRowSpanFn ? (r0, r1) => dftRowSpanFn(f, r0, r1) : undefined),
        type,
        valTxts,
        frozen,
        footStyler,
      },
    };
    const size =
      fixedWidth ??
      widths?.[f] ??
      getDftColWidth(f as string) ??
      (type === ColumnType.Checkbox ? 50 : undefined);

    if (size) col.size = size;

    //if (colDef) {
    //  // eslint-disable-next-line no-param-reassign
    //  const cdef = c as ColumnDef<T, unknown>;
    //  cdef.meta = col.meta;
    //  if (size) cdef.size = size;
    //  return cdef;
    //}
    if (accessFns) {
      col.id = f;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return columnHelper.accessor(accessFns[i] as any, col);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return columnHelper.accessor(f as any, col);
  });

  if (headerGroups) {
    let [c0, c1] = [0, 0];
    return headerGroups.map((r) => {
      c0 = c1;
      c1 = c0 + r[1];
      return columnHelper.group({
        id: `_${r[0]}`,
        header: () => <span>{r[0]}</span>,
        columns: cols.slice(c0, c1),
      });
    });
  }

  return cols;
}

export type SimpleGridArgs<T extends IId> = ColumnArgs<T> & {
  title?: string;
  titleNode?: JSX.Element;
  meta?: TableMeta<T>;
  infoMsg?: string;
  errMsg?: string;
  footNote?: string;
  onStateChange?: React.Dispatch<
    React.SetStateAction<DataGridState<T> | undefined>
  >;
  pageSize?: number; // 페이지당 행수, 값 있으면 페이징
  currPage?: number;
};

export default function SimpleGrid<T extends IId>({
  data,
  columns,
  headers,
  args,
}: {
  data: T[];
  // columns: (keyof T)[] | ColumnDef<T, unknown>[];
  columns: ExtractKeys<T>[];
  headers?: string[];
  args?: SimpleGridArgs<T>;
}) {
  const {
    title,
    titleNode,
    meta,
    infoMsg,
    errMsg,
    footNote,
    onStateChange,
    pageSize,
    currPage: initialCurrPage,
    ...colArgs0
  } = args ?? {};
  const colArgs = headers ? { ...colArgs0, headers } : colArgs0;
  const cols = getColumnDefs(columns, colArgs, true);

  const [pages, setPages] = useState<T[][]>([]);
  const paging = !!pageSize && pageSize > 0;
  const [currPage, setCurrPage] = useState(initialCurrPage ?? 0);

  useEffect(() => {
    if (paging) {
      const paged = _.chunk(data, pageSize);
      setPages(paged);
      if (paged.length <= currPage) {
        setCurrPage(0);
      }
    }
  }, [data, pageSize]);

  useEffect(() => {
    setCurrPage(initialCurrPage ?? 0);
  }, [initialCurrPage]);

  const setCurrPageSafe = (i: number) =>
    setCurrPage(Math.max(0, Math.min(pages.length - 1, i)));

  let showingPages = Math.min(pages.length, 5);
  let page0 = Math.max(0, currPage - 2);
  let page1 = Math.min(pages.length, currPage + 2);
  if (page0 === 0) page1 += showingPages - (page1 - page0);
  if (page1 === pages.length) page0 -= showingPages - (page1 - page0);

  return (
    <div className="children-mb-2">
      {title && <b>{title}</b>}
      {!!titleNode && titleNode}
      {infoMsg && (
        <div className="alert alert-slim alert-info like-pre">{infoMsg}</div>
      )}
      {errMsg && (
        <div className="alert alert-slim alert-danger like-pre">{errMsg}</div>
      )}
      <DataGrid
        data={paging ? pages[currPage] ?? [] : data}
        columns={cols}
        meta={meta}
        onStateChange={onStateChange}
      />
      {footNote && <div style={{ fontSize: 10 }}>{footNote}</div>}
      {paging && (
        <div className="children-me-1 d-flex justify-content-center">
          <Button text label="<<" onClick={() => setCurrPage(0)} />
          <Button
            text
            label="<"
            onClick={() => setCurrPageSafe(currPage - 1)}
          />
          {_.range(page0, page1).map((i) =>
            i === currPage ? (
              <Button
                label={String(i + 1)}
                className="btn btn-sm btn-primary"
                key={i}
                onClick={() => setCurrPage(i)}
              />
            ) : (
              <Button
                text
                label={String(i + 1)}
                key={i}
                onClick={() => setCurrPage(i)}
              />
            ),
          )}
          <Button
            text
            label=">"
            onClick={() => setCurrPageSafe(currPage + 1)}
          />
          <Button
            text
            label=">>"
            onClick={() => setCurrPage(pages.length - 1)}
          />
        </div>
      )}
    </div>
  );
}
