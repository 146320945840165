import React from 'react';

interface SpecialStrgs {
  aiTfim: string[];
  advisor: string[];
  junior: string[]; // 주니어 여부는 시간이 지나면 바뀌지만 주문창서만 쓰면 당일 기준으로 충분
}

export interface Bookmark {
  Id: number;
  url: string;
  title: string;
  ord: number;
  react: boolean;
}

interface UncheckedReports {
  cnt: number;
  subj: string;
}

export class AppInfo {
  readonly currBizDay: string;

  readonly nextBizDay: string;

  readonly isDevTest: boolean;

  readonly dftVh: string | null;

  readonly dftSt: string | null;

  readonly strgs: SpecialStrgs; // 사용자별로 자기가 읽기 가능한 전략들만.

  readonly showHelp: boolean;

  readonly helpIds: Set<string>;

  readonly bookmarks: Bookmark[];

  readonly uncheckedNotiCnt: number;

  readonly uncheckedReports: UncheckedReports;

  constructor(
    currBizDay: string,
    nextBizDay: string,
    isDevTest: boolean,
    dftVh: string | null,
    dftSt: string | null,
    strgs: SpecialStrgs,
    showHelp: boolean,
    helpIds: string[] | Set<string>,
    bookmarks: Bookmark[],
    uncheckedNotiCnt: number,
    uncheckedReports: UncheckedReports,
  ) {
    this.currBizDay = currBizDay;
    this.nextBizDay = nextBizDay;
    this.isDevTest = isDevTest;
    this.dftVh = dftVh;
    this.dftSt = dftSt;
    this.strgs = strgs;
    this.showHelp = showHelp;
    this.helpIds = new Set(helpIds);
    this.bookmarks = bookmarks;
    this.uncheckedNotiCnt = uncheckedNotiCnt;
    this.uncheckedReports = uncheckedReports;
  }

  isAiPe(stId: string): boolean {
    return this.strgs.aiTfim.contains(stId);
  }

  isAdvisor(stId: string): boolean {
    return this.strgs.advisor.contains(stId);
  }

  isJunior(stId: string): boolean {
    return this.strgs.junior.contains(stId);
  }
}

export type FormEventTy = React.FormEvent<HTMLFormElement>;

export type ChangeEventTy = React.ChangeEvent<HTMLInputElement>;

export const DftBtnStyleMx = 'btn btn-sm btn-outline-secondary ms-2';
export const DftBtnStyle = 'btn btn-sm btn-outline-secondary';
export const DftLinkBtnStyle = 'btn btn-sm btn-link';
