// https://soshace.com/react-user-login-authentication-using-usecontext-and-usereducer/
// https://codesandbox.io/s/login-authentication-usecontext-66t9t

import React, {
  useContext,
  createContext,
  useReducer,
  useState,
  useEffect,
  useRef,
} from 'react';
import { AxiosError } from 'axios';
import { uuidv7 } from 'uuidv7';
import { getAxiosErrorMsgs } from '../util/axiosutils';
import { switchExpr } from '../util/utils';

import './messagecontext.css';
import Button from '../ui/Button';

export type Alert = (
  msg: string | string[] | AxiosError,
  fallbackMsg?: string,
) => void;

export type ToastType = 'success' | 'error'

export type MessageBox = {
  alert: Alert;
  confirm: (msg: string) => Promise<boolean>;
  prompt: (msg: string, dft?: string) => Promise<string | null>;
  toast: (msg: string, ty?: ToastType) => void;
};

export type LogLevel = 'info' | 'warning' | 'error';
export type Logger = {
  addLog: (level: LogLevel, msg: string, header?: string) => void;
  addLogs: (level: LogLevel, msgs: string[], header?: string) => void;
};

interface MessageState {
  msgBox: MessageBox;
  logger: Logger;
}

const initialState: MessageState = {
  msgBox: {
    alert: (msg, fallbackMsg) =>
      // eslint-disable-next-line no-alert
      window.alert(getAxiosErrorMsgs(msg, fallbackMsg).join('\n')),
    // eslint-disable-next-line no-alert
    confirm: async (msg: string) => window.confirm(msg),
    // eslint-disable-next-line no-alert
    prompt: async (msg: string, dft?: string) => window.prompt(msg, dft),
    toast: (msg: string, ty?: ToastType) => window.alert(msg),
  },
  logger: {
    addLog: () => {
      // do nothing
    },
    addLogs: () => {
      // do nothing
    },
  },
};

type MessageAction =
  | {
      type: 'SET_MSGBOX';
      msgBox: {
        alert: (msg: string) => void;
        confirm: (msg: string) => Promise<boolean>;
        prompt: (msg: string, dft?: string) => Promise<string | null>;
        toast: (msg: string, ty?: ToastType) => void;
      };
    }
  | {
      type: 'SET_LOGGER';
      logger: {
        addLog: (level: LogLevel, msg: string, header?: string) => void;
        addLogs: (level: LogLevel, msgs: string[], header?: string) => void;
      };
    };

const MessageStateContext = createContext(initialState);
const MessageDispatchContext = createContext<React.Dispatch<MessageAction>>(
  () => {
    // do nothing
  },
);

export function useMessageState() {
  const context = useContext(MessageStateContext);
  if (context === undefined) {
    throw new Error('useMessageState must be used within a MessageProvider');
  }
  return context;
}

export function useMessageDispatch() {
  const context = useContext(MessageDispatchContext);
  if (context === undefined) {
    throw new Error('useMessageDispatch must be used within a MessageProvider');
  }
  return context;
}

function messageReducer(
  state: MessageState,
  action: MessageAction,
): MessageState {
  switch (action.type) {
    case 'SET_MSGBOX':
      return {
        ...state,
        msgBox: {
          alert: (msg, fallbackMsg) =>
            action.msgBox.alert(getAxiosErrorMsgs(msg, fallbackMsg).join('\n')),
          confirm: action.msgBox.confirm,
          prompt: action.msgBox.prompt,
          toast: action.msgBox.toast,
        },
      };

    case 'SET_LOGGER':
      return { ...state, logger: action.logger };

    default:
      return { ...state };
  }
}

type Log = {
  id: string;
  t: Date;
  level: LogLevel;
  msg: string;
  header?: string;
};

const log2str = (v: Log) => {
  const header = v.header ? `[${v.header}] ` : '';
  return `[${v.t.toFormatString('HH:mm:ss')}] ${header}${v.msg}`;
};

export function MessageProvider({
  useLogging,
  children,
}: {
  useLogging: boolean;
  children: JSX.Element;
}) {
  const [messageState, dispatch] = useReducer(messageReducer, initialState);
  // https://levelup.gitconnected.com/how-to-build-a-generic-reusable-synchronous-like-confirmation-dialog-in-react-js-71e32dfa495c
  const [dialogType, setDialogType] = useState<
    'alert' | 'confirm' | 'prompt' | 'toast'
  >('alert');
  const [dialogMsg, setDialogMsg] = useState<string>();
  const [toastType, setToastType] = useState<ToastType | undefined>();
  const [userInput, setUserInput] = useState<string | null>(null);
  const userInputRef = useRef<HTMLInputElement | null>(null);
  const dialogRef = useRef<HTMLDialogElement | null>(null);
  const okButtonRef = useRef<HTMLButtonElement | null>(null);
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const confirmResolver = useRef<(value: boolean) => void>();
  const promptResolver = useRef<(value: string | null) => void>();
  const [dialogVisible, setDialogVisible] = useState(false);

  const [logs, setLogs] = useState<Log[]>([]);
  const [showLogs, setShowLogs] = useState(false);

  const showDialog = (modaless = false) => {
    dialogRef.current?.close();
    if (modaless) dialogRef.current?.show();
    else dialogRef.current?.showModal();
    setDialogVisible(true);
  };

  const closeDialog = () => {
    dialogRef.current?.close();
    setDialogVisible(false);
  };

  let closeTimeoutId: any = undefined;

  const alert = (msg: string) => {
    if (closeTimeoutId) clearTimeout(closeTimeoutId)
    setDialogType('alert');
    setDialogMsg(msg);
    showDialog();
  };

  const confirm = (msg: string) => {
    if (closeTimeoutId) clearTimeout(closeTimeoutId)
    setDialogType('confirm');
    setDialogMsg(msg);
    showDialog();
    return new Promise((resolve: (value: boolean) => void) => {
      confirmResolver.current = resolve;
    });
  };

  const prompt = (msg: string, dft?: string) => {
    if (closeTimeoutId) clearTimeout(closeTimeoutId)
    setDialogType('prompt');
    setDialogMsg(msg);
    setUserInput(dft ?? '');
    showDialog();
    return new Promise((resolve: (value: string | null) => void) => {
      promptResolver.current = resolve;
    });
  };

  const toast = (msg: string, ty?: ToastType) => {
    if (closeTimeoutId) clearTimeout(closeTimeoutId);
    setDialogType('toast');
    setToastType(ty);
    setDialogMsg(msg);
    showDialog(true);
    closeTimeoutId = setTimeout(() => closeDialog(), 3000);
  };

  const setFocus = () => {
    if (dialogType === 'prompt') userInputRef?.current?.focus();
    else if (dialogType === 'toast') closeButtonRef?.current?.focus();
    else okButtonRef?.current?.focus();    
  };

  useEffect(() => setFocus(), [dialogType, dialogVisible]);

  const handleOk = () => {
    if (dialogType === 'confirm' && confirmResolver.current)
      confirmResolver.current(true);
    if (dialogType === 'prompt' && promptResolver.current)
      promptResolver.current(userInput);
    closeDialog();
  };

  const handleCancel = () => {
    if (dialogType === 'confirm' && confirmResolver.current)
      confirmResolver.current(false);
    if (dialogType === 'prompt' && promptResolver.current)
      promptResolver.current(null);
    closeDialog();
  };

  const addLogs = (level: LogLevel, msgs: string[], header?: string) => {
    const t = new Date();
    setLogs((old) => {
      const n = old.length;
      const oldKeep = n > 100 ? old.slice(n - 100) : old;
      return oldKeep.concat(
        msgs.map((msg) => ({ id: uuidv7(), t, level, msg, header })),
      );
    });
  };

  useEffect(() => {
    dispatch({ type: 'SET_MSGBOX', msgBox: { alert, confirm, prompt, toast } });
    dispatch({
      type: 'SET_LOGGER',
      logger: {
        addLog: (level, msg, header) => addLogs(level, [msg], header),
        addLogs: (level, msgs, header) => addLogs(level, msgs, header),
      },
    });
  }, [dispatch]);

  const toggleShowLogs = () => {
    setShowLogs((v) => !v);
  };
  const clearLogs = () => setLogs([]);

  const footer = (
    <div className="d-flex justify-content-end children-ms-2">
      {dialogType !== 'alert' && (
        <Button onClick={handleCancel} btnRole="Cancel" />
      )}
      <Button onClick={handleOk} btnRole="Ok" innerRef={okButtonRef} />
    </div>
  );

  const msgBoxHeader = switchExpr(
    dialogType,
    ['alert', '경고'],
    ['confirm', '확인'],
    ['prompt', '입력'],
    ['toast', toastType === 'error' ? 'ERROR' : ''],
  );
  const headerClass = dialogType === 'toast' && toastType === 'error'
    ? 'alert alert-danger alert-slim' : ''

  const msgBoxClass = dialogType === 'toast' ? 'fade-out-box' : ''

  const mainMarginBottom = '200px'; // 로그창 최대높이 170px
  const logBadgeCls = (level: LogLevel, middle = false) => {
    const c = level === 'error' ? 'danger' : level;
    return `log-badge-${c} position-absolute top-0 start-${
      middle ? 50 : 100
    } translate-middle bg-${c} rounded-circle`;
  };

  // const logTest = () => {
  //   addLogs('warning', ['aaa', 'bbb', 'ccc', 'ddd', 'eee']);
  //   addLogs('error', ['AAA', 'BBB', 'CCC', 'DDD', 'EEE']);
  // };
  // useEffect(logTest, []);

  const nErrors = logs.filter((v) => v.level === 'error').length;
  const nWarnings = logs.filter((v) => v.level === 'warning').length;
  return (
    <MessageStateContext.Provider value={messageState}>
      <MessageDispatchContext.Provider value={dispatch}>
        <div style={{ marginBottom: mainMarginBottom }}>{children}</div>
        {useLogging && (
          <div
            style={{
              position: 'fixed',
              width: '100%',
              bottom: 10,
              left: 10,
            }}
            className="d-flex align-items-start"
          >
            <div
              className="align-self-end"
              style={{ backgroundColor: 'white' }}
            >
              <button
                type="button"
                className="btn btn-secondary btn-sm position-relative"
                onClick={() => toggleShowLogs()}
              >
                {showLogs ? '로그창 닫기' : '로그창 열기'}
                {nWarnings > 0 && (
                  <span
                    className={logBadgeCls('warning', nErrors > 0)}
                    style={{ minWidth: '20px' }}
                  >
                    {nWarnings > 9 ? '9+' : nWarnings}
                  </span>
                )}
                {nErrors > 0 && (
                  <span
                    className={logBadgeCls('error')}
                    style={{ minWidth: '20px' }}
                  >
                    {nErrors > 9 ? '9+' : nErrors}
                  </span>
                )}
              </button>
              <button
                type="button"
                className="btn btn-link py-1 px-2 mt-3"
                title="로그 내역 삭제"
                onClick={() => clearLogs()}
              >
                x
              </button>
            </div>
            {showLogs && (
              <div className="logBox-scroller">
                <div className="logBox">
                  {logs.length === 0 && 'no log'}
                  {logs.map((l) => (
                    <div key={l.id} className={`like-pre log log-${l.level}`}>
                      {log2str(l)}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        <dialog
          className={`msgBox ${msgBoxClass}`}
          ref={dialogRef}
        >
          <div className="d-flex justify-content-between">
            <header className={headerClass}>{`[TMS] ${msgBoxHeader}`}</header>
            {dialogType === 'toast' && (
              <Button className="btn-close" innerRef={closeButtonRef} onClick={() => closeDialog()} />
            )}
          </div>
          <hr className="narrow light" />
          <pre style={{ marginBottom: '0.2rem' }}>{dialogMsg}</pre>
          {dialogType === 'prompt' && (
            <input
              ref={userInputRef}
              type="text"
              name="promptInput"
              value={userInput ?? ''}
              onChange={(e) => setUserInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleOk();
                }
                if (e.key === 'Esc') {
                  e.preventDefault();
                  handleCancel();
                }
              }}
              style={{ width: '90%' }}
            />
          )}
          {dialogType !== 'toast' && (
            <>
              <hr className="narrow light" />
              {footer}
            </>
          )}
        </dialog>
      </MessageDispatchContext.Provider>
    </MessageStateContext.Provider>
  );
}
