import React from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';
import DataGrid from 'tmslib/src/table/DataGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { LendReq, LendReqState } from '../../../Tms/Loan';
import { FuncCall } from '../../../tmsutil';

const columnHelper = createColumnHelper<LendReq>();
const columns = [
  columnHelper.accessor('prodId', { header: '종목' }),
  columnHelper.accessor('prodNm', { header: '종목명', size: 120 }),
  columnHelper.accessor('sgdQty', { header: '수량' }),
  columnHelper.accessor('toHoldRto', {
    header: '잔고대비(%)',
    meta: { formatter: (v) => v?.toFixed(1) },
  }),
  columnHelper.accessor('genNm', { header: '요청자' }),
  columnHelper.accessor('state', { header: '상태' }),
].map((v) => v as ColumnDef<LendReq, unknown>);

export default function LoanOfferReqTab({
  call,
  data,
}: {
  call: FuncCall;
  data: LendReq[];
}) {
  const { msgBox: m } = useMessageState();
  const approve = (items: LendReq[]) => {
    const reqs = items.filter((v) => v.state === LendReqState.승인대기);
    if (!reqs.length) {
      m.alert('승인 대기 중인 대여 지시 없음');
      return;
    }

    const par = { ids: reqs.map((v) => v.Id) };
    call('ApproveLoanOfferReqBatch', par);
  };

  const meta: TableMeta<LendReq> = {
    maxHeight: 160,
    dftColWidth: 80,
    contextMenus: [
      {
        label: '일괄 승인',
        callback: approve,
      },
    ],
  };
  return <DataGrid data={data} columns={columns} meta={meta} />;
}
