import React from 'react';
import { VhGrpTy } from '../../../Tms/Tms';

export type Stat = {
  Id: number;
  todayTu: number | null;
  pfMrgnWei: number | null;
  pfMrgnWeiTgt: number | null;
  pfMrgnWeiTeam: number | null;
  maxCashRto: number | null;
  navAvg: number | null;
  allocPrc: number | null;
  bufUB: number | null;
  bufLB: number | null;
};

export default function StatBar({
  vhGrp,
  stat,
  isAdvisor,
}: {
  vhGrp: VhGrpTy | null;
  stat: Stat | null;
  isAdvisor: boolean;
}) {
  const statColor = 'darkred';
  return !stat ? null : (
    <span style={{ fontWeight: 'bold' }} className="children-me-3">
      {vhGrp === VhGrpTy.HFund && (
        <>
          <span>
            5일 기준가 &nbsp;
            <span style={{ color: statColor }}>
              (<span title="현재">{stat?.navAvg?.toFixed(2)}</span> |
              <span title="배분적용값">{stat?.allocPrc?.toFixed(2)}</span>)
            </span>
          </span>
          <span>
            배분 버퍼 &nbsp;
            <span style={{ color: statColor }}>
              (<span title="상한">{stat?.bufUB?.toFixed(2)}</span> |
              <span title="하한">{stat?.bufLB?.toFixed(2)}</span>)
            </span>
          </span>
        </>
      )}
      <span>
        당일 회전율 &nbsp;
        <span style={{ color: statColor }}>{stat?.todayTu?.toFixed(0)}%</span>
      </span>
      {!isAdvisor && (
        <>
          <span>
            현금 소진율 &nbsp;
            <span style={{ color: statColor }}>
              (<span title="현재">{stat?.pfMrgnWei?.toFixed(1)}</span> |
              <span title="종가">{stat?.pfMrgnWeiTgt?.toFixed(1)}</span>) /
              <span title="규정">{stat?.maxCashRto?.toFixed(1)}</span>%
            </span>
          </span>
          {stat?.pfMrgnWeiTeam && (
            <span>
              팀 현금 소진율 &nbsp;
              <span style={{ color: statColor }}>
                <span title="현재">{stat?.pfMrgnWeiTeam?.toFixed(1)}</span>%
              </span>
            </span>
          )}
        </>
      )}
    </span>
  );
}
