import { z } from 'zod';
import { zodNumber, zodDate } from './ZodType';

export interface MafsPos {
  Id: number;
  d: string;
  fundCd: string;
  fundNm: string;
  astTy: string;
  astNm: string;
  secTy: string;
  secNm: string;
  krCd: string;
  shortCd: string | null;
  prodNm: string;
  yPos: number;
  yAmt: number;
  bQty: number;
  bAmt: number;
  sQty: number;
  sAmt: number;
  pos: number;
  amt: number;
  prc: number;
  rzPl: number;
  unrzPl: number;
  totPl: number;
  errMsg: string | null;
  toSkip: boolean;
  isDiv: boolean;
  evm: string;
}

export interface MafsNoMtmPrc {
  Id: number;
  d: string;
  fundCd: string;
  vhId: string;
  prodId: string;
  krCd: string;
  prodNm: string;
  pos: number;
  amt: number;
  prc: number;
}

export interface MafsBs {
  Id: number;
  d: string;
  fundCd: string;
  fundNm: string;
  itemCd: string;
  itemNm: string;
  debtY: number;
  debtDiff: number;
  debt: number;
  creditY: number;
  creditDiff: number;
  credit: number;
  errMsg: string | null;
}

export interface MafsPdf {
  Id: number;
  d: string;
  fundCd: string;
  vhId: string;
  krCd: string;

  prodNm: string;
  pos: number;
  prc: number;
  amt: number;
  wei: number;
  errMsg: string | null;
}

export const mafsPdfSchema = z.object({
  Id: z.number(),
  d: zodDate,
  fundCd: z.string(),
  vhId: z.string(),
  krCd: z.string(),
  prodNm: z.string(),
  pos: z.number(),
  prc: z.number(),
  amt: z.number(),
  wei: z.number(),
  errMsg: z.string().nullish(),
});

export enum RstrTy {
  None = 'None',
  Right = 'Right',
  Bonus = 'Bonus',
  Lock = 'Lock',
  Mez = 'Mez',
  Div = 'Div',
  Split = 'Split',
  Tras = 'Tras',
  Promise = 'Promise',
}

export interface Rec {
  cd: string;
  nm: string | null;
  rstr: RstrTy;
  y: number;
  b: number;
  s: number;
  pos: number;
  amt: number;
  div: number;
  prft: number;
}

export interface MafsCmprRes {
  Id: string;
  prodId: string;
  prodNm: string | null;
  rstr: RstrTy;
  'tf.y': number | undefined;
  'ma.y': number | undefined;
  'tf.b': number | undefined;
  'ma.b': number | undefined;
  'tf.s': number | undefined;
  'ma.s': number | undefined;
  'tf.pos': number | undefined;
  'ma.pos': number | undefined;
  'tf.amt': number | undefined;
  'ma.amt': number | undefined;
  'tf.div': number | undefined;
  'ma.div': number | undefined;
  'tf.prft': number | undefined;
  'ma.prft': number | undefined;
  diffPos: number;
  diffPrft: number;
  memo: string | null;

  tf?: { [key: string]: number };
  ma?: { [key: string]: number };
  vTot: string | null;
}

export enum AcctTy {
  당기순손익 = '당기순손익',
  기타비용 = '기타비용',
  기타수익 = '기타수익',
  대차비용 = '대차비용',
}
export const boMafsBSAcctAcctTySchema = z.nativeEnum(AcctTy);

export enum Side {
  차변 = '차변',
  대변 = '대변',
}
export const boMafsBSAcctSideSchema = z.nativeEnum(Side);

export interface BoMafsBSAcct {
  Id: string;
  acctCd: string;
  acctTy: AcctTy;
  side: Side;
  nm: string;
}

export const boMafsBSAcctSchema = z.object({
  acctCd: z.string(),
  acctTy: boMafsBSAcctAcctTySchema,
  side: boMafsBSAcctSideSchema,
  nm: z.string(),
});

export interface BoPrftFixing {
  Id: string;
  d: string;
  vhId: string;
  amt: number;
  memo: string;
}

export interface VeriPrdRes {
  Id: string;
  vh: string;
  sd: string;
  diff: number;
  prftFixing: number;
  mafsCost1: number;
  mafsCost2: number;
  mafsPrft: number;
  mafsPrftBfCost: number;
  tmsCost1: number;
  tmsCost2: number;
  tmsCost3: number;
  tmsPrft: number;
  tmsPrftBfCost: number;
  msg: string;
}

export const boPrftFixingSchema = z.object({
  d: zodDate,
  vhId: z.string(),
  amt: zodNumber(false),
  memo: z.string().nullish(),
});

export interface BoReGenPosBSRsn {
  Id: string;
  d: string;
  d0: string;
  d1: string;
  rsn: string;
  userid: string;
}
export const boReGenPosBSRsnSchema = z.object({
  d: zodDate,
  d0: zodDate,
  d1: zodDate,
  rsn: z.string(),
  userid: z.string().nullish(),
});

export interface StrgPrftPrd {
  Id: string;
  stId: string;
  nm2: string | null;
  prft?: { [key: string]: number };
}
