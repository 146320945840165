export enum CalendarType {
  Default = 'Default',
  Seminar = 'Seminar',
  Memo = 'Memo',
  CmplAi = 'CmplAi',
  IPO = 'IPO',
  SeminarSinga = 'SeminarSinga',
}

export enum SeminarTy {
  VS = 'VS',
  IR = 'IR',
  SMR = 'SMR',
  NDR = 'NDR',
  AI = 'AI',
  Special = 'Special',
  Request = 'Request',
}

export interface Calendar {
  Id: number;
  ty: CalendarType;
  d: string;
  dt: string;
  hm: string;
  userId: string;
  memo: string | null;
  prodId: string | null;
  userNm: string | null;
  prodNm: string | null;
  smrTy: SeminarTy;
  smrTyDesc: string;
  callerId: string;
  callerNm: string;
  brkId: string;
  brkNm: string;
  score: number;
  subTitle: string | null;
  subTitleColor: string | null;
}
