import React, { useRef, useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import { DataGridState } from 'tmslib/src/table/DataGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { parseBool } from 'tmslib/src/util/utils';
import { VhGrpTy, GetVhGrpName } from '../../../Tms/Tms';
import {
  RtDiff,
  updateRtObj,
  updateRtRows,
  setRtEffect,
} from '../../../rtutil';

import {
  OrdSt,
  OrdStLog,
  OrdVh,
  OrdState,
  OrdProcState,
  LogWithId /* , OrdLog, OrdAmtLog */,
} from '../../../Tms/Ord';
import { ConfirmInfo } from '../../../Tms/Loan';
import {
  AlgoOrder,
  AlgoTag,
  AlgoVh,
  AlgoAcct,
  AlgoSlice,
  AlgoSliceCncl,
  AlgoStat,
  FixDispMsg,
  EmsxRouteMsg,
} from '../../../Tms/Ems';
import OrdStateBar from './OrdStateBar';
import OrderInputBar from '../../Front/Target/OrderInputBar';
import OrdStTab from './OrdStTab';
import OrdVhTab from './OrdVhTab';
import AlgoOrderTab from './AlgoOrderTab';
import AlgoTagTab from './AlgoTagTab';
import AlgoVhTab from './AlgoVhTab';
import AlgoPrgsChart from './AlgoPrgsChart';
import AlgoAcctTab from './AlgoAcctTab';
import AlgoSliceTab from './AlgoSliceTab';
import AlgoSliceCnclTab from './AlgoSliceCnclTab';
import FixMsgTab from './FixMsgTab';
import EmsxMsgTab from './EmsxMsgTab';

import './ordproc.scss';
import ProcBar from './ProcBar';
import BatchBar from './BatchBar';
import BatchOrdTab from '../../Front/Target/BatchOrdTab';
import OrdLogTab from './OrdLogTab';
import OrdStLogTab from './OrdStLogTab';
import AlgoBatchTab from './AlgoBatchTab';
import AlgoSliceBatchTab from './AlgoSliceBatchTab';
import AlgoStatTab from './AlgoStatTab';

import WarningBar from './WarningBar';
import { useAuthState } from '../../Auth/AuthContext';
import { OrderProvider } from '../../Front/Target/OrderContext';
import DateSelector from '../../../shared/DateSelector';
import { UserGroup } from '../../../Tms/Identity';
import { genFuncCall } from '../../../tmsutil';

type OrdProcRes = {
  t: number;
  state: OrdState | null;
  state_upd: { [key: string]: object | null } | null;
  ordProcState: OrdProcState | null;
  ordProcState_upd: RtDiff<OrdProcState> | null;
  cnfrmNeeded: ConfirmInfo[] | null;
  cnfrmNeeded_diff: RtDiff<ConfirmInfo> | null;
  cnfmLogs: LogWithId[] | null;
  cnfmLogs_diff: RtDiff<LogWithId> | null;
  ordLastLogs: LogWithId[] | null;
  ordLastLogs_diff: RtDiff<LogWithId> | null;
  amtLastLogs: LogWithId[] | null;
  amtLastLogs_diff: RtDiff<LogWithId> | null;
  lastLogId: number | null;
  lastAmtId: number | null;

  osts: OrdSt[] | null;
  osts_diff: RtDiff<OrdSt> | null;
  ovhs: OrdVh[] | null;
  ovhs_diff: RtDiff<OrdVh> | null;
  algos: AlgoOrder[] | null;
  algos_diff: RtDiff<AlgoOrder> | null;
  altags: AlgoTag[] | null;
  altags_diff: RtDiff<AlgoTag> | null;
  alvhs: AlgoVh[] | null;
  alvhs_diff: RtDiff<AlgoVh> | null;
  alaccts: AlgoAcct[] | null;
  alaccts_diff: RtDiff<AlgoAcct> | null;
  slices: AlgoSlice[] | null;
  slices_diff: RtDiff<AlgoSlice> | null;
  slcCncls: AlgoSliceCncl[] | null;
  slcCncls_diff: RtDiff<AlgoSliceCncl> | null;
  fixMsgs: FixDispMsg[] | null;
  fixMsgs_diff: RtDiff<FixDispMsg> | null;
  emsxMsgs: EmsxRouteMsg[] | null;
  emsxMsgs_diff: RtDiff<EmsxRouteMsg> | null;
  algoId: number | null;

  algoBatch: AlgoOrder[] | null;
  algoBatch_diff: RtDiff<AlgoOrder> | null;
  algoSliceBatch: AlgoSlice[] | null;
  algoSliceBatch_diff: RtDiff<AlgoSlice> | null;
  ordStLog: OrdStLog[] | null;
  ordStLog_diff: RtDiff<OrdStLog> | null;
  algoStat: AlgoStat[] | null;
  algoStat_diff: RtDiff<AlgoStat> | null;
};

export default function OrdProcMain() {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    user,
    connection,
    connected,
    info,
  } = useAuthState();
  const { msgBox: m, logger } = useMessageState();

  const vhGrps =
    user?.Group === UserGroup.MgtOper
      ? [VhGrpTy.TFIM]
      : [
        VhGrpTy.HFund,
        VhGrpTy.VFund,
        VhGrpTy.AIFund,
        VhGrpTy.TFIM,
        VhGrpTy.PAFund,
        VhGrpTy.HBFund,
        VhGrpTy.ETF,
        VhGrpTy.PEFund,
        VhGrpTy.Troika,
        VhGrpTy.FoHFs,
        VhGrpTy.VLS,
      ];

  const d = searchParams.get('d') || info?.currBizDay || '';
  const vhGrp: VhGrpTy = (searchParams.get('vhGrp') || vhGrps[0]) as VhGrpTy;
  const hideDone: boolean = parseBool(searchParams.get('hideDone')) ?? false;

  const [IsRealTime, setIsRealTime] = useState<boolean>(true);
  const [LastSecs, setLastSecs] = useState<number | null>(60);
  const [BatchT0, setBatchT0] = useState<string | null>('');

  const [ordState, setOrdState] = useState<OrdState | null>(null);
  const [ordProcState, setOrdProcState] = useState<OrdProcState | null>(null);
  const [cnfrmNeeded, setCnfrmNeeded] = useState<ConfirmInfo[]>([]);
  const [cnfmLogs, setCnfmLogs] = useState<LogWithId[]>([]);
  const [ordLastLogs, setOrdLastLogs] = useState<LogWithId[]>([]);
  const [amtLastLogs, setAmtLastLogs] = useState<LogWithId[]>([]);
  const lastLogId = useRef<number | null>(0);
  const lastAmtId = useRef<number | null>(0);

  const [inMins, setInMins] = useState<number>(10);
  const [osts, setOsts] = useState<OrdSt[]>([]);

  const [OrdStDgState, SetOrdStDgState] = useState<DataGridState<OrdSt>>();

  const [currOstId, setCurrOstId] = useState<number>();
  const [ovhs, setOvhs] = useState<OrdVh[]>([]);
  const [algos, setAlgos] = useState<AlgoOrder[]>([]);
  const [currAlgoId, setCurrAlgoId] = useState<number>();
  const [altags, setAltags] = useState<AlgoTag[]>([]);
  const [alvhs, setAlvhs] = useState<AlgoVh[]>([]);
  const [currAlvhId, setCurrAlvhId] = useState<number>();
  const [alaccts, setAlaccts] = useState<AlgoAcct[]>([]);
  const [slices, setSlices] = useState<AlgoSlice[]>([]);
  const [currSliceId, setCurrSliceId] = useState<number>();
  const [slcCncls, setSlcCncls] = useState<AlgoSliceCncl[]>([]);
  const [fixMsgs, setFixMsgs] = useState<FixDispMsg[]>([]);
  const [emsxMsgs, setEmsxMsgs] = useState<EmsxRouteMsg[]>([]);

  const lastResT = useRef(0);
  const lastAlgoId = useRef<number | null>(null);

  const [algoBatch, setAlgoBatch] = useState<AlgoOrder[]>([]);
  const [algoSliceBatch, setAlgoSliceBatch] = useState<AlgoSlice[]>([]);
  const [ordStLog, setOrdStLog] = useState<OrdStLog[]>([]);
  const [algoStat, setAlgoStat] = useState<AlgoStat[]>([]);

  const [updateNeeded, setUpdateNeeded_] = useState(0);
  const setUpdateNeeded = () => setUpdateNeeded_((p) => p + 1);

  useEffect(() => {
    setOvhs([]);
    setAlgos([]);
  }, [currOstId]);

  useEffect(() => {
    if (currAlgoId !== lastAlgoId.current) {
      setAltags([]);
      setAlvhs([]);
      setAlaccts([]);
      setSlices([]);
    }
  }, [currAlgoId]);

  useEffect(() => {
    setSlcCncls([]);
    setFixMsgs([]);
    setEmsxMsgs([]);
  }, [currSliceId]);

  useEffect(
    () =>
      setRtEffect({
        m,
        logger,
        intv: 3,
        lastResT,
        params: {
          d,
          vhGrp,
          hideDone,
          ostId: currOstId,
          inMins,
          algoId: currAlgoId,
          alvhId: currAlvhId,
          slcId: currSliceId,
          IsRealTime,
          LastSecs,
          BatchT0,
          lastLogId: lastLogId.current,
          lastAmtId: lastAmtId.current,
        },
        reqAddr: 'RequestOrdProc',
        rcvAddr: 'ReceiveOrdProc',
        connection,
        onReceive: (res: OrdProcRes) => {
          setOrdState((prev) => updateRtObj(prev, res?.state, res?.state_upd));
          setOrdProcState((prev) =>
            updateRtObj(prev, res?.ordProcState, res?.ordProcState_upd),
          );
          setCnfrmNeeded((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.cnfrmNeeded,
              diff: res?.cnfrmNeeded_diff,
            }),
          );
          setCnfmLogs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.cnfmLogs,
              diff: res?.cnfmLogs_diff,
            }),
          );
          setOrdLastLogs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.ordLastLogs,
              diff: res?.ordLastLogs_diff,
            }),
          );
          setAmtLastLogs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.amtLastLogs,
              diff: res?.amtLastLogs_diff,
            }),
          );

          setOsts((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.osts,
              diff: res?.osts_diff,
            }),
          );
          setOvhs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.ovhs,
              diff: res?.ovhs_diff,
            }),
          );
          setAlgos((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.algos,
              diff: res?.algos_diff,
            }),
          );
          setAltags((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.altags,
              diff: res?.altags_diff,
            }),
          );
          setAlvhs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.alvhs,
              diff: res?.alvhs_diff,
            }),
          );
          setAlaccts((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.alaccts,
              diff: res?.alaccts_diff,
            }),
          );
          setSlices((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.slices,
              diff: res?.slices_diff,
            }),
          );
          setSlcCncls((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.slcCncls,
              diff: res?.slcCncls_diff,
            }),
          );

          setFixMsgs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.fixMsgs,
              diff: res?.fixMsgs_diff,
            }),
          );
          setEmsxMsgs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.emsxMsgs,
              diff: res?.emsxMsgs_diff,
            }),
          );

          setAlgoBatch((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.algoBatch,
              diff: res?.algoBatch_diff,
            }),
          );
          setAlgoSliceBatch((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.algoSliceBatch,
              diff: res?.algoSliceBatch_diff,
            }),
          );
          setOrdStLog((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.ordStLog,
              diff: res?.ordStLog_diff,
            }),
          );
          setAlgoStat((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.algoStat,
              diff: res?.algoStat_diff,
            }),
          );

          lastAlgoId.current = res.algoId;
          lastLogId.current = res.lastLogId;
          lastAmtId.current = res.lastAmtId;
        },
      }),
    [
      connection,
      connected,
      d,
      vhGrp,
      hideDone,
      currOstId,
      currAlgoId,
      currAlvhId,
      currSliceId,
      IsRealTime,
      LastSecs,
      BatchT0,
      updateNeeded,
      inMins,
    ],
  );

  const oprocCall = genFuncCall(m, logger, '/Back/OrdProc', () =>
    setUpdateNeeded(),
  );
  const tgtCall = genFuncCall(m, logger, '/Front/Target', () =>
    setUpdateNeeded(),
  );

  return (
    <>
      <div className="row" style={{ width: '1800px' }}>
        <div className="col-10">
          <div className="row" style={{ width: '1500px ' }}>
            <div className="col children-me-2">
              <select
                name="vhGrpSelect"
                value={vhGrp}
                onChange={(e) =>
                  setSearchParams({
                    d,
                    vhGrp: e.target.value,
                    hideDone: hideDone.toString(),
                  })
                }
              >
                {vhGrps.map((v) => (
                  <option key={v} value={v}>
                    {GetVhGrpName(v)}
                  </option>
                ))}
              </select>
              <DateSelector
                value={d}
                onChange={(date) => {
                  if (date !== d) {
                    setSearchParams({
                      d: date,
                      vhGrp,
                      hideDone: hideDone.toString(),
                    });
                  }
                }}
              />
              <label htmlFor="hideDone">
                <input
                  type="checkbox"
                  checked={hideDone}
                  id="hideDone"
                  onChange={(e) =>
                    setSearchParams({
                      d,
                      vhGrp,
                      hideDone: e.target.checked.toString(),
                    })
                  }
                />
                완료주문 제외
              </label>
              <span>|</span>
              <OrdStateBar state={ordState} />
            </div>
          </div>
          <hr className="light narrow" />
          <OrderProvider>
            <OrderInputBar tgtCall={tgtCall} isOrdProc d={d} vhGrp={vhGrp} />
            <hr className="light narrow" />
            <div className="row" style={{ width: '1600px' }}>
              <div className="col-5">
                <ProcBar
                  d={d}
                  vhGrp={vhGrp}
                  ordProcState={ordProcState}
                  inMins={inMins}
                  setInMins={setInMins}
                />
              </div>
              <div className="col-7">
                <BatchOrdTab tgtCall={tgtCall} isOrdProc osts={osts} />
              </div>
            </div>
            <hr className="light narrow" />
            <BatchBar
              oprocCall={oprocCall}
              tgtCall={tgtCall}
              OrdStDgState={OrdStDgState}
              OrdStPrdCnt={ordProcState?.prdCnt}
            />
          </OrderProvider>
          <WarningBar
            tgtCall={tgtCall}
            d={d}
            cnfrmNeeded={cnfrmNeeded}
            cnfmLogs={cnfmLogs}
          />
        </div>
        <div className="col-2">{/* market depth */}</div>
      </div>
      <div className="row" style={{ width: '3050px' }}>
        <div className="col-6">
          <OrdStTab
            tgtCall={tgtCall}
            osts={osts}
            currOstId={currOstId}
            setCurrOstId={setCurrOstId}
            OrdStDgState={OrdStDgState}
            SetOrdStDgState={SetOrdStDgState}
          />
          <OrdLogTab ordLastLogs={ordLastLogs} amtLastLogs={amtLastLogs} />
          <hr className="light narrow" />
          <div
            style={{ width: '1500px', paddingLeft: '10px' }}
            className="d-flex justify-content-between"
          >
            <AlgoBatchTab
              oprocCall={oprocCall}
              algoBatch={algoBatch}
              d={d}
              vhGrp={vhGrp}
            />
            <OrdStLogTab ordStLog={ordStLog} />
          </div>
          <br />
          <hr className="light narrow" />
          <br />
          <div
            style={{ width: '1500px', paddingLeft: '10px' }}
            className="d-flex justify-content-between"
          >
            <AlgoSliceBatchTab
              algoSliceBatch={algoSliceBatch}
              IsRealTime={IsRealTime}
              setIsRealTime={setIsRealTime}
              LastSecs={LastSecs || 60}
              setLastSecs={setLastSecs}
              BatchT0={BatchT0 || ''}
              setBatchT0={setBatchT0}
            />
            <AlgoStatTab algoStat={algoStat} />
          </div>
          <br />
          <br />
        </div>
        <div className="col-6 ">
          <div style={{ minHeight: '300px' }}>
            <b>펀드별 주문 수량</b>
            <OrdVhTab ovhs={ovhs} />
          </div>
          <br />
          <div style={{ minHeight: '80px' }}>
            <b>주문 집행</b>
            <AlgoOrderTab
              algos={algos}
              currAlgoId={currAlgoId}
              setCurrAlgoId={setCurrAlgoId}
              d={d}
              isAlgoSpot={false}
            />
          </div>
          <br />
          <div style={{ minHeight: '80px' }}>
            <b>주문 집행 태그</b>
            <AlgoTagTab altags={altags} />
          </div>
          <br />
          <div
            style={{ width: '1100px' }}
            // className="d-flex justify-content-between"
          >
            <b>펀드별 주문 집행 집계</b>
            <AlgoVhTab
              alvhs={alvhs}
              currAlvhId={currAlvhId}
              setCurrAlvhId={setCurrAlvhId}
            />
            {user?.isDev && false && <AlgoPrgsChart currAlgoId={currAlgoId} />}
          </div>
          <br />
          <div style={{ minHeight: '50px' }}>
            <b>계좌별 주문 집행 집계</b>
            <AlgoAcctTab alaccts={alaccts} />
          </div>
          <br />
          <b>주문 집행 진행 현황</b>
          <AlgoSliceTab
            slices={slices}
            currSliceId={currSliceId}
            setCurrSliceId={setCurrSliceId}
          />
          <br />
          <b>자식 주문 정정/취소 내역</b>
          <AlgoSliceCnclTab slcCncls={slcCncls} />
          <br />
          <b>FIX 메세지</b>
          <FixMsgTab fixMsgs={fixMsgs} />
          <br />
          <b>EMSX 메세지</b>
          <EmsxMsgTab emsxMsgs={emsxMsgs} />
          <br />
        </div>
      </div>
    </>
  );
}
