import React, { useRef, useEffect, CSSProperties } from 'react';
import { Justify } from 'react-bootstrap-icons';
import { SimpleGridDataType } from '../table/SimpleGrid';

export const signColorSpan = (
  v: SimpleGridDataType,
  digits: number,
  args?: {
    style?: React.CSSProperties;
    body?: React.ReactNode;
  },
) => {
  const { style, body } = args ?? {};
  return (
    <span style={{ color: v?.getSignColor(), ...style }}>
      {body ?? v?.toFixedWithComma(digits)}
    </span>
  );
};

export function AlwaysScrollToBottom() {
  const elementRef = useRef<null | HTMLDivElement>(null);
  useEffect(() => elementRef.current?.scrollIntoView());
  return <div ref={elementRef} />;
}

// wwwroot util.js
export function rgb2colorSub(rgb: number) {
  const rgbt = Math.min(Math.max(0, rgb), 255);
  const hexlist = '0123456789ABCDEF';
  return String(hexlist.charAt(Math.floor(rgbt / 16)));
}

export function rgb2color(red: number, green: number, blue: number) {
  const redn = rgb2colorSub(red);
  const greenn = rgb2colorSub(green);
  const bluen = rgb2colorSub(blue);
  return `#${redn}${greenn}${bluen}`;
}

export const getColorGray = (
  val: number | undefined,
  threshold = 1,
  max = 10,
  emp = 20,
  blackWhite = false,
) => {
  let color = blackWhite ? 'default' : val?.getSignColor();
  let backgroundColor = 'white';
  if (val) {
    let v2 = Math.abs(val);
    if (v2 >= threshold) {
      v2 = Math.min(v2, max);
      const norm = Math.round(v2 * emp);
      if (blackWhite)
        backgroundColor = rgb2color(255 - norm, 255 - norm, 255 - norm);
      else if (val > 0)
        backgroundColor = rgb2color(255, 255 - norm, 255 - norm);
      else backgroundColor = rgb2color(255 - norm, 255 - norm, 255);
      if (norm > 120) color = '#fff';
    }
  }
  return { color, backgroundColor };
};

export const getBlockColorStyle = (colors: CSSProperties): CSSProperties => ({
  ...colors,
  paddingRight: '3px',
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
});
