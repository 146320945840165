import React, { useEffect, useState } from 'react';
import { toNumber } from 'lodash';
import { Row } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import { DftBtnStyleMx, DftLinkBtnStyle } from '../../AppTypes';
import DateSelector from '../../shared/DateSelector';
import {
  UrlGrid,
  UrlGridArgs,
  emptyGridArgs,
  callAxios,
  callAxiosGet,
} from '../../tmsutil';
import {
  RiskItem,
  RiskCate,
  RiskPar,
  riskItemSchema,
  riskCateSchema,
  riskParSchema,
  riskCateByItemSchema,
} from '../../Tms/Risk';

const page = '/Middle/RiskConfig';
const btnClass = DftBtnStyleMx;
const linkClass = DftLinkBtnStyle;
type T = { Id: number };

const riskItemDft: UrlGridArgs<RiskItem> = {
  url: `${page}/RiskItem`,
  title: '위험 관리 항목 설정',
  // prettier-ignore
  columns: ['title', 'subj', 'subjId', 'stat', 'prd', 'ord'],
  // prettier-ignore
  headers: ['항목명', '대상', '대상종목', '통계량', '기간', '순번'],
  widths: { title: 150 },
  height: 700,
  infoMsg: `대상 : Stock(주식), Stock2(주식+선물), StkExEtf(주식-ETF), Stk2ExEtf(주식+선물-ETF)`,
  editable: true,
  schema: riskItemSchema,
};

const riskCate: UrlGridArgs<RiskCate> = {
  url: `${page}/RiskCate`,
  title: '전략 유형별 관리 항목',
  columns: ['cate', 'subCate', 'title', 'ord'],
  headers: ['유형', '세부유형', '항목명', '순번'],
  widths: { title: 150 },
  meta: {
    dftColWidth: 100,
    useGlobalFilter: false,
  },
  height: 700,
  infoMsg: `대상 : Stock(주식), Stock2(주식+선물), StkExEtf(주식-ETF), Stk2ExEtf(주식+선물-ETF)
항목 추가는 관리 항목 설정 -> 항목 클릭 -> 적용 대상 (전략 유형) 화면에서 가능 합니다.`,
  editable: true,
  schema: riskCateSchema,
};

const riskCateByItem: UrlGridArgs<RiskCate> = {
  url: `${page}/RiskCateByItem`,
  title: '적용 대상 (전략 유형)',
  columns: ['cate', 'subCate'],
  headers: ['유형', '세부유형'],
  widths: {},
  meta: {
    dftColWidth: 100,
    useGlobalFilter: false,
  },
  height: undefined,
  editable: true,
  schema: riskCateByItemSchema,
};

const riskPar: UrlGridArgs<RiskPar> = {
  url: `${page}/RiskPar`,
  title: '매니저별 위험 설정',
  // prettier-ignore
  columns: ['stId', 'dngrMin', 'alrtMin', 'alrtMax', 'dngrMax', 'd0', 'grace', 'outdated'],
  // prettier-ignore
  headers: ['전략', '위반하단', '경고하단', '경고상단', '위반상단', '적용시작일', '매매제한유예', '적용중단'],
  widths: { grace: 100, outdated: 70 },
  meta: {
    dftColWidth: 100,
    useGlobalFilter: false,
  },
  checkboxes: ['grace', 'outdated'],
  height: undefined,
  editable: true,
  schema: riskParSchema,
};

type Items = {
  Id: number;
  title: string;
};

export default function RiskConfig() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';

  const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [cates, setCates] = useState<string[]>([]);
  const [items, setItems] = useState<Items[]>([]);
  const [cate, setCate] = useState<string>('LongShort');
  const [itemId, setItemId] = useState<number | null>(null); // RiskPar 사용
  const [incPast, setIncPast] = useState<boolean>(false);

  const [showGridRiskCateItem, setShowRiskCateItem] = useState<boolean>(false);
  const [itemIdRiskCate, setItemIdRiskCate] = useState<number>(); // 위험 관리 항목 설정 Row Click 사용
  const [gridRiskCateItem, setGridRiskCateItem] =
    useState<UrlGridArgs<RiskCate>>(emptyGridArgs);
  const [refreshNeededRiskCateItem, setRefreshNeededRiskCateItem] = useState(0);

  const itemIdTitles = ['전략 유형별 관리 항목', '적용 대상 (전략 유형)'];

  const getParams = (title: string) => {
    if (title.isIn(...itemIdTitles)) {
      return { id: itemId };
    }
    if (title === '매니저별 위험 설정') {
      return { d, cate, itemId, incPast };
    }
    return {};
  };

  const clearArgs = () => {
    setGridArgs(emptyGridArgs);
    setShowRiskCateItem(false);
    setGridRiskCateItem(emptyGridArgs);
  };

  const query = (args: unknown) => {
    clearArgs();
    setRefreshNeeded((p) => p + 1);
    setGridArgs(args as UrlGridArgs<T>);
  };

  const riskItem = {
    ...riskItemDft,
    meta: {
      dftColWidth: 100,
      useGlobalFilter: false,
      onRowClick: (r: Row<RiskItem>) => {
        setItemIdRiskCate(r.original.Id);
        setShowRiskCateItem(true);
        setGridRiskCateItem(emptyGridArgs);
        setRefreshNeededRiskCateItem((p) => p + 1);
        setGridRiskCateItem(riskCateByItem);
      },
    },
  };

  const getData = () => {
    callAxiosGet({
      m,
      logger,
      url: `${page}/Data`,
      params: { d, cate, itemId, incPast },
      onSuccess: (data, res) => {
        setCates(data);
        const items0 = res.data.auxData[0];
        setItems(items0);
        if (itemId === null) {
          setItemId(items0[0].Id); // 초기값
        }
      },
    });
  };

  const call = (params: unknown, confirmMsg: string) => {
    callAxios({
      m,
      logger,
      url: `${page}/SetGraceBatch`,
      params,
      confirmMsg,
      onSuccess: (data, res) => {
        if (!res.data.warnings?.length) {
          m.alert('OK');
          query(riskPar);
        } else {
          m.alert([confirmMsg].concat(res.data.warnings).join('\n'));
        }
      },
      onBegin: () => clearArgs(),
      title: confirmMsg,
    });
  };

  useEffect(() => {
    clearArgs();
    getData(); // 파라미터 초기값
    if (!itemId) return;
    query(riskPar); // 파라미터값의 매니저별 위험 설정
  }, [d, cate, incPast]);

  useEffect(() => {
    if (!itemId) return;
    query(riskPar);
  }, [itemId]);

  return (
    <div className="row" style={{ minWidth: '1200px' }}>
      <div className="col">
        <DateSelector
          value={d}
          onChange={(date) => {
            if (date !== d) {
              setSearchParams({ d: date });
            }
          }}
        />
        &nbsp;&nbsp; | &nbsp;&nbsp;
        <button
          type="button"
          className={btnClass}
          onClick={() => query(riskItem)}
        >
          관리 항목 설정
        </button>
        &nbsp;&nbsp; | &nbsp;&nbsp;
        <button
          type="button"
          className={btnClass}
          onClick={() => query(riskCate)}
        >
          유형별 관리 항목
        </button>
      </div>
      <hr className="narrow light" />
      <div className="col">
        파라미터
        <select
          name="cateSelect"
          value={cate}
          onChange={(e) => {
            setCate(e.target.value);
          }}
        >
          {cates.map((v) => (
            <option key={v} value={v}>
              {v}
            </option>
          ))}
        </select>
        &nbsp;&nbsp;
        <select
          name="itemSelect"
          value={itemId ?? ''}
          onChange={(e) => {
            setItemId(toNumber(e.target.value));
          }}
        >
          {items.map((v) => (
            <option key={v.Id} value={v.Id}>
              {v.title}
            </option>
          ))}
        </select>
        &nbsp;&nbsp;
        <label htmlFor="incPast">
          <input
            type="checkbox"
            checked={incPast}
            id="incPast"
            onChange={(e) => setIncPast(e.target.checked)}
          />
          이전값 포함
        </label>
        &nbsp;&nbsp;
        <button
          type="button"
          className={btnClass}
          onClick={() => query(riskPar)}
        >
          파라미터 설정
        </button>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <button
          type="button"
          className={linkClass}
          onClick={() =>
            call({ grace: true }, '전 항목 / 전 매니저 일괄 매매제한 유예')
          }
        >
          일괄 매매제한 유예
        </button>
        &nbsp;&nbsp;
        <button
          type="button"
          className={linkClass}
          onClick={() =>
            call(
              { grace: false },
              '전 항목 / 전 매니저 일괄 매매제한 유예 종료',
            )
          }
        >
          일괄 매매제한 유예 종료
        </button>
        <div className="alert alert-slim alert-warning like-pre">
          {`- 기존 설정을 적용중단하고 신규설정을 추가하면서, 매매제한유예를 걸려는 경우, 기존 설정도 매매제한 유예를 걸어야 함.
  (기존 설정 일괄 매매제한 유예 -> 기존 설정 적용 중단 및 신규 설정 추가 -> 신규 설정 일괄 매매제한 유예)`}
        </div>
      </div>
      <hr className="narrow light" />
      <div className="row" style={{ width: '1200px' }}>
        <div className="col">
          <UrlGrid
            args={gridArgs}
            params={getParams(gridArgs.title)}
            refreshNeeded={refreshNeeded}
          />
        </div>
        {showGridRiskCateItem && (
          <div className="col">
            <UrlGrid
              args={gridRiskCateItem}
              params={{ itemId: itemIdRiskCate }}
              refreshNeeded={refreshNeededRiskCateItem}
            />
          </div>
        )}
      </div>
    </div>
  );
}
