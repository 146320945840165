import React, { useEffect, useState } from 'react';
import { Row } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { SimpleGridDataType } from 'tmslib/src/table/SimpleGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import VhclSelector from '../../shared/VhclSelector';
import StrgSelector, { SimpleStrg } from '../../shared/StrgSelector';
import ProdSelector from '../../shared/ProdSelector';
import { UrlGrid, UrlGridArgs, emptyGridArgs, ValidData, callAxios, downloadFile, saveMemo, isSingleFund } from '../../tmsutil';
import {
  BoBS,
  BoKsdStkFill,
  boKsdStkFillSchema,
  BoKsdFutFill,
  boKsdFutFillSchema,
  BoFill,
  BoFillAggr,
  boFillAggrSchema,
  BoPos,
  BoCashDiv,
  boCashDivSchema,
  BoFF,
  boFFSchema,
  BoTrd,
  boTrdSchema,
  BoSettle,
  boSettleSchema,
  BoAcctBS,
  boAcctBSSchema,
  BoAcctFF,
  boAcctFFSchema,
  BoLoanFee,
  boLoanFeeSchema,
  BoCost,
  boCostSchema,
  SwapPlCmprRes,
  BoDirBS,
  RptPos,
  UFutPosLtd,
  HousePos,
  AlgoAcctFillOvr,
} from '../../Tms/BO';
import { KsdCmprRes } from '../../Tms/KsdFill';
import {
  MafsPos,
  MafsBs,
  MafsPdf,
  mafsPdfSchema,
  MafsCmprRes,
  BoMafsBSAcct,
  boMafsBSAcctSchema,
  BoPrftFixing,
  boPrftFixingSchema,
  BoReGenPosBSRsn,
  boReGenPosBSRsnSchema,
  VeriPrdRes,
  StrgPrftPrd,
  MafsNoMtmPrc,
} from '../../Tms/MAFS';
import { VhGrpTy, GetVhGrpName, GetVirTotVhcl } from '../../Tms/Tms';
import DateSelector from '../../shared/DateSelector';
import { DftBtnStyleMx } from '../../AppTypes';
import { prodUserDailySchema, ProdUserDaily, RstrTy } from '../../Tms/Prod';
import ButtonWithHelp from '../../shared/ButtonWithHelp';

type PageFunc =
  | 'UpdateDirBsAndFlowsFromMafsBs'
  | 'UpdatePricesFromMafsPos'
  | 'UpdateMissedFixFillFromKsdFill'
  | 'DeleteFixFutSpreadFill'
  | 'SaveKFutRolloverFills'
  | 'GenBoPos'
  | 'VerifyPos'
  | 'VerifyRstr'
  | 'GenBoBS'
  | 'GenBoLoanFee'
  | 'GenHousePos'
  | 'GenBoBsPeriod'
  | 'BoBsPeriodFile'
  | 'BoFillAggrFile'
  | 'BoPosFile'
  | 'BoCostFile'
  | 'MngrPrftPrdFile';

// #region helper
const rstrHelp = `[매매제한] 없음:None(0), 유증:R(10), 무증:B(20), 락업:L(30), 메자닌행사:M(40), 주식배당:D(50), 분할:S(60), 이전상장:T(70)
[매매제한(벤처)] 벤처신주:V(1), 구벤처신CB/BW:W(2)
[매매제한(합성)] 유증/벤처신주:RV(11), 락업/벤처신주:LV(31)`;

const fillHelp = `[체결소스] 0:Manual(수기), 1:KSD(예탁원), 4:EfXp(한투해선API), 5:FIX, 8:EMSX(블룸버그), 13:RstrSwitch(매매제한변경), 14:ConvRight(CB전환), 15:AiSent(AI팀전송)
[체결구분] 0:Fill(체결), 1:CorpAct(이벤트), 2:RtT1(실시간익일 [ex]니케이야간), 3:RollOver(선물롤오버), 4:BlockDeal(블록딜), 12:Reinv(재투자)
[전략할당] 0:Manual(수기), 1:OrdNo(주문번호), 3:AcctDft(기본계좌), 4:SetOff(상계)`;

const posHelp = `[북] 기초데이터 => 계좌 => 북 구분 설명 참조
[실시간] 0:Regular(국내물), 1:Book(부킹,해외물), 2:Real(실시간,해외물)
[손익,보유] FC: 펀드 통화 기준, PC: 종목 통화 기준 `;

const currMenu = '/Back/Closing';
const dftHeight = 900;
// #endregion

// #region 미펀

// prettier-ignore
const mafsBs: UrlGridArgs<MafsBs> = {
  url: `${currMenu}/MafsBs`,
  title: '가격 대장',
  columns: ['d', 'fundCd', 'fundNm', 'itemCd', 'itemNm', 'debtY', 'debtDiff', 'debt', 'creditY', 'creditDiff', 'credit', 'errMsg'],
  headers: ['일자', '펀드', '펀드명', '계정코드', '계정명', '차변전일', '차변당증', '차변당일', '대변전일', '대변당증', '대변당일', '에러'],
  editable: false,
  meta: { dftColWidth: 100 },
  widths: {
    fundCd: 80,
    fundNm: 350,
    itemNm: 200,
  },
  height: dftHeight,
};

// prettier-ignore
const mafsPos: UrlGridArgs<MafsPos> = {
  url: `${currMenu}/MafsPos`,
  title: '자산명세',
  columns: ['d', 'fundCd', 'fundNm', 'astTy', 'astNm', 'secTy', 'secNm', 'krCd', 'shortCd', 'prodNm',
    'yPos', 'yAmt', 'bQty', 'bAmt', 'sQty', 'sAmt', 'pos', 'amt', 'prc', 'rzPl', 'unrzPl', 'totPl', 'evm', 'errMsg'],
  headers: ['일자', '펀드', '펀드명', '자산종류', '자산종류명', '종목구분', '종목구분명', '종목코드', '단축코드', '종목명',
    '전일잔고', '전일평가액', '매수수량', '매수금액', '매도수량', '매도금액', '당일잔고', '당일평가액', '적용단가', '매매손익', '평가손익', '총손익', '평가방법', '에러'],
  editable: false,
  meta: { dftColWidth: 100 },
  widths: {
    astTy: 60,
    fundCd: 80,
    fundNm: 350,
    evm: 80,
  },
  height: dftHeight,
};

// prettier-ignore
const mafsPdf: UrlGridArgs<MafsPdf> = {
  url: `${currMenu}/MafsPdf`,
  title: 'PDF',
  columns: ['d', 'fundCd', 'vhId', 'krCd', 'prodNm', 'pos', 'prc', 'amt', 'wei', 'errMsg'],
  headers: ['일자', '펀드', '펀드', '종목코드', '종목명', '수량', '가격', '금액', '비중', '에러'],
  editable: false,
  meta: { dftColWidth: 100 },
  widths: {
    fundCd: 80,
  },
  height: dftHeight,
  schema: mafsPdfSchema,
};

// prettier-ignore
const noMtmPrc: UrlGridArgs<MafsNoMtmPrc> = {
  url: `${currMenu}/MafsNoMtmPrc`,
  title: '미펀비평가가격',
  columns: ['d', 'fundCd', 'vhId', 'prodId', 'krCd', 'prodNm', 'pos', 'amt', 'prc'],
  headers: ['일자', '펀드', '펀드', '종목코드', 'krCd', '종목명', '수량', '금액', '가격'],
  editable: false,
  meta: { dftColWidth: 100 },
  widths: {
    fundCd: 80,
    prodNm: 300,
  },
  height: dftHeight,
  schema: mafsPdfSchema,
};

// prettier-ignore
const cmprMafsPos: UrlGridArgs<MafsCmprRes> = {
  url: `${currMenu}/CmprMafsPos`,
  title: '개별',
  columns: ['prodId', 'prodNm', 'rstr', 'tf.y', 'ma.y', 'tf.b', 'ma.b', 'tf.s', 'ma.s', 'tf.pos', 'ma.pos', 'tf.amt', 'ma.amt', 'tf.div', 'ma.div', 'tf.prft', 'ma.prft', 'diffPrft', 'memo'],
  accessFns: [
    (r) => r.prodId,
    (r) => r.prodNm,
    (r) => r.rstr,
    (r) => r.tf?.y,
    (r) => r.ma?.y,
    (r) => r.tf?.b,
    (r) => r.ma?.b,
    (r) => r.tf?.s,
    (r) => r.ma?.s,
    (r) => r.tf?.pos,
    (r) => r.ma?.pos,
    (r) => r.tf?.amt,
    (r) => r.ma?.amt,
    (r) => r.tf?.div,
    (r) => r.ma?.div,
    (r) => r.tf?.prft,
    (r) => r.ma?.prft,
    (r) => r.diffPrft,
    (r) => r.memo,
  ],
  headers: ['코드', '명', '제한', '타임', '미래', '타임', '미래', '타임', '미래', '타임', '미래', '타임', '미래', '타임', '미래', '타임', '미래', '차이', '메모'],
  headerGroups: [
    ['종목', 3],
    ['전일보유', 2],
    ['당일매수', 2],
    ['당일매도', 2],
    ['당일보유', 2],
    ['보유금액', 2],
    ['배당', 2],
    ['당일손익', 2],
    ['', 1],
    ['', 1],
  ],
  dftStyler: (
    v: SimpleGridDataType,
    c: keyof MafsCmprRes,
    r: Row<MafsCmprRes>,
  ) => {
    const tok = c.split('_');

    if (c.includes('_')) {
      const tf = r.original.tf?.[tok[1]] ?? 0;
      const ma = r.original.ma?.[tok[1]] ?? 0;

      if (['y', 'b', 's', 'pos'].indexOf(tok[1]) >= 0) {
        if (tf !== ma) {
          return { backgroundColor: '#faa' };
        }
      }
    }
    return null;
  },
  meta: { dftColWidth: 100 },
  height: dftHeight,
};

const cmprMafsPosBatch: UrlGridArgs<MafsCmprRes> = {
  url: `${currMenu}/CmprMafsPosBatch`,
  title: '',
  columns: [],
  headers: [],
  meta: {
    dftColWidth: 150,
  },
  height: dftHeight,
  dftStyler: (
    v: SimpleGridDataType,
    c: keyof MafsCmprRes,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    r: Row<any>,
  ) => {
    if (r.original.prodId && ['L', 'M'].indexOf(r.original.prodId[0]) >= 0) {
      return { backgroundColor: 'lightgray' };
    }
    if (c.startsWith('pos')) {
      if (v) {
        return { backgroundColor: 'red', color: 'white' };
      }
    }
    if (c.startsWith('prft')) {
      if (v) {
        if (Math.abs(v as number) >= 3000)
          return {
            backgroundColor: 'red',
            color: 'white',
            width: '200px',
          };
      }
    }
    return null;
  },
  widths: { rstr: 60 },
  dynamicColumns: (res) => {
    const { vhs }: { vhs: string[] } = res.data.extras;
    const columns = [
      'prodId',
      'prodNm',
      'rstr',
      ...vhs.map((v) => `pos.${v}`),
      ...vhs.map((v) => `prft.${v}`),
      'memo',
    ];
    const headers = [
      '코드',
      '명',
      '제한',
      ...vhs.map((v) => v.substring(2)),
      ...vhs.map((v) => v.substring(2)),
      'memo',
    ];
    return {
      columns,
      headers,
      headerGroups: [
        ['종목', 3],
        ['당일보유오차 (타임-미펀)', vhs.length],
        ['당일손익오차 (타임-미펀)', vhs.length],
        ['메모', 1],
      ],
    };
  },
};
// #endregion

// #region 손익검증
const boMafsBSAcct: UrlGridArgs<BoMafsBSAcct> = {
  url: `${currMenu}/BoMafsBSAcct`,
  title: '미펀계정',
  columns: ['acctTy', 'acctCd', 'nm', 'side'],
  headers: ['구분', '계정코드', '계정과목명', '당잔'],
  editable: true,
  meta: { dftColWidth: 80, idType: 'uuid' },
  widths: {
    acctTy: 100,
    acctCd: 100,
    nm: 250,
  },
  height: 600,
  schema: boMafsBSAcctSchema,
};

const boPrftFixing: UrlGridArgs<BoPrftFixing> = {
  url: `${currMenu}/BoPrftFixing`,
  title: '손익오차조정',
  columns: ['d', 'vhId', 'amt', 'memo'],
  headers: ['날짜', '펀드', '오차조정분', '사유'],
  editable: true,
  meta: { dftColWidth: 80, idType: 'uuid' },
  widths: {
    amt: 200,
    memo: 250,
  },
  height: 600,
  schema: boPrftFixingSchema,
};

const boReGenPosBSRsn: UrlGridArgs<BoReGenPosBSRsn> = {
  url: `${currMenu}/BoReGenPosBSRsn`,
  title: '손익재처리',
  columns: ['d', 'd0', 'd1', 'rsn', 'userid'],
  headers: ['날짜', '시작일', '종료일', '사유', '입력자'],
  editable: true,
  meta: { dftColWidth: 80, idType: 'uuid' },
  widths: {
    rsn: 250,
  },
  height: 600,
  schema: boReGenPosBSRsnSchema,
};

// prettier-ignore
const veriPrdRes: UrlGridArgs<VeriPrdRes> = {
  url: `${currMenu}/veriPrdRes`,
  title: '누적손익조회',
  columns: ['vh', 'diff', 'prftFixing', 'mafsPrftBfCost', 'mafsCost1', 'mafsCost2', 'mafsPrft',
    'tmsPrftBfCost', 'tmsCost1', 'tmsCost2', 'tmsCost3', 'tmsPrft', 'msg'],
  headers: ['펀드', '차이', '손익보정', '비용차감 전 손익', '비용1', '비용2', '당기순손익'
    , '비용차감 전 손익', '비용1', '비용2', '상계수수료', '당기순손익', '참고/에러'],
  meta: { dftColWidth: 120, },
  widths: {
    msg: 300,
  },
  headerGroups: [['구분', 3], ['사무관리 계정 값', 4], ['TMS 손익', 5], ['', 1]],
  height: 600,
  infoMsg: ['미펀 : 비용1 (기타수익-기타비용), 비용2 (대차+스왑비용)', 'TMS : 비용1 (자금비용), 비용2 (대차+스왑비용)'].join('\n'),
};

// prettier-ignore
const strgPrftPrd: UrlGridArgs<StrgPrftPrd> = {
  url: `${currMenu}/strgPrftPrd`,
  title: '기간전략손익',
  columns: [],
  headers: [],
  meta: {
    dftColWidth: 150,
  },
  widths: {
    stId: 110,
    nm2: 100,
  },
  height: dftHeight,
  dynamicColumns: (res) => {
    const { vhs } = res.data.extras;
    return {
      columns: ['stId', 'nm2'].concat(vhs.map((v: string) => `prft.${v}`)),
      headers: ['전략명', '전략명2'].concat(vhs),
    };
  },
};
// #endregion

// #region 예탁원

// prettier-ignore
const ksdStkFill: UrlGridArgs<BoKsdStkFill> = {
  url: `${currMenu}/BoKsdStkFill`,
  headers: ['날짜', '순번', '응답코드', '자료구분', '운용사', '증권사', 'dataNo', '지점', '계좌', '증권구분', '시장구분', '매매구분', '주문유형', '종목', '주문번호', '체결번호', '수량', '가격', '금액', '체결일', '정산일', '체결T', '삭제'],
  title: '체결원본(주식)',
  columns: ['d', 'seq', 'resCd', 'dataTy', 'icCd', 'brkCd', 'dataNo', 'brnchCd', 'acctCd', 'secTy', 'mktTy', 'bsTy', 'ordTy', 'cd', 'ordNo', 'fillNo', 'qty', 'prc', 'ta', 'trdd', 'stld', 'trdt', 'deleted'],
  editable: true,
  meta: { dftColWidth: 80 },
  height: dftHeight,
  schema: boKsdStkFillSchema,
};

// prettier-ignore
const ksdFutFill: UrlGridArgs<BoKsdFutFill> = {
  url: `${currMenu}/BoKsdFutFill`,
  title: '체결원본(선물)',
  columns: ['d', 'seq', 'resCd', 'dataTy', 'icCd', 'fundCd', 'brkCd', 'brnchCd', 'acctCd', 'mktTy', 'bsTy', 'ordTy', 'cd', 'ordNo', 'fillNo', 'qty', 'prc', 'ta', 'trdd', 'trdt', 'deleted'],
  headers: ['날짜', '순번', '응답코드', '자료구분', '운용사', '펀드', '증권사', '지점', '계좌', '시장구분', '매매구분', '주문유형', '종목', '주문번호', '체결번호', '수량', '가격', '금액', '체결일', '체결T', '삭제'],
  editable: true,
  meta: { dftColWidth: 80 },
  height: dftHeight,
  schema: boKsdFutFillSchema,
};

// prettier-ignore
const ksdFill: UrlGridArgs<BoFill> = {
  url: `${currMenu}/KsdFill`,
  title: '가공후 체결',
  columns: ['d', 'vhId', 'acctId', 'prodId', 'prodNm', 'isBuy', 'qty', 'prc', 'fee', 'tax', 'ordNo', 'src', 'ty', 'stId', 'stAlloc', 'trdt'],
  headers: ['날짜', '펀드', '계좌', '종목코드', '종목명', '매수?', '수량', '가격', '수수료', '세금', '주문번호', '소스', '구분', '전략', '전략할당구분', '체결시간'],
  editable: false,
  meta: { dftColWidth: 100 },
  height: dftHeight,
};

// prettier-ignore
const cmprKsdFill: UrlGridArgs<KsdCmprRes> = {
  url: `${currMenu}/CmprKsdFill`,
  title: '예탁원 체결 비교',
  columns: ['vhId', 'stId', 'acctId', 'prodId', 'prodNm', 'isBuy', 'fixQty', 'fixPrc', 'ksdQty', 'ksdPrc'],
  headers: ['펀드', '전략', '계좌', '코드', '종목명', '매수', '수량', '가격', '수량', '가격'],
  headerGroups: [['펀드/전략/계좌/종목', 6], ['FIX', 2], ['예탁원', 2]],
  meta: { dftColWidth: 100 },
  height: dftHeight,
}

// #endregion

// #region 포생

// prettier-ignore
const boFillAggr: UrlGridArgs<BoFillAggr> = {
  url: `${currMenu}/BoFillAggr`,
  title: '체결 소계',
  columns: ['d', 'vhId', 'acctId', 'prodId', 'prodNm', 'book', 'rt', 'rstr', 'isBuy',
    'qty', 'prc', 'fee', 'tax', 'src', 'ty', 'stId', 'stAlloc', 'avgPrc', 'avgFx', 'ord', 'ls', 'fillt', 'userNm', 'aprvState'],
  headers: ['날짜', '펀드', '계좌', '종목코드', '종목명', '북', '실시간', '제한', '매수',
    '수량', '가격', '수수료', '세금', '소스', '구분', '전략', '전략할당구분', '평단가', '평환율', 'ord', '롱숏', '체결시간', '입력자', '승인단계'],
  editable: true,
  meta: { dftColWidth: 80 },
  height: dftHeight,
  infoMsg: [rstrHelp, fillHelp, posHelp].join('\n'),
  schema: boFillAggrSchema,
  userApprovalMenus: true
};

// prettier-ignore
const boCashDiv: UrlGridArgs<BoCashDiv> = {
  url: `${currMenu}/BoCashDiv`,
  title: '현금 배당',
  columns: ['d', 'vhId', 'stId', 'prodId', 'prodNm', 'book', 'rt', 'rstr', 'div', 'rcvd', 'src', 'userNm'],
  headers: ['날짜', '펀드', '전략', '종목코드', '종목명', '북', '실시간', '제한', '배당금', '수령일', '소스', '입력자'],
  editable: true,
  meta: { dftColWidth: 100 },
  widths: {
    src: 120,
  },
  height: dftHeight,
  schema: boCashDivSchema,
};

// prettier-ignore
const boPos: UrlGridArgs<BoPos> = {
  url: `${currMenu}/BoPos`,
  title: '포지션',
  // prettier-ignore
  columns: ['d', 'vhId', 'stId', 'prodId', 'prodNm', 'book', 'rt', 'rstr', 'posYd', 'prcYd', 'bQty', 'bPrc', 'sQty', 'sPrc', 'close',
    'div', 'fee', 'osFee', 'tax', 'pos', 'prft', 'prftVhCcy', 'closeFx', 'avgPrc', 'avgFx', 'fxplVhCcy',],
  // prettier-ignore
  headers: ['날짜', '펀드', '전략', '종목코드', '종목명', '북', '실시간', '제한', '전일보유', '전일종가', '매수량', '매수가', '매도량', '매도가', '당일종가',
    '배당', '수수료', '상계수수료', '세금', '보유(PC)', '손익(PC)', '손익(FC)', '마감환율', '평단가', '평환율', '환손익',],
  height: dftHeight,
  meta: { dftColWidth: 80 },
  infoMsg: [rstrHelp, posHelp].join('\n'),
};

// prettier-ignore
const boPosDetail: UrlGridArgs<BoPos> = {
  url: `${currMenu}/BoPosDetail`,
  title: '포지션(D)',
  // prettier-ignore
  columns: [
    'd', 'vhId', 'stId', 'prodId', 'prodNm', 'book', 'rt', 'rstr', 'posYd', 'prcYd',
    'bQty', 'bPrc', 'sQty', 'sPrc', 'close', 'div', 'fee', 'osFee', 'tax',
    'pos', 'prft', 'prftVhCcy', 'closeFx', 'avgPrc', 'avgFx', 'fxplVhCcy',
    'underId', 'underNm', 'fx', 'bpv', 'amtVhCcy', 'na', 'ta', 'units',
    'wei', 'ls', 'mc', 'sh', 'mkta', 'ventCate', 'noMTM',
  ],
  // prettier-ignore
  headers: [
    '날짜', '펀드', '전략', '종목코드', '종목명', '북', '실시간', '제한', '전일보유', '전일종가',
    '매수량', '매수가', '매도량', '매도가', '당일종가', '배당', '수수료', '상계수수료', '세금',
    '보유(PC)', '손익(PC)', '손익(FC)', '마감환율', '평단가', '평환율', '환손익',
    '기초자산', '기초자산명', '환율', '승수', '보유(FC)', '순자산', '총자산', '좌수',
    '비중', 'LS', '시총', '상장주식수', '거래대금', '벤처', '비평가',
  ],
  height: dftHeight,
  meta: { dftColWidth: 100 },
  infoMsg: [rstrHelp, posHelp].join('\n'),
};

// prettier-ignore
const settlePrc: UrlGridArgs<ProdUserDaily> = {
  url: `${currMenu}/SettlePrc`,
  title: '종목 마감 가격',
  columns: ['d', 'prodId', 'prodNm', 'val', 'keep', 'userNm'],
  headers: ['날짜', '종목코드', '종목명', '마감 가격', '익일유지', '입력자'],
  editable: true,
  height: dftHeight,
  meta: { dftColWidth: 100 },
  schema: prodUserDailySchema,
};
// #endregion

// #region 자금,해외,해외선물계좌

// prettier-ignore
const boFF: UrlGridArgs<BoFF> = {
  url: `${currMenu}/BoFF`,
  title: '펀드 입출금',
  columns: ['d', 'vhId', 'stId', 'flow', 'src'],
  headers: ['날짜', '펀드', '전략', '입출금', '소스'],
  editable: true,
  height: dftHeight,
  meta: { dftColWidth: 100 },
  schema: boFFSchema,
};

// prettier-ignore
const boSettle: UrlGridArgs<BoSettle> = {
  url: `${currMenu}/BoSettle`,
  title: '결산/재투자',
  columns: ['d', 'vhId', 'stId', 'reinv', 'nav'],
  headers: ['날짜', '펀드', '전략', '시작금액', 'nav싱가폴'],
  editable: true,
  height: dftHeight,
  meta: { dftColWidth: 100 },
  schema: boSettleSchema,
};

// prettier-ignore
const boTrd: UrlGridArgs<BoTrd> = {
  url: `${currMenu}/BoTrd`,
  title: '해외 선입선출',
  columns: ['procD', 'vhId', 'book', 'prodId', 'ed', 'eord', 'ep', 'pos', 'xd', 'xord', 'xp', 'plheld', 'resetD', 'resetP', 'resetP0'],
  headers: ['처리일', '운용상품', '북', '종목코드', '진입일', '진입번호', '진입가', '포지션', '청산일', '청산번호', '청산가격', '청산손익홀딩', '리셋일', '리셋가', '이전리셋가'],
  editable: true,
  height: dftHeight,
  meta: { dftColWidth: 100 },
  schema: boTrdSchema,
}

// prettier-ignore
const boAcctBS: UrlGridArgs<BoAcctBS> = {
  url: `${currMenu}/BoAcctBS`,
  title: '예탁금',
  columns: ['d', 'acctId', 'curncy', 'balance'],
  headers: ['날짜', '계좌', '통화', '예탁금'],
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: boAcctBSSchema,
};

// prettier-ignore
const boAcctFF: UrlGridArgs<BoAcctFF> = {
  url: `${currMenu}/BoAcctFF`,
  title: '입출금',
  columns: ['d', 'acctId', 'flow'],
  headers: ['날짜', '계좌', '입출금'],
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: boAcctFFSchema,
};

// #endregion

// #region 손익

// prettier-ignore
const boLoanFee: UrlGridArgs<BoLoanFee> = {
  url: `${currMenu}/BoLoanFee`,
  title: '매니저 대차비용',
  columns: ['d', 'vhId', 'stId', 'fee'],
  headers: ['날짜', '펀드', '전략', '대차비용'],
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: boLoanFeeSchema,
};

// prettier-ignore
const boCost: UrlGridArgs<BoCost> = {
  url: `${currMenu}/BoCost`,
  title: '스왑 비용',
  columns: ['d', 'vhId', 'book', 'cost'],
  headers: ['날짜', '펀드', '북', '비용'],
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: boCostSchema,

};

// prettier-ignore
const cmprSwapPl: UrlGridArgs<SwapPlCmprRes> = {
  url: `${currMenu}/CmprSwapPl`,
  title: '스왑 손익 검증',
  columns: ['vhId', 'book', 'prftCh2', 'expCh2', 'fxCh2', 'posSum2', 'csMrgn2', 'tmsPrft', 'prftDiff2', 'tmsPos', 'posDiff2', 'memo',],
  headers: ['상품', '북', '총손익변동', '이자비용변동', '환손익변동', '포지션합', '현금증거금', 'TMS손익', '손익차', 'TMS포지션', '포지션차', '메모',],
  editable: false,
  meta: {
    dftColWidth: 100,
    height: dftHeight
  },
  height: dftHeight,
  dftStyler: (v, c, r) => {
    let color = 'black';
    if (c === 'prftDiff2' && r.original.prftDiff2 != null) {
      if (r.original.prftDiff2 > 1000) color = 'red';
      if (r.original.prftDiff2 < -1000) color = 'blue';
    }
    if (c === 'posDiff2' && r.original.posDiff2 != null) {
      if (r.original.posDiff2 > 0) color = 'red';
      if (r.original.posDiff2 < 0) color = 'blue';
    }
    return { color };
  },
};

// prettier-ignore
const boDirBS: UrlGridArgs<BoDirBS> = {
  url: `${currMenu}/BoDirBS`,
  title: 'Whole 손익(미펀)',
  columns: ['d', 'vhId', 'stId', 'prin', 'units', 'prft', 'cost', 'na', 'taxna', 'nav', 'taxnav', 'ta', 'liab', 'flow', 'prin0'],
  headers: ['날짜', '펀드', '전략', '원본합계', '좌수', '손익', '비용', '순자산', '과표순자산', '기준가', '과표기준가', '총자산', '부채', '입출금', '원본액'],
  editable: false,
  meta: { dftColWidth: 100 },
  height: dftHeight,
}

// #endregion

// #region 기준가, 리포트

// prettier-ignore
const boBS: UrlGridArgs<BoBS> = {
  url: `${currMenu}/BoBS`,
  title: '기준가',
  columns: ['d', 'vhId', 'stId', 'prin', 'units', 'prft', 'cost', 'na', 'nav', 'rt', 'loan', 'fee', 'osFee', 'tax', 'ta', 'navReal'],
  headers: ['날짜', '펀드', '전략', '원본', '좌수', '손익', '비용', '순자산', '기준가', '수익률', '대차비용', '수수료', '상계수수료', '세금', '총자산', '실시간기준가'],
  meta: { dftColWidth: 100 },
  height: dftHeight,
}
// #endregion

// #region 기타, 파일

// prettier-ignore
const housePos: UrlGridArgs<HousePos> = {
  url: `${currMenu}/HousePos`,
  title: '하우스 포지션',
  columns: ['prodId', 'prodNm', 'stks', 'evts', 'swap', 'wrnts', 'mznConv', 'loan', 'holds', 'holdRto', 'shorts', 'shortRto', 'netShortRto', 'shares'],
  headers: ['종목코드', '종목명', '주식', '권리주', '스왑', '워런트', '메자닌', '대차잔고', '롱합산', '롱비율', '숏잔고', '숏비율', '공시숏', '상장주식수'],
  editable: false,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  infoMsg: `주식  : 직접 보유(북=Cash) 수량 (ETF, 조합분 제외)
  메자닌 : 메자닌의 주식 환산 수량 (준감실 관리)
  롱합산 : 주식 + 워런트 + 메자닌 + 대차잔고
  롱비율 : 롱합산 / (상장주식수 + 메자닌)
  숏비율 : 현금숏 / 상장주식수
  공시숏 : (현금롱 + 현금숏) / 상장주식수`,
}

// prettier-ignore
const uFutPosLtd: UrlGridArgs<UFutPosLtd> = {
  url: `${currMenu}/UFutPosLtd`,
  title: '해외선물 만기',
  columns: ['ty', 'days', 'prodId', 'prodNm', 'ltd', 'sts'],
  headers: ['구분', '남은 일', '종목코드', '종목명', '만기', '전략'],
  editable: false,
  meta: { dftColWidth: 120 },
  height: dftHeight,
}

// prettier-ignore
const algoAcctFillOvr: UrlGridArgs<AlgoAcctFillOvr> = {
  url: `${currMenu}/AlgoAcctFillOvr`,
  title: '체결 덮어쓰기',
  columns: ['aacId', 'CumQty', 'AvgPx'],
  headers: [
    '계좌별 주문ID',
    '체결수량',
    '체결단가',
  ],
  editable: true,
  meta: { dftColWidth: 100, idType: 'uuid' },
  height: dftHeight,
};

// prettier-ignore
const kFutRolloverFills: UrlGridArgs<BoFillAggr> = {
  url: `${currMenu}/KFutRolloverFills`,
  title: '국내선물 만기',
  columns: ['vhId', 'book', 'prodId', 'prodNm', 'd', 'prc', 'qty', 'fee'],
  headers: ['펀드', '북', '종목코드', '종목명', '만기', '정산가', '청산수량', '수수료'],
  editable: false,
  meta: { dftColWidth: 100 },
  height: dftHeight,
}

// prettier-ignore
const rptPos: UrlGridArgs<RptPos> = {
  url: `${currMenu}/RptPos`,
  title: 'ETEAM 잔고',
  columns: ['prodId', 'prodNm', 'vent', 'pos', 'close', 'amtVhCcy', 'mc', 'ta', 'wei'],
  headers: ['종목코드', '종목명', '벤처', '수량', '종가', '보유금액', '시총', '거래대금', '비중'],
  meta: { dftColWidth: 100 },
  height: undefined,
}

// #endregion

// #region tys formmater, styler
type T = { Id: number }; // 임의 IId 타입

enum GenPosAstTy {
  All = 'All',
  Dom = 'Dom',
  UStk = 'UStk',
  UStkSwap = 'UStkSwap',
  UStkCash = 'UStkCash',
  UFund = 'UFund',
  UFut = 'UFut',
}

const getGenPosAstTyNm = (ty: GenPosAstTy) => {
  switch (ty) {
    case GenPosAstTy.All:
      return '전체';
    case GenPosAstTy.Dom:
      return '국내 주식/선물';
    case GenPosAstTy.UStk:
      return '해외주식';
    case GenPosAstTy.UStkSwap:
      return '해외주식스왑';
    case GenPosAstTy.UStkCash:
      return '해외주식캐시';
    case GenPosAstTy.UFund:
      return '해외펀드';
    case GenPosAstTy.UFut:
      return '해외선물';
    default:
      return ty;
  }
};

const genPosAstTys = [
  GenPosAstTy.All,
  GenPosAstTy.Dom,
  GenPosAstTy.UStk,
  GenPosAstTy.UStkSwap,
  GenPosAstTy.UStkCash,
  GenPosAstTy.UFund,
  GenPosAstTy.UFut,
];

const vhGrpsForMafsCmpr = [
  VhGrpTy.HFund,
  VhGrpTy.VFund,
  VhGrpTy.AIFund,
  VhGrpTy.PAFund,
  VhGrpTy.HBFund,
  VhGrpTy.ETF,
  VhGrpTy.PEFund,
  VhGrpTy.Troika,
];
// #endregion

export default function Closing() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const vh = searchParams.get('vh') || 'ALL_FUNDS';
  const [d0, setD0] = useState(d);
  const [strg, setStrg] = useState<SimpleStrg | null>(null);
  const [prodId, setProdId] = useState<string | null>(null);
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);
  const [exceptSprd, setExceptSprd] = useState(false);
  const linkClass = 'btn btn-link';
  const [genPosAstTy, setGenPosAstTy] = useState(GenPosAstTy.All);
  const [warnResMsg, setWarnResMsg] = useState<string | null>(null);
  const [cdPat, setCdPat] = useState<string | null>(null);

  const btnClass = DftBtnStyleMx;

  useEffect(() => {
    setGridArgs(emptyGridArgs);
  }, [d]);

  const getParams = (title: string) => {
    if (title === '개별') return { d, vh };
    if (title.startsWith('미펀검증배치')) {
      return { d, tgt: title.split('_')[1] };
    }
    if (title === '기간전략손익') {
      return { d, d0, vhOrGrp: vh };
    }
    return {
      d,
      d0,
      vhOrGrp: vh,
      tgt: vh,
      st: strg?.Id,
      prod: prodId ?? cdPat,
    };
  };

  const clearArgs = () => {
    setWarnResMsg('');
    setGridArgs(emptyGridArgs);
  };

  const singleVhFuncs = ['CmprMafsPos'];

  const query = (args: unknown) => {
    const iargs = args as UrlGridArgs<T>;
    const func = iargs.url.split('/').pop();
    if (func?.isIn(...singleVhFuncs)) {
      if (!isSingleFund(vh)) {
        m.alert(`${iargs.title}  ${vh} 로 조회불가, 단일 펀드만 조회가능`);
        return;
      }
    }
    setRefreshNeeded((p) => p + 1);
    setGridArgs(args as UrlGridArgs<T>);
  };

  const getButton = (a: unknown) => {
    const { title } = a as UrlGridArgs<T>;
    const titleDisp = title.startsWith('미펀검증배치')
      ? GetVhGrpName(title.split('_')[1] as VhGrpTy)
      : title;
    const bgColor = title.isIn('예탁원 체결 비교') ? 'greenyellow' : '';

    return (
      <ButtonWithHelp
        key={title}
        helpid={title}
        className={btnClass}
        onClick={() => {
          setWarnResMsg('');
          query(a);
        }}
        style={{
          backgroundColor: bgColor,
        }}
        label={titleDisp}
      />
    );
  };

  const putMemo = (pars: unknown, oldMemo: string | null) =>
    saveMemo({
      m,
      logger,
      isDelete: false,
      memoParams: pars,
      callback: () => setRefreshNeeded((p) => p + 1),
      oldval: oldMemo,
    });

  const deleteMemo = (pars: unknown) =>
    saveMemo({
      m,
      logger,
      isDelete: true,
      memoParams: pars,
      callback: () => setRefreshNeeded((p) => p + 1),
    });

  const cmprMafsMemoPars = (vhId: string, o: MafsCmprRes) => ({
    d,
    vh: vhId,
    ty: 'MafsPos',
    prod: o.prodId,
    rstr: o.rstr,
  });

  const cmprMafsPosWithCxtMenus = {
    ...cmprMafsPos,
    meta: {
      ...cmprMafsPos.meta,
      contextMenus: [
        {
          label: '메모 입력/수정',
          callback: (objs: MafsCmprRes[]) =>
            putMemo(cmprMafsMemoPars(vh, objs[0]), objs[0].memo),
        },
        {
          label: '메모 삭제',
          callback: (objs: MafsCmprRes[]) =>
            deleteMemo(cmprMafsMemoPars(vh, objs[0])),
          disabled: (items: MafsCmprRes[]) => !items.some((v) => v.memo),
        },
      ],
    },
  };

  const cmprMafsPosBatches = vhGrpsForMafsCmpr.map((grp) => ({
    ...cmprMafsPosBatch,
    title: `미펀검증배치_${grp}`,
    meta: {
      ...cmprMafsPosBatch.meta,
      contextMenus: [
        {
          label: '메모 입력/수정',
          callback: (objs: MafsCmprRes[]) =>
            putMemo(
              cmprMafsMemoPars(GetVirTotVhcl(grp), objs[0]),
              objs[0].memo,
            ),
        },
        {
          label: '메모 삭제',
          callback: (objs: MafsCmprRes[]) =>
            deleteMemo(cmprMafsMemoPars(GetVirTotVhcl(grp), objs[0])),
        },
      ],
    },
  }));

  const cmprSwapPlMemoPars = (o: SwapPlCmprRes) => ({
    d,
    vh: o.vhId,
    ty: 'SwapPl',
    prod: 'WMEMO',
    rstr: RstrTy.None,
    book: o.book,
  });
  // prettier-ignore
  const cmprSwapPlWithCxtMenus = {
    ...cmprSwapPl,
    meta: {
      ...cmprSwapPl.meta,
      contextMenus: [
        {
          label: '메모 입력/수정',
          callback: (objs: SwapPlCmprRes[]) =>
            putMemo(cmprSwapPlMemoPars(objs[0]), objs[0].memo,),
        },
        {
          label: '메모 삭제',
          callback: (objs: SwapPlCmprRes[]) =>
            deleteMemo(cmprSwapPlMemoPars(objs[0])),
          disabled: (items: SwapPlCmprRes[]) => !items.some((v) => v.memo),
        },
      ],
    },
  };

  // #region axios
  const handleAxiosResult = (
    data: ValidData,
    res: AxiosResponse,
    cfmmsg: string | null,
    callbackGridArgs?: unknown,
  ) => {
    if (!res.data.warnings?.length) {
      setWarnResMsg(`${cfmmsg ?? ''} OK`);
    } else {
      setWarnResMsg([cfmmsg].concat(res.data.warnings).join('\n'));
    }
    if (callbackGridArgs) {
      query(callbackGridArgs);
    }
  };

  const call = (
    func: PageFunc,
    params: unknown,
    confirmMsg: string,
    callbackGridArgs?: unknown,
    isGet = false,
  ) => {
    callAxios({
      m,
      logger,
      url: `/Back/Closing/${func}`,
      params,
      confirmMsg,
      onSuccess: (data, res) =>
        handleAxiosResult(data, res, confirmMsg, callbackGridArgs),
      onBegin: () => clearArgs(),
      isGet,
      title: confirmMsg,
    });
  };

  const callAxiosFunc = (
    funcNm: PageFunc,
    cfrmMsg: string,
    callbackGridArgs?: unknown,
    close = false,
  ) => {
    switch (funcNm) {
      case 'UpdateDirBsAndFlowsFromMafsBs':
      case 'UpdatePricesFromMafsPos':
      case 'UpdateMissedFixFillFromKsdFill':
      case 'DeleteFixFutSpreadFill':
      case 'SaveKFutRolloverFills':
        call(funcNm, { d }, cfrmMsg, callbackGridArgs);
        break;
      case 'GenBoPos':
        call(
          funcNm,
          { d, vhOrGrp: vh, close, exceptSprd, ty: genPosAstTy },
          cfrmMsg,
          callbackGridArgs,
        );
        break;
      case 'VerifyPos':
      case 'VerifyRstr':
        call(funcNm, { d, vhOrGrp: vh }, cfrmMsg, callbackGridArgs, true);
        break;
      case 'GenBoBS':
      case 'GenBoLoanFee':
      case 'GenHousePos':
        call(funcNm, { d, vhOrGrp: vh }, cfrmMsg, callbackGridArgs);
        break;
      case 'GenBoBsPeriod':
        call(funcNm, { d0, d, vhOrGrp: vh }, cfrmMsg, callbackGridArgs);
        break;
      default:
        setWarnResMsg('Unkown func');
    }
  };

  // #endregion

  const getFile = (
    funcNm: PageFunc,
    vhOrGrp: string,
    st: string | undefined,
    prod: string | undefined,
  ) => {
    downloadFile({
      m,
      logger,
      url: `/Back/Closing/Download${funcNm}`,
      params: { d, d0, vhOrGrp, st, prod },
    });
  };

  return (
    <div style={{ minWidth: '3000px' }} className="children-me-2">
      <DateSelector
        value={d}
        initDate0={d0}
        onChange={(date) => date !== d && setSearchParams({ d: date, vh })}
        onChangeD0={(date0) => setD0(date0)}
      />
      <VhclSelector
        d={d}
        meta
        all
        tfim
        onChange={(vhcl) =>
          vhcl && vhcl.Id !== vh && setSearchParams({ d, vh: vhcl.Id })
        }
        value={vh}
      />
      <b>[조회용 필터]</b>
      <StrgSelector
        d={d}
        value={strg?.Id}
        onChange={setStrg}
        whole
        empty
        singa
      />
      <ProdSelector
        onChange={(e) => {
          setProdId(e?.Id ?? null);
        }}
      />
      <label htmlFor="cdPat">
        <input
          id="cdPat"
          onChange={(e) => setCdPat(e.target.value)}
          placeholder="^시작문자열"
          size={15}
        />
      </label>
      <hr className="narrow light" />

      {!user?.isCapital && <>
        <b>미펀</b> &nbsp;&nbsp;
        {[mafsBs, mafsPos].map((v) => getButton(v))}
        <button
          type="button"
          className={linkClass}
          onClick={() =>
            callAxiosFunc('UpdateDirBsAndFlowsFromMafsBs', '가격대장 재로딩')
          }
        >
          (가격대장 재로딩)
        </button>
        |
        <button
          type="button"
          className={linkClass}
          onClick={() =>
            callAxiosFunc('UpdatePricesFromMafsPos', '미펀가격 재로딩')
          }
        >
          (미펀가격 재로딩)
        </button>
        |{[mafsPdf, noMtmPrc].map((v) => getButton(v))}
        |
        <hr className="narrow light" />
        <b>미펀 검증</b>&nbsp;&nbsp;
        {[cmprMafsPosWithCxtMenus].map((v) => getButton(v))}
        <span> | </span>
        <b>통합</b>&nbsp;&nbsp;
        {cmprMafsPosBatches.map((v) => getButton(v))}
        <b>손익검증</b>&nbsp;&nbsp;
        {[
          boMafsBSAcct,
          boPrftFixing,
          boReGenPosBSRsn,
          veriPrdRes,
          strgPrftPrd,
        ].map((v) => getButton(v))}
        <hr className="narrow light" />
        <b>예탁원</b>&nbsp;&nbsp;
        {getButton(ksdStkFill)}
        {getButton(ksdFutFill)}
        {getButton(ksdFill)}
        {[cmprKsdFill].map((v) => getButton(v))}
        <span> |</span>
        <button
          type="button"
          className={linkClass}
          onClick={() =>
            callAxiosFunc(
              'UpdateMissedFixFillFromKsdFill',
              'FIX체결 빠진것 채우기',
            )
          }
        >
          (FIX체결 빠진것 채우기)
        </button>
        <span> |</span>
        <button
          type="button"
          className={linkClass}
          onClick={() =>
            callAxiosFunc('DeleteFixFutSpreadFill', 'FIX선물스프레드체결 삭제')
          }
        >
          (FIX선물스프레드체결 삭제)
        </button>
        <hr className="narrow light" />
        <b>포생</b>&nbsp;&nbsp;
        {[algoAcctFillOvr, boFillAggr, boCashDiv, boPos, boPosDetail].map((v) =>
          getButton(v),
        )}
        <span> | </span>
        <label htmlFor="exceptSprd">
          <input
            id="exceptSprd"
            type="checkbox"
            checked={exceptSprd}
            onChange={(e) => setExceptSprd(e.target.checked)}
          />
          스프레드 제외
        </label>
        <select
          value={genPosAstTy}
          onChange={(e) => {
            setGenPosAstTy(e.target.value as GenPosAstTy);
          }}
          name="genPosAstTy"
        >
          {genPosAstTys.map((v) => (
            <option key={v} value={v}>
              {getGenPosAstTyNm(v)}
            </option>
          ))}
        </select>
        <button
          type="button"
          className={linkClass}
          onClick={() => callAxiosFunc('GenBoPos', '포생 마감', boPos, true)}
        >
          (포생 마감)
        </button>
        |
        <button
          type="button"
          className={linkClass}
          onClick={() => callAxiosFunc('GenBoPos', '포생 임시', boPos)}
        >
          (포생 임시)
        </button>
        |{[settlePrc].map((v) => getButton(v))}|
        <button
          type="button"
          style={{ backgroundColor: 'greenyellow' }}
          className={btnClass}
          onClick={() => callAxiosFunc('VerifyPos', '내부 검증')}
        >
          내부 검증
        </button>
        <button
          type="button"
          className={btnClass}
          onClick={() => callAxiosFunc('VerifyRstr', '매매제한 검증')}
        >
          매매제한 검증
        </button>
        <hr className="narrow light" />
        <b>자금</b>&nbsp;&nbsp;
        {[boFF, boSettle].map((v) => getButton(v))}
        <span> | </span>
        <b>해외</b>&nbsp;&nbsp;
        {[boTrd].map((v) => getButton(v))}
        &nbsp;&nbsp;
        <b>해외선물계좌</b>
        {[boAcctBS, boAcctFF].map((v) => getButton(v))}
        <span> | </span>
        <hr className="narrow light" />
        <b>손익</b>&nbsp;&nbsp;
        {[boLoanFee].map((v) => getButton(v))}
        <button
          type="button"
          className={linkClass}
          onClick={() =>
            callAxiosFunc('GenBoLoanFee', '매니저 대차비용 생성', boLoanFee)
          }
        >
          (매니저 대차비용 생성)
        </button>
        <span> | </span>
        {[boCost, cmprSwapPlWithCxtMenus, boDirBS].map((v) => getButton(v))}

        <hr className="narrow light" />
        <b>기준가</b>&nbsp;&nbsp;
        {[boBS].map((v) => getButton(v))}
        <button
          type="button"
          className={btnClass}
          onClick={() => getFile('BoBsPeriodFile', vh, strg?.Id, undefined)}
        >
          기준가 파일
        </button>
        <button
          type="button"
          className={linkClass}
          onClick={() => callAxiosFunc('GenBoBS', '기준가 생성', boBS)}
        >
          (기준가 생성)
        </button>
        <span>|</span>
        <button
          type="button"
          className={linkClass}
          onClick={() => callAxiosFunc('GenBoBsPeriod', '기준가 연속 생성', boBS)}
        >
          (연속 생성)
        </button>
        <b>리포트</b>&nbsp;&nbsp;
        {[rptPos].map((v) => getButton(v))}
        <hr className="narrow light" />
        <b>기타</b>&nbsp;&nbsp;
        {[housePos].map((v) => getButton(v))}
        <button
          type="button"
          className={linkClass}
          onClick={() =>
            callAxiosFunc('GenHousePos', '하우스 포지션 재생성', housePos)
          }
        >
          (재생성)
        </button>
        {[uFutPosLtd, kFutRolloverFills].map((v) => getButton(v))}
        <button
          type="button"
          className={linkClass}
          onClick={() =>
            callAxiosFunc(
              'SaveKFutRolloverFills',
              '국내선물 청산체결생성',
              kFutRolloverFills,
            )
          }
        >
          (국내선물 청산체결생성)
        </button>
        <b>파일</b>&nbsp;&nbsp;
        <button
          type="button"
          className={btnClass}
          onClick={() => getFile('BoFillAggrFile', vh, strg?.Id, undefined)}
        >
          체결 소계
        </button>
        <button
          type="button"
          className={btnClass}
          onClick={() => getFile('BoPosFile', vh, strg?.Id, undefined)}
        >
          포지션
        </button>
        <button
          type="button"
          className={btnClass}
          onClick={() => getFile('BoCostFile', vh, strg?.Id, undefined)}
        >
          스왑비용
        </button>
        <button
          type="button"
          className={btnClass}
          onClick={() => getFile('MngrPrftPrdFile', vh, undefined, undefined)}
        >
          기간전략손익
        </button>
        <hr className="narrow light" />
      </>
      }

      {user?.isCapital &&
        <>
          <hr className="narrow light" />
          <b>포생</b>&nbsp;&nbsp;
          {[boFillAggr, boCashDiv, boPos, boPosDetail].map((v) =>
            getButton(v),
          )}
          <span> | </span>
          <label htmlFor="exceptSprd">
            <input
              id="exceptSprd"
              type="checkbox"
              checked={exceptSprd}
              onChange={(e) => setExceptSprd(e.target.checked)}
            />
            스프레드 제외
          </label>
          <select
            value={genPosAstTy}
            onChange={(e) => {
              setGenPosAstTy(e.target.value as GenPosAstTy);
            }}
            name="genPosAstTy"
          >
            {genPosAstTys.map((v) => (
              <option key={v} value={v}>
                {getGenPosAstTyNm(v)}
              </option>
            ))}
          </select>
          <button
            type="button"
            className={linkClass}
            onClick={() => callAxiosFunc('GenBoPos', '포생 마감', boPos, true)}
          >
            (포생 마감)
          </button>
          |
          <button
            type="button"
            className={linkClass}
            onClick={() => callAxiosFunc('GenBoPos', '포생 임시', boPos)}
          >
            (포생 임시)
          </button>
          |{[settlePrc].map((v) => getButton(v))}|
          <button
            type="button"
            style={{ backgroundColor: 'greenyellow' }}
            className={btnClass}
            onClick={() => callAxiosFunc('VerifyPos', '내부 검증')}
          >
            내부 검증
          </button>
          <button
            type="button"
            className={btnClass}
            onClick={() => callAxiosFunc('VerifyRstr', '매매제한 검증')}
          >
            매매제한 검증
          </button>
          <hr className="narrow light" />
          <b>기준가</b>&nbsp;&nbsp;
          {[boBS].map((v) => getButton(v))}
          <button
            type="button"
            className={btnClass}
            onClick={() => getFile('BoBsPeriodFile', vh, strg?.Id, undefined)}
          >
            기준가 파일
          </button>
          <button
            type="button"
            className={linkClass}
            onClick={() => callAxiosFunc('GenBoBS', '기준가 생성', boBS)}
          >
            (기준가 생성)
          </button>
          <span>|</span>
          <button
            type="button"
            className={linkClass}
            onClick={() => callAxiosFunc('GenBoBsPeriod', '기준가 연속 생성', boBS)}
          >
            (연속 생성)
          </button>
          <hr className="narrow light" />

          <b>파일</b>&nbsp;&nbsp;
          <button
            type="button"
            className={btnClass}
            onClick={() => getFile('BoFillAggrFile', vh, strg?.Id, undefined)}
          >
            체결 소계
          </button>
          <button
            type="button"
            className={btnClass}
            onClick={() => getFile('BoPosFile', vh, strg?.Id, undefined)}
          >
            포지션
          </button>
          <button
            type="button"
            className={btnClass}
            onClick={() => getFile('BoCostFile', vh, strg?.Id, undefined)}
          >
            스왑비용
          </button>
          <button
            type="button"
            className={btnClass}
            onClick={() => getFile('MngrPrftPrdFile', vh, undefined, undefined)}
          >
            기간전략손익
          </button>
          <hr className="narrow light" />
        </>
      }

      {warnResMsg && (
        <div className="alert alert-slim alert-warning like-pre">
          {warnResMsg}
        </div>
      )}{' '}
      <UrlGrid
        args={gridArgs}
        params={getParams(gridArgs.title)}
        refreshNeeded={refreshNeeded}
      />
    </div>
  );
}
