import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuthState } from '../Auth/AuthContext';
import { DftBtnStyleMx } from '../../AppTypes';
import { UrlGridArgs, UrlGrid } from '../../tmsutil';
import DateSelector from '../../shared/DateSelector';
import { BoFillAggr } from '../../Tms/BO';

const currMenu = '/Middle/Approval';
const btnClass = DftBtnStyleMx;

const dftHeight = 300;

// prettier-ignore
const boFillAggr: UrlGridArgs<BoFillAggr> = {
  url: `${currMenu}/BoFillAggr`,
  title: '체결 소계',
  columns: ['d', 'vhId', 'acctId', 'prodId', 'prodNm', 'book', 'rt', 'rstr', 'isBuy',
    'qty', 'prc', 'fee', 'tax', 'src', 'ty', 'stId', 'stAlloc', 'avgPrc', 'avgFx', 'ord', 'ls', 'fillt', 'userNm', 'aprvState'],
  headers: ['날짜', '펀드', '계좌', '종목코드', '종목명', '북', '실시간', '제한', '매수',
    '수량', '가격', '수수료', '세금', '소스', '구분', '전략', '전략할당구분', '평단가', '평환율', 'ord', '롱숏', '체결시간', '입력자', '승인단계'],
  editable: true,
  meta: { dftColWidth: 80 },
  height: dftHeight,
  adminApprovalMenus: true
};

export default function Approval() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const d0 = searchParams.get('d0') || d;
  const [refreshNeeded, setRefreshNeeded] = useState(0);

  return (
    <div className="row children-mb-3" style={{ minWidth: '1500px' }}>
      <div className="col-4">
        <DateSelector
          value={d}
          initDate0={d0}
          onChange={(date) => date !== d && setSearchParams({ d: date, d0 })}
          onChangeD0={(date0) =>
            date0 !== d0 && setSearchParams({ d, d0: date0 })
          }
        />
        &nbsp;&nbsp;
        <button
          type="button"
          className={btnClass}
          onClick={() => setRefreshNeeded((p) => p + 1)}
        >
          조회
        </button>
      </div>
      <hr className="narrow light" />
      <div>
        <UrlGrid
          args={boFillAggr}
          params={{ d, d0 }}
          refreshNeeded={refreshNeeded}
        />
      </div>
    </div>
  );
}
