import React, { useEffect, useState } from 'react';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import DateSelector from '../../shared/DateSelector';
import VhclSelector from '../../shared/VhclSelector';
import { Broker, VhGrpTy } from '../../Tms/Tms';
import { UrlGrid, UrlGridArgs, callAxios, emptyGridArgs } from '../../tmsutil';
import ButtonWithHelp from '../../shared/ButtonWithHelp';
import { DftBtnStyle } from '../../AppTypes';
import { BrkFeeStat, EtfPdf, KsdEtfSetupRdmpt, KsdEtfSrBsk, LpDaily, MafsCashDiv, etfPdfSchema, mafsCashDivSchema } from '../../Tms/Etf';
import FileUploadDialog from '../../shared/FileUploadDialog';
import { BoFF } from '../../Tms/BO';
import { CronJob, CronJobLog } from '../../Tms/CronJob';

const currMenu = '/ETF/Data';
const dftHeight = 900;
type PageFunc = 'GenLpDaily' | 'UpdateEtfTmrwFlow' | 'DeleteKsdEtfSetupRdmpt';

// prettier-ignore
const etfPdf: UrlGridArgs<EtfPdf> = {
    url: `${currMenu}/EtfPdf`,
    title: '미펀 PDF',
    columns: ['d', 'vhId', 'prodId', 'prodNm', 'pos'],
    headers: ['날짜', '펀드', '종목코드', '종목명', '수량'],
    editable: true,
    meta: { dftColWidth: 120 },
    height: dftHeight,
    schema: etfPdfSchema
}

// prettier-ignore
const mafsCashDiv: UrlGridArgs<MafsCashDiv> = {
    url: `${currMenu}/MafsCashDiv`,
    title: '미펀 현금배당',
    columns: ['d', 'vhId', 'fundCd', 'prodId', 'prodNm', 'pos', 'div', 'divamt', 'errMsg'],
    headers: ['날짜', '펀드', '펀드코드', '종목코드', '종목명', '기준주수', '주당배당금', '총배당금', '에러'],
    editable: true,
    meta: { dftColWidth: 120 },
    height: dftHeight,
    schema: mafsCashDivSchema
}

// prettier-ignore
const ksdEtfSetupRdmpt: UrlGridArgs<KsdEtfSetupRdmpt> = {
    url: `${currMenu}/KsdEtfSetupRdmpt`,
    title: '설정/환매 청구',
    columns: ['d', 'vhId', 'brkId', 'acptNo', 'proc', 'job', 'prop', 'etfTy', 'etfCd', 'apCd', 'reqCu', 'stlD'],
    headers: ['날짜', '펀드', 'AP', '접수번호', '진행구분', '업무구분', '재산구분', 'ETF유형', 'ETF코드', 'AP코드', '청구CU수', '결제일'],
    editable: false,
    meta: {
        dftColWidth: 60,
    },
    widths: {
        acptNo: 80,
        etfCd: 120,
        stlD: 120,
        apCd: 120,
    },
    height: 400,
    infoMsg: ['진행구분 : 1. 승인 2.미승인',
        '업무구분 : 1.설정 2.환매 3.금액설정',
        '재산구분 : 1.자기 2.투자자',
        'ETF유형 : 1.일반 2.레버리지 3.인버스 9.해당없음'
    ].join('\n'),

}
const ksdEtfSrBsk: UrlGridArgs<KsdEtfSrBsk> = {
    url: `${currMenu}/KsdEtfSrBsk`,
    title: '',
    columns: ['prodId', 'prodNm', 'needq', 'paidq', 'exceptq', 'shortq'],
    headers: ['종목코드', '종목명', '필요수량', '납입수량', '미구성수량', '부족수량'],
    editable: false,
    meta: {
        dftColWidth: 80,
    },
    widths: {},
    height: 400,
}
// prettier-ignore
const lpDaily: UrlGridArgs<LpDaily> = {
    url: `${currMenu}/LpDaily`,
    title: '당/익일 LP daily',
    columns: ['d', 'vhId', 'brkId', 'brkNm', 'ch2', 'ch1', 'ch', 'cu', 'cuAdj'],
    headers: ['날짜', '펀드', 'LP코드', 'LP명', '금일신청', '익일납입', '금일납입', 'CU수', 'CU수기조정'],
    editable: false,
    meta: { dftColWidth: 120 },
    height: dftHeight,
}

// prettier-ignore
const etfFlow: UrlGridArgs<BoFF> = {
    url: `${currMenu}/EtfFlow`,
    title: '당/익일 입출금',
    columns: ['d', 'vhId', 'stId', 'flow', 'src'],
    headers: ['날짜', '펀드', '전략', '입출금', '소스'],
    editable: false,
    meta: { dftColWidth: 120 },
    height: dftHeight,
}

// prettier-ignore
const brk: UrlGridArgs<Broker> = {
    url: `${currMenu}/Brk`,
    title: 'LP 코드',
    columns: ['Id', 'nm', 'KsdCd'],
    headers: ['LP', 'LP명', '예탁원코드'],
    editable: false,
    meta: { dftColWidth: 120 },
    height: dftHeight,
}

// prettier-ignore
const brkFeeStat: UrlGridArgs<BrkFeeStat> = {
    url: `${currMenu}/BrkFeeStat`,
    title: 'LP 수수료',
    columns: ['brkId', 'brkNm', 'fee'],
    headers: ['LP', 'LP명', '수수료(원)'],
    editable: false,
    meta: { dftColWidth: 120 },
    height: dftHeight,
}

// prettier-ignore
const EtfCronJobFile: UrlGridArgs<CronJob> = {
    url: `${currMenu}/CronJob`,
    title: 'ETF 작업 로그',
    columns: ['note', 'func', 't0', 't1', 'endT', 'ty', 'msg'],
    headers: ['이름', '함수', 't0', 't1', '실행시간', '구분', 'msg'],
    editable: false,
    meta: { dftColWidth: 120 },
    widths: { note: 200, t0: 60, t1: 60, endT: 120, msg: 250, },
    height: 400,
}

// prettier-ignore
const cronJobLog: UrlGridArgs<CronJobLog> = {
    url: `${currMenu}/CronJobLog`,
    title: '최근 로그',
    columns: ['startT', 'endT', 'ty', 'msg'],
    editable: false,
    meta: { dftColWidth: 120, },
    height: 400,
    widths: { startT: 120, endT: 120, ty: 80, msg: 250 },
};

type T = { Id: number }; // 임의 IId 타입

export default function EtfData() {
    const { info } = useAuthState();
    const { msgBox: m, logger } = useMessageState();
    const [d, setD] = useState<string>(info?.currBizDay || '');
    const [d0, setD0] = useState<string | null>(info?.currBizDay || '');
    const [vh, setVh] = useState<string | null>('ETF');
    const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);
    const [subGridArgs, setSubGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);
    const [subDataId, setSubDataId] = useState<number | null>(null);

    const [logMsg, setLogMsg] = useState<string | null>(null);

    const [refreshNeeded, setRefreshNeeded] = useState(0);
    const [subRefreshNeeded, setSubRefreshNeeded] = useState(0);

    const [fileUploadVisible, setFileUploadVisible] = useState<boolean>(false);
    const [fileUploadTitle, setFileUploadTitle] = useState<string>('');
    const [fileUploadUrl, setFileUploadUrl] = useState<string>('');

    useEffect(() => {
        setGridArgs(emptyGridArgs);
        setSubGridArgs(emptyGridArgs);
    }, [d, d0]);

    const linkClass = 'btn btn-link';
    const btnClass = DftBtnStyle;

    const KsdEtfSetupRdmptFileUpload = () => {
        setFileUploadVisible(true);
        setFileUploadTitle('장후 예탁원 설정환매 파일');
        setFileUploadUrl(`${currMenu}/UploadKsdEtfSetupRdmptFile`);
    };

    const query = (args: unknown) => {
        setSubGridArgs(emptyGridArgs);
        setRefreshNeeded((p) => p + 1);
        setGridArgs(args as UrlGridArgs<T>);
    };

    const getButton = (a: unknown) => {
        const { title } = a as UrlGridArgs<T>;
        return (
            <ButtonWithHelp
                key={title}
                helpid={title}
                className={btnClass}
                onClick={() => {
                    query(a);
                }}
                label={title}
            />
        );
    };

    const call = (func: PageFunc, params: unknown, confirmMsg: string, callbackGridArgs?: unknown) => {
        callAxios({
            m,
            logger,
            url: `${currMenu}/${func}`,
            params,
            confirmMsg,
            onSuccess: () => {
                setGridArgs(callbackGridArgs as UrlGridArgs<T>);
            },
            onBegin: () => {
                if (func !== 'DeleteKsdEtfSetupRdmpt') {
                    setGridArgs(emptyGridArgs)
                }
            },
        });
    };

    const deleteKsdRdmpt = (items: KsdEtfSetupRdmpt[]) => {
        if (!items) return;
        call('DeleteKsdEtfSetupRdmpt', { d }, '전체 삭제?');
    }

    const ksdEtfSetupRdmptWithRowClick: UrlGridArgs<KsdEtfSetupRdmpt> = {
        ...ksdEtfSetupRdmpt,
        meta: {
            ...ksdEtfSetupRdmpt.meta,
            onRowClick: (r) => {
                setSubDataId(r.original.Id)
                setSubGridArgs(ksdEtfSrBsk as unknown as UrlGridArgs<T>)
                setSubRefreshNeeded((p) => p + 1);
            },
            contextMenus: [
                {
                    label: '전체 삭제',
                    callback: deleteKsdRdmpt
                },
            ],
        },
    };


    const EtfCronJobLogArgs: UrlGridArgs<CronJobLog> = {
        ...cronJobLog,
        meta: {
            ...cronJobLog.meta,
            onRowClick: (r) => {
                let { msg } = r.original;
                if (msg != null) {
                    msg = msg.replace(/;;/g, '\n');
                    setLogMsg(msg);
                }
            },
        },
    };

    const EtfCronJobFileArgs: UrlGridArgs<CronJob> = {
        ...EtfCronJobFile,
        meta: {
            ...EtfCronJobFile.meta,
            onRowClick: (r) => {
                setSubDataId(r.original.Id)
                setSubGridArgs(EtfCronJobLogArgs as unknown as UrlGridArgs<T>)
                setSubRefreshNeeded((p) => p + 1);
            },
            contextMenus: [
                {
                    label: '실행 요청',
                    callback: (items: CronJob[]) => {
                        callAxios({
                            m,
                            logger,
                            url: `${currMenu}/AddCronJobReq`,
                            params: { d, id: items[0].Id },
                            onSuccess: (_, res) => res.data.warnings?.length ? m.alert(res.data.warnings) : m.alert('OK'),
                        });
                    }
                },
            ]
        },
    };


    return (
        <div style={{ minWidth: '1500px' }} className="children-me-2">
            <DateSelector
                value={d}
                initDate0={d0}
                onChange={(date) => setD(date)}
                onChangeD0={(date0) => setD0(date0)}
            />
            <VhclSelector
                d={d}
                value={vh}
                grp={VhGrpTy.ETF}
                onChange={(v) => setVh(v?.Id ?? '')}
                meta
            />
            {[etfPdf, mafsCashDiv].map((v) => getButton(v))}

            <hr className="narrow light" />
            <b>장후</b>
            <span> 1)예탁원 </span>
            {getButton(ksdEtfSetupRdmptWithRowClick)}
            <ButtonWithHelp
                onClick={() => KsdEtfSetupRdmptFileUpload()}
                helpid="설정환매청구"
                label="파일선택"
            />
            |
            <ButtonWithHelp
                className={linkClass}
                onClick={() =>
                    call('GenLpDaily', { d }, '당/익일 LP daily 생성', lpDaily)
                }
                helpid="lpdaily생성"
                label="2) 당/익일 LP daily 생성"
            />
            <ButtonWithHelp
                className={linkClass}
                onClick={() =>
                    call('UpdateEtfTmrwFlow', { d }, '익일 입출금 선반영', etfFlow)
                }
                helpid="입출금선반영"
                label="3) 익일 입출금 선반영"
            />

            <hr className="narrow light" />

            {[lpDaily, etfFlow, brk, brkFeeStat, EtfCronJobFileArgs].map((v) => getButton(v))}

            <UrlGrid
                args={gridArgs}
                params={{ d0, d, vhOrGrp: vh }}
                refreshNeeded={refreshNeeded}
            />

            <FileUploadDialog
                headerTitle={fileUploadTitle}
                fileExtensionLimit={['txt']}
                fileSizeLimit={5}
                params={{ d }}
                url={fileUploadUrl}
                visible={fileUploadVisible}
                setVisible={setFileUploadVisible}
                setRefreshNeeded={setRefreshNeeded}
            />
            {logMsg && (
                <div style={{ width: '900px' }} className="alert alert-slim alert-info like-pre">{logMsg}</div>
            )}
            <UrlGrid
                args={subGridArgs}
                params={{ id: subDataId, d }}
                refreshNeeded={subRefreshNeeded}

            />

        </div>
    );
}