import React, { useEffect } from 'react';

export default function ButtonGroup({
  name,
  value: initialValue,
  options,
  onChange,
  labels,
  containerStyle,
  labelStyle,
  disabled,
}: {
  name: string;
  value: string;
  options: string[];
  onChange?: (value: string) => void;
  labels?: string[];
  containerStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  disabled?: boolean;
}) {
  const [value, setValue] = React.useState<string>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    onChange?.(value);
  }, [value]);

  return (
    <div className="btn-group" style={containerStyle}>
      {options.map((o, i) => (
        <React.Fragment key={`${o}`}>
          <input            
            type="radio"
            className="btn-check"
            name={name}
            id={`${name}_${o}`}
            value={o}
            checked={value === o}
            onChange={(e) => setValue(e.target.value)}
            disabled={disabled}
          />
          <label
            htmlFor={`${name}_${o}`}
            className="btn btn-outline-primary rounded-0"
            style={labelStyle}
          >
            {labels ? labels[i] : `${o}`}
          </label>
        </React.Fragment>
      ))}
    </div>
  );
}
