import React, { useEffect, useState } from 'react';
import { toNumber } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { rgb2color } from 'tmslib/src/util/uiutil';
import { ifesleExpr } from 'tmslib/src/util/utils';
import { useAuthState } from '../Auth/AuthContext';
import { DftBtnStyle } from '../../AppTypes';
import {
  UrlGrid,
  UrlGridArgs,
  emptyGridArgs,
  callAxiosGet,
} from '../../tmsutil';
import DateSelector from '../../shared/DateSelector';
import { setDropColUS, LsScoreChartAndTable } from './LsCommon';
import './lsPickUS.scss';

const page = '/Front/LsPickUS';

type SectorRes = {
  Id: string;
  nm: string;
};

type PickErResUS = {
  Id: number;
  prodId: string;
  prodNm: string;
  tics: string | null;
  mc: number | null;
  ta20a: number | null;
  HBH: number | null;
  LCH: number | null;
  KDB22: number | null;
  HSH22: number | null;
  MICH: number | null;
  ch: number | null;
  cnt: number | null;
  er: number | null;
  mer0: number | null;
  mer1: number | null;
  oiy: number | null;
  niy: number | null;
  oiq: number | null;
  niq: number | null;
  fore0: number | null;
  fore1: number | null;
  inst0: number | null;
  inst1: number | null;
  dr0: number | null;
  dr1: number | null;
  dr2: number | null;
  yqDiff: number | null; // UI 용
};

const sts = ['HBH', 'LCH', 'KDB22', 'HSH22', 'MICH'];
const lsPickUSDft: UrlGridArgs<PickErResUS> = {
  url: '',
  title: '',
  // prettier-ignore
  columns: ['prodId', 'prodNm', 'tics','mc', 'ta20a', 'HBH', 'LCH', 'KDB22', 'HSH22', 'MICH',
            'cnt', 'ch', 'oiy', 'oiq', 'yqDiff', 'dr0', 'dr1', 'dr2'],
  // prettier-ignore
  headers: ['Code', 'Name', 'Sector', '시총(bln)', '거래(mln)', 'HBH', 'LCH', 'KDB22', 'HSH22', 'MICH',
            '#R', 'Ch(%)', 'FndmY', 'FndmQ', 'Y-Q', 'D0', 'D1', 'D2'],
  widths: { prodNm: 250, tics: 80, mc: 70, ta20a: 70 },
  height: undefined,
  meta: {
    dftColWidth: 50,
    // useGlobalFilter: false,
    showRowNum: true,
    containerClass: 'lsPickUS',
  },
  dftFormatter: (v, c, r) => {
    if (v === null) return v;
    if (c === 'yqDiff') {
      if (r?.original?.oiy === null || r?.original?.oiq === null) return null;
      return (
        (r?.original?.oiy ?? null) - (r?.original?.oiq ?? null)
      ).toFixedWithComma(1);
    }
    if (c === 'mc') return ((v as number) * 1e-9)?.toFixedWithComma(0);
    if (c === 'ta20a') return ((v as number) * 1e-6)?.toFixedWithComma(0);
    if (sts.indexOf(c) >= 0) return (v as number).toFixed(1); // * 100 ?
    if (c === 'ch') return (v as number).toFixed(2);
    if (['er', 'mer0', 'mer1'].indexOf(c) >= 0) return v?.toFixed(4);
    // prettier-ignore
    if (['oiy', 'oiq', 'niy','dr0', 'dr1', 'dr2'].indexOf(c) >= 0) return v?.toFixed(1);

    return v;
  },
  dftStyler: (v, c, r) => {
    let color: string | undefined;
    let backgroundColor: string | undefined;
    if (v === null) return { color, backgroundColor };
    if (sts.indexOf(c) >= 0) color = (v as number)?.getSignColor();
    if (c === 'ch') color = v?.getSignColor();
    if (['er', 'mer0', 'mer1'].indexOf(c) >= 0) {
      const norm = (v as number) * 500;
      color =
        ifesleExpr(
          [(v as number) > 0, rgb2color(norm, 0, 0)],
          [(v as number) < 0, rgb2color(0, 0, -norm)],
        ) ?? '#999';
    }
    // prettier-ignore
    if (['oiy', 'oiq', 'niy'].indexOf(c) >= 0) {
      if ((v as number) > 70) {
        const norm = (v as number - 70) * (c === 'oiy' || c === 'oiq' ? 5 : 3);
        backgroundColor = rgb2color(255, 255 - norm, 255 - norm);
        if (norm > 100) color = 'white';
      }
      if ((v as number) < 30) {
        const norm = (30 - (v as number)) * (c === 'oiy' || c === 'oiq' ? 5 : 3);
        backgroundColor = rgb2color(255 - norm, 255 - norm, 255)
        if (norm > 100) color = 'white'
      }
    }
    if (c === 'yqDiff') {
      if (r?.original?.oiy !== null && r?.original?.oiq !== null) {
        // Unsafe arithmetic operation on optional chaining. It can result in NaN  no-unsafe-optional-chaining
        const v0: number =
          (r?.original?.oiy ?? null) - (r?.original?.oiq ?? null);
        if (v0 > 30) {
          const norm = (v0 - 30) * 1.5;
          backgroundColor = rgb2color(255, 255 - norm, 255 - norm);
          if (norm > 100) color = 'white';
        }
        if (v0 < -30) {
          const norm = (-30 - v0) * 1.5;
          backgroundColor = rgb2color(255 - norm, 255 - norm, 255);
          if (norm > 100) color = 'white';
        }
      }
    }
    if (['dr0', 'dr1', 'dr2'].indexOf(c) >= 0) {
      [color, backgroundColor] = setDropColUS(v as number);
    }

    return { color, backgroundColor };
  },
};

export default function LsPickUS() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [idx, setIdx] = useState<string | null>(null);
  const [sec, setSec] = useState<string>('Whole');
  const [sectors, setSectors] = useState<SectorRes[]>([]);
  const [edQ, setEdQ] = useState<string>('');
  const [minRpts, setMinRpts] = useState<number>(1);
  const [drlen1, setDrlen1] = useState<number>(5);
  const [drlen2, setDrlen2] = useState<number>(10);
  const [drlen3, setDrlen3] = useState<number>(20);

  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [lsPickUSArgs, setLsPickUSArgs] =
    useState<UrlGridArgs<PickErResUS>>(emptyGridArgs);

  const [showScore, setShowScore] = useState<boolean>(false);
  const [prodId, setProdId] = useState<string>('');
  const [prodNm, setProdNm] = useState<string>('');

  const lsPickUS: UrlGridArgs<PickErResUS> = {
    ...lsPickUSDft,
    url: `${page}/Data`,
    meta: {
      ...lsPickUSDft.meta,
      onRowClick: (r) => {
        setShowScore(true);
        setProdId(r.original.prodId);
        setProdNm(r.original.prodNm);
      },
    },
  };

  const queryForLsPickUS = (idx0: string | null) => {
    setShowScore(false);
    setIdx(idx0);
    setLsPickUSArgs(emptyGridArgs);
    setLsPickUSArgs(lsPickUS);
    setRefreshNeeded((p) => p + 1);
  };

  useEffect(() => {
    callAxiosGet({
      m,
      logger,
      url: `${page}/Sector`,
      params: { d },
      onSuccess: (data) => {
        setSearchParams({ d: data.bfBizDay });
        setSectors(data.sectors);
      },
    });
    const now = new Date();
    setEdQ(now.consenEoQ().toFormatString('yyyy-MM-dd'));
  }, []);

  useEffect(() => {
    queryForLsPickUS(null);
    setProdId('');
    setProdNm('');
  }, [d]);

  return (
    <>
      <div className="target children-me-2" style={{ minWidth: '1300px' }}>
        <DateSelector
          value={d}
          onChange={(date) => {
            if (date !== d) {
              setSearchParams({ d: date });
            }
          }}
        />
        <select value={sec} onChange={(e) => setSec(e.target.value)}>
          {sectors.map((v) => (
            <option key={v.Id} value={v.Id}>
              {v.nm}
            </option>
          ))}
        </select>
        &nbsp;
        <span style={{ color: 'gray' }}>
          분기말:
          <input
            id="qed"
            type="text"
            value={edQ}
            size={10}
            onChange={(e) => setEdQ(e.target.value)}
          />
        </span>
        <button
          type="button"
          className={DftBtnStyle}
          onClick={() => queryForLsPickUS(null)}
        >
          Get
        </button>
        <button
          type="button"
          className={DftBtnStyle}
          onClick={() => queryForLsPickUS('ISPX')}
        >
          SP500
        </button>
        <button
          type="button"
          className={DftBtnStyle}
          onClick={() => queryForLsPickUS('INDX')}
        >
          NASDAQ100
        </button>
        <hr className="narrow light" />
        min.Rpts:
        <input
          type="text"
          id="minRpts"
          size={3}
          value={minRpts}
          onChange={(e) => setMinRpts(toNumber(e.target.value))}
        />
        &nbsp;&nbsp; (Drop):
        <input
          type="text"
          id="drlen1"
          size={3}
          value={drlen1}
          onChange={(e) => setDrlen1(toNumber(e.target.value))}
        />
        <input
          type="text"
          id="drlen2"
          size={3}
          value={drlen2}
          onChange={(e) => setDrlen2(toNumber(e.target.value))}
        />
        <input
          type="text"
          id="drlen3"
          size={3}
          value={drlen3}
          onChange={(e) => setDrlen3(toNumber(e.target.value))}
        />
      </div>
      <hr className="narrow light" />
      <div className="row" style={{ width: '4020px' }}>
        <div className="col-4">
          <UrlGrid
            args={lsPickUSArgs}
            params={{ d, rpts: minRpts, drlen1, drlen2, drlen3, edQ, sec, idx }}
            refreshNeeded={refreshNeeded}
          />
        </div>
        <div className="col-8">
          {showScore && (
            <LsScoreChartAndTable
              subPage="LsPickUS"
              prodId={prodId}
              prodNm={prodNm}
            />
          )}
        </div>
      </div>
    </>
  );
}
