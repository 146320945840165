import React, { useState, useEffect } from 'react';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { useSearchParams } from 'react-router-dom';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import { DftBtnStyleMx } from '../../AppTypes';
import DateSelector from '../../shared/DateSelector';
import {
  UrlGrid,
  UrlGridArgs,
  emptyGridArgs,
  callAxiosGet,
} from '../../tmsutil';
import { InvByOtherFunds, invByOtherFundsSchema } from '../../Tms/TmsDocu';

const page = '/Fund/FoHFs';
const btnClass = DftBtnStyleMx;
type T = { Id: number };

export interface FoHFsRes {
  Id: number;
  vhId: string;
  amtVhCcy: number;
  wei: number;
  nav: number;
  na: number;
  weiInHedge: number;
  weiInHedgeByOthers: number;
  fundWeiInHedge: number;
}

const invByOtherFunds: UrlGridArgs<InvByOtherFunds> = {
  url: `${page}/InvByOtherFunds`,
  title: '타사 펀드 투자 현황',
  // prettier-ignore
  columns: ['vhId', 'inv', 'ty', 'clt', 'd0', 'units', 'd1', 'nav', 'amt', 'wei'],
  // prettier-ignore
  headers: ['피투자펀드', '투자펀드운용사', '공/사모', '수익자', '투자일', '투자좌수', '환매일', '당일기준가', '투자금액', '투자비중'],
  // prettier-ignore
  widths: {vhId: 90, ty: 70, d0:80, d1: 80,wei: 90},
  editable: true,
  height: undefined,
  meta: {
    dftColWidth: 120,
  },
  schema: invByOtherFundsSchema,
};

export default function FoHFs() {
  const { msgBox: m, logger } = useMessageState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const d = searchParams.get('d') || info?.currBizDay || '';

  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [gridInvByOtherFunds, setGridInvByOtherFunds] =
    useState<UrlGridArgs<T>>(emptyGridArgs);
  const [foHFsRes, setFoHFsRes] = useState<FoHFsRes[]>([]);

  const getFoHFsRes = () =>
    callAxiosGet({
      m,
      logger,
      url: `${page}/Data`,
      params: { d },
      onSuccess: (data) => setFoHFsRes(data),
    });

  const query = (args: unknown) => {
    setGridInvByOtherFunds(emptyGridArgs);
    setRefreshNeeded((p) => p + 1);
    setGridInvByOtherFunds(args as UrlGridArgs<T>);
  };

  const getData = () => {
    getFoHFsRes();
    query(invByOtherFunds);
  };
  useEffect(() => {
    getData();
  }, [d]);

  return (
    <div className="children-me-2" style={{ minWidth: '1500px' }}>
      <DateSelector
        value={d}
        onChange={(date) => date !== d && setSearchParams({ d: date })}
      />
      <button type="button" className={btnClass} onClick={() => getData()}>
        투자 비율
      </button>
      <hr className="narrow light" />
      <b>사모펀드 투자 비율</b>
      <SimpleGrid
        data={foHFsRes}
        // prettier-ignore
        columns={['vhId', 'amtVhCcy', 'wei', 'nav', 'na', 'weiInHedge', 'weiInHedgeByOthers', 'fundWeiInHedge']}
        // prettier-ignore
        headers={['펀드코드', '투자금액', '투자비율', '기준가', '순자산', '당사 + 타사', '타사', '전체 재간접 비율']}
        args={{
          widths: { fundWeiInHedge: 120 },
          meta: {
            dftColWidth: 100,
          },
          headerGroups: [
            ['피투자펀드', 5],
            ['사모 내 공모 비율', 2],
            ['', 1],
          ],
          dftStyler: (v, c, r) => {
            if (r.original.vhId === 'Sum') return { backgroundColor: 'yellow' };
            // prettier-ignore
            if (['nav','wei','weiInHedge','weiInHedgeByOthers','fundWeiInHedge',].indexOf(c) >= 0) {
              if (r.original.vhId !== 'Sum') {
                if (c === 'wei' && r.original.wei > 20)
                  return { backgroundColor: 'orange' };
                if (c === 'weiInHedge' && r.original.weiInHedge > 50)
                  return { backgroundColor: 'orange' };
                if (c === 'fundWeiInHedge' && r.original.fundWeiInHedge > 40)
                  return { backgroundColor: 'orange' };
              }
            }
            return null;
          },
          dftFormatter: (v, c) => {
            if (c === 'amtVhCcy' || c === 'na') return v?.toFixedWithComma(0);
            // prettier-ignore
            if (['nav','wei','weiInHedge','weiInHedgeByOthers','fundWeiInHedge',].indexOf(c) >= 0) {
              return v?.toFixedWithComma(2);
            }
            return v;
          },
          infoMsg: `[관리 기준]
투자비율 : 20% 이내
사모 내 공모 비율 : 50% 이하
전체 재간접 비율 : 40% 이하`,
        }}
      />
      <hr className="narrow light" />
      <div>
        <UrlGrid
          args={gridInvByOtherFunds}
          params={{ d }}
          refreshNeeded={refreshNeeded}
        />
      </div>
    </div>
  );
}
