import { Cookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie/cjs/types';

const cookies = new Cookies();

export const setCookie = (
  name: string,
  value: string,
  option: CookieSetOptions,
) => cookies.set(name, value, { ...option });

export const getCookie = (name: string) => cookies.get(name);

export const removeCookie = (name: string) => cookies.remove(name);
