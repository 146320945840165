import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Row } from '@tanstack/react-table';
import { gradationStyle, rescale } from 'tmslib/src/util/utils';
import { useAuthState } from '../Auth/AuthContext';
import DateSelector from '../../shared/DateSelector';
import { GetVhGrpName, VhGrpTy } from '../../Tms/Tms';
import StrgSelector from '../../shared/StrgSelector';
import { UrlGrid, UrlGridArgs } from '../../tmsutil';
import { DftBtnStyle } from '../../AppTypes';
import { UserGroup } from '../../Tms/Identity';

const currMenu = '/Front/VhDiff';

interface VhWei {
  Id: number;
  prodId: string;
  prodNmRstr: string;
  reprVhId: string;
  weiAvg: number;
  weiDic: { [key: string]: number };
}

const vhDiff: UrlGridArgs<VhWei> = {
  url: `${currMenu}/VhWei`,
  title: '',
  columns: [],
  headers: [],
  meta: {
    dftColWidth: 40,
  },
  widths: { prodNmRstr: 180 },
  height: 1500,
  dftFormatter: (v) => v?.toFixed(2),
  dftStyler: (v: unknown, c: keyof VhWei, r: Row<VhWei>) => {
    if (['prodId', 'prodNmRstr', 'weiAvg'].indexOf(c) >= 0) {
      return { backgroundColor: 'azure' };
    }
    const o = r.original;
    const diff = (v as number) - o.weiDic[o.reprVhId];
    return gradationStyle(rescale(diff, 0, 70));
  },
  dynamicColumns: (res) => {
    const { vhs } = res.data.extras;
    const flds = vhs.map((v: string) => `weiDic.${v}`);
    return {
      columns: ['prodId', 'prodNmRstr', 'weiAvg'].concat(flds),
      headers: ['종목코드', '종목명', '평균'].concat(vhs),
    };
  },
};

const FundTgts = [VhGrpTy.HFund, VhGrpTy.VFund, VhGrpTy.AIFund, VhGrpTy.Troika];

export default function VhDiff() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, info } = useAuthState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const tgts = user?.Group === UserGroup.RFM ? [VhGrpTy.RFM_BM, VhGrpTy.RFM_LS] : FundTgts
  const tgt = searchParams.get('tgt') || tgts[0];
  const st = searchParams.get('st') || 'Whole';
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  useEffect(() => setRefreshNeeded((p) => p + 1), [d, tgt, st]);

  return (
    <>
      <div
        style={{ display: 'flex', flexDirection: 'row' }}
        className="children-me-2"
      >
        <DateSelector
          value={d}
          onChange={(date) =>
            date !== d && setSearchParams({ d: date, tgt, st })
          }
        />
        <select
          value={tgt}
          onChange={(e) => setSearchParams({ d, tgt: e.target.value, st })}
        >
          {tgts.map((t) => (
            <option key={t} value={t}>
              {GetVhGrpName(t)}
            </option>
          ))}
        </select>
        <StrgSelector
          d={d}
          vhGrp={tgt as VhGrpTy}
          value={st}
          onChange={(v) => {
            if (v && v.Id !== st) {
              setSearchParams({ d, tgt, st: v.Id });
            }
          }}
          whole
        />
        <button
          type="button"
          className={DftBtnStyle}
          onClick={() => setRefreshNeeded((p) => p + 1)}
        >
          조회
        </button>
      </div>
      <hr className="narrow light" />
      <UrlGrid
        args={vhDiff}
        params={{ d, tgt, st }}
        refreshNeeded={refreshNeeded}
      />
      <br />
    </>
  );
}
