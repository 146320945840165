import React, { useEffect, useState } from 'react';
import Dialog from 'tmslib/src/ui/Dialog';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Exporting from 'highcharts/modules/exporting';
import exportingcsv from 'highcharts/modules/export-data';

Exporting(Highcharts);
exportingcsv(Highcharts);

export type PerfChartsExDlgArgs = {
  visible: boolean;
  daily: Highcharts.Options;
};

interface Props {
  args: PerfChartsExDlgArgs;
  setArgs: React.Dispatch<React.SetStateAction<PerfChartsExDlgArgs>>;
}

const chtOpt: Highcharts.Options = {
  chart: {
    alignTicks: false,
    type: 'line',
    width: 1000,
    height: 600,
    zooming: {
      type: 'x',
    },
  },
  credits: { enabled: false },
  title: { text: '' },
  accessibility: { enabled: false },
  plotOptions: {
    line: {
      marker: { enabled: false },
      shadow: false,
      animation: false,
      lineWidth: 1,
    },
  },
};

export default function PerfChartsExDlg({ args, setArgs }: Props) {
  const [chartOptions, setChart] = useState<Highcharts.Options>(chtOpt);

  useEffect(() => {
    if (!args.daily) return;
    setChart((p) => ({
      ...p,
      colors: args.daily.colors,
      xAxis: args.daily.xAxis,
      yAxis: args.daily.yAxis,
      series: args.daily.series,
    }));
  }, [args.daily]);

  return (
    <div className="card flex justify-content-center">
      <Dialog
        header=""
        visible={args.visible}
        onHide={() => setArgs({ ...args, visible: false })}
      >
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </Dialog>
    </div>
  );
}
