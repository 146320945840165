import 'bootstrap/dist/css/bootstrap.css';
import 'tmslib/src/tmslib.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import * as Sentry from '@sentry/react';
import { MessageProvider } from 'tmslib/src/context/MessageContext';
import { AuthProvider } from './pages/Auth/AuthContext';
import App from './App';

Sentry.init({
  dsn: 'https://04371d4f7e226fc86533b286ea87da0d@o4507274418913280.ingest.us.sentry.io/4507274421534720',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  enabled: process.env.NODE_ENV !== 'development',
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    // 'localhost',
    /^https:\/\/tms\.timefolio\.net/,
    /^https:\/\/tms2\.timefolio\.net/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // ignoreErrors: ['Non-Error promise rejection captured'], 아이폰서 브라우저가 아이들상태 갔을때 발생으로 보임. 더 발생안하는걸로 봐서 고쳐진것 같아 ignore에서 뺌.
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(
  <CookiesProvider>
    <BrowserRouter basename="">
      <MessageProvider useLogging>
        <AuthProvider>
          <App />
        </AuthProvider>
      </MessageProvider>
    </BrowserRouter>
  </CookiesProvider>,
);
