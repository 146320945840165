import React, { useMemo, useState } from 'react';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { UrlGrid, UrlGridArgs , callAxiosGet, checkItems } from '../../tmsutil'; 
import {
  NginxAccessLog,
  WebMenu,
  WebMenuAuth,
  webMenuAuthSchema,
  webMenuSchema,
} from '../../Tms/Identity';
import { DftBtnStyleMx } from '../../AppTypes';

const Page = '/Admin/MenuAuth';

const webMenuDft: UrlGridArgs<WebMenu> = {
  url: `${Page}/WebMenu`,
  title: '메뉴',
  // prettier-ignore
  columns: ['grp', 'name', 'args', 'title', 'subGrp', 'ord', 'invalid', 'hide', 'react', 'obsolete','etitle'],
  editable: true,
  height: 800,
  meta: { dftColWidth: 80 },
  widths: { grp: 50, ord: 40 },
  schema: webMenuSchema,
};

const webMenuAuth: UrlGridArgs<WebMenuAuth> = {
  url: `${Page}/WebMenuAuth`,
  title: '메뉴 리스트',
  // prettier-ignore
  columns: ['userGrp', 'userId', 'menuGrp', 'menu', 'args', 'permission'],
  editable: true,
  height: 800,
  meta: { dftColWidth: 80 },
  widths: { userGrp: 50, menuGrp: 50, permission: 50 },
  schema: webMenuAuthSchema,
};

export default function MenuAuth() {
  const { msgBox: m, logger } = useMessageState();
  const [all, setAll] = useState(false);
  const [accessLogs, setAccessLogs] = useState<NginxAccessLog[]>([]);
  const [refreshNeeded, setRefreshNeeded] = useState(0);

  const getAccessLog = (items: WebMenu[]) => {
    if (!checkItems(items, m, true)) return;
    callAxiosGet({
      m,
      logger,
      url: `${Page}/AccessLog`,
      params: { id: items[0].Id },
      onSuccess: (data) => setAccessLogs(data),
    });
  };

  const webMenu: UrlGridArgs<WebMenu> = useMemo(
    () => ({
      ...webMenuDft,
      meta: {
        ...webMenuDft.meta,
        contextMenus: [
          {
            label: '접속 로그',
            callback: getAccessLog,
          },
        ],
      },
    }),
    [],
  );

  return (
    <>
      <label htmlFor="all">
        <input
          id="all"
          type="checkbox"
          checked={all}
          onChange={(e) => setAll(e.target.checked)}
        />
        invalid 포함
      </label>
      <button
        type="button"
        className={DftBtnStyleMx}
        onClick={() => setRefreshNeeded((p) => p + 1)}
      >
        조회
      </button>
      <hr className="narrow light" />
      <div className="d-flex children-me-4">
        <UrlGrid
          args={webMenu}
          params={{ all }}
          refreshNeeded={refreshNeeded}
        />
        <UrlGrid args={webMenuAuth} params={{}} refreshNeeded={refreshNeeded} />
      </div>
      <SimpleGrid
        data={accessLogs}
        columns={['t', 'userNm', 'request_decoded']}
        args={{
          meta: { maxHeight: 300 },
          widths: { t: 150, request_decoded: 300 },
        }}
      />
    </>
  );
}
