import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TableMeta } from '@tanstack/react-table';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import { UrlGrid, UrlGridArgs, emptyGridArgs } from '../../tmsutil';
import {
  AcctFee,
  acctFeeSchema,
  AcctGrpMst,
  acctGrpMstSchema,
  Account,
  accountSchema,
  ArsInfo,
  ArsInfoSchema,
  Broker,
  brokerSchema,
  BrokerFam,
  brokerFamSchema,
  BoVhStl,
  boVhStlSchema,
  DstrMst,
  dstrMstSchema,
  DstrAmt,
  dstrAmtSchema,
  Vehicle,
  vehicleSchema,
  VhGrpInfo,
  vhGrpInfoSchema,
  VhInfo,
  vhInfoSchema,
  Strg,
  strgSchema,
  UFutFeeInfo,
  uFutFeeInfoSchema,
  UstkCost,
  ustkCostSchema,
  UstkTax,
  ustkTaxSchema,
  VhGrpStrg,
  vhGrpStrgSchema,
  StrgVhInEx,
  strgVhInExSchema,
  VhStInfo,
} from '../../Tms/Tms';
import { BorrowReqPrior, borrowReqPriorSchema } from '../../Tms/Loan';
import DateSelector from '../../shared/DateSelector';
import FileUploadDialog from '../../shared/FileUploadDialog';
import { DftBtnStyleMx } from '../../AppTypes';
import ButtonWithHelp from '../../shared/ButtonWithHelp';

const currMenu = '/Back/Master';
const dftHeight = 900;

// #region Basic

// prettier-ignore
const brk: UrlGridArgs<Broker> = {
  url: `${currMenu}/Broker`,
  title: '증권사',
  columns: ['Id', 'nm', 'nm2', 'StpCompID', 'StpCompID2', 'KsdCd', 'invalid', 'KStkDma', 'cashEmsx', 'swapEmsx', 'engNm',],
  headers: ['Id', '증권사명', '증권사명2', 'STP코드', 'STP코드2', '예탁원코드', '미사용', '국내주식DMA', '캐시EMSX', '스왑EMSX', 'Eng'],
  meta: { dftColWidth: 100 },
  editable: true,
  height: dftHeight,
  schema: brokerSchema,
};

// prettier-ignore
const acct: UrlGridArgs<Account> = {
  url: `${currMenu}/Account`,
  title: '계좌',
  columns: ['Id', 'nm', 'nm2', 'dstrId', 'vhId', 'brkId', 'acctGrp', 'book',
    'astTy', 'executor', 'invalid', 'htsUser', 'accNo', 'KsdCd', 'short_', 'fix',
    'emsx', 'dma', 'tag', 'kstkFeeRto', 'ord', 'stId', 'brkLimit'],
  headers: ['Id', '계좌명', '계좌명2', '판매사', '상품', '증권사', '창구', '북',
    '자산구분', '채널', '미사용', 'hts', '계좌번호', '예탁원번호', '공매도', 'FIX',
    'EMSX', 'DMA', 'tag', '주식수수료(%)', '순번', '기본전략', '브로커리밋'],
  widths: { Id: 100, nm: 120, nm2: 120, accNo: 120, astTy: 60, tag: 60, KsdCd: 100, kstkFeeRto: 80, brkLimit: 500 },
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: accountSchema,
  infoMsg: `[북] Undefined, Cash
[북 - 국내스왑] SS_KSwap, KI_KSwap, MA_KSwap, SH_KSwap, DS_KSwap, KB_KSwap
[북 - 해외스왑] SS_USwap, KI_USwap, MA_USwap, KB_USwap
[북 - 국내선물] Kyo_KFut, KI_KFut , SS_KFut, KW_KFut, MA_KFut, NH_KFut
[북 - 해외선물] KI_UFut, KI_UFut_GM
[북 - 대용잔고전용 (계좌정보엔 입력안함) ] KSD, SS_PBS, SH_PBS, MA_PBS, KB_PBS

[창구구분] 일반법인, PBS, 장내파생, 해외장내파생, LP, LP장내파생, LP해외장내파생, 타임고유, 해외주식
[자산구분] Unknown, KStk, KFut, UStk, UFut
[채널구분] FIX (기관, KRITIME), FIX2 (리테일, KRITIMEFLO), EMSX (해외), OFF (오프주문), SIM (가상주문)`,
};

// prettier-ignore
const acctGrpMst: UrlGridArgs<AcctGrpMst> = {
  url: `${currMenu}/AcctGrpMst`,
  title: '창구',
  columns: ['tgt', 'brkId', 'astTy', 'acctGrp', 'KStkDma'],
  headers: ['타겟', '증권사코드', '자산구분', '창구', '국내주식DMA'],
  widths: { KStkDma: 100 },
  editable: true,
  meta: { dftColWidth: 100 },

  height: dftHeight,
  schema: acctGrpMstSchema,
};

// prettier-ignore
const acctFee: UrlGridArgs<AcctFee> = {
  url: `${currMenu}/AcctFee`,
  title: '선물 수수료',
  columns: ['acctId', 'astTy', 'fee', 'flat'],
  headers: ['계좌코드', '구분', '수수료(%,원)', '정액제'],
  editable: true,
  widths: { acctId: 120, astTy: 80, fee: 100, flat: 50 },
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: acctFeeSchema,
};

// prettier-ignore
const vehicle: UrlGridArgs<Vehicle> = {
  url: `${currMenu}/Vehicle`,
  title: '운용상품',
  columns: ['Id', 'nm', 'lev', 'initD', 'lastD', 'ord', 'ty', 'cls', 'grp', 'KsdCd', 'mafs', 'krCd', 'brkId', 'crncy', 'ctry', 'pfmFee', 'cuUnits', 'cmplExpD', 'cmplGrp', 'summaryOrd', 'bmId', 'etpId', 'termFile'],
  headers: ['Id', '상품명', 'Lev', '시작일', '종료일', '순번', '구분', '클래스', '펀드그룹', '예탁원', '미래', 'KR코드', '증권사', '통화', '국가', '성과보수', '1CU좌수', '준감실만기', '준감실구분', '섬머리순', 'BM', '상장코드', '약관'],
  editable: true,
  widths: {
    Id: 120,
    nm: 100,
    initD: 80,
    lastD: 80,
    cmplExpD: 80,
    cmplGrp: 80,
    bmId: 60,
    etpId: 60,
  },
  height: dftHeight,
  schema: vehicleSchema,
};

// prettier-ignore
const vhGrpInfos: UrlGridArgs<VhGrpInfo> = {
  url: `${currMenu}/VhGrpInfo`,
  title: '펀드그룹정보',
  columns: ['grp', 'reprVhId', 'trdGrp'],
  headers: ['그룹', '대표펀드', '독립거래단위'],
  editable: true,
  widths: { grp: 100, reprVhId: 100, trdGrp: 100 },
  meta: { dftColWidth: 100 },
  height: 500,
  schema: vhGrpInfoSchema,
  infoMsg: '[독립거래단위] None(없음), Stk(주식운용본부), AI(대체투자본부), PE(PE본부), ETF(ETF본부), Multi(멀티운용전략)'
};

// prettier-ignore
const vhInfos: UrlGridArgs<VhInfo> = {
  url: `${currMenu}/VhInfo`,
  title: '대체상품정보',
  columns: ['aiGrpId', 'aiGrpNm', 'Id', 'nm', 'strgDesc', 'assetDesc',
    'initD', 'cmplExpD', 'brokerDesc', 'bncDesc', 'bmDesc', 'pfmFee'],
  headers: ['ai그룹Id*', 'ai그룹명', 'Id', '상품명', '주요전략*', '투자기업*',
    '설정일', '준감실만기', '주요판매사*', '주요수익자*', '기준수익률*', '성과보수율'],
  editable: true,
  widths: {
    aiGrpId: 100, aiGrpNm: 100, Id: 80, nm: 200, strgDesc: 150, assetDesc: 100,
    initD: 80, cmplExpD: 100, brokerDesc: 200, bncDesc: 200, bmDesc: 100,
  },
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: vhInfoSchema,
  infoMsg: `[AI그룹ID]  코스닥벤처펀드(개방형):KVF_OPEN(0),코스닥벤처펀드(폐쇄형):KVF_CLOSED(1),폐쇄형 블라인드 펀드:AI(2),섹터 폐쇄형 블라인드 펀드:AI_Sector(3),
 선후순위 상장사 메자닌 펀드:MZF_Waterfall(4),상장사 메자닌 펀드:MZF(5),주식혼합형 상장사 메자닌 펀드:MZF_Alpha(6),프로젝트 펀드:PJT(7),하이일드 펀드:HY(8),
 성장금융 뉴딜펀드:GLF(9)`
};

// prettier-ignore
const ArsInfos: UrlGridArgs<ArsInfo> = {
  url: `${currMenu}/ArsInfo`,
  title: 'ARS정보',
  columns: ['Id', 'nm', 'lev', 'brkId',
    'ShCd', 'ShSub', 'ShNm', 'ShInitD', 'ShLastD', 'ShTy', 'netLmt', 'grsLmt',
    'mgtFee', 'pfmFee', 'huddle', 'feePrd', 'swapFee', 'prevId', 'rollOver', 'lossCut', 'insuRt', 'memo'],
  headers: ['Id', '상품명', 'Lev', '증권사',
    '신한코드', '신한Sub', '신한이름', '신한시작일', '신한종료일', '신한타입', '넷한도', '총한도',
    '운용보수', '성과보수', '허들', '보수기간', '스왑비용', '롤오버계좌', '롤오버', '한계가격', '보장율', '메모'],
  editable: false,
  widths: { Id: 80, nm: 100, ShInitD: 80, ShLastD: 80, ShNm: 80, memo: 200, },
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: ArsInfoSchema,
};

// prettier-ignore
const strg: UrlGridArgs<Strg> = {
  url: `${currMenu}/Strg`,
  title: '전략',
  // prettier-ignore
  columns: ['Id', 'nm', 'nm2', 'ordKey', 'lastD', 'costRto', 'cate', 'subCate', 'dftTgt',
    'subTrdGrp', 'bmId', 'bm0', 'nav0', 'exRt0', 'resetd', 'lsd', 'note', 'tatPfId'],
  // prettier-ignore
  headers: ['Id', '전략명', '전략명2', '주문키', 'lastd', '자금비용', '구분', 'sub구분', '기본타겟',
    'Sub거래단위', 'BM', 'BM초기값', 'BM리셋nav', '달성초과률(%)', '리셋일', '롱숏시작일', 'note', 'tatPfId'],
  editable: true,
  // prettier-ignore
  widths: {
    Id: 120, nm: 100, nm2: 100, costRto: 70, dftTgt: 100, vhIn: 200, vhEx: 200,
    bmId: 70, bm0: 80, nav0: 80, exRt0: 100, subTrdGrp: 100, lsd: 100, note: 150
  },
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: strgSchema,
};

// prettier-ignore
const vhGrpStrg: UrlGridArgs<VhGrpStrg> = {
  url: `${currMenu}/VhGrpStrg`,
  title: '상품그룹별 전략',
  columns: ['grp', 'stId', 'd0', 'd1', 'ord', 'info', 'costRto', 'teamId'],
  headers: ['타겟', '전략', 'd0', 'd1', '순번', '정보', '자금비용', '팀'],
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: vhGrpStrgSchema,
};

// prettier-ignore
const strgVhInEx: UrlGridArgs<StrgVhInEx> = {
  url: `${currMenu}/StrgVhInEx`,
  title: '전략별 상품',
  columns: ['d0', 'd1', 'stId', 'vhId', 'incl', 'grp'],
  headers: ['d0', 'd1', '전략', '펀드', 'In/Ex', 'grp'],
  editable: true,
  meta: {
    dftColWidth: 100,
    idType: 'uuid',
  },
  height: dftHeight,
  schema: strgVhInExSchema,

  infoMsg: `[In/Ex] : true = vhIn (include), false = vhEx (exclude)
*** 전략 테이블에 vh.In이나 vh.Ex 값이 있는 전략은 전략 테이블 설정값이 우선합니다. ***`,
};

// prettier-ignore
const vhStInfo: UrlGridArgs<VhStInfo> = {
  url: `${currMenu}/VhStInfo`,
  title: '전략/펀드 목록',
  columns: ['grp', 'stId', 'vhId', 'isFirstDay'],
  headers: ['타겟', '전략', '펀드', '시작일?'],
  editable: true,
  meta: {
    dftColWidth: 100,
    idType: 'uuid',
  },
  checkboxes: ['isFirstDay'],
  height: dftHeight,
  schema: strgVhInExSchema,
};

// #endregion

// #region Order
const loanReqPrior: UrlGridArgs<BorrowReqPrior> = {
  url: `${currMenu}/LoanReqPrior`,
  title: '대차 순서',
  columns: ['d0', 'd1', 'swap', 'brkId', 'ord', 'skip', 'note'],
  headers: ['적용초일', '적용말일', '스왑', '증권사', '순위', '제외', '메모'],
  editable: true,
  widths: {
    d0: 80,
    d1: 80,
    note: 400,
  },
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: borrowReqPriorSchema,
};

// prettier-ignore
const brokerFam: UrlGridArgs<BrokerFam> = {
  url: `${currMenu}/BrokerFam`,
  title: '증권사 계열사',
  columns: ['brkId', 'prodId', 'prodNm'],
  headers: ['브로커', '종목코드', '종목명'],
  editable: true,
  meta: { dftColWidth: 100 },
  height: 500,
  schema: brokerFamSchema,
};

// prettier-ignore
const ustkCostSwap: UrlGridArgs<UstkCost> = {
  url: `${currMenu}/UstkCost`,
  title: '해외스왑 수수료',
  columns: ['brkId', 'isSwap', 'ctryId', 'fee', 'effd'],
  headers: ['브로커', '스왑?', '국가', '수수료', '매매적용일'],
  editable: true,
  meta: { dftColWidth: 100 },
  height: 500,
  schema: ustkCostSchema,
};

// prettier-ignore
const ustkCostCash: UrlGridArgs<UstkCost> = {
  url: `${currMenu}/UstkCost`,
  title: '해외캐시 수수료',
  columns: ['brkId', 'isSwap', 'ctryId', 'fee', 'effd'],
  headers: ['브로커', '스왑?', '국가', '수수료', '매매적용일'],
  editable: true,
  meta: { dftColWidth: 100 },
  height: 500,
  schema: ustkCostSchema,
};

// prettier-ignore
const uFutFeeInfo: UrlGridArgs<UFutFeeInfo> = {
  url: `${currMenu}/UFutFeeInfo`,
  title: '해외선물 수수료',
  columns: ['futrootId', 'brkId', 'tgt', 'effd', 'fee', 'crncy', 'under', 'bbId', 'bbMkt'],
  headers: ['Root*', '증권사*', '타겟*', '적용일', '수수료(정액)*', '통화', '기초자산', '블룸 앞', '블룸 뒤'],
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: uFutFeeInfoSchema,
};

// prettier-ignore
const ustkTax: UrlGridArgs<UstkTax> = {
  url: `${currMenu}/ustkTax`,
  title: '해외 세금',
  columns: ['ctryId', 'effd', 'toSwap', 'bTaxStk', 'sTaxStk', 'bTaxEtf', 'sTaxEtf', 'exProds'],
  headers: ['국가', '적용일', '스왑적용', '매수세', '매도세', 'ETF매수세', 'ETF매도세', '예외종목'],
  editable: true,
  meta: { dftColWidth: 100 },
  widths: { exProds: 300 },
  height: dftHeight,
  schema: ustkTaxSchema,
};

// #endregion

// #region Etc

// prettier-ignore
const dstrMst: UrlGridArgs<DstrMst> = {
  url: `${currMenu}/DstrMst`,
  title: '판매사',
  columns: ['Id', 'nm', 'ord'],
  headers: ['판매사', '이름', '순서'],
  editable: true,
  widths: { Id: 120, nm: 120, ord: 50 },
  meta: { dftColWidth: 100 },
  height: 300,
  schema: dstrMstSchema,
};

// prettier-ignore
const dstrAmt: UrlGridArgs<DstrAmt> = {
  url: `${currMenu}/DstrAmt`,
  title: '판매사 약정',
  columns: ['dstrId', 'dstrNm', 'd', 'acctFeert', 'amt', 'feert'],
  headers: ['판매사', '이름', '시작일', '매매수수료', '투자금액', '판매수수료(연간)',],
  editable: true,
  widths: { dstrId: 70, dstrNm: 70, acctFeert: 100, amt: 100, feert: 100 },
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: dstrAmtSchema,
};

// prettier-ignore
const boVhStl: UrlGridArgs<BoVhStl> = {
  url: `${currMenu}/BoVhStl`,
  title: '펀드 결산/재투자',
  columns: ['vhId', 'ord', 'd0', 'd1', 'stlNav', 'skipDrop', 'divPrft', 'taxnav',],
  headers: ['상품', '기수', '기수초일', '기수말일', '결산기준가', '분배락skip', '이익분배금', '과표기준가',],
  editable: true,
  widths: {
    d0: 80, reinvD: 80, d1: 80, stlNav: 100, stlNav2: 100, taxna: 100, units: 100,
    skipDrop: 100, divPrft: 100, taxnav: 100,
  },
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: boVhStlSchema,
};

// #endregion

type T = { Id: number }; // 임의 IId 타입

export default function Master() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const { msgBox: m } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [all, setAll] = useState(false);
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);
  const [fileUploadVisible, setFileUploadVisible] = useState<boolean>(false);
  const [vhId, setVhId] = useState<string>('');
  useEffect(() => setGridArgs(emptyGridArgs), [d]);

  const vehicleMeta: TableMeta<Vehicle> = {
    maxHeight: 160,
    contextMenus: [
      {
        label: '약관 업로드',
        callback: (item: Vehicle[]) => {
          if (!item.length) {
            m.alert('항목 미선택');
          }
          setFileUploadVisible(true);
          setVhId(item[0].Id);
        },
      },
    ],
  };

  const query = (args: unknown) => {
    setRefreshNeeded((p) => p + 1);
    setGridArgs(args as UrlGridArgs<T>);
  };

  const getButton = (a: unknown) => {
    const { title } = a as UrlGridArgs<T>;
    return (
      <ButtonWithHelp
        key={title}
        helpid={title}
        className={DftBtnStyleMx}
        onClick={() => query(a)}
        label={title}
      />
    );
  };

  const getParams = (title: string) => {
    if (title === '해외스왑 수수료') return { d, all, isSwap: true };
    if (title === '해외캐시 수수료') return { d, all, isSwap: false };
    return { d, all };
  };

  return (
    <div style={{ minWidth: '1200px' }}>
      <DateSelector
        value={d}
        onChange={(date) => date !== d && setSearchParams({ d: date })}
      />
      &nbsp;&nbsp;
      <label htmlFor="all">
        <input
          id="all"
          type="checkbox"
          checked={all}
          onChange={(e) => setAll(e.target.checked)}
        />
        미사용/만료 포함
      </label>
      <hr className="narrow light" />
      <b>기본</b>&nbsp;&nbsp;
      {[
        brk,
        acct,
        acctGrpMst,
        acctFee,
        { ...vehicle, meta: vehicleMeta },
        vhGrpInfos,
        vhInfos,
        ArsInfos,
        strg,
        vhGrpStrg,
        strgVhInEx,
        vhStInfo,
      ].map((v) => getButton(v))}
      <hr className="narrow light" />
      <b>주문</b>&nbsp;&nbsp;
      {[
        loanReqPrior,
        brokerFam,
        ustkCostSwap,
        ustkCostCash,
        uFutFeeInfo,
        ustkTax,
      ].map((v) => getButton(v))}
      <hr className="narrow light" />
      <b>기타</b>&nbsp;&nbsp;
      {[dstrMst, dstrAmt, boVhStl].map((v) => getButton(v))}
      <hr className="narrow light" />
      <FileUploadDialog
        headerTitle={`${vhId} 약관 업로드`}
        fileExtensionLimit={['pdf']}
        fileSizeLimit={5}
        params={{ vh: vhId }}
        url="/Back/Master/UploadFundTerm"
        visible={fileUploadVisible}
        setVisible={setFileUploadVisible}
        setRefreshNeeded={setRefreshNeeded}
      />
      <UrlGrid
        args={gridArgs}
        params={getParams(gridArgs.title)}
        refreshNeeded={refreshNeeded}
      />
    </div>
  );
}
