import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { rgb2color } from 'tmslib/src/util/uiutil';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import DateSelector from '../../shared/DateSelector';
import VhclSelector from '../../shared/VhclSelector';
import ProdSelector from '../../shared/ProdSelector';
import {
  UrlGrid, emptyGridArgs, UrlGridArgs,
  callAxios,
  utilGet,
  downloadFile,
  ValidData,
  isSingleFund,
} from '../../tmsutil';
import {
  ordBanGrpSchema,
  ordBrkAmtTgtSchema,
  ordBrkDftSchema,
  ordMapSchema,
  VhGrpTy,
  ordRiskCfgSchema,
  DProdVhValue,
} from '../../Tms/Tms';
import {
  OrdVhCfg,
  ordVhCfgSchema,
  OrdBanGrp,
  OrdRiskCfg,
  OrdAcctSpec,
  ordAcctSpecSchema,
  OrdBrkAmtTgt,
  OrdBrkDft,
  OrdMap,
} from '../../Tms/Ord';
import { ProdRiskCfg, prodRiskCfgSchema } from '../../Tms/Prod';
import {
  CashShortLimit,
  cashShortLimitSchema,
  CashShortLimitQty,
  BorrowCh,
  borrowChSchema,
  BorrowPos,
  BorrowRdmptAvail,
  LoanPosCmprWithKsd,
  BorrowRdmptPrior,
  borrowRdmptPriorSchema,
  BorrowPool,
  BorrowFeeCnfrm,
  borrowFeeCnfrmSchema,
  BorrowFeeCheck,
  LendCh,
  lendChSchema,
  LendPos,
  CalcdLendPos,
} from '../../Tms/Loan';
import {
  MrgnCh,
  mrgnChSchema,
  MrgnPos,
  MrgnAvail,
  KsdCollateral,
  KsdMrgnPos,
  MrgnPosCmpRes,
} from '../../Tms/BO';
import { DftBtnStyleMx } from '../../AppTypes';
import FileUploadDialog from '../../shared/FileUploadDialog';
import '../../App.scss';
import ButtonWithHelp from '../../shared/ButtonWithHelp';
import { Company, getCompName } from '../../Tms/Identity';

const currMenu = '/Back/TrdCtrl';
const dftHeight = 500;

// 주문
const ordVhCfgHelp = `ShortAsSwap (숏스왑)
LongAsSwap (롱스왑)
CoverCashFirst (캐시부터 청산, 롱 / 숏 공통)
BuyCashSellSwap (매수는 캐시, 매도는 스왑)`;

// prettier-ignore
const ordVhCfg: UrlGridArgs<OrdVhCfg> = {
  url: `${currMenu}/OrdVhCfg`,
  title: '펀드별 주문 설정',
  columns: ['d0', 'd1', 'vhId', 'ty', 'inProds', 'exProds', 'invalid'],
  headers: ['시작일', '마지막일', '펀드코드', '구분', '지정종목(콤마구분)', '예외종목(콤마구분)', '적용취소',],
  editable: true,
  meta: { dftColWidth: 70, useGlobalFilter: false },
  widths: { ty: 100, inProds: 150, exProds: 150, invalid: 80 },
  height: 300,
  infoMsg: ordVhCfgHelp,
  schema: ordVhCfgSchema,
};

const ordBanGrpHelp = `[전략] AllStrg(전전략금지), Whole(펀드단 금지)  [종목] WALL(전종목금지) [방향] All, Buy, Sell, LE, LX, SE, SX [구분] 0:일반, 1:AI딜`;

// prettier-ignore
const ordBanGrp: UrlGridArgs<OrdBanGrp> = {
  url: `${currMenu}/OrdBanGrp`,
  title: '주문 금지 설정',
  columns: ['grp', 'vhId', 'stId', 'prodId', 'prodNm', 'side', 'd0', 'd1', 'ty', 'memo', 'operNm',],
  headers: ['타겟', '펀드', '전략', '종목코드', '종목명', '방향', '시작일', '종료일', '구분', '메모', '입력자',],
  editable: true,
  meta: { dftColWidth: 100 },
  widths: { prodId: 80, prodNm: 150, memo: 200, d0: 80, d1: 80, ty: 50 },
  height: dftHeight,
  infoMsg: ordBanGrpHelp,
  schema: ordBanGrpSchema,
};

const ordRiskCfgHelp = `주문 전송 금액 한도
기간주문약정 전송비율 : 1 이면 약정부터 채우고 디폴트 증권사로 나감. 0이면 약정 안채우고 디폴트 증권사로.`;

// prettier-ignore
const ordRiskCfg: UrlGridArgs<OrdRiskCfg> = {
  url: `${currMenu}/OrdRiskCfg`,
  title: '주문 금액 한도 설정',
  columns: ['maxAmtGen', 'maxAmt1m', 'maxAmt10m', 'opgaHitRto', 'maxCashRto'],
  headers: ['1회 한도', '1분 한도', '10분 한도', '기간주문약정 전송비율', '현금소진율',],
  editable: true,
  meta: { dftColWidth: 100 },
  widths: { opgaHitRto: 150 },
  height: 300,
  infoMsg: ordRiskCfgHelp,
  schema: ordRiskCfgSchema,
};

const prodRiskCfgHelp = `당일 종목별 주문 수량 한도 (상장주식수 대비 %)
미입력시 DMA 는 상장주식수 대비 1%, 브로커주문은 5%, 누적수량은 10%, 거래량대비는 100%로 체크`;

// prettier-ignore
const prodRiskCfg: UrlGridArgs<ProdRiskCfg> = {
  url: `${currMenu}/ProdRiskCfg`,
  title: '당일 종목별 한도 설정',
  columns: ['prodId', 'prodNm', 'maxDmaRto', 'maxBrkRto', 'maxCumRto', 'volCumRto',],
  headers: ['종목코드', '종목명', 'DMA', '브로커', '누적', '거래량대비%'],
  editable: true,
  meta: { dftColWidth: 100 },
  widths: {},
  height: dftHeight,
  infoMsg: prodRiskCfgHelp,
  schema: prodRiskCfgSchema,
};

const cashShortLimitHelp = `직접대차 숏 한도 비율 (상장주식수 대비 %)
미입력시 상장주식수 대비 0.4%`;

// prettier-ignore
const cashShortLimit: UrlGridArgs<CashShortLimit> = {
  url: `${currMenu}/CashShortLimit`,
  title: '직접대차 숏 한도비율 설정',
  columns: ['prodId', 'prodNm', 'vhIn', 'vhEx', 'rto'],
  headers: ['종목코드', '종목명', 'vh.In', 'vh.Ex', '한도'],
  editable: true,
  meta: { dftColWidth: 100 },
  widths: {},
  height: dftHeight,
  infoMsg: cashShortLimitHelp,
  schema: cashShortLimitSchema,
};

// LoanPos
// prettier-ignore
const cashShortLimitQty: UrlGridArgs<CashShortLimitQty> = {
  url: `${currMenu}/CashShortLimitQty`,
  title: '직접대차 숏 한도 조회',
  columns: ['prodId', 'prodNm', 'vhId', 'cash', 'swap', 'limitRto', 'vhRto', 'limitQty', 'limitAvail',],
  headers: ['종목코드', '종목명', '펀드', '직접대차', '스왑대차', '한도비율', '펀드비율', '직대한도', '한도잔여',],
  editable: false,
  meta: { dftColWidth: 70 },
  widths: {},
  height: 1000,
};
// 주문 end

// 약정, 기타
const ordAcctSpecHelp = `지정된 펀드/종목/방향/전략 에 대해 특정 계좌/브로커 방식으로 나감`;

// prettier-ignore
const ordAcctSpec: UrlGridArgs<OrdAcctSpec> = {
  url: `${currMenu}/OrdAcctSpec`,
  title: '펀드/종목별 계좌',
  columns: ['vhId', 'prodId', 'prodNm', 'ls', 'ex', 'stIn', 'acctId', 'byBrk'],
  headers: ['펀드', '종목', '종목명', 'ls', 'ex', 'st.In', '계좌', '브로커'],
  editable: true,
  meta: { dftColWidth: 70 },
  widths: { prodNm: 200 },
  height: dftHeight,
  infoMsg: ordAcctSpecHelp,
  schema: ordAcctSpecSchema,
};

//  readonly
// 'amtDone', 'rto', 'ystdDone', 'RemainedDays',
// 'TodayTgt', 'TodaySent', 'ToSendToday', 'TodayBrkGen'
// prettier-ignore
const ordBrkAmtTgt: UrlGridArgs<OrdBrkAmtTgt> = {
  url: `${currMenu}/OrdBrkAmtTgt`,
  title: '증권사 기간 약정',
  columns: ['d0', 'd1', 'tgt', 'brkId', 'astTy', 'acctGrp', 'vhIn', 'vhEx', 'amtTgt', 'amtDone', 'rto', 'invalid', 'ystdDone', 'RemainedDays', 'TodayTgt', 'TodaySent', 'ToSendToday', 'TodayBrkGen',],
  headers: ['시작일', '종료일', '타겟', '증권사', '자산구분', '창구', 'vh.In', 'vh.Ex', '목표약정액', '누적달성', '달성비중', '정지', '전일누적달성', '남은영업일', '당일목표', '당일전송', '당일예정', '당일브로커',],
  editable: true,
  meta: { dftColWidth: 90 },
  widths: { d0: 70, d1: 70, tgt: 70, brkId: 50, astTy: 60, acctGrp: 70 },
  height: dftHeight,
  schema: ordBrkAmtTgtSchema,
};

// prettier-ignore
const ordBrkDft: UrlGridArgs<OrdBrkDft> = {
  url: `${currMenu}/OrdBrkDft`,
  title: '증권사 기본 비율',
  columns: ['tgt', 'brkId', 'astTy', 'acctGrp', 'vhIn', 'vhEx', 'rto'],
  headers: ['타겟', '증권사', '자산구분', '창구', 'vh.In', 'vh.Ex', '비율(배수)',],
  editable: true,
  meta: { dftColWidth: 70 },
  widths: { vhIn: 500 },
  height: 850,
  schema: ordBrkDftSchema,
};

// prettier-ignore
const ordMap: UrlGridArgs<OrdMap> = {
  url: `${currMenu}/OrdMap`,
  title: '주문 매핑',
  columns: ['src', 'tgt', 'stId'],
  headers: ['소스', '타겟', '전략'],
  editable: true,
  meta: { dftColWidth: 70 },
  widths: {},
  height: dftHeight,
  schema: ordMapSchema,
};
// 약정, 기타 end

// 대차 t MM/dd HH:mm:ss
const borrowChHelp = `구분 => Regular: 일반, CorpAct = 기업이벤트, KeepOffset: 킵상계, KeepAdj = 킵조정
T 항목 입력 양식 ex) 2023-12-08 15:00:00`;

const borrowCh: UrlGridArgs<BorrowCh> = {
  url: `${currMenu}/BorrowCh`,
  title: '차입신규/상환',
  // prettier-ignore
  columns: ['d', 'vhId', 'book', 'prodId', 'prodNm', 'brkId', 'sgdQty', 'fee', 'mrgn', 'stId', 'trTy', 'src', 't', 'reqId', 'rerate', 'rerated',],
  // prettier-ignore
  headers: ['날짜', '펀드', '북', '종목코드', '종목명', '증권사', '수량', '요율(%)', '담보금액', '킵', '구분', '소스', 'T', '자동', '변경후요율', '요율변경일',],
  editable: true,
  meta: { dftColWidth: 70 },
  widths: { d: 60, trTy: 80, mrgn: 80, t: 110, rerated: 80 },
  height: dftHeight,
  infoMsg: borrowChHelp,
  schema: borrowChSchema,
};

// prettier-ignore
const borrowPos: UrlGridArgs<BorrowPos> = {
  url: `${currMenu}/BorrowPos`,
  title: '차입잔고',
  // prettier-ignore
  columns: ['d', 'vhId', 'book', 'prodId', 'prodNm', 'brkId', 'pos', 'avgfee', 'mrgn', 'stId', 'recall',],
  // prettier-ignore
  headers: ['날짜', '펀드', '북', '종목코드', '종목명', '증권사', '수량', '요율(%)', '담보금액', '킵', '리콜',],
  editable: false,
  meta: { dftColWidth: 70 },
  widths: { mrgn: 80 },
  height: dftHeight,
};

// prettier-ignore
const borrowRdmptAvail: UrlGridArgs<BorrowRdmptAvail> = {
  url: `${currMenu}/BorrowRdmptAvail`,
  title: '상환가능',
  // prettier-ignore
  columns: ['vhId', 'prodId', 'prodNm', 'pos', 'shortAvail', 'rdmptAvail', 'recallAvail',],
  // prettier-ignore
  headers: ['펀드', '종목코드', '종목명', '공용보유', '매도가능', '상환가능', '리콜가능',],
  editable: false,
  meta: { dftColWidth: 70 },
  widths: {},
  height: dftHeight,
};

// LoanPos
// prettier-ignore
const cmprBorrowPosWithKsd: UrlGridArgs<LoanPosCmprWithKsd> = {
  url: `${currMenu}/CmprBorrowPosWithKsd`,
  title: '대차 잔고 검증',
  columns: ['vhId', 'prodId', 'prodNm', 'brkId', 'tpos', 'kpos'],
  headers: ['펀드', '종목', '종목명', '증권사', 'TMS', 'KSD'],
  editable: false,
  meta: { dftColWidth: 70 },
  widths: { tpos: 80, kpos: 80 },
  height: dftHeight,
};

// select * from main.LoanCh where amt > 0
const borrowChInfo: UrlGridArgs<BorrowCh> = {
  url: `${currMenu}/LoanChInfo`,
  title: '대차거래정보보관 조회',
  // prettier-ignore
  columns: ['filld', 'vhKsd', 'book', 'prodId', 'prodNm', 'brkNm', 'sgdQty', 'fee', 't', 'd', 'expd', 'rerate', 'rerated',],
  // prettier-ignore
  headers: ['계약체결일', '펀드코드', '북', '종목코드', '종목명', '계약상대방', '수량', '요율(%)', '계약체결일시', '결제일', '만기일', '변경후요율', '요율변경일',],
  editable: false,
  meta: { dftColWidth: 70 },
  widths: { filld: 80, vhKsd: 90, brkNm: 80, t: 120, expd: 80, rerated: 80 },
  height: dftHeight,
};
// 대차 end

// select * from LoanRdmptPrior order by id desc;
// opts.readOnlyFlds = ['prodNm', 'rdmpt']
// prettier-ignore
const borrowRdmptPrior: UrlGridArgs<BorrowRdmptPrior> = {
  url: `${currMenu}/BorrowRdmptPrior`,
  title: '상환우선순위',
  // prettier-ignore
  columns: ['d0', 'd1', 'prodId', 'prodNm', 'brkId', 'recall', 'rdmpt', 'ord', 'memo',],
  // prettier-ignore
  headers: ['적용시작일', '적용해제일', '종목코드', '종목명', '증권사', '리콜수량', '상환수량', '순위', '메모',],
  editable: true,
  meta: { dftColWidth: 70 },
  widths: {},
  height: dftHeight,
  schema: borrowRdmptPriorSchema,
};
// 대차 상환 end

// 대차 Pool
// prettier-ignore
const borrowPool: UrlGridArgs<BorrowPool> = {
  url: `${currMenu}/BorrowPool`,
  title: '대차 Pool',
  // prettier-ignore
  columns: ['d', 'prodId', 'prodNm', 'brkId', 'qty', 'fee'],
  // prettier-ignore
  headers: ['날짜', '종목코드', '종목명', '증권사', '수량', '요율(%)'],
  editable: false,
  meta: { dftColWidth: 70 },
  widths: {},
  height: dftHeight,
};

// prettier-ignore
const borrowFeeCnfrm: UrlGridArgs<BorrowFeeCnfrm> = {
  url: `${currMenu}/BorrowFeeCnfrm`,
  title: '매니저 대차요율 컨펌',
  // prettier-ignore
  columns: ['prodId', 'prodNm', 'stId', 'acpt', 'fee', 't', 'operNm'],
  // prettier-ignore
  headers: ['종목', '종목명', '매니저', '진행여부', '요율', 'T', '컨펌자'],
  editable: true,
  meta: { dftColWidth: 70 },
  widths: { acpt: 80 },
  height: dftHeight,
  schema: borrowFeeCnfrmSchema,
};
// 대차 Pool end

// 대용
// prettier-ignore
const mrgnCh: UrlGridArgs<MrgnCh> = {
  url: `${currMenu}/MrgnCh`,
  title: '대용설정/해지',
  columns: ['d', 'vhId', 'book', 'prodId', 'prodNm', 'sgdQty', 'ty', 'mrgn'],
  headers: ['날짜', '펀드', '북', '종목코드', '종목명', '보유량', '구분', '인정담보금액',],
  editable: true,
  meta: { dftColWidth: 70 },
  widths: { sgdQty: 80, mrgn: 80 },
  height: dftHeight,
  schema: mrgnChSchema,
};

// prettier-ignore
const mrgnPos: UrlGridArgs<MrgnPos> = {
  url: `${currMenu}/MrgnPos`,
  title: '대용잔고',
  columns: ['d', 'vhId', 'book', 'prodId', 'prodNm', 'sgdQty', 'ty', 'mrgn'],
  headers: ['날짜', '펀드', '북', '종목코드', '종목명', '보유량', '구분', '인정담보금액',],
  editable: false,
  meta: { dftColWidth: 70 },
  widths: { sgdQty: 100, mrgn: 100 },
  height: dftHeight,
};

const mrgnAvailHelp = `대용 설정 가능 / 해지 필요 수량`;

// prettier-ignore
const mrgnAvail: UrlGridArgs<MrgnAvail> = {
  url: `${currMenu}/MrgnAvail`,
  title: '가능/해지 수량',
  columns: ['vhId', 'prodId', 'prodNm', 'h', 'buySum', 'pdfBuy', 'lo', 'mg', 'rdmpt', 'avail', 'cncl', 'ty', 'unavailTy', 'info',],
  headers: ['펀드', '종목코드', '종목명', 'T잔고', '미결매수', '현물납입', '대여', '대용', '환매예정', '가능수량', '해지수량', '구분', '담보불가', '비고',],
  editable: false,
  meta: { dftColWidth: 70 },
  widths: { info: 200 },
  height: dftHeight,
  infoMsg: mrgnAvailHelp,
};

const ksdCollateralHelp = `대용 설정 가능 / 해지 필요 수량`;

// prettier-ignore
const ksdCollateral: UrlGridArgs<KsdCollateral> = {
  url: `${currMenu}/KsdCollateral`,
  title: '예탁원 차입주식 담보',
  columns: ['vhId', 'rqrd', 'surplus', 'avail', 'cncl', 'rdmpt', 'rmndSurplus20', 'rmndSurplus13',],
  headers: ['펀드', '필요담보금액', '당일잉여담보', '설정가능액', '해지금액', '상환가능액', '잔여잉여담보예상(2x)', '잔여잉여담보예상(1.3x)',],
  editable: false,
  meta: { dftColWidth: 100 },
  widths: { rmndSurplus20: 150, rmndSurplus13: 150 },
  height: dftHeight,
  infoMsg: ksdCollateralHelp,
};

// prettier-ignore
const ksdMrgnPos: UrlGridArgs<KsdMrgnPos> = {
  url: `${currMenu}/KsdMrgnPos`,
  title: '예탁원대용잔고',
  columns: ['vhId', 'prodId', 'prodNm', 'pos0', 'upq', 'dnq', 'pos'],
  headers: ['펀드', '종목코드', '종목명', '전일잔량', '증가', '감소', '당일잔량',],
  editable: false,
  meta: { dftColWidth: 100 },
  widths: { pos0: 120, pos: 120 },
  height: dftHeight,
};

// MrgnPos
// prettier-ignore
const cmprMrgnPosWithKsd: UrlGridArgs<MrgnPosCmpRes> = {
  url: `${currMenu}/CmprMrgnPosWithKsd`,
  title: '대용잔고검증',
  columns: ['vhId', 'prodId', 'prodNm', 'tms', 'ksd'],
  headers: ['펀드', '종목코드', '종목명', 'TMS', 'KSD'],
  editable: false,
  meta: { dftColWidth: 100 },
  widths: {},
  height: dftHeight,
};
// 대용 end

// 대여
const lendChHelp = `상태 => Initiated: 대여요청 진행중(부킹전), Recalled: 리콜요청완료(부킹(결제)전), Booked: 부킹완료
구분 => Regular: 일반, CorpAct = 기업이벤트
T 항목 입력 양식 ex) 2023-12-08 15:00:00`;

// prettier-ignore
const lendCh: UrlGridArgs<LendCh> = {
  url: `${currMenu}/LendCh`,
  title: '대여신규/상환',
  // prettier-ignore
  columns: ['d', 'vhId', 'prodId', 'prodNm', 'brkId', 'state', 'sgdQty', 'fee', 'trTy', 'src', 't'],
  // prettier-ignore
  headers: ['날짜', '펀드', '종목코드', '종목명', '증권사', '상태', '수량', '요율(%)', '구분', '소스', 'T'],
  editable: true,
  meta: { dftColWidth: 80 },
  widths: {},
  height: dftHeight,
  schema: lendChSchema,
  infoMsg: lendChHelp,
};

const lendPosHelp = `상태 => Initiated: 대여요청 진행중(부킹전), Recalled: 리콜요청완료(부킹(결제)전), Booked: 부킹완료`

// prettier-ignore
const lendPos: UrlGridArgs<LendPos> = {
  url: `${currMenu}/LendPos`,
  title: '대여잔고',
  // prettier-ignore
  columns: ['d', 'vhId', 'prodId', 'prodNm', 'brkId', 'state', 'pos', 'avgfee'],
  // prettier-ignore
  headers: ['날짜', '펀드', '종목코드', '종목명', '증권사', '상태', '수량', '요율(%)'],
  editable: false,
  meta: { dftColWidth: 80 },
  widths: {},
  height: dftHeight,
  infoMsg: lendPosHelp,
};

const calcdLendPos: UrlGridArgs<DProdVhValue<CalcdLendPos>> = {
  url: `${currMenu}/CalcdLendPos`,
  title: '대여잔고(New)',
  // prettier-ignore
  columns: ['d', 'vhId', 'prodId', 'prodNm', 'value.YstdKsd', 'value.Event', 'value.NewReq', 'value.Recall', 'value.Pos'],
  // prettier-ignore
  headers: ['날짜', '펀드', '종목코드', '종목명', '전일KSD', '당일이벤트', '신규요청', '리콜', '수량'],
  editable: false,
  meta: { dftColWidth: 80 },
  widths: {},
  height: dftHeight,
  infoMsg: lendPosHelp,
};

// prettier-ignore
const lendAvail: UrlGridArgs<MrgnAvail> = {
  url: `${currMenu}/LoanOfferAvail`,
  title: '가능/리콜 수량',
  // prettier-ignore
  columns: ['vhId', 'prodId', 'prodNm', 'h', 'buySum', 'pdfBuy', 'lo', 'mg', 'rdmpt', 'offerAvail', 'recallNeeded', 'sellNotYet', 'recallNeeded2',],
  // prettier-ignore
  headers: ['펀드', '종목코드', '종목명', 'T잔고', '미결매수', '현물납입', '대여', '대용', '환매예정', '가능수량', '리콜필요수량', '매도예정량', '리콜필요수량2',],
  editable: false,
  meta: { dftColWidth: 80 },
  widths: {},
  height: dftHeight,
};

// prettier-ignore
const cmprLendPosWithKsd: UrlGridArgs<LoanPosCmprWithKsd> = {
  url: `${currMenu}/CmprLendPosWithKsd`,
  title: '대여잔고검증',
  columns: ['vhId', 'prodId', 'prodNm', 'brkId', 'tpos', 'kpos'],
  headers: ['펀드', '종목', '종목명', '증권사', 'TMS', 'KSD'],
  editable: false,
  meta: { dftColWidth: 80 },
  widths: {},
  height: dftHeight,
};
// 대여 end

// LoanPool
// prettier-ignore
const borrowFeeCheck: UrlGridArgs<BorrowFeeCheck> = {
  url: `${currMenu}/LoanFeeCheck`,
  title: '요율 체크(TTM)',
  // prettier-ignore
  columns: ['vhId', 'prodId', 'prodNm', 'brkId', 'avgfee', 'pool', 'diff'],
  // prettier-ignore
  headers: ['상품코드', '종목', '종목명', '증권사', '타임평요율(%)', '대차Pool(%)', '괴리',],
  dftFormatter: (v) => v?.toFixed(2),
  dftStyler: (v, c, r) => {
    if (c === 'diff' && r.original.diff > 2) {
      const norm = 255 * r.original.diff * 0.1;
      if (norm > 150 || norm < -150) return { color: 'white' };
      if (norm > 0) {
        return { backgroundColor: rgb2color(255, 255 - norm, 255 - norm) };
      }
      return { backgroundColor: rgb2color(255 + norm, 255 + norm, 255) };
    }
    return null;
  },
  widths: { avgfee: 100, pool: 100 },
  height: dftHeight,
  meta: {
    dftColWidth: 70,
    useGlobalFilter: false,
  },
};

type BorrowPoolCrossTab = {
  Id: number;
  prodId: string;
  prodNm?: string | null;
  min: number;
};

const borrowPoolCrossTab: UrlGridArgs<BorrowPoolCrossTab> = {
  url: `${currMenu}/LoanPoolCrossTab`,
  title: '요율 비교',
  columns: [],
  headers: [],
  dftStyler: (v, c, r) => {
    if (c.endsWith('pf')) {
      if (r.original.min == null) return { backgroundColor: '#aaa' }; // 회색
      if (Number(v) === Number(r.original.min))
        return { backgroundColor: '#faa' }; // 분홍색
      return { backgroundColor: '#ffa' }; // 노란색
    }
    return null;
  },
  height: dftHeight,
  meta: {
    dftColWidth: 50,
    useGlobalFilter: false,
  },
  dynamicColumns: (res) => {
    const { brks }: { brks: string[] } = res.data.extras;
    return {
      columns: [
        'prodId',
        'prodNm',
        ...brks.flatMap((v) => [
          `brks.${v}.pf`,
          `brks.${v}.pq`,
          `brks.${v}.lf`,
          `brks.${v}.lq`,
        ]),
      ],
      headers: [
        '종목',
        '종목명',
        ...brks.flatMap(() => ['풀요율', '가능량', '실요율', '대차량']),
      ],
      headerGroups: [
        ['종목정보', 2],
        ...brks.map((v) => [getCompName(v as Company), 4] as [string, number]),
      ],
    };
  },
};

const btnClass = DftBtnStyleMx;
const linkClass = 'btn btn-link';
type T = { Id: number }; // 임의 IId 타입

type PageFunc =
  | 'GenBorrowPos' // 대차잔고 생성
  | 'AdjLoanKeepOnClose' // 장후 킵 조정
  | 'UpdateLoanPosAvgFeeFromKsd' // 대차평요율 업뎃
  | 'GenLoanRdmptFromKsd' // 대차상환 생성
  | 'GenMrgnPos' // 대용잔고 생성
  | 'GenMrgnChFromKsd' // 설정/해지 생성
  | 'GenLendPos' // 대여잔고 생성
  | 'UpdateLoanOfferPosAvgFeeFromKsd' // 대여평요율 업뎃
  | 'SendLoanChEmail'
  | 'MrgnSetupFile'
  | 'MrgnCnclFile'
  | 'LoanRdmptFile'
  | 'LoanRecallFile'
  | 'LoanNewFile'
  | 'UploadKsdLoanNewFile'
  | 'UploadKsdLoanPosFile'
  | 'UploadKsdLoanRdmptFile'
  | 'UploadKsdMrgnPosFile';

const LOAN_BROKERS = ['KW', 'SS', 'KI', 'MA', 'SH', 'KB'];

export default function TrdCtrl() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const vh = searchParams.get('vh') || 'ALL_FUNDS';
  const [d0, setD0] = useState(d);
  const [prodId, setProdId] = useState<string | null>(null);
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);

  const [fileUploadVisible, setFileUploadVisible] = useState<boolean>(false);
  const [fileUploadTitle, setFileUploadTitle] = useState<string>('');
  const [fileUploadUrl, setFileUploadUrl] = useState<string>('');
  const [brkId, setBrkId] = useState<string>('SS');
  const [warnResMsg, setWarnResMsg] = useState<string | null>(null);

  const getParams = () => ({ d, d0, vhOrGrp: vh, prodId });

  const clearArgs = () => {
    setWarnResMsg(null);
    setGridArgs(emptyGridArgs);
  };

  useEffect(() => {
    clearArgs();
  }, [d]);

  const query = (args: unknown) => {
    const argst = args as UrlGridArgs<T>;
    clearArgs();
    setGridArgs(argst);
    setRefreshNeeded((p) => p + 1);
  };

  const getButton = (a: unknown) => {
    const { title } = a as UrlGridArgs<T>; // args title
    return (
      <ButtonWithHelp
        key={title}
        helpid={title}
        className={btnClass}
        onClick={() => query(a)}
        label={title}
      />
    );
  };

  // #region axios
  const handleAxiosResult = (
    funcNm: PageFunc,
    data: ValidData,
    res: AxiosResponse,
    cfmmsg: string | null,
    callbackGridArgs?: unknown,
  ) => {
    if (!res.data.warnings?.length) {
      setWarnResMsg(`${cfmmsg ?? ''} OK`);
    } else {
      setWarnResMsg([cfmmsg].concat(res.data.warnings).join('\n'));
    }
    if (funcNm === 'AdjLoanKeepOnClose' && data.length > 0) {
      m.alert(['OK : 비차입공매도 '].concat(data).join('\n'));
    }
    if (callbackGridArgs) {
      query(callbackGridArgs);
    }
  };

  const call = (
    funcNm: PageFunc,
    params: unknown,
    confirmMsg: string,
    callbackGridArgs?: unknown,
  ) => {
    callAxios({
      m,
      logger,
      url: `${currMenu}/${funcNm}`,
      params,
      confirmMsg,
      onSuccess: (data, res) =>
        handleAxiosResult(funcNm, data, res, confirmMsg, callbackGridArgs),
      onBegin: () => clearArgs(),
      title: confirmMsg,
    });
  };

  const callAxiosFunc = (
    funcNm: PageFunc,
    cfrmMsg: string,
    callbackGridArgs?: unknown,
  ) => {
    switch (funcNm) {
      case 'GenBorrowPos': // 대차잔고 생성
      case 'GenLendPos': // 대여잔고 생성
      case 'GenMrgnPos': // 대용잔고 생성
        call(funcNm, { d, vhOrGrp: vh }, cfrmMsg, callbackGridArgs);
        break;
      case 'AdjLoanKeepOnClose': // 장후 킵 조정
      case 'UpdateLoanPosAvgFeeFromKsd': // 대차평요율 업뎃
      case 'GenLoanRdmptFromKsd': // 대차상환 생성
      case 'GenMrgnChFromKsd': // 설정/해지 생성
      case 'UpdateLoanOfferPosAvgFeeFromKsd': // 대여평요율 업뎃
        call(funcNm, { d }, cfrmMsg, callbackGridArgs);
        break;
      default:
        m.alert('잘못된 함수명');
        break;
    }
  };
  // #endregion

  // 이메일전송
  const sendLoanChEmail = async (brk: string, rerate: boolean) => {
    const cfrmMsg: string = rerate
      ? '대차 요율 변경 이메일 전송'
      : '대차 수량 이메일 전송';
    call('SendLoanChEmail', { d, brk, rerate }, cfrmMsg, '');
  };

  const fileUpload = (url: PageFunc) => {
    let title = '';
    if (url === 'UploadKsdLoanNewFile') {
      title = '예탁원 대차 체결';
    } else if (url === 'UploadKsdLoanPosFile') {
      title = '예탁원 대차 잔고';
    } else if (url === 'UploadKsdLoanRdmptFile') {
      title = '예탁원 대차 상환';
    } else if (url === 'UploadKsdMrgnPosFile') {
      title = '예탁원 대용 잔고';
    } else {
      m.alert('파일업로드 메뉴 이상');
      return;
    }
    setFileUploadVisible(true);
    setFileUploadTitle(title);
    setFileUploadUrl(`${currMenu}/${url}`);
  };

  // 대차설정파일 (d: string, brk: string, m: MessageBox)
  // 대용설정파일 (d: string, vh: string, m: MessageBox)
  // 대용해지파일 (d: string, vh: string, m: MessageBox)
  // 리콜용상환파일 (d: string, m: MessageBox)
  const getFile = (
    funcNm: PageFunc,
    vh_?: string | null,
    brk_?: string | null,
  ) => {
    if (funcNm === 'MrgnSetupFile' || funcNm === 'MrgnCnclFile') {
      if (!isSingleFund(vh_ ?? '')) {
        m.alert('개별 펀드를 선택하세요');
        return;
      }
    }
    let params: object;
    if (funcNm === 'LoanRdmptFile') {
      // 아규먼트 다른 경우 500 error
      params = { d, vhOrGrp: vh_ ?? '' };
    } else if (funcNm === 'LoanRecallFile') {
      params = { d };
    } else if (funcNm === 'LoanNewFile') {
      params = { d, brk: brk_ ?? '' };
    } else {
      params = { d, vh: vh_ ?? '', brk: brk_ ?? '' };
    }

    downloadFile({
      m,
      logger,
      url: `${currMenu}/Download${funcNm}`,
      params,
    });
  };

  const getFileBatch = (funcNm: PageFunc, tgt: VhGrpTy) => {
    const params = { d, tgt };
    utilGet(m, logger, 'Funds', params, (data) => {
      const hfunds = data as string[];
      if (hfunds.length > 0) {
        hfunds.map((v) =>
          (async () => {
            const x = await getFile(funcNm, v, '');
            return x;
          })(),
        );
      }
    });
  };

  return (
    <div style={{ minWidth: '1500px' }} className="children-me-2">
      <DateSelector
        value={d}
        initDate0={d0}
        onChange={(date) => date !== d && setSearchParams({ d: date, vh })}
        onChangeD0={(date0) => setD0(date0)}
      />
      <VhclSelector
        d={d}
        meta
        all
        tfim
        onChange={(vhcl) =>
          vhcl && vhcl.Id !== vh && setSearchParams({ d, vh: vhcl.Id })
        }
        value={vh}
      />
      <ProdSelector onChange={(e) => setProdId(e?.Id ?? null)} />
      <hr className="narrow light" />
      <b>주문</b>&nbsp;&nbsp;
      {[
        ordVhCfg,
        ordBanGrp,
        ordRiskCfg,
        prodRiskCfg,
        cashShortLimit,
        cashShortLimitQty,
      ].map((v) => getButton(v))}
      <hr className="narrow light" />
      <b>약정</b>&nbsp;&nbsp;
      {[ordAcctSpec, ordBrkAmtTgt, ordBrkDft].map((v) => getButton(v))}
      |&nbsp;&nbsp;<b>기타</b>&nbsp;&nbsp;
      {getButton(ordMap)}
      <hr className="narrow light" />
      <b>차입</b>&nbsp;&nbsp;
      {[borrowCh, borrowPos].map((v) => getButton(v))}|
      <button
        type="button"
        className={linkClass}
        onClick={() =>
          callAxiosFunc('GenBorrowPos', '차입잔고 생성', borrowPos)
        }
      >
        (차입잔고 생성)
      </button>
      |&nbsp;&nbsp;
      {getButton(borrowRdmptAvail)} {/* 상환가능 */}
      |&nbsp;&nbsp;
      <select
        name="loanNewBrk"
        value={brkId}
        onChange={(e) => setBrkId(e.target.value)}
      >
        {LOAN_BROKERS.map((v) => (
          <option key={v} value={v}>
            {v}
          </option>
        ))}
      </select>
      <button
        key="대차설정파일"
        type="button"
        className={btnClass}
        onClick={() => getFile('LoanNewFile', '', brkId)}
      >
        대차설정파일
      </button>
      <button
        key="이메일전송"
        type="button"
        className={btnClass}
        onClick={() => sendLoanChEmail(brkId, false)}
      >
        이메일전송
      </button>
      <button
        key="이메일전송Rerate전송"
        type="button"
        className={btnClass}
        onClick={() => sendLoanChEmail(brkId, true)}
      >
        Rerate전송
      </button>
      |
      <button
        type="button"
        className="btn link-danger"
        onClick={() =>
          callAxiosFunc('AdjLoanKeepOnClose', '장후 킵 조정', borrowPos)
        }
      >
        (장후 킵조정(H,V,AI,T))
      </button>
      <br />
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      {getButton(cmprBorrowPosWithKsd)} {/* 대차잔고검증 */}|
      <button
        type="button"
        className={linkClass}
        onClick={() =>
          callAxiosFunc(
            'UpdateLoanPosAvgFeeFromKsd',
            '예탁원 자료로 대차평요율 업뎃',
            borrowCh,
          )
        }
      >
        (대차평요율 업뎃)
      </button>
      |&nbsp;&nbsp;
      {getButton(borrowChInfo)}
      <hr className="narrow light" />
      <b>대차 상환 </b>&nbsp;&nbsp;
      <button
        type="button"
        className={btnClass}
        onClick={() => getFile('LoanRdmptFile', vh, '')}
      >
        대차상환파일
      </button>
      |
      <button
        type="button"
        className={linkClass}
        onClick={() =>
          callAxiosFunc('GenLoanRdmptFromKsd', '대차상환 생성', borrowCh)
        }
      >
        (대차상환 생성)
      </button>
      |&nbsp;&nbsp;
      {getButton(borrowRdmptPrior)} {/* 상환우선순위  */}
      <button
        type="button"
        className={btnClass}
        onClick={() => getFile('LoanRecallFile', '', '')}
      >
        리콜용 상환파일
      </button>
      <hr className="narrow light" />
      <b>대차 Pool </b>&nbsp;&nbsp;
      {[borrowPool, borrowPoolCrossTab, borrowFeeCheck, borrowFeeCnfrm].map(
        (v) => getButton(v),
      )}
      <hr className="narrow light" />
      <b>대용 </b>&nbsp;&nbsp;
      {[mrgnCh, mrgnPos].map((v) => getButton(v))}|
      <button
        type="button"
        className={linkClass}
        onClick={() => callAxiosFunc('GenMrgnPos', '대용잔고 생성', mrgnPos)}
      >
        (대용잔고 생성)
      </button>
      |&nbsp;&nbsp;
      {[mrgnAvail, ksdCollateral].map((v) => getButton(v))}{' '}
      {/* 가능/해지 수량 예탁원 차입주식 담보 */}|
      <button
        type="button"
        className={btnClass}
        onClick={() => getFile('MrgnSetupFile', vh, '')}
      >
        대용설정파일
      </button>
      <button
        type="button"
        className={btnClass}
        onClick={() => getFileBatch('MrgnSetupFile', VhGrpTy.HFund)}
      >
        일괄(H)
      </button>
      |
      <button
        type="button"
        className={btnClass}
        onClick={() => getFile('MrgnCnclFile', vh, '')}
      >
        대용해지파일
      </button>
      <button
        type="button"
        className={btnClass}
        onClick={() => getFileBatch('MrgnCnclFile', VhGrpTy.HFund)}
      >
        일괄(H)
      </button>
      <br />
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </span>
      {[ksdMrgnPos].map((v) => getButton(v))} {/* 예탁원대용잔고 */}
      <button
        type="button"
        className={linkClass}
        onClick={() =>
          callAxiosFunc(
            'GenMrgnChFromKsd',
            '설정/해지 생성',
            cmprMrgnPosWithKsd,
          )
        }
      >
        (설정/해지 생성)
      </button>
      {getButton(cmprMrgnPosWithKsd)} {/* 대용잔고 검증 */}
      <hr className="narrow light" />
      <b>대여 </b>&nbsp;&nbsp;
      {[lendCh, lendPos, calcdLendPos].map((v) => getButton(v))}|
      <button
        type="button"
        className={linkClass}
        onClick={() => callAxiosFunc('GenLendPos', '대여잔고 생성', lendPos)}
      >
        (대여잔고 생성)
      </button>
      |{[lendAvail, cmprLendPosWithKsd].map((v) => getButton(v))}{' '}
      {/* 가능/리콜 수량 */}|
      <button
        type="button"
        className={linkClass}
        onClick={() =>
          callAxiosFunc(
            'UpdateLoanOfferPosAvgFeeFromKsd',
            '예탁원 자료로 대여평요율 업뎃',
            lendCh,
          )
        }
      >
        (대여평요율 업뎃)
      </button>
      <hr className="narrow light" />
      <b>예탁원 파일 업로드</b>&nbsp;&nbsp;
      <button
        type="button"
        className={btnClass}
        onClick={() => fileUpload('UploadKsdLoanNewFile')}
      >
        대차 신규
      </button>
      &nbsp;
      <button
        type="button"
        className={btnClass}
        onClick={() => fileUpload('UploadKsdLoanPosFile')}
      >
        대차 잔고
      </button>
      &nbsp;
      <button
        type="button"
        className={btnClass}
        onClick={() => fileUpload('UploadKsdLoanRdmptFile')}
      >
        대차 상환
      </button>
      &nbsp;|&nbsp;&nbsp;
      <button
        type="button"
        className={btnClass}
        onClick={() => fileUpload('UploadKsdMrgnPosFile')}
      >
        대용 잔고
      </button>
      |&nbsp;&nbsp;
      <hr className="narrow light" />
      {warnResMsg && (
        <div className="alert alert-slim alert-warning like-pre">
          {warnResMsg}
        </div>
      )}{' '}
      <UrlGrid
        args={gridArgs}
        params={getParams()}
        refreshNeeded={refreshNeeded}
      />
      <FileUploadDialog
        headerTitle={fileUploadTitle}
        fileExtensionLimit={['csv']}
        fileSizeLimit={5}
        params={getParams()}
        url={fileUploadUrl}
        visible={fileUploadVisible}
        setVisible={setFileUploadVisible}
        setRefreshNeeded={setRefreshNeeded}
      />
    </div>
  );
}
