import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuthState } from '../Auth/AuthContext';
import { DftBtnStyleMx } from '../../AppTypes';
import DateSelector from '../../shared/DateSelector';
import { UrlGrid, UrlGridArgs, emptyGridArgs } from '../../tmsutil';
import { VarRes } from '../../Tms/MO';

const page = '/Middle/VaR';
const btnClass = DftBtnStyleMx;
type T = { Id: number };

const varRes: UrlGridArgs<VarRes> = {
  url: `${page}/Data`,
  title: '',
  // prettier-ignore
  columns: ['vhId', 'na', 'drv', 'drvr', 'trs', 'trsr'],
  // prettier-ignore
  headers: ['펀드', 'NAV(억)', '파생상품 VaR', '파생상품 VaR(%)', 'TRS VaR', 'TRS VaR(%)'],
  widths: { drvr: 150 },
  dftFormatter: (v, c) => {
    if (c === 'vhId') {
      return v;
    }
    if (['na', 'drv', 'trs'].indexOf(c) >= 0) {
      return v?.toFixedWithComma(0);
    }
    return v?.toFixedWithComma(2);
  },
  meta: {
    dftColWidth: 100,
    useGlobalFilter: false,
  },
  height: undefined,
};

export default function VaR() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const d = searchParams.get('d') || info?.currBizDay || '';

  const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);
  const [refreshNeeded, setRefreshNeeded] = useState(0);

  const clearArgs = () => {
    setGridArgs(emptyGridArgs);
  };

  const query = (args: unknown) => {
    clearArgs();
    setRefreshNeeded((p) => p + 1);
    setGridArgs(args as UrlGridArgs<T>);
  };

  useEffect(() => {
    query(varRes);
  }, [d]);

  return (
    <div className="row" style={{ minWidth: '1500px' }}>
      <div className="col-4">
        <DateSelector
          value={d}
          onChange={(date) => {
            if (date !== d) {
              setSearchParams({ d: date });
            }
          }}
        />
        &nbsp;&nbsp;
        <button
          type="button"
          className={btnClass}
          onClick={() => query(varRes)}
        >
          Get Data
        </button>
      </div>
      <hr className="narrow light" />
      <div>
        <UrlGrid args={gridArgs} params={{ d }} refreshNeeded={refreshNeeded} />
      </div>
    </div>
  );
}
