import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import VhclSelector from '../../shared/VhclSelector';
import { UrlGrid, UrlGridArgs, emptyGridArgs } from '../../tmsutil';
import {
  FundTrnc,
  AccruedAlloc,
} from '../../Tms/BO';
import { DftBtnStyleMx } from '../../AppTypes';
import DateSelector from '../../shared/DateSelector';

const currMenu = '/Back/FundMgt';
const dftHeight = 800;

// prettier-ignore
const fundTrnc: UrlGridArgs<FundTrnc> = {
  url: `${currMenu}/FundTrnc`,
  title: '성과보수 내역',
  columns: ['ord', 'brkId', 'd', 'prodId', 'ty', 'units', 'nav', 'cumUnits', 'shwm', 'hwm', 'prft', 'perfFee', 'deferred',],
  headers: ['거래번호', '판매사', '일자', '종목코드', '구분', '거래좌수', '거래기준가', '누적좌수', '매도HWM', '잔여HWM', '수익금', '성과보수', '이연성과보수',],
  editable: false,
  meta: { dftColWidth: 100 },
  widths: { ord: 60, d: 70, brkId: 50, prodId: 70, ty: 50, nav: 60 },
  height: dftHeight,
  // schema: fundTrncSchema,
};

// prettier-ignore
const accruedAlloc: UrlGridArgs<AccruedAlloc> = {
  url: `${currMenu}/AccruedAlloc`,
  title: '미지급 성과 보수',
  columns: ['prodId', 'nav', 'SS', 'NH', 'MA', 'sum', 'drop'],
  headers: ['펀드', '기준가격', '삼성', 'NH', '미래', '합계', '위드타임 기준가',],
  editable: false,
  meta: { dftColWidth: 100 },
  widths: { prodId: 70, nav: 60 },
  height: dftHeight,
  // schema: accruedAllocSchema,
};

type T = { Id: number }; // 임의 IId 타입
export default function FundMgt() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const { msgBox: m } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const vh = searchParams.get('vh') || 'TWT';

  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);

  const chkVhcl = () => {
    if (vh !== 'TWT') {
      m.alert('공모재간접만 가능');
      return false;
    }
    return true;
  };

  const query = (args: unknown) => {
    if (chkVhcl()) {
      setRefreshNeeded((p) => p + 1);
      setGridArgs(args as UrlGridArgs<T>);
    }
  };
  const btnClass = DftBtnStyleMx;
  const getButton = (a: unknown) => {
    const { title } = a as UrlGridArgs<T>;
    return (
      <button
        key={title}
        type="button"
        className={btnClass}
        onClick={() => query(a)}
      >
        {title}
      </button>
    );
  };

  return (
    <div style={{ minWidth: '1500px' }} className="children-me-2">
      <DateSelector
        value={d}
        onChange={(date) => date !== d && setSearchParams({ d: date, vh })}
      />
      <VhclSelector
        d={d}
        onChange={(vhcl) =>
          vhcl && vhcl.Id !== vh && setSearchParams({ d, vh: vhcl.Id })
        }
        value={vh}
      />
      <hr className="narrow light" />
      <b>재간접</b>&nbsp;&nbsp;
      {[fundTrnc, accruedAlloc].map((v) => getButton(v))}
      <hr className="narrow light" />
      <UrlGrid
        args={gridArgs}
        params={{ d, vh }}
        refreshNeeded={refreshNeeded}
      />
    </div>
  );
}
