import './tables.css';

export type SimpleTableDataType = string | number | null | undefined;

export const simpleTable = <
  T extends { [key in keyof T]: SimpleTableDataType },
  K extends keyof T,
>(
  data: T[],
  columns: K[],
  args?: {
    keyFn?: (r: T) => K;
    headers?: string[];
    className?: string;
    autoNum?: boolean;
    formatter?: (
      v: SimpleTableDataType,
      field: K,
      obj: T,
    ) => SimpleTableDataType;
    styler?: (
      v: SimpleTableDataType,
      field: K,
      obj: T,
    ) => React.CSSProperties | undefined;
  },
) => {
  const { keyFn, headers, className, autoNum, formatter, styler } = args ?? {};
  return (
    <table className={className ?? 'simple-tab'}>
      <thead>
        <tr>
          {autoNum && <th>no</th>}
          {columns.map((c, j) => (
            <th key={c as string}>{headers?.[j] ?? (c as string)}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((r, i) => (
          <tr key={(keyFn?.(r) as string) ?? i}>
            {autoNum && <th>{i + 1}</th>}
            {columns.map((c) => {
              const v = r[c];
              return (
                <td key={c as string} style={styler?.(v, c, r)}>
                  {formatter?.(v, c, r) ?? v}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const arrayTable = (
  data: SimpleTableDataType[][],
  args?: {
    keyFn?: (r: SimpleTableDataType[]) => string;
    headers?: string[];
    grpHeaders?: [string, number][];
    rowHeaders?: string[];
    className?: string;
    autoNum?: boolean;
    formatter?: (
      v: SimpleTableDataType,
      i: number,
      j: number,
    ) => SimpleTableDataType;
    styler?: (
      v: SimpleTableDataType,
      i: number,
      j: number,
    ) => React.CSSProperties | undefined;
    tableStyle?: React.CSSProperties;
  },
) => {
  const {
    keyFn,
    headers,
    grpHeaders,
    rowHeaders,
    className,
    autoNum,
    formatter,
    styler,
    tableStyle,
  } = args ?? {};
  return (
    <table className={className ?? 'simple-tab'} style={tableStyle}>
      {headers && (
        <thead>
          {grpHeaders && (
            <tr>
              {grpHeaders.map((h) => (
                <th key={h[0]} colSpan={h[1]}>{h[0]}</th>
              ))}
            </tr>
          )}
          <tr>
            {autoNum && <th>no</th>}
            {headers.map((h) => (
              <th key={h}>{h}</th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {data.map((r, i) => (
          <tr key={keyFn?.(r) ?? i}>
            {autoNum && <th>{i + 1}</th>}
            {rowHeaders && <th>{rowHeaders[i]}</th>}
            {r.map((v, j) => (
              // eslint-disable-next-line react/no-array-index-key
              <td key={j} style={styler?.(v, i, j)}>
                {formatter?.(v, i, j) ?? v}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
