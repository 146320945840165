import React, { useEffect, useState } from 'react';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { callAxios, callAxiosGet } from '../../tmsutil';
import { DftBtnStyle } from '../../AppTypes';
import GoogleTrendsWidget from './GoogleTrendsWidget';

const geoPicker = [
  { id: 'GR', name: '그리스' },
  { id: 'NG', name: '나이지리아' },
  { id: 'ZA', name: '남아프리카' },
  { id: 'NL', name: '네덜란드' },
  { id: 'NO', name: '노르웨이' },
  { id: 'NZ', name: '뉴질랜드' },
  { id: 'TW', name: '대만' },
  { id: 'KR', name: '대한민국' },
  { id: 'DK', name: '덴마크' },
  { id: 'DE', name: '독일' },
  { id: 'RU', name: '러시아' },
  { id: 'RO', name: '루마니아' },
  { id: 'MY', name: '말레이시아' },
  { id: 'MX', name: '멕시코' },
  { id: 'US', name: '미국' },
  { id: 'VN', name: '베트남' },
  { id: 'BE', name: '벨기에' },
  { id: 'BR', name: '브라질' },
  { id: 'SA', name: '사우디아라비아' },
  { id: 'SE', name: '스웨덴' },
  { id: 'CH', name: '스위스' },
  { id: 'ES', name: '스페인' },
  { id: 'SG', name: '싱가포르' },
  { id: 'AR', name: '아르헨티나' },
  { id: 'IE', name: '아일랜드' },
  { id: 'GB', name: '영국' },
  { id: 'AU', name: '오스트레일리아' },
  { id: 'AT', name: '오스트리아' },
  { id: 'UA', name: '우크라이나' },
  { id: 'IL', name: '이스라엘' },
  { id: 'EG', name: '이집트' },
  { id: 'IT', name: '이탈리아' },
  { id: 'IN', name: '인도' },
  { id: 'ID', name: '인도네시아' },
  { id: 'JP', name: '일본' },
  { id: 'CZ', name: '체코' },
  { id: 'CL', name: '칠레' },
  { id: 'CA', name: '캐나다' },
  { id: 'KE', name: '케냐' },
  { id: 'CO', name: '콜롬비아' },
  { id: 'TH', name: '태국' },
  { id: 'TR', name: '튀르키예' },
  { id: 'PE', name: '페루' },
  { id: 'PT', name: '포르투갈' },
  { id: 'PL', name: '폴란드' },
  { id: 'FR', name: '프랑스' },
  { id: 'FI', name: '핀란드' },
  { id: 'PH', name: '필리핀' },
  { id: 'HU', name: '헝가리' },
  { id: 'HK', name: '홍콩' },
];

const timePicker = [
  { backend: 'CM', id: 'now 1-H', name: '지난 1시간' },
  { backend: 'CM', id: 'now 4-H', name: '지난 4시간' },
  { backend: 'CM', id: 'now 1-d', name: '지난 1일' },
  { backend: 'CM', id: 'now 7-d', name: '지난 7일' },
  { backend: 'IZG', id: 'today 1-m', name: '지난 30일' },
  { backend: 'IZG', id: 'today 3-m', name: '지난 90일' },
  { backend: 'IZG', id: 'today 12-m', name: '지난 12개월' },
  { backend: 'IZG', id: 'today 5-y', name: '지난 5년' },
  { backend: 'IZG', id: 'all_2008', name: '2008 - 현재' },
  { backend: 'IZG', id: 'all', name: '2004 - 현재' },
];

const searchTyPicker = [
  { id: '', name: '웹 검색' },
  { id: 'images', name: '이미지 검색' },
  { id: 'news', name: '뉴스 검색' },
  { id: 'froogle', name: 'Google 쇼핑' },
  { id: 'youtube', name: 'YouTube 검색' },
];

const geoDic = new Map(geoPicker.map((v) => [v.id, v.name]));
const timeDic = new Map(timePicker.map((v) => [v.id, v.name]));
const searchTyDic = new Map(searchTyPicker.map((v) => [v.id, v.name]));

type Trend = {
  Id: string;
  keyword: string;
  geo: string;
  time: string;
  searchTy?: string | null;
};

export default function GoogleTrend() {
  const { msgBox: m, logger } = useMessageState();
  const [keyword_, setKeyword_] = useState('');
  const [keyword, setKeyword] = useState('');
  const [geo_, setGeo_] = useState('KR');
  const [geo, setGeo] = useState('');
  const [time_, setTime_] = useState('now 1-d');
  const [time, setTime] = useState('');
  const [searchTy_, setSearchTy_] = useState('');
  const [searchTy, setSearchTy] = useState('');
  const [trend, setTrend] = useState<Trend[]>([]);

  // Load script
  const [scriptLoaded, setScriptLoaded] = useState(false);
  useEffect(() => {
    if (scriptLoaded) {
      return;
    }
    // Load the Google Trends embed script dynamically if not already present
    const script = document.createElement('script');
    script.src =
      'https://ssl.gstatic.com/trends_nrtr/3769_RC01/embed_loader.js';
    script.addEventListener('load', () => setScriptLoaded(true));
    document.body.appendChild(script);
  }, []);

  const call = (
    func: string,
    params: unknown,
    onSuccess: () => void,
    confirmMsg?: string,
  ) =>
    callAxios({
      m,
      logger,
      url: `Front/GoogleTrend/${func}`,
      params,
      confirmMsg,
      onSuccess,
    });

  const getData = () => {
    setKeyword(keyword_);
    setGeo(geo_);
    setTime(time_);
    setSearchTy(searchTy_);
  };

  // 기존 저장된 검색어
  const initialData = () => {
    callAxiosGet({
      m,
      logger,
      url: 'Front/GoogleTrend/Data',
      params: {},
      onError: () => {},
      onSuccess: (data) => setTrend(data),
    });
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <div className="target children-me-2">
      <div key="keyword">
        <label htmlFor="keyword">
          검색:&nbsp;&nbsp;
          <input
            type="text"
            name="keyword_"
            size={50}
            placeholder="2단어 이상 검색시 , 로 분리해서 입력"
            defaultValue={keyword_}
            onChange={(e) => setKeyword_(e.target.value)}
            onKeyDown={(e) => (e.key === 'Enter' ? getData() : null)}
          />
        </label>
        &nbsp;&nbsp;
        <button
          type="button"
          className={DftBtnStyle}
          onClick={() =>
            call(
              'Save',
              {
                keyword: keyword_,
                geo: geo_,
                time: time_,
                searchTy: searchTy_,
              },
              () => initialData(),
            )
          }
        >
          저장
        </button>
      </div>
      <hr className="narrow light" />
      <div>
        <select
          name="geo_"
          defaultValue={geo_}
          onChange={(e) => setGeo_(e.target.value)}
        >
          {geoPicker.map((v) => (
            <option key={v.id} value={v.id}>
              {v.name}
            </option>
          ))}
        </select>
        &nbsp;&nbsp;
        <select
          name="time_"
          defaultValue={time_}
          onChange={(e) => setTime_(e.target.value)}
        >
          {timePicker.map((v) => (
            <option key={v.id} value={v.id}>
              {v.name}
            </option>
          ))}
        </select>
        &nbsp;&nbsp;
        <select
          name="searchTy_"
          defaultValue={searchTy_}
          onChange={(e) => setSearchTy_(e.target.value)}
        >
          {searchTyPicker.map((v) => (
            <option key={v.id} value={v.id}>
              {v.name}
            </option>
          ))}
        </select>
        &nbsp;&nbsp;
        <button type="button" className={DftBtnStyle} onClick={() => getData()}>
          Get
        </button>
      </div>

      {/* 신규 검색어 */}
      <hr className="narrow light" />
      <div>
        <GoogleTrendsWidget
          keyword={keyword}
          geo={geo}
          time={time}
          searchTy={searchTy ?? ''}
          hl="ko"
          type="TIMESERIES"
        />
      </div>

      {/* 저장된 검색어  */}
      <hr className="narrow light" />
      {trend.map((v) => (
        <div key={`${v.Id}`}>
          <b>
            {`${v.keyword} | ${geoDic.get(v.geo)} | ${timeDic.get(v.time)} | ${searchTyDic.get(v.searchTy ?? '')}`}
          </b>
          &nbsp;&nbsp;
          <button
            type="button"
            className={DftBtnStyle}
            onClick={() =>
              call(
                'Delete',
                {
                  id: v.Id,
                },
                () => initialData(),
                '데이터 삭제',
              )
            }
          >
            삭제
          </button>
          <GoogleTrendsWidget
            keyword={v.keyword}
            geo={v.geo}
            time={v.time}
            searchTy={v.searchTy ?? ''}
            hl="ko"
            type="TIMESERIES"
          />
        </div>
      ))}

      {/* <div>
        <GoogleTrendsWidget
          keyword={keyword}
          geo={geo}
          time={time}
          searchTy={searchTy}
          hl="ko"
          type="GEO_MAP"
        />
      </div>
      <hr className="narrow light" />
      {keyword.split(',').map((v, i) => (
        <div key={`${v}`}>
          <div key={`RT_${v}`} className="d-flex justify-content-between">
            <div key={`RT_SUB_${v}`} className="col-6">
              <GoogleTrendsWidget
                keyword={keyword}
                geo={geo}
                time={time}
                searchTy={searchTy}
                hl="ko"
                type={
                  keyword.split(',').length === 1
                    ? `RELATED_TOPICS`
                    : `GEO_MAP_${i}`
                }
              />
            </div>
            <hr className="narrow light" />
            <div key={`RQ_SUB_${v}`} className="col-6">
              <GoogleTrendsWidget
                keyword={keyword}
                geo={geo}
                time={time}
                searchTy={searchTy}
                hl="ko"
                type={
                  keyword.split(',').length === 1
                    ? `RELATED_QUERIES`
                    : `RELATED_QUERIES_${i}`
                }
              />
            </div>
          </div>
        </div>
      ))} */}
    </div>
  );
}
