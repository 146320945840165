import React, { CSSProperties, useState } from 'react';

export default function NumericInput({
  value: initialValue,
  onChange: onFinalChange,
  width,
  name,
  placeholder,
  style,
  className,
  disabled,
  required,
  readOnly,
  step,
  min,
  max,
}: {
  value: number | null;
  onChange: (value: number | null) => void;
  width?: number | string;
  name?: string;
  placeholder?: string;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  step?: string | number;
  min?: string | number;
  max?: string | number;
}) {
  const [value, setValue] = useState(initialValue?.toString() ?? '');

  React.useEffect(() => {
    setValue(initialValue?.toString() ?? '');
  }, [initialValue]);

  return (
    <input
      type="number"
      name={name}
      placeholder={placeholder}
      style={{ ...(width ? { width: `${width}px` } : {}), ...style }}
      value={value}
      className={className}
      onChange={(e) => {
        setValue(e.target.value);
        //-0.xx 입력하려고 하면 -0을 0으로 바꿔서 음수 소수점 입력불가
        if (e.target.value == '-0') {
          return;
        }
        // form 안에서 엔터키로 submit 할때는 onBlur 이벤트가 안 일어나기 때문에,
        // 입력값이 숫자 맞으면 여기서 onFinalChange 호출해줌
        const numVal = e.target.value.toNumber();
        if (!Number.isNaN(numVal)) onFinalChange(numVal);
      }}
      onBlur={() => {
        const numVal = value.toNumber();
        if (numVal !== initialValue) onFinalChange(numVal);
      }}
      disabled={disabled}
      required={required}
      readOnly={readOnly}
      step={step}
      min={min}
      max={max}
    />
  );
}
