import React, { useState, useEffect } from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';
import DataGrid, { ColumnType, DataGridState } from 'tmslib/src/table/DataGrid';
import { ifesleExpr } from 'tmslib/src/util/utils';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import {
  updateOrdSts,
  UpdateOrdStField,
  getUpdateFieldFromOrdStProp,
} from '../../Front/Target/ordutil';
import { OrdSt, GetOrdStStateColor, OrdPrcTyValTxts } from '../../../Tms/Ord';
import { FuncCall } from '../../../tmsutil';

interface Props {
  tgtCall: FuncCall;
  osts: OrdSt[];
  currOstId: number | undefined;
  setCurrOstId: React.Dispatch<React.SetStateAction<number | undefined>>;
  OrdStDgState: DataGridState<OrdSt> | undefined;
  SetOrdStDgState: React.Dispatch<
    React.SetStateAction<DataGridState<OrdSt> | undefined>
  >;
}

const columnHelper = createColumnHelper<OrdSt>();
const columns = [
  columnHelper.accessor('ord', {
    header: 'ord',
    size: 50,
    meta: {
      frozen: true,
      title: (v, r) => String(r.original.Id),
      formatter: (v) => v.toFixedWithComma(0),
    },
  }),
  columnHelper.accessor('stId', {
    header: '전략',
    meta: { frozen: true },
  }),
  columnHelper.accessor('tgtStr', {
    header: '펀드',
    size: 60,
    meta: { frozen: true },
  }),
  columnHelper.accessor('prodId', {
    header: '종목',
    size: 70,
    meta: { frozen: true },
  }),
  columnHelper.accessor('prodNm', {
    header: '종목명',
    size: 150,
    meta: { frozen: true },
  }),
  columnHelper.accessor('ls', {
    header: 'LS',
    size: 30,
    meta: {
      frozen: true,
      styler: (v, r) => ({ color: r.original.sgn.getSignColor() }),
    },
  }),
  columnHelper.accessor('ex', {
    header: 'EX',
    size: 30,
    meta: {
      frozen: true,
      styler: (v, r) => ({ color: r.original.sgn.getSignColor() }),
    },
  }),
  columnHelper.accessor('wei', {
    header: '비중',
    size: 45,
    meta: {
      frozen: true,
      formatter: (v) => v.toFixedWithComma(3, 2),
    },
  }),
  columnHelper.accessor('exitAll', {
    header: '전량',
    size: 35,
    meta: { type: ColumnType.Checkbox, frozen: true },
  }),
  columnHelper.accessor('hm0', { header: '시작T*' }),
  columnHelper.accessor('hm1', { header: '종료T*' }),
  columnHelper.accessor('prcTy', {
    header: '유형*',
    size: 60,
    meta: { type: ColumnType.Combobox, valTxts: OrdPrcTyValTxts },
  }),
  columnHelper.accessor('limitPrc', {
    header: '제한가*',
    size: 60,
    meta: {
      formatter: (v) => (v ? v.toFixedWithComma(2, 0) : ''),
    },
  }),
  columnHelper.accessor('note', {
    header: '메모*',
    size: 100,
    meta: {
      tooltip: true,
      title: (v) => v ?? '',
    },
  }),
  columnHelper.accessor('stateDisp', {
    header: '상태',
    size: 60,
    meta: {
      frozen: true,
      styler: (v, r) => ({
        color: GetOrdStStateColor(r.original.state, r.original.pend),
      }),
    },
  }),
  columnHelper.accessor('isVI', {
    header: 'VI',
    size: 25,
    meta: { type: ColumnType.Checkbox, frozen: true },
  }),
  columnHelper.accessor('nErrs', {
    header: '#err',
    size: 30,
    meta: {
      frozen: true,
      formatter: (v) => (v === 0 ? '' : v?.toString()),
      styler: (v, r) => ({
        color: r.original.errResolved ? 'gray' : 'red',
        fontWeight: 'bold',
      }),
    },
  }),
  columnHelper.accessor('errMsgDisp', {
    header: 'err',
    size: 110,
    meta: {
      frozen: true,
      styler: () => ({ color: 'red', fontWeight: 'bold' }),
      title: (v) => v ?? '',
    },
  }),
  columnHelper.accessor('propNinfo', {
    header: '참고',
    size: 110,
    meta: {
      frozen: true,
      title: (v) => v ?? '',
    },
  }),
  columnHelper.accessor('oqty', {
    header: '주문량',
    meta: {
      frozen: true,
      formatter: (v) => (v ? v.toFixedWithComma(0) : ''),
    },
  }),
  columnHelper.accessor('nUnAcpt', {
    header: '미접',
    size: 30,
    meta: {
      frozen: true,
      formatter: (v) => (v === 0 ? '' : v?.toString()),
      styler: () => ({ color: 'violet', fontWeight: 'bold' }),
    },
  }),
  columnHelper.accessor('prgsRt', {
    header: '진행률',
    meta: {
      frozen: true,
      formatter: (v, r) => {
        if (v === 100) return v.toString();
        return `${Math.floor(v)}/${Math.floor(
          Math.max(v, r.original.mSentRt),
        )}`;
      },
    },
  }),
  columnHelper.accessor('maxVhPrgsDiff', {
    header: '편차',
    size: 30,
    meta: {
      frozen: true,
      formatter: (v) => {
        const diff = v ?? 0;
        return diff < 20 ? null : diff.toString();
      },
      styler: (v) => ({
        color: 'magenta',
        fontWeight: (v ?? 0) > 40 ? 'bold' : 'normal',
      }),
    },
  }),
  columnHelper.accessor('offsetWei', {
    header: '상계',
    size: 30,
    meta: {
      frozen: true,
      formatter: (v) => (v === 0 ? '' : v?.toFixed(0)),
    },
  }),
  columnHelper.accessor('fillPrc', {
    header: '체결가',
    size: 60,
    meta: {
      frozen: true,
      formatter: (v) => v?.toFixedSigFig(0),
    },
  }),
  columnHelper.accessor('currPrc', {
    header: '현재가',
    size: 60,
    meta: {
      frozen: true,
      formatter: (v) => v?.toFixedSigFig(0, true),
    },
  }),
].map((v) => v as ColumnDef<OrdSt, unknown>);

export default function OrdStTab({
  tgtCall,
  osts,
  currOstId,
  setCurrOstId,
  OrdStDgState,
  SetOrdStDgState,
}: Props) {
  const [data, setData] = useState<OrdSt[]>(osts);
  useEffect(() => setData(osts), [osts]);

  useEffect(() => {
    if (OrdStDgState?.currentObj?.Id !== currOstId) {
      setCurrOstId(OrdStDgState?.currentObj?.Id);
    }
  }, [currOstId, setCurrOstId, OrdStDgState]);

  const { msgBox: m } = useMessageState();

  const meta: TableMeta<OrdSt> = {
    height: 850,
    editable: true,
    rowClassifier: (r) =>
      [
        ifesleExpr(
          [r.original.verySlow, 'very-slow'],
          [r.original.slow, 'slow'],
        ) ?? '',
        r.original.oppWrkn ? ' oppwrkn' : '',
      ]
        .filter((v) => v !== '')
        .join(' '),

    updateField: (original, rowIdx, colId, value) => {
      const fld = getUpdateFieldFromOrdStProp(colId as keyof OrdSt);
      updateOrdSts([original], fld, value as string, false, tgtCall, false, m);

      const newData = data.map((v) =>
        v.Id === original.Id ? { ...v, [colId]: value } : v,
      );
      setData(newData);
    },
    useGlobalFilter: true,
    contextMenus: [
      {
        label: '에러해결',
        callback: (objs: OrdSt[]) =>
          updateOrdSts(
            objs,
            UpdateOrdStField.ResolveError,
            null,
            false,
            tgtCall,
            false,
            m,
          ),
      },
      {
        label: '정지',
        callback: (objs: OrdSt[]) =>
          updateOrdSts(
            objs,
            UpdateOrdStField.Cancel,
            null,
            false,
            tgtCall,
            false,
            m,
          ),
      },
      {
        label: '재개',
        callback: (objs: OrdSt[]) =>
          updateOrdSts(
            objs,
            UpdateOrdStField.Resume,
            null,
            false,
            tgtCall,
            false,
            m,
          ),
      },
    ],
    containerClass: 'ordproc-ordst',
  };

  return (
    <DataGrid
      data={data}
      columns={columns}
      meta={meta}
      onStateChange={SetOrdStDgState}
    />
  );
}
