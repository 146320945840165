import React, { useEffect, useState } from 'react';
import { toNumber } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import { callAxiosGet } from '../../tmsutil';
import DateSelector from '../../shared/DateSelector';
import { Ownership5, Ownership10 } from '../../Tms/MO';
import { DftBtnStyleMx } from '../../AppTypes';

const page = '/Middle/Ownership';
const btnClass = DftBtnStyleMx;
type PageFunc = 'Ownership5' | 'Ownership10';

export default function Ownership() {
  const { msgBox: m, logger } = useMessageState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [ownership5, setOwnership5] = useState<Ownership5[]>([]);
  const [ownership5tgt, setOwnership5tgt] = useState<number>(4.1);
  const [ownership10, setOwnership10] = useState<Ownership10[]>([]);
  const [ownership10tgt, setOwnership10tgt] = useState<number>(8.0);
  const [potential, setPotential] = useState<boolean>(false);

  const callGet = (funcNm: PageFunc, params: unknown) =>
    callAxiosGet({
      m,
      logger,
      url: `${page}/${funcNm}`,
      params,
      onSuccess: (data) => {
        if (data) {
          switch (funcNm) {
            case 'Ownership5':
              setOwnership5(data);
              break;
            case 'Ownership10':
              setOwnership10(data);
              break;
            default:
              m.alert('함수명 없음');
              break;
          }
        }
      },
    });

  const getData = () => {
    callGet('Ownership5', { d, tgt: ownership5tgt });
    callGet('Ownership10', { d, tgt: ownership10tgt, potential });
  };

  useEffect(() => {
    getData();
  }, [d, ownership5tgt, ownership10tgt, potential]);

  return (
    <div className="row" style={{ minWidth: '1500px' }}>
      <div className="col-4">
        <DateSelector
          value={d}
          onChange={(date) => {
            if (date !== d) {
              setSearchParams({ d: date });
            }
          }}
        />
        &nbsp;&nbsp;
        <button type="button" className={btnClass} onClick={() => getData()}>
          조회
        </button>
      </div>
      <hr className="narrow light" />
      <div>
        <b>
          [상장사 5% 지분공시]&nbsp;{' '}
          <input
            type="text"
            id="ownership5tgt"
            value={ownership5tgt}
            size={2}
            style={{ textAlign: 'right' }}
            onChange={(e) => setOwnership5tgt(toNumber(e.target.value))}
          />
          %
        </b>
        <SimpleGrid
          data={ownership5}
          // prettier-ignore
          headers={['종목코드', '종목명', '주식수량(ETF,조합제외)', 'ETF', '조합', '주식이벤트', '메자닌', '워런트', '차입롱',
                    '합계 (ETF제외)', '발행주식수', 'T비율', 'T-1비율', 'T-2비율', '직전공시일', '직전공시비율', '비고']}
          // prettier-ignore
          columns={['prodId', 'prodNm', 'stk', 'etf', 'asc', 'evt', 'mzn', 'wrnt', 'loan',
                    'holds', 'shares', 'holdRto', 't1Rto', 't2Rto', 'notiBd', 'notiWei', 'notiMemo']}
          args={{
            widths: { prodNm: 200, stk: 150, notiMemo: 150 },
            meta: { dftColWidth: 100 },
            dftFormatter: (v, c) => {
              // prettier-ignore
              if (['stk', 'etf', 'asc', 'evt', 'mzn', 'wrnt', 'loan', 'holds', 'shares'].indexOf(c) >= 0){
                return v?.toFixedWithComma(0);
              }
              if (['holdRto', 't1Rto', 't2Rto', 'notiWei'].indexOf(c) >= 0) {
                return v?.toFixedWithComma(2);
              }
              return v;
            },
            dftStyler: (v, c, r) => {
              if (
                ['holdRto', 't1Rto', 't2Rto'].indexOf(c) >= 0 &&
                (r.original.holdRto !== r.original.t1Rto ||
                  r.original.holdRto !== r.original.t2Rto ||
                  r.original.t1Rto !== r.original.t2Rto)
              ) {
                return { backgroundColor: 'orange' };
              }
              return null;
            },
          }}
        />
      </div>

      <br />
      <br />
      <hr className="narrow light" />
      <div>
        <b>[10% 의결권]&nbsp;</b>
        <input
          type="text"
          id="ownership10tgt"
          value={ownership10tgt}
          size={2}
          style={{ textAlign: 'right' }}
          onChange={(e) => setOwnership10tgt(toNumber(e.target.value))}
        />
        %&nbsp;&nbsp;
        <label htmlFor="potential">
          <input
            id="potential"
            type="checkbox"
            checked={potential}
            onChange={(e) => setPotential(e.target.checked)}
          />
          잠재주식 반영
        </label>
        <SimpleGrid
          data={ownership10}
          // prettier-ignore
          headers={['종목코드', '종목명', '헤지', '대체', 'PE', '고유', '헤지', '대체', 'PE', '고유',
                    '워런트', 'ETF', 'PE조합', '총수량', '총비율', '발행주식수', '의결권수량', '의결권비율', '의결권행사가능주식수', '상장여부']}
          // prettier-ignore
          columns={['prodId', 'prodNm',
                    'hgStk', 'aiStk', 'peStk', 'piStk','hgMzn', 'aiMzn', 'peMzn', 'piMzn',
                    'wrnt', 'etf', 'asc',
                    'tot', 'totRto', 'shares',
                    'vote', 'voteRto', 'voteAvail', 'isListed']}
          args={{
            meta: { dftColWidth: 100 },
            headerGroups: [
              ['', 1],
              ['', 1],
              ['주식', 4],
              ['메자닌', 4],
              ['', 1],
              ['', 1],
              ['', 1],
              ['', 1],
              ['', 1],
              ['', 1],
              ['', 1],
              ['', 1],
              ['', 1],
              ['', 1],
            ],
            dftFormatter: (v, c) => {
              if (['totRto', 'voteRto'].indexOf(c) >= 0) {
                return v?.toFixedWithComma(2);
              }
              if (c === 'isListed') {
                return v ? '상장' : '비상장';
              }
              if (['prodId', 'prodNm'].indexOf(c) < 0) {
                return v?.toFixedWithComma(0);
              }
              return v;
            },
            dftStyler: (v, c, r) => {
              if (c === 'voteRto' && r.original.voteRto >= 10) {
                return { backgroundColor: 'orange' };
              }
              if (
                c === 'voteAvail' &&
                r.original.voteAvail !== r.original.vote
              ) {
                return { backgroundColor: 'orange' };
              }
              return null;
            },
          }}
        />
      </div>
    </div>
  );
}
