import React, { useMemo } from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
  CellContext,
} from '@tanstack/react-table';
import DataGrid from 'tmslib/src/table/DataGrid';
import { ifesleExpr } from 'tmslib/src/util/utils';
import { MessageBox, useMessageState } from 'tmslib/src/context/MessageContext';
import Button from 'tmslib/src/ui/Button';
import { RiskLog } from '../../../Tms/MO';
import { useAuthState } from '../../Auth/AuthContext';
import { FuncCall, checkItems } from '../../../tmsutil';

const reqViolPermit = async (
  items: RiskLog[],
  tgtCall: FuncCall,
  m: MessageBox,
) => {
  if (!checkItems(items, m, true)) return;
  const item = items[0];
  if (item.ystdViolId == null) {
    m.alert('위반 정보 없음');
    return;
  }
  const note = await m.prompt('허용 요청 사유');
  if (note == null) return;
  const par = { d: item.d, id: item.ystdViolId, note };
  tgtCall('ReqViolPermit', par, { title: '주문 허용 요청' });
};

// 위반이 있어도 3일 미만이거나 이미 요청했거나 요청거절내역이 있으면 불가
const unablePermit = (c: CellContext<RiskLog, null | undefined>) =>
  c.row.original.violDays < 3 ||
  c.row.original.permitted ||
  c.row.original.permitRejected;

const columnHelper = createColumnHelper<RiskLog>();
const getColumns = (tgtCall: FuncCall, m: MessageBox) =>
  [
    columnHelper.accessor('title', { header: '항목', size: 100 }),
    columnHelper.accessor('subjNm', {
      header: '종목/섹터',
      size: 100,
      meta: {
        formatter: (v) => v?.slice(0, 20),
      },
    }),
    columnHelper.accessor('stat', {
      header: '현재값',
      size: 60,
      meta: {
        formatter: (v, r) =>
          v?.toFixed(r.original.itemStat === 'Turnover' ? 0 : 2),
      },
    }),
    columnHelper.accessor('expdStat', {
      header: '종가예상',
      size: 70,
      meta: {
        formatter: (v, r) =>
          v?.toFixed(r.original.itemStat === 'Turnover' ? 0 : 2),
      },
    }),
    columnHelper.accessor('stateMsg', {
      header: '상태',
      meta: { textAlign: 'center' },
    }),
    columnHelper.accessor('violDays', {
      header: '위반일수',
      size: 70,
      meta: {
        formatter: (v, r) =>
          (r.original.ystdViolDays ?? 0) > 0
            ? `${v} (${r.original.ystdViolDays})`
            : '',
      },
    }),
    columnHelper.accessor('grace', {
      header: '제재유예',
      size: 70,
      meta: { formatter: (v) => (v ? '유예 중' : '') },
    }),
    columnHelper.accessor('permitted', {
      header: '주문허용',
      size: 70,
      meta: {
        formatter: (v, r) =>
          ifesleExpr(
            [v, '승인'],
            [r.original.permitRejected, '거부'],
            [r.original.permitRqtd, '대기'],
            [(r.original.ystdViolDays ?? 0) >= 3, '매매제한'],
          ),
      },
    }),
    columnHelper.accessor('alertPars', {
      header: '경고라인',
      size: 70,
      meta: { textAlign: 'center' },
    }),
    columnHelper.accessor('violPars', {
      header: '위반라인',
      size: 70,
      meta: { textAlign: 'center' },
    }),
    columnHelper.accessor('reqPermit', {
      header: '허용 ',
      size: 37,
      meta: {
        frozen: true,
        styler: () => ({ textAlign: 'center' }),
      },

      cell: (c) => (
        <Button
          onClick={() => reqViolPermit([c.row.original], tgtCall, m)}
          btnRole='Icon'
          icon='Unlock'
          disabled={unablePermit(c)}
          noDisplayWhenDisabled
        />
      ),
    }),
  ].map((v) => v as ColumnDef<RiskLog, unknown>);

export default function RiskTab({
  tgtCall,
  risks,
}: {
  tgtCall: FuncCall;
  risks: RiskLog[];
}) {
  const { user } = useAuthState();
  const { msgBox: m } = useMessageState();

  const reqViolPermitInner = async (items: RiskLog[]) => {
    reqViolPermit(items, tgtCall, m);
  };

  const columns = useMemo(() => getColumns(tgtCall, m), [user?.isAdvisor]);

  const meta: TableMeta<RiskLog> = {
    rowClassifier: (r) => {
      const l = Math.max(r.original.level ?? 0, r.original.expdLevel ?? 0);
      if (l === 1) return 'alert alert-warning';
      if (l === 2) return 'alert alert-danger';
      return '';
    },
    contextMenus: [
      {
        label: '사흘 위반 허용 요청',
        callback: reqViolPermitInner,
      },
    ],
  };
  return <DataGrid data={risks} columns={columns} meta={meta} />;
}
