import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { gradationStyle, parseBool } from 'tmslib/src/util/utils';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { arrayTable } from 'tmslib/src/table/tables';
import { useAuthState } from '../Auth/AuthContext';
import { DftBtnStyle } from '../../AppTypes';
import { callAxiosGet } from '../../tmsutil';
import { LsWeiBySec } from '../../Tms/Pf';
import DVhStSelector from '../../shared/DVhStSelector';
import './pfanal.scss';

const dftChtMkSzWei: Highcharts.Options = {
  chart: {
    type: 'pie',
    width: 170,
    height: 170,
  },
  credits: { enabled: false },
  title: undefined,
  tooltip: {
    pointFormat: '{point.percentage:.1f}%',
  },
  plotOptions: {
    pie: {
      cursor: 'pointer',
      dataLabels: {
        format: '<b>{point.name}</b>: {point.percentage:.0f}',
        distance: -1,
        alignTo: 'plotEdges',
        style: { fontSize: '11px' },
      },
      size: '100%',
    },
  },
};

const dftChtTurnOver: Highcharts.Options = {
  chart: { type: 'line', width: 500, height: 300 },
  credits: { enabled: false },
  title: {
    text: undefined,
    margin: 0,
    style: { fontSize: '14px' },
  },
  xAxis: { type: 'datetime' },
  yAxis: { title: { text: null } },
  legend: { enabled: false },
  plotOptions: {
    line: {
      marker: { enabled: false },
      shadow: false,
      animation: false,
      lineWidth: 2,
    },
  },
  accessibility: { enabled: false },
};

const dftSecWeiCht: Highcharts.Options = {
  chart: { type: 'column', width: 1000, height: 250 },
  credits: { enabled: false },
  title: { text: 'Sector Weight', style: { fontSize: '14px' } },
  yAxis: { title: undefined },
  legend: { enabled: false },
};

const dftPerfCht: Highcharts.Options = {
  chart: { type: 'line', width: 300, height: 250 },
  credits: { enabled: false },
  title: {
    style: { fontSize: '12px' },
    margin: 0,
  },
  xAxis: { type: 'datetime' },
  yAxis: { title: { text: null } },
  legend: { itemStyle: { fontWeight: 'normal' }, margin: 0 },
  plotOptions: {
    line: {
      marker: { enabled: false },
      shadow: false,
      animation: false,
      lineWidth: 1,
    },
  },
};

export default function PfAnal() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const vh = searchParams.get('vh') || info?.dftVh || '';
  const st =
    searchParams.get('st') ||
    (vh === info?.dftVh ? info?.dftSt || '' : 'Whole');

  const grp: boolean = parseBool(searchParams.get('grp')) ?? true;
  const useTgt: boolean = parseBool(searchParams.get('useTgt')) ?? false;

  const [secWei, setSecWei] = useState<LsWeiBySec[]>([]);
  const [sum, setSum] = useState<number[][]>([]);
  const [stat, setStat] = useState<number[][]>([]);
  const [vksp, setVksp] = useState<number>();
  const [vksq, setVksq] = useState<number>();
  const [valuation, setValuation] = useState<number[][]>([]);
  const [secs, setSecs] = useState<string[]>([]);
  const [secVal, setSecVal] = useState<number[][]>([]);
  const [mkL, setMkL] = useState<Highcharts.Options>(dftChtMkSzWei);
  const [mkS, setMkS] = useState<Highcharts.Options>(dftChtMkSzWei);
  const [szL, setSzL] = useState<Highcharts.Options>(dftChtMkSzWei);
  const [szS, setSzS] = useState<Highcharts.Options>(dftChtMkSzWei);
  const [mkts, setMkts] = useState<string[]>([]);
  const [mkLsWei, setMkLsWei] = useState<number[][]>([]);
  const [domFrgnWei, setDomFrgnWei] = useState<number[][]>([]);
  const [turnOver, setTurnOver] = useState<Highcharts.Options>(dftChtTurnOver);
  const [secWeiCht, setSecWeiCht] = useState<Highcharts.Options>(dftSecWeiCht);
  const [perfChts, setPerfChts] = useState<Highcharts.Options[]>([]);
  const [lsPerfChts, setLsPerfChts] = useState<Highcharts.Options[]>([]);

  const useEng = user?.useEng;

  const getData = () => {
    if (!d || !vh || !st) return;
    const params = { d, vh, st, grp, useTgt };
    callAxiosGet({
      m,
      logger,
      url: '/Front/PfAnal/PfAnal',
      params,
      onSuccess: (data) => {
        setSecWei(data.secWei);
        setSum(data.sum);
        setStat(data.stat);
        setVksp(data.vksp);
        setVksq(data.vksq);
        setValuation(data.valuation);
        setSecs(data.secs);
        setSecVal(data.secVal);
        setMkL({
          ...dftChtMkSzWei,
          series: [
            { name: 'mk', data: data.mkL } as Highcharts.SeriesPieOptions,
          ],
        });
        setMkS({
          ...dftChtMkSzWei,
          series: [
            { name: 'mk', data: data.mkS } as Highcharts.SeriesPieOptions,
          ],
        });
        setSzL({
          ...dftChtMkSzWei,
          series: [
            { name: 'sz', data: data.szL } as Highcharts.SeriesPieOptions,
          ],
        });
        setSzS({
          ...dftChtMkSzWei,
          series: [
            { name: 'sz', data: data.szS } as Highcharts.SeriesPieOptions,
          ],
        });

        setMkts(data.mkts);
        setMkLsWei(data.mkLsWei);
        setDomFrgnWei(data.domFrgnWei);
        setTurnOver((p) => ({
          ...p,
          title: {
            ...p.title,
            text: useEng
              ? 'Annualized 20 days Avg. TurnOver(%)'
              : '20일 평균 연환산 회전율(%)',
          },
          series: [
            {
              data: data.turnOver,
              name: useEng ? 'TurnOver(%)' : '회전율(%)',
              type: 'line',
            },
          ],
        }));
        setSecWeiCht((p) => ({
          ...p,
          xAxis: { categories: data.secs },
          series: [
            {
              name: 'Long',
              data: data.secWeiL,
              color: 'red',
            } as Highcharts.SeriesColumnOptions,
            {
              name: 'Short',
              data: data.secWeiS,
              color: 'blue',
            } as Highcharts.SeriesColumnOptions,
          ],
        }));
        setPerfChts(
          [1, 3, 6].map((v, k) => {
            const series = data.perfChts[k];
            series[0].color = 'red';
            series[0].lineWidth = 2;
            series[1].color = 'gray';
            for (let j = 2; j < series.length; j += 1) {
              series[j].visible = false;
            }
            return {
              ...dftPerfCht,
              title: { ...dftPerfCht, text: `${v} Months` },
              series,
            };
          }),
        );
        setLsPerfChts(
          [1, 3, 6].map((v, k) => {
            const series = data.lsPerfChts[k];
            series[0].color = 'red';
            series[1].color = 'blue';
            series[2].color = 'gray';
            for (let j = 2; j < series.length; j += 1) {
              series[j].visible = false;
            }
            return {
              ...dftPerfCht,
              title: { ...dftPerfCht, text: `${v} Months` },
              series,
            };
          }),
        );
      },
    });
  };

  useEffect(getData, [d, vh, st, grp, useTgt]);

  const urlPar = {
    d,
    vh,
    st,
    grp: grp.toString(),
    useTgt: useTgt.toString(),
  };

  const isSinga = vh === 'SINGA';
  const unitStr = isSinga ? '(US$1K)' : '(억)';

  return (
    <>
      <div className="target children-me-2" style={{ minWidth: '1300px' }}>
        <DVhStSelector
          value={[d, vh, st]}
          onChange={(d_, vhId, stId) =>
            setSearchParams({
              ...urlPar,
              d: d_,
              vh: vhId || '',
              st: stId || '',
            })
          }
          vh={{ vir: true }}
          st={{ whole: true, vir: true }}
        />
        <label htmlFor="grp">
          <input
            type="checkbox"
            checked={grp}
            id="grp"
            onChange={(e) =>
              setSearchParams({ ...urlPar, grp: e.target.checked.toString() })
            }
          />
          그룹
        </label>
        <label htmlFor="useTgt">
          <input
            type="checkbox"
            checked={useTgt}
            id="useTgt"
            onChange={(e) =>
              setSearchParams({
                ...urlPar,
                useTgt: e.target.checked.toString(),
              })
            }
          />
          주문 타겟 반영
        </label>
        <button type="button" className={DftBtnStyle} onClick={() => getData()}>
          Get
        </button>
      </div>

      <hr className="narrow light" />
      <div className="d-flex justify-content-start">
        <div className="children-mb-4">
          <SimpleGrid
            data={secWei}
            // prettier-ignore
            columns={['sec','lnm','ltw','snm','stw','secltw','secstw','secntw']}
            // prettier-ignore
            headers={['Sec', 'Long', '%', 'Short', '%', 'L%', 'S%', 'Net']}
            args={{
              widths: { sec: 70, lnm: 180, snm: 180 },
              rowSpanFns: {
                sec: (r0, r1) => r0.original.sec === r1.original.sec,
                secltw: (r0, r1) => r0.original.sec === r1.original.sec,
                secstw: (r0, r1) => r0.original.sec === r1.original.sec,
                secntw: (r0, r1) => r0.original.sec === r1.original.sec,
              },
              meta: { dftColWidth: 35 },
              dftFormatter: (v) => v?.toFixed(1),
              dftStyler: (v, c) => {
                const textAlign = c === 'sec' ? 'center' : undefined;
                const fontWeight = c.isIn('sec', 'ltw', 'stw', 'secntw')
                  ? 'bold'
                  : undefined;
                let col: React.CSSProperties | undefined;
                if (c === 'ltw') col = gradationStyle((v as number) * 30);
                if (c === 'stw') col = gradationStyle(-(v as number) * 30);
                if (c === 'secntw') col = { color: v?.getSignColor() };
                return { textAlign, fontWeight, ...col };
              },
            }}
          />

          <HighchartsReact highcharts={Highcharts} options={turnOver} />
        </div>

        <div className="children-mb-4" style={{ marginLeft: '70px' }}>
          {arrayTable(sum, {
            className: 'simple-tab pfanal-tab',
            headers: ['', 'Long', 'Short', 'Exposure'],
            rowHeaders: [
              `Size${unitStr}`,
              `P&L${unitStr}`,
              'Perf Vs BM',
              'Wei(%)',
            ],
            styler: (v, i, j) => {
              const color = i === 1 || i === 2 ? v?.getSignColor() : undefined;
              const backgroundColor = j === 1 ? '#eff' : undefined;
              return { color, backgroundColor };
            },
            formatter: (v, i) => v?.toFixedWithComma(i === 2 ? 2 : 1),
          })}

          <div>
            {arrayTable(stat, {
              className: 'simple-tab pfanal-tab',
              headers: ['', 'Long', 'Short', 'Net'],
              rowHeaders: [
                'Pf Std.Dev',
                useEng ? 'SD(xcpt Cash/MK)' : 'SD(현금/시장제외)',
                useEng ? 'Rt Std.Dev' : '수익률 Std.Dev',
                'Beta',
              ],
              formatter: (v) => v?.toFixedWithComma(1),
              styler: (v, i, j) =>
                j === 1 || j === 3 ? { backgroundColor: '#eff' } : undefined,
            })}
            {`Market Std.Dev: KOSPI ${vksp}, KOSDAQ ${vksq}`}
          </div>

          {arrayTable(valuation, {
            className: 'simple-tab pfanal-tab',
            headers: ['', 'Long', 'Short'],
            rowHeaders: ['PER(tr)', 'PBR', 'ROE(tr,%)', 'DVR(tr,%)'],
            formatter: (v) => v?.toFixedWithComma(1),
            styler: (v, i, j) =>
              j === 1 ? { backgroundColor: '#eff' } : undefined,
          })}

          {arrayTable(secVal, {
            className: 'simple-tab pfanal-tab',
            grpHeaders: [
              ['', 1],
              ['PER(tr)', 2],
              ['PBR', 2],
            ],
            headers: ['', 'Long', 'Short', 'Long', 'Short'],
            rowHeaders: secs,
            formatter: (v) => v?.toFixedWithComma(1),
            styler: (v, i, j) =>
              j === 1 || j === 3 ? { backgroundColor: '#eff' } : undefined,
          })}

          <table className="simple-tab pfanal-tab">
            <tr>
              <th> </th>
              <th>Long</th>
              <th>Short</th>
            </tr>
            <tr>
              <th>Market</th>
              <td>
                <HighchartsReact highcharts={Highcharts} options={mkL} />
              </td>
              <td>
                <HighchartsReact highcharts={Highcharts} options={mkS} />
              </td>
            </tr>
            <tr>
              <th>Size</th>
              <td>
                <HighchartsReact highcharts={Highcharts} options={szL} />
              </td>
              <td>
                <HighchartsReact highcharts={Highcharts} options={szS} />
              </td>
            </tr>
          </table>

          {arrayTable(mkLsWei, {
            className: 'simple-tab pfanal-narrow-tab',
            headers: ['', ...mkts],
            rowHeaders: ['L', 'S'],
            formatter: (v) => v?.toFixedWithComma(1),
          })}

          {arrayTable(domFrgnWei, {
            className: 'simple-tab pfanal-tab',
            headers: ['', 'Domestic', 'Foreign', 'Sum'],
            rowHeaders: ['Long', 'Short', 'Gross', 'Net'],
            formatter: (v) => v?.toFixedWithComma(1),
          })}
        </div>
      </div>
      <hr className="narrow light" />
      <HighchartsReact highcharts={Highcharts} options={secWeiCht} />
      <div
        className="d-flex justify-content-between"
        style={{ width: '1000px' }}
      >
        <HighchartsReact highcharts={Highcharts} options={perfChts[0]} />
        <HighchartsReact highcharts={Highcharts} options={perfChts[1]} />
        <HighchartsReact highcharts={Highcharts} options={perfChts[2]} />
      </div>
      <div
        className="d-flex justify-content-between"
        style={{ width: '1000px' }}
      >
        <HighchartsReact highcharts={Highcharts} options={lsPerfChts[0]} />
        <HighchartsReact highcharts={Highcharts} options={lsPerfChts[1]} />
        <HighchartsReact highcharts={Highcharts} options={lsPerfChts[2]} />
      </div>
    </>
  );
}
