import React from 'react';
import { SimpleTableDataType, arrayTable, simpleTable } from 'tmslib/src/table/tables';
import { round } from 'tmslib/src/util/utils';
import { Nav, EtfInfo, BmRt, PfSum } from '../../../Tms/Pf';
import { Curncy, LS } from '../../../Tms/Common';
import { SimpleVhcl } from '../../../shared/VhclSelector';
import { useAuthState } from '../../Auth/AuthContext';
import { GetDefaultUnit, GetDefaultUnitDesc } from '../../../tmsutil';

interface Props {
  vhcl: SimpleVhcl | null;
  isEtf: boolean;
  nav: Nav | null;
  bmRt: BmRt | null;
  pfSum: PfSum[];
  etf: EtfInfo | null;
}

export default function Performance({
  vhcl,
  isEtf,
  nav,
  bmRt,
  pfSum,
  etf,
}: Props) {
  const dunit = GetDefaultUnit(vhcl);
  const dunitDesc = GetDefaultUnitDesc(vhcl);
  const isUVhcl = vhcl?.crncy !== Curncy.KRW;
  const { user } = useAuthState();

  const getNavData = () => {
    if (isEtf) {
      return [
        ['PDF iNAV', nav?.pdfNav],
        ['ETF 현재가', nav?.etfPrc],
        ['USDKRW', nav?.fx],
      ];
    }

    if (isUVhcl != null && isUVhcl) {
      return [[user?.useEng ? 'Today' : '당일', nav?.navch_dom]];
    }

    return [
      ['국내당일', nav?.navch_dom],
      ['해외당일', nav?.navch_realf],
      ['해외전일', nav?.navch_bookf],
    ];
  };

  const bmData = [
    [bmRt?.bm1Nm, bmRt?.bm1Rt],
    [bmRt?.bm2Nm, bmRt?.bm2Rt],
    [bmRt?.bm3Nm, bmRt?.bm3Rt],
  ];
  let bmData2: (string | number | null | undefined)[][] = [];

  if (vhcl?.Id === 'SINGA') {
    bmData.push([bmRt?.bm4Nm, bmRt?.bm4Rt]);

    bmData2 = [
      [bmRt?.bm5Nm, bmRt?.bm5Rt],
      [bmRt?.bm6Nm, bmRt?.bm6Rt],
      [bmRt?.bm7Nm, bmRt?.bm7Rt],
      [bmRt?.bm8Nm, bmRt?.bm8Rt],
    ];
  }

  const className = 'pf-ret';
  const navFormatter = (v: SimpleTableDataType) => v?.toFixedWithComma(2);
  const bmFormatter = (v: SimpleTableDataType) =>
    typeof v === 'number' ? `${v.toFixedWithComma(2)}%` : v;
  const signColorStyler = (v: SimpleTableDataType) => ({
    color: v?.getSignColor(),
  });

  const pfSumFormatter = (
    v: SimpleTableDataType,
    fld: keyof PfSum,
    r: PfSum,
  ) => {
    if (fld.startsWith('amt') || fld.startsWith('prft')) {
      const digits = fld.startsWith('prft') ? 2 : 0;
      return v == null ? v : ((v as number) * dunit).toFixedWithComma(digits);
    }
    if ((fld as string) === 'ex') {
      return bmRt?.bm1Rt == null
        ? null
        : (r.rt - (r.ls === LS.S ? -1 : 1) * bmRt.bm1Rt).toFixedWithComma(2);
    }
    if (fld.startsWith('wei')) return v?.toFixedWithComma(1);
    if (fld.endsWith('beta1y')) return v?.toFixedWithComma(2);
    if (fld.endsWith('std1y')) return v?.toFixedWithComma(1);
    return v;
  };
  const pfSumStyler = (v: SimpleTableDataType, fld: keyof PfSum, r: PfSum) => {
    if (fld.startsWith('prft')) {
      return v == null
        ? undefined
        : { color: ((v as number) * 1e-8).getSignColor() };
    }
    if ((fld as string) === 'ex') {
      return bmRt?.bm1Rt == null
        ? undefined
        : { color: (r.rt - bmRt.bm1Rt).getSignColor() };
    }
    return undefined;
  };

  const etfCu = isEtf
    ? [
        ['T', etf?.cu0, etf?.s0, etf?.r0],
        ['T+1', etf?.cu1, etf?.s1, etf?.r1],
        ['T+2', etf?.cu2, etf?.s2, etf?.r2],
      ]
    : [[]];
  const etfSum =
    isEtf && etf
      ? [
          [
            'Pos(백만원)',
            round(etf.na * 1e-6),
            round(etf.etfAmt * 1e-6),
            round(etf.stkAmt * 1e-6),
            round(etf.sfutAmt * 1e-6),
            round(etf.ifutAmt * 1e-6),
            round(etf.liqAmt * 1e-6),
            round(etf.accrDivAmt * 1e-6),
          ],
          [
            '편입비(%)',
            '',
            ((etf.etfAmt / etf.na) * 100).toFixed(1),
            ((etf.stkAmt / etf.na) * 100).toFixed(1),
            ((etf.sfutAmt / etf.na) * 100).toFixed(1),
            ((etf.ifutAmt / etf.na) * 100).toFixed(1),
            ((etf.liqAmt / etf.na) * 100).toFixed(1),
            ((etf.accrDivAmt / etf.na) * 100).toFixed(1),
          ],
        ]
      : [[]];
  return (
    <div
      className="d-flex align-items-end children-me-3"
      style={{ minWidth: '1000px' }}
    >
      <fieldset className="grpbox">
        <legend>NAV</legend>
        <span style={{ fontSize: 'x-large', padding: '3px 10px 3px 5px' }}>
          {nav?.nav.toFixedWithComma(2)}
        </span>
        <span style={{ color: Number(nav?.navch).getSignColor() }}>
          {nav?.navch.toFixedWithComma(2)} ({nav?.chpct}%)
        </span>
      </fieldset>
      {arrayTable(getNavData(), {
        className,
        formatter: navFormatter,
        styler: isEtf ? undefined : signColorStyler,
      })}
      {arrayTable(bmData, {
        className,
        formatter: bmFormatter,
        styler: signColorStyler,
      })}
      {!!bmData2.length &&
        arrayTable(bmData2, {
          className,
          formatter: bmFormatter,
          styler: signColorStyler,
        })}
      {isEtf &&
        arrayTable(etfCu, {
          className,
          headers: ['', 'CU수', '설정', '환매'],
        })}
      {!isEtf &&
        simpleTable(
          pfSum,
          isUVhcl
            ? [
                'title',
                'amt',
                'prft',
                'ex' as keyof PfSum,
                'wei',
                'pfbeta1y',
                'pfstd1y',
                'rtstd1y',
              ]
            : [
                'title',
                'amt',
                'amtf',
                'prft',
                'prftf',
                'ex' as keyof PfSum,
                'wei',
                'weif',
                'pfbeta1y',
                'pfstd1y',
                'rtstd1y',
              ],
          {
            className,
            headers: isUVhcl
              ? [
                  '',
                  'Size'.concat(dunitDesc),
                  'P&L'.concat(dunitDesc),
                  'Ex(%)',
                  'Wei(%)',
                  'Beta',
                  'PfStd1y',
                  'RtStd1y',
                ]
              : [
                  '',
                  'Size'.concat(dunitDesc),
                  'Sz해외',
                  'P&L'.concat(dunitDesc),
                  'PL해외',
                  'Ex(%)',
                  'Wei(%)',
                  'W해외',
                  'Beta',
                  'PfStd1y',
                  'RtStd1y',
                ],
            formatter: pfSumFormatter,
            styler: pfSumStyler,
          },
        )}
      {isEtf &&
        arrayTable(etfSum, {
          className,
          headers: [
            '',
            'NAV',
            'ETF',
            '주식',
            '주식선물',
            '지수선물',
            '유동성',
            '미수배당',
          ],
        })}
    </div>
  );
}
