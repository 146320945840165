import React, { useEffect, useState } from 'react';
import { toNumber } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { Row } from '@tanstack/react-table';
import './lsUniv.scss';
import { SimpleGridDataType } from 'tmslib/src/table/SimpleGrid';
import { rgb2color } from 'tmslib/src/util/uiutil';
import { useAuthState } from '../Auth/AuthContext';
import { DftBtnStyle } from '../../AppTypes';
import { UrlGrid, emptyGridArgs, UrlGridArgs } from '../../tmsutil';
import DateSelector from '../../shared/DateSelector';
import { setCol, setDropCol, LsScoreChartAndTable } from './LsCommon';

const page = '/Front/LsUniv';
type LsUnivRes = {
  Id: number;
  d: string;
  Whole: string;
};

type InspectRes = {
  Id: number;
  cd: string;
  nm: string;
  sec: string;
  mc: number;
  mcRnk: number;
  ta: number;
  ta20Rnk: number;
  cnt: number;
  mer0: number;
  mer1: number;
  er: number;
  dr: number;
  beta: number;
};

const lsUnivResDft: UrlGridArgs<LsUnivRes> = {
  url: `${page}/Data`,
  title: '',
  columns: [],
  headers: [],
  dftFormatter: (v, c) => {
    if (c.endsWith('.wei')) {
      if (v === undefined) return '';
      return `${v?.toFixed(1)}%`;
    }
    if (c.endsWith('.cnt')) {
      if (v === undefined) return '';
    }
    return v;
  },
  dftStyler: (v, c) => {
    let color: string | undefined;
    let backgroundColor: string | undefined;
    let fontSize: string | undefined;
    if (v === null || v === undefined) return null;
    if (c === 'd') backgroundColor = '#eee';
    if (c.endsWith('.cnt')) fontSize = '12px';
    if (c.endsWith('.wei')) fontSize = '10px';
    return { fontSize, color, backgroundColor };
  },
  widths: { d: 65, Whole: 50 },
  height: undefined,
  meta: {
    dftColWidth: 35,
    useGlobalFilter: false,
    containerClass: 'lsUniv',
  },
  dynamicColumns: (res) => {
    const secsEx = ['d', 'Whole'];
    const secs0: string[] = res.data.extras;
    const secs = secs0.filter((v) => !secsEx.contains(v));
    const columns = [
      'd',
      'Whole',
      ...secs.flatMap((v) => [`${v}.cnt`, `${v}.wei`]),
    ];
    const headers = ['일자', 'Whole', ...secs.flatMap(() => ['cnt', 'wei'])];
    let headerGroups: [string, number][] = secs.map((v) => [v, 2]);
    headerGroups = [['', 1], ['', 1], ...headerGroups];
    return { columns, headers, headerGroups };
  },
};

// prettier-ignore
const inspectColumns = ['cd', 'nm', 'sec', 'mc', 'mcRnk', 'ta', 'ta20Rnk','cnt', 'mer0', 'mer1', 'er', 'dr', 'beta']
// prettier-ignore
const inspectHeaders = ['Code', 'Name', 'Sec', '시총', '시총#', '금액', '금액#','#rpts', 'MER0', 'MER1', 'Fndm', 'Price', '베타']
// prettier-ignore
const colorDic: { [key: string]: string }
  = { 'ME': '#FFC6B9', 'IN': '#D0CFFF', 'AU': '#8CFF8D', 'CO': '#FFD4EF', 'FI': '#A8D6FF', 'IT': '#FF9FA4',
      'ET': '#ff9', 'TU': '#9fc', 'HO': '#daf', 'UN': '#ccc','BI':'#ffe1b9','RE':'#b9f9ff','SW':'#d4ffb9' }

const inspectDft: UrlGridArgs<InspectRes> = {
  url: `${page}/Inspect`,
  title: '',
  columns: [],
  headers: [],
  // prettier-ignore
  widths: { cd: 60, nm: 100, mcRnk: 50, ta20Rnk: 50, mer0: 45, mer1: 45},
  height: undefined,
  meta: {
    dftColWidth: 40,
    useGlobalFilter: false,
    showRowNum: true,
  },
  dynamicColumns: (res) => {
    const secs: string[] = res.data.extras;
    const columns = inspectColumns.concat(secs.map((v) => v));
    const headers = inspectHeaders.concat(secs.map((v) => v));
    return { columns, headers };
  },
  titles: {
    // prettier-ignore
    mc: (v: SimpleGridDataType, r: Row<InspectRes>) => `${r.original.mc} 조`,
    // prettier-ignore
    ta: (v: SimpleGridDataType, r: Row<InspectRes>) => `${r.original.ta} 20일 평균거래대금(십억)`,
  },
  dftStyler: (v, c) => {
    let color: string | undefined;
    let backgroundColor: string | undefined;
    if (c === 'sec') backgroundColor = colorDic[v as string];
    if (['mer0', 'mer1'].indexOf(c) >= 0) {
      const norm = Math.round((v as number) * 50);
      if (norm > 0) backgroundColor = rgb2color(255, 255 - norm, 255 - norm);
      else backgroundColor = rgb2color(255 + norm, 255 + norm, 255);

      if (norm > 100 || norm < -100) color = 'white';
      else color = 'black';
    }
    // prettier-ignore
    if(['ME','IN','AU','CO','FI','IT','ET','TU','HO','BI','RE','SW','UN'].indexOf(c)>=0){
      const norm = Math.round(Math.max(0, (v as number) - 0.3) * 300);
      [color, backgroundColor] = setCol(norm)
    }
    if (['er', 'dr'].indexOf(c) >= 0) {
      [color, backgroundColor] = setDropCol(c, v as number);
    }
    return { color, backgroundColor };
  },
  dftFormatter: (v, c) => {
    if (['mer0', 'mer1'].indexOf(c) >= 0) return v?.toFixedWithComma(4);
    if (['mc', 'beta', 'dr', 'er'].indexOf(c) >= 0)
      return v?.toFixedWithComma(1);
    // prettier-ignore
    if(['ME','IN','AU','CO','FI','IT','ET','TU','HO','BI','RE','SW','UN'].indexOf(c)>=0){
      return v?.toFixedWithComma(2);
    }
    return v;
  },
};

export default function LsUniv() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [inspectD, setInspectD] = useState<string>('');
  const [mc, setMc] = useState<number>(200);
  const [ta, setTa] = useState<number>(200);
  const [sec, setSec] = useState<string>('');
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [refreshNeededInspect, setRefreshNeededInspect] = useState(0);
  const [lsArgs, setLsArgs] = useState<UrlGridArgs<LsUnivRes>>(emptyGridArgs);
  const [inspectArgs, setInspectArgs] =
    useState<UrlGridArgs<InspectRes>>(emptyGridArgs);
  const [showScore, setShowScore] = useState<boolean>(false);
  const [prodId, setProdId] = useState<string>('');
  const [prodNm, setProdNm] = useState<string>('');

  const inspect: UrlGridArgs<InspectRes> = {
    ...inspectDft,
    meta: {
      ...inspectDft.meta,
      onRowClick: (r) => {
        setShowScore(true);
        setProdId(r.original.cd);
        setProdNm(r.original.nm);
      },
    },
  };

  const lsUnivRes: UrlGridArgs<LsUnivRes> = {
    ...lsUnivResDft,
    meta: {
      ...lsUnivResDft.meta,
      onCellClick: (c) => {
        setSec(c.column.id.split('_')[0]);
        setInspectArgs(inspect);
        setRefreshNeededInspect((p) => p + 1);
        setShowScore(false);
      },
      onRowClick: (r) => {
        setInspectD(r.original.d);
      },
    },
  };

  const queryForLsUniv = () => {
    setShowScore(false);
    setLsArgs(emptyGridArgs);
    setLsArgs(lsUnivRes);
    setRefreshNeeded((p) => p + 1);
  };

  useEffect(() => {
    queryForLsUniv();
  }, [d]);

  return (
    <>
      <div className="target children-me-2" style={{ minWidth: '1300px' }}>
        <DateSelector
          value={d}
          onChange={(date) => {
            if (date !== d) {
              setSearchParams({ d: date });
            }
          }}
        />
        Mkt.Cap (Top N):
        <input
          type="text"
          id="mc"
          size={4}
          value={mc}
          onChange={(e) => setMc(toNumber(e.target.value))}
        />
        &nbsp; Trd.Amt (Top N):
        <input
          type="text"
          id="ta"
          size={4}
          value={ta}
          onChange={(e) => setTa(toNumber(e.target.value))}
        />
        &nbsp;
        <button
          type="button"
          className={DftBtnStyle}
          onClick={() => queryForLsUniv()}
        >
          Get
        </button>
      </div>

      <hr className="narrow light" />
      <div className="row" style={{ width: '3200px' }}>
        <div className="col-4">
          <UrlGrid
            args={lsArgs}
            params={{ d, mc, ta }}
            refreshNeeded={refreshNeeded}
          />
        </div>
        <div className="col-8">
          <div>
            <UrlGrid
              args={inspectArgs}
              params={{ d: inspectD, mc, ta, sec }}
              refreshNeeded={refreshNeededInspect}
            />
          </div>
          {showScore && (
            <LsScoreChartAndTable
              subPage="LsUniv"
              prodId={prodId}
              prodNm={prodNm}
            />
          )}
        </div>
      </div>
    </>
  );
}
