import React, { useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { callAxios } from '../../tmsutil';

export default function ConfirmEmail() {
  const searchParams = useSearchParams()[0];
  const userid = searchParams.get('userid');
  const token = searchParams.get('token');
  const { msgBox: m } = useMessageState();

  useEffect(() => {
    callAxios({
      m,
      logger: null,
      url: '/Auth/ConfirmEmail',
      params: { userid, token },
      onSuccess: () => {
        m.alert('[Email confirmed]');
      },
      onError: (errMsgs) => {
        m.alert(`[Email confirm fail] ${errMsgs.join('\n')}`);
      },
    });
  }, []);
  return <Navigate replace to="/" />;
}
