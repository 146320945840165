import React, { useEffect, useRef } from 'react';
import Button from './Button';
import './dialog.css';

export default function Dialog({
  visible,
  style,
  onHide,
  header,
  footer,
  children,
}: {
  visible: boolean;
  style?: React.CSSProperties;
  onHide?: () => void;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
}) {
  const dialogRef = useRef<HTMLDialogElement | null>(null);

  const closeDialog = () => {
    onHide?.();
    dialogRef.current?.close();
  };

  useEffect(() => {
    if (visible) {
      dialogRef.current?.close();
      dialogRef.current?.showModal();
    } else closeDialog();
  }, [visible]);
  return (
    <dialog
      className="dialog"
      ref={dialogRef}
      style={style}
      onKeyDown={(e) => {
        if (e.key === 'Escape') closeDialog();
      }}
    >
      {visible && (
        <>
          <div className="d-flex justify-content-between">
            <header style={{ fontWeight: 'bold' }}>{header}</header>
            <Button className="btn-close" onClick={() => closeDialog()} />
          </div>
          <hr className="narrow light" />
          {children}
          {footer && (
            <>
              <hr className="narrow light" />
              {footer}
            </>
          )}
        </>
      )}
    </dialog>
  );
}
