import React, { useRef, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { mapValueToString } from 'tmslib/src/util/utils';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../../Auth/AuthContext';
import { RtDiff, updateRtRows, setRtEffect } from '../../../rtutil';
import DateSelector from '../../../shared/DateSelector';
import LoanOfferReqTab from './LoanOfferReqTab';
import {
  LendRatio,
  LendReq,
  LendReqLog,
  RecallNeeded,
} from '../../../Tms/Loan';

type LoanOfferReqRes = {
  t: number;
  reqs: LendReq[] | null;
  reqs_diff: RtDiff<LendReq> | null;
  logs: LendReqLog[] | null;
  logs_diff: RtDiff<LendReqLog> | null;
  recallNeeded: RecallNeeded[] | null;
  recallNeeded_diff: RtDiff<RecallNeeded> | null;
  ratios: LendRatio[] | null;
  ratios_diff: RtDiff<LendRatio> | null;
};

export default function LoanOfferReqMain() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { connection, connected, info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';

  const lastResT = useRef(0);

  const [currReqId, setCurrReqId] = useState<number>();

  const [reqs, setReqs] = useState<LendReq[]>([]);
  const [logs, setLogs] = useState<LendReqLog[]>([]);
  const [recallNeeded, setRecallNeeded] = useState<RecallNeeded[]>([]);
  const [ratios, setRatios] = useState<LendRatio[]>([]);

  const [updateNeeded, setUpdateNeeded_] = useState(0);
  const setUpdateNeeded = () => setUpdateNeeded_((p) => p + 1);

  useEffect(() => {
    setLogs([]);
  }, [currReqId]);

  const reqParams = {
    d,
    currReqId,
  };
  useEffect(
    () =>
      setRtEffect({
        m,
        logger,
        intv: 5,
        lastResT,
        params: reqParams,
        reqAddr: 'RequestLoanOfferReq',
        rcvAddr: 'ReceiveLoanOfferReq',
        connection,
        onReceive: (res: LoanOfferReqRes) => {
          setReqs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.reqs,
              diff: res?.reqs_diff,
            }),
          );
          setLogs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.logs,
              diff: res?.logs_diff,
            }),
          );
          setRecallNeeded((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.recallNeeded,
              diff: res?.recallNeeded_diff,
            }),
          );
          setRatios((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.ratios,
              diff: res?.ratios_diff,
            }),
          );
        },
      }),
    [connection, connected, d, updateNeeded, currReqId],
  );

  const toParams = mapValueToString;

  return (
    <div className="children-mb-1" style={{ minWidth: '1600px' }}>
      <div className="children-me-2">
        <DateSelector
          value={d}
          onChange={(date) =>
            setSearchParams(toParams({ ...reqParams, d: date }))
          }
        />
      </div>
      <hr className="light narrow" />
      <div className="row" style={{ width: '1000px' }}>
        <div className="col">
          <h5>
            <span className="badge bg-danger">리콜 필요</span>
          </h5>
          <SimpleGrid
            data={recallNeeded}
            columns={['vhId', 'brkId', 'prodId', 'prodNm', 'negQty']}
            headers={[
              '대여펀드',
              'LP(임시)',
              '종목코드',
              '종목명',
              '수량(음수)',
            ]}
            args={{
              meta: { dftColWidth: 70 },
            }}
          />
        </div>
        <div className="col" style={{ marginLeft: '20px' }}>
          <h5>
            <span className="badge bg-secondary">대여 비율</span>
          </h5>
          <SimpleGrid
            data={ratios}
            columns={['vhId', 'wei', 'loSum', 'posSum']}
            headers={['대여펀드', '비율(%)', '대여액', '주식액']}
            args={{
              meta: { dftColWidth: 70 },
              widths: { loSum: 120, posSum: 120 },
            }}
          />
        </div>
      </div>
      <hr className="light narrow" />
      <div className="row" style={{ width: '1600px' }}>
        <div className="col">
          <LoanOfferReqTab
            setUpdateNeeded={setUpdateNeeded}
            reqs={reqs}
            currId={currReqId}
            setCurrId={setCurrReqId}
          />
        </div>
        <div className="col" style={{ marginLeft: '20px' }}>
          <h5>
            <span className="badge bg-secondary">변경 로그</span>
          </h5>
          <SimpleGrid
            data={logs}
            columns={['tstr', 'ty', 'oldVal', 'newVal', 'userNm', 'info']}
            headers={['T', '필드', '기존값', '변경값', '변경자', '참고']}
            args={{
              meta: { dftColWidth: 70 },
            }}
          />
        </div>
      </div>
    </div>
  );
}
