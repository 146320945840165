import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { UrlGrid, UrlGridArgs, ValidData, callAxios, emptyGridArgs } from '../../tmsutil';
import { useAuthState } from '../Auth/AuthContext';
import DateSelector from '../../shared/DateSelector';
import VhclSelector from '../../shared/VhclSelector';
import { VhGrpTy } from '../../Tms/Tms';
import { DftBtnStyleMx } from '../../AppTypes';
import { EtfPdf, etfPdfSchema, EtfPfTy, EtfUniv, etfUnivSchema, EtfWeiAdj, etfWeiAdjSchema, GetSectorName, PdfPar, pdfParSchema, PfWei, PfWeiItem, SectorType, SecUniv, secUnivSchema } from '../../Tms/Etf';
import ButtonWithHelp from '../../shared/ButtonWithHelp';

type T = { Id: number }; // 임의 IId 타입
const currMenu = '/ETF/GenPdf';
const dftHeight = 400;

const secTys = [
  SectorType.BBIG,
  SectorType.CarbZero,
  SectorType.KCulture,
  SectorType.KV,
  SectorType.HighDiv,
  SectorType.KBio,
  SectorType.GAI,
  SectorType.GSD,
  SectorType.GBIO,
  SectorType.GCT
]


// prettier-ignore
const pdfPar: UrlGridArgs<PdfPar> = {
  url: `${currMenu}/PdfPar`,
  title: '파라미터',
  columns: ['name', 'sltd', 'note', 'd0', 'RefVhId', 'EtfAstTy', 'MinMC', 'MinTA', 'TflWei', 'TfsWei',
    'EtfCds', 'EtfWeis', 'BasketId', 'ActiveWei', 'CashWei', 'IdxMembCnt', 'isEqwETFL', 'isEqwTF',
    'FutCds', 'FutWeis', 'ShortAdj', 'MaxEachWei', 'MinEachWei', 'MinSseWei',
    'TiltEach', 'TiltSum', 'smallCapMinMC', 'maxWeiSmallCap'
  ],
  headers: ['이름', '적용', '노트', '적용시작일', '참조펀드', '자산구분', '시총(억)', '거래금(억)', 'TFL비중(s)', 'TFS비중',
    'ETF종목리스트', 'ETF비중리스트', '바스켓코드', '액티브비중', '현금비중', '지수구성종목수', 'ETFL동일비중', 'TFLS동일비중',
    '선물종목리스트', '선물비중리스트', '숏종목조정', '종목한도(%)', '최소한도(%)', '삼전최저(%)',
    'Tilting(t)', 'Tilting(T)', '캡시총(억)', '캡비중(%)'
  ],
  editable: true,
  meta: {
    dftColWidth: 65,
  },
  height: 200,
  schema: pdfParSchema,
  checkboxes: ['sltd', 'isEqwETFL', 'isEqwTF'],
};


// prettier-ignore
const etfWeiAdj: UrlGridArgs<EtfWeiAdj> = {
  url: `${currMenu}/EtfWeiAdj`,
  title: '종목 가중치 관리',
  columns: ['prodId', 'prodNm', 'wei', 'd0', 'd1'],
  headers: ['종목', '종목명', '가중치', '적용시작일', '적용종료일'],
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: etfWeiAdjSchema,
};

// prettier-ignore
const dftEtfUniv: UrlGridArgs<EtfUniv> = {
  url: `${currMenu}/EtfUniv`,
  title: '유니버스',
  columns: ['prodId', 'prodNm', 'd0', 'd1', 'isRepr'],
  headers: ['종목', '종목명', '적용시작일', '제거일', '대표'],
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: etfUnivSchema,
};

const etfUnivBatch = [true, false].map((isRepr) => ({
  ...dftEtfUniv,
  title: isRepr ? `유니버스 관리` : `유니버스 대표종목`,
}));

// prettier-ignore
const dftSecUniv: UrlGridArgs<SecUniv> = {
  url: `${currMenu}/SecUniv`,
  title: '섹터관리',
  columns: ['prodId', 'prodNm', 'sec', 'secNm', 'd0', 'd1'],
  headers: ['종목', '종목명', '섹터', '섹터명', '적용시작일', '적용종료일'],
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: secUnivSchema,
};

// prettier-ignore
const ManualPos: UrlGridArgs<EtfPdf> = {
  url: `${currMenu}/EtfPdf`,
  title: `PDF 일괄 수기 조정`,
  columns: ['prodId', 'prodNm', 'pos'],
  headers: ['종목코드', '종목명', '수량'],
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: etfPdfSchema,
};

const SecUnivBatch = secTys.map((sec) => ({
  ...dftSecUniv,
  title: `섹터관리 ${sec}`,
}));

type PageFunc =
  | 'PdfPar'
  | 'GenPdf'
  | 'EtfPdf'
  | 'SetEtfUnivRepr'
  | 'SetManualPos'

const dftColumns = ['Id', 'prodId', 'prodNm', 'weiPct', 'weiPctCh'];
const dftHeaders = ['#', '종목코드', '종목명', '비중(%)', 'ch(%)']

export default function GenPdf() {
  const { info } = useAuthState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { msgBox: m, logger } = useMessageState();

  const intVhId = 'TEK';
  const d = searchParams.get('d') || info?.currBizDay || '';
  const vh = searchParams.get('vh') || intVhId;

  const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);
  const [warnResMsg, setWarnResMsg] = useState<string | null>(null);
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [parId, setParId] = useState<number>()
  const [pars, setPars] = useState<PdfPar[]>([])
  const [pfs, setPfs] = useState<PfWei[]>([])
  const clearArgs = () => {
    setWarnResMsg('');
    setGridArgs(emptyGridArgs)
  }

  const call = (
    func: PageFunc,
    params: unknown,
    isGet: boolean,
    onSuccess: ((data: ValidData, res: AxiosResponse) => void) | undefined,
  ) => {
    callAxios({
      m,
      logger,
      url: `${currMenu}/${func}`,
      isGet,
      params,
      onSuccess,
    })
  };

  const getParMsts = () => {
    call('PdfPar', { vh }, true, (data) => {
      setPars(data)
      if (data.length > 0) {
        setParId(data[0].Id)
      }
    })
  };

  const getPdf = () => {
    if (parId == null) return;
    call('GenPdf', { d, vh, par: parId }, true, (data) => {

      setPfs(() => {
        const { pdf } = data;

        const OrgRefPf = { ...pdf.OrgRefPf, ty: EtfPfTy.OrgRefPf, columns: dftColumns.concat(['trdWeiPct', 'gics']), headers: dftHeaders.concat(['매매(%)', 'GICS']) }
        const RefPf = { ...pdf.RefPf, ty: EtfPfTy.RefPf, columns: dftColumns.concat(['secNm', 'mc', 'ta']), headers: dftHeaders.concat(['섹터', '시총(억)', '거래액']) }
        const TflPf = { ...pdf.TflPf, ty: EtfPfTy.TFL, columns: dftColumns.concat(['trdWeiPct', 'secNm']), headers: dftHeaders.concat(['매매(%)', '섹터']) }
        const TfsPf = { ...pdf.TfsPf, ty: EtfPfTy.TFS, columns: dftColumns.concat(['trdWeiPct', 'secNm']), headers: dftHeaders.concat(['매매(%)', '섹터']) }
        const CustEtfUniv = {
          ...pdf.CustEtfUniv, ty: EtfPfTy.CustETFUniv,
          columns: vh === "TED" ? ['Id', 'secNm', 'prodId', 'prodNm', 'mc', 'ta', 'divrt', 'shortWei', 'isRepr'] :
            ['Id', 'secNm', 'prodId', 'prodNm', 'mc', 'shortWei', 'isRepr'],
          headers: vh === "TED" ?
            ['#', '섹터', '종목코드', '종목명', '평시총(억)', '거래액', '수정배당수익률', '숏(%)', '선택'] :
            ['#', '섹터', '종목코드', '종목명', '평시총(억)', '숏(%)', '선택']
        }
        const EtflPf = { ...pdf.EtflPf, ty: EtfPfTy.ETFL, columns: dftColumns.concat(['secNm']), headers: dftHeaders.concat(['섹터']) }
        const ActivePf = { ...pdf.ActivePf, ty: EtfPfTy.ActivePf, columns: dftColumns, headers: dftHeaders }
        const BasketPf = { ...pdf.BasketPf, ty: EtfPfTy.BasketPf, columns: dftColumns.concat(['orgweiPct', 'prc']), headers: dftHeaders.concat(['원비중', '종가']) }
        const PassivePf = { ...pdf.PassivePf, ty: EtfPfTy.PassivePf, columns: dftColumns, headers: dftHeaders }
        const FinalPf = {
          ...pdf.FinalPf, ty: EtfPfTy.FinalPf,
          columns: ['Id', 'prodId', 'prodNm', 'orgweiPct', 'weiPct', 'weiPctCh', 'secNm', 'isPassive'],
          headers: ['#', '종목코드', '종목명', 'L조정전', 'L조정후', 'CH(%)', '섹터', '지수']
        }
        const PrePdf = {
          ...pdf.PrePdf, ty: EtfPfTy.PrePdf,
          columns: ['Id', 'prodId', 'krCd', 'prodNm', 'prc', 'pdfPos', 'thPos', 'thDiff', 'manualPos', 'finalPos',
            'pdfWeiPct', 'thWeiPct', 'finalWeiPct', 'thPctDiff', 'finalPctDiff', 'err'],
          headers: ['#', '종목코드', 'KR코드', '종목명', '종가', '기보유', '이론', '차이', '수기', '최종',
            '기보유w', '이론w', '최종w', '비중차(전)', '비중차(후)', '에러'],
        }
        return [OrgRefPf, RefPf, TflPf, TfsPf, CustEtfUniv, EtflPf, ActivePf, BasketPf, PassivePf, FinalPf, PrePdf]
      })
    })
  }

  const setEtfUnivRepr = (r: PfWeiItem[], isSet: boolean) => {
    call('SetEtfUnivRepr', { d, vh, prodIds: r.map((rr) => rr.prodId).join(','), isSet }, false, getPdf)
  }

  const getData = () => {
    getParMsts();
    getPdf();
    setRefreshNeeded((p) => p + 1)
  }

  useEffect(() => {
    getParMsts();
    getData();
  }, [])

  useEffect(() => {
    clearArgs();
    getData();
  }, [d, vh, parId])

  const getParams = (title: string) => {
    if (title.startsWith('섹터관리')) {
      return {
        sec: title.split(' ')[1]
      }
    }
    if (title.startsWith('유니버스')) {
      return {
        d, vh, isRepr: title === '유니버스 대표종목'
      }
    }
    return {
      d,
      vh,
      par: parId
    };
  };

  const query = (args: unknown) => {
    setGridArgs(args as UrlGridArgs<T>);
    setRefreshNeeded((p) => p + 1);
  };

  const updateManualPosField = (
    original: PfWeiItem,
    rowIndex: number,
    columnId: string,
    value: unknown,
  ) => {
    if (columnId !== 'manualPos') return;
    call('SetManualPos', { d, vh, prod: original.prodId, pos: value }, false, () => getPdf());
  };

  const getButton = (a: unknown) => {
    const { title } = a as UrlGridArgs<T>;
    const titleDisp = title.startsWith('섹터관리')
      ? GetSectorName(title.split(' ')[1] as SectorType)
      : title;

    return (
      <ButtonWithHelp
        key={title}
        helpid={title}
        className={DftBtnStyleMx}
        onClick={() => {
          setWarnResMsg('');
          query(a);
        }}
        label={titleDisp}
      />
    );
  };

  return (
    <div style={{ minWidth: '1500px' }} className="children-me-2">
      <DateSelector
        value={d}
        onChange={(date) => date !== d && setSearchParams({ d: date, vh })}
      />
      <VhclSelector
        d={d}
        value={vh}
        grp={VhGrpTy.ETF}
        onChange={(vhcl) =>
          vhcl && vhcl.Id !== vh && setSearchParams({ d, vh: vhcl.Id })
        } />

      <select
        value={parId}
        onChange={(e) => { setParId(e.target.value as unknown as number) }}
      >
        {pars.map((a) => (
          <option key={a.Id} value={a.Id}>
            {a.name}
          </option>
        ))}
      </select>
      <button
        type="button"
        className={DftBtnStyleMx}
        onClick={getData}
      >
        조회
      </button>
      {[pdfPar, etfWeiAdj].map((v) => getButton(v))}
      {etfUnivBatch.map((v) => getButton(v))}

      <hr className="narrow light" />
      <b>섹터관리</b>
      {SecUnivBatch.map((v) => getButton(v))}

      <hr className="narrow light" />
      {warnResMsg && (
        <div className="alert alert-slim alert-warning like-pre">
          {warnResMsg}
        </div>
      )}

      <hr className="narrow light" />
      <UrlGrid
        args={gridArgs}
        params={getParams(gridArgs.title)}
      />

      <hr className="narrow light" />
      <div className="d-flex flex-wrap" key='pfswrapper' >
        {pfs.length > 0 && pfs.map(((pf, i) =>
          <div style={{ margin: 10 }} key={`${pf.Name}${pf.Info}`}>
            <SimpleGrid
              data={pf.Items}
              columns={pf.columns as (keyof PfWeiItem)[]}
              headers={pf.headers ?? []}
              args={{
                title: `${i}. ${pf.Name ?? ''}`,
                editables: pf.ty === EtfPfTy.PrePdf ? ['manualPos'] : [],
                meta: {
                  editable: pf.ty === EtfPfTy.PrePdf,
                  updateField: pf.ty === EtfPfTy.PrePdf ? updateManualPosField : undefined,
                  dftColWidth: 60,
                  maxHeight: 400,
                  useGlobalFilter: true,
                  useFilterBox: true,
                  contextMenus: pf.ty === EtfPfTy.CustETFUniv ? [
                    {
                      label: '대표 종목 선정',
                      callback: (items) => setEtfUnivRepr(items, true),
                      disabled: (items) => items.some((v) => v.isRepr),
                    },
                    {
                      label: '대표 종목 취소',
                      callback: (items) => setEtfUnivRepr(items, false),
                      disabled: (items) => items.some((v) => !v.isRepr),
                    },
                    { divider: true },
                  ] : [],

                },
                widths: { Id: 25, secNm: 70, prodNm: 150, krCd: 120 },
                infoMsg: pf.Info != null ? (pf.Desc ?? '').concat(' | ', pf.Info) : (pf.Desc ?? ''),
                errMsg: pf.Errors.join(','),
                dftStyler: (v, c) => {
                  let color: string | undefined;
                  let backgroundColor: string | undefined;
                  if (['cnt', 'ch', 'eri'].indexOf(c) >= 0) {
                    backgroundColor = '#dfd';
                  }
                  if (v === null) return null;
                  if (['thDiff', 'thPctDiff', 'finalPctDiff', 'weiPct', 'weiPctCh', 'trdWeiPct'].indexOf(c) >= 0) {
                    color = v?.getSignColor();
                  }
                  return { color, backgroundColor };
                },
                dftFormatter: (v, c) => {
                  if (['weiPct', 'thPctDiff', 'finalPctDiff', 'weiPctCh', 'trdWeiPct', 'divrt', 'shortWei', 'pdfWeiPct', 'thWeiPct', 'finalWeiPct', 'thPctDiff', 'finalPctDiff'].indexOf(c) >= 0) {
                    return v?.toFixedWithComma(2);
                  }
                  if (['mc', 'ta', 'prc', 'pdfPos', 'thPos', 'thDiff', 'manualPos', 'finalPos'].indexOf(c) >= 0) {
                    return v?.toFixedWithComma(0);
                  }
                  return v;
                },

              }}
            />
          </div>
        ))}

        <UrlGrid
          args={ManualPos}
          params={{ d, vh }}
          refreshNeeded={refreshNeeded}
        />

      </div>
    </div >
  );
}
