import React, { useEffect, useState } from 'react';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { OrdState } from '../../../Tms/Ord';
import { callAxios } from '../../../tmsutil';

interface Props {
  state: OrdState | null;
}

export default function OrdStateBar({ state }: Props) {
  const [blockProc, setBlockProc] = useState<boolean>(
    state?.blockProc ?? false,
  );
  const [emsPend, setEmsPend] = useState<boolean>(state?.emsPend ?? false);
  const { msgBox: m, logger } = useMessageState();

  const call = (func: string, params: unknown) =>
    callAxios({
      m,
      logger,
      url: `/Back/OrdProc/${func}`,
      params,
    });
  const updateBlockProc = (block: boolean) => {
    setBlockProc(block);
    call('SetBlockProc', { block });
  };

  const updateEmsPend = (pend: boolean) => {
    setEmsPend(pend);
    call('SetEmsPend', { pend });
  };

  useEffect(() => setBlockProc(state?.blockProc ?? false), [state?.blockProc]);
  useEffect(() => setEmsPend(state?.emsPend ?? false), [state?.emsPend]);

  return (
    <span className="col children-me-2">
      {state?.staledOsts && (
        <span className="alert alert-slim alert-danger">
          미등록 주문 : {state?.staledOsts}
        </span>
      )}
      <label htmlFor="blockProc" style={{ color: blockProc ? 'red' : 'gray' }}>
        <input
          type="checkbox"
          checked={blockProc}
          id="blockProc"
          onChange={(e) => updateBlockProc(e.target.checked)}
        />
        주문처리 금지
        {blockProc && (
          <span>
            중(
            {state?.blockOper})
          </span>
        )}
      </label>
      <label htmlFor="emsPend" style={{ color: emsPend ? 'red' : 'gray' }}>
        <input
          type="checkbox"
          checked={emsPend}
          id="emsPend"
          onChange={(e) => updateEmsPend(e.target.checked)}
        />
        알고 펜딩
      </label>
      <span style={{ color: 'red', fontWeight: 'bold' }}>{state?.errMsg}</span>
    </span>
  );
}
