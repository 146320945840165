import React, { useEffect, useState } from 'react';
import { Table, RowData } from '@tanstack/react-table';
import OutsideHandler from '../ui/OutsideHandler';
import {
  selectAll,
  copyToClipboard,
  copyHeaderToClipboard,
  deleteSelectedRows,
} from './datagridutil';
import { IId } from './DataGrid';

export interface IContextMenuItem<TData extends RowData> {
  label?: string | ((items: TData[]) => string);
  callback?: (items: TData[]) => void;
  disabled?: (items: TData[]) => boolean;
  divider?: boolean;
  title?: boolean;
  underTitle?: boolean;
}

export interface IContextMenuProps<TData extends RowData> {
  table: Table<TData>;
  top: number;
  left: number;
  close?: () => void;
  menus?: IContextMenuItem<TData>[];
  dynamicMenusOnShow?: (items: TData[]) => Promise<IContextMenuItem<TData>[]>;
}

const callCallback = <TData extends RowData>(
  table: Table<TData>,
  callback?: (items: TData[]) => void,
  close?: () => void,
) => {
  const { rows } = table.getSelectedRowModel();
  callback?.(rows.map((r) => r.original));
  close?.();
};

export default function ContextMenu<TData extends IId>({
  table,
  top,
  left,
  close,
  menus: initMenus,
  dynamicMenusOnShow,
}: IContextMenuProps<TData>) {
  const [menus, setMenus] = useState<IContextMenuItem<TData>[]>(
    initMenus ?? [],
  );
  useEffect(() => {
    const getDynMenus = async () => {
      if (dynamicMenusOnShow) {
        const { rows } = table.getSelectedRowModel();
        const items = rows.map((r) => r.original);
        const dynMenus = await dynamicMenusOnShow(items);
        setMenus([...(initMenus ?? []), ...dynMenus]);
      }
    };
    getDynMenus();
  }, []);
  return (
    <div className="contextmenu" style={{ top: `${top}px`, left: `${left}px` }}>
      <OutsideHandler callback={close}>
        {!!menus.length &&
          menus.map((m, k) => {
            const { rows } = table.getSelectedRowModel();
            const data = rows.map((r) => r.original)
            if (data.length === 0) return null;
            const label = typeof m.label === 'function' ? m.label(data) : m.label
            let className = 'contextmenu-item';
            if (m.divider) {
              className += ' divider';
              return <div key={label ?? k} className={className} />;
            }
            if (m.title) className += ' title';
            if (m.underTitle) className += ' under-title';
            if (m.disabled?.(data)) className += ' disabled';
            return (
              <div key={label ?? k}>
                <button
                  type="button"
                  className={className}
                  onClick={() => callCallback(table, m.callback, close)}
                >
                  {label ?? ''}
                </button>
              </div>
            );
          }).filter(v => v != null)}
        <div className="contextmenu-item divider" />
        <div>
          <button
            type="button"
            className="contextmenu-item"
            onClick={() => {
              copyToClipboard(table, false);
              close?.();
            }}
          >
            Copy Selected Rows
          </button>
        </div>
        <div>
          <button
            type="button"
            className="contextmenu-item"
            onClick={() => {
              copyToClipboard(table, true);
              close?.();
            }}
          >
            Copy Selected Cells
          </button>
        </div>
        <div>
          <button
            type="button"
            className="contextmenu-item"
            onClick={() => {
              copyHeaderToClipboard(table);
              close?.();
            }}
          >
            Copy Headers
          </button>
        </div>
        <div className="contextmenu-item divider" />
        <div>
          <button
            type="button"
            className="contextmenu-item"
            onClick={() => {
              selectAll(table, true);
              close?.();
            }}
          >
            Select All
          </button>
        </div>
        {table.options.meta?.rowDeletable && (
          <>
            <div className="contextmenu-item divider" />
            <div>
              <button
                type="button"
                className="contextmenu-item"
                onClick={() => {
                  deleteSelectedRows(table);
                  close?.();
                }}
              >
                Delete Selected Rows
              </button>
            </div>
          </>
        )}
      </OutsideHandler>
    </div>
  );
}
