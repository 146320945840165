import React, { useRef, useState, useEffect } from 'react';
// import { isMobileSafari, isIOS } from 'react-device-detect';
import AutoComplete from 'tmslib/src/ui/AutoComplete';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { utilGet } from '../tmsutil';

export type SimpleProd = {
  Id: string;
  nm: string;
  t0?: string | null;
  t1?: string | null;
};

interface Props {
  value?: SimpleProd | null;
  onChange: (prod: SimpleProd | null) => void;
  className?: string;
}

export default function ProdSelector({ value, onChange, className }: Props) {
  const { msgBox: m, logger } = useMessageState();
  const [prod, setProd] = useState<SimpleProd | null>(null);
  const [prodSearchRes, setProdSearchRes] = useState<SimpleProd[]>([]);

  useEffect(() => {
    if (value === null || value?.Id) setProd(value);
  }, [value]);

  useEffect(() => {
    if (prod === null || prod?.Id) onChange(prod);
  }, [prod]);

  const noSearch = useRef<boolean>(false);
  const search = (query: string) => {
    if (noSearch.current) {
      // 종목명으로 검색후 엔터치면 한번더 검색해서 검색결과 또 보여줌. 그거 막으려고
      noSearch.current = false;
      setProdSearchRes([]);
      return;
    }
    if (query.trim()) {
      const params = { term: query.trim() };
      utilGet(m, logger, 'SearchProducts', params, (data) =>
        setProdSearchRes(data),
      );
    }
  };

  return (
    <AutoComplete
      field="Id"
      size={10}
      placeholder="종목"
      suggestions={prodSearchRes}
      itemTemplate={(p: SimpleProd) => `${p.nm} (${p.Id})`}
      completeMethod={search}
      onChange={(v) => setProd(v)}
      inputClassName={className}
      inputStyle={{ backgroundColor: prod ? '#ddf' : '#ffd' }}
      title={prod?.nm}
      initItem={value}
    />
  );
}
