import React, { useEffect, useState } from 'react';
import { toNumber } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import './lsPick.scss';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { rgb2color } from 'tmslib/src/util/uiutil';
import { ifesleExpr } from 'tmslib/src/util/utils';
import { useAuthState } from '../Auth/AuthContext';
import { DftBtnStyle } from '../../AppTypes';
import {
  UrlGrid,
  UrlGridArgs,
  emptyGridArgs,
  callAxiosGet,
} from '../../tmsutil';
import DateSelector from '../../shared/DateSelector';
import { setDropCol, LsScoreChartAndTable } from './LsCommon';

const page = '/Front/LsPick';

type LsPickRes = {
  Id: number;
  prodId: string;
  prodNm: string;
  tics: string;
  mc: number;
  ta20a: number;
  TTM: number | null;
  TML: number | null;
  TVG: number | null;
  TVV: number | null;
  TVS: number | null;
  TVI: number | null;
  cnt: number | null;
  ch: number | null;
  er: number | null;
  mer0: number | null;
  mer1: number | null;
  oiy: number | null;
  oiq: number | null;
  yqDiff: number | null;
  niy: number | null;
  fore0: number | null;
  fore1: number | null;
  inst0: number | null;
  inst1: number | null;
  dr0: number | null;
  dr1: number | null;
  dr2: number | null;
  roe: number | null;
  per: number | null;
  pbr: number | null;
  vdays: number | null;
};

const getVdays = (v: number) => {
  if (v >= 365) return ['1년 이상', undefined];
  if (v >= 182) return ['6개월 이상', undefined];
  if (v >= 91) return ['3개월 이상', '#ffc'];
  return ['3개월 미만', '#ffc'];
};

const lsPickDft: UrlGridArgs<LsPickRes> = {
  url: '',
  title: '',
  columns: [],
  headers: [],
  height: undefined,
  meta: {
    dftColWidth: 45,
    useGlobalFilter: false,
    containerClass: 'lsPick',
  },
  dftFormatter: (v, c, r) => {
    if (v === null) return v;
    if (c === 'yqDiff') {
      if (r?.original?.oiy === null || r?.original?.oiq === null) return null;
      return (
        (r?.original?.oiy ?? null) - (r?.original?.oiq ?? null)
      ).toFixedWithComma(1);
    }
    if (c === 'mc' || c === 'ta20a')
      return ((v as number) * 1e-8)?.toFixedWithComma(0);
    if (c === 'tics') return v === null ? null : String(v).substring(0, 2);
    if (['TTM', 'TML', 'TVG', 'TVV', 'TVS', 'TVI'].indexOf(c) >= 0) {
      return (v as number).toFixed(1); // * 100 ??
    }
    if (['er', 'mer0', 'mer1'].indexOf(c) >= 0) return v?.toFixedWithComma(4);
    // prettier-ignore
    if (['oiy', 'oiq', 'niy', 'fore0', 'fore1', 'inst0', 'inst1','dr0', 'dr1', 'dr2','roe','per'].indexOf(c) >= 0) {
      return v?.toFixedWithComma(1);
    }
    if (c === 'pbr') return v?.toFixedWithComma(2);
    if (c === 'vdays') {
      const [daysNm] = getVdays(v as number);
      return daysNm;
    }
    return v;
  },
  dftStyler: (v, c, r) => {
    let color: string | undefined;
    let backgroundColor: string | undefined;
    if (v === null) return { color, backgroundColor };
    if (['TTM', 'TML', 'TVG', 'TVV', 'TVS', 'TVI'].indexOf(c) >= 0) {
      color = (v as number)?.getSignColor(); // * 100 ??
    }
    if (c === 'ch') color = v?.getSignColor();
    if (['er', 'mer0', 'mer1'].indexOf(c) >= 0) {
      const norm = (v as number) * 500;
      color =
        ifesleExpr(
          [(v as number) > 0, rgb2color(norm, 0, 0)],
          [(v as number) < 0, rgb2color(0, 0, -norm)],
        ) ?? '#999';
    }
    // prettier-ignore
    if (['oiy', 'oiq', 'niy', 'fore0', 'fore1', 'inst0', 'inst1'].indexOf(c) >= 0) {
      if ((v as number) > 70) {
        const norm = (v as number - 70) * (c === 'oiy' || c === 'oiq' ? 5 : 3);
        backgroundColor = rgb2color(255, 255 - norm, 255 - norm);
        if (norm > 100) color = '';
      }
      if ((v as number) < 30) {
        const norm = (30 - (v as number)) * (c === 'oiy' || c === 'oiq' ? 5 : 3);
        backgroundColor = rgb2color(255 - norm, 255 - norm, 255)
        if (norm > 100) color = 'white'
      }
    }
    if (c === 'yqDiff') {
      if (r?.original?.oiy !== null && r?.original?.oiq !== null) {
        // Unsafe arithmetic operation on optional chaining. It can result in NaN  no-unsafe-optional-chaining
        const v0: number =
          (r?.original?.oiy ?? null) - (r?.original?.oiq ?? null);
        if ((v0 as number) > 30) {
          const norm = (v0 - 30) * 1.5;
          backgroundColor = rgb2color(255, 255 - norm, 255 - norm);
          if (norm > 100) color = 'white';
        }
        if ((v0 as number) < -30) {
          const norm = (-30 - v0) * 1.5;
          backgroundColor = rgb2color(255 - norm, 255 - norm, 255);
          if (norm > 100) color = 'white';
        }
      }
    }
    if (['dr0', 'dr1', 'dr2'].indexOf(c) >= 0) {
      [color, backgroundColor] = setDropCol(c, v as number);
    }
    if (c === 'vdays') {
      const [, backgroundColor0] = getVdays(v as number);
      backgroundColor = backgroundColor0;
    }

    return { color, backgroundColor };
  },
};

// prettier-ignore
const columns0  = ['prodId', 'prodNm', 'tics', 'mc', 'ta20a','TTM', 'TML',
  'cnt', 'ch', 'er', 'mer0', 'mer1', 'oiy', 'oiq', 'yqDiff', 'niy', 'fore0', 'fore1', 'inst0', 'inst1', 'dr0', 'dr1', 'dr2', 'roe', 'per', 'pbr']
// prettier-ignore
const headers0  = ['Code', 'Name', 'Sector', '시총(억)', '거래(억)','TTM', 'TML',
  '#R', 'Ch(%)', 'ER', 'MER0', 'MER1', 'FndmY', 'FndmQ', 'Y-Q', 'NetY', 'Fore0', 'Fore1', 'Inst0', 'Inst1', 'D0', 'D1', 'D2', 'ROE', 'PER', 'PBR']

// prettier-ignore
const columnsVent0  = ['Id', 'prodId', 'prodNm', 'tics', 'mc', 'ta20a','TTM', 'TML','TVG', 'TVV', 'TVS', 'TVI',
  'cnt', 'ch', 'er', 'mer0', 'mer1', 'oiy', 'oiq', 'yqDiff', 'niy', 'fore0', 'fore1', 'inst0', 'inst1', 'dr0', 'dr1', 'dr2', 'roe', 'per', 'pbr', 'vdays']
// prettier-ignore
const headersVent0  = ['No', 'Code', 'Name', 'Sector', '시총(억)', '거래(억)','TTM', 'TML', 'TVG', 'TVV', 'TVS', 'TVI',
  '#R', 'Ch(%)', 'ER', 'MER0', 'MER1', 'FndmY', 'FndmQ', 'Y-Q', 'NetY', 'Fore0', 'Fore1', 'Inst0', 'Inst1', 'D0', 'D1', 'D2', 'ROE', 'PER', 'PBR', '남은기간']

export default function LsPick() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [sec, setSec] = useState<string>('Whole');
  const [sectors, setSectors] = useState<string[]>([]);
  const [edQ, setEdQ] = useState<string>('');
  const [actlen1, setActlen1] = useState<number>(10);
  const [actlen2, setActlen2] = useState<number>(60);
  const [drlen1, setDrlen1] = useState<number>(5);
  const [drlen2, setDrlen2] = useState<number>(10);
  const [drlen3, setDrlen3] = useState<number>(20);
  const [mc, setMc] = useState<number>(300);
  const [ta, setTa] = useState<number>(300);
  const [minRpts, setMinRpts] = useState<number>(5);
  const [vmc, setVmc] = useState<number>(1000);
  const [vta, setVta] = useState<number>(20);
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [lsPickArgs, setLsPickArgs] =
    useState<UrlGridArgs<LsPickRes>>(emptyGridArgs);
  const [vent, setVent] = useState<boolean>(false);
  const [showScore, setShowScore] = useState<boolean>(false);
  const [prodId, setProdId] = useState<string>('');
  const [prodNm, setProdNm] = useState<string>('');

  const lsPick: UrlGridArgs<LsPickRes> = {
    ...lsPickDft,
    url: `${page}/Data`,
    columns: columns0 as (keyof LsPickRes)[],
    headers: headers0,
    // prettier-ignore
    widths: { prodId: 70, prodNm: 150, mc: 70, ta20a: 70, vdays: 80 ,oiy:50, oiq:50 },
    meta: {
      ...lsPickDft.meta,
      showRowNum: true,
      onRowClick: (r) => {
        setShowScore(true);
        setProdId(r.original.prodId);
        setProdNm(r.original.prodNm);
      },
    },
  };

  const lsPickVent: UrlGridArgs<LsPickRes> = {
    ...lsPickDft,
    url: `${page}/VentData`,
    columns: columnsVent0 as (keyof LsPickRes)[],
    headers: headersVent0,
    widths: { Id: 40, prodId: 70, prodNm: 150, mc: 70, ta20a: 70, vdays: 80 },
    height: 1000, // 로딩 속도로 인해서 적용함
    meta: {
      ...lsPickDft.meta,
      onRowClick: (r) => {
        setShowScore(true);
        setProdId(r.original.prodId);
        setProdNm(r.original.prodNm);
      },
    },
  };

  const getParams = () => {
    if (vent) {
      // prettier-ignore
      return {d, mc: vmc, ta: vta, actlen1, actlen2, drlen1, drlen2, drlen3, edQ, sec,};
    }
    // prettier-ignore
    return {d,mcrnk: mc,tarnk: ta,rpts: minRpts,minbeta: '',actlen1,actlen2,drlen1,drlen2,drlen3,edQ,sec,};
  };

  const queryForLsPick = (vent0: boolean) => {
    setShowScore(false);
    setLsPickArgs(emptyGridArgs);
    setVent(vent0);
    setLsPickArgs(vent0 ? lsPickVent : lsPick);
    setRefreshNeeded((p) => p + 1);
  };

  useEffect(() => {
    callAxiosGet({
      m,
      logger,
      url: `${page}/Sector`,
      params: {},
      onSuccess: (data) => setSectors(data),
    });
    const now = new Date();
    setEdQ(now.consenEoQ().toFormatString('yyyy-MM-dd'));
  }, []);

  useEffect(() => {
    queryForLsPick(false);
    setProdId('');
    setProdNm('');
  }, [d]);

  return (
    <>
      <div className="target children-me-2" style={{ minWidth: '1300px' }}>
        <DateSelector
          value={d}
          onChange={(date) => {
            if (date !== d) {
              setSearchParams({ d: date });
            }
          }}
        />
        <select value={sec} onChange={(e) => setSec(e.target.value)}>
          {sectors.map((a) => (
            <option key={a} value={a}>
              {a}
            </option>
          ))}
        </select>
        &nbsp;
        <span style={{ color: 'gray' }}>
          분기말:
          <input
            id="qed"
            type="text"
            value={edQ}
            size={10}
            onChange={(e) => setEdQ(e.target.value)}
          />
        </span>
        <hr className="narrow light" />
        Len (Fore/Inst):
        <input
          type="text"
          id="actlen1"
          size={3}
          value={actlen1}
          onChange={(e) => setActlen1(toNumber(e.target.value))}
        />
        <input
          type="text"
          id="actlen2"
          size={3}
          value={actlen2}
          onChange={(e) => setActlen2(toNumber(e.target.value))}
        />
        &nbsp;&nbsp; Len (Drop):
        <input
          type="text"
          id="drlen1"
          size={3}
          value={drlen1}
          onChange={(e) => setDrlen1(toNumber(e.target.value))}
        />
        <input
          type="text"
          id="drlen2"
          size={3}
          value={drlen2}
          onChange={(e) => setDrlen2(toNumber(e.target.value))}
        />
        <input
          type="text"
          id="drlen3"
          size={3}
          value={drlen3}
          onChange={(e) => setDrlen3(toNumber(e.target.value))}
        />
        <hr className="narrow light" />
        Mkt.Cap (Top N):
        <input
          type="text"
          id="mc"
          size={4}
          value={mc}
          onChange={(e) => setMc(toNumber(e.target.value))}
        />
        &nbsp; Trd.Amt (Top N):
        <input
          type="text"
          id="ta"
          size={4}
          value={ta}
          onChange={(e) => setTa(toNumber(e.target.value))}
        />
        min.Rpts:
        <input
          type="text"
          id="minRpts"
          size={3}
          value={minRpts}
          onChange={(e) => setMinRpts(toNumber(e.target.value))}
        />
        &nbsp;
        <button
          type="button"
          className={DftBtnStyle}
          onClick={() => queryForLsPick(false)}
        >
          Get
        </button>
        <hr className="narrow light" />
        Mkt.Cap(억):
        <input
          type="text"
          id="vmc"
          size={5}
          value={vmc}
          onChange={(e) => setVmc(toNumber(e.target.value))}
        />
        &nbsp; Trd.Amt(억):
        <input
          type="text"
          id="vta"
          size={3}
          value={vta}
          onChange={(e) => setVta(toNumber(e.target.value))}
        />
        &nbsp;
        <button
          type="button"
          className={DftBtnStyle}
          onClick={() => queryForLsPick(true)}
        >
          벤처펀드
        </button>
      </div>

      <hr className="narrow light" />
      <div className="row" style={{ width: vent ? '5300px' : '4500px' }}>
        <div className="col-4">
          <UrlGrid
            args={lsPickArgs}
            params={getParams()}
            refreshNeeded={refreshNeeded}
          />
        </div>
        <div className="col-8">
          {showScore && (
            <LsScoreChartAndTable
              subPage="LsUniv"
              prodId={prodId}
              prodNm={prodNm}
            />
          )}
        </div>
      </div>
    </>
  );
}
