import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import { DftBtnStyleMx } from '../../AppTypes';
import DateSelector from '../../shared/DateSelector';
import { UrlGrid, UrlGridArgs, emptyGridArgs, callAxios } from '../../tmsutil';
import VhclSelector from '../../shared/VhclSelector';
import FileUploadDialog from '../../shared/FileUploadDialog';
import {
  FundLiq,
  KsdSetup,
  KsdRdmpt,
  ksdSetupSchema,
  ksdRdmptSchema,
  BoLiqAdj,
  boLiqAdjSchema,
} from '../../Tms/BO';

const page = '/Fund/Liquidity';
const btnClass = DftBtnStyleMx;
const linkClass = 'btn btn-link';
type T = { Id: number }; // 임의 IId 타입

type PageFunc = 'UploadKsdSetupFile' | 'UploadKsdRdmptFile';

// prettier-ignore
const columnsDetail = ['d', 'vhId', 'na', 'depositYd',
                      'repoBuy', 'stkTrdY2', 'stkTrdY1', 'bond2stl', 'ipoPay', 'feePay', 'liq0',
                      'cashBuy', 'cashSell', 'swapLE', 'swapSE', 'fundBuy', 'trd',
                      'inFlow', 'outFlow', 'flow',
                      'credit', 'liqAdj', 'liq', 'liqRt'];
// prettier-ignore
const headersDetail = ['날짜', '펀드', '순자산',
                      '전일예금(+)', 'Repo환매(+)', '주식T-2매매(-)', '주식T-1매매(-)', '채권결제예정(-)', '청약대금납입(-)', '보수인출금(-)', '합계',
                      '주식현금매수(-)', '주식현금매도(+)', '스왑롱진입(-.3)', '스왑숏진입(-.3)', '펀드매수증거금(-)', '합계',
                      '설정(+)', '환매(-)', '합계',
                      '차입가능액', '당일자금조정', '유동자금', '유동비율'];

const headerGroupsDetail: [string, number][] = [
  ['', 1],
  ['', 1],
  ['', 1],
  ['장전 자금', 8],
  ['매매 기여분', 6],
  ['설정/환매', 3],
  ['', 1],
  ['', 1],
  ['', 1],
  ['', 1],
];

const fundLiqDft: UrlGridArgs<FundLiq> = {
  url: `${page}/Liquidity`,
  title: '펀드 유동성 (천원)',
  // prettier-ignore
  columns: ['d', 'vhId', 'na', 'liq0', 'trd', 'flow', 'credit', 'liqAdj', 'liq', 'liqRt'],
  // prettier-ignore
  headers: ['날짜', '펀드', '순자산', '장전 자금', '매매 기여분', '설정/환매', '차입가능액', '당일자금조정', '유동자금', '유동비율'],
  // prettier-ignore
  widths: {},
  editable: false,
  height: undefined,
  meta: {
    dftColWidth: 115,
  },
  dftStyler: (v, c) => {
    if (['vhId', 'liqRt'].indexOf(c) >= 0) {
      return { backgroundColor: '#ffa', fontWeight: 'bold' };
    }
    return null;
  },
  dftFormatter: (v, c) => {
    if (typeof v === 'number' && c !== 'liqRt') {
      const v0 = Math.round(v * 1e-3);
      const v1 =
        (v0 >= 1000 || v0 <= -1000) && v0 % 1 === 0 ? v.toFixedWithComma(0) : v;
      return v1;
    }
    return v;
  },
};

const ksdSetup: UrlGridArgs<KsdSetup> = {
  url: `${page}/KsdSetup`,
  title: '펀드 설정 대금 통보 승인',
  // prettier-ignore
  columns: ['d', 'vhId', 'proc', 'regul', 'krCd', 'crncy', 'dstrCd', 'selfAmt', 'invAmt', 'totAmt', 'regNo', 'setupD'],
  // prettier-ignore
  headers: ['날짜', '펀드', '진행구분', '적용법', '펀드코드', '통화', '판매사', '자기분', '투자자분', '총금액', '접수번호', '설정일'],
  // prettier-ignore
  widths: {dstrCd: 80, setupD: 80},
  editable: true,
  height: 800,
  meta: {
    dftColWidth: 90,
  },
  schema: ksdSetupSchema,
};

const ksdRdmpt: UrlGridArgs<KsdRdmpt> = {
  url: `${page}/KsdRdmpt`,
  title: '펀드 환매 청구 승인',
  // prettier-ignore
  columns: ['d', 'vhId', 'proc', 'ty', 'regul', 'krCd', 'crncy', 'timing', 'dstrCd', 'amtTy', 'selfQty', 'invQty', 'totQty', 'selfAmt', 'invAmt', 'totAmt', 'fixD', 'payD', 'regNo'],
  // prettier-ignore
  headers: ['날짜', '펀드', '진행구분', '환매구분', '적용법', '펀드코드', '통화', '환매주기', '판매사', '청구구분', '자기분수량', '투자자분수량', '총수량', '자기분금액', '투자자분금액', '총금액', '대금확정일', '지급일', '접수번호'],
  // prettier-ignore
  widths: {dstrCd: 80, fixD: 80, payD: 80},
  editable: true,
  height: 800,
  meta: {
    dftColWidth: 90,
  },
  schema: ksdRdmptSchema,
};

const boLiqAdj: UrlGridArgs<BoLiqAdj> = {
  url: `${page}/BoLiqAdj`,
  title: '당일 자금 조정액',
  // prettier-ignore
  columns: ['d', 'vhId', 'flow', 'note'],
  // prettier-ignore
  headers: ['날짜', '펀드', '조정액', '조정사유'],
  // prettier-ignore
  widths: {note: 350},
  editable: true,
  height: 500,
  meta: {
    dftColWidth: 100,
  },
  schema: boLiqAdjSchema,
};

export default function Liquidity() {
  const { msgBox: m, logger } = useMessageState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [d0, setD0] = useState(d);
  const vh = searchParams.get('vh') || 'ALL_FUNDS';

  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);
  const [detail, setDetail] = useState<boolean>(false);

  const [fileUploadVisible, setFileUploadVisible] = useState<boolean>(false);
  const [fileUploadTitle, setFileUploadTitle] = useState<string>('');
  const [fileUploadUrl, setFileUploadUrl] = useState<string>('');
  const [warnResMsg, setWarnResMsg] = useState<string | null>(null);

  let fundLiq: UrlGridArgs<FundLiq>;
  if (detail) {
    fundLiq = {
      ...fundLiqDft,
      columns: columnsDetail as (keyof FundLiq)[],
      headers: headersDetail,
      headerGroups: headerGroupsDetail,
      dftStyler: (v, c) => {
        if (['vhId', 'liqRt'].indexOf(c) >= 0) {
          return { backgroundColor: '#ffa', fontWeight: 'bold' };
        }
        if (
          _.difference(
            columnsDetail,
            fundLiqDft.columns as (keyof FundLiq)[],
          ).indexOf(c) >= 0
        ) {
          return { color: '#777' };
        }
        return null;
      },
      dftFormatter: (v, c) => {
        if (typeof v === 'number' && c !== 'liqRt') {
          const v0 = Math.round(v * 1e-3);
          const v1 =
            (v0 >= 1000 || v0 <= -1000) && v0 % 1 === 0
              ? v.toFixedWithComma(0)
              : v;
          return v1;
        }
        if (['bond2stl', 'ipoPay', 'feePay'].indexOf(c) >= 0) {
          return 'NA';
        }
        return v;
      },
    };
  } else {
    fundLiq = { ...fundLiqDft };
  }

  const getParams = (url: string) => {
    const func = url.split('/').pop();
    if (!func) return {};
    if (func.isIn('BoLiqAdj')) {
      return { d, d0 };
    }
    return { d, d0, vhOrGrp: vh };
  };

  const clearArgs = () => {
    setWarnResMsg(null);
    setGridArgs(emptyGridArgs);
  };

  const query = (args: unknown) => {
    setWarnResMsg(null);
    setGridArgs(emptyGridArgs);
    setRefreshNeeded((p) => p + 1);
    setGridArgs(args as UrlGridArgs<T>);
  };

  const fileUpload = (url: PageFunc) => {
    let title = '';
    if (url === 'UploadKsdSetupFile') {
      title = '설정 통보';
    } else if (url === 'UploadKsdRdmptFile') {
      title = '환매 청구';
    } else {
      m.alert('파일업로드 메뉴 이상');
      return;
    }
    setFileUploadVisible(true);
    setFileUploadTitle(title);
    setFileUploadUrl(`${page}/${url}`);
  };

  useEffect(() => {
    query(fundLiq);
  }, [d, d0, vh, detail]);

  return (
    <div className="children-me-2" style={{ minWidth: '1500px' }}>
      <DateSelector
        value={d}
        initDate0={d0}
        onChange={(date) => date !== d && setSearchParams({ d: date, vh })}
        onChangeD0={(date0) => setD0(date0)}
      />
      <VhclSelector
        d={d}
        meta
        all
        onChange={(vhcl) =>
          vhcl && vhcl.Id !== vh && setSearchParams({ d, vh: vhcl.Id })
        }
        value={vh}
      />
      <button type="button" className={btnClass} onClick={() => query(fundLiq)}>
        유동비율
      </button>
      <label htmlFor="detail">
        <input
          type="checkbox"
          checked={detail}
          id="detail"
          onChange={(e) => setDetail(e.target.checked)}
        />
        상세
      </label>
      <hr className="narrow light" />
      <b>예탁원</b>&nbsp;&nbsp;
      <button
        type="button"
        className={btnClass}
        onClick={() => query(ksdSetup)}
      >
        설정 통보
      </button>
      <button type="button" onClick={() => fileUpload('UploadKsdSetupFile')}>
        파일선택
      </button>
      &nbsp;&nbsp;|&nbsp;&nbsp;
      <button
        type="button"
        className={btnClass}
        onClick={() => query(ksdRdmpt)}
      >
        환매 청구
      </button>
      <button type="button" onClick={() => fileUpload('UploadKsdRdmptFile')}>
        파일선택
      </button>
      <hr className="narrow light" />
      <b>기타</b>&nbsp;&nbsp;
      <button
        type="button"
        className={btnClass}
        onClick={() => query(boLiqAdj)}
      >
        당일 자금 조정
      </button>
      &nbsp;&nbsp;|
      <button
        type="button"
        className={linkClass}
        onClick={() => {
          const cfmmsg = `마켓리더 입출금 선반영 ${d}`;
          callAxios({
            m,
            logger,
            url: `${page}/UpdateTmlFundFlow`,
            params: { d },
            confirmMsg: cfmmsg,
            onBegin: () => clearArgs(),
            title: '마켓리더 입출금 선반영',
            onSuccess: (data, res) => {
              if (!res.data.warnings?.length) {
                setWarnResMsg(`${cfmmsg ?? ''} OK`);
              } else {
                setWarnResMsg([cfmmsg].concat(res.data.warnings).join('\n'));
              }
            },
          });
        }}
      >
        (마켓리더 입출금 선반영)
      </button>
      <hr className="narrow light" />
      {warnResMsg && (
        <div className="alert alert-slim alert-warning like-pre">
          {warnResMsg}
        </div>
      )}{' '}
      <div>
        <UrlGrid
          args={gridArgs}
          params={getParams(gridArgs.url)}
          refreshNeeded={refreshNeeded}
        />
        <FileUploadDialog
          headerTitle={fileUploadTitle}
          fileExtensionLimit={['txt']}
          fileSizeLimit={5}
          params={getParams(fileUploadTitle)}
          url={fileUploadUrl}
          visible={fileUploadVisible}
          setVisible={setFileUploadVisible}
          setRefreshNeeded={setRefreshNeeded}
        />
      </div>
    </div>
  );
}
