import React, { useEffect, useState } from 'react';
import { useMessageState } from './context/MessageContext';
import Dialog from './ui/Dialog';
import SimpleGrid from './table/SimpleGrid';
import Editor from './ui/Editor';
import Button from './ui/Button';
import ButtonGroup from './ui/ButtonGroup';

type Item = {
  Id: number;
  name: string;
};
const items: Item[] = [
  { Id: 1, name: 'xx' },
  { Id: 2, name: 'yy' },
];

export default function Test() {
  const { msgBox: m, logger } = useMessageState();

  const [showDialog, setShowDialog] = useState(false);
  const [ystd, setYstd] = useState(true);

  const [res, setRes] = useState('');

  const a: any = [];

  useEffect(() => logger.addLog('info', 'xx'), []);

  return (
    <>
      <div className="children-me-2">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Button label="alert" onClick={() => m.alert('n')} />
        <Button
          label="confirm"
          onClick={async () => setRes(String(await m.confirm('test')))}
        />
        <Button
          label="prompt"
          onClick={async () => setRes(String(await m.prompt('test')))}
        />
        <Button label="toast" onClick={async () => m.toast('test')} />
        <Button
          label="error toast"
          onClick={async () => m.toast('test', 'error')}
        />
        <Button label="Dialog" onClick={() => setShowDialog(true)} />
        <ButtonGroup
          name="ystd"
          value={String(ystd)}
          onChange={(v) => setYstd(v === 'true')}
          options={[String(true), String(false)]}
          labels={['전일 종목', '당일 종목']}
        />
      </div>
      <Dialog
        visible={showDialog}
        onHide={() => setShowDialog(false)}
        style={{ width: '300px', height: '200px' }}
      >
        test
      </Dialog>
      <SimpleGrid
        data={items}
        columns={['Id', 'name']}
        headers={['Id', '이름']}
        args={{
          meta: { height: 65 },
          widths: { name: 80 },
        }}
      />
      <SimpleGrid
        data={items}
        columns={['Id', 'name']}
        headers={['Id', '이름']}
        args={{
          meta: { height: 65 },
          headerGroups: [['group', 2]],
          widths: { name: 80 },
        }}
      />
      <SimpleGrid
        data={items}
        columns={['Id', 'name']}
        headers={['Id', '이름']}
        args={{
          headerGroups: [['group', 2]],
          widths: { name: 80 },
        }}
      />
      <Editor
        value="<p>ab<strong>c</strong>d</p>"
        style={{ width: '400px', height: '300px' }}
        onBlur={(v) => setRes(v)}
      />
      <div>{res}</div>
      <br />
      <br />
      <br />
      <br />
      <button type="button" onClick={() => a.unknownFunction()}>
        exception
      </button>
    </>
  );
}
