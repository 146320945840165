import { MessageBox } from 'tmslib/src/context/MessageContext';
import {
  GetOrdPrcTyName,
  OrdPrcTy2,
  OrdSt,
  OrdStState,
  os2num,
} from '../../../Tms/Ord';
import { FuncCall } from '../../../tmsutil';

export enum UpdateOrdStField {
  None = 'None',
  SetOrd = 'SetOrd',
  ResolveError = 'ResolveError',
  NoNeedCncl = 'NoNeedCncl',
  EnforceExitAll = 'EnforceExitAll',
  Cancel = 'Cancel',
  Resume = 'Resume',
  SetPend = 'SetPend',
  ReleasePend = 'ReleasePend',
  SetRemoved = 'SetRemoved',
  SetGenerated = 'SetGenerated',
  SetWait = 'SetWait',
  SetDone = 'SetDone',
  MapRegister = 'MapRegister',
  SetT0 = 'SetT0',
  SetT1 = 'SetT1',
  SetPrcTy = 'SetPrcTy',
  SetLimitPrc = 'SetLimitPrc',
  SetNote = 'SetNote',
  SetWei = 'SetWei',
  SetRollOver = 'SetRollOver',
  FutMaxSprd = 'FutMaxSprd',
  NoCnclOK = 'NoCnclOK',
  UseKeep = 'UseKeep',
  OffsetAtClose = 'OffsetAtClose',
  SetByBrk = 'SetByBrk',
  EnterSwap = 'EnterSwap',
  DsgndBrk = 'DsgndBrk',
  EnforceDone = 'EnforceDone',
  EnterCash = 'EnterCash',
  Uptick = 'Uptick',
  Pyramiding = 'Pyramiding',
  OmniAcct = 'OmniAcct',
}

const updateOrdStFieldNames: { [key in UpdateOrdStField]: string } = {
  [UpdateOrdStField.None]: 'None',
  [UpdateOrdStField.SetOrd]: '주문 번호',
  [UpdateOrdStField.ResolveError]: '에러 해결',
  [UpdateOrdStField.NoNeedCncl]: '취소무응답 무시',
  [UpdateOrdStField.EnforceExitAll]: '전량청산 강제진행',
  [UpdateOrdStField.Cancel]: '정지',
  [UpdateOrdStField.Resume]: '재개',
  [UpdateOrdStField.SetPend]: '보류',
  [UpdateOrdStField.ReleasePend]: '보류 해제',
  [UpdateOrdStField.SetRemoved]: '제거',
  [UpdateOrdStField.SetGenerated]: '미등록 처리',
  [UpdateOrdStField.SetWait]: '대기 처리',
  [UpdateOrdStField.SetDone]: '완료 처리',
  [UpdateOrdStField.MapRegister]: '맵 재등록',
  [UpdateOrdStField.SetT0]: '시작시간',
  [UpdateOrdStField.SetT1]: '종료시간',
  [UpdateOrdStField.SetPrcTy]: '유형',
  [UpdateOrdStField.SetLimitPrc]: '제한가',
  [UpdateOrdStField.SetNote]: '메모',
  [UpdateOrdStField.SetWei]: '비중',
  [UpdateOrdStField.SetRollOver]: 'Roll Over',
  [UpdateOrdStField.FutMaxSprd]: '선물 괴리율',
  [UpdateOrdStField.NoCnclOK]: '정지불가동의',
  [UpdateOrdStField.UseKeep]: '킵 사용',
  [UpdateOrdStField.OffsetAtClose]: '마감상계 표시',
  [UpdateOrdStField.SetByBrk]: '브로커 개입',
  [UpdateOrdStField.EnterSwap]: '스왑 진입',
  [UpdateOrdStField.DsgndBrk]: '증권사 지정',
  [UpdateOrdStField.EnforceDone]: '강제완료처리',
  [UpdateOrdStField.EnterCash]: '캐시 진입',
  [UpdateOrdStField.Uptick]: '업틱',
  [UpdateOrdStField.Pyramiding]: '지연주문 누적',
  [UpdateOrdStField.OmniAcct]: '통합 계좌',
};

export const firstEnumInOrdStFlds = [
  UpdateOrdStField.ResolveError,
  UpdateOrdStField.Cancel,
  UpdateOrdStField.SetRemoved,
  UpdateOrdStField.SetT0,
  UpdateOrdStField.SetRollOver,
  UpdateOrdStField.SetByBrk,
  UpdateOrdStField.Uptick,
  UpdateOrdStField.OmniAcct,
];

export const OrdStUpdateFields = Object.keys(updateOrdStFieldNames)
  .filter((v) => v !== 'None')
  .map((v: string) => ({
    val: v,
    txt: updateOrdStFieldNames[v as UpdateOrdStField],
    isFirst: firstEnumInOrdStFlds.contains(v as UpdateOrdStField),
  }));

export const getUpdateFieldFromOrdStProp = (
  prop: keyof OrdSt,
): UpdateOrdStField => {
  switch (prop) {
    case 'hm0':
      return UpdateOrdStField.SetT0;
    case 'hm1':
      return UpdateOrdStField.SetT1;
    case 'prcTy':
      return UpdateOrdStField.SetPrcTy;
    case 'limitPrc':
      return UpdateOrdStField.SetLimitPrc;
    case 'note':
      return UpdateOrdStField.SetNote;
    case 'ord':
      return UpdateOrdStField.SetOrd;
    case 'wei':
      return UpdateOrdStField.SetWei;
    default:
      return UpdateOrdStField.None;
  }
};

export const updateOrdSts = async (
  osts: OrdSt[],
  fld: UpdateOrdStField,
  val: string | null,
  askCnfrm: boolean,
  call: FuncCall,
  fromTarget: boolean,
  m: MessageBox,
) => {
  if (!osts.length) {
    m.alert('선택 주문 없음');
    return;
  }

  let st: string | null = null;
  let alterField: UpdateOrdStField | null = null;
  let userInputVal: string | null = null;

  const cnclOrRemove = fld.isIn(
    UpdateOrdStField.SetRemoved,
    UpdateOrdStField.Cancel,
  );

  if (fromTarget) {
    if (!cnclOrRemove && osts.length !== 1) {
      m.alert('한 행만 선택하세요');
      return;
    }

    const ost = osts[0];

    if (fld === UpdateOrdStField.Cancel) {
      if (os2num(ost.state) < os2num(OrdStState.Waiting)) {
        // 미등록 주문을 타겟서 취소 시 삭제로 변경
        alterField = UpdateOrdStField.SetRemoved;
      }
    }

    if (ost.state === OrdStState.Pending && !cnclOrRemove) {
      m.alert('승인 대기 중인 주문 수정 불가. 삭제 후 재입력하세요');
      return;
    }

    if (fld === UpdateOrdStField.SetT1) {
      if (ost.stId.startsWith('RFM')) {
        m.alert(
          '모의 운용은 종료시간 정정 불가. 주문 정지 후 신규 주문을 넣으세요',
        );
        return;
      }
    }

    if (val == null) {
      // 아래서 입력받음
      if (fld === UpdateOrdStField.SetT1) {
        const oldVal = ost.hm1 ?? '';
        userInputVal = await m.prompt('종료시간 (HH:mm or HHmm)', oldVal);
        if (userInputVal == null) return;
        userInputVal = userInputVal.trim();
        if (userInputVal === oldVal) return;
      }
      if (fld === UpdateOrdStField.SetLimitPrc) {
        const oldVal = ost.limitPrc?.toString() ?? '';
        userInputVal = await m.prompt('제한가', oldVal);
        if (userInputVal == null) return;
        userInputVal = userInputVal.trim();
        if (userInputVal === oldVal) return;
      }
      if (fld === UpdateOrdStField.FutMaxSprd) {
        const oldVal = ost.futMaxSprd ?? '';
        userInputVal = await m.prompt('주식선물 괴리율(%)', oldVal);
        if (userInputVal == null) return;
        userInputVal = userInputVal.trim();
        if (userInputVal === oldVal) return;
      }
    }
    st = ost.stId;
  }

  if (fld === UpdateOrdStField.None) {
    m.alert('정정 필드 확인');
    return;
  }

  if (askCnfrm) {
    const f = updateOrdStFieldNames[fld];
    const sval =
      fld === UpdateOrdStField.SetPrcTy
        ? GetOrdPrcTyName(val as OrdPrcTy2)
        : val;
    const txt = `${osts.length}개 주문 ${f} ${sval === null ? '' : sval}?`;
    if (!(await m.confirm(txt))) {
      return;
    }
  }

  const par = {
    st,
    ids: osts.map((v) => v.Id),
    fld: alterField ?? fld,
    val: userInputVal ?? val,
  };
  call('UpdateOrdSts', par, {
    title: `주문 정정 (${updateOrdStFieldNames[fld]} ${val || ''})`,
  });
};

export enum OrderPermitType {
  None = 'None',
  주문금액과다 = '주문금액과다',
  종목한도초과 = '종목한도초과',
  넷한도초과 = '넷한도초과',
  공시한도초과 = '공시한도초과',
  FHF승인요 = 'FHF승인요',
  삼전한도초과 = '삼전한도초과',
  ETF한도초과 = 'ETF한도초과',
  해외주식지정가초과 = '해외주식지정가초과',
  초과반대방향취소 = '초과반대방향취소',
  롱한도초과 = '롱한도초과',
  종가집중매매 = '종가집중매매',
  신주인수권승인요 = '신주인수권승인요',
}

export const reqOrderPermit = async (
  osts: OrdSt[],
  ty: OrderPermitType,
  call: FuncCall,
  m: MessageBox,
) => {
  if (!osts.length) {
    m.alert('선택 주문 없음');
    return;
  }
  if (osts.length !== 1 && ty !== OrderPermitType.FHF승인요) {
    m.alert('항목을 하나만 선택하세요');
    return;
  }
  if (ty === OrderPermitType.해외주식지정가초과) {
    const msg =
      '분할/합병 등 기업 이벤트 여부를 확인하였습니까? (기업이벤트가 있으면 기준가격을 운용지원팀에 알려준 후 주문 등록 하세요)';
    if (!(await m.confirm(msg))) return;
  }
  const note = await m.prompt('허용 요청 사유');
  if (note == null) return;

  const par = {
    d: osts[0].d,
    ids: osts.map((v) => v.Id),
    ty,
    note,
  };
  call('ReqOrderPermit', par);
};

// permitTy, tag desc
export const OrdPermitTypeOnTarget: [OrderPermitType, string][] = [
  [OrderPermitType.주문금액과다, '시장대비 과비중'], // 2
  [OrderPermitType.종목한도초과, '개별주문 한도위반'], // 3
  [OrderPermitType.넷한도초과, '넷익스포져 위반'], // 4
  [OrderPermitType.공시한도초과, '5%공시 위반'], // 1
  [OrderPermitType.삼전한도초과, '삼성전자 한도위반'],
  [OrderPermitType.해외주식지정가초과, '해외주식지정가위반'],
  [OrderPermitType.종가집중매매, '종가집중매매'],
  [OrderPermitType.종가집중매매, '종가집중매매'],
  [OrderPermitType.신주인수권승인요, '신주인수권승인요'],
];

export const OrdPermitTypeOnTargetEtc: [OrderPermitType, string][] = [
  [OrderPermitType.ETF한도초과, 'ETF한도 초과'],
  [OrderPermitType.FHF승인요, '재간접 승인'],
];
