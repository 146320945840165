// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../tmsapp2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../tmsapp2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table.simple-tab {
  border-collapse: collapse;
  line-height: 1.3;
  font-size: 12px;
}

table.simple-tab th {
  border: solid 1px #e3e6e9;
  padding: 1px 5px;
  min-width: 30px;
  text-align: center;
}

table.simple-tab td {
  border: solid 1px #e3e6e9;
  padding: 1px 5px;
  min-width: 30px;
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./../tmslib/src/table/tables.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":["table.simple-tab {\r\n  border-collapse: collapse;\r\n  line-height: 1.3;\r\n  font-size: 12px;\r\n}\r\n\r\ntable.simple-tab th {\r\n  border: solid 1px #e3e6e9;\r\n  padding: 1px 5px;\r\n  min-width: 30px;\r\n  text-align: center;\r\n}\r\n\r\ntable.simple-tab td {\r\n  border: solid 1px #e3e6e9;\r\n  padding: 1px 5px;\r\n  min-width: 30px;\r\n  text-align: right;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
