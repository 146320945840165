import React from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';
import DataGrid from 'tmslib/src/table/DataGrid';
import { AlgoSliceCncl } from '../../../Tms/Ems';


interface Props {
  slcCncls: AlgoSliceCncl[];
}

const columnHelper = createColumnHelper<AlgoSliceCncl>();
const columns = [
  columnHelper.accessor('procHms', { header: '처리T', size: 60 }),
  columnHelper.accessor('nmc', { header: '구분' }),
  columnHelper.accessor('ClOrdID', { header: '주문번호', size: 100 }),
  columnHelper.accessor('OrigClOrdID', { header: '원주문', size: 100 }),
  columnHelper.accessor('price', {
    header: '주문가',
    size: 60,
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('FillQty', {
    header: '체결량',
    size: 60,
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('FillPrc', {
    header: '평단가',
    size: 60,
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('ErrorMsg', {
    header: '에러',
    size: 100,
    meta: {
      styler: () => ({ color: 'red' }),
    },
  }),
  columnHelper.accessor('ordMsg', { header: '지시', size: 100 }),
  columnHelper.accessor('replRsn', { header: 'rsn', size: 100 }),
].map((v) => v as ColumnDef<AlgoSliceCncl, unknown>);

export default function AlgoSliceCnclTab({ slcCncls }: Props) {
  const meta: TableMeta<AlgoSliceCncl> = {};
  return <DataGrid data={slcCncls} columns={columns} meta={meta} />;
}
