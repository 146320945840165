import React, { useEffect, useState } from 'react';
import { Row } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { SimpleGridDataType } from 'tmslib/src/table/SimpleGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import VhclSelector from '../../shared/VhclSelector';
import { UrlGrid, UrlGridArgs, emptyGridArgs , ValidData, callAxios } from '../../tmsutil'; 
import {
  ProdRstr,
  prodRstrSchema,
  ProdRstrCodeMap,
  prodRstrCodeMapSchema,
  ConvRight,
  convRightSchema,
  RstrPos,
  rstrPosSchema,
} from '../../Tms/BO';
import { DftBtnStyleMx } from '../../AppTypes';
import DateSelector from '../../shared/DateSelector';
import { VhGrpTy } from '../../Tms/Tms';

const currMenu = '/Back/RstrPos';
const dftHeight = 500;

type PageFunc =
  | 'VerifyRstr'
  | 'GenFillForConvRight'
  | 'GenRstrPosFromProdRstr'
  | 'GenARSRstrFills'
  | 'GenFillForRstrPos';

const rstrHelp0 = `매매제한 => 없음:None, 유증:Right, 무증:Bonus, 락업(보호예수O):Lock, 메자닌행사:Mez, 주식배당:Div, 분할:Split, 이전상장:Transfer, 보유확약(보호예수X):Promise`;

const rstrHelp1 = `매매제한 => 없음:None, 유증:R, 무증:B, 락업(보호예수O):L, 메자닌행사:M, 주식배당:D, 분할:S, 이전상장:T, 보유확약(보호예수X):P
매매제한(벤처) => 벤처신주:V, 구벤처신CB/BW:W
매매제한(합성) => 유증/신주:RV, 락업/신주:LV, 전환/신주:MV, 이전/신주:TV, 확약/신주:PV
가격 예외처리 : 유증 워런트 진입가/청산가, 무증락일 진입가 0으로 처리

[자동반영 적용 기준] : 변경전,후 종목 및 수량이 동일해야 함
[자동반영 미적용 케이스]
 1) 유증 : 전기간(권리락일, 워런트 상장일, 워런트 상폐일, 본주 상장일)
 2) 무증 : 권리락일 (무증분 상장일은 자동 반영)
 3) 메자닌행사 : 전환청구일  (청구분 상장일은 자동반영)
[시작일 청산 여부] : L,P,V 등의 케이스에, 기포지션에 대해 L,P,V 를 들어가는 경우 [시작일 청산] 칼럼을 true로 해야함.
[재처리시] 체결소게를 삭제했으면 매매제한 데이터도 삭제후 재등록 해야함.
`;

const rstrHelp2 = `- 비상장채권 전환청구 입력 시
전환가격: (전환청구수량+미수이자)/전환후보통주수량
단주대금: 0`;

// prettier-ignore
const prodRstr: UrlGridArgs<ProdRstr> = {
  url: `${currMenu}/ProdRstr`,
  title: '매매 제한 종목',
  columns: ['d','prodId','prodNm','rstr','rto','listD','prc','wrntD0','wrntD1','wrntCd', ],
  headers: ['락일','종목코드','종목명','매매제한','증가비율','상장일','유증가','워런트상장일','워런트상폐일','워런트코드', ],
  editable: true,
  meta: { dftColWidth: 70, useGlobalFilter: false, contextMenus: [] },
  widths: {},
  height: dftHeight,
  schema: prodRstrSchema,
  infoMsg: rstrHelp0,
};

// prettier-ignore
const prodRstrCodeMap: UrlGridArgs<ProdRstrCodeMap> = {
  url: `${currMenu}/ProdRstrCodeMap`,
  title: '미펀 코드 매핑',
  columns: ['prodId', 'prodNm', 'rstr', 'mafs', 'mafs2', 'mafs3'],
  headers: ['종목코드','종목명','매매제한','미펀코드','미펀코드2','미펀코드3', ],
  editable: true,
  meta: { dftColWidth: 100, useGlobalFilter: false },
  widths: {},
  height: dftHeight,
  schema: prodRstrCodeMapSchema,
  infoMsg: rstrHelp0,
};

// prettier-ignore
const convRight: UrlGridArgs<ConvRight> = {
  url: `${currMenu}/ConvRight`,
  title: '전환청구',
  columns: ['d','vhId','stId','sProdId','sProdNm','rstr','convQty','oddLotAmt','bProdId','bProdNm','underPrc','afQty','listD','isDone',],
  headers: ['날짜','펀드','전략','매도코드','매도종목명','매매제한','전환청구수량','단주대금','매수코드','매수종목명','매수종목현재가','전환후보통주수량','상장일','기처리',  ],
  editable: true,
  meta: { dftColWidth: 100, useGlobalFilter: false },
  widths: {},
  height: dftHeight,
  schema: convRightSchema,
  infoMsg: [rstrHelp1, rstrHelp2].join('\n'),
};

const dftStylerForRstrPos = (
  v: SimpleGridDataType,
  c: keyof RstrPos,
  r: Row<RstrPos>,
) => {
  if (r.original.errMsg != null) return { backgroundColor: 'red' };
  return null;
};

// prettier-ignore
const rstrPosArgs: UrlGridArgs<RstrPos> = {
  url: `${currMenu}/RstrPos`,
  title: '매매 제한 기간',
  columns: ['d0','rlsd','vhId','stId','prodId','prodNm','book','rstr','ls','qty','userNm','memo','errMsg','exitOnD0','f0xCd','f0xRstr','f0xId','f0eId','prc0','f1xId','prc1','f1eCd','f1eRstr','f1eId',],
  headers: ['시작일','해제일','펀드','전략','종목코드','종목명','북','제한','LS','수량','입력자','메모','자동반영에러','시작일청산','기존청산코드','기존청산제한','기존청산','제한진입','시작일가격','제한청산','종료일가격','신규진입코드','신규진입제한','신규진입', ],
  editable: true,
  meta: {
    dftColWidth: 70,
    useGlobalFilter: true,
    contextMenus: [],
  },
  widths: { d0: 80, rlsd: 80, memo: 150, errMsg: 150, f0xCd: 80, f1eCd: 80, f0xRstr: 90, f1eRstr: 90,},
  height: dftHeight,
  schema: rstrPosSchema,
  checkboxes: ['exitOnD0', 'f0xId','f0eId','f1xId','f1eId'],
  dftStyler: dftStylerForRstrPos,
  infoMsg: [rstrHelp1].join('\n'),
};

const btnClass = DftBtnStyleMx;
const linkClass = 'btn btn-link';
type T = { Id: number }; // 임의 IId 타입

export default function RstrPosMain() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const vh = searchParams.get('vh');

  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);
  const [verifyMsg, setVerifyMsg] = useState<string | null>(null);
  const [warnResMsg, setWarnResMsg] = useState<string | null>(null);

  const clearArgs = () => {
    setWarnResMsg(null);
    setGridArgs(emptyGridArgs);
  };

  const query = (args: unknown) => {
    clearArgs();
    setRefreshNeeded((p) => p + 1);
    setGridArgs(args as UrlGridArgs<T>);
  };

  const getButon = (a: unknown) => {
    const { title } = a as UrlGridArgs<T>; // args title
    return (
      <button
        key={title}
        type="button"
        className={btnClass}
        onClick={() => query(a)}
      >
        {title}
      </button>
    );
  };

  const handleAxiosResult = (
    data: ValidData,
    res: AxiosResponse,
    cfmmsg: string | null,
    callbackGridArgs?: unknown,
    func?: string | null,
  ) => {
    if (!res.data.warnings?.length) {
      setWarnResMsg(`${cfmmsg ?? ''} OK`);
    } else {
      setWarnResMsg([cfmmsg].concat(res.data.warnings).join('\n'));
    }
    if (func === 'VerifyRstr') {
      setVerifyMsg(
        res.data.warnings === 0 ? 'OK' : res.data.warnings.join('\n'),
      );
    }
    if (callbackGridArgs) {
      query(callbackGridArgs);
    }
  };

  const call = (
    func: PageFunc,
    params: unknown,
    confirmMsg: string,
    callbackGridArgs?: unknown,
    isGet = false,
  ) => {
    let page = `${currMenu}`;
    if (func === 'VerifyRstr') {
      page = '/Back/Closing';
      setVerifyMsg(null);
    }
    callAxios({
      m,
      logger,
      url: `${page}/${func}`,
      params,
      confirmMsg,
      onSuccess: (data, res) =>
        handleAxiosResult(data, res, confirmMsg, callbackGridArgs, func),
      onBegin: () => clearArgs(),
      isGet,
    });
  };

  const getFillForRstrPos = async (
    objs: RstrPos[],
    label: string,
    ty: string,
    callbackGridArgs: unknown,
  ) => {
    const ids = objs.map((v) => v.Id);
    const idsCnt = ids.length;
    const tyStr = ty[2] === 'E' ? '진입' : '청산'; // RstrPosFillType
    const cfrmMsg = `${label}

    ${d} ${idsCnt.toString()} 개 매매제한  ${tyStr}  체결 생성`;

    call('GenFillForRstrPos', { d, ty, ids }, cfrmMsg, callbackGridArgs);
  };

  const rstrPosArgsWithCxtMenus: UrlGridArgs<RstrPos> = {
    ...rstrPosArgs,
    meta: {
      ...rstrPosArgs.meta,
      contextMenus: [
        {
          label: '시작일 기존포지션 청산체결 생성',
          callback: (objs: RstrPos[]) =>
            getFillForRstrPos(
              objs,
              '시작일 기존포지션 청산체결 생성',
              'F0X',
              rstrPosArgsWithCxtMenus,
            ),
        },
        {
          label: '시작일 매매제한 진입체결 생성',
          callback: (objs: RstrPos[]) =>
            getFillForRstrPos(
              objs,
              '시작일 매매제한 진입체결 생성',
              'F0E',
              rstrPosArgsWithCxtMenus,
            ),
        },
        {
          label: '해제일 매매제한 청산체결 생성',
          callback: (objs: RstrPos[]) =>
            getFillForRstrPos(
              objs,
              '해제일 매매제한 청산체결 생성',
              'F1X',
              rstrPosArgsWithCxtMenus,
            ),
        },
        {
          label: '해제일 신규포지션 진입체결 생성',
          callback: (objs: RstrPos[]) =>
            getFillForRstrPos(
              objs,
              '해제일 신규포지션 진입체결 생성',
              'F1E',
              rstrPosArgsWithCxtMenus,
            ),
        },
      ],
    },
  };

  useEffect(() => {
    clearArgs();
    if (!d || !vh) return;
    call('VerifyRstr', { d, vhOrGrp: vh }, '', rstrPosArgsWithCxtMenus, true);
  }, [d, vh]);

  const prodRstrWithCxtMenus = {
    ...prodRstr,
    meta: {
      ...prodRstr.meta,
      contextMenus: [
        {
          label: '펀드 매매 제한 기간 생성',
          callback: (objs: ProdRstr[]) =>
            call(
              'GenRstrPosFromProdRstr',
              { d, vhOrGrp: vh, evtId: objs[0].Id },
              '펀드 매매 제한 기간 생성',
              rstrPosArgsWithCxtMenus,
            ),
        },
      ],
    },
  };

  const vhclTemplate = () => {
    const isTfim = user?.isSupervisory || user?.isAiOper;
    if (user?.isMgt) {
      return (
        <VhclSelector
          d={d}
          meta={false}
          grp={VhGrpTy.TFIM}
          onChange={(vhcl) =>
            vhcl && vhcl.Id !== vh && setSearchParams({ d, vh: vhcl.Id })
          }
          value={vh}
        />
      );
    }
    return (
      <VhclSelector
        d={d}
        meta
        all
        tfim={isTfim}
        onChange={(vhcl) =>
          vhcl && vhcl.Id !== vh && setSearchParams({ d, vh: vhcl.Id })
        }
        value={vh}
      />
    );
  };

  return (
    <div style={{ minWidth: '1500px' }} className="children-me-2">
      <DateSelector
        value={d}
        onChange={(date) =>
          date !== d && setSearchParams({ d: date, vh: vh ?? '' })
        }
      />
      {vhclTemplate()}
      <hr className="narrow light" />
      {[prodRstrWithCxtMenus, prodRstrCodeMap, convRight].map((v) =>
        getButon(v),
      )}
      <button
        type="button"
        className={linkClass}
        onClick={() =>
          call(
            'GenFillForConvRight',
            { d, vhOrGrp: vh },
            '전환청구 처리',
            rstrPosArgsWithCxtMenus,
          )
        }
      >
        (전환청구 처리)
      </button>
      |&nbsp;&nbsp;
      {getButon(rstrPosArgsWithCxtMenus)}
      <hr className="narrow light" />
      {verifyMsg && (
        <>
          <b>매매제한 검증</b>
          <div className="alert alert-danger like-pre">{verifyMsg}</div>
        </>
      )}
      {warnResMsg && (
        <div className="alert alert-slim alert-warning like-pre">
          {warnResMsg}
        </div>
      )}{' '}
      <UrlGrid
        args={gridArgs}
        params={{ d, vhOrGrp: vh }}
        refreshNeeded={refreshNeeded}
      />
    </div>
  );
}
