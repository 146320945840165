import React, { useEffect, useState } from 'react';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { Calendar, CalendarType } from '../../Tms/Tools';
import CalendarCore from '../Tools/CalendarCore';
import { callAxios, UrlGrid, UrlGridArgs, ValidData } from '../../tmsutil';
import { AiInv, AiProd } from '../../Tms/AI';
import { setInvProdDetail } from './BookView';

const currMenu = '/AI/Mezzanine';
type PageFunc = 'Calendar'

// prettier-ignore
const mznDft: UrlGridArgs<AiInv> = {
  url: `${currMenu}/AiInv`,

  title: '발행',
  columns: ['prodId', 'prodNm', 'invTy', 'underNm', 'oia', 'oca',
    'invD', 'issueD', 'ltd', 'couponRt', 'ytm',
    'fstRfxPrc', 'currRfxPrc', 'currPrc', 'prcDiffRto', 'rfxLB', 'convD',
    'putReqD0', 'putExerD', 'callExerD', 'callRto'],
  headers: ['종목코드', '종목명', '투자형태', '기초자산명', '투자원금(억)', '현재원금(억)',
    '투자일', '발행일', '만기일', 'Coupon', 'YTM',
    '최초전환가액', '현재전환가액', '현재주가', '괴리율', '리픽싱하단', '전환가능일',
    'Put청구일', 'Put행사일', 'Call행사일', 'Call비율'],
  height: 500,
  meta: { dftColWidth: 100 },
  widths: { underNm: 100, oia: 100, oca: 100, ltd: 80, invD: 80, issueD: 80, putReqD0: 80, putExerD: 80, callExerD: 80 }
};



export default function Mezzanine() {
  const { msgBox: m, logger } = useMessageState();
  const [year, setYear] = useState<number | null>(null)
  const [month, setMonth] = useState<number | null>(null)
  const [itmes, setItems] = useState<Calendar[]>([]);
  // const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [refreshNeeded] = useState(0);
  const [inv, setInv] = useState<AiInv>();
  const [prod, setProd] = useState<AiProd>();


  const mzn: UrlGridArgs<AiInv> =
  {
    ...mznDft,
    meta: {
      onRowClick: (r) => { setInv(r.original); setProd(r.original.prod) }
    },
  }

  const call = (
    func: PageFunc,
    params: unknown,
    isGet: boolean,
    whenInvalidMsg?: string | null,
    onSuccess?: (data: ValidData) => void,

  ) =>
    callAxios({
      m,
      logger,
      url: `${currMenu}/${func}`,
      whenInvalidMsg,
      params,
      onSuccess,
      isGet,
    });

  const getData = () => {
    if (year != null && month != null) {
      call('Calendar', { y: year, m: month, ty: CalendarType.Default }, true, null, (res: Calendar[]) => { setItems(res) });
    }
  }

  useEffect(() => {
    getData();
  }, [year, month])

  return (
    <div style={{ minWidth: '1500px' }}>
      <hr className="narrow light" />
      <CalendarCore ty={CalendarType.Default} currItem={null} items={itmes} setUCYear={setYear} setUCMonth={setMonth} />
      <hr className="narrow light" />

      <UrlGrid
        args={mzn}
        params={{}}

        refreshNeeded={refreshNeeded}
      />


      <br />
      {inv != null && prod != null && setInvProdDetail(prod, inv)};
    </div>
  )
}