import React, { useEffect, useState } from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';
import DataGrid, { DataGridState } from 'tmslib/src/table/DataGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { switchExpr } from 'tmslib/src/util/utils';
import { LendReq, LendReqState } from '../../../Tms/Loan';
import { DftBtnStyleMx } from '../../../AppTypes';
import { callAxios, downloadFile, checkItems } from '../../../tmsutil';

interface Props {
  setUpdateNeeded: () => void;
  reqs: LendReq[];
  currId: number | undefined;
  setCurrId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const columnHelper = createColumnHelper<LendReq>();
const columns = [
  columnHelper.accessor('ty', { header: '구분' }),
  columnHelper.accessor('vhId', { header: '대여펀드' }),
  columnHelper.accessor('brkId', { header: 'LP코드' }),
  columnHelper.accessor('brkNm', { header: 'LP명' }),
  columnHelper.accessor('prodId', { header: '종목', size: 70 }),
  columnHelper.accessor('prodNm', { header: '종목명', size: 120 }),
  columnHelper.accessor('sgdQty', {
    header: '수량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('fee', {
    header: '요율(%)',
    meta: { formatter: (v) => v?.toFixed(2) },
  }),
  columnHelper.accessor('toHoldRto', {
    header: '잔고대비',
    meta: { formatter: (v) => v?.toFixed(1) },
  }),
  columnHelper.accessor('state', { header: '상태' }),
  columnHelper.accessor('t', {
    header: '생성T',
    meta: { formatter: (v) => v?.slice(11, 16) },
  }),
  columnHelper.accessor('genNm', { header: '생성자' }),
  columnHelper.accessor('mngrNm', { header: '매니저' }),
  columnHelper.accessor('operNm', { header: '트레이더' }),
  columnHelper.accessor('availd', { header: '리콜결제일', size: 80 }),
  columnHelper.accessor('note', { header: '메모', size: 150 }),
].map((v) => v as ColumnDef<LendReq, unknown>);

export default function LoanOfferReqTab({
  setUpdateNeeded,
  reqs,
  currId,
  setCurrId,
}: Props) {
  const [dgState, setDgState] = useState<DataGridState<LendReq>>();

  const { msgBox: m, logger } = useMessageState();

  useEffect(() => {
    if (dgState?.currentObj?.Id !== currId) {
      setCurrId(dgState?.currentObj?.Id);
    }
  }, [currId, setCurrId, dgState]);

  const getLpFile = () =>
    downloadFile({
      m,
      logger,
      url: '/PBS/LoanOfferReq/DownloadLpFile',
      params: { ids: dgState?.selectedObjs.map((v) => v.Id) ?? [] },
    });

  const getKsdRecallReqFile = (ty: 'TML' | 'TEKTEI' | 'ETFETC') =>
    downloadFile({
      m,
      logger,
      url: '/PBS/LoanOfferReq/DownloadKsdRecallReqFile',
      params: {
        ids:
          dgState?.selectedObjs
            .filter((v) =>
              switchExpr(
                ty,
                ['TML', v.vhId === 'TML'],
                ['TEKTEI', v.vhId === 'TEK' || v.vhId === 'TEI'],
                ['ETFETC', !v.vhId.isIn('TML', 'TEK', 'TEI')],
              ),
            )
            .map((v) => v.Id) ?? [],
      },
    });

  const updateNote = async (items: LendReq[]) => {
    if (!checkItems(items, m, true)) return;
    const note = await m.prompt('메모', items[0].note ?? undefined);
    callAxios({
      m,
      logger,
      url: '/PBS/LoanOfferReq/UpdateLoanOfferReqNote',
      params: { id: items[0].Id, fld: 'note', val: note, old: items[0].note },
      onSuccess: () => setUpdateNeeded(),
    });
  };

  const updateReqState = (items: LendReq[], state: LendReqState) =>
    callAxios({
      m,
      logger,
      url: '/PBS/LoanOfferReq/UpdateLoanOfferReqState',
      params: { ids: items.map((v) => v.Id), state },
      onSuccess: () => setUpdateNeeded(),
    });

  const updateReqStateAvail = (
    items: LendReq[],
    state: LendReqState,
  ) =>
    switchExpr(
      state,
      [
        LendReqState.처리중,
        items.every((v) => v.state === LendReqState.접수대기),
      ],
      [
        LendReqState.예탁원완료,
        items.every((v) => v.state === LendReqState.처리중),
      ],
      [
        LendReqState.처리불가,
        items.every((v) =>
          v.state.isIn(LendReqState.접수대기, LendReqState.처리중),
        ),
      ],
    ) ?? false;

  const memoTitle = '메모 입력';
  const meta: TableMeta<LendReq> = {
    maxHeight: 1000,
    dftColWidth: 50,
    contextMenus: [
      LendReqState.처리중,
      LendReqState.예탁원완료,
      undefined,
      LendReqState.처리불가,
      undefined,
      memoTitle,
    ].map((s) =>
      s === undefined
        ? { divider: true }
        : {
            label: s,
            callback: (items: LendReq[]) =>
              s === memoTitle
                ? updateNote(items)
                : updateReqState(items, s as LendReqState),
            disabled: (items: LendReq[]) =>
              s === memoTitle
                ? false
                : !updateReqStateAvail(items, s as LendReqState),
          },
    ),
  };

  return (
    <>
      <h5>
        <span className="badge bg-secondary">대여 요청</span>&nbsp;
        <button
          type="button"
          className={DftBtnStyleMx}
          onClick={() => getLpFile()}
        >
          LP 제공 파일
        </button>
        <button
          type="button"
          className={DftBtnStyleMx}
          onClick={() => getKsdRecallReqFile('TML')}
        >
          리콜 신청 파일 (TML)
        </button>
        <button
          type="button"
          className={DftBtnStyleMx}
          onClick={() => getKsdRecallReqFile('TEKTEI')}
        >
          리콜 신청 파일 (TEK,TEI)
        </button>
        <button
          type="button"
          className={DftBtnStyleMx}
          onClick={() => getKsdRecallReqFile('ETFETC')}
        >
          리콜 신청 파일 (기타 ETF)
        </button>
      </h5>
      <DataGrid
        data={reqs}
        columns={columns}
        meta={meta}
        onStateChange={setDgState}
      />
    </>
  );
}
