import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import {
  RtDiff,
  updateRtRows,
  setRtEffect,
  updateRtObj,
} from '../../../rtutil';
import { PosWeiTarget } from '../../../Tms/BO';
import { Nav, BmRt, PfSum, EtfInfo, GrpWei } from '../../../Tms/Pf';
import { StrgCate, VhGrpTy } from '../../../Tms/Tms';
import { SimpleStrg } from '../../../shared/StrgSelector';
import Performance from './Performance';
import PerfCharts from './PerfCharts';
import PosWeiTab from './PosWeiTab';
import GrpWeiTab from './GrpWeiTab';
import './portfolio.scss';
import { useAuthState } from '../../Auth/AuthContext';
import { SimpleVhcl } from '../../../shared/VhclSelector';
import PosHistChart from './PosHistChart';
import VirtualOrder from './VirtualOrder';
import DVhStSelector from '../../../shared/DVhStSelector';

type PortfolioRes = {
  t: number;
  nav: Nav | null;
  nav_upd: { [key: string]: object } | null;
  bmRt: BmRt | null;
  bmRt_upd: { [key: string]: object } | null;
  pfSum: PfSum[] | null;
  pfSum_diff: RtDiff<PfSum> | null;
  etf: EtfInfo | null;
  etf_upd: { [key: string]: object } | null;
  lpos: PosWeiTarget[] | null;
  lpos_diff: RtDiff<PosWeiTarget> | null;
  spos: PosWeiTarget[] | null;
  spos_diff: RtDiff<PosWeiTarget> | null;
  ventSum: GrpWei[] | null;
  ventSum_diff: RtDiff<GrpWei> | null;
};

export default function PortfolioMain() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { connection, connected, info, user } = useAuthState();
  const { msgBox: m, logger } = useMessageState();

  const d = searchParams.get('d') || info?.currBizDay || '';
  const vh = searchParams.get('vh') || info?.dftVh || '';
  let st =
    searchParams.get('st') ||
    (vh === info?.dftVh ? info?.dftSt || '' : 'Whole');
  if ((vh === 'SMIC' || vh === 'IVY' || user?.isAdvisor) && st === 'Whole') {
    st = '';
  }

  const [vhcl, setVhcl] = useState<SimpleVhcl | null>(null);
  const [strg, setStrg] = useState<SimpleStrg | null>(null);

  const [nav, setNav] = useState<Nav | null>(null);
  const [bmRt, setBmRt] = useState<BmRt | null>(null);
  const [pfSum, setPfSum] = useState<PfSum[]>([]);
  const [etf, setEtf] = useState<EtfInfo | null>(null);
  const [lpos, setLpos] = useState<PosWeiTarget[]>([]);
  const [spos, setSpos] = useState<PosWeiTarget[]>([]);
  const lastResT = useRef(0);
  const [ventSum, setVentSum] = useState<GrpWei[]>([]);
  const [sltdProd, setSltdProd] = useState<string | null>(null);

  const vhOrVir = `${strg?.cate === StrgCate.Team && vh.isIn('TTM', 'TTE', 'TRP') ? 'V' : ''}${vh}`;
  useEffect(
    () =>
      setRtEffect({
        m,
        logger,
        intv: 5,
        lastResT,
        params: { d, vh: vhOrVir, st },
        reqAddr: 'RequestPortfolio',
        rcvAddr: 'ReceivePortfolio',
        connection,
        connCond: () => !!d && !!vh && !!st,
        onReceive: (res: PortfolioRes) => {
          setNav((prev) => updateRtObj(prev, res?.nav, res?.nav_upd));

          setBmRt((prev) => updateRtObj(prev, res?.bmRt, res?.bmRt_upd));

          setEtf((prev) => updateRtObj(prev, res?.etf, res?.etf_upd));

          setPfSum((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.pfSum,
              diff: res?.pfSum_diff,
            }),
          );

          setLpos((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.lpos,
              diff: res?.lpos_diff,
            }),
          );
          setSpos((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.spos,
              diff: res?.spos_diff,
            }),
          );
          setVentSum((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.ventSum,
              diff: res?.ventSum_diff,
            }),
          );
        },
      }),
    [connection, connected, d, vhOrVir, st],
  );

  const isEtf = vhcl?.grp === VhGrpTy.ETF;
  // const width = isEtf ? '2000px' : '1800px';
  return (
    <div className="fund-portfolio-container children-mb-3">
      <DVhStSelector
        value={[d, vh, st]}
        onChange={(d_, vhId, stId, vh_, st_) => {
          setVhcl(vh_);
          setStrg(st_);
          if (d_ !== d || vhId !== vh || stId !== st) {
            setSearchParams({
              d: d_,
              vh: vhId || '',
              st: stId || '',
            });
          }
        }}
        vh={{ vir: true }}
        st={{ whole: true, team: true, vir: true }}
      />
      {vhOrVir && st && (
        <>
          <Performance
            vhcl={vhcl}
            isEtf={isEtf}
            nav={nav}
            bmRt={bmRt}
            pfSum={pfSum}
            etf={etf}
          />
          {vhOrVir && st && (
            <PerfCharts
              d={d}
              vh={vhOrVir}
              st={st}
              isEtf={isEtf}
              bmRt={bmRt?.bm1Rt}
              fundRt={nav?.chpct}
            />
          )}
          <h5>Long Position</h5>
          <PosWeiTab
            vh={vhcl}
            st={st}
            isEtf={isEtf}
            pos={lpos}
            setProd={setSltdProd}
          />
          <h5>Short Position</h5>
          <PosWeiTab
            vh={vhcl}
            st={st}
            isEtf={isEtf}
            pos={spos}
            setProd={setSltdProd}
          />
          <div>{ventSum.length > 0 && <GrpWeiTab ventSum={ventSum} />}</div>
          {sltdProd && (
            <PosHistChart vh={vhOrVir} st={st} isEtf={isEtf} prod={sltdProd} />
          )}
          {vhOrVir.isIn('SMIC', 'IVY', 'VLS') && (
            <VirtualOrder d={d} vh={vhOrVir} st={st} />
          )}
        </>
      )}
    </div>
  );
}
