// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../tmsapp2/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../tmsapp2/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `dialog.msgBox {
  border: solid 1px gray;
  min-width: 200px;
  max-width: 600px;
  padding: 0.8em;
  top: 5%;
  left: 50%;
  margin-top: 0;
  margin-left: 0;
  transform: translate(-50%);
  z-index: 1000;
}

div.logBox-scroller {
  max-height: 170px;
  width: 85%;
  margin-left: 30px;
  /* for scroll down */
  overflow: scroll;
  display: flex;
  flex-direction: column-reverse;
  overflow-anchor: auto !important;
  border: solid 1px lightgray;
}

div.logBox {
  background-color: rgba(250, 250, 250, 0.9);
}

div.log {
  font-size: 12px;
  padding: 1px;
}

div.log-info {
  color: #555;
}

div.log-warning {
  color: #c70;
}

span.log-badge-warning {
  background-color: #c70 !important;
}

div.log-error {
  color: #d34;
}

.fade-out-box {
  background-color: #efe;
  animation: fadeout 5s;
  animation-fill-mode: forwards;
  position: fixed;  /* toast 박스는 modaless라서 위치 고정 안하면 상단으로 스크롤됨 */
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.3;
  }
}
`, "",{"version":3,"sources":["webpack://./../tmslib/src/context/messagecontext.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,OAAO;EACP,SAAS;EACT,aAAa;EACb,cAAc;EACd,0BAA0B;EAC1B,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,UAAU;EACV,iBAAiB;EACjB,oBAAoB;EACpB,gBAAgB;EAChB,aAAa;EACb,8BAA8B;EAC9B,gCAAgC;EAChC,2BAA2B;AAC7B;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,sBAAsB;EACtB,qBAAqB;EACrB,6BAA6B;EAC7B,eAAe,GAAG,6CAA6C;AACjE;AACA;EACE;IACE,UAAU;EACZ;EACA;IACE,YAAY;EACd;AACF","sourcesContent":["dialog.msgBox {\r\n  border: solid 1px gray;\r\n  min-width: 200px;\r\n  max-width: 600px;\r\n  padding: 0.8em;\r\n  top: 5%;\r\n  left: 50%;\r\n  margin-top: 0;\r\n  margin-left: 0;\r\n  transform: translate(-50%);\r\n  z-index: 1000;\r\n}\r\n\r\ndiv.logBox-scroller {\r\n  max-height: 170px;\r\n  width: 85%;\r\n  margin-left: 30px;\r\n  /* for scroll down */\r\n  overflow: scroll;\r\n  display: flex;\r\n  flex-direction: column-reverse;\r\n  overflow-anchor: auto !important;\r\n  border: solid 1px lightgray;\r\n}\r\n\r\ndiv.logBox {\r\n  background-color: rgba(250, 250, 250, 0.9);\r\n}\r\n\r\ndiv.log {\r\n  font-size: 12px;\r\n  padding: 1px;\r\n}\r\n\r\ndiv.log-info {\r\n  color: #555;\r\n}\r\n\r\ndiv.log-warning {\r\n  color: #c70;\r\n}\r\n\r\nspan.log-badge-warning {\r\n  background-color: #c70 !important;\r\n}\r\n\r\ndiv.log-error {\r\n  color: #d34;\r\n}\r\n\r\n.fade-out-box {\r\n  background-color: #efe;\r\n  animation: fadeout 5s;\r\n  animation-fill-mode: forwards;\r\n  position: fixed;  /* toast 박스는 modaless라서 위치 고정 안하면 상단으로 스크롤됨 */\r\n}\r\n@keyframes fadeout {\r\n  from {\r\n    opacity: 1;\r\n  }\r\n  to {\r\n    opacity: 0.3;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
