import React, { useEffect } from 'react';

export default function RadioGroup({
  name,
  value: initialValue,
  options,
  onChange,
  labels,
}: {
  name: string;
  value: string;
  options: string[];
  onChange: (value: string) => void;
  labels?: string[];
}) {
  const [value, setValue] = React.useState<string>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <span className='children-me-2'>
      {options.map((o, i) => (
        <label key={o} htmlFor={`${name}_${o}`}>
          <input
            type="radio"
            name={name}
            id={`${name}_${o}`}
            value={o}
            checked={value === o}
            onChange={(e) => setValue(e.target.value)}
          />
          {labels ? labels[i] : o}
        </label>
      ))}
    </span>
  );
}
