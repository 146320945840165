import React, { useMemo } from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';
import DataGrid from 'tmslib/src/table/DataGrid';
import { roundToSigFig } from 'tmslib/src/util/utils';
import { IContextMenuItem } from 'tmslib/src/table/ContextMenu';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { OrdSt, GetOrdPrcTyName, GetOrdStStateColor } from '../../../Tms/Ord';
import { LS, ex2num } from '../../../Tms/Common';
import {
  updateOrdSts,
  UpdateOrdStField,
  reqOrderPermit,
  OrderPermitType,
} from './ordutil';
import { FuncCall, isKSFut, isKStk } from '../../../tmsutil';

interface Props {
  call: FuncCall;
  osts: OrdSt[];
  allOsts: boolean;
}

const columnHelper = createColumnHelper<OrdSt>();
const columns = [
  columnHelper.accessor('tgtStr', { header: '펀드', size: 60 }),
  columnHelper.accessor('wei', {
    header: '비중',
    size: 60,
    meta: {
      formatter: (v, r) =>
        `${(ex2num(r.original.ex) * v).toFixed(2)}%${
          r.original.exitAll ? '(전)' : ''
        }`,
      styler: (v, r) => ({ color: r.original.sgn.getSignColor() }),
    },
  }),
  columnHelper.accessor('totQty', {
    header: '수량',
    size: 60,
    meta: {
      formatter: (v) => v?.toFixedWithComma(0),
      styler: () => ({ color: 'gray' }),
    },
  }),
  columnHelper.accessor('limitPrc', { header: '제한가' }),
  columnHelper.accessor('prcTy', {
    header: '유형',
    meta: {
      formatter: (v) => GetOrdPrcTyName(v),
      textAlign: 'center',
    },
  }),
  columnHelper.accessor('hm0', { header: '시작T' }),
  columnHelper.accessor('hm1', { header: '종료T' }),
  columnHelper.accessor('stateDisp', {
    header: '상태',
    size: 70,
    meta: {
      styler: (v, r) => ({
        color: GetOrdStStateColor(r.original.state, r.original.pend),
      }),
    },
  }),
  columnHelper.accessor('prgsRt', {
    header: '체결률',
    cell: (c) => c.getValue()?.toFixed(0),
  }),
  columnHelper.accessor('fillPrc', {
    header: '체결가',
    meta: { formatter: (v) => roundToSigFig(v)?.toFixedWithComma(0) },
  }),

  columnHelper.accessor('note', {
    header: '메모/에러/참고',
    cell: (c) => {
      const o = c.row.original;
      return (
        <ul className="ord-msgs">
          {o.note && <li>{o.note}</li>}
          {o.errMsgDisp && <li style={{ color: 'red' }}>{o.errMsgDisp}</li>}
          {o.warn && <li style={{ color: 'violet' }}>{o.warn}</li>}
          {o.info && <li>{o.info}</li>}
        </ul>
      );
    },
    size: 300,
  }),
].map((v) => v as ColumnDef<OrdSt, unknown>);

const allOstCols = [
  columnHelper.accessor('prodId', {
    header: '종목',
    size: 60,
    meta: { frozen: true },
  }),
  columnHelper.accessor('prodNm', {
    header: '종목명',
    size: 100,
    meta: { frozen: true },
  }),
  columnHelper.accessor('ls', {
    header: 'LS',
    size: 20,
    meta: {
      frozen: true,
      styler: (v, r) => ({ color: r.original.sgn.getSignColor() }),
    },
  }),
].map((v) => v as ColumnDef<OrdSt, unknown>);

export default function SubOrdTab({ call, osts, allOsts }: Props) {
  const { msgBox: m } = useMessageState();

  const update = (items: OrdSt[], fld: UpdateOrdStField, val?: string) =>
    updateOrdSts(items, fld, val ?? null, false, call, true, m);

  const cxtMenus: IContextMenuItem<OrdSt>[] = [
    {
      label: '취소 / 정지',
      callback: (items: OrdSt[]) => update(items, UpdateOrdStField.Cancel),
      disabled: (items: OrdSt[]) => items.some((v) => v.Done),
    },
    {
      label: '종료시간 변경',
      callback: (items: OrdSt[]) => update(items, UpdateOrdStField.SetT1),
      disabled: (items: OrdSt[]) => items.some((v) => v.Done),
    },
    {
      label: '제한가 변경',
      callback: (items: OrdSt[]) => update(items, UpdateOrdStField.SetLimitPrc),
      disabled: (items: OrdSt[]) => items.some((v) => v.Done),
    },
    {
      label: '주식선물 괴리율',
      callback: (items: OrdSt[]) => update(items, UpdateOrdStField.FutMaxSprd),
      disabled: (items: OrdSt[]) => items.some((v) => !isKSFut(v.prodId)),
    },
    {
      label: '킵 사용/리턴',
      callback: (items: OrdSt[]) => update(items, UpdateOrdStField.UseKeep),
      disabled: (items: OrdSt[]) =>
        items.some((v) => !isKStk(v.prodId) || v.ls !== LS.S),
    },
    { divider: true },
    { label: '주문 허용 요쳥', title: true },
    {
      label: '초과 반대 방향 취소',
      underTitle: true,
      callback: (items: OrdSt[]) =>
        reqOrderPermit(items, OrderPermitType.초과반대방향취소, call, m),
    },
  ];
  const meta: TableMeta<OrdSt> = {
    contextMenus: cxtMenus,
    containerClass: 'target-subords',
  };

  const cols = useMemo(
    () => (allOsts ? allOstCols.concat(columns) : columns),
    [allOsts],
  );

  return <DataGrid data={osts} columns={cols} meta={meta} />;
}
