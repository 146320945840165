import { z } from 'zod';
import {
  StkTy,
  stkTySchema,
  ProddailySources,
  proddailySourcesSchema,
} from './Prod';
import {
  zodPosiInt,
  zodDateNullable,
  zodPosiNumber,
  zodRange,
} from './ZodType';

export enum EvtTy {
  RightIssue = 'RightIssue',
  CB = 'CB',
  BW = 'BW',
  StkDividend = 'StkDividend',
  BonusIssue = 'BonusIssue',
  CapReduction = 'CapReduction',
  StkSplit = 'StkSplit',
  SpinOff = 'SpinOff',
  PhysicalDivision = 'PhysicalDivision',
  Merger = 'Merger',
  PrftIncineration = 'PrftIncineration',
  Etc = 'Etc',
}
export const evtTySchema = z.nativeEnum(EvtTy);

export interface IdxMemb {
  Id: number;
  d: string;
  idxId: string;
  memId: string;
  liqmc: number;
  liqrt: number;
  wei: number;
  memNm: string | null;
}

export const idxMembSchema = z.object({
  d: zodDateNullable,
  idxId: z.string(),
  memId: z.string(),
  liqmc: zodPosiInt(false),
  liqrt: zodRange(0, 1),
  wei: zodRange(0, 100),
  memNm: z.string().nullish().readonly(),
});

export interface CorpAction {
  Id: number;
  prodId: string;
  evtty: EvtTy;
  evtsubtyDesc: string;
  issued: string;
  notified: string;
  allocated: string | null;
  paidin: string | null;
  listed: string | null;
  stkty: StkTy | null;
  prcd: string | null;
  increasedSh: number | null;
  faceval: number | null;
  issueval: number | null;
  capAfChange: number | null;
  splitOffComp: string | null;
  listedExp: string | null;
  rto: number | null;
  updt: string;
  src: ProddailySources;
  userid: string;
  invalid: boolean;
  prodNm: string | null;
  notifiednd: string;
  allocatedyd: string | null;
}

export const corpActionSchema = z.object({
  prodId: z.string(),
  evtty: evtTySchema,
  evtsubtyDesc: z.string(),
  issued: z.string(),
  notified: z.string(),
  allocated: z.string().nullish(),
  paidin: z.string().nullish(),
  listed: z.string().nullish(),
  stkty: stkTySchema,
  prcd: z.string().nullish(),
  increasedSh: zodPosiNumber(true),
  faceval: zodPosiNumber(true),
  issueval: zodPosiNumber(true),
  capAfChange: zodPosiNumber(true),
  splitOffComp: z.string().nullish(),
  listedExp: z.string().nullish(),
  rto: zodPosiNumber(true),
  updt: z.string().nullish().readonly(),
  src: proddailySourcesSchema,
  userid: z.string().nullish().readonly(),
  invalid: z.coerce.boolean(),
  prodNm: z.string().nullish().readonly(),
  notifiednd: z.string().nullish().readonly(),
  allocatedyd: z.string().nullish().readonly(),
});

export enum UCorpActionStatusTy {
  PE = 0,
  CO = 1,
  UP = 2,
  ES = 3,
  CX = 4,
}
export const uCorpActionStatusTySchema = z.nativeEnum(UCorpActionStatusTy);

export interface UCorpAction {
  Id: number;
  evtKey: string;
  IdxId: string;
  prodId: string;
  prodNm: string | null;
  nd: string | null;
  lud: string | null;
  effd: string | null;
  status: string;
  statusty: UCorpActionStatusTy;
  actionTy: string | null;
  actionDesc: string | null;
  indexShare: number | null;
  TSO: number | null;
  PES: number | null;
  adjf: number | null;
  adjfheld: number | null;
  closePrc: number | null;
  adjPrc: number | null;
  cashAdjPrc: number | null;
  div: number | null;
  oldSym: string | null;
  memo: string | null;
  updt: string;
}

export const uCorpActionSchema = z.object({
  evtKey: z.string().readonly(),
  IdxId: z.string().readonly(),
  prodId: z.string().readonly(),
  prodNm: z.string().nullish().readonly(),
  nd: z.string().nullish().readonly(),
  lud: z.string().nullish().readonly(),
  effd: z.string().nullish().readonly(),
  status: z.string().readonly(),
  statusty: uCorpActionStatusTySchema,
  actionTy: z.string().nullish().readonly(),
  actionDesc: z.string().nullish().readonly(),
  indexShare: z.coerce.number().nullish().readonly(),
  TSO: z.coerce.number().nullish().readonly(),
  PES: z.coerce.number().nullish().readonly(),
  adjf: z.coerce.number().nullish().readonly(),
  adjfheld: z.coerce.number().nullish().readonly(),
  closePrc: z.coerce.number().nullish().readonly(),
  adjPrc: z.coerce.number().nullish().readonly(),
  cashAdjPrc: z.coerce.number().nullish().readonly(),
  div: z.coerce.number().nullish().readonly(),
  oldSym: z.string().nullish().readonly(),
  memo: z.string().nullish().readonly(),
  updt: z.string().nullish().readonly(),
});
