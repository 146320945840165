import React from 'react';
import { HubConnection } from '@microsoft/signalr';
import { Logger, MessageBox } from 'tmslib/src/context/MessageContext';

interface IId {
  Id: number | string;
}

export type RtDiff<T> = {
  // longId 용
  added: T[];
  removed: (number | string)[];
  updated: { [key: number | string]: { [key in keyof T]: object | null } };
  ords_chgd: { [key: number | string]: number };
};

export interface ISnapShotAndDiff<T> {
  snapshot: T[] | null;
  diff: RtDiff<T> | null;
}

export function updateRtObj<T extends IId>(
  prev: T | null,
  newObj: T | null,
  upd: { [key: string]: object | null } | null,
): T | null {
  if (newObj) return newObj;
  if (!upd) return prev;
  if (!prev) return prev;
  return { ...prev, ...upd };
}

// 가정 : 행 순서, 칼럼 순서 무의미 (소팅은 클라이언트 단에서 명시.)
export function updateRtRows<T extends IId>(
  rows: T[],
  res: ISnapShotAndDiff<T>,
): T[] {
  if (!res) return rows;
  if (res.snapshot) return res.snapshot;
  if (res.diff) {
    const removed = new Set(res.diff.removed);
    const upd = res.diff.updated;
    const newRows = rows
      .concat(res.diff.added)
      .filter((v) => !removed.has(v.Id))
      .map((v) => (Object.hasOwn(upd, v.Id) ? { ...v, ...upd[v.Id] } : v));
    if (Object.keys(res.diff.ords_chgd).length) {
      // Object.entries 에서 키가 string으로 바뀌기 때문에 아예 ordDic 전체를 string 키로.
      const oldOrdDic = new Map(rows.map((r, i) => [r.Id.toString(), i]));
      const ordDic = new Map([
        ...oldOrdDic,
        ...Object.entries(res.diff.ords_chgd),
      ]);
      newRows.sort(
        (a, b) =>
          (ordDic.get(a.Id.toString()) ?? 0) -
          (ordDic.get(b.Id.toString()) ?? 0),
      );
    }
    return newRows;
  }
  return rows;
}

export interface IRtEffectInfo<T extends { t: number }> {
  m: MessageBox;
  logger: Logger;
  intv: number;
  lastResT: React.MutableRefObject<number>;
  params: object | (() => object);
  reqAddr: string;
  rcvAddr: string;
  connection: HubConnection | null;
  connCond?: () => boolean;
  onReceive: (res: T) => void;
}

/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["info"] }] */
export const setRtEffect = <T extends { t: number }>(
  info: IRtEffectInfo<T>,
) => {
  let infoPar = info.params instanceof Function ? info.params() : info.params;
  let req = {
    intv: info.intv,
    auto: false,
    lastT: info.lastResT.current,
    ...infoPar,
  };

  let timeoutId: NodeJS.Timeout | null = null;
  if ((info.connCond?.() ?? true) && info.connection?.state === 'Connected') {
    info.connection.on(info.rcvAddr, (res) => {
      if (res.errors?.length) {
        // Rt 요청 중첩 등 관련 에러는 표시 안해줌.
        // [SILENT] 붙은 애들도 표시 안함
        const errs = res.errors.filter(
          (e: string) => !e.startsWith('[Rt 요청') && !e.startsWith('[SILENT]'),
        );
        if (errs.length) {
          info.logger.addLogs('error', errs, '게시');
          info.m.alert(errs);
        }
        return;
      }

      if (res.warnings?.length) {
        info.logger.addLogs('warning', res.warnings, '게시');
      }

      const { data } = res;

      if (info.lastResT) {
        info.lastResT.current = data.t;
      }
      info.onReceive(data);

      infoPar = info.params instanceof Function ? info.params() : info.params;
      req = {
        intv: info.intv,
        auto: true,
        lastT: data.t,
        ...infoPar,
      };
      timeoutId = setTimeout(
        () =>
          info.connection?.state === 'Connected' &&
          info.connection
            .send(info.reqAddr, req)
            .catch(() =>
              info.logger.addLog('error', '실시간 메세지 요청 실패', '게시'),
            ),
        info.intv * 1000,
      );
    });

    if (!timeoutId)
      info.connection
        .send(info.reqAddr, req)
        .catch(() =>
          info.logger.addLog('error', '실시간 메세지 요청 실패', '게시'),
        );
  }
  return () => {
    // clean up
    // console.log('summary connection binding clean up')
    if (info.connection) info.connection.off(info.rcvAddr);
    if (timeoutId) clearTimeout(timeoutId);
  };
};
