import React, { useEffect, useState } from 'react';
import _, { toNumber } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { Row } from '@tanstack/react-table';
import SimpleGrid, { SimpleGridDataType } from 'tmslib/src/table/SimpleGrid';
import { useAuthState } from '../Auth/AuthContext';
import { DftBtnStyle } from '../../AppTypes';
import { callAxiosGet } from '../../tmsutil';
import DateSelector from '../../shared/DateSelector';

const page = '/Front/LsPerf';

type PerfRes = {
  Id: string;
  h1: number;
  h2: number;
  wh: number;
};

type Pick = {
  cd: string;
  nm: string;
  er: number;
  rt: number;
  exit: boolean;
  newb: boolean;
};

// TODO titles
// api에서 50개 이하로 제한 함
type PickRes = {
  Id: string;
  picks: Pick[];
  'picks.0.nm': string;
  'picks.1.nm': string;
  'picks.2.nm': string;
  'picks.3.nm': string;
  'picks.4.nm': string;
  'picks.5.nm': string;
  'picks.6.nm': string;
  'picks.7.nm': string;
  'picks.8.nm': string;
  'picks.9.nm': string;
  'picks.10.nm': string;
  'picks.11.nm': string;
  'picks.12.nm': string;
  'picks.13.nm': string;
  'picks.14.nm': string;
  'picks.15.nm': string;
  'picks.16.nm': string;
  'picks.17.nm': string;
  'picks.18.nm': string;
  'picks.19.nm': string;
  'picks.20.nm': string;
  'picks.21.nm': string;
  'picks.22.nm': string;
  'picks.23.nm': string;
  'picks.24.nm': string;
  'picks.25.nm': string;
  'picks.26.nm': string;
  'picks.27.nm': string;
  'picks.28.nm': string;
  'picks.29.nm': string;
  'picks.30.nm': string;
  'picks.31.nm': string;
  'picks.32.nm': string;
  'picks.33.nm': string;
  'picks.34.nm': string;
  'picks.35.nm': string;
  'picks.36.nm': string;
  'picks.37.nm': string;
  'picks.38.nm': string;
  'picks.39.nm': string;
  'picks.40.nm': string;
  'picks.41.nm': string;
  'picks.42.nm': string;
  'picks.43.nm': string;
  'picks.44.nm': string;
  'picks.45.nm': string;
  'picks.46.nm': string;
  'picks.47.nm': string;
  'picks.48.nm': string;
  'picks.49.nm': string;
};

const chtOpt: Highcharts.Options = {
  chart: { type: 'line', width: 800, height: 500 },
  credits: { enabled: false },
  title: { text: '', style: { fontSize: '12px' }, margin: 0 },
  xAxis: { type: 'datetime' },
  yAxis: { title: { text: 'Cum.Return' } },
  legend: { itemStyle: { fontWeight: 'normal' }, margin: 0 },
  plotOptions: {
    line: {
      marker: { enabled: false },
      shadow: false,
      animation: false,
      lineWidth: 1,
    },
  },
  colors: ['#000000', '#ff5555', '#5555ff', '#55ff55'],
};

export default function LsPerf() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';

  const now = new Date();
  const [d0, setD0] = useState(now.addMonths(-24).toFormatString('yyyy-MM-dd')); // -24
  const [nMC, setNMC] = useState<number>(300);
  const [nTA, setNTA] = useState<number>(300);
  const [nRpts, setNRpts] = useState<number>(5);
  const [nGrp, setNGrp] = useState<number>(3);
  const [pfSz, setPfSz] = useState<number>(0);
  const [bufSz, setBufSz] = useState<number>(15);
  const [cost, setCost] = useState<number>(0);
  const [lag, setLag] = useState<number>(1);
  const [mktShort, setMktShort] = useState<boolean>(false);

  const [perf, setPerf] = useState<PerfRes[]>([]);
  const [tu, setTu] = useState<string>('');
  const [chtPerfOpts, setChtPerfOpts] = useState<Highcharts.Options>(chtOpt);
  const [pickL, setPickL] = useState<PickRes[]>([]);
  const [pickS, setPickS] = useState<PickRes[]>([]);
  const [pickLColMax, setPickLColMax] = useState<number>(0);
  const [pickSColMax, setPickSColMax] = useState<number>(0);

  const queryForLsPerf = () => {
    callAxiosGet({
      m,
      logger,
      url: `${page}/Data`,
      // prettier-ignore
      params: {d0, d, nMC, nTA, nRpts, nGrp, pfSz, bufSz, cost, lag, mktShort},
      onSuccess: (data) => {
        setPerf(data.perf);
        setTu(data.tu);
        setPickL(data.pickL);
        setPickLColMax(
          _.max(data.pickL.map((r: PickRes) => r.picks.length)) ?? 0,
        );
        setPickS(data.pickS);
        setPickSColMax(
          _.max(data.pickS.map((r: PickRes) => r.picks.length)) ?? 0,
        );

        const series = [...data.cht];
        series[3].visible = false;
        setChtPerfOpts((p) => ({
          ...p,
          series,
        }));
      },
    });
  };

  useEffect(() => {
    queryForLsPerf();
  }, [d]);

  let pickLHeaders0: string[] = [];
  let pickLColumns0: string[] = [];
  if (pickL.length > 0) {
    pickLHeaders0 = ['', ..._.range(1, pickLColMax).map((i) => `${String(i)}`)];
    pickLColumns0 = [
      'Id',
      ..._.range(0, pickLColMax - 1).map((i) => `picks.${String(i)}.nm`),
    ];
  }

  let pickSHeaders0: string[] = [];
  let pickSColumns0: string[] = [];
  if (pickS.length > 0) {
    pickSHeaders0 = ['', ..._.range(1, pickSColMax).map((i) => `${String(i)}`)];
    pickSColumns0 = [
      'Id',
      ..._.range(0, pickSColMax - 1).map((i) => `picks.${String(i)}.nm`),
    ];
  }

  const titles0 = (v: SimpleGridDataType, r: Row<PickRes>): string => {
    let v0: string = '';
    // const max = pickLColMax > pickSColMax ? pickLColMax : pickSColMax;
    // if (max >= 50) {
    //   m.alert(`${max} 칼럼 갯수 50 초과`);
    // }
    // Unary operator '++' used
    // for (let w = 0; w < max; w += 1) {
    r.original.picks.forEach((k: Pick) => {
      if (v === k.nm) {
        v0 = `${k.cd} ${k.er.toFixed(4)} ${k.rt.toFixed(2)}`;
      }
    });
    // }
    return v0;
  };

  return (
    <div className="target children-me-2" style={{ minWidth: '100px' }}>
      <b>Universe</b> &nbsp; Mkt.Cap (Top N):{' '}
      <input
        type="text"
        id="nMC"
        size={4}
        value={nMC}
        onChange={(e) => setNMC(toNumber(e.target.value))}
      />
      &nbsp; Trd.Amt (Top N):
      <input
        type="text"
        id="nTA"
        size={4}
        value={nTA}
        onChange={(e) => setNTA(toNumber(e.target.value))}
      />
      &nbsp; min.Rpts:{' '}
      <input
        id="nRpts"
        type="text"
        size={3}
        value={nRpts}
        onChange={(e) => setNRpts(toNumber(e.target.value))}
      />{' '}
      &nbsp;
      <hr className="narrow light" />
      <b>Setting</b> &nbsp; &nbsp; nGrp:{' '}
      <input
        id="nGrp"
        type="text"
        size={3}
        value={nGrp}
        onChange={(e) => setNGrp(toNumber(e.target.value))}
      />
      &nbsp; Pf.Sz:{' '}
      <input
        id="pfSz"
        type="text"
        size={3}
        value={pfSz}
        onChange={(e) => setPfSz(toNumber(e.target.value))}
      />
      &nbsp; Buf.Sz:{' '}
      <input
        id="bufSz"
        type="text"
        size={3}
        value={bufSz}
        onChange={(e) => setBufSz(toNumber(e.target.value))}
      />
      &nbsp; Cost(%):{' '}
      <input
        id="cost"
        type="text"
        size={3}
        value={cost}
        onChange={(e) => setCost(toNumber(e.target.value))}
      />{' '}
      &nbsp; Lag:{' '}
      <input
        id="lag"
        type="text"
        size={3}
        value={lag}
        onChange={(e) => setLag(toNumber(e.target.value))}
      />{' '}
      &nbsp;
      <label htmlFor="mktShort">
        <input
          id="mktShort"
          type="checkbox"
          checked={mktShort}
          onChange={(e) => setMktShort(e.target.checked)}
        />
        시장숏
      </label>
      <hr className="narrow light" />
      <b>Period</b> &nbsp; &nbsp;
      <DateSelector
        value={d}
        initDate0={d0}
        onChange={(date) => date !== d && setSearchParams({ d: date })}
        onChangeD0={(date0) => setD0(date0)}
      />
      <button
        type="button"
        className={DftBtnStyle}
        onClick={() => queryForLsPerf()}
      >
        Get
      </button>
      <hr className="narrow light" />
      <SimpleGrid
        data={perf}
        columns={['Id', 'h1', 'h2', 'wh']}
        headers={['', 'Section.1', 'Section.2', 'Whole']}
        args={{
          meta: {
            dftColWidth: 80,
          },
        }}
      />
      <hr className="narrow light" />
      TurnOver: <span id="tu">{tu}%</span>
      <HighchartsReact highcharts={Highcharts} options={chtPerfOpts} />
      <hr className="narrow light" />
      <h4>Long Picks</h4>
      {pickL.length > 0 && (
        <SimpleGrid
          data={pickL}
          columns={pickLColumns0 as (keyof PickRes)[]}
          headers={pickLHeaders0}
          args={{
            meta: {
              dftColWidth: 60,
              height: 2000,
            },
            widths: { Id: 80 },
            titles: {
              'picks.0.nm': titles0,
              'picks.1.nm': titles0,
              'picks.2.nm': titles0,
              'picks.3.nm': titles0,
              'picks.4.nm': titles0,
              'picks.5.nm': titles0,
              'picks.6.nm': titles0,
              'picks.7.nm': titles0,
              'picks.8.nm': titles0,
              'picks.9.nm': titles0,
              'picks.10.nm': titles0,
              'picks.11.nm': titles0,
              'picks.12.nm': titles0,
              'picks.13.nm': titles0,
              'picks.14.nm': titles0,
              'picks.15.nm': titles0,
              'picks.16.nm': titles0,
              'picks.17.nm': titles0,
              'picks.18.nm': titles0,
              'picks.19.nm': titles0,
              'picks.20.nm': titles0,
              'picks.21.nm': titles0,
              'picks.22.nm': titles0,
              'picks.23.nm': titles0,
              'picks.24.nm': titles0,
              'picks.25.nm': titles0,
              'picks.26.nm': titles0,
              'picks.27.nm': titles0,
              'picks.28.nm': titles0,
              'picks.29.nm': titles0,
              'picks.30.nm': titles0,
              'picks.31.nm': titles0,
              'picks.32.nm': titles0,
              'picks.33.nm': titles0,
              'picks.34.nm': titles0,
              'picks.35.nm': titles0,
              'picks.36.nm': titles0,
              'picks.37.nm': titles0,
              'picks.38.nm': titles0,
              'picks.39.nm': titles0,
              'picks.40.nm': titles0,
              'picks.41.nm': titles0,
              'picks.42.nm': titles0,
              'picks.43.nm': titles0,
              'picks.44.nm': titles0,
              'picks.45.nm': titles0,
              'picks.46.nm': titles0,
              'picks.47.nm': titles0,
              'picks.48.nm': titles0,
              'picks.49.nm': titles0,
            },
            dftFormatter: (v, c, r) => {
              let v0 = v;
              if (v0 === null) return v0;
              r.original.picks.forEach(() => {
                if (c.startsWith(`picks.`) && v !== undefined)
                  v0 = `${String(v).slice(0, 4)}`;
              });
              return v0;
            },
            dftStyler: (v, c, r) => {
              let color: string | undefined;
              let backgroundColor: string | undefined;
              r.original.picks.forEach((k, i) => {
                if (c.startsWith(`picks.${i}.nm`) && k.newb) {
                  color = '#ff0';
                  backgroundColor = '#f55';
                }
                if (c.startsWith(`picks.${i}.nm`) && k.exit) {
                  color = '#ff0';
                  backgroundColor = '#55f';
                }
              });
              return { color, backgroundColor };
            },
          }}
        />
      )}
      <hr className="narrow light" />
      <h4>Short Picks</h4>
      {pickS.length > 0 && (
        <SimpleGrid
          data={pickS}
          columns={pickSColumns0 as (keyof PickRes)[]}
          headers={pickSHeaders0}
          args={{
            meta: {
              dftColWidth: 60,
              height: 2000,
            },
            widths: { Id: 80 },
            titles: {
              'picks.0.nm': titles0,
              'picks.1.nm': titles0,
              'picks.2.nm': titles0,
              'picks.3.nm': titles0,
              'picks.4.nm': titles0,
              'picks.5.nm': titles0,
              'picks.6.nm': titles0,
              'picks.7.nm': titles0,
              'picks.8.nm': titles0,
              'picks.9.nm': titles0,
              'picks.10.nm': titles0,
              'picks.11.nm': titles0,
              'picks.12.nm': titles0,
              'picks.13.nm': titles0,
              'picks.14.nm': titles0,
              'picks.15.nm': titles0,
              'picks.16.nm': titles0,
              'picks.17.nm': titles0,
              'picks.18.nm': titles0,
              'picks.19.nm': titles0,
              'picks.20.nm': titles0,
              'picks.21.nm': titles0,
              'picks.22.nm': titles0,
              'picks.23.nm': titles0,
              'picks.24.nm': titles0,
              'picks.25.nm': titles0,
              'picks.26.nm': titles0,
              'picks.27.nm': titles0,
              'picks.28.nm': titles0,
              'picks.29.nm': titles0,
              'picks.30.nm': titles0,
              'picks.31.nm': titles0,
              'picks.32.nm': titles0,
              'picks.33.nm': titles0,
              'picks.34.nm': titles0,
              'picks.35.nm': titles0,
              'picks.36.nm': titles0,
              'picks.37.nm': titles0,
              'picks.38.nm': titles0,
              'picks.39.nm': titles0,
              'picks.40.nm': titles0,
              'picks.41.nm': titles0,
              'picks.42.nm': titles0,
              'picks.43.nm': titles0,
              'picks.44.nm': titles0,
              'picks.45.nm': titles0,
              'picks.46.nm': titles0,
              'picks.47.nm': titles0,
              'picks.48.nm': titles0,
              'picks.49.nm': titles0,
            },
            dftFormatter: (v, c, r) => {
              let v0 = v;
              if (v0 === null) return v0;
              r.original.picks.forEach(() => {
                if (c.startsWith(`picks.`) && v !== undefined)
                  v0 = `${String(v).slice(0, 4)}`;
              });
              return v0;
            },
            dftStyler: (v, c, r) => {
              let color: string | undefined;
              let backgroundColor: string | undefined;
              r.original.picks.forEach((k, i) => {
                if (c.startsWith(`picks.${i}.nm`) && k.newb) {
                  color = '#ff0';
                  backgroundColor = '#f55';
                }
                if (c.startsWith(`picks.${i}.nm`) && k.exit) {
                  color = '#ff0';
                  backgroundColor = '#55f';
                }
              });
              return { color, backgroundColor };
            },
          }}
        />
      )}
    </div>
  );
}
