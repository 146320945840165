import { z } from 'zod';
import { zodPosiInt } from './ZodType';

export enum Cate {
  Open = 'Open',
  Real = 'Real',
  Close = 'Close',

  // 100 시장 데이터
  KRX = 'KRX',
  QW = 'QW',
  BB = 'BB',
  Stat = 'Stat',
  Mkt = 'Mkt',
  MktR = 'MktR', // mkt real

  // 200 백오피스
  USTK = 'USTK',
  USwap = 'USwap',
  UFUT = 'UFUT',
  KSwap = 'KSwap',
  PosBs = 'PosBs',
  Loan = 'Loan',
  Ord = 'Ord',
  마감 = '마감',
  BO = 'BO', // 백오피스 기타

  // 300 기타
  ETF = 'ETF',
  ETFFile = 'ETFFile',
  Check = 'Check',
  ETC = 'ETC',
}

export const cateSchema = z.nativeEnum(Cate);

export enum Auth {
  NotUsed = 'NotUsed',
  Dev = 'Dev',
  Back = 'Back',
  ETF = 'ETF',
}
export const authSchema = z.nativeEnum(Auth);

export enum SkipDay {
  None = 'None',
  SatSun = 'SatSun',
  SunMon = 'SunMon',
  Sun = 'Sun',
  Weekly = 'Weekly',
}
export const skipDaySchema = z.nativeEnum(SkipDay);

export enum Channel {
  None = 'None',
  Dev = 'Dev',
  Oper = 'Oper',
  Mngr = 'Mngr',
}
export const channelSchema = z.nativeEnum(Channel);

export enum Runner {
  Main = 'Main',
  Second = 'Second',
}
export const runnerSchema = z.nativeEnum(Runner);

export enum Types {
  NotYet = 'NotYet',
  Done = 'Done',
  Error = 'Error',
  FinalFail = 'FinalFail',
  UnKnownFunc = 'UnKnownFunc',
}
export const typesSchema = z.nativeEnum(Types);

export interface CronJob {
  Id: number;
  func: string;
  note?: string | null;
  arg1?: string | null;
  arg2?: string | null;
  arg1desc?: string | null;
  arg2desc?: string | null;
  t0: string;
  t1: string;
  intv: number; // in sec
  repeat: boolean;
  skipDays: SkipDay;
  skipOnKorHoli: boolean;
  ord: number;
  skip: boolean;
  auth: Auth;
  chan: Channel;
  cate: Cate;
  rand?: number | null; // in sec
  endT?: string | null;
  elapsed?: number | null; // in sec
  ty?: Types | null;
  msg?: string | null;
  runner: Runner;
  devToo: boolean; // 테스트 환경에서도 돌리기
  eoyHoli: boolean; // endOfYearAsHoliday
}

export const cronJobSchema = z.object({
  Id: z.coerce.number().readonly(),
  cate: cateSchema,
  func: z.string(),
  note: z.string().nullish(),
  arg1: z.string().nullish(),
  t0: z.string(),
  t1: z.string(),
  intv: zodPosiInt(false),
  rand: zodPosiInt(true),
  repeat: z.coerce.boolean(),
  skipDays: skipDaySchema,
  skipOnKorHoli: z.coerce.boolean(),
  eoyHoli: z.coerce.boolean(),
  chan: channelSchema,
  runner: runnerSchema,
  skip: z.coerce.boolean(),
  auth: authSchema,
  ord: zodPosiInt(false),
  devToo: z.coerce.boolean(),
  endT: z.string().nullish().readonly(),
  elapsed: z.number().nullish().readonly(),
  ty: typesSchema.nullish().readonly(),
  msg: z.string().nullish().readonly(),
});

export interface CronJobPrereq {
  Id: number;
  jobId: number;
  preId: number;
  nextId?: number | null;
  jobNm?: string | null; // 추가
  preNm?: string | null; // 추가
}

export interface CronJobLog {
  Id: number;
  d: string;
  jobId: number;
  startT: string;
  endT: string;
  ty: Types;
  msg?: string | null;
  reqId?: string | null;
}

export interface CronJobReq {
  Id: number;
  d: string;
  jobId: number;
  reqT: string;
  doneT: string;
  userId: string;
  msg?: string | null;
  userNm?: string | null;
  jobNm?: string | null;
}

export const cronJobPrereqSchema = z.object({
  preId: zodPosiInt(false),
  preNm: z.string().nullish(),
  jobId: zodPosiInt(false),
  jobNm: z.string().nullish(),
});
