// https://soshace.com/react-user-login-authentication-using-usecontext-and-usereducer/
// https://codesandbox.io/s/login-authentication-usecontext-66t9t

import React, {
  useContext,
  createContext,
  useReducer,
  ReactNode,
} from 'react';
import { jwtDecode } from 'jwt-decode';
import { HubConnection } from '@microsoft/signalr';
import { setCookie, removeCookie } from '../cookie';
import User, { WebMenu, Claims, UserGroup, Company } from '../../Tms/Identity';
import { AppInfo } from '../../AppTypes';
import { getMainAxios } from '../../tmsutil';

interface AuthState {
  user: User | null;
  connection: HubConnection | null;
  connected: boolean;
  errMsg: string | null;
  info: AppInfo | null;
  showHelp: boolean;
}

const initialState: AuthState = {
  user: null,
  connection: null,
  connected: false,
  errMsg: null,
  info: null,
  showHelp: false,
};

interface AuthRes {
  accessToken: string;
  userid: string;
  menus: WebMenu[];
  showHelp: boolean;
}

export type AuthAction =
  // | { type: 'REQUEST_LOGIN' }
  | { type: 'LOGIN'; res: AuthRes }
  | { type: 'LOGIN_ERROR'; errMsg: string }
  | { type: 'LOGOUT' }
  | { type: 'SILENT_REFRESH'; auth: AuthRes; info: AppInfo }
  | { type: 'SILENT_REFRESH_ERROR'; errMsg: string }
  | { type: 'NEW_CONNECTION'; connection: HubConnection }
  | { type: 'SET_CONNECTED'; connected: boolean }
  | { type: 'SET_APPINFO'; info: AppInfo }

const AuthStateContext = createContext(initialState);
const AuthDispatchContext = createContext<React.Dispatch<AuthAction>>(() => {
  // do nothing
});

export function useAuthState() {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }
  return context;
}

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }
  return context;
}

export const JWT_EXPIRY_TIME = 15 * 60 * 1000; // 만료 시간 (15분 밀리 초로 표현)

const login = (res: AuthRes): User => {
  const { accessToken, userid, menus } = res;
  // accessToken 설정
  const ax = getMainAxios()
  ax.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  const { Name, Comp, Group } = jwtDecode<Claims>(accessToken);
  const user = new User(
    userid,
    accessToken,
    Name,
    Comp as Company,
    Group as UserGroup,
    menus,
  );

  // 서버에서 저장하니깐
  setCookie('userid', userid, {
    maxAge: JWT_EXPIRY_TIME,
    path: '/',
    sameSite: 'strict',
    secure: true,
  });
  return user;
};

const logout = (state: AuthState): void => {
  removeCookie('userid');
  removeCookie('refreshToken');
  if (state.connection) {
    state.connection.stop();
  }
};

function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        showHelp: action.res.showHelp,
        user: login(action.res),
      };

    case 'LOGIN_ERROR':
      logout(state);
      return { ...initialState, errMsg: action.errMsg };

    case 'LOGOUT':
      logout(state);
      return initialState;

    case 'SILENT_REFRESH': {
      const {
        currBizDay,
        nextBizDay,
        isDevTest,
        dftVh,
        dftSt,
        strgs,
        showHelp,
        helpIds,
        bookmarks,
        uncheckedNotiCnt,
        uncheckedReports,
      } = action.info;
      const info = new AppInfo(
        currBizDay,
        nextBizDay,
        isDevTest,
        dftVh,
        dftSt,
        strgs,
        showHelp,
        helpIds,
        bookmarks,
        uncheckedNotiCnt,
        uncheckedReports,
      );
      return { ...state, showHelp, user: login(action.auth), info };
    }

    case 'SILENT_REFRESH_ERROR':
      logout(state);
      return { ...initialState, errMsg: action.errMsg };

    case 'NEW_CONNECTION':
      return { ...state, connection: action.connection };

    case 'SET_CONNECTED':
      return { ...state, connected: action.connected };

    case 'SET_APPINFO': {
      const {
        currBizDay,
        nextBizDay,
        isDevTest,
        dftVh,
        dftSt,
        strgs,
        showHelp,
        helpIds,
        bookmarks,
        uncheckedNotiCnt,
        uncheckedReports,
      } = action.info;
      const info = new AppInfo(
        currBizDay,
        nextBizDay,
        isDevTest,
        dftVh,
        dftSt,
        strgs,
        showHelp,
        helpIds,
        bookmarks,
        uncheckedNotiCnt,
        uncheckedReports,
      );
      return { ...state, showHelp, info };
    }

    default:
      return { ...state };
  }
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const [authState, dispatch] = useReducer(authReducer, initialState);
  return (
    <AuthStateContext.Provider value={authState}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}
