import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import SimpleGrid, { SimpleGridArgs } from 'tmslib/src/table/SimpleGrid';
import NumericInput from 'tmslib/src/ui/NumericInput';
import { DataGridState } from 'tmslib/src/table/DataGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import DateSelector from '../../shared/DateSelector';
import { GetVhGrpName, VhGrpTy } from '../../Tms/Tms';
import { DftBtnStyle } from '../../AppTypes';
import { PosRstrTy, posRstrTySchema } from '../../Tms/BO';
import { ValidData, callAxios, checkItems } from '../../tmsutil';

const currMenu = '/Back/Diff';
const dftHeight = 1000;

type PageFunc = 'DftSubj' | 'SendOrders' | 'Data';

type DiffTab = {
  Id: number;
  vhId: string;
  stId: string;
  prodId: string;
  prodNm: string;
  rstr: PosRstrTy;
  currw: number;
  tgtw: number;
  ordw: number;
  ordAmt: number;
  ordQty: number;
  avail: boolean;
  ordProdId?: string | null;
};

export const diffTabSchema = z.object({
  Id: z.coerce.number(),
  vhId: z.string(),
  stId: z.string(),
  prodId: z.string(),
  prodNm: z.string().readonly(),
  rstr: posRstrTySchema,
  currw: z.coerce.number(),
  tgtw: z.coerce.number(),
  ordw: z.coerce.number(),
  ordAmt: z.coerce.number(),
  ordQty: z.coerce.number(),
  avail: z.coerce.boolean().nullish(),
  ordProdId: z.string().nullish(),
});

enum DiffTy {
  TgtDiff = 'TgtDiff', // 타겟 펀드와 디프 조정
  SelfLev = 'SelfLev', // 자기 자신의 레버리지 조정
}

const vhGrps = [
  VhGrpTy.HFund,
  VhGrpTy.VFund,
  VhGrpTy.AIFund,
  VhGrpTy.HBFund,
  VhGrpTy.Troika,
];

// prettier-ignore
const diffTabColumns = ['vhId',	'stId',	'prodId',	'prodNm',	'rstr',	'currw',	'tgtw',	'ordw',	'ordAmt',	'ordQty',	'avail',	'ordProdId',];
// prettier-ignore
const diffTab: SimpleGridArgs<DiffTab> = {
  headers: ['펀드',	'전략',	'종목코드',	'종목명',	'제한',	'비중',	'타겟비중',	'주문비중',	'주문액(백만)',	'예상수량',	'가능',	'주문대체',],
  widths: {prodNm: 200,	currw: 60,	tgtw: 60,	ordw: 60,	ordAmt: 70,	ordQty: 70,	ordProdId: 60,},
  checkboxes: ['avail'],
  meta: {
    dftColWidth: 60,
    useGlobalFilter: true,
    height: dftHeight,
  },
  schema: diffTabSchema,
};

export default function Diff() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [vhGrp, setVhGrp] = useState<VhGrpTy>(VhGrpTy.HFund);
  const [dftVhs, setDftVhs] = useState<string[]>([]);
  const [vhIn, setVhIn] = useState<string[]>([]);
  const [vhEx, setVhEx] = useState<string[]>([]);
  const [vhs, setVhs] = useState<string[]>([]);
  const [dftSts, setDftSts] = useState<string[]>([]);
  const [stIn, setStIn] = useState<string[]>([]);
  const [stEx, setStEx] = useState<string[]>([]);
  const [sts, setSts] = useState<string[]>([]);
  const [prodIn, setProdIn] = useState<string[]>([]);
  const [prodEx, setProdEx] = useState<string[]>([]);
  const [diffTy, setDiffTy] = useState<DiffTy>(DiffTy.TgtDiff);
  const [simpleData, setSimpleData] = useState<DiffTab[] | null>(null);
  const [tgtVhs, setTgtVhs] = useState<string[]>([]);
  const [tgtVh, setTgtVh] = useState<string>('');
  const [tgtSts, setTgtSts] = useState<string[]>([]);
  const [tgtSt, setTgtSt] = useState<string>('');
  const [lev, setLev] = useState(1.0);
  const [tgtYd, setTgtYd] = useState<boolean>(false);
  const [diff, setDiff] = useState(0.1);
  const [availOnly, setAvailOnly] = useState<boolean>(false);
  const [shortAsKSFut, setShortAsKSFut] = useState<boolean>(false);

  const [ord, setOrd] = useState(-99);
  const [hm0, setHm0] = useState('');
  const [hm1, setHm1] = useState('');
  const [group, setGroup] = useState(true);
  const [dgState, setDgState] = useState<DataGridState<DiffTab>>();
  const [noPrcs, setNoPrcs] = useState('');

  // onStateChange error
  // const dftFormatterForDiffTab = (v: SimpleGridDataType, c: keyof DiffTab) => {
  //   if (c === 'ordAmt') {
  //     return Math.round((v as number) * 1e-6);
  //   }
  //   return v;
  // };

  const clearArgs = () => {
    setSimpleData(null); // null : 그리드 안보임 [] : 그리드 보임
  };

  const call = (
    func: PageFunc,
    params: unknown,
    confirmMsg: string,
    onSuccess: (data: ValidData, res: AxiosResponse) => void,
    isGet = true,
  ) =>
    callAxios({
      m,
      logger,
      url: `${currMenu}/${func}`,
      params,
      confirmMsg,
      onSuccess,
      isGet,
    });

  // 트로이카 예외처리 ty: diffTy
  // 셀프조정에 디프조정으로 변경시 테겟 다시 조회
  // diffTy DiffTy.SelfLev 셀프조정인 경우 타겟펀드 타겟전략 disable
  const getDftSubj = () => {
    call('DftSubj', { d, tgt: vhGrp, ty: diffTy }, '', (data) => {
      setDftVhs(data.vhs); // 펀드
      setDftSts(data.sts); // 전략
      if (diffTy === DiffTy.SelfLev) {
        setTgtVh('');
        setTgtSt('');
        setTgtVhs([]);
        setTgtSts([]);
      } else {
        setTgtVhs(data.tgtVhs); // 타겟펀드
        setTgtSts(data.tgtSts); // 타겟전략
      }
    }); // 작업요청
  };

  const getParams = () => {
    const vhst = vhs.join(',');
    const stst = sts.join(',');
    const prodInt = prodIn.join(',');
    const prodExt = prodEx.join(',');
    return {
      d,
      vhs: vhst,
      sts: stst,
      prodIn: prodInt,
      prodEx: prodExt,
      ty: diffTy,
      tgtVh,
      tgtSt,
      lev,
      tgtYd,
      diff,
      availOnly,
      shortAsKSFut,
    };
  };

  const getData = () => {
    const parms = getParams();
    call('Data', parms, '', (data) => {
      setSimpleData(data);
    });
  };

  // 일자 vhgrp 변경시 전체 초기화
  useEffect(() => {
    getDftSubj();
    clearArgs();
  }, [d, vhGrp]);

  // 그리드 초기화
  useEffect(() => {
    clearArgs();
  }, [vhIn, vhEx, stIn, stEx, prodIn, prodEx, tgtVh, tgtSt]);

  useEffect(() => {
    getDftSubj();
  }, [diffTy]);

  useEffect(() => {
    setVhs(vhIn.length ? vhIn : dftVhs.filter((v) => !vhEx.contains(v)));
    if (!tgtVh || !tgtVhs.contains(tgtVh)) {
      if (diffTy === DiffTy.SelfLev) {
        setTgtVh('');
      } else {
        setTgtVh(tgtVhs.length ? tgtVhs[0] : '');
      }
    }
    // 펀드지정시 타겟에 없는 경우 vhs null
    const dftVhIn = dftVhs.filter((v) => vhIn.contains(v));
    if (dftVhs.length !== 0 && vhIn.length !== 0 && dftVhIn.length === 0) {
      setVhs([]);
    }
  }, [diffTy, dftVhs, tgtVhs, vhIn, vhEx]); // diffTy tgtVh 초기값 설정

  useEffect(() => {
    setSts(stIn.length ? stIn : dftSts.filter((v) => !stEx.contains(v)));
    // 전략지정시 타겟에 없는 경우 sts null
    const dftStIn = dftSts.filter((v) => stIn.contains(v));
    if (dftSts.length !== 0 && stIn.length !== 0 && dftStIn.length === 0) {
      setSts([]);
    }
  }, [dftSts, stIn, stEx]); // 초기값은 null

  // prettier-ignore
  const sendOrders = async () => {
    const sltd = (dgState?.selectedObjs ?? []).filter((v) => v.avail);
    if (!checkItems(sltd, m)) return;
    const confirmMsg = `${sltd.length}개 가능 diff 주문 지시 입력?`;

    const flds: (keyof DiffTab)[] = [
      'vhId',
      'stId',
      'prodId',
      'rstr',
      'currw',
      'tgtw',
      'ordAmt',
      'ordProdId',
    ];
    const ords = JSON.stringify(
      sltd.map((r) => Object.fromEntries(flds.map((f) => [f, r[f]]))),
    );

    const params = { d, tgt: vhGrp, diff, ord, t0: hm0, t1: hm1, group, ords };

    call('SendOrders', params, confirmMsg, (data, res) => {
      getData();
      if (data != null) {
        m.alert(`${data} 개 주문 전송`);
      }
      if (res.data.warnings.length) {
        setNoPrcs(`${res.data.warnings.join(',')}`);
      }
    },
    false, // isGet
    );
  };

  const split = (s: string) => {
    if (!s.trim()) return [];
    return s.split(',');
  };

  return (
    <div style={{ minWidth: '1500px' }} className="children-me-2">
      <DateSelector
        value={d}
        onChange={(date) => date !== d && setSearchParams({ d: date })}
      />
      <select
        name="vhGrp"
        value={vhGrp}
        onChange={(e) => setVhGrp(e.target.value as VhGrpTy)}
      >
        {vhGrps.map((vg) => (
          <option key={vg} value={vg}>
            {GetVhGrpName(vg)}
          </option>
        ))}
      </select>
      <hr className="narrow light" />
      <b>펀드</b>&nbsp;
      <input
        name="vhIn"
        size={10}
        placeholder="지정"
        value={vhIn.join(',')}
        onChange={(e) => setVhIn(split(e.target.value))}
      />
      <input
        name="vhEx"
        size={10}
        placeholder="제외"
        value={vhEx.join(',')}
        onChange={(e) => setVhEx(split(e.target.value))}
      />
      <input name="vhs" size={60} disabled value={vhs.join(',')} />
      <hr className="narrow light" />
      <b>전략</b>&nbsp;
      <input
        name="stIn"
        size={10}
        placeholder="지정"
        value={stIn.join(',')}
        onChange={(e) => setStIn(split(e.target.value))}
      />
      <input
        name="stEx"
        size={10}
        placeholder="제외"
        value={stEx.join(',')}
        onChange={(e) => setStEx(split(e.target.value))}
      />
      <input name="sts" size={60} disabled value={sts.join(',')} />
      <hr className="narrow light" />
      <b>종목</b>&nbsp;
      <input
        name="prodIn"
        size={10}
        placeholder="지정"
        value={prodIn.join(',')}
        onChange={(e) => setProdIn(split(e.target.value))}
      />
      <input
        name="prodEx"
        size={10}
        placeholder="제외"
        value={prodEx.join(',')}
        onChange={(e) => setProdEx(split(e.target.value))}
      />
      <hr className="narrow light" />
      <b>설정</b>&nbsp;
      <select
        name="diffTy"
        value={diffTy}
        onChange={(e) => setDiffTy(e.target.value as DiffTy)}
      >
        <option value={DiffTy.TgtDiff}>디프조정</option>
        <option value={DiffTy.SelfLev}>셀프조정</option>
      </select>
      &nbsp; 타겟펀드:
      <select
        name="tgtVh"
        value={tgtVh}
        disabled={diffTy === DiffTy.SelfLev}
        onChange={(e) => setTgtVh(e.target.value)}
      >
        {diffTy === DiffTy.SelfLev && <option> </option>}
        {tgtVhs.map((vh) => (
          <option key={vh} value={vh}>
            {vh}
          </option>
        ))}
      </select>
      &nbsp; 타겟전략:
      <select
        name="tgtSt"
        value={tgtSt}
        disabled={diffTy === DiffTy.SelfLev}
        onChange={(e) => setTgtSt(e.target.value)}
      >
        <option> </option>
        {tgtSts.map((st) => (
          <option key={st} value={st}>
            {st}
          </option>
        ))}
      </select>
      &nbsp; Lev:
      <NumericInput
        name="lev"
        width="60"
        value={lev ?? 1.0}
        onChange={(v) => setLev(v ?? 1.0)}
      />
      <label htmlFor="tgtYd">
        <input
          type="checkbox"
          id="tgtYd"
          onChange={(e) => setTgtYd(e.target.checked)}
        />
        전일마감타겟
      </label>{' '}
      &nbsp; Diff:
      <NumericInput
        name="diff"
        width="60"
        value={diff ?? 0.1}
        onChange={(v) => setDiff(v ?? 0.1)}
      />
      <label htmlFor="availOnly">
        <input
          type="checkbox"
          id="availOnly"
          onChange={(e) => setAvailOnly(e.target.checked)}
        />
        주문가능만
      </label>{' '}
      &nbsp;
      <label htmlFor="shortAsKSFut">
        <input
          type="checkbox"
          id="shortAsKSFut"
          onChange={(e) => setShortAsKSFut(e.target.checked)}
        />
        국내숏 주식선물로
      </label>{' '}
      &nbsp;
      <button type="button" className={DftBtnStyle} onClick={() => getData()}>
        디프 조회
      </button>
      &nbsp;&nbsp;
      <span className="alert alert-info alert-slim">(현재비중)</span>
      <hr className="narrow light" />
      <b>설정</b>&nbsp; ord:{' '}
      <NumericInput
        name="ord"
        width="60"
        value={ord}
        onChange={(v) => setOrd(v ?? -99)}
      />
      t0:
      <input
        type="text"
        name="hm0"
        size={4}
        placeholder="즉시"
        value={hm0}
        onChange={(e) => setHm0(e.target.value)}
      />
      t1:
      <input
        type="text"
        name="hm1"
        size={4}
        placeholder="종료"
        value={hm1}
        onChange={(e) => setHm1(e.target.value)}
      />
      <label htmlFor="groupOst">
        <input
          type="checkbox"
          id="groupOst"
          checked={group}
          onChange={(e) => setGroup(e.target.checked)}
        />
        그룹화
      </label>{' '}
      &nbsp;
      <button
        type="button"
        className={DftBtnStyle}
        onClick={() => sendOrders()}
      >
        선택 주문 전송
      </button>
      &nbsp;&nbsp;
      <span className="alert alert-info alert-slim">
        (매매제한 및 예상수량 0.8 미만은 주문안나감)
      </span>
      <hr className="narrow light" />
      {noPrcs && <div className="alert alert-danger">{noPrcs}</div>}
      {simpleData && (
        <SimpleGrid
          data={simpleData}
          columns={diffTabColumns as (keyof DiffTab)[]}
          headers={diffTab.headers}
          args={{
            title: diffTab.title,
            meta: diffTab.meta,
            widths: diffTab.widths,
            checkboxes: diffTab.checkboxes,
            onStateChange: setDgState,
          }}
        />
      )}
    </div>
  );
}
