import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { rgb2color } from 'tmslib/src/util/uiutil';
import { callAxiosGet } from '../../tmsutil';

const page = '/Front';

interface Props {
  subPage: string;
  prodId: string;
  prodNm: string;
}

type ErsRes = {
  Id: number;
  d: string;
  cnt: number;
  ch: number;
  eri: number;
  'KEYBANC CAP MKT': string;
  'WEDBUSH SECURIT': string;
  'OPPENHEIMER HLD': string;
};

export function setCol(norm: number) {
  let color: string | undefined;
  let backgroundColor: string | undefined;
  if (norm > 0) backgroundColor = rgb2color(255, 255 - norm, 255 - norm);
  else backgroundColor = rgb2color(255 + norm, 255 + norm, 255);
  if (norm > 150 || norm < -150) color = 'white';
  else color = 'black';

  return [color, backgroundColor];
}

export function setDropCol(c: string, v: number) {
  let color: string | undefined;
  let backgroundColor: string | undefined;
  if (v === null) return [color, backgroundColor];

  const threshold = c === 'er' ? 25 : 35;
  let emp = 6;
  if (['dr0', 'dr1', 'dr2'].indexOf(c) >= 0) {
    emp = 4; // LsPick 칼럼
  }
  if ((v as number) >= 100 - threshold) {
    const norm = Math.round(((v as number) - (100 - threshold)) * emp);
    backgroundColor = rgb2color(255, 255 - norm, 255 - norm);
    if (norm > 120) color = '#fff';
  }
  if ((v as number) <= threshold) {
    const norm = Math.round((threshold - (v as number)) * emp);
    backgroundColor = rgb2color(255 - norm, 255 - norm, 255);
    if (norm > 120) color = '#fff';
  }
  return [color, backgroundColor];
}

export function setDropColUS(v: number) {
  let color: string | undefined;
  let backgroundColor: string | undefined;
  if (v === null) return [color, backgroundColor];

  const threshold = 35;
  const emp = 4;
  if ((v as number) >= 100 - threshold) {
    const norm = Math.round(((v as number) - (100 - threshold)) * emp);
    backgroundColor = rgb2color(255, 255 - norm, 255 - norm);
    if (norm > 120) color = '#fff';
  }
  if ((v as number) <= threshold) {
    const norm = Math.round((threshold - (v as number)) * emp);
    backgroundColor = rgb2color(255 - norm, 255 - norm, 255);
    if (norm > 120) color = '#fff';
  }
  return [color, backgroundColor];
}

const yAxisLsUniv = [
  { title: { text: '주가' } },
  { title: { text: '펀드멘탈' } },
  { title: { text: '개인누적순매수' }, opposite: true },
  { title: { text: '대차잔고' }, opposite: true },
];

const yAxisLsUnivUS = [
  { title: { text: '주가' } },
  { title: { text: '펀드멘탈' } },
];

const chtOpt: Highcharts.Options = {
  chart: { type: 'line', width: 1000, height: 600 },
  credits: { enabled: false },
  title: {
    style: { fontSize: '12px' },
    margin: 0,
  },
  xAxis: { type: 'datetime' },
  legend: { itemStyle: { fontWeight: 'normal' }, margin: 0 },
  plotOptions: {
    line: {
      marker: { enabled: false },
      shadow: false,
      animation: false,
      lineWidth: 1,
    },
  },
};

export function LsScoreChartAndTable({ subPage, prodId, prodNm }: Props) {
  const { msgBox: m, logger } = useMessageState();
  const [chtSecoreOpts, setChtSecoreOpts] =
    useState<Highcharts.Options>(chtOpt);

  const [ers, setErs] = useState<ErsRes[]>([]);
  const [brks, setBrks] = useState<string[]>([]);
  const [brkNms, setBrkNms] = useState<string[]>([]);

  useEffect(() => {
    const now = new Date();
    const d0 = now.addMonths(-24).toFormatString('yyyy-MM-dd');
    const url0 =
      subPage === 'LsUniv'
        ? `${page}/${subPage}/ScoreChartAndTable`
        : `${page}/${subPage}/ScoreChartAndTableUS`;

    callAxiosGet({
      m,
      logger,
      url: url0,
      params: { d0, cd: prodId },
      onSuccess: (data) => {
        if (!data.cht.length) return;
        // score chart
        const series = [...data.cht];
        series[0].yAxis = 0;
        series[0].lineWidth = 3;
        series[1].yAxis = 1;
        series[1].lineWidth = 3;
        if (subPage === 'LsUniv') {
          series[2].yAxis = 2;
          series[3].yAxis = 3;
        }
        const yAxis0 = subPage === 'LsUniv' ? yAxisLsUniv : yAxisLsUnivUS;

        setChtSecoreOpts((p) => ({
          ...p,
          title: {
            text: prodNm,
          },
          yAxis: yAxis0,
          series,
        }));
        // score table
        setErs(data.ers);
        setBrkNms(data.brkNms);
        setBrks(data.brks);
      },
    });
  }, [prodId]);

  const dftColWidth0 = subPage === 'LsUniv' ? 45 : 140;

  return (
    <>
      <div>
        <HighchartsReact highcharts={Highcharts} options={chtSecoreOpts} />
      </div>
      <div>
        <SimpleGrid
          data={ers}
          // prettier-ignore
          columns={['d', ...brks.map((v) => v as keyof ErsRes), 'cnt', 'ch', 'eri']}
          // prettier-ignore
          headers={['', ...brkNms.map((v) => v),'리폿수', 'ER', 'INDEX']}
          args={{
            meta: {
              dftColWidth: dftColWidth0,
            },
            // prettier-ignore
            widths: { cnt: 60, ch: 60, eri: 60, 'KEYBANC CAP MKT': 140, "WEDBUSH SECURIT": 140, "OPPENHEIMER HLD": 140 },
            dftStyler: (v, c) => {
              let color: string | undefined;
              let backgroundColor: string | undefined;
              if (v === null) return null;

              if (['cnt', 'ch', 'eri'].indexOf(c) >= 0) {
                backgroundColor = '#dfd';
              }
              if (['cnt', 'eri'].indexOf(c) < 0) {
                color = v?.getSignColor();
              }
              if (c === 'd') backgroundColor = '#eee';
              return { color, backgroundColor };
            },
          }}
        />
      </div>
    </>
  );
}
