import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toNumber } from 'lodash';
import Editor from 'tmslib/src/ui/Editor';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { SimpleGridDataType } from 'tmslib/src/table/SimpleGrid';
import { useAuthState } from '../Auth/AuthContext';
import { DftBtnStyleMx } from '../../AppTypes';
import DateSelector from '../../shared/DateSelector';
import {
  utilGet,
  callAxios,
  UrlGrid,
  UrlGridArgs,
  emptyGridArgs,
} from '../../tmsutil';
import { UnfairRes } from '../../Tms/MO';
import { MemoTy } from '../../Tms/Tms';

const page = '/Middle/Unfair';
const btnClass = DftBtnStyleMx;

const dftFormatter = (v: SimpleGridDataType, c: keyof UnfairRes) => {
  if (['qty', 'buy', 'vol', 'vol20', 'sh'].indexOf(c) >= 0) {
    return v?.toFixedWithComma(0);
  }
  if (['vw', 'bw', 'tvw', 'tbw'].indexOf(c) >= 0) {
    return v?.toFixedWithComma(1);
  }
  return v;
};

const unfairDft: UrlGridArgs<UnfairRes> = {
  url: `${page}/Data`,
  title: '',
  // prettier-ignore
  columns: ['prodId', 'prodNm', 'd', 'qty', 'vol', 'vol20', 'vw'],
  // prettier-ignore
  headers: ['종목코드', '종목명', '날짜', '매매량', '시장거래량', '시장20일평균', '매매비중'],
  widths: {},
  dftFormatter,
  meta: {
    dftColWidth: 90,
    useGlobalFilter: false,
  },
  height: undefined,
  rowSpanFns: {
    prodId: (r0, r1) => r0.original.prodId === r1.original.prodId,
    prodNm: (r0, r1) => r0.original.prodNm === r1.original.prodNm,
  },
  // prettier-ignore
  auxColumns: [
                ['prodId', 'prodNm', 'd', 'buy', 'vol', 'bw'],
                ['prodId', 'prodNm', 'qty', 'sh', 'tvw'],
                ['prodId', 'prodNm', 'buy', 'sh', 'tbw'],
              ],
  // prettier-ignore
  auxHeaders: [
                ['종목코드', '종목명', '매수량', '상장주식수', '매수비중'],
                ['종목코드', '종목명', '매매량', '상장주식수', '매매비중'],
                ['종목코드', '종목명', '매수량', '상장주식수', '매수비중'],
              ],
};

export default function Unfair() {
  const { msgBox: m, logger } = useMessageState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const d = searchParams.get('d') || info?.currBizDay || '';

  const [initMemo, setInitMemo] = useState('');
  const [memo, setMemo] = useState('');
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [gridVws, setGridVws] = useState<UrlGridArgs<UnfairRes>>(emptyGridArgs);
  const [tgtVw, setTgtVw] = useState<number>(30);
  const [tgtBw, setTgtBw] = useState<number>(30);
  const [tgtTvw, setTgtTvw] = useState<number>(5);
  const [tgtTbw, setTgtTbw] = useState<number>(1);

  const unfair: UrlGridArgs<UnfairRes> = {
    ...unfairDft,
    meta: {
      ...unfairDft.meta,
    },
    auxGridArgs: [
      {
        title: '[3일, 30% 이상 연속 매수 관여 종목]',
        titleNode: (
          <b>
            <input
              type="text"
              id="tgtBw"
              defaultValue={tgtBw}
              size={2}
              style={{ textAlign: 'right' }}
              onChange={(e) => {
                setTgtBw(toNumber(e.target.value));
              }}
            />
            %
          </b>
        ),
      },
      {
        title: '[당일매매(매수+매도) 5% 이상 종목]',
        titleNode: (
          <b>
            <input
              type="text"
              id="tgtTvw"
              defaultValue={tgtTvw}
              size={2}
              style={{ textAlign: 'right' }}
              onChange={(e) => setTgtTvw(toNumber(e.target.value))}
            />
            %
          </b>
        ),
      },
      {
        title: '[당일매수 1% 이상 종목]',
        titleNode: (
          <b>
            <input
              type="text"
              id="tgtTbw"
              defaultValue={tgtTbw}
              size={2}
              style={{ textAlign: 'right' }}
              onChange={(e) => setTgtTbw(toNumber(e.target.value))}
            />
            %
          </b>
        ),
      },
    ],
  };

  const getMemo = () => {
    const params = { d, ty: MemoTy.CmplUnfair };
    utilGet(m, logger, 'Memo', params, (data) => setInitMemo(data));
  };

  const getData = () => {
    setGridVws(emptyGridArgs);
    setRefreshNeeded((p) => p + 1);
    setGridVws(unfair);
    getMemo();
  };

  useEffect(() => {
    getData();
  }, [d]);

  return (
    <div className="row" style={{ minWidth: '1500px' }}>
      <div className="col-4">
        <DateSelector
          value={d}
          onChange={(date) => {
            if (date !== d) {
              setSearchParams({ d: date });
            }
          }}
        />
        &nbsp;&nbsp;
        <button type="button" className={btnClass} onClick={() => getData()}>
          조회
        </button>
      </div>
      <hr className="narrow light" />
      <div>
        <b>
          [거래량 30% 초과매매제한]&nbsp;{' '}
          <input
            type="text"
            id="tgtVw"
            value={tgtVw}
            size={2}
            style={{ textAlign: 'right' }}
            onChange={(e) => setTgtVw(toNumber(e.target.value))}
          />
          %
        </b>
        <UrlGrid
          args={gridVws}
          params={{ d, tgtVw, tgtBw, tgtTvw, tgtTbw }}
          refreshNeeded={refreshNeeded}
        />
      </div>
      <div style={{ width: '1200px' }}>
        <b>[검토평]</b>
        <Editor
          value={initMemo}
          onBlur={(html) => setMemo(html)}
          onChange={(html) => setMemo(html)}
          style={{ height: 500 }}
        />
        <button
          type="button"
          className={btnClass}
          onClick={() =>
            callAxios({
              m,
              logger,
              url: '/Util/SaveMemo',
              params: { d, ty: MemoTy.CmplUnfair, memo },
              onSuccess: (data, res) => {
                if (!res.data.warnings?.length) {
                  m.alert('Ok');
                  getMemo();
                } else {
                  m.alert(res.data.warnings);
                }
              },
            })
          }
        >
          Save
        </button>
      </div>
    </div>
  );
}
