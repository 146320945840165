import React, { useState, useEffect, useMemo } from 'react';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';
import DataGrid, { ColumnType, DataGridState } from 'tmslib/src/table/DataGrid';
import { AlgoSlice } from '../../../Tms/Ems';


interface Props {
  slices: AlgoSlice[];
  currSliceId: number | undefined;
  setCurrSliceId: React.Dispatch<React.SetStateAction<number | undefined>>;
  spot?: boolean;
}

const columnHelper = createColumnHelper<AlgoSlice>();
const getColumns = (spot: boolean) =>
  [
    columnHelper.accessor('Id', { header: 'Id', size: 70 }),
    columnHelper.accessor('sliceNo', { header: '#', size: 25 }),
    columnHelper.accessor('vhQtysRepr', { header: '배분', size: 60 }),
    columnHelper.accessor('schedHms', { header: '예정T', size: 60 }),
    columnHelper.accessor('procHms', { header: '처리T', size: 60 }),
    columnHelper.accessor('ls', {
      header: 'LS',
      size: 30,
      meta: {
        styler: (v, r) => ({ color: r.original.sgn.getSignColor() }),
      },
    }),
    columnHelper.accessor('ex', {
      header: 'EX',
      size: 30,
      meta: {
        styler: (v, r) => ({ color: r.original.sgn.getSignColor() }),
      },
    }),
    columnHelper.accessor('acctId', { header: '계좌', size: 70 }),
    columnHelper.accessor('executor', { header: '채널' }),
    columnHelper.accessor('byBrk', {
      header: '브',
      size: 30,
      meta: {
        type: ColumnType.Checkbox,
      },
    }),
    columnHelper.accessor('qty2ord', {
      header: '예정량',
      meta: { formatter: (v) => v?.toFixedWithComma(0) },
    }),
    columnHelper.accessor('price', {
      header: '가격',
      meta: { formatter: (v) => v?.toFixedWithComma(0) },
    }),
    columnHelper.accessor('ClOrdID', { header: '주문번호', size: 100 }),
    columnHelper.accessor('OrdStatus', { header: '상태', size: 60 }),
    columnHelper.accessor('OrderQty', {
      header: '주문량',
      meta: { formatter: (v) => v?.toFixedWithComma(0) },
    }),
    columnHelper.accessor('CumQty', {
      header: '체결량',
      meta: { formatter: (v) => v?.toFixedWithComma(0) },
    }),
    columnHelper.accessor('AvgPx', {
      header: '평단가',
      size: 70,
      meta: { formatter: (v) => v?.toFixedWithComma(0) },
    }),
    columnHelper.accessor('LeavesQty', {
      header: '미체결',
      meta: { formatter: (v) => v?.toFixedWithComma(0) },
    }),
    columnHelper.accessor('Done', {
      header: '완료',
      size: 30,
      meta: {
        type: ColumnType.Checkbox,
      },
    }),
    columnHelper.accessor('lessQtyRsn', {
      header: '덜주문',
      size: 50,
    }),
    columnHelper.accessor('ErrorMsg', {
      header: '에러',
      meta: {
        styler: () => ({ color: 'red' }),
      },
    }),
    columnHelper.accessor('condFailHms', { header: '시도T' }),
    columnHelper.accessor('ordMsg', { header: '지시', size: 50 }),
    columnHelper.accessor('infoMsg', { header: '참고', size: 50 }),
    columnHelper.accessor('acctMatch', { header: '계좌매칭', size: 70 }),
    spot ? null : columnHelper.accessor('nRepl', { header: 'n', size: 25 }),
    spot
      ? null
      : columnHelper.accessor('plcStrgDisp', { header: 'Pl', size: 25 }),
    spot
      ? null
      : columnHelper.accessor('DeadlineHms', { header: 'Deadline', size: 60 }),
    spot
      ? null
      : columnHelper.accessor('doneHms', { header: 'DoneT', size: 60 }),
    spot
      ? null
      : columnHelper.accessor('DoneElapsed', { header: '소요', size: 30 }),
    spot
      ? null
      : columnHelper.accessor('enforceRsnDisp', {
          header: 'En',
          size: 25,
        }),
    spot
      ? null
      : columnHelper.accessor('replRsnDisp', { header: 'Re', size: 25 }),
    spot ? null : columnHelper.accessor('CostTick', { header: 'cost' }),
  ]
    .filter((v) => v)
    .map((v) => v as ColumnDef<AlgoSlice, unknown>);

export default function AlgoSliceTab({
  slices,
  currSliceId,
  setCurrSliceId,
  spot,
}: Props) {
  const [dgState, setDgState] = useState<DataGridState<AlgoSlice>>();

  useEffect(() => {
    if (dgState?.currentObj?.Id !== currSliceId) {
      setCurrSliceId(dgState?.currentObj?.Id);
    }
  }, [currSliceId, setCurrSliceId, dgState]);

  const cols = useMemo(() => getColumns(spot ?? false), [spot]);
  const meta: TableMeta<AlgoSlice> = { height: spot ? 0 : 800 };
  return (
    <DataGrid
      data={slices}
      columns={cols}
      meta={meta}
      onStateChange={setDgState}
    />
  );
}
