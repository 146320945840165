import React, { useEffect, useState } from 'react';
import NumericInput from 'tmslib/src/ui/NumericInput';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { BorrowState } from '../../../Tms/Loan';
import { callAxios } from '../../../tmsutil';

interface Props {
  state: BorrowState | null;
}

const dftFeeCnfrmMin = 100;
const dftFeeEnforceMax = 0;
export default function LoanStateBar({ state }: Props) {
  const [doQuote, setDoQuote] = useState<boolean>(state?.doQuote ?? true);
  const [feeCnfrmMin, setFeeCnfrmMin] = useState<number>(
    state?.feeCnfrmMin ?? dftFeeCnfrmMin,
  );
  const [feeEnforceMax, setFeeEnforceMax] = useState<number>(
    state?.feeEnforceMax ?? dftFeeEnforceMax,
  );
  const { msgBox: m, logger } = useMessageState();

  const call = (func: string, params: unknown) =>
    callAxios({
      m,
      logger,
      url: `/PBS/LoanReq/${func}`,
      params,
    });

  const updateDoQuote = (quote: boolean) => {
    setDoQuote(quote);
    call('SetDoQuote', { quote });
  };

  const updateFeeCnfrmMin = (fee: number | null) => {
    if (fee == null) {
      m.alert('빈 입력값 불가');
      return;
    }
    setFeeCnfrmMin(fee);
    call('SetFeeCnfrmMin', { fee });
  };

  const updateFeeEnforceMax = (fee: number | null) => {
    if (fee == null) {
      m.alert('빈 입력값 불가');
      return;
    }
    setFeeEnforceMax(fee);
    call('SetFeeEnforceMax', { fee });
  };

  useEffect(() => setDoQuote(state?.doQuote ?? true), [state?.doQuote]);
  useEffect(
    () => setFeeCnfrmMin(state?.feeCnfrmMin ?? dftFeeCnfrmMin),
    [state?.feeCnfrmMin],
  );
  useEffect(
    () => setFeeEnforceMax(state?.feeEnforceMax ?? dftFeeEnforceMax),
    [state?.feeEnforceMax],
  );

  return (
    <>
      <label htmlFor="doQuote">
        <input
          type="checkbox"
          id="doQuote"
          checked={doQuote}
          onChange={(e) => updateDoQuote(e.target.checked)}
        />
        타사 요율 재문의
      </label>
      &nbsp;&nbsp;&nbsp;&nbsp; 매니저 컨펌 요율(%):&nbsp;
      <NumericInput
        name="cnfrmMin"
        width="50"
        value={feeCnfrmMin}
        onChange={(v) => updateFeeCnfrmMin(v)}
        placeholder={dftFeeCnfrmMin.toString()}
      />
      &nbsp;&nbsp;&nbsp;&nbsp; 강제 진행 요율(%):&nbsp;
      <NumericInput
        name="enforceMax"
        width="50"
        value={feeEnforceMax}
        onChange={(v) => updateFeeEnforceMax(v)}
        placeholder={dftFeeEnforceMax.toString()}
      />
    </>
  );
}
