import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toNumber } from 'lodash';
import Editor from 'tmslib/src/ui/Editor';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import { DftBtnStyleMx } from '../../AppTypes';
import { callAxiosGet, utilGet, callAxios } from '../../tmsutil';
import DateSelector from '../../shared/DateSelector';
import {
  LongShortTogether,
  NetExposure,
  UStkFillPrc,
  IpoLockup,
  EtfDevCorr,
} from '../../Tms/MO';
import { OrdBanGrp } from '../../Tms/Ord';
import { MemoTy } from '../../Tms/Tms';

const currMenu = '/Middle/Review';
const btnClass = DftBtnStyleMx;
type PageFunc =
  | 'LongShortTogether'
  | 'NetExposure'
  | 'UStkFillPrc'
  | 'AiBan'
  | 'IpoLockup'
  | 'EtfDevCorr';

export default function Review() {
  const { msgBox: m, logger } = useMessageState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [longShortTogether, setLongShortTogether] = useState<
    LongShortTogether[]
  >([]);
  const [netExposure, setNetExposure] = useState<NetExposure[]>([]);
  const [netExposureTgt, setNetExposureTgt] = useState<number>(100);

  const [uStkFillPrc, setUStkFillPrc] = useState<UStkFillPrc[]>([]);
  const [ustkFillPrcTgt, setUstkFillPrcTgt] = useState<number>(10);

  const [aiBan, setAiBan] = useState<OrdBanGrp[]>([]);
  const [ipoLockup, setIpoLockup] = useState<IpoLockup[]>([]);
  const [ipoDetail, setIpoDetail] = useState<boolean>(false);
  const [etfDevCorr, setEtfDevCorr] = useState<EtfDevCorr[]>([]);
  const [initMemo, setInitMemo] = useState('');
  const [memo, setMemo] = useState('');

  const callGet = (funcNm: PageFunc, params: unknown) =>
    callAxiosGet({
      m,
      logger,
      url: `${currMenu}/${funcNm}`,
      params,
      onSuccess: (data) => {
        if (data) {
          switch (funcNm) {
            case 'LongShortTogether':
              setLongShortTogether(data);
              break;
            case 'NetExposure':
              setNetExposure(data);
              break;
            case 'UStkFillPrc':
              setUStkFillPrc(data);
              break;
            case 'AiBan':
              setAiBan(data);
              break;
            case 'IpoLockup':
              setIpoLockup(data);
              break;
            case 'EtfDevCorr':
              setEtfDevCorr(data);
              break;
            default:
              m.alert('함수명 없음');
              break;
          }
        }
      },
    });

  const getData = () => {
    callGet('LongShortTogether', { d });
    callGet('NetExposure', { d, tgt: netExposureTgt });
    callGet('UStkFillPrc', { d, tgt: ustkFillPrcTgt });
    callGet('AiBan', { d });
    callGet('IpoLockup', { d, detail: ipoDetail });
    callGet('EtfDevCorr', { d });
  };

  const getMemo = () => {
    const params = { d, ty: MemoTy.CmplReview };
    utilGet(m, logger, 'Memo', params, (data) => setInitMemo(data));
  };

  useEffect(() => {
    getData();
    getMemo();
  }, [d, netExposureTgt, ustkFillPrcTgt, ipoDetail]);

  return (
    <div className="row children-mb-3" style={{ minWidth: '1500px' }}>
      <div className="col-4">
        <DateSelector
          value={d}
          onChange={(date) => {
            if (date !== d) {
              setSearchParams({ d: date });
            }
          }}
        />
        &nbsp;&nbsp;
        <button type="button" className={btnClass} onClick={() => getData()}>
          조회
        </button>
      </div>
      <hr className="narrow light" />
      <div>
        <b>[동일종목 양건 포지션 점검]</b>
        <SimpleGrid
          data={longShortTogether}
          headers={['펀드', '종목코드', '종목명', '롱', '숏']}
          columns={['vhId', 'prodId', 'prodNm', 'l', 's']}
          args={{ meta: { dftColWidth: 100 } }}
        />
      </div>
      <div>
        <b>
          [Net Exposure]&nbsp;{' '}
          <input
            type="text"
            id="netExposureTgt"
            value={netExposureTgt}
            size={2}
            style={{ textAlign: 'right' }}
            onChange={(e) => setNetExposureTgt(toNumber(e.target.value))}
          />
          %
        </b>
        <SimpleGrid
          data={netExposure}
          headers={['펀드', '전략', 'Exposure']}
          columns={['vhId', 'stId', 'exp']}
          args={{ meta: { dftColWidth: 100 } }}
        />
      </div>
      <div>
        <b>
          [해외주식 주문 가격]&nbsp;{' '}
          <input
            type="text"
            id="ustkFillPrcTgt"
            value={ustkFillPrcTgt}
            size={2}
            style={{ textAlign: 'right' }}
            onChange={(e) => setUstkFillPrcTgt(toNumber(e.target.value))}
          />
          %
        </b>
        <SimpleGrid
          data={uStkFillPrc}
          // prettier-ignore
          headers={['펀드', '전략',  '종목코드', '종목명', '구분', '전일종가', '체결가', 'VWAP', '비율']}
          // prettier-ignore
          columns={['vhId', 'stId', 'prodId', 'prodNm', 'isBuy', 'b', 'prc', 'vwap', 'rt']}
          args={{
            widths: { prodNm: 250, isBuy: 50 },
            meta: { dftColWidth: 80 },
          }}
        />
      </div>
      <div>
        <b>[AI 매매금지종목 모니터링]</b>
        <SimpleGrid
          data={aiBan}
          // prettier-ignore
          headers={['종목코드', '종목명', '그룹', '지정펀드', '전략', '방향', '메모', '시작일', '마지막일', '입력자']}
          // prettier-ignore
          columns={['prodId', 'prodNm', 'grp', 'vhId', 'stId', 'side', 'memo', 'd0', 'd1', 'operNm']}
          args={{
            widths: { prodNm: 150, memo: 250 },
            meta: { dftColWidth: 80 },
          }}
        />
      </div>
      <div>
        <b>[IPO 의무보유 확약]&nbsp; </b>
        <label htmlFor="ipoDetail">
          <input
            type="checkbox"
            id="ipoDetail"
            onChange={(e) => setIpoDetail(e.target.checked)}
          />
          상세
        </label>
        <SimpleGrid
          data={ipoLockup}
          // prettier-ignore
          headers={['종목코드', '종목명', '펀드코드', '시작일', '해제일', '확약주수', '현재주수', '차이']}
          // prettier-ignore
          columns={['prodId', 'prodNm', 'vhId', 'd0', 'd1', 'qty', 'pos', 'diff']}
          args={{
            widths: { prodNm: 250 },
            meta: { dftColWidth: 80 },
            dftStyler: (v, c, r) => {
              if (c === 'diff') {
                const val = r.original.pos - r.original.qty;
                if (val < 0) return { backgroundColor: 'red', color: 'white' };
                return null;
              }
              return null;
            },
          }}
        />
      </div>
      <div>
        <b>[ETF 모니터링]</b>
        <SimpleGrid
          data={etfDevCorr}
          // prettier-ignore
          headers={['펀드', '괴리율(%)', '장마감괴리율(%)', '1년 상관계수']}
          // prettier-ignore
          columns={['vhId', 'dev', 'devClose', 'corr']}
          args={{
            widths: { devClose: 150 },
            meta: { dftColWidth: 100 },
          }}
        />
      </div>
      <div style={{ width: '1000px' }}>
        <b>[검토평]</b>
        <Editor
          value={initMemo}
          onBlur={(html) => setMemo(html)}
          onChange={(html) => setMemo(html)}
          style={{ height: 150 }}
        />
        <button
          type="button"
          className={btnClass}
          onClick={() =>
            callAxios({
              m,
              logger,
              url: '/Util/SaveMemo',
              params: { d, ty: MemoTy.CmplReview, memo },
              onSuccess: (data, res) => {
                if (!res.data.warnings?.length) {
                  m.alert('Ok');
                  getMemo();
                } else {
                  m.alert(res.data.warnings);
                }
              },
            })
          }
        >
          Save
        </button>
      </div>
    </div>
  );
}
