import React, { useState, useEffect } from 'react';
import { TableMeta } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';
import { ifesleExpr } from 'tmslib/src/util/utils';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import {
  UrlGrid,
  UrlGridArgs,
  emptyGridArgs,
  callAxios,
  checkItems,
} from '../../tmsutil';
import '../../App.scss';

import {
  Ctry,
  ctrySchema,
  HolidaySession,
  holidaySessionSchema,
  Holiday,
  holidaysSchema,
  DateInfo,
  dateInfoSchema,
  VhGrpTy,
  GetVhGrpName,
  DateInfo2,
} from '../../Tms/Tms';
import {
  AstMrgnRto,
  astMrgnRtoSchema,
  Product,
  productSchema,
  ProdDaily,
  prodDailySchema,
  ProdUserDaily,
  prodUserDailySchema,
  ProdBatch,
  prodBatchSchema,
  ProdAction,
  prodActionSchema,
  ProdCashDiv,
  prodCashDivSchema,
  BpvHist,
  bpvHistSchema,
  FutInfo,
  futInfoSchema,
} from '../../Tms/Prod';
import {
  IdxMemb,
  idxMembSchema,
  CorpAction,
  corpActionSchema,
  UCorpAction,
  uCorpActionSchema,
} from '../../Tms/MK';

import { PosBookTy } from '../../Tms/Common';
import { OrdSsBanEvt, ordSsBanEvtSchema } from '../../Tms/Ord';

import DateSelector from '../../shared/DateSelector';
import { DftBtnStyleMx, DftLinkBtnStyle } from '../../AppTypes';
import ButtonWithHelp from '../../shared/ButtonWithHelp';

const currMenu = '/Back/Market';
const dftHeight = 900;

type PageFunc =
  | 'UpdateNewKrCd'
  | 'GenBoCashDiv'
  | 'SetBoCashDivRcvd'
  | 'SetOrdSsBanEvt'
  | 'UpdateBbYstdPrc'
  | 'GenBoCashDiv'
  | 'SetBoCashDivRcvd'
  | 'UpdateProduct';

// #region 해외
// prettier-ignore
const ctry: UrlGridArgs<Ctry> = {
  url: `${currMenu}/Ctry`,
  title: '국가별 주식시장',
  columns: ['Id', 'nm', 'tzi', 'tzdb', 'exch_t0', 'exch_t1', 'kor_t0', 'kor_t1', 'exch_lbt0', 'exch_lbt1', 'kor_lbt0', 'kor_lbt1'],
  headers: ['Id', '국가명', '거래소 타임존', '거래소 타임존2', '장시작(현지)', '장종료(현지)', '장시작(한국)', '장종료(한국)',
    '점심시작(현지)', '점심종료(현지)', '점심시작(한국)', '점심종료(한국)'],
  widths: {
    nm: 80,
    tzi: 150,
    tzdb: 120,
    exch_t0: 80,
    exch_t1: 80,
    exch_lbt0: 80,
    exch_lbt1: 80,
    kor_t0: 90,
    kor_t1: 90,
    kor_lbt0: 90,
    kor_lbt1: 90,
  },
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: ctrySchema,
};

// prettier-ignore
const holidaySession: UrlGridArgs<HolidaySession> = {
  url: `${currMenu}/HolidaySession`,
  title: '국가별/일별 장시간 조정',
  columns: ['d', 'ctry', 'note', 'exch_t0', 'exch_t1', 'kor_t0', 'kor_t1'],
  headers: ['날짜', '국가', '메모', '장시작(현지)', '장종료(현지)', '장시작(한국)', '장종료(한국)'],
  widths: {
    ctry: 80,
    note: 120,
    exch_t0: 90,
    exch_t1: 90,
    kor_t0: 90,
    kor_t1: 90
  },
  editable: true,
  height: dftHeight,
  schema: holidaySessionSchema,
};

// prettier-ignore
const futInfo: UrlGridArgs<FutInfo> = {
  url: `${currMenu}/FutInfo`,
  title: '해외 선물 정보',
  columns: ['Id', 'nm', 'under', 'tics', 'crncy', 'bpv', 'tickSz',
    'bbId', 'bbMkt', 'nd_t0', 'nd_t1', 'KIdp', 'p0', 'p1', 'skipKisp'],
  headers: ['Root', '종목명', '기초자산', '섹터', '통화', 'BPV', '틱사이즈',
    '블룸 앞', '블룸 뒤', 'T+1시작', 'T+1종료', '한투시세소수점', '하한', '상한', '한투제외'],
  widths: { nm: 100 },
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: futInfoSchema,
};

// prettier-ignore
const uCorpAction: UrlGridArgs<UCorpAction> = {
  url: `${currMenu}/UCorpAction`,
  title: '해외 CA',
  columns: ['IdxId', 'evtKey', 'prodId', 'prodNm', 'nd', 'lud', 'effd', 'status', 'actionTy', 'actionDesc',
    'div', 'indexShare', 'PES', 'TSO', 'closePrc', 'adjf', 'adjfheld', 'adjPrc', 'cashAdjPrc', 'memo'],
  headers: ['IdxId', '이벤트키', '종목코드', '종목명', '첫공시일', '마지막업뎃일', '발효일', '상태', 'CA타입', 'CA이름',
    '주당배당금', 'Idx주식수(전)', 'Idx주식수(후)', 'TSO', '종가', '조정계수', '조정계수held', '수정가격', '현금수정가격', '참고'],
  widths: {
    prodNm: 200,
    actionTy: 100,
    actionDesc: 100,
    nd: 80,
    lud: 80,
    effd: 80,
    indexShare: 100,
    PES: 100,
    TSO: 100,
    memo: 180,
  },
  editable: false,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: uCorpActionSchema,
};

// #endregion

// #region 종목
// prettier-ignore
const productDft: UrlGridArgs<Product> = {
  url: `${currMenu}/Product`,
  title: '종목',
  columns: ['Id', 'nm', 'krCd', 'listd', 'ltd', 'under', 'prcCd', 'noMTM', 'trdbl', 'trdUnit',
    'stkTy', 'cusTy', 'ventNewTy', 'rightTy', 'tics', 'gics', 'wics',
    'exch', 'crncy', 'quoteUnit', 'bbTkr', 'mafs', 'bpv', 'fm', 'vent_d0', 'vent_d1', 'isin',
    'addedt', 'src', 'exchSym'],
  headers: ['Id', '종목명', 'KR코드', '상장일', '만기일', '기초자산', '가격코드', '비평가', '거래가능', '거래단위',
    '주식구분', '사용자구분', '벤처신주', '권리구분', 'TICS', 'GICS', 'WICS',
    '거래소', '통화', '호가단위', '블룸버그', '미래', 'BPV', '결산월', '벤처지정일', '벤처해제일', 'ISIN',
    '입력T', '소스', '거래소심볼'],
  widths: {
    Id: 80, nm: 200, krCd: 100, listd: 80, ltd: 80, gics: 80, wics: 80,
    under: 80, prcCd: 80, bbTkr: 100, mafs: 100, vent_d0: 80, vent_d1: 80,
    isin: 100, addedt: 120, src: 100, exchSym: 80,
  },
  editable: true,
  meta: { dftColWidth: 60 },
  height: dftHeight,
  schema: productSchema,
};

// #endregion

// #region 가격
// prettier-ignore
const prodDaily: UrlGridArgs<ProdDaily> = {
  url: `${currMenu}/ProdDaily`,
  title: '일 종가',
  columns: [
    'prodId', 'prodNm', 'b', 'o', 'h', 'l', 'c', 'stl', 'v', 'ta', 'sh', 'mc', 'src',],
  headers: [
    '종목코드', '종목명', '기준시가', '시가', '고가', '저가', '종가', '정산가', '거래량', '거래대금', '주식수', '시가총액', '소스',],
  widths: {},
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: prodDailySchema,
};

// prettier-ignore
const mktWeekly: UrlGridArgs<ProdUserDaily> = {
  url: `${currMenu}/MktWeekly`,
  title: 'Weekly',
  columns: ['prodId', 'prodNm', 'val'],
  headers: ['지표코드', '지표명', '종가'],
  widths: { val: 100, },
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: prodUserDailySchema,
};

// prettier-ignore
const prodBatchThBaseP: UrlGridArgs<ProdBatch> = {
  url: `${currMenu}/ProdBatchThBaseP`,
  title: '선물이론가',
  columns: ['prodId', 'prodNm', 'thBaseP', 'exDiv'],
  headers: ['종목코드', '종목명', '기준이론가격', '배당락후 배당가치'],
  widths: { thBaseP: 100, exDiv: 100, },
  editable: false,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: prodBatchSchema,
};

// prettier-ignore
const prodBatchSh: UrlGridArgs<ProdBatch> = {
  url: `${currMenu}/ProdBatchSh`,
  title: '신규종목 상장주식수',
  columns: ['prodId', 'prodNm', 'sh'],
  headers: ['종목코드', '종목명', '상장주식수'],
  widths: { sh: 120, },
  editable: false,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: prodBatchSchema,
};

// prettier-ignore
const prodAction: UrlGridArgs<ProdAction> = {
  url: `${currMenu}/ProdAction`,
  title: '시장 조치',
  columns: ['d', 'prodId', 'prodNm', 'actTy', 'd0', 'd1', 't0', 't1'],
  headers: ['날짜', '종목코드', '종목명', '타입', '지정일', '적용마지막일', '시작시간', '종료시간',],
  widths: { actTy: 100, d0: 100, d1: 100, t0: 70, t1: 70 },
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: prodActionSchema,
  infoMsg: `VI: 변동성완화, OverHeat: 단기과열, Illiquid: 저유동성,
        AbnormalJump: 이상급등, OverSellShort: 공매도과열,
        Caution:투자주의, Warn: 투자경고, Danger: 투자위험, Supervised: 관리종목,
        Suspension: 거래정지, OpenBaseP: 시가기준가, SinglePrice: 단일가,
        insuffSh:상장주식수부족, Liquidation: 정리매매, CautionAtt: 투자주의환기,
        NoCollateral: 담보불가 (사용자 입력)`,
};
// prettier-ignore
const aiShares: UrlGridArgs<ProdUserDaily> = {
  url: `${currMenu}/AiShares`,
  title: '비상장 발행주식수',
  columns: ['d', 'prodId', 'prodNm', 'val'],
  headers: ['적용시작일', '종목코드', '종목명', '발행주식수'],
  widths: { val: 100 },
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: prodUserDailySchema,
};
// #endregion

// #region 배당,지수
// prettier-ignore
const prodCashDiv: UrlGridArgs<ProdCashDiv> = {
  url: `${currMenu}/ProdCashDiv`,
  title: '현금배당 매매기준일',
  columns: ['book', 'prodId', 'prodNm', 'div', 'ind', 'bookd', 'payd'],
  headers: ['북', '종목코드', '종목명', '주당배당금', '매매기준일', '배당반영일', '수령일',],
  widths: { book: 60, div: 80, ind: 80, bookd: 80, payd: 80 },
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: prodCashDivSchema,
};

// prettier-ignore
const idxMemb: UrlGridArgs<IdxMemb> = {
  url: `${currMenu}/IdxMemb`,
  title: '구성종목',
  columns: ['idxId', 'memId', 'memNm', 'liqmc', 'liqrt', 'wei'],
  headers: ['지수코드', '종목코드', '종목명', '유동시총', '유동비율%', '비중%'],
  widths: { liqmc: 100, liqrt: 100, wei: 100, memNm: 150, },
  editable: true,
  height: dftHeight,
  schema: idxMembSchema,
};
// #endregion

// #region 기타

// prettier-ignore
const astMrgnRto: UrlGridArgs<AstMrgnRto> = {
  url: `${currMenu}/AstMrgnRto`,
  title: '자산별 증거금 비율',
  columns: ['ast', 'astNm', 'rto'],
  headers: ['자산코드', '자산명', '비율(%)'],
  meta: { dftColWidth: 100 },
  widths: {},
  editable: true,
  height: dftHeight,
  schema: astMrgnRtoSchema,
  infoMsg: `KStk: 국내주식, KStkShort: 국내주식숏, KStkSwap: 국내주식스왑, KBond: 국내채권,
        K2F: 코스피선물, KQF: 코스닥선물, KSF: 국내주식선물, KBF: 국내채권선물, KUF: 국내달러선물,
        UStk: 해외주식, UFut: 해외선물`
};

// prettier-ignore
const bpvHist: UrlGridArgs<BpvHist> = {
  url: `${currMenu}/BpvHist`,
  title: '주식 선물 승수',
  columns: ['prodId', 'prodNm', 'd', 'bpv'],
  headers: ['종목코드', '종목명', '기준일', 'bpv'],
  widths: { bpv: 100 },
  editable: true,
  height: dftHeight,
  schema: bpvHistSchema,
};

// prettier-ignore
const holiday: UrlGridArgs<Holiday> = {
  url: `${currMenu}/Holiday`,
  title: '국내외 휴일',
  columns: ['d', 'ctry', 'note'],
  headers: ['날짜', '국가', 'note'],
  widths: { d: 80, ctry: 80, note: 200 },
  editable: true,
  height: dftHeight,
  schema: holidaysSchema,
};

// prettier-ignore
const stockMeet: UrlGridArgs<DateInfo> = {
  url: `${currMenu}/StockMeet`,
  title: '주식팀 미팅 직전 영업일',
  columns: ['d'],
  headers: ['d'],
  widths: {},
  editable: true,
  height: 300,
  schema: dateInfoSchema,
};

const stockMeet2: UrlGridArgs<DateInfo2> = {
  url: `${currMenu}/StockMeet2`,
  title: '미팅일 test',
  columns: ['d'],
  headers: ['d'],
  widths: {},
  editable: true,
  height: 300,
  schema: dateInfoSchema,
  meta: { idType: 'uuid' },
};

// prettier-ignore
const rightIssue: UrlGridArgs<CorpAction> = {
  url: `${currMenu}/RightIssue`,
  title: '유상증자일정',
  columns: ['prodId', 'prodNm', 'evtsubtyDesc', 'stkty', 'notified', 'notifiednd', 'allocatedyd', 'allocated', 'listed', 'listedExp',],
  headers: ['종목코드', '종목명', '이벤트', '주식타입', '공시일(T)', '신주발행 정지공고(T+1)', '권리락(T2-1)', '신주배정기준일(T2)', '신주상장일', '신주상장 예정일',],
  widths: { evtsubtyDesc: 100, stkty: 80 },
  editable: true,
  meta: { dftColWidth: 100 },
  height: dftHeight,
  schema: corpActionSchema,
  infoMsg: `이벤트: 유상증자 주주배정(1), 유상증자 3자배정(2), 유상증자 주주우선배정(3), 유상증자 보통주의 우선주주주배정(4),유상증자 시장상장공모(5), 유상증자 일반공모(6), 유상증자 출자전환(8), 유상증자 (타종목배정)(9)`,
};

// prettier-ignore
const ordSsBanEvt: UrlGridArgs<OrdSsBanEvt> = {
  url: `${currMenu}/OrdSsBanEvt`,
  title: '유증 공매도 금지 관리',
  columns: ['prodId', 'prodNm', 'notified', 'd0', 'd1', 'listd', 'info', 'allowed', 'memo', 'operNm', 'gent',],
  headers: ['종목코드', '종목명', '공시일', '시작일', '종료일', '상장일', '참고', '허용', '메모', '변경자', '업뎃시간',],
  widths: { prodId: 70, prodNm: 120, },
  editable: true,
  height: dftHeight,
  schema: ordSsBanEvtSchema,
};

// #endregion

const productCategories = [
  '전체',
  '국내주식',
  '국내선물',
  '국내채권',
  '해외주식',
  '해외선물',
  '펀드/조합',
  '인덱스',
  '워런트',
  '환율',
  '기타',
];

const vhGrpsForCashDiv = [
  VhGrpTy.HFund,
  VhGrpTy.VFund,
  VhGrpTy.AIFund,
  VhGrpTy.TFIM,
  VhGrpTy.PAFund,
  VhGrpTy.HBFund,
  VhGrpTy.ETF,
  VhGrpTy.CAPAssoc,
  VhGrpTy.Troika,
  VhGrpTy.VLS,
];

const booksForCashDiv = [
  PosBookTy.Cash,
  PosBookTy.KI_KSwap,
  PosBookTy.KI_USwap,
  PosBookTy.SS_KSwap,
  PosBookTy.NH_KSwap,
  PosBookTy.NH_USwap,
  PosBookTy.SH_KSwap,
  PosBookTy.SH_USwap,
  PosBookTy.SS_USwap,
  PosBookTy.MA_KSwap,
  PosBookTy.MA_USwap,
  PosBookTy.KB_KSwap,
  PosBookTy.KB_USwap,
];

type T = { Id: number }; // 임의 IId 타입

export default function Market() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info, user } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const [vhGrpForCashDiv, setVhGrpForCashDiv] = useState(VhGrpTy.HFund);
  const [bookForCashDiv, setBookForCashDiv] = useState(PosBookTy.Cash);
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [incExpired, setIncExpired] = useState(false);
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);
  const [cds, setCds] = useState<string>('');
  const [exAuCd, setExAuCd] = useState<string>('');
  const [newShortCd, setNewShortCd] = useState<string>('');
  const [newKrCd, setNewKrCd] = useState<string>('');
  const [bookd, setBookd] = useState<string>('');

  useEffect(() => setGridArgs(emptyGridArgs), [d]);

  const query = (args: unknown) => {
    setRefreshNeeded((p) => p + 1);
    setGridArgs(args as UrlGridArgs<T>);
  };
  const btnClass = DftBtnStyleMx;
  const linkClass = DftLinkBtnStyle;

  const getButton = (a: unknown) => {
    const { title } = a as UrlGridArgs<T>;
    return (
      <ButtonWithHelp
        key={title}
        helpid={title}
        className={btnClass}
        onClick={() => query(a)}
        label={title}
      />
    );
  };

  const call = (
    func: PageFunc,
    params: unknown,
    whenInvalidMsg?: string | null,
    onSuccess?: () => void,
  ) =>
    callAxios({
      m,
      logger,
      url: `/Back/Market/${func}`,
      whenInvalidMsg,
      params,
      onSuccess,
    });

  const updateBbYstdPrc = () => call('UpdateBbYstdPrc', { d, cds });

  const updateNewKrCd = () => {
    const invalidMsg = ifesleExpr(
      [!exAuCd, 'AU|B코드 빈칸'],
      [!newShortCd, '표준단축코드 빈칸'],
      [!newKrCd, 'KR코드 빈칸'],
    );
    call(
      'UpdateNewKrCd',
      { auCd: exAuCd, newCd: newShortCd, krCd: newKrCd },
      invalidMsg,
    );
  };

  const genBoCashDiv = (tgt: VhGrpTy, book: PosBookTy) =>
    call(
      'GenBoCashDiv',
      { tgt, book, bookd },
      !bookd ? '배당 반영일 빈칸' : null,
    );

  const setBoCashDivRcvd = (tgt: string, book: PosBookTy) =>
    call(
      'SetBoCashDivRcvd',
      { tgt, book, rcvd: bookd },
      !bookd ? '수령일 빈칸' : null,
    );

  const setOrdSsBanEvt = (items: OrdSsBanEvt[], allowed: boolean) => {
    if (!checkItems(items, m, true)) return;
    call('SetOrdSsBanEvt', { id: items[0].Id, allowed }, null, () =>
      setRefreshNeeded((p) => p + 1),
    );
  };

  const ordSsBanEvtMeta: TableMeta<OrdSsBanEvt> = {
    dftColWidth: 100,
    contextMenus: [
      {
        label: '공매도 허용',
        callback: (items: OrdSsBanEvt[]) => setOrdSsBanEvt(items, true),
      },
      {
        label: '공매도 불가',
        callback: (items: OrdSsBanEvt[]) => setOrdSsBanEvt(items, false),
      },
    ],
  };

  const updateProduct = async (items: Product[]) => {
    if (!checkItems(items, m, true)) return;
    if (items[0].Id[0] !== 'U') return;
    const newId = await m.prompt('종목 코드 변경');
    if (newId === '' || newId == null || items[0].Id === newId) {
      m.alert('변경코드 없음');
      return;
    }
    const params = { id: items[0].Id, val: newId };
    call('UpdateProduct', params, null, () => setRefreshNeeded((p) => p + 1));
  };

  let product: UrlGridArgs<Product>;
  if (user?.isDev) {
    product = {
      ...productDft,
      meta: {
        contextMenus: [
          {
            label: '종목 코드 변경',
            callback: (items: Product[]) => updateProduct(items),
          },
        ],
      },
    };
  } else {
    product = {
      ...productDft,
    };
  }

  const getParams = (title: string) => {
    if (title === '전체') return { incExpired, cd_pat: null, pat_neg: false };
    if (title === '국내주식')
      return { incExpired, cd_pat: '^A', pat_neg: false };
    if (title === '국내선물')
      return { incExpired, cd_pat: '^(1|4)', pat_neg: false };
    if (title === '국내채권')
      return { incExpired, cd_pat: '^B', pat_neg: false };
    if (title === '해외주식')
      return { incExpired, cd_pat: '^U', pat_neg: false };
    if (title === '해외선물')
      return { incExpired, cd_pat: '^V', pat_neg: false };
    if (title === '펀드/조합')
      return { incExpired, cd_pat: '^F', pat_neg: false };
    if (title === '인덱스') return { incExpired, cd_pat: '^I', pat_neg: false };
    if (title === '워런트') return { incExpired, cd_pat: '^J', pat_neg: false };
    if (title === '환율') return { incExpired, cd_pat: '^X', pat_neg: false };
    if (title === '기타')
      return { incExpired, cd_pat: '^(A|1|4|B|U|V|F|I|J|X)', pat_neg: true };

    return { d };
  };

  return (
    <div style={{ minWidth: '1200px' }}>
      <DateSelector
        value={d}
        onChange={(date) => date !== d && setSearchParams({ d: date })}
      />
      <hr className="narrow light" />
      <b>해외</b>&nbsp;&nbsp;
      {[ctry, holidaySession, futInfo, uCorpAction].map((v) => getButton(v))}
      <hr className="narrow light" />
      <b>종목</b>&nbsp;&nbsp;
      <label htmlFor="incExpired">
        <input
          id="incExpired"
          type="checkbox"
          checked={incExpired}
          onChange={(e) => setIncExpired(e.target.checked)}
        />
        상폐 포함
      </label>
      {productCategories.map((v) => getButton({ ...product, title: v }))}
      <hr className="narrow light" />
      <b>가격 </b>&nbsp;&nbsp;
      {[
        prodDaily,
        mktWeekly,
        prodBatchThBaseP,
        prodBatchSh,
        prodAction,
        aiShares,
      ].map((v) => getButton(v))}
      &nbsp;|&nbsp;
      <span>
        <label htmlFor="bbYstdPrcCds">
          <input
            id="bbYstdPrcCds"
            placeholder="콤마로 구분"
            value={cds}
            onChange={(e) => setCds(e.target.value)}
          />
        </label>
        <button
          type="button"
          className={linkClass}
          onClick={() => updateBbYstdPrc()}
        >
          (전일 해외 업뎃)
        </button>
      </span>
      <hr className="narrow light" />
      <b>배당</b>&nbsp;&nbsp;
      {getButton(prodCashDiv)}
      &nbsp;
      <select
        name="vhGrpSelect"
        value={vhGrpForCashDiv}
        onChange={(e) => setVhGrpForCashDiv(e.target.value as VhGrpTy)}
      >
        {vhGrpsForCashDiv.map((v) => (
          <option key={v} value={v}>
            {GetVhGrpName(v)}
          </option>
        ))}
      </select>
      <select
        name="bookSelect"
        value={bookForCashDiv}
        onChange={(e) => setBookForCashDiv(e.target.value as PosBookTy)}
      >
        {booksForCashDiv.map((v) => (
          <option key={v} value={v}>
            {v}
          </option>
        ))}
      </select>
      <label htmlFor="bookd">
        <input
          id="bookd"
          placeholder="배당반영/수령일"
          value={bookd}
          size={12}
          onChange={(e) => setBookd(e.target.value)}
        />
      </label>
      <button
        type="button"
        className={linkClass}
        onClick={() => genBoCashDiv(vhGrpForCashDiv, bookForCashDiv)}
      >
        (현금배당 생성)
      </button>
      <button
        type="button"
        className={linkClass}
        onClick={() => setBoCashDivRcvd(vhGrpForCashDiv, bookForCashDiv)}
      >
        (수령일 적용)
      </button>
      <hr className="narrow light" />
      <b>표준코드 업뎃</b> &nbsp;&nbsp;&nbsp;
      <label htmlFor="exAuCd">
        <input
          id="exAuCd"
          placeholder="AU|B코드"
          value={exAuCd}
          size={10}
          onChange={(e) => setExAuCd(e.target.value)}
        />
      </label>
      <label htmlFor="newShortCd">
        <input
          id="newShortCd"
          placeholder="표준단축코드"
          value={newShortCd}
          size={10}
          onChange={(e) => setNewShortCd(e.target.value)}
        />
      </label>
      <label htmlFor="newKrCd">
        <input
          id="newKrCd"
          placeholder="KR코드"
          value={newKrCd}
          size={10}
          onChange={(e) => setNewKrCd(e.target.value)}
        />
      </label>
      <button
        type="button"
        className={btnClass}
        onClick={() => updateNewKrCd()}
      >
        업뎃
      </button>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <b>지수</b>
      {getButton(idxMemb)}
      <hr className="narrow light" />
      <b>기타</b> &nbsp;&nbsp;&nbsp;
      {[
        astMrgnRto,
        bpvHist,
        holiday,
        stockMeet,
        stockMeet2,
        rightIssue,
        { ...ordSsBanEvt, meta: ordSsBanEvtMeta },
      ].map((v) => getButton(v))}
      <hr className="narrow light" />
      <UrlGrid
        args={gridArgs}
        params={getParams(gridArgs.title)}
        refreshNeeded={refreshNeeded}
      />
    </div>
  );
}
