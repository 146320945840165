import React from 'react';

export interface SmallBadgeProps {
  id?: string;
  className?: string;
  fontSize?: string;
  padding?: string;
  title?: string;
  body?: React.ReactNode;
  onClick?: () => void;
}

export default function SmallBadge({
  id,
  className,
  fontSize,
  padding,
  title,
  body,
  onClick,
  children,
}: React.PropsWithChildren<SmallBadgeProps>) {
  return (
    <span
      id={id}
      className={className ?? 'badge badge-pill bg-danger'}
      style={{
        position: 'relative',
        top: '-0.2rem',
        right: '0rem',
        fontSize: fontSize ?? '70%',
        padding: padding ?? '0.2rem',
        height: '12px',
      }}
      title={title}
      onClick={() => onClick?.()}
    >
      {body ?? children}
    </span>
  );
}
