import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  createColumnHelper,
  TableMeta,
  ColumnDef,
} from '@tanstack/react-table';
import DataGrid from 'tmslib/src/table/DataGrid';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import { UrlGrid, UrlGridArgs, emptyGridArgs , callAxios } from '../../tmsutil'; 
import { DftBtnStyleMx } from '../../AppTypes';
import {
  PbsCreditAvail,
  MrgnPos,
  PbsSvc,
  currDebtSvcSchema,
  creditAvailSvcSchema,
  MrgnRto,
  mrgnRtoSchema,
} from '../../Tms/BO';
import DateSelector from '../../shared/DateSelector';

// 입력값
const currMenu = '/Back/DebtAvail';

// prettier-ignore
const currDebt: UrlGridArgs<PbsSvc> = {
    url: `${currMenu}/CurrDebt`,
    title: '現 차입금',
    columns: ['vhId', 'brkId', 'currDebt', 'allocCredit'],
    headers: ['펀드', 'PBS', '現 차입금', '배분'],
    widths: {},
    editable: true,
    meta: { dftColWidth: 100, useGlobalFilter: false },
    height: 350,
    schema: currDebtSvcSchema,
  };

// prettier-ignore
const creditAvail: UrlGridArgs<PbsSvc> = {
    url: `${currMenu}/CreditAvail`,
    title: 'PBS별 잔여한도금액',
    columns: ['brkId', 'creditAvail', 'maxCredit'],
    headers: ['PBS', '잔여한도액', '총한도액'],
    widths: {},
    editable: true,
    meta: { dftColWidth: 100, useGlobalFilter: false },
    height: 350,
    schema: creditAvailSvcSchema,
  };

// prettier-ignore
const mrgnRto: UrlGridArgs<MrgnRto> = {
    url: `${currMenu}/MrgnRto`,
    title: '담보인정비율',
    columns: ['d0', 'd1', 'book', 'ty', 'rto'],
    headers: ['d0', 'd1', '북', '구분', '인정비율'],
    widths: {},
    editable: true,
    meta: { dftColWidth: 70, useGlobalFilter: false },
    height: 350,
    schema: mrgnRtoSchema,
  };

// 차입 가능금
const columnHelper = createColumnHelper<PbsCreditAvail>();
const columns = [
  columnHelper.accessor('vhId', { header: '펀드', size: 50, meta: {} }),
  columnHelper.accessor('pbs', { header: 'PBS', size: 50, meta: {} }),
  columnHelper.accessor('mrgn', {
    header: '총담보인정가액',
    size: 100,
    meta: { formatter: (v) => v.toFixedWithComma(0) },
  }),
  columnHelper.accessor('debt', {
    header: '現 차입금',
    size: 70,
    meta: { formatter: (v) => v.toFixedWithComma(0) },
  }),
  columnHelper.accessor('expdShort0', {
    header: '기존담보 잉여/부족금',
    size: 140,
    meta: { formatter: (v) => v.toFixedWithComma(0) },
  }),
  columnHelper.accessor('toCnclAmt', {
    header: 'T+1일 예상해지금',
    size: 140,
    meta: { formatter: (v) => v.toFixedWithComma(0) },
  }),
  columnHelper.accessor('expdShort', {
    header: '(담보부족+해지)*1.2',
    size: 140,
    meta: { formatter: (v) => v.toFixedWithComma(0) },
  }),
  columnHelper.accessor('availAmt', {
    header: '추가담보 인정가액',
    size: 130,
    meta: { formatter: (v) => v.toFixedWithComma(0) },
  }),
  columnHelper.accessor('expdNet', {
    header: '담보감안금액',
    size: 130,
    meta: {
      formatter: (v) => v.toFixedWithComma(0),
      styler: (v, r) => ({
        color: r.original.expdNet.getSignColorMinusRed(),
      }),
    },
  }),
  columnHelper.accessor('pbsAvail', {
    header: '잔여한도배분금액',
    size: 130,
    meta: { formatter: (v) => v.toFixedWithComma(0) },
  }),
  columnHelper.accessor('finalAvail', {
    header: '최종차입가능금액',
    size: 130,
    meta: {
      formatter: (v: number) => v.toFixedWithComma(0),
      title: (v, r) => {
        if (r.original.pbs === 'KI' || r.original.pbs === 'MA') {
          const limit = r.original.pbs === 'MA' ? 5e9 : 2e10;
          if (r.original.debt + r.original.finalAvail > limit) {
            const limitStr = r.original.pbs === 'MA' ? 50 : 200;
            const finalAvailTitle = `현차입금 + 최종차입가능액 >  ${limitStr} 억`;
            return finalAvailTitle;
          }
        }
        return '';
      },
      styler: (v, r) => {
        if (r.original.pbs === 'KI' || r.original.pbs === 'MA') {
          const limit = r.original.pbs === 'MA' ? 5e9 : 2e10;
          if (r.original.debt + r.original.finalAvail > limit) {
            return { backgroundColor: 'pink' };
          }
        }
        return null;
      },
    },
  }),
].map((v) => v as ColumnDef<PbsCreditAvail, unknown>);

// 종목별 데이터
// prettier-ignore
const prodFlds: (keyof MrgnPos)[] =
  ['prodId',	'prodNm',	'book',	'ty',	'sgdQty',	'prc',	'rto',	'mrgn',
    'h2',	'b1',	's1',	'need_cncl',	'toCnclAmt',	'avail',	'availAmt',	'unavailTy',
  ];

// prettier-ignore
const prodHeaders =
  ['종목코드',	'종목명',	'북',	'구분',	'대용',	'종가',	'인정율',	'인정담보액',
  'T-2잔고',	'T-1매수',	'T-1매도',	'부족수량',	'부족금액',	'가능수량',	'가능금액',	'담보불가',
  ];

const prodWidths = { prc: 60, mrgn: 80, toCnclAmt: 80, availAmt: 80 };
const prodMeta: TableMeta<MrgnPos> = {
  dftColWidth: 70,
  useGlobalFilter: true,
};

type T = { Id: number }; // 임의 IId 타입
export default function DebtAvail() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [data, setData] = useState<PbsCreditAvail[]>([]);
  const [prodData, setDataProd] = useState<MrgnPos[]>([]);
  const [gridCurrDebt, setGridCurrDebt] =
    useState<UrlGridArgs<T>>(emptyGridArgs);
  const [gridCreditAvail, setGridCreditAvail] =
    useState<UrlGridArgs<T>>(emptyGridArgs);
  const [gridMrgnRto, setGridMrgnRto] = useState<UrlGridArgs<T>>(emptyGridArgs);
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [input, setInput] = useState<boolean>(false);

  const meta: TableMeta<PbsCreditAvail> = {
    dftColWidth: 110,
    onRowClick: (r) => {
      setDataProd(r.original.mrgns);
    },
  };

  const clearArgs = () => {
    setInput(false);
    setData([]);
    setDataProd([]);
  };

  const getData = () => {
    callAxios({
      m,
      logger,
      url: `${currMenu}/PbsCreditAvail`,
      params: { d },
      confirmMsg: '',
      onSuccess: (x) => {
        setData(x);
      },
      onBegin: () => clearArgs(),
      isGet: true,
    });
  };

  useEffect(() => getData(), [d]);

  // 버튼 클릭시 조회 / 전체 조회 됨
  const getDataInput = () => {
    setInput(true);
    setRefreshNeeded((p) => p + 1);
    setGridCurrDebt(currDebt as unknown as UrlGridArgs<T>);
    setGridCreditAvail(creditAvail as unknown as UrlGridArgs<T>);
    setGridMrgnRto(mrgnRto as unknown as UrlGridArgs<T>);
  };

  const inputTemplate = () => (
    <>
      <div className="col-3">
        <UrlGrid
          args={gridCurrDebt}
          params={{ d }}
          refreshNeeded={refreshNeeded}
        />
      </div>
      <div className="col-3">
        <UrlGrid
          args={gridCreditAvail}
          params={{ d }}
          refreshNeeded={refreshNeeded}
        />
      </div>
      <div className="col-3">
        <UrlGrid
          args={gridMrgnRto}
          params={{ d }}
          refreshNeeded={refreshNeeded}
        />
      </div>
    </>
  );

  return (
    <div className="children-me-2" style={{ minWidth: '1500px' }}>
      <div>
        <DateSelector
          value={d}
          onChange={(date) => date !== d && setSearchParams({ d: date })}
        />
        <button
          type="button"
          className={DftBtnStyleMx}
          onClick={() => getData()}
        >
          차입가능금
        </button>
        <button
          type="button"
          className={DftBtnStyleMx}
          onClick={() => getDataInput()}
        >
          입력값
        </button>
      </div>
      <div className="row" style={{ minWidth: '1500px' }}>
        <hr className="narrow light" />
        {input && inputTemplate()}
      </div>
      <div>
        <hr className="narrow light" />
        <b>차입 가능금</b>
        <DataGrid data={data} columns={columns} meta={meta} />
        <hr className="narrow light" />
        <b>종목별 데이터</b>
        <SimpleGrid
          data={prodData}
          columns={prodFlds}
          headers={prodHeaders}
          args={{
            meta: prodMeta,
            widths: prodWidths,
          }}
        />
      </div>
    </div>
  );
}
