import React, { useEffect, useState } from 'react';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { arrayTable } from 'tmslib/src/table/tables';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import Button from 'tmslib/src/ui/Button';
import { UrlGrid, UrlGridArgs, callAxiosGet } from '../../tmsutil';
import { VhStAuth, vhStAuthSchema } from '../../Tms/Identity';
import { useAuthState } from '../Auth/AuthContext';
import { StrgCate, VhGrpTy } from '../../Tms/Tms';
import { Curncy } from '../../Tms/Common';
import DateSelector from '../../shared/DateSelector';

const Page = '/Admin/VhStAuth';

const vhStAuthDft: UrlGridArgs<VhStAuth> = {
  url: `${Page}/VhStAuth`,
  title: '펀드 / 전략 권한',
  // prettier-ignore
  columns: ['ord', 'note', 'd0', 'd1', 'invalid', 'disallow', 'userGrp', 'userTeam', 'userId', 'header', 'allVhs', 'vhTy', 'vhGrp', 'vhId', 'whole', 'strgs', 'mineOnly', 'teamSts', 'stCate', 'stSubCate', 'stId', 'wholeIfStrg', 'permission', 'tags'],
  // prettier-ignore
  headers: ['순번', '노트', '시작일', '종료일', '무효', '불허', '그룹', '팀', '개별', '팀장', '전체', '타입', '그룹', '개별', 'Whole', '각 전략', '내 전략', '팀원 전략', 'Cate', 'Sub', '개별', '내꺼있음Whole', '권한', '태그'],
  headerGroups: [
    ['기본', 6],
    ['대상 유저', 4],
    ['대상 펀드', 4],
    ['대상 전략', 8],
    ['권한', 2],
  ],
  height: 500,
  fixedWidth: 60,
  editable: true,
  meta: { idType: 'uuid' },
  schema: vhStAuthSchema,
  infoMsg: `* 대상 유저, 대상 펀드, 대상 전략 내 각 필터 조건들은 or 조건으로 적용 (단, 팀장 필터는 and 조건)
[대상 전략]
각 전략 : Whole이 아닌 개별 전략
내 전략 : 매니저별 자기 자신의 전략
팀원 전략 : 같은 팀 팀원의 전략`,
};

type VhSts = {
  vh: string;
  sts: { Id: string; cate: StrgCate }[];
  crncy: Curncy;
};
type VhStAuthRec = {
  Id: number;
  vhGrp: VhGrpTy;
  vhSts: VhSts[];
};

interface VhStAuthRes {
  userId: string;
  userNm: string;
  permission: 'None' | 'NavOnly' | 'MngrEval' | 'WeeklyPlan' | 'MstTgt' | 'Read' | 'Write';
  Recs: VhStAuthRec[];
}

// string userId, string userNm, AuthPermission permission, VhStAuthRec[] res
interface CombinedAuthRes {
  Id: number;
  userId: string;
  userNm: string;
  rvhs: string[] | null;
  rsts: string[] | null;
  wvhs: string[] | null;
  wsts: string[] | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const vhStsFormatter = (v: any, c: string): React.ReactNode => {
  if (c === 'vhSts') {
    return arrayTable(
      (v as VhSts[]).map((r) => [r.vh, r.sts.map((x) => x.Id).join(', ')]),
    );
  }
  return <span>{v.toString()}</span>;
};

export default function VhStAuthPage() {
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const [d, setD] = useState(info?.currBizDay ?? '');
  const [all, setAll] = useState(false);
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [currAuth, setCurrAuth] = useState<VhStAuth | null>(null);
  const [currUserId, setCurrUserId] = useState<string>('');
  const [authResByRules, setAuthResByRules] = useState<CombinedAuthRes[]>([]);
  const [authResByUserRead, setAuthResByUserRead] = useState<VhStAuthRec[]>([]);
  const [authResByUserWrite, setAuthResByUserWrite] = useState<VhStAuthRec[]>(
    [],
  );

  const getAuthResByRules = (items: VhStAuth[]) =>
    callAxiosGet({
      m,
      logger,
      url: `${Page}/AuthResByRules`,
      params: { d, ids: items.map((v) => v.Id) },
      onSuccess: (data) => setAuthResByRules(data),
    });

  useEffect(() => {
    if (currAuth) getAuthResByRules([currAuth]);
    else {
      setAuthResByRules([]);
    }
    setCurrUserId('');
  }, [currAuth]);

  const vhStAuth: UrlGridArgs<VhStAuth> = {
    ...vhStAuthDft,
    meta: {
      ...vhStAuthDft.meta,
      // 직접 getAuthRes 호출하면 셀 수정 잘 안됨. state 변수 도입해야
      // 아니면 편집 모드 여부 도입해서 편집 모드 때는 행 클릭 콜백 undefined로
      onRowClick: (r) => setCurrAuth(r.original),
      contextMenus: [
        {
          label: '적용 결과',
          callback: getAuthResByRules,
        },
      ],
    },
    onDataChange: () => setCurrAuth(null),
  };

  const getAuthResByUser = (id: string) =>
    callAxiosGet({
      m,
      logger,
      url: `${Page}/AuthResByUser`,
      params: { d, id },
      onSuccess: (data: VhStAuthRes[]) => {
        setAuthResByUserRead(data[0].Recs);
        setAuthResByUserWrite(data[1].Recs);
      },
    });

  useEffect(() => {
    if (currUserId) getAuthResByUser(currUserId);
    else {
      setAuthResByUserRead([]);
      setAuthResByUserWrite([]);
    }
  }, [currUserId]);

  return (
    <>
      <div className="children-me-2">
        <DateSelector value={d} onChange={(v) => setD(v)} />
        <label htmlFor="all">
          <input
            id="all"
            type="checkbox"
            checked={all}
            onChange={(e) => setAll(e.target.checked)}
          />
          무효 포함
        </label>
        <Button onClick={() => setRefreshNeeded((p) => p + 1)} label="Get" />
      </div>
      <hr className="narrow light" />
      <UrlGrid
        args={vhStAuth}
        params={{ d, all }}
        refreshNeeded={refreshNeeded}
      />
      <hr className="narrow light" />
      <SimpleGrid
        data={authResByRules}
        columns={['userNm', 'rvhs', 'rsts', 'wvhs', 'wsts']}
        headers={[
          '유저',
          '펀드(읽기)',
          '전략(읽기)',
          '펀드(쓰기)',
          '전략(쓰기)',
        ]}
        args={{
          widths: { userNm: 100 },
          meta: {
            dftColWidth: 300,
            cellStyle: { whiteSpace: 'normal' }, // 셀 컨텐츠 줄바꾸기용
            onRowClick: (r) => setCurrUserId(r.original.userId),
          },
          dftFormatter: (v) => (Array.isArray(v) ? v.join(', ') : v),
        }}
      />
      <hr className="narrow light" />
      <SimpleGrid
        data={authResByUserRead}
        columns={['vhGrp', 'vhSts']}
        headers={['타겟', '펀드/전략']}
        args={{
          title: '읽기',
          dftFormatter: vhStsFormatter,
          widths: { vhSts: 1000 },
        }}
      />
      <hr className="narrow light" />
      <SimpleGrid
        data={authResByUserWrite}
        columns={['vhGrp', 'vhSts']}
        headers={['타겟', '펀드/전략']}
        args={{
          title: '쓰기',
          dftFormatter: vhStsFormatter,
          widths: { vhSts: 1000 },
        }}
      />
    </>
  );
}
