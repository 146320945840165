import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import { DftBtnStyleMx } from '../../AppTypes';
import { callAxiosGet } from '../../tmsutil';
import DateSelector from '../../shared/DateSelector';
import VhclSelector from '../../shared/VhclSelector';
import { VhGrpTy, GetVhGrpName } from '../../Tms/Tms';
import { PreAllocRes, PreAllocStatRes } from '../../Tms/MO';

const page = '/Middle/PreAlloc';
const btnClass = DftBtnStyleMx;
const vhGrps = [VhGrpTy.HFund, VhGrpTy.VFund, VhGrpTy.AIFund];

export default function PreAlloc() {
  const { msgBox: m, logger } = useMessageState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const vh = searchParams.get('vh') || '';
  const [vhGrp, setVhGrp] = useState<VhGrpTy>(VhGrpTy.HFund);

  const [gridDataOld, setGridDataOld] = useState<PreAllocRes[]>([]);
  const [gridStat, setGridStat] = useState<PreAllocStatRes[]>([]);

  // TODO vh 사용하지 않고 있음
  const getData = () =>
    callAxiosGet({
      m,
      logger,
      url: `${page}/DataOld`,
      params: { d, vh },
      onSuccess: (data, res) => {
        if (data) {
          setGridDataOld(data);
          setGridStat(res.data.auxData[0]);
        }
      },
    });

  useEffect(() => {
    getData();
  }, [d, vhGrp, vh]);

  return (
    <div className="children-me-2" style={{ minWidth: '1500px' }}>
      <DateSelector
        value={d}
        onChange={(date) => date !== d && setSearchParams({ d: date, vh })}
      />
      <select
        name="vhGrpSelect"
        value={vhGrp}
        onChange={(e) => {
          setVhGrp(e.target.value as VhGrpTy);
        }}
      >
        {vhGrps.map((v) => (
          <option key={v} value={v}>
            {GetVhGrpName(v)}
          </option>
        ))}
      </select>
      <VhclSelector
        d={d}
        onChange={(vhcl) =>
          vhcl && vhcl.Id !== vh && setSearchParams({ d, vh: vhcl.Id })
        }
        value={vh}
        grp={vhGrp}
        empty
      />
      &nbsp;&nbsp;
      <button type="button" className={btnClass} onClick={() => getData()}>
        사전 배분 결과
      </button>
      <hr className="narrow light" />
      <div>
        <SimpleGrid
          data={gridStat}
          // prettier-ignore
          headers={['구분', '전체건수', '점검필요']}
          // prettier-ignore
          columns={['ty', 'cnt', 'invalid']}
          args={{
            meta: { dftColWidth: 100 },
          }}
        />
      </div>
      <hr className="narrow light" />
      <div>
        <SimpleGrid
          data={gridDataOld}
          // prettier-ignore
          headers={['운용상품', '종목코드', '종목명', 'L/S', 'E/X', '주문수량', '체결수량', '배분수량', '주문>=체결', '체결=배분']}
          // prettier-ignore
          columns={['vhId', 'prodId', 'prodNm', 'ls', 'ex', 'q2o', 'fq', 'alloc', 'q2oValid', 'fillAlloc']}
          args={{
            widths: { prodNm: 200 },
            meta: { dftColWidth: 80, height: 800, useGlobalFilter: true },
            dftStyler: (v, c, r) => {
              if (c === 'q2oValid' || c === 'fillAlloc') {
                if (
                  r.original.q2oValid === '적정' ||
                  r.original.fillAlloc === '적정'
                ) {
                  return { backgroundColor: '#7EBFB3' }; // TODO 색
                }
                return { backgroundColor: '#BF3B47' };
              }
              return null;
            },
          }}
        />
      </div>
    </div>
  );
}
