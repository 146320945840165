import React, { useState, useEffect } from 'react';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { utilGet } from '../tmsutil';

export enum BrokerType {
  PBS = 'PBS',
  SwapBrokers = 'SwapBrokers',
  KStk = 'KStk',
  KFut = 'KFut',
  UStk = 'UStk',
  UFut = 'UFut',
}

export interface SimpleBrk {
  Id: string;
  nm: string;
}

interface Props {
  value?: string | null;
  onChange: (st: SimpleBrk | null, fst?: boolean) => void;
  ty: BrokerType;
  empty?: boolean; // 기본 빈값 허용
  showId?: boolean;
  className?: string;
}

export default function BrokerSelector(props: Props) {
  const { value: initBrkId, onChange, ty, empty, showId, className } = props;

  const [brks, setBrks] = useState<SimpleBrk[]>([]);
  const [brk, setBrk] = useState<SimpleBrk | null>(null);
  const [cnt, setCnt] = useState(0);
  const { msgBox: m, logger } = useMessageState();

  const getDefault = () => {
    if (!brks.length) return null;
    if (initBrkId) return brks.find((v) => v.Id === initBrkId) ?? null;
    if (empty) return null;
    return brks[0];
  };

  useEffect(() => setBrk(getDefault()), [initBrkId]);

  useEffect(() => {
    onChange(brk, cnt === 1);
    setCnt((p) => p + 1);
  }, [brk]);

  useEffect(() => {
    if (!brk || !brks.filter((v) => v.Id === brk.Id).length) {
      setBrk(getDefault());
    }
  }, [brks]);

  useEffect(() => {
    const params = {
      ty,
    };
    utilGet(m, logger, 'Brokers', params, (data) => setBrks(data));
    // 배열인 exc에 의존하면, 호출측서 exc를 useState으로 상태변수로 관리하지 않고 바로 넘기면 재반복 호출됨.
    // 바로 넘길수 있게 exc를 의존성에서 제외(exc는 바뀔일 없으므로 괜찮음).
  }, [ty]);

  return (
    <select
      value={brk?.Id || ''}
      onChange={(e) =>
        setBrk(brks.find((v) => v.Id === e.target.value) ?? null)
      }
      className={className}
      name="stId"
    >
      {empty && <option> </option>}
      {brks.map((v) => (
        <option key={v.Id} value={v.Id}>{`${v.nm}${
          showId ? ` (${v.Id})` : ''
        }`}</option>
      ))}
    </select>
  );
}
