import { z } from 'zod';
import { zodNumber, zodDate, zodDateNullable } from './ZodType';

export enum TmsDocuType {
  None = 'None',
  InvByOtherFunds = 'InvByOtherFunds',
}
export const tmsDocuTypeSchema = z.nativeEnum(TmsDocuType);

export interface TmsDocu {
  Id: number;
  docuTy: TmsDocuType;
  doc: string;
}

export interface InvByOtherFunds extends TmsDocu {
  vhId: string;
  inv: string;
  ty: string;
  clt: string;
  d0: string;
  units: number | null;
  d1: string | null;
  nav: number | null;
  amt: number | null;
  wei: number | null;
}

export const invByOtherFundsSchema = z.object({
  vhId: z.string(),
  inv: z.string(),
  ty: z.string(),
  clt: z.string(),
  d0: zodDate,
  units: zodNumber(true), // null true
  d1: zodDateNullable,
  nav: zodNumber(true).readonly(),
  amt: zodNumber(true).readonly(),
  wei: zodNumber(true).readonly(),
});
