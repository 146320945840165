import { z } from 'zod';
import { StrgCate, StrgSubCate, strgCateSchema, strgSubCateSchema } from './Tms';
import { BoStatPeriod, boStatPeriodSchema } from './BO';
import { zodDate, zodNumber } from './ZodType';

export enum Subject {
  Pf = 'Pf', // 전체
  Stock2 = 'Stock2', // 주식 + 선물
  Sec = 'Sec',
  Comp = 'Comp',
  Over1T = 'Over1T',
  Under1T = 'Under1T',
  KSP = 'KSP',
  KSQ = 'KSQ',
  Frgn = 'Frgn',
  Specific = 'Specific',
  Return = 'Return',
  Under3T = 'Under3T',
  Under10BD = 'Under10BD', // 10 bil usd
  NAV = 'NAV',
  ETF = 'ETF',
  Stk2ExEtf = 'Stk2ExEtf', // 주식 + 선물 - ETF
  Stock = 'Stock', // 주식(선물 제외)
  StkExEtf = 'StkExEtf', //  주식 - ETF
  Futures = 'Futures',
  Under5pct = 'Under5pct', // 동일 법인 발행 증권 합산 5% 이하 투자 종목
  LoanOffer = 'LoanOffer', // 대여
}

export const subjectSchema = z.nativeEnum(Subject);

export enum Statistics {
  SumW = 'SumW',
  SecW = 'SecW',
  LongW = 'LongW',
  ShortW = 'ShortW',
  GrossW = 'GrossW',
  EachW = 'EachW',
  StdDev = 'StdDev',
  Beta = 'Beta',
  Turnover = 'Turnover',
  AvgGap = 'AvgGap',
  Level = 'Level', // 자기 자신 값
  Similarity = 'Similarity', // bm 복제율
  LongN = 'LongN',
  ShortN = 'ShortN', // 롱 종목수, 숏 종목수
  TrdAmtRto = 'TrdAmtRto', // 20일 평균거래대금 대비 %
  Under5pctN = 'Under5pctN', // 5%이하 투자종목 수
  Under5pctW = 'Under5pctW', // 5%이하 투자종목 비중합
  ToPosAmtRto = 'ToPosAmtRto', // 포지션 대비 비중
}

export const statisticsSchema = z.nativeEnum(Statistics);

export interface RiskItem {
  Id: number;
  title: string;
  subj: Subject;
  subjId: string | null;
  stat: Statistics;
  prd: BoStatPeriod | null;
  ord: number;
}

export const riskItemSchema = z.object({
  title: z.string(),
  subj: subjectSchema,
  subjId: z.string().nullish(),
  stat: statisticsSchema,
  prd: boStatPeriodSchema.nullish(),
  ord: zodNumber(false),
});

export interface RiskCate {
  Id: number;
  cate: StrgCate;
  subCate: StrgSubCate;
  itemId: number;
  ord: number;
  title: string | null;
}

export const riskCateSchema = z.object({
  cate: strgCateSchema.readonly(),
  subCate: strgSubCateSchema.readonly(),
  title: z.string().nullish(),
  ord: zodNumber(false),
});

export const riskCateByItemSchema = z.object({
  cate: strgCateSchema,
  subCate: strgSubCateSchema,
});

export interface RiskPar {
  Id: number;
  stId: string;
  itemId: number;
  dngrMin: number | null;
  alrtMin: number | null;
  alrtMax: number | null;
  dngrMax: number | null;
  grace: boolean;
  outdated: boolean;
  d0: string;
  pars: number[] | null;
}

export const riskParSchema = z.object({
  stId: z.string(),
  dngrMin: zodNumber(true),
  alrtMin: zodNumber(true),
  alrtMax: zodNumber(true),
  dngrMax: zodNumber(true),
  d0: zodDate,
  grace: z.boolean().nullish(),
  outdated: z.boolean().nullish(),
});
