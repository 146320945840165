import React, { useEffect, useState } from 'react';
import {
  createColumnHelper,
  ColumnDef,
  TableMeta,
} from '@tanstack/react-table';
import DataGrid, { DataGridState } from 'tmslib/src/table/DataGrid';
import { BorrowReqVh } from '../../../Tms/Loan';

interface Props {
  items: BorrowReqVh[];
  currId: number | undefined;
  setCurrId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const columnHelper = createColumnHelper<BorrowReqVh>();

const columns = [
  columnHelper.accessor('vhId', { header: '펀드' }),
  columnHelper.accessor('prodId', { header: '종목코드', size: 80 }),
  columnHelper.accessor('availq', {
    header: '가능량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('needq', {
    header: '필요량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('finalq', {
    header: '확정량',
    meta: { formatter: (v) => v?.toFixedWithComma(0) },
  }),
  columnHelper.accessor('fee', {
    header: '요율',
    meta: { formatter: (v) => v?.toFixed(2) },
  }),
].map((v) => v as ColumnDef<BorrowReqVh, unknown>);

export default function LoanReqVhTab({ items, currId, setCurrId }: Props) {
  const [dgState, setDgState] = useState<DataGridState<BorrowReqVh>>();

  useEffect(() => {
    if (dgState?.currentObj?.Id !== currId) {
      setCurrId(dgState?.currentObj?.Id);
    }
  }, [currId, setCurrId, dgState]);

  const meta: TableMeta<BorrowReqVh> = {
    dftColWidth: 60,
  };

  return (
    <DataGrid
      data={items}
      columns={columns}
      meta={meta}
      onStateChange={setDgState}
    />
  );
}
