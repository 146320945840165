import React, { useEffect, useState } from 'react';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { useSearchParams } from 'react-router-dom';
import { useAuthState } from '../Auth/AuthContext';
import VhclSelector from '../../shared/VhclSelector';
import ProdSelector from '../../shared/ProdSelector';
import { UrlGrid, UrlGridArgs, callAxiosGet, emptyGridArgs } from '../../tmsutil';
import DateSelector from '../../shared/DateSelector';
import ButtonWithHelp from '../../shared/ButtonWithHelp';
import { IPMSPos, PositionEvent } from '../../Tms/BO';
import { DftBtnStyle } from '../../AppTypes';

const currMenu = '/Back/IPMS';
const dftHeight = 900;
type T = { Id: number }; // 임의 IId 타입


// prettier-ignore
const iPMSPosDft: UrlGridArgs<IPMSPos> = {
  url: `${currMenu}/PositionState`,
  title: '',
  columns: ['vhId', 'prodId', 'Date', 'hmStr', 'YstdNet', 'OrderCumSum', 'NonFixFillSum', 'NetPosition', 'Borrowed', 'MaxAdditoinalSaleQty', 'Saleable'],
  headers: ['계산단위', '종목코드', '날짜', '시간', '전일포지션', '픽스체결', '픽스외체결', '포지션', '순대차잔고', '미체결매도', '매도가능수량'],
  editable: false,
  widths: {},
  height: dftHeight,
  meta: { dftColWidth: 100 }
};

export default function IPMS() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [hm, setHms] = useState<string>('');
  const vh = searchParams.get('vh') || 'ALL_FUNDS';
  const [prodId, setProdId] = useState<string | null>(null);
  const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [warnResMsg, setWarnResMsg] = useState<string | null>(null);
  const [cdPat, setCdPat] = useState<string | null>(null);
  const [eventArgs, setEventArgs] = useState<IPMSPos | null>(null);
  const [events, setEvents] = useState<PositionEvent[]>([])

  useEffect(() => {
    setGridArgs(emptyGridArgs);
    setEventArgs(null);
    setEvents([])
  }, [d]);

  useEffect(() => {
    if (!eventArgs) return;
    callAxiosGet({
      m,
      logger,
      url: `${currMenu}/PositionEvents`,
      params: { d, vhOrGrp: eventArgs.vhId, prodId: eventArgs.prodId, hm },
      onSuccess: (data) => {
        setEvents(data)
      }
    });

  }, [eventArgs])

  const getParams = () =>
  ({
    d,
    vhOrGrp: vh,
    prodId,
    cdPat,
    hm,
  });

  const query = (args: unknown) => {
    setRefreshNeeded((p) => p + 1);
    setGridArgs(args as UrlGridArgs<T>);
  };

  const iPMSPos: UrlGridArgs<IPMSPos> = {
    ...iPMSPosDft,
    meta: {
      ...iPMSPosDft.meta,
      // 직접 getAuthRes 호출하면 셀 수정 잘 안됨. state 변수 도입해야
      // 아니면 편집 모드 여부 도입해서 편집 모드 때는 행 클릭 콜백 undefined로
      onRowClick: (r) => setEventArgs(r.original),
    },

  };

  return (
    <div style={{ minWidth: '3000px' }} className="children-me-2">
      <DateSelector
        value={d}
        onChange={(date) => date !== d && setSearchParams({ d: date, vh })}
      />
      <input
        type="text"
        name="hm"
        size={6}
        placeholder="hhmm"
        value={hm}
        onChange={(e) => setHms(e.target.value)}
      />

      <VhclSelector
        d={d}
        meta
        all
        tfim
        onChange={(vhcl) =>
          vhcl && vhcl.Id !== vh && setSearchParams({ d, vh: vhcl.Id })
        }
        value={vh}
      />
      <ProdSelector
        onChange={(e) => {
          setProdId(e?.Id ?? null);
        }}
      />

      <label htmlFor="cdPat">
        <input
          id="cdPat"
          onChange={(e) => setCdPat(e.target.value)}
          placeholder="^시작문자열"
          size={15}
        />
      </label>

      <ButtonWithHelp
        key='GetDataButton'
        helpid='조회'
        className={DftBtnStyle}
        onClick={() => {
          setWarnResMsg('');
          query(iPMSPos);
        }}
        label='조회'
      />
      <hr className="narrow light" />

      {warnResMsg && (
        <div className="alert alert-slim alert-warning like-pre">
          {warnResMsg}
        </div>
      )}{' '}
      <div
        style={{ width: '1800px', paddingLeft: '10px' }}
        className="d-flex justify-content-between"
      >
        <UrlGrid
          args={gridArgs}
          params={getParams()}
          refreshNeeded={refreshNeeded}
        />
        {eventArgs && <SimpleGrid
          data={events}
          columns={['Date', 'hmStr', 'vhId', 'prodId', 'EventTypeDesc', 'DataDesc']}
          headers={['날짜', '시간', '펀드', '종목', '이벤트타입', 'Data']}

          args={{
            meta: { dftColWidth: 120 },
            widths: { 'DataDesc': 400 },
            title: '이벤트 상세',
            stylers: {
              vhId: () => ({ textAlign: 'left' }),
              prodId: () => ({ textAlign: 'left' }),
              EventTypeDesc: () => ({ textAlign: 'left' }),
              DataDesc: () => ({ textAlign: 'left' })
            },
          }}

        />}

      </div>

    </div>
  );
}
