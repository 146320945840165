import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import { DftBtnStyleMx } from '../../AppTypes';
import { callAxios, UrlGrid, UrlGridArgs, emptyGridArgs } from '../../tmsutil';
import DateSelector from '../../shared/DateSelector';
import StrgSelector from '../../shared/StrgSelector';
import { RiskLog } from '../../Tms/MO';
import { VhGrpTy, GetVhGrpName } from '../../Tms/Tms';

const page = '/Middle/RiskMaster';
const btnClass = DftBtnStyleMx;
type T = { Id: number };
const vhGrps = [VhGrpTy.HFund, VhGrpTy.PAFund, VhGrpTy.ETF];
const violLevels = ['', '주의이상', '위반만'];

const getHold = (r: RiskLog): [number, boolean] => {
  const maxLevel =
    (r.level || 0) > (r.expdLevel || 0) ? r.level || 0 : r.expdLevel || 0;
  let hold =
    maxLevel > 0 &&
    r.d.startsWith('2018') &&
    (r.title.startsWith('60일') || r.title.startsWith('20일'));
  hold = maxLevel > 0 && r.d.startsWith('2018');

  return [maxLevel, hold];
};

const riskLog: UrlGridArgs<RiskLog> = {
  url: `${page}/Data`,
  title: '펀드별 조회',
  // prettier-ignore
  columns: ['d', 'vhId', 'cate', 'title', 'subjNm', 'stat', 'note', 'violDays', 'alert', 'danger'],
  // prettier-ignore
  headers: ['날짜', '펀드', '구분', '항목', '종목/섹터', '현재값', '상태', '위반일수', '경고라인', '위반라인'],
  widths: {},
  meta: {
    dftColWidth: 90,
    useGlobalFilter: true,
  },
  height: undefined,
  dftStyler: (v, c, r) => {
    const maxLevel =
      (r.original.level || 0) > (r.original.expdLevel || 0)
        ? r.original.level || 0
        : r.original.expdLevel || 0;
    const hold = false;
    if (maxLevel >= 1) {
      if (maxLevel === 1 || hold) return { backgroundColor: '#fff3cd' }; // alert-warning
      if (maxLevel === 2 && !hold) return { backgroundColor: '#f8d7da' }; // alert-danger
    }
    return null;
  },
  // dftFormatter: (v, c, r) => {
  //   const hold = false;
  //   if (c === 'note') {
  //     if (hold) return '유예';
  //     if (r.original.level === 1) return '경고';
  //     if (r.original.level === 2) return '위반';
  //     if (r.original.expdLevel === 1) return '경고 예상';
  //     if (r.original.expdLevel === 2) return '위반 예상';
  //   }
  //   if (c === 'alert')
  //     return `${r.original.pars?.split('|')[1]} | ${r.original.pars?.split('|')[2]}`;
  //   if (c === 'danger')
  //     return `${r.original.pars?.split('|')[0]} | ${r.original.pars?.split('|')[3]}`;
  //   return v;
  // },

  accessFns: [
    (r) => r.d,
    (r) => r.vhId,
    (r) => r.cate,
    (r) => r.title,
    (r) => r.subjNm,
    (r) => r.stat,
    (r) => {
      const [, hold] = getHold(r);
      if (hold) return '유예';
      if (r.level === 1) return '경고';
      if (r.level === 2) return '위반';
      if (r.expdLevel === 1) return '경고 예상';
      if (r.expdLevel === 2) return '위반 예상';
      return '';
    },
    (r) => {
      if (r.ystdViolDays != null && r.ystdViolDays > 0) {
        return `${r.violDays} (${r.ystdViolDays})`;
      }
      return '';
    },
    (r) => `${r.pars?.split('|')[1]} | ${r.pars?.split('|')[2]}`,
    (r) => `${r.pars?.split('|')[0]} | ${r.pars?.split('|')[3]}`,
  ],

  // prettier-ignore
  auxColumns: [
    ['d', 'vhId', 'stId', 'cate', 'title', 'subjNm', 'stat', 'level', 'note', 'violDays', 'alert', 'danger'],
  ],
  // prettier-ignore
  auxHeaders: [
    ['날짜', '펀드', '매니저', '구분', '항목', '종목/섹터', '현재값', 'level', '상태', '위반일수', '경고라인', '위반라인'],
  ],
  auxGridArgs: [
    {
      title: '펀드/매니저별 조회',
      dftStyler: (v, c, r) => {
        const [maxLevel, hold] = getHold(r.original);
        if (maxLevel >= 1) {
          if (maxLevel === 1 || hold) return { backgroundColor: '#fff3cd' };
          if (maxLevel === 2 && !hold) return { backgroundColor: '#f8d7da' };
        }
        return null;
      },
      // dftFormatter: (v, c, r) => {
      //   const [, hold] = getHold(r.original);
      //   if (c === 'note') {
      //     if (hold) return '유예';
      //     if (r.original.level === 1) return '경고';
      //     if (r.original.level === 2) return '위반';
      //     if (r.original.expdLevel === 1) return '경고 예상';
      //     if (r.original.expdLevel === 2) return '위반 예상';
      //   }
      //   if (c === 'violDays' && r.original.ystdViolDays > 0) {
      //     return `${r.original.violDays} (${r.original.ystdViolDays})`;
      //   }
      //   if (c === 'alert')
      //     return `${r.original.pars.split('|')[1]} | ${r.original.pars.split('|')[2]}`;
      //   if (c === 'danger')
      //     return `${r.original.pars.split('|')[0]} | ${r.original.pars.split('|')[3]}`;
      //   if (c === 'violRstr' && r.original.ystdViolDays >= 3) {
      //     if (r.original.permitted === true) return '승인';
      //     if (r.original.permitted === false) return '거부';
      //     if (r.original.permitRqtd) return '대기';
      //     return '매매제한';
      //   }
      //   return v;
      // },
      accessFns: [
        (r) => r.d,
        (r) => r.vhId,
        (r) => r.stId,
        (r) => r.cate,
        (r) => r.title,
        (r) => r.subjNm,
        (r) => r.stat,
        (r) => r.level,
        (r) => {
          const [, hold] = getHold(r);
          if (hold) return '유예';
          if (r.level === 1) return '경고';
          if (r.level === 2) return '위반';
          if (r.expdLevel === 1) return '경고 예상';
          if (r.expdLevel === 2) return '위반 예상';
          return '';
        },
        (r) => {
          if (r.ystdViolDays != null && r.ystdViolDays > 0) {
            return `${r.violDays} (${r.ystdViolDays})`;
          }
          return '';
        },
        (r) => `${r.pars?.split('|')[1]} | ${r.pars?.split('|')[2]}`,
        (r) => `${r.pars?.split('|')[0]} | ${r.pars?.split('|')[3]}`,
      ],
    },
  ],
};

export default function RiskMaster() {
  const { msgBox: m, logger } = useMessageState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { info } = useAuthState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const [d0, setD0] = useState(d);
  const st = searchParams.get('st') || '';

  const [vhGrp, setVhGrp] = useState<VhGrpTy>(VhGrpTy.HFund);
  const [violLevel, setViolLevel] = useState<string>('');
  const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);
  const [refreshNeeded, setRefreshNeeded] = useState(0);

  const clearArgs = () => {
    setGridArgs(emptyGridArgs);
  };

  const query = (args: unknown) => {
    clearArgs();
    setRefreshNeeded((p) => p + 1);
    setGridArgs(args as UrlGridArgs<T>);
  };

  useEffect(() => {
    query(riskLog);
  }, [d0, d, violLevel]);

  return (
    <div className="children-me-2" style={{ minWidth: '1500px' }}>
      <DateSelector
        value={d}
        initDate0={d0}
        onChange={(date) => date !== d && setSearchParams({ d: date })}
        onChangeD0={(date0) => setD0(date0)}
      />
      <button type="button" className={btnClass} onClick={() => query(riskLog)}>
        Get Data
      </button>
      상태 &nbsp;
      <select
        name="violSelect"
        value={violLevel}
        onChange={(e) => {
          setViolLevel(e.target.value);
        }}
      >
        {violLevels.map((v) => (
          <option key={v} value={v}>
            {v}
          </option>
        ))}
      </select>
      <select
        name="vhGrpSelect"
        value={vhGrp}
        onChange={(e) => {
          setVhGrp(e.target.value as VhGrpTy);
        }}
      >
        {vhGrps.map((v) => (
          <option key={v} value={v}>
            {GetVhGrpName(v)}
          </option>
        ))}
      </select>
      <StrgSelector
        d={d}
        vhGrp={vhGrp}
        value={st}
        onChange={(v) => {
          if (v && v.Id !== st) {
            setSearchParams({ d, d0, st: v.Id, violLevel });
          }
        }}
        whole
        empty
        team
      />
      <button
        type="button"
        className={btnClass}
        onClick={() => {
          callAxios({
            m,
            logger,
            url: `${page}/Recalc`,
            params: { d, st },
            onSuccess: (data, res) => {
              if (!res.data.warnings?.length) {
                m.alert('OK');
              } else {
                m.alert(res.data.warnings);
              }
            },
          });
        }}
      >
        과거자 수정
      </button>
      <hr className="narrow light" />
      <div>
        <UrlGrid
          args={gridArgs}
          params={{ d, d0, st, violLevel }}
          refreshNeeded={refreshNeeded}
        />
      </div>
    </div>
  );
}
