import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { cutName, mapValueToString, parseBool } from 'tmslib/src/util/utils';
import NumericInput from 'tmslib/src/ui/NumericInput';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import InputGrid from 'tmslib/src/table/InputGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import DateSelector from '../../shared/DateSelector';
import { AstTy, getAstTyName } from '../../Tms/Prod';
import { checkItems, callAxios, callAxiosGet, utilGet } from '../../tmsutil';
import { DftBtnStyle } from '../../AppTypes';
import VhclSelector from '../../shared/VhclSelector';
import BrokerSelector, { BrokerType } from '../../shared/BrokerSelector';
import { EX, LS, getExName, getLsName } from '../../Tms/Common';
import ProdSelector, { SimpleProd } from '../../shared/ProdSelector';
import StrgSelector, { BlockDeal } from '../../shared/StrgSelector';
import {
  AlgoType,
  getAlgoTypeName,
  workingAlgoTypes,
  AlgoOrderPend,
  AlgoOrder,
  AlgoTag,
  AlgoVh,
  AlgoAcct,
  AlgoSlice,
  AlgoSliceCncl,
  FixDispMsg,
  EmsxRouteMsg,
} from '../../Tms/Ems';
import { RtDiff, updateRtRows, setRtEffect } from '../../rtutil';

import AlgoOrderTab from './OrdProc/AlgoOrderTab';
import AlgoTagTab from './OrdProc/AlgoTagTab';
import AlgoVhTab from './OrdProc/AlgoVhTab';
import AlgoAcctTab from './OrdProc/AlgoAcctTab';
import AlgoSliceTab from './OrdProc/AlgoSliceTab';
import AlgoSliceCnclTab from './OrdProc/AlgoSliceCnclTab';
import FixMsgTab from './OrdProc/FixMsgTab';
import EmsxMsgTab from './OrdProc/EmsxMsgTab';

const Assets = [AstTy.KStk, AstTy.KFut, AstTy.UStk, AstTy.UFut];
type DefaultOrder = 'FIX1' | 'FIX2' | 'QRAFT';
const DefaultOrders: DefaultOrder[] = ['FIX1', 'FIX2', 'QRAFT'];
type Acct = {
  Id: string;
  nm: string;
  accNo: string | null;
};

type BlockDealQty = {
  Id: number;
  st: string;
  qty: number;
};

type AlgoSpotRes = {
  t: number;
  pends: AlgoOrderPend[] | null;
  pends_diff: RtDiff<AlgoOrderPend> | null;
  algos: AlgoOrder[] | null;
  algos_diff: RtDiff<AlgoOrder> | null;
  altags: AlgoTag[] | null;
  altags_diff: RtDiff<AlgoTag> | null;
  alvhs: AlgoVh[] | null;
  alvhs_diff: RtDiff<AlgoVh> | null;
  alaccts: AlgoAcct[] | null;
  alaccts_diff: RtDiff<AlgoAcct> | null;
  slices: AlgoSlice[] | null;
  slices_diff: RtDiff<AlgoSlice> | null;
  slcCncls: AlgoSliceCncl[] | null;
  slcCncls_diff: RtDiff<AlgoSliceCncl> | null;
  fixMsgs: FixDispMsg[] | null;
  fixMsgs_diff: RtDiff<FixDispMsg> | null;
  emsxMsgs: EmsxRouteMsg[] | null;
  emsxMsgs_diff: RtDiff<EmsxRouteMsg> | null;
  algoId: number | null;
  sliceId: number | null;
};

export default function AlgoSpot() {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    user,
    connection,
    connected,
    info,
  } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const d = searchParams.get('d') || info?.currBizDay || '';
  const asset: AstTy = (searchParams.get('asset') as AstTy) || AstTy.KStk;
  const test: boolean = parseBool(searchParams.get('test')) ?? false; // test session
  const vhOrGrp = searchParams.get('vhOrGrp') || 'HFund';
  const vhIn = searchParams.get('vhIn') || '';
  const vhEx = searchParams.get('vhEx') || '';
  const brk = searchParams.get('brk') || '';
  const swap = parseBool(searchParams.get('swap')) ?? false;
  const branch = parseBool(searchParams.get('branch')) ?? false;
  const incInvalidAcct = parseBool(searchParams.get('incInvalidAcct')) ?? false;
  const ls: LS = (searchParams.get('ls') as LS) || LS.L;
  const ex: EX = (searchParams.get('ex') as EX) || EX.E;
  const acct = searchParams.get('acct') || '';
  const algoTy: AlgoType =
    (searchParams.get('algoTy') as AlgoType) || AlgoType.Limit;
  const st = searchParams.get('st') || '';
  const byBrk = parseBool(searchParams.get('byBrk')) ?? false;

  const [accts, setAccts] = useState<Acct[]>([]);
  const [prod, setProd] = useState<SimpleProd | null>(null);
  const [currPrc, setCurrPrc] = useState<number | null>(null);
  const [prodSizeKrw, setProdSizeKrw] = useState<number | null>(null);
  const [ordQty, setOrdQty] = useState<number | null>(null);
  const [ordAmt, setOrdAmt] = useState<number | null>(null);
  const [fee, setFee] = useState<number | null>(null);
  const [ordPrc, setOrdPrc] = useState<number | null>(null);
  const [hm0, setHm0] = useState<string>('');
  const [hm1, setHm1] = useState<string>('');
  const [futMaxSprd, setFutMaxSprd] = useState<number | null>(3);
  const [qraftFreeRt, setQraftFreeRt] = useState<number | null>(1);
  const [noArb, setNoArb] = useState(false);
  const [ordMsg, setOrdMsg] = useState('');
  const [blockDealQtys, setBlockDealQtys] = useState<BlockDealQty[]>([]);

  const [pends, setPends] = useState<AlgoOrderPend[]>([]);
  const [algos, setAlgos] = useState<AlgoOrder[]>([]);
  const [currAlgoId, setCurrAlgoId] = useState<number>();
  const [altags, setAltags] = useState<AlgoTag[]>([]);
  const [alvhs, setAlvhs] = useState<AlgoVh[]>([]);
  const [currAlvhId, setCurrAlvhId] = useState<number>();
  const [alaccts, setAlaccts] = useState<AlgoAcct[]>([]);
  const [slices, setSlices] = useState<AlgoSlice[]>([]);
  const [currSliceId, setCurrSliceId] = useState<number>();
  const [slcCncls, setSlcCncls] = useState<AlgoSliceCncl[]>([]);
  const [fixMsgs, setFixMsgs] = useState<FixDispMsg[]>([]);
  const [emsxMsgs, setEmsxMsgs] = useState<EmsxRouteMsg[]>([]);

  const lastResT = useRef(0);
  const lastAlgoId = useRef<number | null>(null);
  const lastSliceId = useRef<number | null>(null);

  const [updateNeeded, setUpdateNeeded_] = useState(0);
  const setUpdateNeeded = () => setUpdateNeeded_((p) => p + 1);

  useEffect(() => {
    if (currAlgoId !== lastAlgoId.current) {
      setAltags([]);
      setAlvhs([]);
      setAlaccts([]);
      setSlices([]);
    }
  }, [currAlgoId]);

  useEffect(() => {
    if (currSliceId !== lastSliceId.current) {
      setSlcCncls([]);
      setFixMsgs([]);
      setEmsxMsgs([]);
    }
  }, [currSliceId]);

  useEffect(
    () =>
      setRtEffect({
        m,
        logger,
        intv: 5,
        lastResT,
        params: {
          d,
          algoId: currAlgoId,
          alvhId: currAlvhId,
          slcId: currSliceId,
          test,
        },
        reqAddr: 'RequestAlgoSpot',
        rcvAddr: 'ReceiveAlgoSpot',
        connection,
        onReceive: (res: AlgoSpotRes) => {
          setPends((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.pends,
              diff: res?.pends_diff,
            }),
          );
          setAlgos((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.algos,
              diff: res?.algos_diff,
            }),
          );
          setAltags((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.altags,
              diff: res?.altags_diff,
            }),
          );
          setAlvhs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.alvhs,
              diff: res?.alvhs_diff,
            }),
          );
          setAlaccts((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.alaccts,
              diff: res?.alaccts_diff,
            }),
          );
          setSlices((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.slices,
              diff: res?.slices_diff,
            }),
          );
          setSlcCncls((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.slcCncls,
              diff: res?.slcCncls_diff,
            }),
          );

          setFixMsgs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.fixMsgs,
              diff: res?.fixMsgs_diff,
            }),
          );
          setEmsxMsgs((prevRows) =>
            updateRtRows(prevRows, {
              snapshot: res?.emsxMsgs,
              diff: res?.emsxMsgs_diff,
            }),
          );

          lastAlgoId.current = res.algoId;
          lastSliceId.current = res.sliceId;
        },
      }),
    [
      connection,
      connected,
      d,
      currAlgoId,
      currAlvhId,
      currSliceId,
      updateNeeded,
    ],
  );

  const naviParams = {
    d,
    asset,
    test,
    vhOrGrp,
    vhIn,
    vhEx,
    brk,
    swap,
    branch,
    incInvalidAcct,
    ls,
    ex,
    acct,
    algoTy,
    st,
    byBrk,
  };

  const getAccounts = () => {
    const params = _.omit(naviParams, ['acct', 'algoTy', 'st', 'byBrk']);
    callAxiosGet({
      m,
      logger,
      url: '/Back/AlgoSpot/Accounts',
      params,
      onSuccess: (data) => setAccts(data),
    });
  };

  useEffect(
    () => getAccounts(),
    [
      d,
      asset,
      test,
      vhOrGrp,
      vhIn,
      vhEx,
      brk,
      swap,
      branch,
      incInvalidAcct,
      ls,
      ex,
    ],
  );

  useEffect(() => {
    if (!prod?.Id) {
      setCurrPrc(null);
      setProdSizeKrw(null);
    } else {
      utilGet(m, logger, 'ProdPrc', { prod: prod.Id }, (data) =>
        setCurrPrc(data),
      );
      utilGet(m, logger, 'ProdSizeKrw', { prod: prod.Id }, (data) =>
        setProdSizeKrw(data),
      );
    }
  }, [prod]);

  useEffect(() => {
    if (!prodSizeKrw || !ordQty) setOrdAmt(null);
    else setOrdAmt(Math.round(prodSizeKrw * ordQty * 1e-4));
  }, [prodSizeKrw, ordQty]);

  const toParams = mapValueToString;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setDefaultOrder = (o: DefaultOrder) => {
    let ac;
    if (o === 'QRAFT') {
      ac = 'SH_LP_TTM';
    } else {
      const candi =
        o === 'FIX1'
          ? ['MZ_TTE2', 'NH_TTA', 'MZ_TTE', 'NHL_TTQ2', 'MZ_TTH']
          : ['SSL_TTQ2', 'SH_TTE', 'SSL_TTT2', 'SH_TTQ', 'SSL_TTM2'];
      ac = candi[new Date().getDate() % 5];
    }
    setSearchParams(
      toParams({
        ...naviParams,
        st: 'ETEAM',
        byBrk: o === 'QRAFT',
        algoTy: o === 'QRAFT' ? AlgoType.QRAFT_TWAP : AlgoType.Limit,
        acct: ac,
      }),
    );
    setProd(o === 'QRAFT' ? null : { Id: 'A069500', nm: 'KODEX 200' });
    setOrdQty(1);
  };

  useEffect(() => {
    if (prodSizeKrw) {
      const totQty = _.sum(blockDealQtys.map((v) => Number(v.qty)));
      setOrdAmt(Math.round(prodSizeKrw * totQty * 1e-4));
    }
  }, [prodSizeKrw, blockDealQtys]);

  useEffect(() => {
    if (st === BlockDeal) setOrdQty(null);
    else setBlockDealQtys([]);
  }, [st]);

  const checkParams = () => {
    if (!prod?.Id) m.alert('종목코드 미입력');
    else if (st !== BlockDeal && !ordQty) m.alert('주문수량 미입력');
    else if (st === BlockDeal && ordQty)
      m.alert('블록딜, 개별주문수량 동시 입력');
    else if (
      (algoTy === AlgoType.Limit || algoTy === AlgoType.AfAuc) &&
      !ordPrc
    )
      m.alert('주문가격 미입력');
    else return true;
    return false;
  };

  const getOrderParams = (accounts: string[]) => ({
    d,
    test,
    st,
    accts: accounts.join(','),
    prodId: prod?.Id,
    ls,
    ex,
    algoTy,
    ordQty,
    ordPrc,
    hm0,
    hm1,
    byBrk,
    ordMsg,
    fee,
    noArb,
    blockInfo: blockDealQtys,
  });

  const call = (func: string, params: unknown, title?: string) =>
    callAxios({
      m,
      logger,
      url: `/Back/AlgoSpot/${func}`,
      params,
      onSuccess: () => setUpdateNeeded(),
      title,
    });

  const genPends = async () => {
    if (!checkParams()) return;
    const accounts = acct ? [acct] : accts.map((v) => v.Id);
    if (!(await m.confirm(`${accounts.length}개 계좌로 임시 주문 생성?`)))
      return;
    call('GenPends', getOrderParams(accounts), '임시 주문 생성');
  };

  const updatePendQty = async (items: AlgoOrderPend[]) => {
    if (!checkItems(items, m, true)) return;
    const qty = await m.prompt('주문 수량');
    if (!qty) return;
    call(
      'UpdatePend',
      { id: items[0].Id, fld: 'qty2ord', val: qty },
      '임시 주문 수량 정정',
    );
  };

  const deletePends = (items: AlgoOrderPend[]) => {
    if (!checkItems(items, m)) return;
    call('DeletePends', { ids: items.map((v) => v.Id) }, '임시 주문 삭제');
  };

  const sendPends = () => {
    call('SendPends', { d }, '임시 주문 일괄 전송');
  };

  const sendDirect = async () => {
    if (!checkParams()) return;
    const accounts = acct ? [acct] : accts.map((v) => v.Id);
    const par0 = getOrderParams(accounts);
    const par = { ...par0, vhOrGrp, brkId: brk, acctId: acct };
    // if (!par.acctId) { alert('계좌 확인'); return; } // 알고는 계좌 미지정
    if (
      !(await m.confirm(
        `${prod?.nm} ${ordQty} ${getLsName(ls)} ${getExName(ex)}`,
      ))
    )
      return;
    call('SendDirect', par, '단일 계좌 즉시 전송');
  };

  return (
    <div style={{ minWidth: '1200px' }} className="children-me-2">
      <DateSelector
        value={d}
        onChange={(date) => date !== d && setSearchParams({ d: date })}
      />
      <select
        name="asset"
        value={asset}
        onChange={(e) =>
          setSearchParams(toParams({ ...naviParams, asset: e.target.value }))
        }
      >
        {Assets.map((a) => (
          <option key={a} value={a}>
            {getAstTyName(a)}
          </option>
        ))}
      </select>
      <label htmlFor="test">
        <input
          type="checkbox"
          id="test"
          checked={test}
          onChange={(e) =>
            setSearchParams(toParams({ ...naviParams, test: e.target.checked }))
          }
        />
        test
      </label>
      {user?.isDev &&
        DefaultOrders.map((o) => (
          <button
            type="button"
            className={DftBtnStyle}
            key={o}
            onClick={() => setDefaultOrder(o)}
          >
            {`${o} 디폴트`}
          </button>
        ))}
      <hr className="narrow light" />
      <VhclSelector
        d={d}
        value={vhOrGrp}
        onChange={(v, fst) =>
          v &&
          !fst &&
          v.Id !== vhOrGrp &&
          setSearchParams(toParams({ ...naviParams, vhOrGrp: v?.Id ?? '' }))
        }
        meta
        tfim
        htrk
      />
      <input
        type="text"
        name="vhIn"
        value={vhIn}
        size={8}
        placeholder="펀드 지정"
        onChange={(e) =>
          setSearchParams(toParams({ ...naviParams, vhIn: e.target.value }))
        }
      />
      <input
        type="text"
        name="vhEx"
        value={vhEx}
        size={8}
        placeholder="펀드 제외"
        onChange={(e) =>
          setSearchParams(toParams({ ...naviParams, vhEx: e.target.value }))
        }
      />
      <span>&nbsp;|&nbsp;</span>
      증권사:
      <BrokerSelector
        value={brk}
        ty={asset.toString() as BrokerType}
        onChange={(v) =>
          (v?.Id ?? '') !== brk &&
          setSearchParams(toParams({ ...naviParams, brk: v?.Id ?? '' }))
        }
        empty
        showId
      />
      <span>&nbsp;|&nbsp;</span>
      계좌:
      <select
        name="acct"
        value={acct}
        onChange={(e) =>
          setSearchParams(toParams({ ...naviParams, acct: e.target.value }))
        }
      >
        <option> </option>
        {accts.map((v) => (
          <option key={v.Id} value={v.Id}>
            {`${v.nm} (${v.accNo})`}
          </option>
        ))}
      </select>
      <label htmlFor="swap">
        <input
          type="checkbox"
          id="swap"
          checked={swap}
          onChange={(e) =>
            setSearchParams(toParams({ ...naviParams, swap: e.target.checked }))
          }
        />
        스왑
      </label>
      <label htmlFor="branch">
        <input
          type="checkbox"
          id="branch"
          checked={branch}
          onChange={(e) =>
            setSearchParams(
              toParams({ ...naviParams, branch: e.target.checked }),
            )
          }
        />
        지점
      </label>
      <label htmlFor="incInvalidAcct">
        <input
          type="checkbox"
          id="incInvalidAcct"
          checked={incInvalidAcct}
          onChange={(e) =>
            setSearchParams(
              toParams({ ...naviParams, incInvalidAcct: e.target.checked }),
            )
          }
        />
        미사용 포함
      </label>
      <hr className="narrow light" />
      <ProdSelector value={prod} onChange={(p) => setProd(p)} />
      <span style={{ color: 'gray' }}>{cutName(prod?.nm)}</span>
      <span style={{ color: 'gray' }}>{currPrc}</span>
      <span>&nbsp;|&nbsp;</span>
      <select
        name="ls"
        value={ls}
        style={{ color: ls === LS.L ? 'red' : 'blue' }}
        onChange={(e) =>
          setSearchParams(toParams({ ...naviParams, ls: e.target.value }))
        }
      >
        <option style={{ color: 'red' }}>L</option>
        <option style={{ color: 'blue' }}>S</option>
      </select>
      <select
        name="ex"
        value={ex}
        style={{ color: ex === EX.E ? 'red' : 'blue' }}
        onChange={(e) =>
          setSearchParams(toParams({ ...naviParams, ex: e.target.value }))
        }
      >
        <option style={{ color: 'red' }}>E</option>
        <option style={{ color: 'blue' }}>X</option>
      </select>
      수량:
      <NumericInput
        name="ordQty"
        width="90"
        value={ordQty}
        onChange={(v) => setOrdQty(v)}
        disabled={st === BlockDeal}
      />
      <span style={{ color: 'gray' }}>{`주문액 ${ordAmt ?? 0}만원`}</span>
      <span>&nbsp;|&nbsp;</span>
      <span>
        <NumericInput
          name="fee"
          width="60"
          value={fee}
          onChange={(v) => setFee(v)}
          placeholder="수수료"
        />
        bp
      </span>
      <hr className="narrow light" />
      주문유형:{' '}
      <select
        name="algoTy"
        value={algoTy}
        onChange={(e) =>
          setSearchParams(toParams({ ...naviParams, algoTy: e.target.value }))
        }
      >
        {workingAlgoTypes.map((v) => (
          <option key={v} value={v}>
            {getAlgoTypeName(v)}
          </option>
        ))}
      </select>
      지정/제한가:
      <NumericInput
        name="ordPrc"
        width="110"
        value={ordPrc}
        onChange={(v) => setOrdPrc(v)}
      />
      <span>&nbsp;|&nbsp;</span>
      <input
        type="text"
        name="hm0"
        size={6}
        placeholder="시작(즉시)"
        value={hm0}
        onChange={(e) => setHm0(e.target.value)}
      />
      <span>~</span>
      <input
        type="text"
        name="hm1"
        size={6}
        placeholder="종료(즉시)"
        value={hm1}
        onChange={(e) => setHm1(e.target.value)}
      />
      {asset === AstTy.KFut && (
        <>
          <span>&nbsp;|&nbsp;</span>
          <span>
            주식선물이격:
            <NumericInput
              name="futMaxSprd"
              width="60"
              value={futMaxSprd}
              onChange={(v) => setFutMaxSprd(v)}
            />
            %
          </span>
        </>
      )}
      {user?.isDev && (
        <>
          <span>&nbsp;|&nbsp;</span>
          <span>
            Q자유도:
            <NumericInput
              name="qraftFreeRt"
              width="60"
              value={qraftFreeRt}
              onChange={(v) => setQraftFreeRt(v)}
            />
            %
          </span>
        </>
      )}
      <span>&nbsp;|&nbsp;</span>
      <label htmlFor="noArb">
        <input
          id="noArb"
          type="checkbox"
          checked={noArb}
          onChange={(e) => setNoArb(e.target.checked)}
        />
        비차익태그
      </label>
      <hr className="narrow light" />
      전략:
      <StrgSelector
        d={d}
        value={st}
        onChange={(strg) =>
          strg && setSearchParams(toParams({ ...naviParams, st: strg.Id }))
        }
        vhOrGrp={vhOrGrp}
        empty
        blockDeal
      />
      <label htmlFor="byBrk">
        <input
          type="checkbox"
          id="byBrk"
          checked={byBrk}
          onChange={(e) =>
            setSearchParams(
              toParams({ ...naviParams, byBrk: e.target.checked }),
            )
          }
        />
        브로커
      </label>
      <input
        type="text"
        name="ordMsg"
        size={20}
        placeholder="주문지시"
        value={ordMsg}
        onChange={(e) => setOrdMsg(e.target.value)}
      />
      <span>&nbsp;|&nbsp;</span>
      <button type="button" className={DftBtnStyle} onClick={() => genPends()}>
        주문 생성
      </button>
      <button type="button" className={DftBtnStyle} onClick={() => sendPends()}>
        일괄 전송
      </button>
      <span>&nbsp;|&nbsp;</span>
      <button
        type="button"
        className={DftBtnStyle}
        onClick={() => sendDirect()}
      >
        단일 계좌 즉시 전송
      </button>
      {st === BlockDeal && (
        <>
          <hr className="narrow light" />
          <InputGrid
            columns={['st', 'qty']}
            headers={['전략', '수량']}
            args={{ onSetData: setBlockDealQtys, meta: { dftColWidth: 100 } }}
          />
        </>
      )}
      <hr className="narrow light" />
      <div className="alert alert-slim alert-info like-pre">
        {`[1회성 주문 (지정가,시장가,시간외)]
  -  주문지시에 시작 시간만 필요.
  -  브로커 체크해서 나가면서, 브로커에게 종료시간 지시하고 싶으면 종료 시간도 지정
[TWAP,VWAP]
  -  특별한 이유 없으면 쓰지 마세요
[해외주식]
  -  지정가, 시장가만 가능
  -  시작시간, 종료시간을 한국시간으로 입력
  -  증권사 전송 시에 현지시간으로 변경되어 전송됨.
  -  일괄 전송 시, 증권사/종목/방향/가격 등으로 묶어서 모주문도 함께 생성됨.
  -  체결 분배는 자동으로 진행
[해외선물]
  -  지정가, 시장가만 가능
  -  시작시간, 종료시간을 한국시간으로 입력`}
      </div>
      <hr className="narrow light" />
      <SimpleGrid
        data={pends}
        columns={[
          'executor',
          'prodId',
          'prodNm',
          'stId',
          'acctId',
          'byBrk',
          'ls',
          'ex',
          'algoTy',
          't0',
          't1',
          'qty2ord',
          'price',
          'fee',
          'block',
          'ordMsg',
          'operNm',
        ]}
        headers={[
          '채널',
          '종목코드',
          '종목명',
          '전략',
          '계좌',
          '브',
          'LS',
          'EX',
          '유형',
          '시작',
          '종료',
          '주문량',
          '주문가',
          '수수료',
          '블락딜',
          '주문지시',
          '주문자',
        ]}
        args={{
          title: '임시 주문',
          widths: { algoTy: 90, t0: 60, t1: 60 },
          checkboxes: ['byBrk'],
          dftStyler: (v, c, r) =>
            c === 'ls' || c === 'ex'
              ? { color: r.original.sgn?.getSignColor() }
              : null,
          meta: {
            contextMenus: [
              {
                label: '수량 정정',
                callback: updatePendQty,
              },
              {
                label: '선택 삭제',
                callback: deletePends,
              },
            ],
          },
        }}
      />
      <hr className="narrow light" />
      <br />
      <div style={{ minHeight: '80px' }}>
        <AlgoOrderTab
          algos={algos}
          currAlgoId={currAlgoId}
          setCurrAlgoId={setCurrAlgoId}
          spot
          d={d}
          isAlgoSpot
        />
      </div>
      <br />
      <div style={{ minHeight: '80px' }}>
        <AlgoTagTab altags={altags} />
      </div>
      <br />
      <div style={{ minHeight: '80px' }}>
        <AlgoVhTab
          alvhs={alvhs}
          currAlvhId={currAlvhId}
          setCurrAlvhId={setCurrAlvhId}
        />
      </div>
      <br />
      <div style={{ minHeight: '50px' }}>
        <AlgoAcctTab alaccts={alaccts} />
      </div>
      <br />
      <AlgoSliceTab
        slices={slices}
        currSliceId={currSliceId}
        setCurrSliceId={setCurrSliceId}
        spot
      />
      <br />
      <AlgoSliceCnclTab slcCncls={slcCncls} />
      <br />
      <FixMsgTab fixMsgs={fixMsgs} />
      <br />
      <EmsxMsgTab emsxMsgs={emsxMsgs} />
      <br />
    </div>
  );
}
