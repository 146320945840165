import React, { useEffect, useState, useCallback } from 'react';
import Dialog from 'tmslib/src/ui/Dialog';
import NumericInput from 'tmslib/src/ui/NumericInput';
import MultiCheckBox from 'tmslib/src/ui/MultiCheckBox';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { VhGrpTy } from '../../../Tms/Tms';
import { LS } from '../../../Tms/Common';
import {
  OrdPrcTy2,
  OrdPrcTyValTxts,
  OrdSt,
} from '../../../Tms/Ord';
import DateSelector from '../../../shared/DateSelector';
import ProdSelector, { SimpleProd } from '../../../shared/ProdSelector';
import StrgSelector from '../../../shared/StrgSelector';
import {
  UserCfgTy,
  selectedUserCfgs,
  updateUserCfg,
  isKMainIdxFutOrSpr,
  isUStk,
  isUAst,
  callAxios,
  utilGet,
  FuncCall,
  ValidData,
} from '../../../tmsutil';
import UnregisteredTab from './UnregisteredTab';
import BatchOrdTab from './BatchOrdTab';
import { useOrderDispatch, useOrderState, HoveredBtn } from './OrderContext';
import { useAuthState } from '../../Auth/AuthContext';
import { DftBtnStyle, DftLinkBtnStyle } from '../../../AppTypes';
import BasketEditor from './BasketEditor';

interface Props {
  tgtCall: FuncCall;
  isOrdProc: boolean;
  d: string;
  initStId?: string;
  vhGrp: VhGrpTy | null;
  vhId?: string | null;
  unregistered?: OrdSt[];
}

export type Basket = {
  Id: number;
  nm: string;
};

export const getT0T1ByPty = (
  // prod: SimpleProd | null,
  prodId: string | undefined,
  pty: OrdPrcTy2 | undefined,
  kStkSessionOffset:
    | {
      open: number;
      close: number;
    }
    | undefined,
  afAucTime: string | undefined,
  afAucTimes: { t0: string; t1: string }[] | undefined,
) => {
  if (!prodId || !pty || !kStkSessionOffset || isUAst(prodId)) {
    return { open: '', close: '' };
  }

  const os = kStkSessionOffset;
  switch (pty) {
    case OrdPrcTy2.BfClose:
      return {
        open: `${(os.open + 8).toIntDigits(2)}:30`,
        close: `${(os.open + 8).toIntDigits(2)}:40`,
      };
    case OrdPrcTy2.OpenAuc:
      if (isKMainIdxFutOrSpr(prodId)) {
        return {
          open: `${(os.open + 8).toIntDigits(2)}:40`,
          close: `${(os.open + 8).toIntDigits(2)}:44`,
        };
      }
      return {
        open: `${(os.open + 8).toIntDigits(2)}:50`,
        close: `${(os.open + 8).toIntDigits(2)}:59`,
      };
    case OrdPrcTy2.AfClose:
      return { open: '', close: `${os.close + 16}:00` };
    case OrdPrcTy2.AfAuc:
      if (!afAucTimes || afAucTime?.length === 0)
        return { open: '', close: '' };
      if (!afAucTime || afAucTime !== '') {
        return { open: afAucTimes[0].t0, close: afAucTimes[0].t1 };
      }
      return {
        open: afAucTime,
        close: afAucTimes.find((v) => v.t0 === afAucTime)?.t1 ?? '',
      };
    case OrdPrcTy2.CloseAuc:
      return {
        open: `${(os.open + 15).toIntDigits(2)}:22`,
        close: `${(os.open + 15).toIntDigits(2)}:29`,
      };
    default:
      return { open: '', close: '' };
  }
};

export const getPrcTyByAstTy = (prodId: string) => {
  const res = OrdPrcTyValTxts.filter((v) =>
    isUAst(prodId)
      ? v.val.isIn(
        OrdPrcTy2.Opp,
        OrdPrcTy2.Fixed,
        OrdPrcTy2.Market,
        OrdPrcTy2.OpenAuc,
        OrdPrcTy2.CloseAuc,
      )
      : true,
  );
  return res;
};

export default function OrderInputBar({
  tgtCall,
  isOrdProc,
  d,
  initStId,
  vhGrp,
  vhId,
  unregistered,
}: Props) {
  const isTarget = !isOrdProc;

  const { inp, hover } = useOrderState();
  const dispatch = useOrderDispatch();

  useEffect(() => {
    if (!vhGrp) return;
    dispatch({ type: 'inp/d_tgt', d, tgt: vhGrp });
  }, [dispatch, d, vhGrp]);

  const setOrd = useCallback(
    (ord: number | null) => dispatch({ type: 'inp/ord', ord }),
    [dispatch],
  );

  const setStId = useCallback(
    (stId: string) => dispatch({ type: 'inp/stId', stId }),
    [dispatch],
  );
  useEffect(() => {
    if (initStId) setStId(initStId);
  }, [initStId, setStId]);

  const setT0T1 = useCallback(
    (t0: string, t1: string) => dispatch({ type: 'inp/t0_t1', t0, t1 }),
    [dispatch],
  );

  const setLs = (ls: LS) => dispatch({ type: 'inp/ls', ls });
  const setChWei = (chWei: number | null) =>
    dispatch({ type: 'inp/chWei', chWei });
  const setExAll = (exAll: boolean) => dispatch({ type: 'inp/exAll', exAll });
  const setPrcTy = (pty: OrdPrcTy2) => dispatch({ type: 'inp/pty', pty });
  const setT0 = (t0: string) => dispatch({ type: 'inp/t0', t0 });
  const setT1 = (t1: string) => dispatch({ type: 'inp/t1', t1 });
  const setPrc = (prc: number | null) => dispatch({ type: 'inp/prc', prc });
  const setNote = (note: string) => dispatch({ type: 'inp/note', note });
  const setVhIn = (vhIn: string) => dispatch({ type: 'inp/vhIn', vhIn });
  const setVhEx = (vhEx: string) => dispatch({ type: 'inp/vhEx', vhEx });
  const setPrdD1 = (prdD1: string) => dispatch({ type: 'inp/prdD1', prdD1 });

  const [afAucTimes, setAfAucTimes] = useState<{ t0: string; t1: string }[]>(
    [],
  );
  const [kStkSessionOffset, setKStkSessionOffset] = useState<{
    open: number;
    close: number;
  }>();
  const [baskets, setBaskets] = useState<Basket[]>([]);
  const [basket, setBasket] = useState<Basket>();

  const [afAucTime, setAfAucTime] = useState<string>('');
  const [advisorT0, setAdvisorT0] = useState<string[]>([]);
  const [advisorT1, setAdvisorT1] = useState<string[]>([]);

  const setProduct = useCallback(
    (prod: SimpleProd | null) => {
      dispatch({
        type: 'inp/product',
        Id: prod?.Id ?? '',
        nm: prod?.nm ?? '',
      });
      if (prod?.Id && isUStk(prod.Id)) {
        if (prod.t0 && prod.t1) setT0T1(prod.t0, prod.t1);
      } else {
        const res = getT0T1ByPty(
          inp.prodId,
          inp.pty,
          kStkSessionOffset,
          afAucTime,
          afAucTimes,
        );
        setT0T1(res.open, res.close);
      }
    },
    [dispatch],
  );

  const { user, info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();

  // 모니터링 편의상 SGA 전략은 사용자가 자문사인걸로 잡히게
  const isAdvisor = user?.isAdvisor || (isTarget && inp?.stId === 'SGA');

  const get = (
    func: string,
    params: unknown,
    onSuccess: (data: ValidData) => void,
  ) =>
    callAxios({
      m,
      logger,
      url: `/Front/Target/${func}`,
      params,
      onSuccess,
      isGet: true,
    });

  useEffect(() => {
    if (!inp.d) return;
    if (inp.pty === OrdPrcTy2.AfAuc) {
      const params = { d: inp.d };
      get('AfAucTimes', params, (data) => {
        setAfAucTimes(data);
        setAfAucTime(data[0].t0);
        setT0T1(data[0].t0, data[0].t1);
      });
    }
  }, [inp.d, inp.pty]);

  useEffect(() => {
    if (!inp.d) return;
    const params = { d: inp.d };
    utilGet(m, logger, 'KStkSessionOffset', params, (data) =>
      setKStkSessionOffset(data),
    );
  }, [inp.d]);

  useEffect(() => {
    const res = getT0T1ByPty(
      inp.prodId,
      inp.pty,
      kStkSessionOffset,
      afAucTime,
      afAucTimes,
    );
    setT0T1(res.open, res.close);
    // }, [inp.pty, inp.prodId, kStkSessionOffset, setT0T1, afAucTime]);
  }, [inp.pty, kStkSessionOffset, setT0T1, afAucTime]);

  useEffect(() => {
    if (!isAdvisor || !inp.d || !inp.prodId) return;

    const params = { d: inp.d, prod: inp.prodId };
    get('AdvisorOrderTime', params, (data) => {
      if (data.t0.length) {
        if (inp.prodId.startsWith('UUS')) {
          setT0(data.t0.slice(-1)[0]);
        } else {
          setT0(data.t0[0]);
        }
      }
      setAdvisorT0(data.t0);
      setAdvisorT1(data.t1);
    });
  }, [inp.d, inp.prodId, isAdvisor]);

  const orderCfgs = [
    UserCfgTy.TgtShowBatchOrd,
    UserCfgTy.TgtShowBasketOrd,
    UserCfgTy.TgtShowPeriodOrd,
  ];

  const [sltdOrderCfgs, setSltdOrderCfgs] = useState<UserCfgTy[]>([]);

  useEffect(() => {
    if (
      isTarget &&
      sltdOrderCfgs.contains(UserCfgTy.TgtShowBasketOrd) &&
      inp.stId
    ) {
      get('Baskets', { st: inp.stId }, (data) => setBaskets(data));
    }
  }, [isTarget, sltdOrderCfgs, inp.stId]);

  useEffect(() => {
    selectedUserCfgs(m, logger, orderCfgs, (sltd) => setSltdOrderCfgs(sltd));
  }, []);

  const getDftT1 = () =>
    kStkSessionOffset ? `${kStkSessionOffset.close + 15}:10` : '';

  const confirmInput = async (isBasket?: boolean, extMsg?: string) => {
    if (!inp.stId) {
      m.alert('전략 미선택');
      return false;
    }
    if (!isBasket && !inp.prodId) {
      m.alert('종목 미지정');
      return false;
    }
    if (isBasket && !basket) {
      m.alert('바스켓 미지정');
      return false;
    }
    if (!inp.chWei) {
      m.alert('주문비중 확인');
      return false;
    }
    if (inp.exAll && inp.chWei > 0) {
      m.alert('전량청산 주문비중 양수');
      return false;
    }
    if (isAdvisor) {
      if (!inp.t0?.trim()) {
        m.alert('주문 시작 시간 미지정');
        return false;
      }
    }
    const nm = isBasket ? `[바스켓] ${basket?.nm}` : inp.prodNm;
    const lsMsg = `${inp.ls === LS.L ? '롱' : '숏'} ${
      inp.exAll ? '전량' : ''
    } ${inp.chWei > 0 ? '진입' : '청산'}`;
    return m.confirm(
      `[${inp.stId}] ${nm} ${Math.abs(inp.chWei)}% ${lsMsg} ${extMsg ?? ''}?`,
    );
  };

  const getOrderInputWei = () => {
    if (!inp.prodId) {
      m.alert('종목 미선택');
      return null;
    }
    return {
      ...inp,
      vhIn: inp.tgt === VhGrpTy.ETF && !inp.vhIn ? vhId ?? null : inp.vhIn,
    };
  };

  const addOrdStWei = async () => {
    if (!(await confirmInput())) return;
    const par0 = getOrderInputWei();
    if (!par0) return;
    const par = { ...par0, isOrdProc };
    tgtCall('AddOrdStWei', par, {
      title: '주문 입력',
    });
  };

  const addOrdStLpAlloc = async () => {
    if (!(await confirmInput(false, 'LP 분배'))) return;
    if (isOrdProc) {
      m.alert('타겟 화면에서만 가능');
      return;
    }
    if (vhGrp !== VhGrpTy.ETF) {
      m.alert('ETF만 가능');
      return;
    }
    const par = getOrderInputWei();
    tgtCall('AddOrdStLpAlloc', par, { title: 'LP 분배 주문' });
  };

  const addOrdStBasket = async () => {
    if (!(await confirmInput(true))) return;
    if (isOrdProc) {
      m.alert('타겟 화면에서만 가능');
      return;
    }
    if (!basket) {
      m.alert('바스켓 미선택');
      return;
    }
    const par = {
      ...inp,
      prodId: 'WBATCHTMP',
      bskId: basket.Id,
      vhIn: inp.tgt === VhGrpTy.ETF && !inp.vhIn ? vhId ?? null : inp.vhIn,
    };
    tgtCall('AddOrdStBasket', par, {
      title: '바스켓 주문 입력',
    });
  };

  const addOrdStPrd = async () => {
    if (info?.isJunior(inp.stId)) {
      m.alert('주니어 기간 주문 금지');
      return;
    }
    if (!inp.prdD1 || inp.prdD1 <= d) {
      m.alert('기간주문 종료일 확인');
      return;
    }
    if (!(await confirmInput(false, `${inp.prdD1} 까지 기간주문`))) return;
    const par = getOrderInputWei();
    tgtCall('AddOrdStPrd', par, { title: '기간 주문 입력' });
  };

  const batchBtnHover = hover === HoveredBtn.Batch;
  const prdBatchBtnHover = hover === HoveredBtn.PrdBatch;
  const basketBtnHover = hover === HoveredBtn.Basket;
  const periodBtnHover = hover === HoveredBtn.Period;
  const modBatchBtnHover = hover === HoveredBtn.ModBatch;
  const keepReqBtnHover = hover === HoveredBtn.KeepReq;
  const recallOrderBtnHover = hover === HoveredBtn.recallOrderBtnHover;
  const loanKeepReqBtnHover = hover === HoveredBtn.loanKeepReqBtnHover;

  const setBtnHover = (hover_: HoveredBtn) =>
    dispatch({ type: 'hover', hover: hover_ });

  const [basketEditiorVisible, setBasketEditorVisible] = useState(false);

  return (
    <div className="children-me-2">
      {isOrdProc ? (
        <>
          <NumericInput
            width="70"
            placeholder="주문번"
            value={inp.ord}
            name="ord"
            className={
              modBatchBtnHover || recallOrderBtnHover ? 'input-highlight' : ''
            }
            onChange={(v) => setOrd(v)}
          />
          <StrgSelector
            d={d}
            onChange={(v) => setStId(v?.Id ?? '')}
            vhGrp={vhGrp}
            value={inp.stId}
            className={
              batchBtnHover || keepReqBtnHover || loanKeepReqBtnHover
                ? 'input-highlight'
                : ''
            }
            useTgt
          />
        </>
      ) : (
        <b>주문 입력</b>
      )}
      <ProdSelector
        onChange={setProduct}
        className={
          periodBtnHover || recallOrderBtnHover || loanKeepReqBtnHover
            ? 'input-highlight'
            : ''
        }
      />
      <select
        value={inp.ls}
        onChange={(e) => setLs(e.target.value as LS)}
        id="ls"
        name="ls"
        className={
          (isTarget && batchBtnHover) ||
          prdBatchBtnHover ||
          basketBtnHover ||
          periodBtnHover
            ? 'input-highlight'
            : ''
        }
      >
        <option>L</option>
        {inp.tgt !== VhGrpTy.RFM_BM && <option>S</option>}
      </select>
      <NumericInput
        width="70"
        placeholder="주문(%)"
        value={inp.chWei}
        step="0.01"
        name="chWei"
        className={
          basketBtnHover || periodBtnHover || loanKeepReqBtnHover
            ? 'input-highlight'
            : ''
        }
        onChange={(v) => setChWei(v)}
      />
      <label
        htmlFor="exitAll"
        className={periodBtnHover ? 'input-highlight' : ''}
      >
        <input
          type="checkbox"
          checked={inp.exAll}
          id="exitAll"
          name="exitAll"
          onChange={(e) => setExAll(e.target.checked)}
        />
        전량 청산
      </label>
      {!isAdvisor && (
        <select
          value={inp.pty}
          onChange={(e) => setPrcTy(e.target.value as OrdPrcTy2)}
          name="prcTy"
          className={
            batchBtnHover ||
            prdBatchBtnHover ||
            basketBtnHover ||
            periodBtnHover ||
            recallOrderBtnHover
              ? 'input-highlight'
              : ''
          }
        >
          {getPrcTyByAstTy(inp.prodId ?? '').map((v) => (
            <option key={v.val} value={v.val}>
              {v.txt}
            </option>
          ))}
        </select>
      )}
      {inp.pty === OrdPrcTy2.AfAuc && (
        <select
          value={afAucTime}
          onChange={(e) => setAfAucTime(e.target.value)}
          name="afAucTime"
        >
          <option> </option>
          {afAucTimes.map((v) => (
            <option key={v.t0} value={v.t0}>
              {v.t1}
            </option>
          ))}
        </select>
      )}
      <span>
        시작:
        {isAdvisor && (
          <select
            value={inp.t0 ?? ''}
            onChange={(e) => setT0(e.target.value)}
            name="t0"
            className={batchBtnHover ? 'input-highlight' : ''}
          >
            <option value={' '}> </option>
            {advisorT0.map((v) => (
              <option key={v} value={v}>
                {v}
              </option>
            ))}
          </select>
        )}
        {!isAdvisor && (
          <input
            type="text"
            size={5}
            placeholder="즉시"
            name="t0"
            className={
              batchBtnHover ||
              prdBatchBtnHover ||
              basketBtnHover ||
              periodBtnHover ||
              recallOrderBtnHover
                ? 'input-highlight'
                : ''
            }
            value={inp.t0 || ''}
            onChange={(e) => setT0(e.target.value)}
          />
        )}
      </span>
      <span>
        종료:
        {isAdvisor && (
          <select
            value={inp.t1 ?? ''}
            onChange={(e) => setT1(e.target.value)}
            name="t1"
            className={batchBtnHover ? 'input-highlight' : ''}
          >
            <option value={' '}>장 종료까지</option>
            {advisorT1
              .filter((v) => !inp.t0 || v > inp.t0)
              .map((v) => (
                <option key={v} value={v}>
                  {v}
                </option>
              ))}
          </select>
        )}
        {!isAdvisor && (
          <input
            type="text"
            size={5}
            placeholder={getDftT1()}
            name="t1"
            className={
              batchBtnHover ||
              prdBatchBtnHover ||
              basketBtnHover ||
              periodBtnHover ||
              recallOrderBtnHover
                ? 'input-highlight'
                : ''
            }
            value={inp.t1 || ''}
            onChange={(e) => setT1(e.target.value)}
          />
        )}
      </span>
      <NumericInput
        width={80}
        placeholder="지정가"
        name="prc"
        className={
          periodBtnHover || recallOrderBtnHover ? 'input-highlight' : ''
        }
        value={inp.prc}
        onChange={(v) => setPrc(v)}
      />
      <input
        type="text"
        size={13}
        placeholder="Memo"
        name="note"
        className={
          batchBtnHover || basketBtnHover || periodBtnHover
            ? 'input-highlight'
            : ''
        }
        value={inp.note || ''}
        onChange={(e) => setNote(e.target.value)}
      />
      <button
        type="button"
        className={DftBtnStyle}
        onClick={() => addOrdStWei()}
      >
        주문 입력
      </button>
      {isOrdProc && (
        <>
          <input
            type="text"
            size={7}
            placeholder="펀드 지정"
            id="vhIn"
            name="vhIn"
            className={
              batchBtnHover || recallOrderBtnHover ? 'input-highlight' : ''
            }
            value={inp.vhIn || ''}
            onChange={(e) => setVhIn(e.target.value)}
          />
          <input
            type="text"
            size={7}
            placeholder="펀드 제외"
            id="vhEx"
            name="vhEx"
            className={
              batchBtnHover || recallOrderBtnHover ? 'input-highlight' : ''
            }
            value={inp.vhEx || ''}
            onChange={(e) => setVhEx(e.target.value)}
          />
        </>
      )}
      {isTarget && vhGrp === VhGrpTy.ETF && (
        <button
          type="button"
          className="btn btn-sm btn-outline-dark"
          onClick={() => addOrdStLpAlloc()}
        >
          LP 분배 주문
        </button>
      )}
      {isTarget && (
        <div className="children-me-3">
          <hr className="light narrow" />
          <b>특수 주문</b>
          <MultiCheckBox
            name="specialOrderCfgs"
            options={orderCfgs}
            selected={sltdOrderCfgs}
            labels={['일괄 주문', '바스켓 주문', '기간 주문']}
            onChangeEach={(item, selected) =>
              updateUserCfg(m, logger, item as UserCfgTy, selected, () =>
                setSltdOrderCfgs(
                  selected
                    ? [...sltdOrderCfgs, ...[item as UserCfgTy]]
                    : sltdOrderCfgs.filter((v) => v !== item),
                ),
              )
            }
          />
        </div>
      )}
      {isTarget && sltdOrderCfgs.contains(UserCfgTy.TgtShowBatchOrd) && (
        <>
          <hr className="light narrow" />
          <div className="d-flex flex-row">
            <BatchOrdTab
              tgtCall={tgtCall}
              isOrdProc={false}
              osts={[]}
              vhId={vhId}
            />
          </div>
        </>
      )}
      {isTarget && sltdOrderCfgs.contains(UserCfgTy.TgtShowBasketOrd) && (
        <>
          <hr className="light narrow" />
          <span>바스켓:</span>
          <select
            value={basket?.Id}
            style={{ minWidth: '80px' }}
            name="basketId"
            onChange={(e) =>
              setBasket(baskets.find((v) => v.Id === Number(e.target.value)))
            }
          >
            <option> </option>
            {baskets.map((v) => (
              <option key={v.Id} value={v.Id}>
                {v.nm}
              </option>
            ))}
          </select>
          <button
            type="button"
            className={DftLinkBtnStyle}
            onClick={() => setBasketEditorVisible(true)}
          >
            편집
          </button>
          <button
            type="button"
            className="btn btn-sm btn-outline-info"
            onClick={() => addOrdStBasket()}
            onFocus={() => setBtnHover(HoveredBtn.Basket)}
            onBlur={() => setBtnHover(HoveredBtn.None)}
            onMouseOver={() => setBtnHover(HoveredBtn.Basket)}
            onMouseOut={() => setBtnHover(HoveredBtn.None)}
          >
            바스켓 주문 입력
          </button>
        </>
      )}
      {isTarget && sltdOrderCfgs.contains(UserCfgTy.TgtShowPeriodOrd) && (
        <>
          <hr className="light narrow" />
          <DateSelector
            value={inp.prdD1 ?? ''}
            className={prdBatchBtnHover ? 'input-highlight' : ''}
            name="prdD1"
            placeholder="기간주문종료일"
            onChange={(date) => setPrdD1(date)}
          />
          <button
            type="button"
            className="btn btn-sm btn-outline-info"
            onClick={() => addOrdStPrd()}
            onFocus={() => setBtnHover(HoveredBtn.Period)}
            onBlur={() => setBtnHover(HoveredBtn.None)}
            onMouseOver={() => setBtnHover(HoveredBtn.Period)}
            onMouseOut={() => setBtnHover(HoveredBtn.None)}
          >
            기간 주문 입력
          </button>
        </>
      )}
      {isTarget && (
        <>
          <hr className="light narrow" />
          <UnregisteredTab
            call={tgtCall}
            stId={inp.stId}
            osts={unregistered ?? []}
          />
        </>
      )}
      {isTarget && (
        <Dialog
          visible={basketEditiorVisible}
          onHide={() => setBasketEditorVisible(false)}
        >
          <BasketEditor st={inp.stId} bsk={basket?.Id} />
        </Dialog>
      )}
    </div>
  );
}
