import { z } from 'zod';
import { LS, PosBookTy } from './Common';
import { AstTy } from './Prod';
import { zodPosiNumber } from './ZodType';

export interface AmtWei {
  Id: number;
  d: string;
  vhId: string;
  stId: string;
  prodId: string;
  prodNm: string;
  amtVhCcy: number;
  wei: number;
  nav: number;
  sec?: string | null;
}

export interface UstkPos {
  Id: number;
  cntry?: string;
  wei?: number | null;
}

export interface UstkPosDetail {
  Id: number;
  d: string;
  vhId: string;
  book: PosBookTy;
  cntry: string;
  ls: LS;
  amtVhCcy: number;
}

export interface Asset {
  Id: number;
  d: string;
  vhId: string;
  stId: string;
  prodId: string;
  nm: string;
  pos: number;
  amtVhCcy: number;
  close: number;
  isF: string;
  astTy: AstTy;
  invTy: string;
  exch: string;
  evaluate: string;
  under: string;
  undernm: string;
  underAstTy: string;
  underInvTy: string;
  underExch: string;
  underofunderId: string;
  underofundernm: string;
  underofunderExch: string;
  sec: string;
}

export interface UstkbbTkr {
  d: string;
  Id: string;
  nm: string;
  bbTkr: string;
}

export const ustkbbTkrSchema = z.object({
  d: z.string(), // d.ToString("yyyyMMdd")
  Id: z.string(),
  nm: z.string(),
  bbTkr: z.string(),
});

export interface BSStatBatch {
  Id: number;
  d: string;
  vhId: string;
  stId: string;
  dsl: number;
  ddl: number;
  fsl: number;
  fdl: number;
  dss: number;
  dds: number;
  fss: number;
  fds: number;
  mzn: number;
  etc: number;

  nav: number;
  na: number;
  prft: number;
  rt: number;
  m3rt: number;
  m3std: number;
  m6rt: number;
  m6std: number;
  mtdrt: number;
  mtdstd: number;
  ytdrt: number;
  ytdstd: number;
  y1rt: number;
  y1std: number;
  d1stdF: number;
  d1stdL: number;
  d1stdS: number;
}

export interface AstAllocByProd {
  Id: number;
  d: string;
  vhId: string;
  prodId: string;
  prodNm: string;
  cate: string;
  amtVhCcy: number;
}

export interface PrftByVhId {
  Id: number;
  d: string;
  vhId: string;
  stId: string;
  na: number;
  lprd: number;
  lprf: number;
  lad: number;
  laf: number;
  sprd: number;
  sprf: number;
  sad: number;
  saf: number;
}
export interface PrftByProd {
  Id: number;
  LS: string;
  Code: string;
  name: string;
  under: string;
  undernm: string;
  prft: number;
  sec: string;
}

export interface ClassBS {
  Id: number;
  d: string;
  vhId: string;
  stId: string;
  prin: number;
  units: number;
  prft: number;
  cost: number;
  na: number;
  nav: number;
}

export interface IdxNav {
  Id: number;
  d: string;
  tolprin: number;
  tolna: number;
  csprin: number;
  csna: number;
  tolNav: number;
  csNav: number;
  NavByTolNadivByCsrt: number;
}

export interface StkNav {
  Id: number;
  d: string;
  tdnaSum: number;
  ydnaSum: number;
  rt: number;
  cumrt: number;
  stIds: string;
  vhIds: string;
}

export enum TableTy {
  All = 'All', // 수수료합산전체
  Users = 'Users', // 사용자지정
  PBS = 'PBS', // PBS
}

export interface FeeSum {
  Id: number;
  tbty: TableTy;
  brkcls?: string | null;
  book: PosBookTy;
  ord?: number | null;
  nm2?: string | null;
  amt?: number | null;
  fee?: number | null;
  amtweiByCash?: number | null;
  amtweiBytgtSum?: number | null;
  amtweiByBrk?: number | null;
  amtweiByWhSum?: number | null;
  feeweiByCash?: number | null;
  feeweiBytgtSum?: number | null;
  feeweiByBrk?: number | null;
  feeweiByWhSum?: number | null;
  feert?: number | null;
  yfeert?: number | null;
  mfeert?: number | null;
  targetfee?: number | null;
  fulfillrt?: number | null;
  dstrSd?: string | null;
  astty?: AstTy | null;
  prodId?: string | null;
  brkId?: string | null;
  vhbrkId?: string | null;
  dstrId?: string | null;
}

export interface SwapPositionByVhId {
  Id: number;
  vhId: string;
  book: PosBookTy;
  ltotd: number;
  ldiffd: number;
  ldiffdrt: number;
  lprd: number;
  stotd: number;
  sdiffd: number;
  sdiffdrt: number;
  sprd: number;
  ltotf: number;
  ldifff: number;
  ldifffrt: number;
  lprf: number;
  stotf: number;
  sdifff: number;
  sdifffrt: number;
  sprf: number;
  ord: number;
}

export interface QueryBrkFee {
  Id: number;
  tablety: TableTy;
  brkcls: string;
  ord: number;
  acctId: string;
  nm: string;
  nm2: string;
}

export const tableTySchema = z.nativeEnum(TableTy);
export const queryBrkFeeSchema = z.object({
  tablety: tableTySchema,
  brkcls: z.string(),
  ord: zodPosiNumber(false), // numericStringSchema(false),
  acctId: z.string(),
  nm: z.string().nullish(),
  nm2: z.string().nullish(),
});

export interface InterestPos {
  Id: number;
  d: string;
  vhId: string;
  stId: string;
  prodId: string;
  nm: string;
  pos: number;
  close: number;
  posAmt: number;
  wei: number;
}

export interface DailyCBA {
  Id: number;
  d: string;
  memo: string;
  diff: number;
  cumdiff: number;
  mngrSum: number;
  loan: number;
  cstnBnf: number;
}

export interface CBA {
  Id: number;
  Key: string;
  nm: string;
  nm2: string;
  tmscost: number;
  mafsprft: number;
  diff: number;
  memo: string;
}

export interface AmtVhCcyByAstAlloc {
  Id: number;
  d: string;
  vhId: string;
  na: number;
  dsl: number;
  ddl: number;
  fsl: number;
  fdl: number;
  dss: number;
  dds: number;
  fss: number;
  fds: number;
  mzn: number;
  etc: number;
}
