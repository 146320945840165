import React, { useEffect, useState } from 'react';
import Button from 'tmslib/src/ui/Button';
import ButtonGroup from 'tmslib/src/ui/ButtonGroup';
import Dialog from 'tmslib/src/ui/Dialog';
import TabView from 'tmslib/src/ui/TabView';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import NumericInput from 'tmslib/src/ui/NumericInput';
import { OrdPrcTy2, OrdSt } from '../../../Tms/Ord';
import {
  FuncCall,
  callAxiosGet,
  isKSFut,
  isKStk,
  isUAst,
  utilGet,
} from '../../../tmsutil';
import { LS, EX } from '../../../Tms/Common';
import { getT0T1ByPty, getPrcTyByAstTy } from './OrderInputBar';
import {
  OrderPermitType,
  reqOrderPermit,
  OrdPermitTypeOnTarget,
} from './ordutil';

export type OrderDlgArgs = {
  title: string;
  ordst?: OrdSt;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

interface Props {
  call: FuncCall;
  args: OrderDlgArgs;
  setArgs: React.Dispatch<React.SetStateAction<OrderDlgArgs>>;
}

export default function OrderDialog({ call, args, setArgs }: Props) {
  const { msgBox: m, logger } = useMessageState();
  const [wei, setWei] = useState<number | null>(args.ordst?.wei ?? null);
  const [exitAll, setExitAll] = useState(args.ordst?.exitAll);
  const [limitPrc, setLimitPrc] = useState<number | null>(
    args.ordst?.limitPrc ?? null,
  );
  const [prcTy, setPrcTy] = useState<OrdPrcTy2>(
    args.ordst?.prcTy ?? OrdPrcTy2.Opp,
  );
  const [hm0, setHM0] = useState<string>(args.ordst?.hm0 ?? '');
  const [hm1, setHM1] = useState<string>(args.ordst?.hm0 ?? '');
  const [memo, setMemo] = useState<string>(args.ordst?.note ?? '');
  const [futMaxSprd, setFutMaxSprd] = useState<string>(
    args.ordst?.futMaxSprd ?? '',
  );
  const [kStkSessionOffset, setKStkSessionOffset] = useState<{
    open: number;
    close: number;
  }>();

  const [afAucTimes, setAfAucTimes] = useState<{ t0: string; t1: string }[]>(
    [],
  );
  const [afAucTime, setAfAucTime] = useState<string>('');
  const [ordPermitTy, setOrdPermitTy] = useState<OrderPermitType | undefined>(
    undefined,
  );

  const updateAfAucTimes = () => {
    const d = args?.ordst?.d;
    if (!d) return;
    const params = { d: args?.ordst?.d };
    callAxiosGet({
      m,
      logger,
      url: '/Front/Target/AfAucTimes',
      params,
      onSuccess: (data) => {
        if (data) {
          setAfAucTimes(data);
          setAfAucTime(data[0].t0);
        }
      },
    });
  };

  useEffect(() => {
    updateAfAucTimes();
  }, []);

  useEffect(() => {
    updateAfAucTimes();
  }, [args?.ordst?.d, prcTy]);

  const updateKStkSessionOffset = () => {
    const d = args?.ordst?.d;
    if (!d) return;
    const params = { d: args?.ordst?.d };
    utilGet(m, logger, 'KStkSessionOffset', params, (data) => {
      setKStkSessionOffset(data);
    });
  };

  useEffect(() => {
    setWei(args.ordst?.wei ?? null);
    setExitAll(args.ordst?.exitAll);
    setLimitPrc(args.ordst?.limitPrc ?? null);
    setPrcTy(args.ordst?.prcTy ?? OrdPrcTy2.Opp);
    setMemo(args.ordst?.note ?? '');
    setFutMaxSprd(args.ordst?.futMaxSprd ?? '');
    updateKStkSessionOffset();
    updateAfAucTimes();
    setOrdPermitTy(undefined);
    setHM0(args.ordst?.hm0 ?? '');
    setHM1(args.ordst?.hm1 ?? '');
  }, [args]);

  const updateT0T1 = () => {
    if (
      // 해외주식은 시작 시간 없이 못넘어오니까 업뎃필요 없음
      isUAst(args.ordst?.prodId ?? '') ||
      prcTy.isIn(OrdPrcTy2.Opp, OrdPrcTy2.Market, OrdPrcTy2.Fixed)
    ) {
      setHM0(args?.ordst?.hm0 ?? '');
      setHM1(args?.ordst?.hm1 ?? '');
    } else {
      const t0t1 = getT0T1ByPty(
        args.ordst?.prodId,
        prcTy,
        kStkSessionOffset,
        afAucTime,
        afAucTimes,
      );
      setHM0(t0t1.open);
      setHM1(t0t1.close);
    }
  };

  useEffect(() => {
    updateT0T1();
  }, [prcTy, afAucTime]);

  const modifyOrder = (useKeep: boolean) => {
    if (!args.ordst) {
      m.alert('정정 대상 주문 없음');
      return;
    }
    const o = args.ordst;
    const data = {
      ostId: o.Id,
      wei,
      exitAll,
      prcTy,
      limitPrc,
      t0: hm0,
      t1: hm1,
      memo,
      futMaxSprd,
      useKeep,
    };
    call('ModifyOrderFromTargetDlg', data, {
      additionalOnSuccess: () => {
        m.toast('주문 정정 완료');
        setArgs({ ...args, visible: false });
      },
    });
  };

  const orderEditPanel = (
    <div className="children-mb-1">
      <fieldset className="simple-border">
        <div className="d-flex justify-content-between">
          <div>
            <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
              {args.ordst?.prodId}
            </div>
            <span style={{ fontSize: 'small', textAlign: 'center' }}>
              {args.ordst?.prodNm}
            </span>
          </div>
          <ButtonGroup
            name="LS"
            value={args.ordst?.ls ?? ''}
            options={[LS.L, LS.S]}
            containerStyle={{ height: 'fit-content' }}
            disabled
          />

          <ButtonGroup
            name="EX"
            value={args.ordst?.ex ?? ''}
            options={[EX.E, EX.X]}
            containerStyle={{ height: 'fit-content' }}
            disabled
          />
        </div>
      </fieldset>

      <fieldset className="simple-border">
        <div>
          주문 비중(%) &nbsp;
          <NumericInput
            width="70"
            // placeholder="주문(%)"
            value={wei}
            step="0.01"
            name="chWei"
            onChange={(e) => setWei(e)}
          />
        </div>

        {args.ordst?.ex === EX.X && (
          <div style={{ float: 'right' }}>
            <label htmlFor="exitAllDlg" aria-label="exitAllDlg">
              <input
                type="checkbox"
                id="exitAllDlg"
                checked={exitAll ?? false}
                onChange={(e) => setExitAll(e.target.checked as boolean)}
              />
              &nbsp;전량 청산
            </label>
          </div>
        )}
      </fieldset>

      <fieldset className="simple-border">
        <div>
          주문유형 &nbsp;
          <select
            value={prcTy}
            onChange={(e) => setPrcTy(e.target.value as OrdPrcTy2)}
            name="prcTy"
          >
            {getPrcTyByAstTy(args.ordst?.prodId ?? '').map((v) => (
              <option key={v.val} value={v.val}>
                {v.txt}
              </option>
            ))}
          </select>
        </div>
      </fieldset>

      <fieldset className="simple-border">
        <div>
          지정가 &nbsp;
          <NumericInput
            width="70"
            value={limitPrc}
            name="limitPrc"
            min={0}
            onChange={(e) => setLimitPrc(e)}
          />
        </div>
      </fieldset>

      {prcTy === OrdPrcTy2.AfAuc && (
        <fieldset className="simple-border">
          <span>단일가</span>&nbsp;
          <select
            value={afAucTime}
            onChange={(e) => {
              setAfAucTime(e.target.value);
            }}
            name="afAucTime"
          >
            <option> </option>
            {afAucTimes.map((v) => (
              <option key={v.t0} value={v.t0}>
                {v.t1}
              </option>
            ))}
          </select>
        </fieldset>
      )}

      <fieldset className="simple-border">
        <div>
          시작시간 &nbsp;
          <input
            type="text"
            id="hm0"
            value={hm0}
            onChange={(e) => setHM0(e.target.value)}
            style={{ width: '100px' }}
            disabled={prcTy === OrdPrcTy2.AfAuc}
            className="mb-1"
          />
        </div>
        <div>
          종료시간 &nbsp;
          <input
            type="text"
            value={hm1}
            onChange={(e) => setHM1(e.target.value)}
            disabled={prcTy === OrdPrcTy2.AfAuc}
            style={{ width: '100px' }}
          />
        </div>
      </fieldset>

      {args.ordst && isKSFut(args.ordst.prodId) && (
        <fieldset className="simple-border">
          <div>
            주식선물 괴리율 (%) &nbsp;
            <input
              type="text"
              id="futMaxSprd"
              value={futMaxSprd}
              onChange={(e) => setFutMaxSprd(e.target.value)}
              style={{ width: '100px' }}
              className="mb-1"
            />
          </div>
        </fieldset>
      )}

      <fieldset className="simple-border">
        <div>
          메 모 &nbsp;
          <input
            type="text"
            id="memo"
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
            style={{ width: '200px' }}
            className="mb-1"
          />
        </div>
      </fieldset>

      <div className="d-flex justify-content-between">
        <Button
          btnRole="Cancel"
          label="닫기"
          onClick={() => setArgs({ ...args, visible: false })}
        />
        {isKStk(args.ordst?.prodId ?? '') && (
          <Button
            label="킵 사용/반환"
            icon="Bookmark"
            onClick={() => {
              modifyOrder(true);
            }}
            disabled={args.ordst?.ls !== LS.S ?? true}
            btnRole="Link"
          />
        )}
        <Button
          label="주문 수정"
          btnRole="Ok"
          onClick={() => modifyOrder(false)}
        />
      </div>
    </div>
  );

  const reqPermitPanel = (
    <div className="children-mb-1">
      <fieldset className="simple-border">
        <div style={{ margin: '5px' }}>항목</div>
        <select
          style={{ margin: '5px' }}
          value={ordPermitTy ?? ''}
          onChange={(e) => {
            setOrdPermitTy((e.target.value as OrderPermitType) ?? undefined);
          }}
          name="ordPermitTy"
        >
          <option value={undefined}> </option>
          {OrdPermitTypeOnTarget.map((v) => (
            <option key={v[0]} value={v[1]}>
              {v[1]}
            </option>
          ))}
        </select>
      </fieldset>
      <div className="d-flex justify-content-between">
        <Button
          btnRole="Cancel"
          label="닫기"
          onClick={() => setArgs({ ...args, visible: false })}
        />
        <Button
          onClick={() => {
            if (args.ordst === undefined || ordPermitTy === undefined) {
              return;
            }
            const prdPermitKey = OrdPermitTypeOnTarget.find(
              (v) => v[1] === ordPermitTy,
            )?.[0];
            reqOrderPermit(
              [args.ordst],
              prdPermitKey as OrderPermitType,
              call,
              m,
            );
          }}
          disabled={args.ordst?.errMsgDisp == null} // TODO
          btnRole="Ok"
          label="요청"
        />
      </div>
    </div>
  );
  return (
    <Dialog
      style={{ minWidth: '300px', minHeight: '400px' }}
      header={args.title}
      visible={args.visible}
      onHide={() => {
        setArgs({ ...args, visible: false });
      }}
    >
      <TabView
        tabs={[
          { header: '주문 수정', content: orderEditPanel },
          { header: '주문 허용 요청', content: reqPermitPanel },
        ]}
      />
    </Dialog>
  );
}
