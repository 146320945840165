import React, { useEffect, useState } from 'react';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { IContextMenuItem } from 'tmslib/src/table/ContextMenu';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import DateSelector from '../../shared/DateSelector';
import StrgSelector from '../../shared/StrgSelector';
import { AllocDaily, AllocDailyCfg, AllocDailyCfgTy, allocDailyCfgchema } from '../../Tms/BO';
import { ValidData, callAxios, utilGet, checkItems, UrlGrid, UrlGridArgs, emptyGridArgs } from '../../tmsutil';
import { DftBtnStyle } from '../../AppTypes';
import ButtonWithHelp from '../../shared/ButtonWithHelp';

const currMenu = '/Master/Allocation';

const allocDailyCfgArgs: UrlGridArgs<AllocDailyCfg> = {
  url: `${currMenu}/AllocDailyCfg`,
  title: 'ttt',
  columns: ['d0', 'd1', 'lbPrin', 'ubPrin', 'lbTgt', 'ubTgt', 'allocVal'],
  headers: ['적용시작일', '적용종료일', '자금하단', '자금상단', '기준값하단', '기준값상단', '적용'],
  widths: { d0: 80, d1: 80 },
  height: 250,
  editable: true,
  meta: {
    dftColWidth: 100,
    idType: 'uuid',
  },
  infoMsg: '포함여부: 적용시작일-적용종료일 [ ] 자금하단-자금상단 [ ) 기준값하단-기준값상단 [ )',
  schema: allocDailyCfgchema,
};

const navCfgArgs: UrlGridArgs<AllocDailyCfg> = {
  ...allocDailyCfgArgs,
  title: '한도배분 구간설정'
}

const stdCfgArgs: UrlGridArgs<AllocDailyCfg> = {
  ...allocDailyCfgArgs,
  title: '표준편차 구간설정'
}

type T = { Id: number }; // 임의 IId 타입

export default function Allocation() {
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const [d, setD] = useState<string>(info?.currBizDay || '');
  const [d0, setD0] = useState<string | null>(null);
  const [st, setSt] = useState<string | null>(null);
  const [allocOnly, setAllocOnly] = useState(true);
  const [daily, setDaily] = useState<AllocDaily[]>([]);
  const [gridArgs, setGridArgs] = useState<UrlGridArgs<T>>(emptyGridArgs);
  const [refreshNeeded, setRefreshNeeded] = useState(0);
  const [incAll, setIncAll] = useState(false);
  const [cfgRefreshNeeded, setCfgRefreshNeeded] = useState(0);

  const vh = 'TTM';
  const linkClass = 'btn btn-link';

  useEffect(() => {
    utilGet(m, logger, 'FundPeriod', { d, vh }, (data) => setD0(data.d0));
  }, []);

  const call = (
    func: string,
    params: unknown,
    onSuccess: (data: ValidData) => void,
    isGet?: boolean,
  ) =>
    callAxios({
      m,
      logger,
      url: `/Master/Allocation/${func}`,
      params,
      onSuccess,
      isGet,
    });

  const getDaily = () => {
    if (!d0) return;
    const params = { d, d0, st: st ?? null, allocOnly };
    call(
      'Daily',
      params,
      (data) => {
        setDaily(data.daily);
        setD0(data.d0);
      },
      true,
    );
  };

  const getButton = (a: unknown) => {
    const { title } = a as UrlGridArgs<T>;
    return (
      <ButtonWithHelp
        title={title}
        key={title}
        helpid={title}
        className={DftBtnStyle}
        onClick={() => {
          setGridArgs(a as UrlGridArgs<T>);
          setCfgRefreshNeeded(p => p + 1)
        }}
        label={title}
      />
    );
  };

  useEffect(() => getDaily(), [d, d0, st, allocOnly, refreshNeeded]);

  const cnfrm = (objs: AllocDaily[], subj: 'Mngr' | 'Cmpl' | 'Oper' | 'NoAlloc') => {
    if (!checkItems(objs, m, true)) return;
    call('Confirm', { id: objs[0].Id, subj }, () =>
      setRefreshNeeded((p) => p + 1),
    );
  };


  const updateField = (
    original: AllocDaily,
    rowIndex: number,
    columnId: string,
    value: unknown,
  ) => {
    const params = columnId === 'manualLev' ?
      { id: original.Id, lev: value, std: original.manualPfStd }
      : { id: original.Id, lev: original.manualLev, std: value };
    call('UpdateAllocDailyManual', params, getDaily);
  };

  const ctxMenu: IContextMenuItem<AllocDaily>[] = [
    {
      label: '매니저 컨펌',
      callback: (objs: AllocDaily[]) => cnfrm(objs, 'Mngr'),
    },
    {
      label: '리스크 컨펌',
      callback: (objs: AllocDaily[]) => cnfrm(objs, 'Cmpl'),
    },
    {
      label: '운용지원 컨펌',
      callback: (objs: AllocDaily[]) => cnfrm(objs, 'Oper'),
    },
    { divider: true },
    {
      label: '유예',
      callback: (objs: AllocDaily[]) => cnfrm(objs, 'NoAlloc'),
    },
  ];

  return (
    <div style={{ minWidth: '1500px' }} className="children-me-2">
      <DateSelector
        value={d}
        initDate0={d0}
        onChange={(date) => setD(date)}
        onChangeD0={(date0) => setD0(date0)}
      />
      <StrgSelector
        d={d}
        vh={vh}
        onChange={(s) => setSt(s?.Id ?? null)}
        empty
        team
      />
      <label htmlFor="allocOnly">
        <input
          type="checkbox"
          id="allocOnly"
          checked={allocOnly}
          onChange={(e) => setAllocOnly(e.target.checked)}
        />
        기준일만 조회
      </label>
      <hr className="narrow light" />
      <label htmlFor="incAll">
        <input
          type="checkbox"
          id="incAll"
          checked={incAll}
          onChange={(e) => setIncAll(e.target.checked)}
        />
        이전값포함
      </label>

      {[navCfgArgs, stdCfgArgs].map((v) => getButton(v))}
      <button
        type="button"
        className={linkClass}
        onClick={async () => {
          if (!(await m.confirm(`${d} 한도배분 재생성`))) return;
          call('GenAllocDaily', { d }, () => {
            setCfgRefreshNeeded(p => p + 1)
            m.alert('OK')
          }, false)
        }
        }
      >
        (한도배분 생성)
      </button>

      <hr className="narrow light" />
      <SimpleGrid
        data={daily}
        // prettier-ignore
        columns={['d', 'allocDate', 'stId', 'initPrin', 'navReal', 'navRealYd', 'navAvg', 'allocPrc', 'tgtLev', 'tgtAlloc', 'noAlloc',
          'mngrCnfrm', 'cmplCnfrm', 'operCnfrm', 'tgtPfStd', 'errmsg', 'manualLev', 'manualPfStd']}
        // prettier-ignore
        headers={['날짜', '기준일', '운용역', '시작자금', '기준가', '전일기준가', '5일평균', '기준값', '목표한도', '배분', '유예',
          '매니저', '리스크', '운용지원', '표준편차', '에러', '임의한도', '임의표준편차']}
        args={{
          meta: { height: 1500, contextMenus: ctxMenu, editable: true, updateField },
          editables: ['manualLev', 'manualPfStd'],
          checkboxes: ['allocDate', 'noAlloc', 'mngrCnfrm', 'cmplCnfrm', 'operCnfrm'],
          widths: { initPrin: 130, tgtLev: 80, tgtAlloc: 50, errmsg: 200, manualLev: 60, manualPfStd: 60 },
          formatters: {
            navReal: (v) => v?.toFixedWithComma(2),
            navAvg: (v) => v?.toFixedWithComma(2),
            allocPrc: (v) => v?.toFixedWithComma(2),
            initPrin: (v) => v?.toFixedWithComma(0),

          },
        }}
      />

      <UrlGrid
        args={gridArgs}
        params={{ d0, d, ty: gridArgs.title === '한도배분 구간설정' ? AllocDailyCfgTy.Nav : AllocDailyCfgTy.PfStd, all: incAll }}
        refreshNeeded={cfgRefreshNeeded}
      />
    </div>
  );
}
