import React, { useState, useEffect } from 'react';
import SimpleGrid from 'tmslib/src/table/SimpleGrid';
import { useMessageState } from 'tmslib/src/context/MessageContext';
import { useAuthState } from '../Auth/AuthContext';
import { ValidData, callAxios, } from '../../tmsutil';
import '../../App.scss';
import DateSelector from '../../shared/DateSelector';
import { DftBtnStyleMx } from '../../AppTypes';
import ButtonWithHelp from '../../shared/ButtonWithHelp';
import { Calendar, CalendarType, SeminarTy } from '../../Tms/Tools';
import CalendarCore from './CalendarCore';

const currMenu = '/Tools/Calendar';

export type NmId = {
  nm: string;
  Id: string;
}

export type SeminarRes = {
  mngrs: NmId[];
  brks: NmId[];
  thisMonth: Calendar[];
  lastWeek: Calendar[];
  thisWeek: Calendar[];
  nextWeek: Calendar[];
  lastWeekStr: string;
  thisWeekStr: string;
  nextWeekStr: string;
}

type PageFunc =
  'SeminarRes'
  | 'Calendar'
  | 'InsertCalendar'
  | 'ModifyCalendar'
  | 'DeleteCalendar'


const getFuncNm = (ty: CalendarType): PageFunc => {
  switch (ty) {
    case CalendarType.Seminar:
    case CalendarType.SeminarSinga:
      return "SeminarRes";
    default:
      return "Calendar";
  }
}

// prettier-ignore
const smrScheduleColumns: (keyof Calendar)[] = ['d', 'hm', 'smrTy', 'brkNm', 'userNm', 'memo']
const smrScheduleHeaders = ['날짜', '시간', '타입', '증권사', '매니저', '내용']

interface Props {
  ty: CalendarType;
}

export default function CalendarPage({ ty }: Props) {
  const { info } = useAuthState();
  const { msgBox: m, logger } = useMessageState();
  const [year, setYear] = useState<number | null>(null)
  const [month, setMonth] = useState<number | null>(null)
  const [currItem, setCurrItem] = useState<Calendar | null>(null);
  const [items, setItems] = useState<Calendar[]>([]);

  const [qryD, setQryD] = useState(info?.currBizDay ?? ''); // 조회기준일
  const [qryD0, setQryD0] = useState(info?.currBizDay ?? ''); // 조회기준시작일( 싱가폴용 )
  const [upsertD, setUpsertD] = useState(info?.currBizDay ?? ''); // upsert 할 떄 날짜 항목 
  const [hm, setHm] = useState('');
  const [memo, setMemo] = useState('');
  const [smrTy, setSmrTy] = useState(SeminarTy.SMR);
  const [mngr, setMngr] = useState('');
  const [brk, setBrk] = useState('');
  const [score, setScore] = useState(2.5);

  const [smrHistory, setSmrHistory] = useState<Calendar[]>([]);
  const [lastWeekSmrs, setLastWeekSmrs] = useState<Calendar[]>([]);
  const [thisWeekSmrs, setThisWeekSmrs] = useState<Calendar[]>([]);
  const [nextWeekSmrs, setNextWeekSmrs] = useState<Calendar[]>([]);
  const [lastWeekStr, setLastWeekStr] = useState('')
  const [thisWeekStr, setThisWeekStr] = useState('')
  const [nextWeekStr, setNextWeekStr] = useState('')

  const [mngrs, setMngrs] = useState<NmId[]>([]);
  const [brks, setBrks] = useState<NmId[]>([]);
  const [insertOrUpdatedId, setInsertOrUpdatedId] = useState<number | null>(null); // 입력 또는 마지막 수정 아이템 

  const btnClass = DftBtnStyleMx;
  const isSmr = [CalendarType.Seminar, CalendarType.SeminarSinga].contains(ty);

  const call = (
    func: PageFunc,
    params: unknown,
    isGet: boolean,
    whenInvalidMsg?: string | null,
    onSuccess?: (data: ValidData) => void,

  ) =>
    callAxios({
      m,
      logger,
      url: `${currMenu}/${func}`,
      whenInvalidMsg,
      params,
      onSuccess,
      isGet,
    });

  const upsertParams = { Id: currItem?.Id ?? null, ty, hm, d: upsertD, callerId: mngr, smrTy, brkId: brk, score, memo, };
  
  useEffect(() => {
    if (currItem != null) {
      if (isSmr) {
        setHm(currItem.hm)
        setSmrTy(currItem.smrTy)
        setMngr(currItem.callerId)
        setBrk(currItem.brkId)
        setScore(currItem.score)
      }
      setUpsertD(currItem.d)
      setMemo(currItem.memo ?? '');
    }
  }, [currItem])

  const getData = () => {
    if (year != null && month != null) {
      if (isSmr) {
        call(getFuncNm(ty), { y: year, m: month, qryD, ty }, true, null, (res: SeminarRes) => {
          setMngrs(res.mngrs ?? [])
          setMngr(res.mngrs[0].Id ?? '')
          setBrks(res.brks ?? [])
          setBrk(res.brks[0].Id ?? '')
          setItems(res.thisMonth)
          setLastWeekSmrs(res.lastWeek);
          setThisWeekSmrs(res.thisWeek);
          setNextWeekSmrs(res.nextWeek);
          setLastWeekStr(res.lastWeekStr ?? [])
          setThisWeekStr(res.thisWeekStr ?? [])
          setNextWeekStr(res.nextWeekStr ?? [])
        });
      }
      else {
        call(getFuncNm(ty), { y: year, m: month, qryD, ty }, true, null, (res: Calendar[]) => { setItems(res) });
      }
    }
  }

  useEffect(() => {
    getData();
  }, [ty, year, month])

  useEffect(() => {
    const updated = items.filter(v => v.Id === insertOrUpdatedId);
    if (updated.length > 0) {
      setCurrItem(updated[0]);
    }
    else setCurrItem(null);
  }, [items, insertOrUpdatedId])

  return (
    <div style={{ minWidth: '1200px' }}>
      <hr className="narrow light" />

      <CalendarCore ty={ty} items={items} currItem={currItem} setCurrItem={setCurrItem} setUCYear={setYear} setUCMonth={setMonth} />

      <br /><br />
      {isSmr && (<>
        <hr className="narrow light" />
        Date:&nbsp;
        <DateSelector
          value={upsertD ?? ''}
          onChange={(date) => setUpsertD(date)}
        />  &nbsp;&nbsp;

        Time:&nbsp;
        <input
          type="text"
          name="time"
          size={7}
          value={hm || ''}
          placeholder='HH:mm'
          onChange={(e) => setHm(e.target.value as string)}
        />
        <span style={{ fontSize: '8px', color: 'gray' }}>(생략가능)</span>

        <br />
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>

          <select
            name="seminarTy"
            value={smrTy}
            onChange={(e) => setSmrTy(e.target.value as SeminarTy)}
          >
            {Object.keys(SeminarTy).map((v) => (
              <option key={v} value={v}>
                {v}
              </option>
            ))}
          </select>

          &nbsp;&nbsp;Manager:&nbsp;&nbsp;
          <select
            name="mngr"
            value={mngr}
            onChange={(e) => setMngr(e.target.value as string)}
          >
            {mngrs != null && mngrs.length > 0 && mngrs.map((v) => (
              <option key={v.Id} value={v.Id}>
                {v.nm}
              </option>
            ))}
          </select>

          &nbsp;&nbsp; Broker:&nbsp;&nbsp;
          <select
            name="brk"
            value={brk}
            onChange={(e) => setBrk(e.target.value as string)}
          >
            {brks != null && brks.length > 0 && brks.map((v) => (
              <option key={v.Id} value={v.Id}>
                {v.nm}
              </option>
            ))}
          </select>

          Score:&nbsp;&nbsp;
          <select
            name="score"
            value={score}
            onChange={(e) => setScore(e.target.value as unknown as number)}
          >
            {[1.5, 2.5].map((v) => (
              <option key={v} value={v}>
                {v}
              </option>
            ))}
          </select>
        </div>
        <input
          type="text"
          name="memo"
          size={50}
          value={memo || ''}
          placeholder='Memo'
          onChange={(e) => setMemo(e.target.value as string)}
        />

        <div style={{ marginTop: '10px' }}>


          <ButtonWithHelp
            key='InsertCalendar'
            helpid='Insert'
            className={btnClass}
            onClick={() => call('InsertCalendar', upsertParams, false, null, (res) => {
              getData();
              setInsertOrUpdatedId(res);
            })}
            label='Insert'
          />
          <ButtonWithHelp
            key='UpdateCalender'
            helpid='Update'
            className={btnClass}
            onClick={() => call('ModifyCalendar', upsertParams, false, null, (res) => {
              getData();
              setInsertOrUpdatedId(res);
            })}
            label='Update'
            disabled={currItem === null}

          />
          <ButtonWithHelp
            key='DeleteCalender'
            helpid='Delete'
            className={btnClass}
            onClick={() => call('DeleteCalendar', upsertParams, false, null, () => {
              getData();
              setCurrItem(null);
            })}
            label='Delete'
            disabled={currItem === null}
          />

        </div>
      </>
      )}

      {ty === CalendarType.Seminar &&
        <>
          <hr className="narrow light" />

          조회기준일:&nbsp;
          <DateSelector
            value={qryD}
            onChange={(date) => date !== qryD && setQryD(date)}
          />

          <ButtonWithHelp
            key='SeminarRes'
            helpid='SeminarRes'
            className={btnClass}
            onClick={() => call('SeminarRes', { Id: currItem?.Id }, false)}
            label='Get'
          />
          <br /><br />

          <SimpleGrid
            data={lastWeekSmrs}
            columns={smrScheduleColumns}
            headers={smrScheduleHeaders}
            args={{
              title: `지난주 : ${lastWeekStr}`,
            }}
          />
          <SimpleGrid
            data={thisWeekSmrs}
            columns={smrScheduleColumns}
            headers={smrScheduleHeaders}
            args={{
              title: `이번주 : ${thisWeekStr}`,
              meta: { dftColWidth: 80, },
            }}
          />
          <SimpleGrid
            data={nextWeekSmrs}
            columns={smrScheduleColumns}
            headers={smrScheduleHeaders}
            args={{
              title: `다음주 : ${nextWeekStr}`,
              meta: { dftColWidth: 80, },
            }}
          />
        </>
      }

      {ty === CalendarType.SeminarSinga &&
        <>
          <br />
          <hr className="narrow light" />

          조회기준일:&nbsp;
          <DateSelector
            initDate0={qryD0}
            value={qryD}
            onChangeD0={(date0) => setQryD0(date0)}
            onChange={(date) => date !== qryD && setQryD(date)}
          />

          <ButtonWithHelp
            key='CalendarGet'
            helpid='CalendarGet'
            className={btnClass}
            onClick={() => call('Calendar', { qryD0, qryD, ty }, true, null, (res) => {
              setSmrHistory(res)
            })}
            label='Get'
          />
          <br /><br />

          <SimpleGrid
            data={smrHistory}
            columns={smrScheduleColumns}
            headers={smrScheduleHeaders}
          />
        </>
      }
    </div>
  );
}
