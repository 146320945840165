import React, { useEffect, useRef } from 'react';

declare const window: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trends: any;
} & Window;

// https://miromannino.com/blog/embedding-google-trends-with-react/
// https://codesandbox.io/p/sandbox/google-trends-component-for-react-pvdr9x?file=%2Fsrc%2FGoogleTrendsWidget.tsx%3A36%2C14-36%2C20
export default function GoogleTrendsWidget({
  keyword,
  geo,
  time,
  searchTy,
  hl,
  type,
}: {
  keyword: string;
  geo: string;
  time: string;
  searchTy: string;
  hl: string;
  type: string;
}) {
  const widgetRef = useRef<HTMLDivElement>(null);

  // Render
  useEffect(() => {
    // searchTy '' 웹검색
    if (!widgetRef.current || !keyword || !time || !geo || !type) {
      return;
    }

    let keywordArr: { keyword: string; geo: string; time: string }[] = [];
    let qArr: string = '';
    keyword.split(',').forEach((v, i) => {
      keywordArr = [...keywordArr, { keyword: v, geo, time }];

      if (keyword.split(',').length === 0) {
        qArr = encodeURIComponent(v);
      } else {
        if (i === 0) qArr = encodeURIComponent(v);
        qArr = `${qArr}, ${encodeURIComponent(v)}`;
      }
    });

    // [{"keyword":"기아","geo":"KR","time":"now 1-d"},{"keyword":"현대차","geo":"KR","time":"now 1-d"}]
    // q=%EA%B8%B0%EC%95%84,%ED%98%84%EB%8C%80%EC%B0%A8&hl=ko

    // property: images
    // "date=now%201-d&geo=KR&gprop=images&q=%EA%B8%B0%EC%95%84,%ED%98%84%EB%8C%80%EC%B0%A8,%EC%82%BC%EC%84%B1,SK&hl=ko"
    let qry = '';
    if (searchTy === 'images') {
      qry =
        `date=${time.split(' ')[0]}%20${time.split(' ')[1]}` +
        `&geo=${geo}` +
        `&gprop=${searchTy}` +
        `&q=${qArr}` +
        `&hl=${hl}`;
    } else {
      qry =
        `&q=${qArr}` +
        `date=${time.split(' ')[0]}%20${time.split(' ')[1]}` +
        `&geo=${geo}` +
        `&hl=${hl}`;
    }

    widgetRef.current.innerHTML = '';
    window.trends.embed.renderExploreWidgetTo(
      widgetRef.current,
      type,
      {
        comparisonItem: keywordArr,
        category: 0,
        property: searchTy,
      },
      {
        exploreQuery: qry,
        guestPath: 'https://trends.google.co.kr:443/trends/embed/',
      },
    );
  }, [keyword, geo, time, searchTy, hl, type]);

  return <div ref={widgetRef} style={{ width: '100%' }} />;
}

// interface GoogleTrendsWidgetProps {
//   keyword: string;
//   geo: string;
//   time: string;
//   hl: string;
//   type: string;
// }

// const GoogleTrendsWidget: React.FC<GoogleTrendsWidgetProps> = memo(
//   ({ keyword, geo, time, hl, type }) => {
//     const widgetRef = useRef<HTMLDivElement>(null);
//     const [scriptLoaded, setScriptLoaded] = useState(false);

//     // Load script
//     useEffect(() => {
//       if (scriptLoaded) {
//         return;
//       }
//       console.log('loading script');
//       // Load the Google Trends embed script dynamically if not already present
//       const script = document.createElement('script');
//       script.src =
//         'https://ssl.gstatic.com/trends_nrtr/3769_RC01/embed_loader.js';
//       script.addEventListener('load', () => setScriptLoaded(true));
//       document.body.appendChild(script);
//     }, []);

//     // Render
//     useEffect(() => {
//       if (!widgetRef.current || !keyword || !time || !hl || !type) {
//         return;
//       }

//       let keywordArr: any = [];
//       let qArr: string = '';
//       keyword.split(',').map((v, i) => {
//         const t = { keyword: v, geo, time };
//         keywordArr.push(t);

//         if (keyword.length == 0) {
//           qArr = encodeURIComponent(v);
//         } else {
//           if (i === 0) qArr = encodeURIComponent(v);
//           else qArr = ',' + qArr + encodeURIComponent(v);
//         }
//       });

//       widgetRef.current.innerHTML = '';
//       window.trends.embed.renderExploreWidgetTo(
//         widgetRef.current,
//         type,
//         {
//           comparisonItem: keywordArr,
//           category: 0,
//           property: '',
//         },
//         {
//           exploreQuery:
//             `&q=${qArr}` + `date=${time}` + `&geo=${geo}` + `&hl=${hl}`,
//           guestPath: 'https://trends.google.co.kr:443/trends/embed/',
//         },
//       );
//     }, [scriptLoaded, keyword, geo, time, hl, type]);

//     return scriptLoaded && <div ref={widgetRef} style={{ width: '100%' }} />;
//   },
// );

// export default GoogleTrendsWidget;
