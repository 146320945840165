import React from 'react';

export default function Home() {
  return <div>Home</div>;
}

// import React, { useEffect, useState } from 'react';
// import { useSearchParams } from 'react-router-dom';
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';
// import Exporting from 'highcharts/modules/exporting';
// import exportingcsv from 'highcharts/modules/export-data';

// import { useMessageState } from 'tmslib/src/context/MessageContext';
// import { useAuthState } from './Auth/AuthContext';
// import { DftBtnStyleMx } from '../AppTypes';

// import { callAxiosGet } from './../tmsutil';
// import DateSelector from './../shared/DateSelector';

// import { DailyCfg } from '../Tms/BO';

// Exporting(Highcharts);
// exportingcsv(Highcharts);

// const page = '/Home/Index';
// const btnClass = DftBtnStyleMx;
// type PageFunc = 'DailyCfgInfo' | 'Nav' | 'Data' | 'NavIntra' | 'PieChart';

// type ChtLine = {
//   key: number;
//   ord: number;
//   prd: string;
//   ty: string;
//   vhIn: string;
//   stIn: string;
//   title: string;
// };

// const chtOpt: Highcharts.Options = {
//   chart: { type: 'line', width: 400, height: 300 },
//   // prettier-ignore
//   colors: ['#00f', '#0f0', '#f00', '#cc0', '#0c0', '#0cf', '#55c', '#c5c', '#e07426', '#8f26e0', '#75affa', '#aedb30', '#db30ca', '#30db8b', '#308edb'],
//   credits: { enabled: false },
//   title: {
//     style: { fontSize: '12px' },
//     margin: 0,
//   },
//   xAxis: { type: 'datetime' },
//   legend: { itemStyle: { fontWeight: 'normal' }, margin: 0 },
//   plotOptions: {
//     line: {
//       marker: { enabled: false },
//       shadow: false,
//       animation: false,
//       lineWidth: 1,
//     },
//   },
// };

// let navIntraCharts: Highcharts.Options[] = [];

// export default function Home() {
//   const [searchParams, setSearchParams] = useSearchParams();
//   const { user, info } = useAuthState();
//   const { msgBox: m, logger } = useMessageState();
//   const d = searchParams.get('d') || info?.currBizDay || '';
//   const [dailyCfgInfo, setDailyCfgInfo] = useState<DailyCfg[]>([]);

//   const [chtLines, setChtLines] = useState<ChtLine[][]>([]);
//   const [navIntraChart, setNavIntraChart] =
//     useState<Highcharts.Options>(chtOpt);
//   const [pieChart, setPieChart] = useState<Highcharts.Options>(chtOpt);

//   useEffect(() => {
//     if (!navIntraChart.series) return;
//     navIntraCharts.push(navIntraChart);
//   }, [navIntraChart]);

//   const callGet = (funcNm: PageFunc, params: unknown, title?: string | null) =>
//     callAxiosGet({
//       m,
//       logger,
//       url: `${page}/${funcNm}`,
//       params,
//       onSuccess: (data) => {
//         if (funcNm === 'NavIntra') {
//           const series = [...data];
//           setNavIntraChart((p) => ({
//             ...p,
//             title: {
//               text: title ?? '',
//             },
//             series,
//           }));
//         } else if (funcNm === 'PieChart') {
//           setPieChart(data);
//         }
//       },
//     });

//   const getDailyCfgInfo = () => {
//     callAxiosGet({
//       m,
//       logger,
//       url: `${page}/DailyCfgInfo`,
//       params: { d },
//       onSuccess: (data) => {
//         setDailyCfgInfo(data.data);
//         setChtLines(data.line);
//       },
//     });
//   };

//   useEffect(() => {
//     if (!chtLines.length) return;
//     chtLines.forEach((v) => {
//       v.forEach((vv) => {
//         const id = vv.key + vv.ord;
//         const title = vv.title;
//         const prd = vv.prd;
//         const vhIn = vv.vhIn;
//         const stIn = vv.stIn;
//         const chtTy = vv.ty;

//         if (chtTy === 'DailyNav') {
//           callGet('NavIntra', { prd, d, vhs: vhIn, sts: stIn }, title);
//         } else if (chtTy === 'PieChart') {
//           callGet('PieChart', { prd, d, vhs: vhIn, sts: stIn }, title);
//         }
//         // // /Tools/MktWeekly?handler=Style
//         // } else if (v.ty === 'StyleSize') {
//         //   callGet('NavIntra', { d, k: 0 });
//         // } else if (v.ty === 'StyleGrowth') {
//         //   callGet('NavIntra', { d, k: 1 });
//         // }
//       });
//     });
//   }, [chtLines]);

//   // public enum ChartTy { None = -1, TaxNav = 0, DailyNav = 1, PieChart = 2, StyleSize = 3, StyleGrowth = 4 }
//   const getData = () => {
//     getDailyCfgInfo();
//   };

//   return (
//     <>
//       <div className="row" style={{ minWidth: '1000px' }}>
//         <div className="col text-center">
//           <div
//             id="browserAlert"
//             className="alert-danger"
//             style={{ display: 'none' }}
//           >
//             Use Chrome or Firefox instead of Internet Explorer.
//           </div>
//         </div>
//       </div>
//       <a href="/Intro" style={{ color: 'gray' }}>
//         intro
//       </a>
//       <div className="target children-me-2" style={{ minWidth: '1300px' }}>
//         &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//         <DateSelector
//           value={d}
//           onChange={(date) => {
//             if (date !== d) {
//               setSearchParams({ d: date });
//             }
//           }}
//         />
//         <button type="button" className={btnClass} onClick={() => getData()}>
//           Get
//         </button>
//       </div>

//       <hr className="narrow light" />
//       <div className="row" style={{ width: '2500px', height: '600px' }}>
//         <div className="col-xs-3">
//           <HighchartsReact
//             highcharts={Highcharts}
//             options={navIntraCharts[0]}
//           />
//           <HighchartsReact
//             highcharts={Highcharts}
//             options={navIntraCharts[1]}
//           />
//           <HighchartsReact
//             highcharts={Highcharts}
//             options={navIntraCharts[2]}
//           />
//           <HighchartsReact
//             highcharts={Highcharts}
//             options={navIntraCharts[3]}
//           />
//         </div>
//       </div>
//       <br />
//       <br />
//       <br />
//       <br />
//     </>
//   );
// }
